
/* START_TEMPLATE */
define('hbs!templates/views/Payments/ChangePlan',['hbs','handlebars','templates/helpers/decimal','templates/helpers/isPlural','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                    <br/>\n                    You will be granted <strong> ";
  if (helper = helpers.renewable_credits) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.renewable_credits); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </strong> ";
  stack1 = (helper = helpers.isPlural || (depth0 && depth0.isPlural),options={hash:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.renewable_credits), options) : helperMissing.call(depth0, "isPlural", (depth0 && depth0.renewable_credits), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " after this purchase.\n                    ";
  return buffer;
  }
function program2(depth0,data) {
  
  
  return "credits";
  }

function program4(depth0,data) {
  
  
  return "credit";
  }

  buffer += "<div class=\"modal-content create-offer-modal payments_change_plan\">\n    <div class=\"modal-header\" style=\"border-radius: 8px 8px 0 0;\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">Change subscription plan</div>\n        </div>\n    </div>\n\n    <div class=\"modal-body\" style=\"min-height: 130px;\">\n\n        <div class=\"modal-loading\">\n            <div class=\"spinner\"></div>\n        </div>\n\n        <div class=\"purchase-summary\" style=\"display: none\">\n            <p>You are going to change your subscription plan to:</p>\n            <p class=\"center-text\"><strong>";
  if (helper = helpers.description) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.description); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</strong></p>\n\n            <div class=\"form-group\">\n                <label class=\"payment-method\">\n                    Recurring Fee*\n                    <i class=\"fa fa-question popover-icon\">\n                        <div class=\"popover-wrap\" style=\"width: 60vw;\">\n                            <h4>Recurring Fee</h4>\n                            The recurring payment fee is paid in advance for the next monthly period, always on the same day of the month. If the specific day is not present in the next month (29, 30, 31), the collection day is rescheduled to the last day of the month. In the case of plan change, the fee is collected immediately for the ongoing month, taking into account the difference between both plan prices and proportionally to the days used. If the fee should be less than zero, no fee is collected and no refunds are made.\n                        </div>\n                    </i>\n                </label>\n                <p class=\"center-text\">\n                    You will be charged <strong>&pound;"
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.recurring_fee), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.recurring_fee), options)))
    + "</strong> this time.\n                    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.renewable_credits), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </p>\n\n                <p>\n                    Starting from next billing cycle your charges will be <strong>&pound;"
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.next_month_recurring_fee), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.next_month_recurring_fee), options)))
    + "</strong><br>\n                    You will receive <strong>";
  if (helper = helpers.next_month_renewable_credits) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.next_month_renewable_credits); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</strong> credits each month.\n                </p>\n\n            </div>\n\n\n        </div>\n    </div>\n    <div class=\"modal-footer\">\n        <p class=\"star-info\">*) All prices exclude VAT</p>\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Cancel", options) : helperMissing.call(depth0, "trans", "Cancel", options)))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary change-plan-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">YES, CHANGE MY PLAN</span></button>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
