// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Retailers/CreateRetailer',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Retailers/CreateRetailer', 'ladda-bootstrap', 'bootstrap-dialog'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                'retailerName' : 'input[name="retailerName"]',
                'retailerEmail' : 'input[name="retailerEmail"]'
            },

            events: {
                'click .create-btn' : 'createRetailer'
            },

            onShow: function () {
                var me = this;
                setTimeout(function () { me.ui.retailerName.focus(); }, 200);
            },

            createRetailer: function (event) {
                var me = this,
                    newRetailer,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    name = me.ui.retailerName.val(),
                    email = me.ui.retailerEmail.val();


                if (_.isEmpty(name)) {
                    App.os.showFieldError('input[name="retailerName"]', App.trans('Please enter Retailer name'));
                    return;
                }

                if (!App.os.isValidEmail(email)) {
                    App.os.showFieldError('input[name="retailerEmail"]', App.trans('Please enter valid email address'));
                    return;
                }

                l.start();

                this.ui.retailerName.prop('disabled', true);
                this.ui.retailerEmail.prop('disabled', true);
                this.$el.find('button').addClass('disabled');

                newRetailer = App.currentUser.getRetailers().create({
                    'name' : name,
                    'email' : email
                }, {
                    wait: true,
                    success: function (model, response, options) {
                        App.vent.trigger('App.retailers.showDetails', newRetailer.get('uri'));
                        App.mainRegion.currentView.modalRegion.currentView.destroy();
                    },
                    error: function (model, response, options) {
                        me.destroy();
                        BootstrapDialog.show({
                            type: BootstrapDialog.TYPE_DANGER,
                            title: App.trans('Huston! We have a problem!'),
                            message: App.trans("Unfortunatelly an error occurred while creating new Retailer.<br><br>") + '<b>' + response.responseJSON.reason + '</b>',
                            buttons: [{
                                label: App.trans("Close"),
                                action: function (dialog) {
                                    dialog.close();
                                }
                            }]
                        });
                    }
                });
            }

        });
    });

