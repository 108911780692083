// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Payments/ChangePlan',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/ChangePlan', 'ladda-bootstrap', 'bootstrap-dialog', 'stripe', 'views/Payments/StripeModal'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog, Stripe, StripeModal) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            events: {
                'click .change-plan-btn': 'changePlan',
                'blur [name=promo_code]': '_refresh'
            },

            initialize: function (options) {
                this.plan = options && options.plan;
                this.model = App.currentCompany.getSubscriptionPlans().getCurrent();
                this.getPlan({ "plan_name" : this.plan}, true);
            },

            onRender: function () {
                var me = this;
                if (me.model) {
                    me.$el.find('.modal-loading').hide();
                    me.$el.find('.purchase-summary').show();
                }
            },

            handleStripe: function(data){
                var token;
                if(data.redirect_url && data.redirect_url.indexOf('stripe')!=-1){
                    token = data.redirect_url.slice(7);

                    App.mainRegion.currentView.modalRegion.$el.removeClass('fade');
                    App.mainRegion.currentView.modalRegion.$el.modal('hide');
                    App.mainRegion.currentView.modalRegion.$el.addClass('fade');

                    var modalView = new StripeModal({'token': token, 'uri': data.uri});
                    var callback = function () {
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    };
                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);
                    } else {
                        callback();
                    }

                }
            },

            getPlan: function (data, preview) {
                var me = this,
                    headers = { 'X-Alt-Referer': App.appUrl + '/#profile/payments' };
                $.ajax({
                    url: App.enrichApiUrl(App.currentCompany.get('uris').Payments) + (preview ? '?preview=1' : ''),
                    type: 'POST',
                    dataType: 'json',
                    headers: headers,
                    data: JSON.stringify(data),

                    success: function (data, textStatus, jqXHR) {

                        // inject private screen fee
                        data['private_screen_fee'] = App.currentCompany.getSubscriptionPlans().getByType(me.plan).get('privateScreenFee')[App.currency];
                        // inject setup fee payment status for rendering purposes
                        data['setup_fee_paid'] = App.currentCompany.isSetupFeePaid();
                        // inject next month recurring fee
                        data['next_month_recurring_fee'] = App.currentCompany.getSubscriptionPlans().getByType(me.plan).get('recurringFee')[App.currency]['monthly'];
                        // inject next month renewable credits
                        data['next_month_renewable_credits'] = App.currentCompany.getSubscriptionPlans().getByType(me.plan).get('renewableCredits');

                        if (data.payment_provider=="Stripe"){
                            me.handleStripe(data);
                        }

                        me.model = new Backbone.Model(data);
                        if (preview) {
                            me.render();
                        } else if(!data.redirect_url || data.redirect_url.indexOf('stripe')==-1){
                            if (!_.isNull(me.model.get('redirect_url'))) {
                                window.location.href = me.model.get('redirect_url');
                            } else {
                                Backbone.history.stop();
                                window.location.replace(App.appUrl + '/?success=1#profile/payments');
                            }
                        }
                    },

                    error: function (jqXHR, textStatus, errorThrown) {
                        var errorMsg = 'Sorry. An error occurred while processing your payment. Please try again later or contact Flow.City Administrator.';
                        if (jqXHR.status == 402 && jqXHR.responseJSON.reason) {
                            errorMsg = jqXHR.responseJSON.reason
                        }
                        App.vent.trigger('App.layout.paymentStatus.show', errorMsg, 'alert-danger');
                        me.destroy();
                    }
                });
            },

            changePlan: function (event) {
                var me = this,
                    data = {},
                    screenKit = me.$el.find('[name=private_screens]:checked').val(),
                    l = Ladda.create(document.querySelector('.modal-content .change-plan-btn'));

                data = {
                    "plan_name" : me.plan
                };

                if (screenKit === "1") {
                    data['private_screens'] = 1;
                }

                l.start();
                me.$el.find('button').addClass('disabled');
                me.getPlan(data, false);
            },

            _refresh: function () {
                var me = this,
                    promoCode = me.$el.find('[name=promo_code]').val();

                if (!_.isEmpty(promoCode)) {
                    delete(me.model);
                    me.$el.find('.modal-loading').show();
                    me.getPlan({ "plan_name" : this.plan, "promo_code" : promoCode }, true);
                }

            }

        });
    });
