// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Displays/Displays',['App',
        'backbone',
        'hbs!templates/views/Displays/Displays',
        'collections/Displays',
        'collections/Companies',
        'collections/DisplayGroups',
        'views/Displays/Display',

        'bootstrap-select'
        ],
    function (
        App,
        Backbone,
        template,
        DisplayCollection,
        CompaniesCollection,
        DisplayGroups,
        Display
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            // childView: Display,
            //
            // scrollContext: '.displays-scroll-area',
            //
            // childViewContainer: 'ul',
            //
            // filterValue: '',
            //
            // searchBar: {
            //     fields: ['name'],
            //     sortBy: {
            //         'name': 'Display Name',
            //         'created': 'Creation Time'
            //     },
            //     filter: [{
            //         type: 'checkbox',
            //         placeholder: 'Online only',
            //         field: 'active'
            //     }, {
            //         type: 'magicsuggest',
            //         placeholder: 'Type display group',
            //         url: '/data/search/display-groups/admin',
            //         searchField: 'display_name',
            //         field: 'group'
            //     }]
            // },
            //
            // ui: {
            //     filter: '#screen-filter'
            // },
            //
            // events: {
            //     'change @ui.filter' : 'refilterView'
            // },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'li',

                    onRender: function () {
                        $('.load-next').hide();
                    },

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            // initialize: function () {
            //     var me = this;
            //
            //     me.childViewOptions = {
            //         parent: me
            //     };
            //
            //     me.collection = App.currentUser.getDisplays({fetch: false});
            //
            //     App.adminDisplayGroups = App.currentUser.getAdminDisplayGroups();
            //     me.listenTo(App.vent, 'App.layout.screenManagement.display.reset', _.bind(me._resetCurrentDisplay, me));
            //
            //
            // },

            onRender: function () {
                var me = this;

                // if (!me.collection.beenQueried()) {
                //     $.when(me.collection.query()).then(function () {
                //         me.$el.find('.no-item').html('<i class="fa fa fa-desktop"></i> No screens found');
                //     });
                // } else {
                //     if (me.collection._moreResults) { me._moreItems(); }
                //     if (!_.size(me.collection)) { me.$el.find('.no-items').html('<i class="fa fa fa-desktop"></i> No screens found'); }
                // }

                this.srcReplacer();

            },

            srcReplacer: function(){
                var me = this;

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=devices';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }

            // refilterView: function () {
            //     var me = this;
            //     me.filterValue = me.ui.filter.val();
            //
            //     console.log('refiltered ', me.filterValue);
            //
            //     if (!_.isEmpty(me.filterValue)) {
            //         me.filter = function (child, index, collection) {
            //             return child.get('group') === me.filterValue;
            //         };
            //     } else {
            //         me.filter = function (child, index, collection) {
            //             return true;
            //         };
            //     }
            //     me.render();
            // },
            //
            // _resetCurrentDisplay: function () {
            //     var me = this,
            //         view;
            //     if (App.currentDisplay) {
            //         view = me.children.findByModel(App.currentDisplay);
            //         if (view && !view.isDestroyed) {
            //             view.render();
            //         }
            //         delete App.currentDisplay;
            //     }
            // }


        });
    });
