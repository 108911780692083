// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferMobilePreview',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferMobilePreview', 'backbone', 'jquery.zoomer'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            modelEvents: {
                'change:media_file': '_refreshPreview'
            },

            events: {
                'change input[name="aspectRatio"]' : '_previewAspectRatio',
                'click .download-mediafile-btn' : '_downloadMediaFile'
            },

            initialize: function () {
                var me = this;
                me.listenTo(App.vent, 'App.layout.offer.refreshPreview', _.bind(me._refreshPreview, me));
            },


            onRender: function () {
                var me = this;
                me.$el.find('.fileinput').on('change.bs.fileinput', _.bind(me._startUpload, me));

                if (App.currentOffer.isDraft()) {
                    me.$el.find('[data-draft="true"]')
                        .prop('disabled', false)
                        .removeClass('hidden');
                    me.$el.find('[data-draft="false"]').remove();
                }

            },

            _refreshPreview: function (aspectRatio) {
                this.render();
            },

            _downloadMediaFile: function (event) {
                event && event.preventDefault();
                var me = this;

                if (App.os.isAppleMobile()) {
                    // workaround for iOS devices
                    window.open(me.model.get('media_file'), '_blank');
                } else {
                    $('#download-frame').attr('src', me.model.get('media_file'));
                }
            },

            _startUpload: function () {
                App.vent.trigger('App.offer.artwork.upload');
            }


        });
    });
