
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/YourLocationWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "\n<div class=\"inner\" style=\"height: calc(100% - 2.5em);\">\n	\n			<!-- MAP -->\n			<section style=\"height: 100%;\">\n				<div style=\"height: 100%;\">\n					<div style=\"height: 100%;\">\n					  <div id=\"map\" class=\"widget\" style=\"height: 19em; border-radius:10px 10px 0 0; width:100%; overflow:hidden;\"></div>\n					</div>\n				</div>\n			</section>\n\n			<div class=\"grand-parent\">\n			  <div class=\"parent\">\n				<div class=\"child\"></div>\n			  </div>\n			</div>\n\n			\n			<section class=\"heatmap-slider-area\">\n\n				<h4 id=\"slider-label\" style=\"color:white; float:right; white-space: nowrap;\nmargin-right: 15px;\" class=\"col-xs-3\"></h4>\n				<div id=\"slider\"></div>\n\n			</section>\n\n\n     \n	\n  <!-- </div> -->\n</div>\n		\n";
  });
return t;
});
/* END_TEMPLATE */
;
