// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,window,console,setTimeout*/
define('layouts/ProfileLayout',['App', 'jquery', 'underscore', 'hbs!templates/layouts/ProfileLayout', 'backbone',

        // views
        'views/Profile/ProfileUserDetails',
        'views/Profile/ProfileLocationSettings',
        // 'views/Profile/ProfileNotifications',
        'views/Payments/PaymentsLayout',
        'views/Profile/ProfileBillingInfo',
        'views/Payments/MediaPlans',
        'views/Payments/Invoices',

        'bootstrap-dialog',
        'views/Payments/ChangePlan',
        'views/Payments/CreditsPurchase'

    ],
    function (App, $, _, template, Backbone,

        ProfileUserDetails,
        ProfileLocationSettings,
        // ProfileNotifications,
        PaymentsLayout,
        ProfileBillingInfo,
        MediaPlans,
        Invoices,

        BootstrapDialog,
        ChangePlan,
        CreditsPurchase

    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'user-profile',

            regions: {
                profileMenu: '#profile-menu',
                profileArea: '#profile-area'
            },

            ui: {
                topMenu: '.top-profile-menu'
            },

            tabs: {
                'user-details' : ProfileUserDetails,
                'billing-info' : ProfileBillingInfo,
                'location-settings' : ProfileLocationSettings,
                // 'notifications' : ProfileNotifications,
                'payments' : PaymentsLayout,
                'media-plans' : MediaPlans,
                'invoices' : Invoices
            },

            events: {
                'click .top-profile-menu a': '_openProfileTab'
            },

            initialize: function (options) {
                var me = this;
                me.options = options;
            },

            setTab: function(){
                var me = this;
                var tabs = me.$el.find('.top-profile-menu li');
                // $.when(App.currentUser.getPermissions().promise).then(function () {
                    var permissions = App.currentUser.getPermissions();
                    var desired = me.$el.find('[data-id='+me.options.profileTab+']');
                    var desiredPerm = desired.attr('data-permission');
                    if(permissions.get(desiredPerm)!='active') {
                        tabs.each(function (i, element) {
                            var elPermission = $(element).attr('data-permission');
                            console.debug("permissions.get(elPermission)", permissions.get(elPermission));
                            if (permissions.get(elPermission) == 'active') {
                                var id = $(element).attr('data-id');
                                me.profileTab = id;
                                me.profileView = me.tabs[me.profileTab];
                                App.appRouter.navigate('profile/' + id, {trigger: true});
                                return false;
                            }
                        })
                    }else{
                        me.profileTab = me.options.profileTab;
                        me.profileView = me.tabs[me.profileTab];
                        App.appRouter.navigate('profile/' + me.options.profileTab, {trigger: true});
                        me.ui.topMenu.find('li[data-id="' + me.profileTab + '"]').addClass('active');
                        me.profileArea.show(new me.profileView());
                    }
                // });
            },

            _openProfileTab: function (event) {
                event.preventDefault();
                var me = this,
                    tabEl = $(event.currentTarget).parent(),
                    tab = tabEl.data('id');

                // clear previous selection
                me.ui.topMenu.find('li').removeClass('active');
                App.appRouter.navigate('profile/' + tab, { trigger: true });

            },


            onRender: function () {
                var me = this;

                // remove subscription plan section for non-payable company
                if (!App.currentCompany.isPayable()) {
                    me.$el.find('[data-id="payments"]').remove();
                }
                //
                // me.setTab();
                //
                // // prevent display subscription page while company is not payable
                // if (!App.currentCompany.isPayable() && me.profileTab == 'payments') {
                //     me.profileTab = 'user-details';
                //     me.profileView = me.tabs[me.profileTab];
                // }
                //
                // if (me.profileView) {
                //     me.ui.topMenu.find('li[data-id="' + me.profileTab + '"]').addClass('active');
                //     me.profileArea.show(new me.profileView());
                // }

                this.srcReplacer();
            },

            srcReplacer: function(){
                var me = this;
                var url_string = window.location.href;

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var module = '?module=profile_layout';
                        var tab = '';
                        if(url_string.includes('location'))
                            tab = '&tab=location';
                        else if(url_string.includes('payments'))
                            tab = '&tab=payments';
                        else if(url_string.includes('user-details'))
                            tab = '&tab=user-details';
                        else if(url_string.includes('billing_info'))
                            tab = '&tab=billing_info';
                        var src = window.appUrl + '/ng/dist/index.html' + module;

                        if(tab.length)
                            src += tab;
                        $('#ng-iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }
        });
    });

