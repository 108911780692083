
/* START_TEMPLATE */
define('hbs!templates/views/DisplayGroups/DisplayGroups',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"admin-screen-list\">\n    <div class=\"add-display-group\">\n        <button class=\"btn btn-default btn-lg btn-top-bar create-btn\"><i class=\"fa fa-plus\"></i> Add new display group</button>\n    </div>\n    <div class=\"displays-list content-panel\">\n        <div class=\"scroll-area displays-scroll-area\">\n            <ul class=\"displays\"></ul>\n            <div class=\"col-lg-12\">\n                <a class=\"servernext infinite-more-link load-next\" id=\"page-load-more\" href=\"#\" style=\"display: none\">\n                    <span class=\"txt\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Show More", options) : helperMissing.call(depth0, "trans", "Show More", options)))
    + "</span>\n                    <div class=\"next-page-loader\"></div>\n                </a>\n            </div>\n        </div>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
