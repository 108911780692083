// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/ChartWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         //'chartjs',
         'hbs!templates/views/Dashboard/ChartWidget',
         'amcharts.serial'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             //Chart,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: function(ctx, opt) { console.log(ctx); console.log(opt); return template(ctx, opt) },

            templateHelpers: function() {
                return {
                    'chartId': this.chartId,
                    'title': this.title
                };
            },

            className: 'white-panel pn chart-area widget widget--footfall',

            ui: {
                chartTitle: '.chart-title',
                chartCanvas: '.chart-canvas'
            },

            initialize: function (options) {
                this.data = [];
                this.dataType = options && options.dataType || 'jsonp';
                this.url = options && options.url || '';
                this.title = options && options.title || 'Chart Title';
                this.chartId = this.title + '-chart';
                this.category = options && options.category || "Timestamp";
                this.graphs = options.graphs;
            },

            onRender: function () {
                var me = this,
                    dd;

                me.ui.chartTitle.html(me.title + ' - <i>today</i>');

                $.ajax({
                    url: App.enrichApiUrl(me.url),

                    error: function(ajax, status, message) {
                        console.error("Call error: " + status + message);
                    },

                    success: function(data) {
                        var _mapObjFn = _.mapValues ? _.mapValues : _.mapObject; // future-proof, newer underscore has mapObject
                        me.data = _.map(data, function (dataRow) {
                            return _mapObjFn(dataRow, function(val, fld) {
                                return fld != me.category ? Number(val) : moment(val).toDate();
                            });
                        });

                        if (!me.isDestroyed) {
                            me.showChart();
                        }
                    }
                });
            },

            adjustCanvasSize: function () {
                var me = this;
                me.ui.chartCanvas.attr('width', me.$el.width() + 'px');
                me.ui.chartCanvas.attr('height', '210px');
                me.ui.chartCanvas.css({
                    width: me.$el.width() + 'px',
                    height: '210px'
                });
            },

            onDestroy: function () {
                var me = this;
                $(window).off('.chartWidget');
            },

            showChart: function () {
                var me = this;
                var graphs = [{
                    "id": "g3",
                    "title": me.graphs[0].name,
                    "fillAlphas": 0.4,
                    "lineColor": "#918BC9",
                    "fillColors": "#918BC9",
                    "valueField": me.graphs[0].column,
                    "balloonText": "<div style='margin:5px; font-size:12px;'>" + me.graphs[0].baloon + ":<b>[[value]]</b></div>",
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }, {
                    "id": "g1",
                    "title": me.graphs[1].name,
                    "fillAlphas": 0.4,
                    "lineColor": "#058282",
                    "fillColors": "#82FAFA",
                    "valueField": me.graphs[1].column,
                    "balloonText": "<div style='margin:5px; font-size:12px;'>" + me.graphs[1].balloon + ":<b>[[value]]</b></div>",
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }, {
                    "id": "g2",
                    "title": me.graphs[2].name,
                    "fillAlphas": 0.4,
                    "lineColor": "#016eaf",
                    "fillColors": "#06b8e4",
                    "valueField": me.graphs[2].column,
                    "balloonText": "<div style='margin:5px; font-size:12px;'>" + me.graphs[2].balloon + ":<b>[[value]]</b></div>",
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }];

                me.chart = AmCharts.makeChart(me.chartId, {
                        "type": "serial",
                        "theme": "light",
                        "path": "/js/libs/amcharts/",
                        "dataProvider": me.data,
                        
                        "fontFamily": "Ruda",
                        "fontSize:": "11",

                        "valueAxes": [{
                            "position": "left",
                            "title": "Crowd Level"
                        }],
                        "graphs": graphs,

                        "chartScrollbar": {
                            "graph": "g1",
                            "scrollbarHeight": 40,
                            "backgroundAlpha": 0,
                            "selectedBackgroundAlpha": 0.1,
                            "selectedBackgroundColor": "#888888",
                            "graphFillAlpha": 0,
                            "graphLineAlpha": 0.5,
                            "selectedGraphFillAlpha": 0,
                            "selectedGraphLineAlpha": 1,
                            "autoGridCount": true,
                            "color": "#AAAAAA"
                        },
                        "chartCursor": {
                            "categoryBalloonDateFormat": "JJ:NN, EEEE", // hard to find, http://www.amcharts.com/tutorials/formatting-dates/
                            "cursorPosition": "mouse"
                        },
                        "categoryField": me.category,
                        "categoryAxis": {
                            "minPeriod": "15mm",
                            "parseDates": true,
                            "dateFormats": [
                                {period:'fff',format:'JJ:NN:SS'},
                                {period:'ss',format:'JJ:NN:SS'},
                                {period:'mm',format:'JJ:NN'},
                                {period:'hh',format:'JJ:NN'},
                                {period:'DD',format:'EEE JJ:NN'},
                                {period:'WW',format:'MMM DD'},
                                {period:'MM',format:'MMM'},
                                {period:'YYYY',format:'YYYY'}]                            
                        },
                        "legend": {
                            "useGraphSettings": true,
                            "position": "absolute",
                            "right": 100,
                            "width": 300,
                            "top": 58,
                            "backgroundAlpha": 0.6,
                            "backgroundColor": "white"
                        },
                        "export": {
                            "enabled": true
                        }
                    });

                me.zoomChart();
                me.chart.hideGraph(me.chart.graphs[2]); // does not look nice with 3 overlayed
                me.chart.addListener("rendered", _.bind(me.zoomChart, me));
            },

            zoomChart: function () {
                var me = this,
                    now = moment(),
                    weekday = now.day(),
                    since = moment(_.first(me.data)[me.category]).day(weekday).hour(6).minute(0).toDate(),
                    to = moment(_.first(me.data)[me.category]).day(weekday).hour(22).minute(0).toDate();
                me.chart.zoomToDates(since, to);
            }
        });
    });


