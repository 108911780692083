
/* START_TEMPLATE */
define('hbs!templates/layouts/ProfileLayout',['hbs','handlebars','templates/helpers/subscriptionPlan'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  
  return "<li data-permission=\"subm_noti_btn\" data-id=\"notifications\"><a href=\"#\">Notifications</a></li>";
  }

function program3(depth0,data) {
  
  
  return "\n<div style=\"display: flex;position: absolute;top: 0;left: 0;right: 0;bottom: 0;\">\n\n    <iframe id=\"ng-iframe\" src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n\n</div>\n";
  }

function program5(depth0,data) {
  
  
  return "\n<div style=\"display: flex;position: absolute;top: 0;left: 30px;right: 0;bottom: 0;\">\n\n    <iframe id=\"ng-iframe\" src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n\n</div>\n";
  }

  buffer += "<!--<div id=\"profile-menu\">-->\n    <!--<ul class=\"top-profile-menu\" style=\"margin-bottom: -3px;\">-->\n        <!--<li data-permission=\"subm_user_btn\" data-id=\"user-details\"><a href=\"#\">User details</a></li>-->\n        <!--<li data-permission=\"subm_bill_btn\" data-id=\"location-settings\"><a href=\"#\">Location settings</a></li>-->\n        <!--<li data-permission=\"subm_bill_btn\" data-id=\"billing-info\"><a href=\"#\">Billing info</a></li>-->\n        <!--&lt;!&ndash;";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, "PLUS", "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "PLUS", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "&ndash;&gt;-->\n        <!--<li data-id=\"payments\" data-permission=\"subm_subs_btn\"><a href=\"#\">Subscription Plan</a></li>-->\n        <!--&lt;!&ndash;<li data-id=\"media-plans\" data-permission=\"subm_invo_btn\"><a href=\"#\">Media Plans</a></li>&ndash;&gt;-->\n        <!--<li data-id=\"media-plans\" data-permission=\"subm_invo_btn\" style=\"background: gray; pointer-events: none;\"><a href=\"#\">Media Plans</a></li>-->\n        <!--<li data-id=\"invoices\" data-permission=\"subm_invo_btn\"><a href=\"#\">Invoices</a></li>-->\n    <!--</ul>-->\n<!--</div>-->\n<!--<div id=\"profile-area\"></div>-->\n";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", "PLUS", "ADMIN", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", "PLUS", "ADMIN", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data},helper ? helper.call(depth0, "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
