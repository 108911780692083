// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferNG',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferNG', 'backbone',

        // models
        'models/Offer',

        // views
        'views/Offers/OfferSchedulesSummary',
        'views/Offers/OfferUploadTemplate',
        'views/Offers/OfferSchedules',
        'views/Offers/OfferPreview',
        'views/Offers/OfferMobilePreview',
        'views/Offers/OfferTemplates',

        'views/Dashboard/CreateCampaignAudience',
        'views/Dashboard/CreateCampaignFormats',
        'views/Dashboard/CreateCampaignBudget',

        'views/Displays/DisplayGroups',
        'views/Payments/CreditsPurchase',

        //behaviors
        'behaviors/Onboarding',
        'behaviors/Permissions',

        'moment',
        'bootstrap-dialog',
        'magnific-popup',
        'jquery.zoomer', 'bootstrap-touchspin'],
    function (App, $, _, template, Backbone,

              // models
              Offer,

              // views
              OfferSchedulesSummary,
              OfferUploadTemplate,
              OfferSchedules,
              OfferPreview,
              OfferMobilePreview,
              OfferTemplates,

              CreateCampaignAudience,
              CreateCampaignFormats,
              CreateCampaignBudget,

              DisplayGroups,
              CreditsPurchase,

              Onboarding,
              Permissions,

              moment,
              BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            tagName: 'section',
            className: 'wrapper',

            regions: {
                // layoutDisplayList: '#display-list',
                // offerUploadTemplate: '.offer-upload-templates',
                // offerSchedules: '.offer-schedules',
                // offerPreviewArea: '.offer-preview-area',
                // offerSchedulesSummary: '.offer-schedules-summary',
                // offerSchedulesSummaryClone: '.offer-schedules-summary-clone',
                // createCampaignAudience: '.create-campaign-audience',
                // createCampaignFormats: '.create-campaign-formats',
                // createCampaignBudget: '.create-campaign-budget'
            },

            ui: {
                layoutDisplayList: '#display-list',
                offerPreview: '#offer-preview',
                progressBar: '.uploading-progress',
                progressBarRadial: '.uploading-progress .radial-progress',
                actions: '.offer-actions',
                statusUpdate: '.offer-notification > div',
                timeSelector: 'input[name="display_time"]',
                loadingMask: '.loading',
                advancedOptions: '#advanced-options',
                durationToggle: '#duration-toggle',
                offerDraftInfo: '.offer-draft-info',
                hiddentabs1:'#campaign-audience-tab',
                hiddentabs2:'#campaign-formats-tab',
                hiddentabs3:'#campaign-budget-tab',
                draftModeNotification:'.publish-campaign-button',
                offerMainWrapper:'.offer-main-wrapper',
                aspectRatioSelector:'.aspect-ratio-selector',
                displayScreens: '.display-groups-scroll-area ul li',
                animateGuideArrows:'.advanced-options-link i',
                ngIframe: '#ng-iframe'
            },

            // behaviors: {
            //     Permissions:{
            //         behaviorClass: Permissions,
            //         App: App
            //     },
            //     Onboarding:{
            //         behaviorClass: Onboarding,
            //         variant: 'Offer'
            //     }
            // },

            initialize: function (options) {
                var me = this;

                if (!(App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER)) {
                    this.model.getAttachments();
                }

                // this._refresh = _.debounce(this.render, 1000);
                // this.listenTo(App.vent, 'App.layout.offer.refresh', _.bind(me._refresh, me));
                // this.listenTo(App.vent, 'App.layout.offer.showTemplates', _.bind(me._openOfferTemplatesModal, me));
                // this.listenTo(App.vent, 'App.offer.artwork.zoom', _.bind(me._zoomArtwork, me));
                // this.listenTo(App.vent, 'App.offer.artwork.upload', _.bind(me.startUpload, me));
                // App.vent.trigger('App.layout.whiteBody.toggle');
                // Backbone.Validation.bind(me);
                this.model.getOfferSchedules().promise.done(_.bind(me.showOfferSchedules, me));
            },

            // bindings: {
            //     '[name="name"]' : 'name',
            //     '[name="text1"]' : 'text1',
            //     '[name="text2"]' : 'text2',
            //     '[name="text3"]' : 'text3',
            //     '[name="link_url"]' : 'link_url',
            //     '[name="offer_variant"]':'offer_variant',
            //     '[name="budget"]' : 'budget',
            //     '[name="intensity"]' : 'intensity',
            //     '[name="audience"]' : 'audience',
            //     '[name="display_time"]' : {
            //         observe: 'display_time',
            //         onSet: function (val, options) {
            //             return parseInt(val);
            //         }
            //     }
            // },

            // events: {
            //     'click .offer-to-draft-btn' : '_revertOfferToDraft',
            //     'touchspin.on.stopspin @ui.timeSelector': 'updateDisplayTime',
            //     'click .select-template-btn' : '_openOfferTemplatesModal',
            //     'blur [data-binding="offer_variant_options"]': '_onOfferVariantOptionsFocusOut',
            //     'click .artwork-specs-link': '_showSpecs',
            //     'click .advanced-options-link' : '_toggleAdvancedOptions',
            //     'click .collapsible-block-click-detector': 'openTab',
            //     'click #buy-credits-here':'_buyCredits',
            //     'click .formats-buttons':'actualiseSummary',
            //     'click .publish-campaign-button' : 'publishCampaign'
            // },

            publishCampaign: function(event){
                var me = this;

                if( $('.campaign-name-field').val() === '') {
                    BootstrapDialog.alert("Set Campaign Name.");
                    return;
                }
                if( parseInt($('#range').html()) < 1 ) {
                    BootstrapDialog.alert("Set the budget.");
                    return;
                }

                console.log(App.currentOffer);

                if(App.currentOffer.getOfferSchedules() && App.currentOffer.getOfferSchedules().models && !App.currentOffer.getOfferSchedules().models[0].id)
                    App.currentOffer.getOfferSchedules().create({
                        "type":"DbOfferScheduleTime",
                        "schedule_start": moment().format("YYYY-MM-DDT00:00"),
                        "schedule_stop": moment().endOf('isoWeek').format("YYYY-MM-DDT23:59"),
                        "weekdays": [0,1,2,3,4,5,6],
                        "time_start": null,
                        "time_stop": null,
                        "all_day":true,
                        "active": true
                    });

                var postSubmitting = function () {
                    window.displayGroupId = $('.create-campaign-audience .audience-buttons.selected').attr('display-group-id');
                    console.log('URI: '+ App.enrichApiUrl(App.currentOffer.attributes.uris.OfferTargets));

                    $.ajax({
                        url: App.enrichApiUrl(App.currentOffer.attributes.uris.OfferTargets),
                        type: 'POST',
                        dataType: "json",
                        data: JSON.stringify({
                            status:'submitted',
                            target:'/data/display-groups/'+window.displayGroupId
                        })
                    }).done(function (data) {
                        //App.currentOffer.getOfferTargets().add(data, {merge: true, silent: true});
                        me.model.lockOffer();
                        $(event.currentTarget).find('.fa-spinner').remove();
                        me.setupView();
                    });
                };
                //console.log($('.'+window.selectedAudience+' .back-to-draft'));
                //$('.'+window.selectedAudience+' .back-to-draft').click();
                $(event.currentTarget).append('<i class="fa fa-spin fa-spinner"></i>');
                if(window.displayGroupId === undefined){
                    $.ajax({
                        url: App.enrichApiUrl(App.currentDepartment.attributes.uris.DisplayGroups),
                        type: 'GET',
                        dataType: "json"
                    }).done(function (data) {
                        var i =0;
                        $(data).each(function(){
                            $('#'+data[i].display_name+'BelowButton').attr('display-group-id',data[i].id);
                            i++;
                        });

                        postSubmitting();
                    });
                } else
                    postSubmitting();

            },

            actualiseSummary: function () {
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            },

            showOfferSchedules: function () {

                // if(App.currentCompany.getSubscriptionPlans().getCurrent().attributes.type==='MULTI') {
                //
                //     var me = this,
                //         offerSummary = $('.offer-schedules-summary'),
                //         offerSummaryClone = $('.offer-schedules-summary-clone');
                //
                //     me.ui.ngIframe.parent().remove();
                //
                //
                //     try {
                //         if (me.offerSchedules)
                //             me.offerSchedules.show(new OfferSchedules());
                //         me.offerSchedulesSummary.show(new OfferSchedulesSummary());
                //         offerSummaryClone.html(offerSummary.html());
                //         offerSummary.bind("DOMSubtreeModified", function () {
                //             offerSummaryClone.find('h3').html(offerSummary.find('h3').html());
                //         });
                //     } catch (e) {
                //         window.location.reload();
                //     }
                // }
            },

            onRender: function () {
                var me = this;

                me.stickit();

                window.page = 'campaign';

                App.currentOffer = this.model;


                me.srcReplacer();
                return;

                console.log(App.currentCompany.getSubscriptionPlans().getCurrent().attributes.type);

                console.log(this.model);
                if (App.currentOffer.get('offer_type') !== 'link') {
                    me.$el.addClass('preview');
                    me.offerPreviewArea.show(new OfferPreview({ model: App.currentOffer }));
                    App.vent.trigger('App.layout.offer.refreshPreview');
                }

                // prepare OfferTargets collection
                App.currentOffer.getOfferTargets({
                    fetch: true,
                    onAutoCreate: function () {
                        if (_.size(App.currentOffer.get('targets')) > 0) {
                            App.currentOffer.getOfferTargets().reset(App.currentOffer.get('targets'));
                        }
                    }
                });

                $.when(App.currentOffer.getOfferTargets().promise).then(function () {
                    App.currentOffer.getOfferTargets().each(function (target) {

                    });
                    App.vent.trigger('App.displays.refresh');
                });

                // listen to OfferTarget changes and refresh display groups list on update
                me.listenTo(App.currentOffer.getOfferTargets(), 'add', _.bind(me._refreshOfferTargets, me));
                me.listenTo(App.currentOffer.getOfferTargets(), 'remove', _.bind(me._refreshOfferTargets, me));

                if (App.userContext === App.os.ROLE_CONTEXT.RETAILER) {
                    me.statusUpdate();
                }


                me.setupView();
                App.vent.trigger('App.layout.offer.refreshPreview');
                me.$el.find("[data-toggle='switch']").wrap('<div class="switch" />').parent().bootstrapSwitch();

                if (_.isNumber(me.model.get('uploadingProgress'))) {
                    me.ui.progressBar.show();
                    App.vent.trigger("App.layout.loadingMask.show");
                }

                // keey advanced options opened while on view refresh
                me._toggleAdvancedOptions(null, true);

                if(this.model.get('offer_variant') == 'brightmove'){
                    this.lockDuration(5);
                }
                if(window.open_advanced_campaign == true){
                    $('.advanced-options-link a').click();
                }
                window.budgetValue = window.budget = $('[name="budget"]').val();
                window.intensityValue = $('[name="intensity"]').val();


                setTimeout(function () {
                    $('.popover-icon').each(function(){
                        var title = $(this).attr('title');
                        var text = $(this).attr('text');
                        $(this).append('<div class="popover-wrap"><h4>'+title+'</h4>'+text+'</div>');
                    });
                }, 100);


            },

            // openTab:function(event){
            //     var me = this;
            //     $(event.currentTarget).siblings('.tab-inner-content').toggle();
            // },
            //
            // lockDuration: function(duration){
            //     this.ui.durationToggle.find('input').val(duration).prop('disabled', true);
            //     this.ui.durationToggle.find('.input-group-btn').css('cursor', 'not-allowed')
            //         .find('button').prop('disabled', true).css('background-color', '#eee');
            // },
            //
            // // sets changes in application and sends data to overwrite to server
            // _saveOffer: function (model, options) {
            //     var me = this;
            //     if (!options.xhr) {
            //
            //         // Approver hack
            //         if (!(App.userContext === App.os.ROLE_CONTEXT.APPROVER && (model.changed.display_time))) {
            //
            //             if( model.changed.name === "" )
            //                 delete model.changed.name;
            //
            //             // checks if any known element has been changed like name - title,
            //             // or audience from CreateCampaignAudience
            //                 if (!_.isUndefined(model.changed.name) ||
            //                     !_.isUndefined(model.changed.text1) ||
            //                     !_.isUndefined(model.changed.text2) ||
            //                     !_.isUndefined(model.changed.text3) ||
            //                     !_.isUndefined(model.changed.audience) ||
            //                     !_.isUndefined(model.changed.offer_variant) ||
            //                     !_.isUndefined(model.changed.offer_type) ||
            //                     !_.isUndefined(model.changed.budget) ||
            //                     !_.isUndefined(model.changed.intensity)
            //                 ) {
            //                     me.model.save();
            //                     //me.model.saveDebounced();
            //                 }
            //
            //         }
            //
            //     }
            // },
            //
            // _buyCredits: function (event) {
            //     event && event.preventDefault();
            //
            //     if (App.currentCompany.getSubscriptionPlans().getCurrent().hasFeature('creditsAquisition')) {
            //
            //         var modalView = new CreditsPurchase(),
            //             callback = function () {
            //                 if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
            //                     App.mainRegion.currentView.modalRegion.show(modalView);
            //             };
            //
            //         if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
            //             App.vent.trigger('App.layout.billingInfo.show', callback);
            //         } else {
            //             App.mainRegion.currentView.modalRegion.show(modalView);
            //         }
            //     }
            // },
            //
            // _rerender: function (model, options) {
            //     var me = this;
            //     if (options.fromChannel) {
            //         me.render();
            //     }
            // },
            //
            // startUpload: function () {
            //
            //
            //     var me = this,
            //         data,
            //         file = $('#fileToUpload')[0].files[0],
            //         uploadUrl = App.enrichApiUrl(me.model.get('uris').Files),
            //         uploadMethod = 'POST',
            //         update = false,
            //         type = file.type,
            //         allowedMimeTypes = App.currentOffer.getMediaRequirements() && _.size(App.currentOffer.getMediaRequirements().format) > 0 ? App.currentOffer.getMediaRequirements().format : me.model.supportedMimeTypes[type],
            //         allowedMimeTypesTxt = [],
            //         dimensions;
            //     if(type.includes('video'))
            //         type = 'video';
            //     else if(type.includes('image'))
            //         type = 'image';
            //     else
            //         type = 'link';
            //     me.fileType = type;
            //
            //
            //     if(file==undefined) return;
            //      console.log(uploadUrl);
            //     _.each(allowedMimeTypes, function (item, idx, arr) { allowedMimeTypesTxt.push(item.split('/')[1]) });
            //     allowedMimeTypesTxt = allowedMimeTypesTxt.join(', ');
            //
            //     /*
            //     if ($.inArray(file.type, allowedMimeTypes) === -1) {
            //         var msg = (type === 'image' ? App.trans("This offer supports only image formats: " + allowedMimeTypesTxt) : App.trans("This offer supports only video formats: " + allowedMimeTypesTxt));
            //         BootstrapDialog.alert(msg + App.trans("<br><br>File upload aborted. Try to upload different file."));
            //         return false;
            //     }
            //     */
            //
            //     var _startUpload = function () {
            //         me.ui.progressBar.show();
            //         App.vent.trigger("App.layout.loadingMask.show");
            //
            //         if (!_.isUndefined(me.model.getAttachments().at(0))) {
            //             update = true;
            //             uploadUrl = me.model.getAttachments().at(0).get('uris').Upload;
            //             uploadMethod = 'PUT';
            //         }
            //
            //
            //         me.model.set({
            //             'offer_type': type
            //         }, {xhr: {}});
            //         $.ajax({
            //             url: App.enrichApiUrl(uploadUrl),
            //             type: uploadMethod,
            //             dataType: "json",
            //             data: JSON.stringify({
            //                 name: file.name
            //             })
            //         }).done(function (data) {
            //             if (data) {
            //                 me.model.uploadFile(
            //                     file,
            //                     data.upload_method,
            //                     data.uris.Upload,
            //                     (update ? data.uris.Uploaded : data.uris.UploadedFiles),
            //                     uploadMethod,
            //                     _.bind(me.setProgressBar, me),
            //                     _.bind(me.finishUploadOnSuccess, me),
            //                     _.bind(me.finishUploadError, me),
            //                     _.bind(me.finishUploadOnMediaFileUpdated, me));
            //             } else {
            //                 console.log('error');
            //             }
            //         }).fail(function (data) {
            //             console.log('error');
            //         });
            //     };
            //
            //     // get file dimensions
            //     if (type.includes('image')) {
            //
            //         //Check whether HTML5 is supported.
            //         if (window.File && window.FileReader) {
            //
            //             var reader = new FileReader();
            //
            //             reader.readAsDataURL(file);
            //             reader.onload = function (e) {
            //
            //                 var image = new Image();
            //                 //Set the Base64 string return from FileReader as source.
            //                 image.src = e.target.result;
            //
            //                 //Validate the File Height and Width.
            //                 image.onload = function () {
            //
            //                     if (App.currentOffer.getMediaRequirements()) {
            //
            //                         //var width = App.currentOffer.getMediaRequirements().width ? parseInt(App.currentOffer.getMediaRequirements().width) : null,
            //                         //    height = App.currentOffer.getMediaRequirements().height ? parseInt(App.currentOffer.getMediaRequirements().height) : null;
            //
            //                         //if (( width && this.width !== width) || (height && this.height !== height)) {
            //                         //    BootstrapDialog.alert("Mobile offer requires " + App.currentOffer.getMediaRequirements().width
            //                          //       + "x"
            //                          //       + App.currentOffer.getMediaRequirements().height
            //                          //       + "px image. Try to upload different file.");
            //                          //   return false;
            //                         //} else {
            //                             _startUpload();
            //                         //}
            //
            //                     } else {
            //                         /*
            //                         if (this.width < 1080 || this.height < 1080) {
            //                             BootstrapDialog.alert("Offer image should have at least 1920x1080px. Try to upload differen file.");
            //                             return false;
            //                         } else {
            //                             _startUpload();
            //                         }*/
            //                         _startUpload();
            //                     }
            //                 };
            //
            //             }
            //         } else {
            //             _startUpload();
            //         }
            //     } else {
            //         _startUpload();
            //     }
            //
            //
            //
            //
            // },
            //
            // setProgressBar: function (percentComplete) {
            //     var me = this;
            //
            //     if (!me.isDestroyed) {
            //
            //         me.model.set({
            //             'uploadingProgress': percentComplete
            //         }, {xhr: {}});
            //
            //         if (me.ui.progressBarRadial) {
            //             me.ui.progressBarRadial.attr('data-progress', percentComplete);
            //         }
            //     }
            // },
            //
            // finishUploadOnSuccess: function () {
            //     var me = this;
            //
            //     if (!me.isDestroyed) {
            //         ..progressBar.hide();
            //         me.setProgressBar('0%');
            //         me.model.unset('uploadingProgress');
            //     }
            //     App.vent.trigger("App.layout.loadingMask.hide");
            // },
            //
            // finishUploadError: function () {
            //     var me = this;
            //
            //     if (!me.isDestroyed) {
            //         me.ui.progressBar.hide();
            //         me.setProgressBar('0%');
            //         me.model.unset('uploadingProgress');
            //     }
            //     App.vent.trigger("App.layout.loadingMask.hide");
            //     me.$el.find('.fileinput #fileToUpload').val('');
            //     BootstrapDialog.alert("An external error occured while processing your request. Please try again or contact Flow.City administrator.");
            // },
            //
            // finishUploadOnMediaFileUpdated: function () {
            //     var me = this,
            //         url;
            //
            //     if(typeof me.model.url === 'string')
            //         url = App.enrichApiUrl(me.model.url);
            //     else
            //         url = App.enrichApiUrl(App.currentOffer.get("uri"));
            //
            //     var _finishUpload = function() {
            //         $.ajax({
            //             url: url,
            //             type: 'PUT',
            //             dataType: "json",
            //             data: JSON.stringify({
            //                 offer_type: me.fileType,
            //                 display_time: me.model.get('display_time')
            //             })
            //         }).done(function () {
            //             if (!me.isDestroyed) {
            //                 App.vent.trigger('App.layout.offer.refreshPreview');
            //             }
            //         }).fail(function (error) {
            //             console.log('error');
            //         });
            //     };
            //
            //     if (me.model.get('offer_type') === 'video') {
            //         me.getVideoDuration(me.model.get('media_file'), _finishUpload);
            //     } else {
            //         _finishUpload();
            //     }
            // },
            //
            // getVideoDuration: function (url, callback) {
            //     var me = this,
            //         videoEl,
            //         duration,
            //         addSourceToVideo = function (element, src) {
            //             var source = document.createElement('source');
            //             source.src = src;
            //             //source.type = type;
            //             element.appendChild(source);
            //         };
            //
            //     videoEl = document.createElement('video');
            //     document.body.appendChild(videoEl);
            //     videoEl.style.visibility = "hidden";
            //     addSourceToVideo(videoEl, App.enrichApiUrl(url));
            //
            //     var t = window.setInterval(function () {
            //         if (videoEl.readyState > 0) {
            //             clearInterval(t);
            //
            //             duration = videoEl.duration;
            //             if (!_.isNaN(duration) && _.isFinite(duration)) {
            //                 console.log('Uploaded video duration:', Math.round(duration),'s');
            //                 me.model.set('display_time', Math.round(duration));
            //             }
            //             videoEl.remove();
            //             callback();
            //         }
            //     }, 500);
            // },
            //
            // onShow: function(){
            //
            //     if(  localStorage.getItem('offerOnboarding') == 1 ) {
            //         localStorage.setItem('offerOnboarding',0);
            //
            //         var t = setTimeout(function(){
            //             App.vent.trigger('App.dashboard.create.loaded');
            //         },2000);
            //     }
            // },
            //
            // setupView: function () {
            //     var me = this,
            //         status = me.model.get('status');
            //
            //     App.vent.trigger('App.displays.refresh');
            //
            //     if (App.currentOffer.isDraft()) {
            //         me.$el.find('[data-draft="true"]')
            //             .removeClass('hidden');
            //         me.$el.find('[data-draft="false"]')
            //             .addClass('hidden');
            //         me.$el.find('.offer-details')
            //             .removeClass('offer-details-grayed-out');
            //     }
            //     else{
            //         me.$el.find('[data-draft="false"]')
            //             .removeClass('hidden');
            //         me.$el.find('[data-draft="true"]')
            //             .addClass('hidden');
            //         me.$el.find('.offer-details')
            //             .addClass('offer-details-grayed-out');
            //     }
            //
            //     if (App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER) {
            //         me.$el.find('[data-draft="false"]:not([data-approver="true"])').remove();
            //         me.$el.find('[data-approver="true"]').prop('disabled', false);
            //     }
            //
            //
            //
            //     me.$el.find('.tdp:not([data-type-' + me.model.get('offer_type') + '="true"])').remove();
            //
            //     me.ui.timeSelector.TouchSpin({
            //         initval: me.model.get('display_time'),
            //         min: 5,
            //         max: 60,
            //         step: 5
            //     });
            //
            //     App.vent.trigger('App.layout.scrollbars.resize');
            //
            //     // hide loading mask if necessary
            //     App.vent.trigger("App.layout.loadingMask.hide");
            // },
            //
            // updateDisplayTime: function (event) {
            //     var me = this;
            //     me.model.set('display_time', parseInt(me.ui.timeSelector.val()));
            // },
            //
            // _revertOfferToDraft: function (event) {
            //     var me = this;
            //     //console.log(App.currentOffer.attributes.targets[0].uri); return;
            //     BootstrapDialog.confirm(App.trans("Do you really want to revert the campaign to draft? It will be unpublished from all available screens."), function (result) {
            //         if (result) {
            //
            //             $(event.currentTarget).append('<i class="fa fa-spin fa-spinner"></i>');
            //             // Show loading mask
            //             App.vent.trigger("App.layout.loadingMask.show");
            //
            //             var afterDraftify = function () {
            //                 $(event.currentTarget).find('.fa-spinner').remove();
            //                 me.model.unlockOffer();
            //                 me.setupView();
            //             };
            //
            //                 // Send back-to-draft request to the server
            //                 App.currentOffer
            //                     .set('back-to-draft', true)
            //                     .save()
            //                     .done(function (data) {
            //
            //                         if(App.currentOffer.attributes.targets !== undefined &&
            //                             App.currentOffer.attributes.targets[0] !== undefined &&
            //                             App.currentCompany.getSubscriptionPlans().getCurrent().attributes.type!=='MULTI'){
            //                                 $.ajax({
            //                                     url: App.enrichApiUrl(App.currentOffer.attributes.targets[0].uri),
            //                                     type: 'DELETE',
            //                                     dataType: "json"
            //                                 }).done(function (data) {
            //                                     afterDraftify();
            //                                 });
            //                         } else{
            //                             afterDraftify();
            //                         }
            //                     });
            //
            //         }
            //     });
            // },
            //
            // statusUpdate: function () {
            //     var me = this,
            //         currDisplayGroup,
            //         displayGroupIcon,
            //         dependingSchedulers;
            //
            //     dependingSchedulers = function (activeSchedulers) {
            //         var outputMap = [],
            //             schedulerMap = {
            //                 DbOfferScheduleDisruptions : '<b>disruptions</b>',
            //                 DbOfferScheduleWeather : '<b>weather</b>'
            //             };
            //
            //         _.each(activeSchedulers, function (active, scheduler) {
            //             if (active && !_.isUndefined(schedulerMap[scheduler])) {
            //                 outputMap.push(schedulerMap[scheduler]);
            //             }
            //         });
            //
            //         return outputMap.join(' and ') + (_.size(outputMap) > 1 ? ' schedules.' : ' schedule.');
            //
            //     };
            //
            //     $.when(App.currentOffer.getOfferTargets().promise).then(function () {
            //         me.ui.statusUpdate.empty();
            //         if (!_.isUndefined(App.currentOffer) && !me.isDestroyed) {
            //             App.currentOffer.getOfferTargets().each(function (target) {
            //
            //
            //
            //                 currDisplayGroup = App.currentUser.getDisplayGroups().get(target.get('target'));
            //                 if (!_.isUndefined(currDisplayGroup)) {
            //                     displayGroupIcon = '<div class="targets-icon ' + target.get('status') + '" style="background-image: url(' + currDisplayGroup.getIcon() + ')" title="' + currDisplayGroup.get('display_name') + ' - ' + target.get('status') + '"></div>';
            //                     switch (target.get('status')) {
            //
            //                         case 'submitted':
            //
            //                             //$('.audience-buttons').removeClass('selected').addClass('unselected');
            //                             //$('#'+currDisplayGroup.get('display_name')+'BelowButton').removeClass('unselected').addClass('selected');
            //                             me.ui.statusUpdate.append($('<div/>', {
            //                                 class: 'alert alert-warning',
            //                                 html: displayGroupIcon + 'Your campaign has been submitted for the review to <b>' + currDisplayGroup.get('display_name') + '</b> Approver'
            //                             }));
            //                             //console.log($(me.ui.offerDraftInfo).css('display'));
            //                             if (App.currentOffer.isDraft()) {
            //                                 $('.offer-draft-info').html('Processing offer..');
            //                                 $('.offer-details').css({'opacity':'0.3'});
            //                                 location.reload();
            //                             }
            //                             break;
            //
            //                         case 'accepted':
            //
            //                             me.ui.statusUpdate.append($('<div/>', {
            //                                 class: 'alert alert-success',
            //                                 html: displayGroupIcon + '<b>Well done!</b> Your offer has been approved by <b>' + currDisplayGroup.get('display_name') + '</b> Approver and it\'s ready for publication.'
            //                             }));
            //                             break;
            //
            //                         case 'rejected':
            //
            //                             me.ui.statusUpdate.append($('<div/>', {
            //                                 class: 'alert alert-danger',
            //                                 html: displayGroupIcon + 'Unfortunately your offer has been rejected by <b>' + currDisplayGroup.get('display_name') + '</b> Approver. Contact her/him for more information.' + (!_.isEmpty(target.get('rejection_reason')) ? '<br><br><b>Reason: <i>' + target.get('rejection_reason') + '</i>' : '')
            //                             }));
            //                             break;
            //
            //                         case 'paused':
            //
            //                             me.ui.statusUpdate.append($('<div/>', {
            //                                 class: 'alert alert-danger',
            //                                 html: displayGroupIcon + 'Your offer on <b>' + currDisplayGroup.get('display_name') + '</b> has been paused due to not enough credits.'
            //                             }));
            //                             break;
            //
            //                         case 'online':
            //
            //                             var comment = "";
            //
            //                             if (!_.isUndefined(target.get('_schedule-status')) && target.get('_schedule-status').is_online) {
            //                                 var buildComment = function(connective, status, status2) {
            //                                     comment = connective + " it is currently <b>" + status + "</b>. ";
            //
            //                                     if (!_.isEmpty(target.get('_schedule-status').next_change) ||
            //                                         target.get('_schedule-status').schedule_active.DbOfferScheduleDisruptions ||
            //                                         target.get('_schedule-status').schedule_active.DbOfferScheduleWeather
            //                                     ) {
            //                                         // default text
            //                                         comment += " It will be " + status2;
            //
            //                                         // next_change is available
            //                                         if (!_.isEmpty(target.get('_schedule-status').next_change)) {
            //                                             comment += " on " + moment(target.get('_schedule-status').next_change).format("D MMM YYYY, h:mm A");
            //                                         }
            //
            //                                         if (target.get('_schedule-status').schedule_active.DbOfferScheduleDisruptions ||
            //                                             target.get('_schedule-status').schedule_active.DbOfferScheduleWeather) {
            //
            //                                             if (!_.isEmpty(target.get('_schedule-status').next_change)) {
            //                                                 comment += " or later"
            //                                             }
            //
            //                                             comment += " depending on " + dependingSchedulers(target.get('_schedule-status').schedule_active);
            //                                         }
            //
            //                                     }
            //                                 };
            //
            //                                 if (!target.get('_schedule-status').is_displayed) {
            //                                     buildComment('but', 'offline', 'online');
            //                                 } else {
            //                                     buildComment('and', 'online', 'taken down');
            //                                 }
            //                             }
            //
            //                             me.ui.statusUpdate.append($('<div/>', {
            //                                 class: 'alert alert-info',
            //                                 html: displayGroupIcon + 'This offer is published on <b>' + currDisplayGroup.get('display_name') + '</b> ' + comment
            //                             }));
            //                             break;
            //                     }
            //                 }
            //             });
            //         }
            //     });
            //
            // },
            //
            // _refreshOfferTargets: function (model, options) {
            //     if (options.fromChannel) {
            //         App.vent.trigger('App.displays.refresh');
            //     }
            // },
            //
            // _zoomArtwork: function () {
            //     var me = this;
            //     if (me.model.get('offer_type') === 'image' && !_.isEmpty(me.model.get('media_file'))) {
            //         var mediaSrc = App.enrichApiUrl(me.model.get('media_file'));
            //         $.magnificPopup.open({
            //             items: {
            //                 src: mediaSrc
            //             },
            //             type: 'image'
            //         });
            //     }
            // },
            //
            //
            //
            // _openOfferTemplatesModal: function () {
            //     var modalView = new OfferTemplates();
            //     App.mainRegion.currentView.modalRegion.show(modalView);
            // },
            //
            // _onOfferVariantOptionsFocusOut: function (event) {
            //     var me = this,
            //         el = $(event.currentTarget),
            //         value = el.val();
            //
            //     // TODO only text filed supported in this version
            //
            //     if (el.is(':required') && _.isEmpty(value)) {
            //         App.os.showFieldError(el, "This field is required");
            //     } else {
            //         App.os.hideFieldError(el);
            //         me.model.setVariantOption(el.data('field'), el.val());
            //     }
            //
            // },
            //
            //
            // onDestroy: function () {
            //     App.currentOffer._advancedOpts = false;
            //     delete(App.currentOffer);
            //     App.vent.trigger("App.layout.loadingMask.hide");
            //     App.vent.trigger('App.layout.whiteBody.toggle');
            //     App.vent.trigger('App.layout.displayList.hide');
            // },
            //
            // _showSpecs: function (event) {
            //     event && event.preventDefault();
            //
            //     var url = "https://storage.googleapis.com/www.flow.city/artwork-specs/specs-" + App.currentOffer.get('offer_type') +
            //         (!_.isEmpty(App.currentOffer.get('offer_variant')) ? "-" + App.currentOffer.get('offer_variant') : '') + ".html";
            //
            //     BootstrapDialog.show({
            //         title: "Artwork specification",
            //         message: $('<div>Loading...</div>').load(url),
            //         buttons: [{
            //             label: 'Close',
            //             action: function(dialogItself){
            //                 dialogItself.close();
            //             }
            //         }]
            //     });
            //
            // },
            //
            // _toggleAdvancedOptions: function (event, checkState) {
            //     event && event.preventDefault();
            //     // commented to avoid losing data view problem when ADVANCED OPTIONS clicked
            //     // App.vent.trigger('App.currentOffer.schedule.render');
            //     var me = this;
            //     $(me.ui.animateGuideArrows).toggleClass('transformed-arrows');
            //
            //     if (!checkState) {
            //         me.ui.advancedOptions.slideToggle(function onAnimationEnd() {
            //             if (me.ui.advancedOptions.is(':visible')) {
            //                 me.$el.find('.advanced-options-link .fa').switchClass('fa-caret-down', 'fa-caret-up');
            //                 $("html, body").animate({scrollTop: $('.advanced-options-link').offset().top - $('header').height() }, 1000);
            //             } else {
            //                 me.$el.find('.advanced-options-link .fa').switchClass('fa-caret-up', 'fa-caret-down');
            //             }
            //             App.currentOffer._advancedOpts = me.ui.advancedOptions.is(':visible');
            //         });
            //     } else if (App.currentOffer && App.currentOffer._advancedOpts) {
            //         me.ui.advancedOptions.show();
            //         me.$el.find('.advanced-options-link .fa').switchClass('fa-caret-up', 'fa-caret-down');
            //     }
            // },

            srcReplacer: function(){

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        window.ngAddress = App.currentOffer.get('uri');
                        var src = window.appUrl + '/ng/dist/index.html?module=campaign&offer=' + App.currentOffer.get('uri') + '&context=' + App.userContext;
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }





        });
    });
