// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentDisplayGroup',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentDisplayGroup', 'backbone'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'tr',
            className: 'retailer-display-group',

            modelEvents: {

            },

            events: {
                'click .btn-remove': '_removeGroup'
            },

            initialize: function (options) {
                var me = this;
                me.parent = me.options.parent;
            },

            _removeGroup: function () {
                this.parent.collection.remove(this.model);
                this.model.getDepartmentConnection(this.parent.model.get('uri')).destroy();
            }


        });
    });
