// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global App,define,console,kwargs,navigator,sessionData,appurl,window,Modernizr*/
define('offiserv-common',["jquery", "underscore", "moment", "modernizr"],
    function ($, _, moment) {

        "use strict";
        var FlowCity = {},
            getMapping,
            listMappings,
            onlineStatusVar = 'FlowCityOnlineStatus';

        FlowCity.testMyFunc = function () {
            console.log("test my func is working");
        };

        FlowCity.isProduction = function () {
            return kwargs.os_env === 'prod';
        };

        FlowCity.isTesting = function () {
            return kwargs.os_env === 'test';
        };

        FlowCity.isExperimental = function () {
            return false;
        };

        FlowCity.isMobile = function () {
            return navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/);
        };

        FlowCity.isAppleMobile = function () {
            return navigator.userAgent.match(/(iPhone|iPod|iPad)/);
        };

        FlowCity.isLoggedIn = function () {
            return (!_.isUndefined(sessionData) && !_.isNull(sessionData) && !_.isUndefined(sessionData.user));
        };

        FlowCity.isAccountExpired = function () {
            return ((moment() >= moment('2014-11-27T12:00:00')) && App.currentCompany.get('standard_trial') && App.currentCompany.get('standard_trial_end') && (moment() > moment(App.currentCompany.get('standard_trial_end'), "YYYY.MM.DD")));
        }

        FlowCity.projectStatus = {
            'initiating': {
                'gauge': '12.5%'  //16.8
            },
            'planning': {
                'gauge': '37.5%' //41.8
            },
            'executing': {
                'gauge': '62.5%'  //66.8
            },
            'closing': {
                'gauge': '87.5%'
            },
            'closed': {
                'gauge': '100%'   // 97
            }
        };

        FlowCity.getProgressColor = function (progress) {

            var result;

            if (progress < 25) {
                result = "#e74c3c";
            } else if (progress >= 25 && progress < 50) {
                result = "#f39c12";
            } else if (progress >= 50 && progress < 85) {
                result = "#3498db";
            } else if (progress >= 85) {
                result = "#6bb942";
            } else {
                result = "#ffffff";
            }

            return result;
        };

        FlowCity.cutUrlParams = function (url) {
            return url.split('?')[0];
        };

        FlowCity.getGravatarImage = function (email) {
            return "https://www.gravatar.com/avatar/" + $.md5(email) + "?s=40&d=wavatar";
        };

        FlowCity.getAvatarUri = function (employee) {
            var result;
            if (employee.avatarUri !== '') {
                result = employee.avatarUri;
            } else {
                result = this.getGravatarImage(employee.email);
            }
            return result;
        };

        FlowCity.showFieldError = function (selector, msg) {

            if (typeof selector === 'string') {
                selector = $(selector);
            }

            // remove error element if any
            selector.next("span.error")
                .remove();

            // add error element
            selector.addClass('invalid')
                .after('<span class="error">' + msg + '</span>');

            // focus field
            selector.focus();
        };

        FlowCity.hideFieldError = function (selector) {
            // remove error element if any
            $(selector).removeClass('invalid')
                .next("span.error")
                .remove();

        };

        FlowCity.calcPosition = function (pos1, pos2) {
            return (pos1 + ((pos2 - pos1) / 2));
        };

        FlowCity.isValidEmail = function (email) {
            var filter = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
            return filter.test(email);
        };

        FlowCity.isValidUrl = function (url) {
            var filter = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$', "g" )
            return filter.test(url);
        }

        FlowCity.checkEmail = function (value) {
            var statusTxt;

            $.ajax({
                async: false,
                type: "GET",
                url: "/email-checker/" + value,
                complete: function (jqXHR, textStatus) {
                    statusTxt = textStatus;
                }
            });

            return (statusTxt === 'success');
        };

        FlowCity.getUserNameFromEmail = function (email) {
            var firstName = '',
                lastName = '',
                userid = email.split('@').shift().split('.'),
                returnObj;

            if (userid.length > 1) {
                firstName = userid.shift();
                lastName = userid.pop();
                lastName = lastName.charAt(0).toLocaleUpperCase() + lastName.slice(1).toLocaleLowerCase();
            } else {
                firstName = userid.shift();
                lastName = '';
            }
            firstName = firstName.charAt(0).toLocaleUpperCase() + firstName.slice(1).toLocaleLowerCase();

            returnObj = {
                firstname: firstName,
                lastname: lastName
            };

            return returnObj;
        };

        FlowCity.fitContentToParent = function () {
            $(".parent-height").each(function () {
                $(this).height($(this).parent().height() - parseInt($(this).parent().css("padding-top"), 10) - parseInt($(this).parent().css("padding-bottom"), 10) + "px");
            });

            $(".fix-to-parent").each(function () {

                var prevHeight = 0,
                    fixToParent = $(this);

                // fix
                if (fixToParent.parent().hasClass('slimScrollDiv')) {
                    fixToParent = fixToParent.parent();
                }

                // get height of all previous siblings
                fixToParent.prevAll().each(function () {
                    prevHeight += $(this).is(':visible') ? $(this).outerHeight(true) : 0;
                });

                $(this).height(fixToParent.parent().height() - prevHeight + "px")
                       .css("overflow", "hidden");

                if ($(this).hasClass('classic-scroll')) {
                    $(this).css("overflow-y", 'auto');
                }

                fixToParent.height(fixToParent.parent().height() - prevHeight + "px");

                if (!$(this).hasClass('classic-scroll')) {

                    if ($(this).hasClass('scroll-area')) {
                        $(this).perfectScrollbar({
                            suppressScrollX: true
                        });
                    } /*else {
                        $(this).slimScroll({
                            height: (fixToParent.parent().height() - prevHeight),
                            wheelStep: 10
                        });
                    }*/
                }
            });
        };

        FlowCity.onResize = {
            add: function (name, callback) {
                App.resizeFunctions.push({ target: name, func: callback });
            },

            remove: function (name) {
                App.resizeFunctions = _.reject(App.resizeFunctions, function (obj) { return obj.target === name; });
            }
        };

        FlowCity.animate = function (target, animCls, opening, callback) {

            var animEndEventNames = {
                'WebkitAnimation' : 'webkitAnimationEnd',
                'OAnimation' : 'oAnimationEnd',
                'msAnimation' : 'MSAnimationEnd',
                'animation' : 'animationend'
            },

                // animation end event name
                animEndEventName = animEndEventNames[Modernizr.prefixed('animation')],
                // support css animations
                support = Modernizr.cssanimations;

            // opening animation - hide element
            if (opening) {
                target.hide();
            }

            if (support) {
                target.addClass(animCls).on(animEndEventName, function () {
                    target
                        .off(animEndEventName)
                        .removeClass(animCls);
                    if (typeof callback === 'function') {
                        callback();
                    }
                });
            }

            if (opening) {
                target.show();
            }
        };

        FlowCity.getContentPanelSize = function () {
            var el = $("#content-panel");

            return {
                top: el.offset().top,
                left: el.offset().left,
                width: el.width(),
                height: el.height()
            };
        };

        // Focus last edited field
        FlowCity.focusLastEdited = function (view, fieldName) {

            // Focus last edited field
            if (!_.isNull(fieldName)) {

                var changedField = view.find('[name="' + fieldName + '"]');
                if (changedField !== undefined) {
                    if (changedField.hasClass("editable")) {
                        changedField.parent().next().children(":first-child").focus();
                    } else if (changedField.prop("tagName") === "SELECT" && changedField.hasClass("custom-select")) {
                        changedField.next().children(":first-child").focus();
                    } else {
                        changedField.focus();
                    }
                }
            }
        };

        FlowCity.setCursor = function (cursor) {
            if (cursor && cursor !== '') {
                $('body').addClass('cursor-' + cursor);
            } else {
                $('body').removeClass(function (index, css) {
                    return (css.match(/\bcursor-\S+/g) || []).join(' ');
                });
            }
        };

        FlowCity.setLastProject = function (projectUri) {
            $.ajax({
                url: appurl + '/data/lastProject',
                type: 'POST',
                dataType: "json",
                data: JSON.stringify({ projectUri: projectUri })
            });
        };

        FlowCity.prolongateTrial = function (network, days) {


            $.ajax({
                url: sessionData.company.uris.Prolongations,
                method: 'post',
                dataType: "json",
                data: JSON.stringify({
                    "network" : network,
                    "days" : days
                }),
                success: function (data, textStatus, jqXHR) {
                    App.currentCompany.set(data);
                    sessionData.company = data;
                    App.mainRegion.currentView.modalRegion.currentView.destroy();
                    App.msg.info(App.trans("Your account has been successfully extended for additional") + ' ' + days + ' ' + App.trans("days"), App.trans('Thank you!'));
                }
            });

            App.GA('send', 'event', 'Engagement', 'SocialShare', network, 5);
        };

        getMapping = function (tableOfPairs) {
            var result = {};
            _.each(tableOfPairs, function (item) {
                result[item[0]] = item.slice(1);
            });
            return result;
        };

        FlowCity.roles = {
            publisher: 'offer_publisher',
            creator: 'offer_creator',
            approver: 'offer_approver',
            userAdmin: 'user_admin',
            superUser: 'super_user',
            reportsViewer: 'reports_viewer',
            audienceDashboardAccess: 'audience_dashboard',
            newOfferScreen: 'new_offer_screen',
            newLoopManagement: 'new_loop_management',
            myPlayers: 'my_players'
        };

        FlowCity.COMMENT_VIEW_MODE = {
            READ_ONLY: 'read-only',
            FULL_EDIT: 'full-edit'
        };

        FlowCity.features = {
            weatherWidget: "Weather Dashboard Widget",
            timeScheduler: "Time based Scheduler",
            tflDisruptions: "Transport Disruptions Monitoring",
            customSuggestions: "Custom Suggestions for Retailers",
            genericCustomTemplates: "Generic Custom Templates",
            weatherScheduler: "Weather based Scheduler",
            tflScheduler: "Transport Disruptions based Scheduler",
            customTemplates: "Custom tailor-made Templates",
            multiBrand: "Multi Retailer Support",
            demographicsScheduler: "Demographics based Scheduler"
        };

        listMappings = {
            projectRoles:   [['pm', "Project Manager"],
                             ['member', "Team Member"],
                             ['business_member', "Business Member"],
                             ['customer_pm', "Guest Project Manager"],
                             ['customer_member', "Guest Team Member"]],
            projectStatuses:  [['initiating', "Initiating"],
                             ['planning', "Planning"],
                             ['executing', "Executing"],
                             ['closing', "Closing"],
                             ['closed', "Closed"]],
            projectHealth:  [['green', "Green"],
                             ['yellow', "Yellow"],
                             ['red', "Red"]],
            displayGroupStatuses:   [
                             ['draft', "Draft"],
                             ['submitted', "Submitted"],
                             ['rejected', "Rejected"],
                             ['accepted', "Accepted"],
                             ['paused', "Paused"],
                             ['online', "Published"]
            ]

        };

        FlowCity.dicts = {};
        FlowCity.lists = {};
        _.each(listMappings, function (mappingArray, mappingId) {
            FlowCity.dicts[mappingId] = getMapping(mappingArray);
            FlowCity.lists[mappingId] = mappingArray;
        });

        FlowCity.DATA_PREFIX =  '/data/';
        FlowCity.getRoutingUrl = function (dbObj) {
            var uri = null;
            if (typeof dbObj === 'string') {
                uri = dbObj;
            } else if (dbObj) {
                if (dbObj.uri !== undefined) {
                    uri = dbObj.uri;
                } else if (dbObj.get !== undefined) {
                    uri = dbObj.get('uri');
                }
            }
            if (uri && uri.length) {
                if (uri[0] === '#') {
                    uri = uri.slice(1);
                }
                if (FlowCity.DATA_PREFIX.length && uri.indexOf(FlowCity.DATA_PREFIX) === 0) {
                    uri = uri.slice(FlowCity.DATA_PREFIX.length);
                }
                if (uri[0] === '/') {
                    uri = uri.slice(1);
                }
            }
            return uri;
        };

        $.fn.scrollTo = function (target, options, callback) {
            if (typeof options === 'function' && arguments.length === 2) { callback = options; options = target; }
            var settings = $.extend({
                scrollTarget  : target,
                offsetTop     : 50,
                duration      : 500,
                easing        : 'swing'
            }, options);

            return this.each(function () {
                var scrollPane = $(this),
                    scrollTarget = (typeof settings.scrollTarget === "number") ? settings.scrollTarget : $(settings.scrollTarget),
                    scrollY = (typeof scrollTarget === "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop, 10);

                scrollPane.animate({scrollTop : scrollY }, parseInt(settings.duration, 10), settings.easing, function () {
                    if (typeof callback === 'function') { callback.call(this); }
                });
            });
        };

        FlowCity.queryParams = {};
        FlowCity.loadSearchQuery = function() {
            /** 
             * Parse all the query params into FlowCity.searchQueryParams and remove querystring from url.
             */
            var match,
                pl = /\+/g,  // Regex for replacing addition symbol with a space
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
                location = window.location,
                query = location.search.substring(1),
                cleanUrl;

            while (match = search.exec(query))
                FlowCity.queryParams[decode(match[1])] = decode(match[2]);
            
            if (FlowCity.queryParams) {
                console.log("extracted query params from query ``" + query + "``:", FlowCity.queryParams);
                cleanUrl = location.protocol + "//" + location.host + location.pathname + location.hash;
                console.log("cleaned url:", cleanUrl);
                window.history.replaceState({}, document.title, cleanUrl);
            }
        };

        FlowCity.getQueryParam = function (name) {
            return FlowCity.queryParams[name];
        };
        
        /** returns param from query and cookie (prefixed by os_param_) by that order */ 
        FlowCity.getParam = function(name) {
            return FlowCity.getQueryParam(name) || $.cookie('os_param_' + name);
        };
        
        FlowCity.decodeB64Cookie = function (cookieName) {
            try {
                var decoded = JSON.parse(atob($.cookie(cookieName) || ""));
                console.log(cookieName + ' decoded from cookie: ', decoded);
                return decoded || {};
            } catch(e) {
                return {};
            }
        };
        
        FlowCity.getABVariant = function () {
            if (typeof variant == 'undefined') {
                return 1;
            } else {
                return variant;
            }
        };
        
        FlowCity.getRegistrationCategory = function (joined) {
            var regClass = ['', '', ''];
            regClass[0] = (sessionData.user && sessionData.user.isCorpEmail ? 'corporate': 'freemail');
            if (sessionData.company && sessionData.company.is_gaps) {
                regClass = ['corporate', 'marketplace', 'gapps'];
            } else if (sessionData.user && sessionData.user.reg_source) {
                if (sessionData.user.reg_source != 'Regular') {
                    regClass[1] = 'social';
                    regClass[2] = sessionData.user.reg_source;
                } else {
                    regClass[1] = 'regular';
                }
            }
            if (joined) {
                return regClass.join('.');
            } 
            return regClass;
        };


        /**
         * aff is affiliate id;
         * afv is affiliate 'contract' variant, for future use; 
         * afc is for custom id, affiliate may want to use it for individual tracking
         */  
         
        FlowCity.promoParams = ['utm_source', 'utm_medium', 'utm_campaign', 'promo', 'due', 'plan_variant', 'aff', 'afv', 'afc', 'template'];

        FlowCity.getPromoParams = function(mappingFn) {
            var params = FlowCity.promoParams;
            var paramsValues = _.filter(
                    _.map(params, function (param) {
                        var paramVal = App.os.getQueryParam(param);
                        return mappingFn(param, paramVal)
                    }),
                    function _isNotEmpty(pv) { return !! pv; });
            return paramsValues;            
        };
        
        FlowCity.oAuthLogin = function (view, provider, gaTracker) {
            if (_.isUndefined(gaTracker)) { gaTracker = 'SignIn'; }
            
            var promoMappingFn = function(param, paramVal) {
                return paramVal ? [param, paramVal].join("=") : '';
            };
            
            var hash = view.$el.find("[name='to-url']").val().split('#')[1] || "";
            var paramsValues = FlowCity.getPromoParams(promoMappingFn).join("&");
            var toUrl = encodeURIComponent("/main?" + paramsValues + (!_.isEmpty(hash) ? '#' + hash : ''));

            console.log("toUrl: ", toUrl);

            var reloadFunc = function () {
                var url = appurl + "/auth/" + provider + '?to-url=' + toUrl;
                console.log("location.replace: ", url);
                window.top.location.replace(url);
            };

            if (App.GA) {
                App.GA(function(tracker) {
                    tracker.send('event', gaTracker, 'Submit', provider);
                    reloadFunc();
                });
            } else { // should never happen as App.GA stub should be in presence
                reloadFunc();
            }
        };
        
        FlowCity.xargs = function(Handlebars, templateHtml, values) {
            var hbsTemplate = Handlebars.compile(templateHtml);
            return hbsTemplate(values);
        };

        FlowCity.getUserLang = function () {
            return !_.isNull(App.currentUser.get('lang')) ? App.currentUser.get('lang') : 'en';
        };

        FlowCity.closeTutorial = function() {
            var tutorialBody = $('.tutorial-screen');
            tutorialBody.fadeOut(function () {tutorialBody.remove();});
        };

        FlowCity.showTutorial = function(tutorialClsName, callback) {

            var tutorialBody = $('<div><div class="buttons"></div></div>')
                    .addClass('tutorial-screen')
                    .addClass(tutorialClsName)
                    .addClass(App.os.getUserLang()),

                closeBtn = $('<button class="btn btn-lg btn-primary">' + App.trans("Start planning") + '</button>')
                    .click(FlowCity.closeTutorial);

            console.log('current lang = %s', App.os.getUserLang());

            if (!_.isNull(callback)) {
                if (_.isString(callback) && callback === 'close') {
                    tutorialBody.find('.buttons').append(closeBtn);
                }
            }

            tutorialBody
                .appendTo("body")
                .fadeIn();

        };


        FlowCity.forceReload = function(toUrl) {
            console.log("App.os.forceReload redirect to: ", toUrl);
            toUrl = toUrl || '';
            if (toUrl.indexOf('/#') >= 0) {
                window.location.href = toUrl; 
                window.location.reload();
            } else {
                window.location.assign(toUrl);
            }
        };

        FlowCity.handleDropDownMouseOut = function (evObj) {

            var $this = $(this),
                handler = this;

            if (!$this.parent().hasClass('open')) {
                return true;
            }

            $this.parent().on('mouseleave', function () {
                var el = $(this);
                handler.timer = setTimeout(function () { el.removeClass('open'); }, 100);
            });

            $this.parent().on('mouseenter', function () {
                if (handler.timer) {
                    clearTimeout(handler.timer);
                    delete handler.timer;
                }
            });
        };

        FlowCity.authDisplay = function (name, apiKey) {

            var gcd = function(a, b) {
                        if ( ! b) {
                            return a;
                        }
                        return gcd(b, a % b);
                },
                displayData,
                screenParams,
                headers = {};

            headers.Key = apiKey;

            if (!App.os.getLocalStorageKey('_flowcity-screen-name')) {
                // for demo station mode or proxy mode when no display defined

                if (_.isUndefined(name) && _.isUndefined(apiKey)) {
                    return false;
                }

                // Get screen configuration
                try {
                    screenParams = {
                        width: screen.width,
                        height: screen.height,
                        aspectRatio: (screen.width / gcd(screen.width, screen.height)) + ":" + (screen.height / gcd(screen.width, screen.height))
                    };
                } catch (e) {
                    ;
                }

                displayData = {
                    'name': name,
                    'aspect_ratio': screenParams.aspectRatio,
                    'width': screenParams.width,
                    'height': screenParams.height
                };
            } else {
                displayData = {
                    'name': name || App.os.getLocalStorageKey('_flowcity-screen-name'),
                    'aspect_ratio': ''
                };
            }

            // Sign in user using ajax request
            $.ajax({
                url: '/data/displays',
                type: 'POST',
                dataType: 'json',
                headers: headers,
                data: JSON.stringify(displayData),

                success: function () {

                    // save screen name for future usage
                    App.os.setLocalStorageKey('_flowcity-screen-name', displayData.name);
                    
                    App.os.initializeTabSession('X-FlowCity-Session-Display', true);

                    // redirect browser
                    Backbone.history.stop();
                    window.location.href = '/display';
                },

                error: function () {
                    console.log('error');
                    $('.login-error').show();
                }
            });
        };

        FlowCity.getLocalStorageKey = function (keyName, isObject) {
            if (typeof localStorage !== 'undefined') {
                if (localStorage.getItem(keyName)) {
                    return !_.isUndefined(isObject) ? JSON.parse(localStorage.getItem(keyName)) : localStorage.getItem(keyName) ;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };

        FlowCity.setLocalStorageKey = function (keyName, value, isObject) {
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem(keyName, !_.isUndefined(isObject) ? JSON.stringify(value) : value);
            }
        };

        FlowCity.removeLocalStorageKey = function (keyName) {
            if (typeof localStorage !== 'undefined') {
                localStorage.removeItem(keyName);
            }
        };

        FlowCity.sendNoOffers = function () {
            $.ajax({
                url: App.currentDisplay.get('uris').NoOffers,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({})
            });
        };

        FlowCity.pingerFunc = function () {
            console.debug('Sending ping...');
            $.ajax({
                url: App.currentDisplay.get('uris').Ping,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({}),
                success: function (data, textStatus, jqXHR) {
                    App.vent.trigger("App.online");
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    App.vent.trigger("App.offline");
                }
            });

            // run ping once again
            App.os.sendDisplayHeartbeat();

            // verify Channel API Ping
            App.os.verifyChannelPing();
        };

        FlowCity.sendDisplayHeartbeat = function (timer) {

            if (!_.isUndefined(App.currentDisplay) && !_.isUndefined(App.currentDisplay.get('uris'))) {
                if (App.pinger) { clearTimeout(App.pinger);}
                App.pinger = setTimeout(FlowCity.pingerFunc, (_.isUndefined(timer) ? (!_.isUndefined(sessionData) ? sessionData.pingPeriod : App.settings.pingPeriod) : timer) * 1000);
            }
        };

        FlowCity.verifyChannelPing = function () {
            var ping,
                fromLastPing;

            if (App.channelPing) {
                ping = App.channelPing.at(0);
                if (_.isUndefined(ping)) {
                    console.debug('No Channel API Ping detected - restarting');
                    Backbone.history.stop();
                    window.location.reload();
                } else {
                    fromLastPing = moment.utc().diff(moment.utc(App.channelPing.at(0).get('timestamp')), 'seconds');

                    if (fromLastPing >= ((!_.isUndefined(sessionData) ? sessionData.pingPeriod : App.settings.pingPeriod) * 5)) {
                        App.channelErrCount++;
                        console.log('Last Channel API Ping over ' + fromLastPing + ' seconds ago');
                    }
                }
            }

        };

        FlowCity.sendCommand = function (uri, command, attrs) {

            var data = _.isObject(attrs) ? attrs : {};
            data.command = command;

            $.ajax({
                url: uri,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify(data)
            });
        };

        FlowCity.isCommandUri = function (uri) {
            var patt = /\/data\/displays\/([0-9]*)\/control/g;
            return patt.test(uri);
        };

        FlowCity.proxyAddr = function (url) {
            if (App.settings.proxy) {
                url = (!(new RegExp('^(http(s)?[:]//)','i')).test(url) ? App.settings.proxyUrl : '') + url;
            }
            return url;
        };

        FlowCity.cacheRemoveAsset = function (target, callback) {
            var offerDir = _.isObject(target) ? target.get('uri').substring(1) : (_.isString(target) ? target.substring(1) : null);
            if (!_.isNull(offerDir)) {
                // Delete stored files
                if (App.settings.preloading) {
                    App.Preloader.removeAssetDir(offerDir, callback);
                }

                // Remove model from LocalStorage
                App.os.removeLocalStorageKey(offerDir);
            }
        };

        /* limit maximum concurrent calls of the function;
         * remaining calls will be queued until previous one resolve promise.
         */
        _.limitConcurrent = function(limit, limitedFunc) {
            var queue = [];            
            return function() {
              var args = _.map(arguments, _.identity),
                  deferred = $.Deferred(),
                  queueSize = _.size(queue),
                  waitingFor = (queueSize >= limit ? queue.shift() : $.Deferred().resolve());
              queue.push(deferred);
              $.when(waitingFor).always(function() {
                  try {
                      console.log('Launching _.limitConcurrent function for ' + (args || [''])[0]);
                      limitedFunc.apply(this, [deferred].concat(args)); 
                  } catch (e) {
                      deferred.reject();
                  }
              });
              return deferred.promise();
            };
        };
        
        // precache template
        // it is rate-limited to 4 pending calls to gently handle 6 concurrent requests hardlimit of Chrome (leave space for ping request)
        FlowCity._saveAsset = _.limitConcurrent(4,
            function(deferred, fileKey, url) {
                if (!_.isUndefined(url)) {
                    App.Preloader.saveAsset(
                        fileKey, url,
                        function () {
                            deferred.resolve(); // signals to _.limitConcurrent that the call is done
                        },
                        function () {
                            console.log('Error retrieving asset...');
                            deferred.reject();
                        }
                    );
                } else {
                    console.log('Error retrieving asset...');
                    deferred.reject();
                }
            }
        );
        
        FlowCity.cacheDownloadAsset = function (model) {
            var offerDir = model.get('uri').substring(1),
                mediaFileKey = offerDir + '/media-file',
                templateFileKey = offerDir + '/template-file',
                lsModel = App.os.getLocalStorageKey(offerDir, true),
                filesObj = {};

            filesObj[mediaFileKey] = { url: model.get('media_file') };
            filesObj[templateFileKey] = {
                url: model.get('source'),
                callback: function () {
                    App.Preloader.getAsset(
                        templateFileKey,
                        function (templateHTML) {
                            model.set('_templateHTML', templateHTML);
                        },
                        function (e) {
                            console.log('Error loading template...')
                        }
                    );
                }
            };

            var assetsNotExist = function () {
                var index = 0,
                    deferreds = [];

                console.debug(offerDir, ' - offer cache is incomplete');

                var _downloadFunc = function (asset, file) {
                    var _deferred = $.Deferred();

                    $.when(FlowCity._saveAsset(asset, App.os.proxyAddr(file.url))).done(
                        function () {
                            console.debug('Downloaded: ', file.url);
                            file.callback && _.isFunction(file.callback) && file.callback();
                            _deferred.resolve();
                        }
                    ).fail(
                        function () {
                            console.debug('Download failed: ', file.url);
                            _deferred.reject();
                        }
                    );

                    return _deferred;
                };

                _.each(filesObj, function (file, index) {
                    deferreds.push(_downloadFunc(index, file));
                });

                $.when.apply(null, deferreds).done(
                    function () {
                        console.debug('### OFFER CACHED ###');
                        model.set('_downloaded', true);
                        App.currentDisplay.getPlayloopOffers().add(model);
                    }
                ).fail(
                    function () {
                        console.debug('### OFFER CACHE ERROR !!! ###');
                        model.unset('_downloaded');
                        setTimeout(assetsNotExist, 20000);
                    }
                );
            };

            var preloadAssets = function () {
                if (App.settings.preloading) {
                    App.Preloader.checkAssets(
                        offerDir,
                        filesObj,
                        function assetsExist () {
                            console.debug(offerDir, ' - all offer files exist');

                            // precache template
                            App.Preloader.getAsset(
                                templateFileKey,
                                function (templateHTML) {
                                    model.set('_templateHTML', templateHTML);
                                    model.set('_downloaded', true);
                                    App.currentDisplay.getPlayloopOffers().add(model);
                                },
                                function (e) {
                                    console.log('Error loading template...')
                                }
                            );
                        },
                        assetsNotExist
                    );
                } else {
                    App.currentDisplay.getPlayloopOffers().add(model);
                }
            };


            // new offer or updated media file?
            if (!lsModel) {
                App.os.setLocalStorageKey(offerDir, model, true);
                preloadAssets();
            } else if (lsModel.media_file_last_updated !== model.get('media_file_last_updated')) {
                // Remove old files
                App.os.cacheRemoveAsset(lsModel.uri, preloadAssets);

                // Save new model in LocalStorage
                App.os.setLocalStorageKey(offerDir, model, true);
            } else {
                preloadAssets();
            }

        };

        FlowCity.initOnlineStatus = function () {
            if (!App.os.getLocalStorageKey(onlineStatusVar)) {
                console.warn('First run of the Display App - setting online status');
                App.os.setLocalStorageKey(onlineStatusVar, 'online');
            }
        };

        FlowCity.getOfferContent = function (offer) {

            if (offer.isDownloaded()) {

                var offlineView,
                    offerDir = offer.get('uri').substring(1),
                    mediaFile = App.Preloader.getAssetUrl(offerDir + '/media-file'),
                    template = offer.get('_templateHTML');


                if (!_.isUndefined(template) && !_.isUndefined(mediaFile)) {
                    template = template.replace("{{media_file}}", mediaFile);
                    template = template.replace("{{ media_file }}", mediaFile);
                    return ['srcdoc', template];
                } else {
                    if (App.os.isAppOnline()) {
                        App.os.sendDisplayHeartbeat();
                        return ['src', (App.settings.proxy ? App.settings.proxyUrl : '') + offer.get('uris').Render];
                    } else {
                        //App.vent.trigger("App.display.changeOffer");
                        return ['src', App.os.proxyAddr('/static/html/noOffer.html')];
                    }
                }
            } else {
                if (App.os.isAppOnline()) {
                    if (offer.get('offer_type') === 'link') {
                        return ['src', offer.get('source')];
                    } else {
                        App.os.sendDisplayHeartbeat();
                        // restart download in case of failure
                        if (!offer.get('_downloading')) {
                            App.os.cacheDownloadAsset(offer);
                        }

                        return ['src', (App.settings.proxy ? App.settings.proxyUrl : '') + offer.get('uris').Render];
                    }
                } else {
                    //App.vent.trigger("App.display.changeOffer");
                    return ['src', App.os.proxyAddr('/static/html/noOffer.html')];
                }
            }
        };

        FlowCity.isAppOnline = function () {
            return App.os.getLocalStorageKey(onlineStatusVar) === 'online';
        };

        FlowCity.setOnlineStatus = function (isOnline) {
            var status = App.os.getLocalStorageKey(onlineStatusVar);

            if (isOnline) {
                if (!status || status === 'offline') {
                    console.warn('== ONLINE ==');
                    App.os.setLocalStorageKey(onlineStatusVar, 'online');
                    App.os.sendDisplayHeartbeat(0); // Send heartbeat immediately

                    // refetch all objects from server
                    $.when(App.currentDisplay.fetch()).then(function () {
                        App.os.syncOffersAfterFetch(App.currentDisplay.getOffers(), App.currentDisplay.get('offers'));
                        App.currentDisplay.getOffers().reset(App.currentDisplay.get('offers'));
                    });

                }
            } else {
                if (!status || status === 'online') {
                    console.warn('== OFFLINE ==');
                    App.os.setLocalStorageKey('DisplayConfig', App.currentDisplay, true);
                    if (_.size(App.currentDisplay.getOffers({fetch: false})) > 0) { // prevent reset of the offers collection
                        App.os.setLocalStorageKey('DisplayOffers', App.currentDisplay.getOffers(), true);
                    }
                    App.os.setLocalStorageKey(onlineStatusVar, 'offline');
                }
            }
        };

        FlowCity.syncOffersAfterFetch = function (oldOfferCollection, newOfferCollection) {
            var offersToRemove = _.difference(_.pluck(oldOfferCollection.models, 'id'), _.pluck(newOfferCollection, 'uri'));
            _.each(offersToRemove, function (offerUri) {
                _.delay(function () { App.os.cacheRemoveAsset(offerUri); }, 0);
            });
        };

        // local Node heartbeat
        FlowCity.nodePingerFunc = function () {
            $.ajax({
                url: App.settings.nodePingUri,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({})
            });
            App.os.sendNodeHeartbeat();
        };

        FlowCity.sendNodeHeartbeat = function () {

            if (App.settings.proxy) {
                if (App.nodePinger) { clearTimeout(App.nodePinger);}
                App.nodePinger = setTimeout(FlowCity.nodePingerFunc, App.settings.nodePingTime * 1000);
            }
        };

        FlowCity.ROLE_CONTEXT = {
            RETAILER: 'retailer',
            APPROVER: 'approver'
        };
        
        FlowCity.initializeCookieSession = function (headerKey, forceInit) {
            // copy per-tab session to cookie to ensure page reload works as desired.
            var tabSession = window.sessionStorage.getItem(headerKey),
                cookieSession = $.cookie(headerKey),
                shouldInit = !cookieSession || !!forceInit;
            
            if (tabSession && shouldInit) {
                var initReason = !!forceInit ? 'forceInit flag' : 'there was no session cookie';
                console.log('Initializing cookie session ' + headerKey + ' because ' + initReason + 'to: '+ tabSession);
                $.cookie(headerKey, tabSession, { expires: 7*500, path: '/' }) // 7*500 is 500 weeks, the same as server sets.
            }
        }
        
        FlowCity.initializeTabSession = function (headerKey, forceInit) {
            // add per-tab header to ajax request to recognize real os-session (multiple tabs conflict)
            if (! window.sessionStorage.getItem(headerKey) || !! forceInit) {
                var session = $.cookie(headerKey);
                var initText = !!forceInit ? 'forced init' : 'has not been set yet';
                if (session) {
                    console.warn('Initializing tab session for ' + headerKey + ' (' + initText + ') to: ' + session);
                    window.sessionStorage.setItem(headerKey, session);
                } else {
                    console.warn('No session cookie - could not set tab session for ' + headerKey);
                }
            }
            $(document).ajaxSend(function( event, jqxhr, settings ) {
                if (settings.includeSessHeader === false) {
                    console.warn('Sending AJAX request without ' + headerKey + ' session header ');
                } else {
                    if (window.sessionStorage.getItem('auth-token')) {
                      jqxhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionStorage.getItem('auth-token'));
                    } else {
                      jqxhr.setRequestHeader(headerKey, window.sessionStorage.getItem(headerKey) || '');
                    }
                }
            });
        };
        
        FlowCity.clearTabSession = function (headerKey) {
            console.warn('Clearing tab session for ' + headerKey + ' (was: ' + window.sessionStorage.getItem(headerKey) + ')');
            window.sessionStorage.removeItem(headerKey);
        };

        FlowCity.EXTERNAL_CONSUMER_TYPE = ['mobile'];

        return FlowCity;

    });

