// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/userDisplayName',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function userDisplayName(user) {

        if (user.firstname || user.lastname) {
            return user.firstname + ' ' + user.lastname;
        } else {
            return user.email
        }
    }

    Handlebars.registerHelper('userDisplayName', userDisplayName);
    return userDisplayName;
});
