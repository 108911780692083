// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/PublicTransportDisruption',["App", "backbone"],
    function (App, Backbone) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var PublicTransportDisruption = Backbone.Model.extend({
            uriStencil: 'public-transport-disruptions'
        });

        return PublicTransportDisruption;
    }
);
