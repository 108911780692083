
/* START_TEMPLATE */
define('hbs!templates/views/Profile/ProfileBillingInfo',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"detailed white-panel tabs-left\">\n    <div class=\"col-lg-12\">\n        <p>For billing purposes only. To change your campaign location go to location settings. All fields required.</p>\n\n        <span class=\"update-status\"></span>\n\n        <form role=\"form\" class=\"form-horizontal mt billing-info-body\">\n\n                <div class=\"form-group\" data-permission=\"bill_comp_fiel\">\n                    <label for=\"companyName\" class=\"col-sm-2 control-label text-right\">Company</label>\n                    <input type=\"text\" class=\"form-control\" id=\"companyName\" name=\"company_name\" value=\"";
  if (helper = helpers.company_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.company_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Company name\">\n                </div>\n\n                <div class=\"form-group\" data-permission=\"bill_firs_fiel\">\n                    <label for=\"firstName\" class=\"col-sm-2 control-label text-right\">First name</label>\n                    <input type=\"text\" class=\"form-control\" id=\"firstName\" name=\"first_name\" value=\"";
  if (helper = helpers.first_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.first_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"First name\">\n                </div>\n\n                <div class=\"form-group\" data-permission=\"bill_last_fiel\">\n                    <label for=\"lastName\" class=\"col-sm-2 control-label text-right\">Last name</label>\n                    <input type=\"text\" class=\"form-control\" id=\"lastName\" name=\"last_name\" value=\"";
  if (helper = helpers.last_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.last_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Last name\">\n                </div>\n\n                <div class=\"form-group\" data-permission=\"bill_vati_fiel\" style=\"width: 45%\">\n                    <label for=\"billingVATID\" class=\"col-sm-2 control-label text-right\">VAT ID</label>\n                    <input type=\"text\" class=\"form-control\" id=\"billingVATID\" name=\"billing_taxid\" placeholder=\"VAT ID\" value=\"";
  if (helper = helpers.billing_taxid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.billing_taxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n\n                <div class=\"form-group\" data-permission=\"bill_1add_fiel\">\n                    <label for=\"billingAddress1\" class=\"col-sm-2 control-label text-right\">Address</label>\n                    <input type=\"text\" class=\"form-control\" id=\"billingAddress1\" name=\"billing_address1\" placeholder=\"Address line 1\" value=\"";
  if (helper = helpers.billing_address1) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.billing_address1); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n\n                <!--<div class=\"form-group\" data-permission=\"bill_2add_fiel\">-->\n                    <!--<label for=\"billingAddress2\" class=\"col-sm-2 control-label text-right\"></label>-->\n                    <!--<input type=\"text\" class=\"form-control\" id=\"billingAddress2\" name=\"billing_address2\" placeholder=\"Address line 2\" value=\"";
  if (helper = helpers.billing_address2) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.billing_address2); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">-->\n                <!--</div>-->\n\n                <div class=\"row no-margin\">\n                    <div class=\"form-group col-sm-4\" data-permission=\"bill_post_fiel\" style=\"padding-left: 0;\">\n                        <label for=\"billingPostCode\" class=\"col-sm-2 control-label text-right\">Post code</label>\n                        <input type=\"text\" class=\"form-control\" id=\"billingPostCode\" name=\"billing_postcode\" placeholder=\"Post code\" value=\"";
  if (helper = helpers.billing_postcode) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.billing_postcode); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                    </div>\n\n                    <div class=\"form-group col-sm-8\" data-permission=\"bill_city_fiel\" style=\"padding: 0;\">\n                        <label for=\"billingCity\" class=\"col-sm-2 control-label text-right\">City</label>\n                        <input type=\"text\" class=\"form-control\" id=\"billingCity\" name=\"billing_town\" placeholder=\"City\" value=\"";
  if (helper = helpers.billing_town) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.billing_town); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                    </div>\n                </div>\n\n                <!--<div class=\"form-group\" data-permission=\"bill_coun_drop\">-->\n                    <!--<label for=\"countryCode\" class=\"col-sm-2 control-label text-right\">Country</label>-->\n                    <!--<div id=\"countryCode\" class=\"dropup\" data-input-name=\"country_code\" data-button-size=\"btn-md\" data-button-type=\"btn-default\" data-scrollable-height=\"250px\" data-scrollable=\"true\"></div>-->\n                <!--</div>-->\n                <div class=\"form-group\" data-permission=\"user_phon_fiel\">\n                    <label class=\"col-lg-2 control-label\">Phone No.</label>\n\n                    <div>\n                        <input type=\"text\" class=\"form-control editable\" name=\"phone\" value=\"";
  if (helper = helpers.phone) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.phone); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Phone number\" style=\"max-width: 300px;\">\n                    </div>\n                </div>\n\n\n            <div class=\"form-group hidden\" data-permission=\"bill_save_btn\">\n                <div class=\"col-lg-offset-2 col-lg-10\">\n                    <button type=\"button\" class=\"btn btn-theme save-billing-info-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">Save</span></button>\n                </div>\n            </div>\n\n        </form>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
