// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/capitalize',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    Handlebars.registerHelper('capitalize', capitalize);
    return capitalize;
});
