
/* START_TEMPLATE */
define('hbs!templates/views/Reports/OverviewHeader',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<li class=\"report report-header overview-overlap\">\n    <div class=\"checkbox checkbox-info checkbox-inline\" style=\"width: 50px;\">\n        <input type=\"checkbox\" name=\"check_report\" data-field=\"check_report\" value=\"1\"><label></label>\n    </div>\n    <div class=\"overview-campaign-name\" id=\"sort-campaign-name\">\n        Campaign Name <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"overview-status\" id=\"sort-status\">\n        Status <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"overview-company-name\" id=\"sort-company-name\">\n        <span>Company Name <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></span>\n    </div>\n    <div class=\"overview-format\" id=\"sort-format\">\n        <span>Format <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></span>\n    </div>\n    <div class=\"overview-start-date\" id=\"sort-start-date\">\n        <span>Start Date <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></span>\n    </div>\n    <div class=\"overview-end-date\" id=\"sort-end-date\">\n        <span>End Date <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></span>\n    </div>\n    <div class=\"overview-impressions\" id=\"sort-impressions\">\n        <span class=\"pull-right\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i> Impressions</span>\n    </div>\n    <div class=\"overview-impact\" id=\"sort-impact\">\n        <span class=\"pull-right\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i> Impact</span>\n    </div>\n    <div class=\"overview-CPV\" id=\"sort-CPV\">\n        <span class=\"pull-right\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i> CPM*</span>\n    </div>\n    <div class=\"overview-daily-spend\" id=\"sort-daily-spend\">\n        <span class=\"pull-right\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i> Daily Spend</span>\n    </div>\n</li>";
  });
return t;
});
/* END_TEMPLATE */
;
