// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/avatar',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function avatar(avatarUri, size) {
        var size = parseInt(size) || 40;

        if (avatarUri.indexOf('http') === -1) {
            avatarUri = App.enrichApiUrl(avatarUri);
        }



        App.vent.trigger('App.avatar.loaded');
        if (avatarUri.indexOf("?") === -1) {
            return avatarUri + '?s=' + size;
        } else {
            return avatarUri + '&s=' + size;
        }

    }

    Handlebars.registerHelper('avatar', avatar);
    return avatar;
});
