// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog*/
define('views/ApplicationLayout/ApplicationHeaderMulti',['App', 'backbone', 'backbone.marionette', 'jquery', 'hbs!templates/views/ApplicationLayout/ApplicationHeaderMulti',
        'views/ApplicationLayout/TopUserInfo',
        'views/Payments/TopCreditsArea',
        'views/Profile/UserDepartmentSelector'

    ],
    function (App, Backbone, Marionette, $, template,
              TopUserInfo,
              TopCreditsArea,
              UserDepartmentSelector
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            events: {
                'click .logout-btn' : 'logout',
                'click .fa-bars' : '_toggleSidebar',
                'click .show-notifications-btn' : '_showNotifications'
            },

            regions: {
                topUserArea: '#top-user-area',
                topCreditsArea: '#top-credits-area',
                topDepartmentSelector: '#top-department-selector-area'
            },

            initialize: function () {
                var me = this;
                this.listenTo(App.vent, 'App.layout.updateTitle', _.bind(this.updatePageTitle, this));
                this.listenTo(App.vent, 'App.counter.update', _.bind(this._updateCounter, this));
                this.listenTo(App.vent, 'App.refreshStyle', _.bind(this._handleStyleRefresh, this));
                me.model = App.currentCompany;
            },

            onRender: function () {
                var me = this;
                if (App.currentCompany.isPayable()) {

                    $.when(App.currentDepartmentPromise).then(function () {
                        me.topCreditsArea.show(new TopCreditsArea({model: App.currentDepartment.useCompanyCredits() ? App.currentCompany : App.currentDepartment}));
                        me.$el.find('#top-credits-area').show();
                    });
                }
                this.topDepartmentSelector.show(new UserDepartmentSelector());
            },

            _handleStyleRefresh: function () {
                var me = this;
                $("#mainLogo").hide();
                _.delay(_.bind(this.render, this), 500)
            },

            _toggleSidebar: function (event) {
                event.preventDefault();
                App.vent.trigger('App.layout.toggleSidebar');
            },

            updatePageTitle: function (attrs) {
                var me = this;
                me.$el.find('.title-row span').html(attrs[0]);
            },

            logout: function (event) {
                event.preventDefault();
                App.vent.trigger('App.logout');
            },

            _showNotifications: function (event) {
                event.preventDefault();
                App.appRouter.navigate('notifications', { trigger: true });
            },

            _updateCounter: function (counterName, value) {
                var me = this,
                    counter = me.$el.find('.counter.' + counterName);

                counter.html(value === 0 ? '' : value);
            }


        });
    }
);
