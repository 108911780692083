// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/DisplayGroups/DisplayGroup',['App', 'jquery', 'underscore', 'hbs!templates/views/DisplayGroups/DisplayGroup', 'backbone', 'models/DisplayGroup', 'collections/Departments', 'collections/Displays', 'models/Location'],
    function (App, $, _, template, Backbone, DisplayGroup, Departments, Displays, Location) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'display-unit',

            modelEvents: {
                'change': '_save'
            },

            ui: {
                'archiveDisplayGroupBtn' : '.archive-display-group-btn',
                'displayGroupDetailsBtn' : '.show-display-group-btn',
                'loadingMask' : '.loading-mask2',
                'detailsPanel' : '.details'
            },

            events: {
                'click @ui.archiveDisplayGroupBtn' : 'archiveDisplayGroup',
                'click @ui.displayGroupDetailsBtn' : 'toggleDetails'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                var me = this;

                me.stickit();

                // keep display expanded on render
                if (me.model.get('expanded')) {
                    me.$el.find('.expanded').show();
                }

                me.$el.find('.display-name span').editable({
                    url: '',
                    pk: 1,
                    send: 'never',
                    mode: 'inline',
                    onblur: 'submit',
                    savenochange: true,
                    showbuttons: false,
                    value: me.model.get('display_name'),
                    success: function onXEditableSubTaskTitle (resp, newVal) {
                        me.$el.removeClass('xeditable-shown');
                        me.model.set('display_name', newVal);
                        if (me.model.hasChanged('display_name')) {
                            me.model.save();
                            me.render();
                        } else {
                            // for some reason the xeditable input is not properly hidden
                            // the simplest workaround is to rerender the view.
                            me.render();
                        }
                    }
                });

                if (me.model.get('expanded')) {
                    me.ui.detailsPanel.show();
                }
            },

            archiveDisplayGroup: function (event) {
                var me = this;
                event.preventDefault();
                me.model.destroy();
            },

            toggleDetails: function (event) {
                var me = this,
                    detailsPanelExpander = me.$el.find('.expanded'),
                    displayGroup = new DisplayGroup(),
                    view;

                displayGroup.url = me.model.get('uri');

                event.preventDefault();

                if (detailsPanelExpander.is( ":hidden" )) {
                    //me.ui.loadingMask && me.ui.loadingMask.show();
                    $.when(displayGroup.fetch()).then(function () {

                        // fetch additional data
                        var departments = new Departments();
                        departments.url = App.enrichApiUrl(me.model.get('uris').Departments);
                        departments._deferredFetch = $.Deferred();
                        departments.promise = departments._deferredFetch.promise();

                        var displays = new Displays();
                        displays.url = App.enrichApiUrl(me.model.get('uris').OnlineDisplays);
                        displays._deferredFetch = $.Deferred();
                        displays.promise = displays._deferredFetch.promise();

                        var location = new Location();
                        location.url = App.enrichApiUrl(me.model.get('location'));
                        location._deferredFetch = $.Deferred();
                        location.promise = location._deferredFetch.promise();


                        var render = function() {
                            me.model.set({'departments': departments.models}, {silent: true});
                            me.model.set({'onlineDisplays': displays.models}, {silent: true});
                            if (me.model.get('location')) {
                                me.model.set({'locationDetails': location}, {silent: true});
                            }

                            view = me.parent.children.findByModel(me.model);
                            me.ui.loadingMask && me.ui.loadingMask.hide();

                            detailsPanelExpander.slideDown("fast", function () { me.model.set({'expanded' : true}, {silent: true}); me.ui.detailsPanel.show(); me.render(); });
                        }

                        if (me.model.get('location')) {
                            $.when(departments.fetch(), displays.fetch(), location.fetch()).then(function () {
                                departments._deferredFetch.resolve();
                                displays._deferredFetch.resolve();
                                location._deferredFetch.resolve();
                                render();
                            });
                        } else {
                            $.when(departments.fetch(), displays.fetch()).then(function () {
                                departments._deferredFetch.resolve();
                                displays._deferredFetch.resolve();
                                render();
                            });
                        }
                    });
                } else {
                    me.model.unset('expanded', {silent: true});
                    detailsPanelExpander.hide(function () { me.ui.detailsPanel.hide(); });
                }

            },

            _save: function (model, options) {
                if (options.fromChannel) {
                    this.render();
                } else if (!options.xhr) {
                    this.model.saveDebounced(1000);
                }
            }

        });
    });
