// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentUserProfile',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentUserProfile', 'backbone'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'col-sm-4 col-lg-3',

            events: {
                'change input': '_updateProfilesList'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                ($.inArray(this.model.get('name'), this.parent.department.getUsers().get(this.parent.retailerUserUri).get('profiles_names')) > -1) && this.$el.find('input').prop('checked', true);

                // Admin workaround
                if (App.currentUser.get('uri') == this.parent.retailerUserUri && (this.model.get('name') == 'super_user' || this.model.get('name') == 'user_admin')) {
                    this.$el.find('input').prop('disabled', true);
                }
            },

            _updateProfilesList: function () {
                this.parent.triggerMethod('update:profiles');
            }
        });
    });
