// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/Profile/UserDepartmentSelector',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore',
        'hbs!templates/views/Profile/UserDepartmentSelector',
        'bootstrap-select'],
    function (App, Backbone, Marionette, $, _,
              template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            events: {
                'change' : '_changeCurrentDepartment'
            },

            initialize: function () {
                var me = this;
                me.collection = App.currentUser.getDepartments();
                $.when(me.collection.promise).then(function () {
                    me.collection.each(function (dept) {
                        me.$el.find('select').append('<option value="' + dept.get('uri') + '">' + dept.get('name') + '</option>')
                    });
                    me.$el.find('.selectpicker').selectpicker('refresh');
                    me.$el.find('.selectpicker').selectpicker('val', App.currentDepartment.get('uri'));

                    me.$el.find('.selectpicker').on('changed.bs.select', function (e) {
                        me._changeCurrentDepartment();
                    });
                });
            },

            onRender: function () {
                this.$el.find('.selectpicker').selectpicker();
            },

            _changeCurrentDepartment: function () {
                var newDept = this.$el.find('.selectpicker').val();
                console.debug('App.departments.changeCurrentDepartment', newDept);
                App.currentDepartment = App.currentUser.getDepartments().get(newDept)
                App.vent.trigger('App.departments.changeCurrentDepartment', newDept);
            }
        })

    });
