// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleBase',['App', 'jquery', 'underscore', 'backbone', 'behaviors/Intercom'],
    function (App, $, _, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            className: 'tab-pane',

            modelEvents: {
                'change': '_save'
            },

            onRender: function () {
                var me = this;
                me.$el.attr({
                    'role': 'tabpanel',
                    'id': me.model.get('type')
                });
            },

            _setRadioState: function (field, value) {
                var me = this,
                    el = me.$el.find("input[name='" + field + "'][value='" + value + "']");
                el.prop("checked", true);
                el.parent().addClass('active');
            },

            _save: function (model, options) {
                var me = this;

                if (!options.xhr) {
                    me.model.saveDebounced();
                }
            },

            _saveNow: function () {
                if (this.model._debouncedSave && !App.currentOffer.get('locked')) {
                    intercomReportEvent('offer_schedule', {
                        id: me.model.get('type')
                    });
                    this.model.save();
                }
            },

            _updateModelFromRadio: function (event) {
                var me = this,
                    el = $(event.currentTarget) || {},
                    field = el.attr('name') || '',
                    value = el.val() || '';

                me.model.set(field, value);
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            },

            destroy: function () {
                var me = this;

                // just to be sure that its saved
                if (App.currentOffer.isDraft() && me.model._debouncedSave) {
                    me.model.save();
                }
            }

        });
    });
