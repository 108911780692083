// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/iif',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function iif(field, value, options) {

        if (field === value) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    }
    Handlebars.registerHelper('iif', iif);
    return iif;
});
