// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/PasswordReset/ForgotPassword',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore', 'hbs!templates/views/PasswordReset/ForgotPassword', 'jquery-validation'],
    function (App, Backbone, Marionette, $, _, template) {
        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,
            model: new Backbone.Model(),

            events: {
                "click #std-password-reset-btn": "reset"
            },

            reset: function() {
                $.ajax({
                    url: App.enrichApiUrl('/data/password-resets'),
                    type: 'POST',
                    data: this.$el.find("#forgot-password-form").serialize(),
                    includeSessHeader: false
                }).done(function (data, status, request) {
                    window.location.href = App.appUrl + "/#password-instructions-sent"
                })
                .fail(function () {
                });
            },
            
            onRender: function () {
                var me = this;

                $('body').css('background', 'transparent');

                me.$el.find("#forgot-password-form").validate({
                    errorElement: "small",
                    errorClass: 'error',
                    onkeyup: false,
                    onfocusin: false,
                    onfocusout: function (element) {
                        $(element).valid();
                    },
                    rules: {
                        email: {
                            required: true,
                            email: true
                        }
                    },
                    messages: {
                        email: {
                            required: "Please enter a valid email address",
                            email: "Please enter a valid email address"
                        }
                    }
                });

                $.backstretch("/static/images/login_background.jpg", {speed: 500});
            }

        });
    });
