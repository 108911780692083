// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Offers/OffersNG',['App',
        'backbone',
        'hbs!templates/views/Offers/OffersNG'
        ],
    function (
        App,
        Backbone,
        template
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            onRender: function () {
                this.srcReplacer();
            },

            srcReplacer: function() {
                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var moduleName = App.userContext == App.os.ROLE_CONTEXT.APPROVER ? 'submitted-offers' : 'offers';
                        var src = window.appUrl + '/ng/dist/index.html?module=' + moduleName;
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }

        });
    });

