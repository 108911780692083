// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Offers/MultiOfferPreview',['App',
        'backbone',
        'hbs!templates/views/Offers/MultiOfferPreview',
        'views/Offers/MultiOfferPreviewItem'
        ],
    function (
        App,
        Backbone,
        template,
        MultiOfferItem
    ) {

        var EmptyView = Backbone.Marionette.ItemView.extend({
            tagName: 'li',
            className: 'add-new-item tdp',

            ui: {
                files: '[name="files"]'
            },

            events: {
                'change @ui.files': 'parseFiles'
            },

            initialize: function () {
                this.$el.attr('data-draft', 'true');
                this.$el.attr('data-type-multi', 'true');
            },

            parseFiles: function() {
                var me = this,
                    filesToUpload = [];
                Array.prototype.forEach.call($(me.ui.files)[0].files, function(file) {
                    filesToUpload.push(file);
                });
                this.$el.find('.fileinput [name="files"]').val('');
                App.vent.trigger('App.layout.multiOffer.addItems', filesToUpload);
            },

            getTemplate: function () {
                var templateHtml = '<div class="inner">' +
                    '<div class="fileinput-new" style="position: relative;">\n' +
                    '                <a class="btn-file">\n' +
                    '                    <span class="fileinput-new"><i class="fa fa-plus-circle"></i></span>\n' +
                    '                    <span class="fileinput-exists">Uploading...</span><input type="file" id="fileToUpload" multiple accept=".jpg,.jpeg,.png,.mp4,.mov,.webm" name="files">\n' +
                    '                </a>\n' +
                    '        </div>' +
                    '</div>',
                    template = Handlebars.compile(templateHtml);
                return template;
            }

        });



        'use strict';
        return Backbone.Marionette.SortedCompositeView.extend({
            template: template,

            className: 'multi-offer-preview',

            childView: MultiOfferItem,

            childViewContainer: '.items',

            filesToUpload: [],
            maxUploads: 4,
            pendingFiles: 0,

            ui: {
                items: '.items'
            },

            initialize: function () {
                this.collection = new Backbone.Collection();
                this.model.set('_multi_offer_artworks', this.model.get('multi_offer_artworks'));
                this.collection.reset(this.model.get('_multi_offer_artworks'));
                this.listenTo(App.vent, 'App.layout.multiOffer.removeItem', _.bind(this._removeItem, this));
                this.listenTo(App.vent, 'App.layout.multiOffer.addItems', _.bind(this._addItems, this));
            },

            _addItems: function (items) {
                this.filesToUpload = this.filesToUpload.concat(items);
                this.startUpload();
            },


            emptyView: function () {
                return new EmptyView();
            },

            onRender: function () {
                var me = this;

                if (App.currentOffer.isDraft()) {
                    me.$el.find('[data-draft="true"]')
                        .prop('disabled', false)
                        .removeClass('hidden');
                    me.$el.find('[data-draft="false"]').remove();

                    me.ui.items.sortable({
                        revert: true,
                        helper: "clone",
                        placeholder: "ghost-item",
                        //containment: "body",
                        opacity: 0.8,
                        appendTo: "parent",
                        items: 'li.multi-offer-item',

                        update: function (event, ui) {
                            console.log('dropped');
                            me._updatePosition(ui);
                        }
                    });
                }
            },

            _updatePosition: function (obj) {
                App.vent.trigger("App.layout.loadingMask.show");
                var me = this,
                    artworkList = [];
                console.log(obj);

                _.each(this.$el.find('.multi-offer-item'), function(item) {
                    $(item).data('id') && artworkList.push($(item).data('id'));
                });
                me.model.set('multi_offer_artworks', artworkList);
                console.log(artworkList);
                me.model.save({}, {
                    success: function() { App.vent.trigger("App.layout.loadingMask.hide"); }
                });
            },

            startUpload: function () {
                var me = this,
                    uploadUrl = App.enrichApiUrl(me.model.get('uris').Files),
                    uploadMethod = 'POST';

                App.vent.trigger("App.layout.loadingMask.show");

                var _startUpload = function (file) {
                    me.maxUploads--;
                    me.pendingFiles++;

                    me.collection.add(file);

                    $.ajax({
                        url: App.enrichApiUrl(uploadUrl),
                        type: uploadMethod,
                        dataType: "json",
                        data: JSON.stringify({
                            name: file.name
                        })
                    }).done(function (data) {
                        if (data) {
                            me.model.uploadFile(
                                file,
                                data.upload_method,
                                data.uris.Upload,
                                data.uris.UploadedFiles,
                                uploadMethod,
                                _.bind(me.setProgressBar, me),
                                _.bind(me.finishUploadOnSuccess, me),
                                _.bind(me.finishUploadError, me),
                                _.bind(function() {}, me));
                        } else {
                            console.log('error');
                        }
                    }).fail(function (data) {
                        console.log('error');
                    });
                };

                if (me.maxUploads - 1 >= 0) {
                    var file = me.filesToUpload.shift();
                    if (file) {
                        file._uid = new Date().valueOf();
                        _startUpload(file);
                        me.startUpload();
                    } else {
                        if (this.pendingFiles === 0) {
                            me.model.save({}, {
                                success: function() { App.vent.trigger("App.layout.loadingMask.hide"); }
                            });
                        }

                    }

                }

            },

            setProgressBar: function (percentComplete, file) {
                var me = this,
                    fileModel = this.collection.find(function(m) { return m.get('_uid') === file._uid; });

                console.log(file._uid, percentComplete);
                fileModel.set('uploadingProgress', percentComplete);
            },

            /**
             * Upload successfully finished. Post upload logic.
             * @param data DbWorkspace file content
             * @param file initial data received from file input
             */
            finishUploadOnSuccess: function (data, file) {
                console.log('finished', data, file);

                // Update local collection
                var idx = this.collection.indexOf(this.collection.find(function(m) { return m.get('_uid') === file._uid; }));
                this.collection.remove(this.collection.at(idx));
                this.collection.add(data, {at: idx});
                this.waitForThumbnail(idx);
                this.updateArtworksList();
                this.pendingFiles--;
                console.debug(this.model.get('multi_offer_artworks'));
                this.maxUploads++;
                this.startUpload();
            },

            waitForThumbnail: function(artworkId) {
                var me = this,
                    wait = 5000,
                    max_retry = 5;

                var checkThumbnailReadiness = function() {
                  max_retry--;
                  var offerFile = me.collection.at(artworkId);

                  offerFile.fetch({
                      success: function(data) {
                          if (data.get('thumbnail')) {
                              console.log('Thumbnail ready!');
                              me.collection.remove(me.collection.at(artworkId));
                              me.collection.add(data, {at: artworkId});
                          } else if (!data.get('thumbnail') && max_retry > 0) {
                              console.log('No thumbnail - retrying ' + max_retry);
                              setTimeout(function() { checkThumbnailReadiness(); }, wait);
                          } else {
                              console.log('No thumbnail - timeout');
                          }
                      }
                  });

                };

                checkThumbnailReadiness();
            },

            updateArtworksList: function () {
                var me = this,
                    artworkList = [];
                this.collection.each(function(model) {
                    model.get('uri') && artworkList.push(model.get('uri'));
                });
                me.model.set('multi_offer_artworks', artworkList);
            },

            finishUploadError: function () {
                var me = this;
                BootstrapDialog.alert("An external error occurred while processing your request. Please try again or contact Flow.City administrator.");
            },

            _removeItem: function (item) {
                this.collection.remove(item);
                this.updateArtworksList();
                this.model.save();
            },

            onRenderCollection: function() {
                this.addChild(null, EmptyView, _.size(this.collection));
            },

            onAddChild: function() {
                var me = this;

                console.log("li", me.$el.find('.items li').length);
                if (me.$el.find('.items li').length >= 1) {
                    console.log("li.add-new-item", me.$el.find('.items li.add-new-item').length);
                    if (me.$el.find('.items li.add-new-item').length === 0) {
                        me.addChild(null, EmptyView, _.size(me.collection));
                    }
                }

            },

            onRemoveChild: function() {
                var me = this;
                if (_.size(this.collection) === 0) {
                    this.render();
                }
            }
        });
    });

