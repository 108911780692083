// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/Profile/ProfilePasswordChange',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore',
        'hbs!templates/views/Profile/ProfilePasswordChange',
        'ladda-bootstrap',
        'jquery-validation'],
    function (App, Backbone, Marionette, $, _,
              template,
              Ladda) {
        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            //className: 'form-panel profile-form row',

            ui: {
                newPassword: 'input[name="password"]',
                retypedPassword: 'input[name="passwordRetyped"]',
                updateStatus: '.update-status'
            },

            bindings: {
                '[name="password"]': 'password',
                '[name="passwordRetyped"]': 'passwordRetyped'
            },

            events: {
                "click .btn.set-password" : "setPassword",
                "keyup [type='password']": "passwordIndentical"
            },

            initialize: function () {
                this.model = App.currentUser;
                Backbone.Validation.bind(this);
            },

            onRender: function() {
                this.stickit();
            },

            _clearFields: function () {
                var me = this;
                me.ui.newPassword.val('');
                me.ui.retypedPassword.val('');
                me.model.unset('password', { silent: true });
                me.model.unset('passwordRetyped', { silent: true });
            },

            setPassword: function (event) {
                var me = this,
                    l = Ladda.create(document.querySelector('.set-password'));

                if (_.isEmpty(me.ui.newPassword.val())) {
                    App.os.showFieldError('input[name="password"]', App.trans('Please enter NEW password'));
                    return;
                }

                if (_.isEmpty(me.ui.retypedPassword.val())) {
                    App.os.showFieldError('input[name="passwordRetyped"]', App.trans('Please retype your NEW password'));
                    return;
                }

                if (me.model.isValid(true)) {
                    l.start();
                }

                me.model.save({}, {
                    success: function() {
                        me._clearFields();
                        me.ui.updateStatus.html('<div class="alert alert-success">Your password has been successfully updated</div>');
                        l.stop();
                    },
                    error: function (model, response, options) {
                        me._clearFields();
                        me.ui.updateStatus.html('<div class="alert alert-success">Unfortunatelly an error occurred while changing your password.<br><br>' + '<b>' + response.responseJSON.reason + '</b></div>');
                        l.stop();
                    }
                });
            },

            passwordIndentical: function () {
                if( $('[name="password"]').val() === $('[name="passwordRetyped"]').val() ){
                    $('#passwordsIdentical').find('span').css('color', '#124D80').html('Correct!');
                }
                else {
                    $('#passwordsIdentical').find('span').css('color', 'red').html('Incorrect!');
                }
            }


        });
    });
