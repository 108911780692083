
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferTemplate',['hbs','handlebars','templates/helpers/templateName'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "<div class=\"aspect-ratio\">";
  if (helper = helpers.aspect_ratio) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.aspect_ratio); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>";
  return buffer;
  }

  buffer += "<div class=\"inner\">\n    <div class=\"icon\">\n        <i class=\"fa fa-file-o\"></i>\n    </div>\n    <div class=\"desc\">\n        "
    + escapeExpression((helper = helpers.templateName || (depth0 && depth0.templateName),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.name), options) : helperMissing.call(depth0, "templateName", (depth0 && depth0.name), options)))
    + "\n    </div>\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.aspect_ratio), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
