// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/DepartmentDisplayGroups',['App',
        'backbone',
        'hbs!templates/views/Retailers/DepartmentDisplayGroups',
        'views/Retailers/DepartmentDisplayGroup',
        'magicsuggest'
        ],
    function (
        App,
        Backbone,
        template,
        DepartmentDisplayGroup
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: DepartmentDisplayGroup,

            childViewContainer: 'tbody',

            className: 'department-display-groups',

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'tr',

                    getTemplate: function () {
                        var templateHtml = '<td colspan="3" class="text-center">No groups configured</td>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            events: {
                'click .addGroupsBtn' : '_addGroups'
            },

            onRender: function () {
                var me = this;

                me.suggestedDisplayGroups = me.$el.find('.suggest-display-groups').magicSuggest({
                    allowFreeEntries: false,
                    hideTrigger: true,
                    minChars: 1,
                    placeholder: 'Start typing group name',
                    //queryParam: 'dummy',
                    //method: 'get',
                    //displayField: 'value',
                    //valueField: 'uri',
                    //data: '/data/search/display-groups/admin',
                    //resultsField: 'objects',

                    data: function (query){
                        var availDisplayGroups = App.currentUser.getAdminDisplayGroupsFull().filter(
                            function (group) {
                                return !group.get('admin_group') && _.isUndefined(me.collection.get(group.get('uri')));
                            }
                        );
                        var displayGroups = availDisplayGroups.map(function (group) { return { 'id': group.get('uri'), 'name': group.get('display_name') }  });
                        return displayGroups.filter(function(d) { return (d.name).toLowerCase().indexOf(query.toLowerCase()) > -1; });
                    }

                });

                $(me.suggestedDisplayGroups).on('beforeload', function () {
                    var data = {};
                    if (!_.isEmpty(me.suggestedDisplayGroups.getRawValue())) {
                        data['display_name'] = me.suggestedDisplayGroups.getRawValue();
                    }
                    me.suggestedDisplayGroups.setDataUrlParams({ query: JSON.stringify(data) });
                });

                $(me.suggestedDisplayGroups).on('selectionchange', function(e, m) {
                    var btn = me.$el.find('.addGroupsBtn');
                    if (_.size(this.getValue()) > 0) {
                        btn.prop('disabled', false);
                    } else {
                        btn.prop('disabled', true);
                    }
                });

            },

            /*filter: function (child, index, collection) {
                var me = this;
                return !child.get('admin_group') && !_.isUndefined(App.currentUser.getDisplayGroupUsers().getByGroupAndUser(child.get('uri'), me.retailerUri));
            },*/

            initialize: function (options) {
                var me = this;

                me.parentView = options && options.parentView;
                if (me.parentView.model) {
                    me.model = me.parentView.model;
                }

                me.collection = me.model.getDisplayGroups();

                /*me.retailerUri = options.retailerUri;*/


                this.childViewOptions = {
                    parent: this
                }
            },

            _addGroups: function () {
                var me = this,
                    dg;

                _.each(me.suggestedDisplayGroups.getSelection(), function (group) {
                    dg = me.collection.create({
                        group: group.id,
                        department: me.model.get('uri')
                    });
                    dg.set('display_name', App.currentUser.getAdminDisplayGroupsFull().get(group.id).get('display_name'), {silent: true});
                });
                me.render();
            },

            onDestroy: function () {
                $(this.suggestedDisplayGroups).off('selectionchange');
            }

        });
    });
