// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summarySensorDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summarySensorDesc(obj) {
        var output = "",
            strMap = {
                'any' : "any",
                'motion' : "motion",
                'touch' : "touch",
                'eye_tracking' : "eye tracking"
            };

        if (!_.isUndefined(obj)) {
            if (!_.isEmpty(obj.sensor)) {
                output += '<br>using <b>' + strMap[obj.sensor] + '</b> sensors';
            }
        }

        return output;
    }

    Handlebars.registerHelper('summarySensorDesc', summarySensorDesc);
    return summarySensorDesc;
});

