// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/Departments',['App',
        'backbone',
        'hbs!templates/views/Retailers/Departments',
        'collections/Companies',
        'views/Retailers/Retailer',
        'views/Retailers/CreateDepartment',
        'jstree'
        ],
    function (
        App,
        Backbone,
        template,
        RetailersCollection,
        Retailer,
        CreateDepartment
    ) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'panel-list-box-scroll-area',

            filterValue: '',

            selectedDepartment: null,

            events: {
                //'retailers-clear-selection' : '_retailersClearSelection'
                'activate_node.jstree' : '_selectedDepartment',
                'move_node.jstree' : '_moveDepartment'
            },

            initialize: function () {
                var me = this;

                me.childViewOptions = {
                    parent: me
                };

                this.listenTo(App.vent, 'App.departments.create', _.bind(me._createDepartment, me));
                this.listenTo(App.vent, 'App.departments.refresh', _.bind(me._listDepartments, me));
            },

            onRender: function () {
                var me = this;
                var collection = me.model.getDepartments({fetch: false});
                collection.url = App.enrichApiUrl(collection.url);
                $.when(collection.fetch())
                    .then(function () {
                        me.departmentTree = me.$el.jstree({
                            'plugins': ["wholerow", "dnd"],
                            'core': {
                                'check_callback' : true,
                                'multiple' : false,
                                'data': me.model.getDepartments().getDepartmentTree(),
                                'themes': {
                                    'name': 'proton',
                                    'responsive': true
                                }
                            }
                        });
                    });
            },

            _listDepartments: function (newNode, parentNode) {
                var me = this;
                me.departmentTree.jstree(true).settings.core.data = me.model.getDepartments().getDepartmentTree();
                me.departmentTree.one('refresh.jstree', function () {
                    me.departmentTree.jstree('deselect_all');
                    parentNode &&  me.departmentTree.jstree('open_node', $('[id="' + parentNode + '"]'));
                    newNode && me.departmentTree.jstree('select_node', $('[id="' + newNode + '"]'));
                });
                me.departmentTree.jstree(true).refresh();
            },

            _retailersClearSelection: function() {
                var me = this;
                me.$el.find('li').removeClass('active');
            },

            _createDepartment: function (event) {
                event && event.preventDefault();

                var me = this,
                    modalView = new CreateDepartment({ departments: me.model.getDepartments(), parentNode: me.selectedDepartment });
                App.mainRegion.currentView.modalRegion.show(modalView);
            },

            _selectedDepartment: function (event, data) {
                this.selectedDepartment = data.node.id || null;
                App.appRouter.navigate(data.node.id);
                App.vent.trigger('App.departments.showDetails', data.node.id);
            },

            _moveDepartment: function (event, data) {
                var me = this;
                console.log(data);

                if (data && data.node && data.parent) {
                    me.model.getDepartments().get(data.node.id).set("append_to", data.parent).save();
                }
            }


        });
    });
