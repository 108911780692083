
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/AgeDistributionWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"widget__header\">\n    <h5><span class=\"icon icon--camera\"></span>Camera: Age distribution</h5>\n</div>\n\n<div class=\"widget__body\">\n    <div>\n        <div class=\"percent-gauge\">\n            <input type=\"text\" class=\"dial\" data-min=\"0\" data-max=\"100\" data-value=\"children\" data-fgColor=\"#6dc7ee\" readonly>\n            <p>0<span>%</span></p>\n            <h3>Children</h3>\n            <h4>[< 13]</h4>\n        </div>\n    </div>\n    <div>\n        <div class=\"percent-gauge\">\n            <input type=\"text\" class=\"dial\" data-min=\"0\" data-max=\"100\" data-value=\"teenagers\" data-fgColor=\"#009ee1\" readonly>\n            <p>0<span>%</span></p>\n            <h3>Teenagers</h3>\n            <h4>[14 - 24]</h4>\n        </div>\n    </div>\n    <div>\n        <div class=\"percent-gauge\">\n            <input type=\"text\" class=\"dial\" data-min=\"0\" data-max=\"100\" data-value=\"young\" data-fgColor=\"#0f66a8\" readonly>\n            <p>0<span>%</span></p>\n            <h3>Young</h3>\n            <h4>[25 - 39]</h4>\n        </div>\n    </div>\n    <div>\n        <div class=\"percent-gauge\">\n            <input type=\"text\" class=\"dial\" data-min=\"0\" data-max=\"100\" data-value=\"adults\" data-fgColor=\"#0e437b\" readonly>\n            <p>0<span>%</span></p>\n            <h3>Adults</h3>\n            <h4>[40 - 65]</h4>\n        </div>\n    </div>\n    <div>\n        <div class=\"percent-gauge\">\n            <input type=\"text\" class=\"dial\" data-min=\"0\" data-max=\"100\" data-value=\"senior\" data-fgColor=\"#0e437b\" readonly>\n            <p>0<span>%</span></p>\n            <h3>Senior</h3>\n            <h4>[66+]</h4>\n        </div>\n    </div>\n\n    <span class=\"widget__loader\">\n        <div class=\"spinner\"></div>\n    </span>\n\n    <span class=\"widget__body--no-camera\">No camera selected</span>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
