// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/getWeekdays',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function getWeekdays(weekdays) {
        var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            tempArr = [];

        $.each(days, function (index, weekday) {
            if ($.inArray(index, weekdays) > -1) {
                tempArr.push(weekday);
            }
        });
        return _.size(tempArr) === 7 ? 'everyday' : "on " + tempArr.join(", ");
    }

    Handlebars.registerHelper('getWeekdays', getWeekdays);
    return getWeekdays;
});

