// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryWeatherDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryWeatherDesc(obj) {
        var output = "",
            exceptions = {
                'rain' : 'rainy',
                'snow' : 'snowy',
                'normal' : 'mild'
            };

        if (!_.isUndefined(obj)) {
            if (obj.sky_condition !== '') {
                output += "<b>" + (exceptions[obj.sky_condition] || obj.sky_condition) + "</b> ";
            }

            if (obj.temperature !== '') {
                output += "<b>" + (exceptions[obj.temperature] || obj.temperature) + "</b> ";
            }

            if (output !== "") {
                output = "<br>on " + output + "weather"
            }
        }

        return output;
    }

    Handlebars.registerHelper('summaryWeatherDesc', summaryWeatherDesc);
    return summaryWeatherDesc;
});
