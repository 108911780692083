// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/formatDate',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function formatDate(dt, format, nullValue) {
        var nullValue = nullValue || '';
        return (!_.isNull(dt) ? moment(dt).format(format ? format : "YYYY-MM-DD" ) : nullValue);
    }

    Handlebars.registerHelper('formatDate', formatDate);
    return formatDate;
});
