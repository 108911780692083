// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog*/
define('views/ApplicationLayout/ApplicationHeader',['App', 'backbone', 'backbone.marionette', 'jquery', 'hbs!templates/views/ApplicationLayout/ApplicationHeader'

    ],
    function (App, Backbone, Marionette, $, template
        ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            events: {
                'click .sidebar-switch' : '_toggleSidebar',
                'click #create_campaign' : '_createCampaign',
                'click #generate_report' : '_generateReport'
            },

            initialize: function () {
                this.listenTo(App.vent, 'App.layout.updateTitle', _.bind(this.updatePageTitle, this));
                this.listenTo(App.vent, 'App.counter.update', _.bind(this._updateCounter, this));
                this.listenTo(App.vent, 'App.refreshStyle', _.bind(this._handleStyleRefresh, this));
            },

            onRender: function () {
                var me = this;
            },

            _handleStyleRefresh: function () {
                var me = this;
                $("#mainLogo").hide();
                _.delay(_.bind(this.render, this), 500)
            },

            _toggleSidebar: function (event) {
                event.preventDefault();
                App.vent.trigger('App.layout.toggleSidebar');
            },

            updatePageTitle: function (attrs) {
                var me = this;
                if(!attrs[0])
                    attrs[0] = ' ';
                var header_title = attrs[0];
                if(!header_title)
                    header_title = ' ';
                var header_desc = '';
                if ($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) === -1) {
                    me._hideGenReportsBtn();
                    if(me.cmpNoCase(attrs[0], 'DASHBOARD')){
                        header_title = 'Welcome, ' + App.currentUser.get("firstname");
                        header_desc = 'Flow City makes out of home as easy to plan and buy as online. So go ahead, advertise yourself with an image or a video.'
                    }
                    else if(me.cmpNoCase(attrs[0], 'New Campaign')){
                        header_title = 'Campaign name';
                        header_desc = 'It\'s easy to start your campaign with FlowCity. It takes only 3 steps.';
                    }
                    else if(me.cmpNoCase(attrs[0], 'My Campaigns')){
                        header_title = 'My Campaigns';
                        header_desc = 'Here, you have all the campaigns you created and their status.';
                    }
                    else if(me.cmpNoCase(attrs[0], 'Reports')){
                        header_title = 'Reports';
                        header_desc = 'Flow City gives you a high-level performance data for reporting or presentation purposes.';
                        me._showGenReportsBtn();
                    }
                }
                else{

                }

                me.$el.find('.title-row .page_title').html(header_title);
                me.$el.find('.title-row .page_desc').html(header_desc);

            },

            cmpNoCase: function(a, b){
                return a.toLowerCase() === b.toLowerCase()
            },

            _updateCounter: function (counterName, value) {
                var me = this,
                    counter = me.$el.find('.counter.' + counterName);

                counter.html(value === 0 ? '' : value);
            },

            _createCampaign: function () {
                App.vent.trigger('App.layout.create_campaign');
            },

            _showGenReportsBtn: function () {
                this.$el.find('#create_campaign').hide();
                this.$el.find('#generate_report').show();
            },

            _hideGenReportsBtn: function () {
                this.$el.find('#generate_report').hide();
                this.$el.find('#create_campaign').show();
            },

            _generateReport: function () {
                var ng = $('#ng-iframe').find('iframe')[0];
                if(ng && ng.contentWindow) {
                    ng.contentWindow.postMessage('genReport', window.appUrl + '/ng/dist/index.html');
                }
            }


        });
    }
);
