
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/ArtworkSpecification',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4><i class=\"fa fa-angle-down\"></i> Artwork Specification <i class=\"fa fa-angle-down\"></i></h4>\n<div>\n	File type: <span class=\"file_type\"></span><br>\n	Size up to <b><span class=\"max_size\"></span></b><br>\n	Resolution: <b><span class=\"resolution\"></span></b>\n	<p>\n		For more details go to <a href=\"/#dashboard/creative-spec\">Creative spec</a>.\n	</p>\n</div>\n<a class=\"upload-button\" href=\"javascript:void(0)\" onclick=\"document.getElementById('upload-file').click()\">Browse</a>\n<a id=\"cancel-button\" class=\"upload-button\" href=\"javascript:void(0)\" >Cancel</a>";
  });
return t;
});
/* END_TEMPLATE */
;
