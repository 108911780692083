
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferTemplates',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-body\" style=\"min-height: 199px;\">\n\n        <div class=\"modal-loading\">\n            <div class=\"spinner\"></div>\n        </div>\n\n        <h4>Select template</h4>\n        <div class=\"offer-templates\"></div>\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n        <button type=\"button\" class=\"btn btn-success confirm-btn\">Confirm selection</button>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
