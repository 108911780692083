// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferTemplate',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferTemplate', 'backbone', 'bootstrap-dialog'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'offer-template-item',

            events: {
                'click' : '_selectTemplate'
            },

            modelEvents: {
                'change': 'render'
            },

            onRender: function () {
                var me = this;
                if (me.model.get('selected')) {
                    me.$el.addClass('selected');
                } else {
                    me.$el.removeClass('selected');
                }
            },

            _selectTemplate: function (event) {
                event && event.preventDefault();
                App.vent.trigger('App.offer.template.select', this.model.get('uri'));
            }

        });
    });
