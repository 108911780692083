
/* START_TEMPLATE */
define('hbs!templates/views/Offers/CreateOffer',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-header\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Create new offer", options) : helperMissing.call(depth0, "trans", "Create new offer", options)))
    + "</div>\n        </div>\n    </div>\n\n    <div class=\"modal-body\">\n        <div class=\"form-group\" data-permission=\"crea_name_fiel\">\n            <label><span class=\"bullet\">1</span>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Enter your offer name", options) : helperMissing.call(depth0, "trans", "Enter your offer name", options)))
    + "</label>\n            <input type=\"text\" class=\"form-control editable\" name=\"offerName\" value=\"\" placeholder=\"Offer name\">\n        </div>\n\n        <label><span class=\"bullet\">2</span>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Select offer type", options) : helperMissing.call(depth0, "trans", "Select offer type", options)))
    + "</label>\n        <div class=\"btn-group btn-group-justified offer-type-selector\" data-toggle=\"buttons\">\n            <label class=\"btn btn-default active\" data-permission=\"crea_imag_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeImage\" autocomplete=\"off\" value=\"image\" checked> <i class=\"fa fa-picture-o\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Image", options) : helperMissing.call(depth0, "trans", "Image", options)))
    + "</div>\n            </label>\n            <label class=\"btn btn-default\" data-permission=\"crea_mult_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeMulti\" autocomplete=\"off\" value=\"multi\"> <i class=\"fa fa-clone\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Multi", options) : helperMissing.call(depth0, "trans", "Multi", options)))
    + "</div>\n            </label>\n            <label class=\"btn btn-default\" data-permission=\"crea_movi_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeVideo\" autocomplete=\"off\" value=\"video\"> <i class=\"fa fa-film\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Movie", options) : helperMissing.call(depth0, "trans", "Movie", options)))
    + "</div>\n            </label>\n            <label class=\"btn btn-default\" data-permission=\"crea_text_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeNoArtwork\" autocomplete=\"off\" value=\"no-artwork\"> <i class=\"fa fa-font\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Text only", options) : helperMissing.call(depth0, "trans", "Text only", options)))
    + "</div>\n            </label>\n            <!--<label class=\"btn btn-default\" data-permission=\"crea_link_btn\">-->\n                <!--<input type=\"radio\" name=\"offerType\" id=\"offerTypeLink\" autocomplete=\"off\" value=\"link\"> <i class=\"fa fa-link\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Link", options) : helperMissing.call(depth0, "trans", "Link", options)))
    + "</div>-->\n            <!--</label>-->\n            <label class=\"btn btn-default\" data-permission=\"crea_mobi_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeMobile\" data-consumer=\"mobile\" autocomplete=\"off\" value=\"image\"> <i class=\"fa fa-mobile\" style=\"font-size: 40px; padding: 8px;\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Mobile", options) : helperMissing.call(depth0, "trans", "Mobile", options)))
    + "</div>\n            </label>\n            <label class=\"btn btn-default\" data-permission=\"crea_taxi_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeBrightMove\" data-consumer=\"brightmove\" autocomplete=\"off\" value=\"image\"> <i class=\"fa fa-taxi\" style=\"font-size: 30px; padding: 13px;\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "BrightMove", options) : helperMissing.call(depth0, "trans", "BrightMove", options)))
    + "</div>\n            </label>\n            <label class=\"btn btn-default\" data-permission=\"crea_metropolis_btn\">\n                <input type=\"radio\" name=\"offerType\" id=\"offerTypeMetropolis\" data-consumer=\"metropolis\" autocomplete=\"off\" value=\"image\"> <i class=\"fa fa-taxi\" style=\"font-size: 30px; padding: 13px;\"></i><div>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Metropolis", options) : helperMissing.call(depth0, "trans", "Metropolis", options)))
    + "</div>\n            </label>\n        </div>\n\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default\" data-permission=\"crea_canc_btn\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Cancel", options) : helperMissing.call(depth0, "trans", "Cancel", options)))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary create-btn ladda-button\" data-permission=\"crea_crea_btn\" data-style=\"zoom-out\"><span class=\"ladda-label\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Create", options) : helperMissing.call(depth0, "trans", "Create", options)))
    + "</span></button>\n    </div>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
