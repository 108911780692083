// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleSensor',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleSensor', 'jquery-knob'],
    function (App, $, _, Backbone, _OfferScheduleBase, template) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'click .btn-sensor' : 'btnSensorHandler'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                setTimeout(function () {
                        me.setInitialButtonsStates();
                    },
                    100);

                return result;

            },

            setInitialButtonsStates: function () {
                var me = this;

                switch(me.model.get('sensor')){
                    case 'eye_tracking':
                        $('#eyeTrackingSensorLabel').addClass('active');
                        break;
                    case 'touch':
                        $('#touchSensorLabel').addClass('active');
                        break;
                    case 'motion':
                        $('#motionSensorLabel').addClass('active');
                        break;
                    case 'any':
                        $('#anySensorLabel').addClass('active');
                        break;
                    default:
                        $('#offSensorLabel').addClass('active');
                }

            },

            btnSensorHandler: function (event) {
                var me = this,
                    handler = event.currentTarget,
                    feature = 'sensor',
                    handlerValue = $(handler).find('input').attr('value');

                $('#'+feature+'Ratio').attr('data-value', handlerValue)
                    .data('value', handlerValue);
                me.model.set(feature, handlerValue);
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('sensor'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            }

        });
    });
