// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentDetails',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentDetails', 'backbone', 'bootstrap-dialog',
        'views/Retailers/DepartmentLocationWidget',
        'views/Retailers/DepartmentLocationAdd',
        'views/Retailers/CreateDepartmentLocation'],
    function (App, $, _, template, Backbone, BootstrapDialog, DepartmentLocationWidget, DepartmentLocationAdd, CreateDepartmentLocation) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            modelEvents: {
                'change': 'render'
            },

            regions: {
                locationWidget    : '#locationWidget',
                newLocationButton : '#newLocationButton'
            },

            events: {
                'touchmove' : function (event) { event.preventDefault(); },
                'click .delete-btn' : '_deleteDepartment',
                'click .add-location-btn'  : '_createDepartmentLocation'
            },

            initialize: function (options) {
                this.parentView = options && options.parentView;
                if (this.parentView.model) {
                    this.model = this.parentView.model;
                    Backbone.Validation.bind(this);
                }
            },

            onRender: function () {
                var me = this;

                if (me.model) {
                    me.$el.find('.name').editable({
                        url: '',
                        pk: 1,
                        send: 'never',
                        mode: 'inline',
                        onblur: 'submit',
                        savenochange: true,
                        showbuttons: false,
                        value: me.model.get('name'),
                        success: function onXEditableSubTaskTitle (resp, newVal) {
                            me.$el.removeClass('xeditable-shown');
                            me.model.set('name', newVal);
                            if (me.model.hasChanged('name')) {
                                App.vent.trigger('App.departments.refresh');
                                me.model.saveDebounced();
                                me.render();
                            } else {
                                me.render();
                            }
                        }
                    });

                    $.when(me.model.getLocations().promise).then(function () {
                        if (me.model.getLocations().length > 0) {
                            var location = me.model.getLocations().at(0);
                            me.location = location;
                            me.locationWidget.show(new DepartmentLocationWidget({ department: me.model, location: location }));
                        } else {
                            me.newLocationButton.show(new DepartmentLocationAdd());
                        }
                    });

                    if (me.model.get('uri') === App.currentCompany.get('uri')) {
                        me.$el.find('.buttons .delete-btn').remove();
                    }
                }

            },



            _deleteDepartment: function (event) {
                event.stopPropagation();
                event.preventDefault();
                var me = this,
                    companyDepartments = App.currentUser.getRetailers().get(me.model.get('uris').parent_resource).getDepartments(),
                    getBackupDepartment = function () {
                        if (!_.isEmpty(me.model.get('ancestors'))) {
                            return _.last(me.model.get('ancestors'));
                        } else {
                            var idx = companyDepartments.indexOf(me.model);
                            return companyDepartments.at(idx > 0 ? idx - 1 : idx + 1).get('uri');
                        }
                    },
                    backupDepartment = getBackupDepartment();


                if (me.model.get('uri') !== App.currentDepartment.get('uri')) {
                    BootstrapDialog.confirm(App.trans("Do you really want to delete this Department?"), function (result) {
                        if (result) {

                            var paramString = {
                                'move_users_to': backupDepartment,
                                'move_offers_to': backupDepartment
                            };

                            // add query string params to uri
                            me.model.set('uri', me.model.get('uri') + '?' + $.param(paramString), {silent: true});

                            me.model.destroy({
                                success: function () {
                                    delete(me.model);
                                    App.vent.trigger('App.departments.refresh');
                                    App.notifyBrowser('Department deleted', 'Existing users and offers (if any) have been moved to "' + companyDepartments.get(backupDepartment).get('name') + '" department');
                                    me.render();
                                },
                                error: function () {
                                    me.render();
                                }
                            })

                        }
                    });
                }
            },

            _createRetailer: function (event) {
                event.stopPropagation();
                event.preventDefault();
                App.vent.trigger('App.retailers.create');
            },

            _createDepartmentLocation: function (event) {
                event && event.preventDefault();

                var me = this,
                    modalView = new CreateDepartmentLocation({ department: me.model});
                App.mainRegion.currentView.modalRegion.show(modalView);
            },

        });
    });
