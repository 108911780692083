// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('models/Attachment',["App", "jquery", "underscore", "backbone"],
    function (App, $, _, Backbone, User) {

        'use strict';
        var Attachment = Backbone.Model.extend({
            uriStencil: 'files'
        });
        return Attachment;
    }
    );
