// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferSchedulePollen',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferSchedulePollen', 'jquery-knob'],
    function (App, $, _, Backbone, _OfferScheduleBase, template) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'click .btn-pollen' : 'btnPollenHandler'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                setTimeout(function () {
                    me.setInitialButtonsStates();
                },
                    100);

                return result;

            },

            setInitialButtonsStates: function () {
                var me = this;

                switch(me.model.get('pollen')){
                    case 'very_high':
                        $('#vHighPollenLabel').addClass('active');
                        break;
                    case 'high':
                        $('#highPollenLabel').addClass('active');
                        break;
                    case 'medium':
                        $('#mediumPollenLabel').addClass('active');
                        break;
                    case 'low':
                        $('#lowPollenLabel').addClass('active');
                        break;
                    case 'any':
                        $('#anyPollenLabel').addClass('active');
                        break;
                    default:
                        $('#offPollenLabel').addClass('active');
                }

            },

            btnPollenHandler: function (event) {
                // this.btnHandler(event.currentTarget, 'pollen');

                var me = this,
                    handler = event.currentTarget,
                    feature = 'pollen',
                    handlerValue = $(handler).find('input').attr('value');

                $('#'+feature+'Ratio').attr('data-value', handlerValue)
                    .data('value', handlerValue);
                me.model.set(feature, handlerValue);
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('pollen'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            }

        });
    });
