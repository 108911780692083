
/* START_TEMPLATE */
define('hbs!templates/views/Notifications/PublicTransportDisruption',['hbs','handlebars','templates/helpers/timeFrom','templates/helpers/formatDateTime'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this, functionType="function";

function program1(depth0,data) {
  
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.to_date), {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  }
function program2(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n	<small>The alert is valid since <b>"
    + escapeExpression((helper = helpers.formatDateTime || (depth0 && depth0.formatDateTime),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.from_date), options) : helperMissing.call(depth0, "formatDateTime", (depth0 && depth0.from_date), options)))
    + "</b> to <b>"
    + escapeExpression((helper = helpers.formatDateTime || (depth0 && depth0.formatDateTime),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.to_date), options) : helperMissing.call(depth0, "formatDateTime", (depth0 && depth0.to_date), options)))
    + "</b>.</small>\n	";
  return buffer;
  }

  buffer += "<div class=\"white-panel notification\">\n    <div class=\"notification-header\">\n        <p><span class=\"tfl-status-";
  if (helper = helpers.status_severity) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_severity); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.status_severity_description) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_severity_description); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span> on line <span class=\"tfl ";
  if (helper = helpers.line_id) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.line_id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.line_id) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.line_id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span></p>\n        <span class=\"created\"><i class=\"fa fa-clock-o\"></i> "
    + escapeExpression((helper = helpers.timeFrom || (depth0 && depth0.timeFrom),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), options) : helperMissing.call(depth0, "timeFrom", (depth0 && depth0.created), options)))
    + "</span>\n    </div>\n\n    <div class=\"notification-body\">\n    ";
  if (helper = helpers.description) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.description); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n    </div>\n\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.from_date), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
