// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/WidgetBase',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment'],
    function (App, Backbone, _, Marionette, $, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            modelEvents: {
                'change' : '_update'
            },

            initialize: function (options) {
                var me = this;
                App.os.onResize.add(me.widgetName, _.bind(me._onResize, me));
                me.listenTo(App.vent, 'App.dashboard.currentSensorGroup.change', _.bind(me.render, me));
                me.listenTo(App.vent, 'App.dashboard.setLoading', _.bind(me._setLoading, me));
            },

            onRender: function () {
                var me = this;
                $.when(App.currentDepartment.getDisplayGroups().promise).then(function onDisplayGroupsReady () {
                    me._update();
                    // if (!_.isNull(App.currentSensorGroup)) {
                    //     me._update();
                    //     // $.when(App.currentSensorGroup.getDemographics().promise)
                    //     //     .then(function onDemographicsReady () {
                    //     //         me._update();
                    //     //     }, function widgetDataLoadingError () {
                    //     //         me.$el.find('.widget__loader').hide();
                    //     //         me.$el.find('.widget__body--no-camera').html('Data not available');
                    //     //         me.$el.addClass('widget--no-camera');
                    //     //     })
                    // } else {
                    //     me.$el.find('.widget__loader').hide();
                    //     me.$el.addClass('widget--no-camera');
                    // }
                });
            },

            onShow: function () {
                var me = this;
                _.delay(function () { me._onResize(); }, 200);
            },

            _onResize: function () {
                var me = this;
            },

            onDestroy: function () {
                var me = this;
                App.os.onResize.remove(me.widgetName);
            },

            _setLoading: function () {
                this.$el.removeClass('widget--no-camera');
                this.$el.find('.widget__loader').show();
            }

        });
    });

