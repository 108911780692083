// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('behaviors/Greeting',['App',
        'backbone',
        'backbone.marionette',
        'jquery',
        'views/Offers/CreateOffer',
        'bootstrap-dialog',
        'hbs!templates/views/Dashboard/Greeting'
    ],
    function (
        App,
        Backbone,
        marionette,
        $,
        CreateOffer,
        BootstrapDialog,
        template
    ) {
        'use strict';
        return marionette.Behavior.extend({

            initialize: function(options){
                var me = this;
            },

            onRender: function() {
                if(!App.currentCompany.isSetupFeePaid()) {
                    this.greet();
                }
            },

            greet: function(){

                var offerCreated = App.os.getLocalStorageKey('CREATIVE_DONE');
                var billingInfoSaved = App.os.getLocalStorageKey('PAYMENT_DONE');

                var me = this;

                if(!offerCreated || !billingInfoSaved) {
                    
                    var instance = BootstrapDialog.show({
                        title: "Welcome",
                        cssClass: "greetings-modal",
                        message: template,
                        buttons: [{
                            label: 'Close',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });

                    if (offerCreated) {
                        instance.$modal.find('#offer-col').remove();
                        instance.$modal.find('#payment-col').attr("class","col-sm-12");
                        instance.setSize(BootstrapDialog.SIZE_SMALL);
                    } else {
                        instance.$modal.find('#specs-btn').on('click', me.openSpecification);
                        instance.$modal.find('#create-btn').on('click', function () {me.openCreation(instance)});
                    }

                    if (billingInfoSaved) {
                        instance.$modal.find('#payment-col').remove();
                        instance.$modal.find('#offer-col').attr("class","col-sm-12");
                        instance.setSize(BootstrapDialog.SIZE_SMALL);
                    } else {
                        instance.$modal.find('#plans-btn').on('click', function () {me.openPlans(instance)});
                    }

                }
            },

            openSpecification: function(){
                event && event.preventDefault();

                var url = "https://storage.googleapis.com/www.flow.city/artwork-specs/specs-image-brightmove.html";

                BootstrapDialog.show({
                    title: "Artwork specification",
                    message: $('<div>Loading...</div>').load(url),
                    buttons: [{
                        label: 'Close',
                        action: function(dialogItself){
                            dialogItself.close();
                        }
                    }]
                });
            },

            openPlans: function(instance){
                var me = this;
                instance.close();
            },

            openCreation: function (instance) {
                var modalView = new CreateOffer();
                modalView.greetings = instance;
                App.mainRegion.currentView.modalRegion.show(modalView);
            }

        });
    });
