// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/Offer',["App", "backbone",
        "collections/Attachments",
        "collections/OfferTargets",
        "collections/OfferSchedules",
        "models/OfferTarget",
        "backbone-model-factory"],

    function (App, Backbone,
            Attachments,
            OfferTargets,
            OfferSchedules,
            OfferTarget) {

        'use strict';
        var Offer = Backbone.ModelFactory({
            uriStencil: 'offers',
            _template: null,
            supportedMimeTypes: {
                image: ["image/png", "image/bmp", "image/gif", "image/jpeg"],
                video: ["video/mp4", "video/ogg", "video/webm", "video/m4v", "video/mkv", "video/m4v", "video/3gpp", "video/quicktime", "video/x-msvideo"]
            },

            validation: {
                'name': {
                    required: true,
                    minLength: 1,
                    msg: "Please enter campaign name"
                }
            },

            parse: function (response) {
                // response.offer_variant_options = JSON.parse(response.offer_variant_options);
                response.uris.OfferTargets = response.uris.OfferTargets + '?recursive=1';
                return response;
            },

            save: function (attrs, options) {
                options || (options = {});
                attrs || (attrs = _.clone(this.attributes));

                delete attrs.supported_schedules;
                options.date = JSON.stringify(attrs);
                return Backbone.Model.prototype.save.call(this, attrs, options);
            },

            getAttachments: function (options) {
                return this.getSubCollection(Attachments, 'Files', options);
            },

            getOfferTargets: function (options) {
                return this.getSubCollection(OfferTargets, 'OfferTargets', options);
            },

            getOfferSchedules: function (options) {
                return this.getSubCollection(OfferSchedules, 'Schedules', options);
            },

            deleteOfferTargetsCollection: function () {
                delete(App.collections[this.get('uris').OfferTargets]);
            },

            isDraft: function () {
                return !this.get('locked');
            },

            lockOffer: function () {
                this.set('locked', true, {silent: true});
            },

            unlockOffer: function () {
                this.set('locked', false, {silent: true});
            },

            isAnyTargetOnline: function () {
                var me = this;
                return !_.isUndefined(me.getOfferTargets({fetch:false}).getByStatus('online'));
            },

            removeAllTargets: function () {
                var me = this;
                me.getOfferTargets().each(function (target) {
                    target.set("status", "draft").save();
                });
            },

            removeTarget: function (targetUri, opts) {
                var me = this,
                    tmpTarget,
                    opts = opts || {};

                tmpTarget = me.getOfferTargets().getByDisplayGroupUri(targetUri);
                if (_.isObject(tmpTarget)) {
                    tmpTarget.destroy();
                }
            },

            setTargetStatus: function (targetUri, status, offerParams) {
                var me = this,
                    tmpTarget,
                    targets = [];

                tmpTarget = me.getOfferTargets().getByDisplayGroupUri(targetUri);

                if (me.isDraft() && me._debouncedSave) {
                    me.save();
                }

                if (me.getOfferSchedules() &&
                    !_.isUndefined(App.currentOffer.getOfferSchedules().find(function(schedule) { return schedule._debouncedSave; }))) {
                    if (status !== 'draft') {
                        me.lockOffer();
                    }

                    // save all schedules
                    App.currentOffer.getOfferSchedules().each(function (schedule) {
                        if (schedule._debouncedSave) {
                            schedule.save();
                        }
                    });
                } else {
                    if (status !== 'draft') {
                        me.lockOffer();
                    }
                }

                if (_.isObject(tmpTarget)) {

                    // update target status and additional attributes
                    tmpTarget.set('status', status);

                    if (_.isObject(offerParams)) {
                        for (var key in offerParams) {
                            if (offerParams.hasOwnProperty(key)) {
                                tmpTarget.set(key, offerParams[key]);
                            }
                        }
                    }

                    tmpTarget.unsetScheduleStatus();
                    console.log(tmpTarget);
                    $.when(tmpTarget.save()).then(function () {
                        tmpTarget.getScheduleStatus();
                    });
                } else {
                    // add new target into collection
                    console.log(targetUri);
                    
                    console.log(status);
                    var newTarget = me.getOfferTargets().create({
                        "target" : targetUri,
                        "status" : status
                    },{
                        success: function () {
                            newTarget.getScheduleStatus();
                        }
                    });
                }
            },
            
            getVideoDuration: function (url, callback) {
                var me = this,
                    videoEl,
                    duration,
                    addSourceToVideo = function (element, src) {
                        var source = document.createElement('source');
                        source.src = src;
                        //source.type = type;
                        element.appendChild(source);
                    };

                videoEl = document.createElement('video');
                document.body.appendChild(videoEl);
                videoEl.style.visibility = "hidden";
                addSourceToVideo(videoEl, App.enrichApiUrl(url));

                var t = window.setInterval(function () {
                    if (videoEl.readyState > 0) {
                        clearInterval(t);

                        duration = videoEl.duration;
                        if (!_.isNaN(duration) && _.isFinite(duration)) {
                            console.log('Uploaded video duration:', Math.round(duration),'s');
                            me.set('display_time', Math.round(duration));
                        }
                        videoEl.remove();
                        callback();
                    }
                }, 500);
            },
            
            
            // Upload - third stage (last)
            finishUpload: function (finishUri, data, statusCode, finishMethod, file, onUploadSuccess, onError, onMediaFileUpdated) {
                var me = this;
                $.ajax({
                    url: App.enrichApiUrl(finishUri),
                    type: finishMethod,
                    dataType: "json",
                    data: JSON.stringify({
                        'response_content': data,
                        'status_code': statusCode
                    }),
                    cache: false,

                    success: function (data, textStatus, jqXHR) {
                        // Update attachments collection
                        me.getAttachments().add(data, {silent: true, merge: true});

                        var finishFunc = function () {
                            if (me.get('offer_type') !== 'multi') {
                                me.set('media_file', data.download_link + '?_d=' + _.random(10000, 99999), {silent: true});
                            }
                            onMediaFileUpdated();
                            onUploadSuccess(data, file);
                        };

                        if (me.get('offer_type') === 'video') {
                            me.getVideoDuration(data.download_link, finishFunc);
                        } else {
                            finishFunc();
                        }
                    },
                    error: function (data, textStatus, errorThrown) {
                        onError();
                    }
                });
            },  
            
            uploadFile: function (file, method, uploadUri, finishUri, finishMethod, onSetProgress, onUploadSuccess, onError, onMediaFileUpdated) {
                var me = this;

                if (!_.isUndefined(file)) {
                    $.ajax({
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();

                            //Upload progress
                            xhr.upload.addEventListener("progress", function (evt) {
                                if (evt.lengthComputable) {
                                    var percentComplete = Math.round((evt.loaded / evt.total) * 100);
                                    onSetProgress(percentComplete, file);
                                }
                            }, false);
                            return xhr;
                        },

                        url: uploadUri,
                        type: method,
                        data: file,
                        cache: false,
                        crossDomain: true,
                        contentType: false,
                        dataType: 'json',
                        processData: false,
                        headers: {
                            'X-Upload-Content-Type': file.type,
                            'X-Upload-Content-Length' : file.size,
                            'Content-Range': 'bytes 0-' + (file.size - 1) + '/' + file.size
                        },

                        success: function (data, textStatus, jqXHR) {
                            if (_.isUndefined(data.error)) {

                                // Sometimes GCS does not return content type (we don't know why)
                                data.contentType = data.contentType || file.type;

                                me.finishUpload(
                                    finishUri, data, jqXHR.status, finishMethod, 
                                    file,
                                    onUploadSuccess,
                                    onError,
                                    onMediaFileUpdated);
                            } else {
                                onError();
                            }
                        },
                        error: function (data, textStatus, errorThrown) {
                            onError();
                        }
                    });

                }
            },

            getPosition: function () {
                return this.get('targets')[0]['position'];
            },

            setPosition: function (position) {
                var tmpTargets = this.get('targets'),
                    tmpOfferTargetModel;
                tmpTargets[0].position = position;
                this.set('targets', tmpTargets, {silent: true});

                tmpOfferTargetModel = new OfferTarget(tmpTargets[0]);
                tmpOfferTargetModel.save();
            },

            removeFromLoop: function () {
                var me = this,
                    tmpTargets = this.get('targets'),
                    tmpOfferTargetModel,
                    displayTarget;

                $.when(me.getOfferTargets().promise).then(function () {
                    displayTarget = me.getOfferTargets().get(tmpTargets[0].uri);
                    if (displayTarget) {
                        displayTarget.set('status', 'draft');
                        displayTarget.save();
                    } else {
                        tmpTargets[0].status = 'draft';
                        tmpOfferTargetModel = new OfferTarget(tmpTargets[0]);
                        tmpOfferTargetModel.save();
                    }
                });

            },

            getMediaRequirements: function () {
                return this.get('media_requirements');
            },

            getVariant: function () {
                return this.get('offer_variant');
            },

            setVariantOption: function (option, value) {
                var obj = this.get('offer_variant_options') || {};
                obj[option] = value;
                this.set('offer_variant_options', obj);
                this.saveDebounced();
            }

        });

        return Offer;
    }
);

