
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/CompanyDetails',['hbs','handlebars','templates/helpers/formatDate','templates/helpers/yesOrNo'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n                <img src=\"";
  if (helper = helpers.company_logo) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.company_logo); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"logo-preview\">\n                ";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n                <img src=\"/static/images/no-logo.png\" class=\"logo-preview\">\n                ";
  }

function program5(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Plan activation</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.active_period_start), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.active_period_start), "YYYY-MM-DD", options)))
    + "</span>\n                    </div>\n                </div>\n                ";
  return buffer;
  }

function program7(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Next payment</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.next_period_start), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.next_period_start), "YYYY-MM-DD", options)))
    + "</span>\n                    </div>\n                </div>\n                ";
  return buffer;
  }

function program9(depth0,data) {
  
  
  return "\n            <h4>Company logo:</h4>\n\n            <div style=\"width: 80%; margin: 0 auto\">\n                <img id=\"preview\"\n                 style=\"width: 100%; height: 200px; display: block; border: 1px solid #ddd; max-width: 100%;\"\n                 src=\"/static/images/empty.png\" alt=\"Logo preview\">\n            </div>\n\n            <div style=\"text-align: center; padding: 10px\">\n                <div class=\"fileinput fileinput-new\" data-provides=\"fileinput\">\n                    <span class=\"btn btn-sm btn-default btn-file\">\n                        <span class=\"fileinput-new\">Select image...</span><span class=\"fileinput-exists disabled\">Change image...</span>\n                        <input type=\"file\" id=\"companyLogo\" name=\"companyLogo\">\n                    </span>\n                </div>\n\n                <button class=\"btn btn-sm btn-default\" data-action=\"upload-image\" style=\"display: none\">Upload image</button>\n            </div>\n            ";
  }

  buffer += "<form class=\"form-horizontal style-form mt\">\n\n    <div class=\"row\">\n        <div class=\"col-md-6\">\n            <h4>Logo:</h4>\n            <div style=\"margin-left: 28px;\">\n                ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.company_logo), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n                <button class=\"btn btn-sm btn-default\" data-action=\"change-image\" style=\"display: block\">Change logo</button>\n            </div>\n\n            <h4>Company details:</h4>\n            <div class=\"form-group retailer-details-line\">\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Created</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.created), "YYYY-MM-DD", options)))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Payable</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.yesOrNo || (depth0 && depth0.yesOrNo),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.payable), options) : helperMissing.call(depth0, "yesOrNo", (depth0 && depth0.payable), options)))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Unlimited credits</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.yesOrNo || (depth0 && depth0.yesOrNo),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.unlimited_credits), options) : helperMissing.call(depth0, "yesOrNo", (depth0 && depth0.unlimited_credits), options)))
    + "</span>\n                    </div>\n                </div>\n            </div>\n\n            <h4>Subscription details:</h4>\n            <div class=\"form-group retailer-details-line\">\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Active plan</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">";
  if (helper = helpers.active_plan) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.active_plan); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n                    </div>\n                </div>\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Available credits</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name company-credits\">";
  if (helper = helpers.credits) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.credits); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n                    </div>\n                </div>\n                <div class=\"retailer-details-line\">\n                    <label class=\"control-label\">Setup fee paid</label>\n                    <div class=\"form-control-static\">\n                        <span class=\"name\">"
    + escapeExpression((helper = helpers.yesOrNo || (depth0 && depth0.yesOrNo),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.setup_fee_paid), options) : helperMissing.call(depth0, "yesOrNo", (depth0 && depth0.setup_fee_paid), options)))
    + "</span>\n                    </div>\n                </div>\n                ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.active_period_start), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.next_period_start), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </div>\n        </div>\n        <div class=\"col-md-6\">\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0._changelogo), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n    </div>\n\n\n\n</form>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
