// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/LoopManagement/Loop',['App',
        'backbone',
        'hbs!templates/views/LoopManagement/Loop',
        'views/LoopManagement/LoopItem'
        ],
    function (
        App,
        Backbone,
        template,
        LoopItem
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: LoopItem,

            childViewContainer: '.offers',

            className: 'loop-management',

            collectionEvents: {
                'add' : 'render',
                'remove' : 'render'
            },

            events: {
                'click .btn-back': '_backToDisplayGroups'
            },

            ui: {
                'loop' : '.offers'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'li',
                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                var me = this;
                this.listenTo(App.vent, 'App.layout.loopManagement.removeFromLoop', _.bind(this._removeFromLoop, this));

                App.vent.trigger("App.layout.loadingMask.show");
                $.when(me.model.getOnlineOffers().fetch()).then(function onOnlineOffersLoaded() {
                    me.collection = me.model.getOnlineOffers();
                    me.render();
                });

            },

            onRender: function () {
                var me = this;

                // Make loop sortable
                me.ui.loop.sortable({
                    revert: true,
                    helper: "clone",
                    placeholder: "ghost-loop-item",
                    //containment: "body",
                    opacity: 0.8,
                    appendTo: "parent",
                    items: 'li.loop-item',

                    update: function (event, ui) {
                        console.log('dropped');
                        me._updatePosition(ui);
                    }
                });

                $.when(me.model.getOnlineOffers().promise).then(function () {
                    me.$el.find('.no-items').html('<i class="fa fa-repeat"></i> No offers in the loop');
                });

                App.vent.trigger("App.layout.loadingMask.hide");

                me.$el.find('img').attr('src', me.model.getIcon());
                me.svgInject();
            },

            _calcPosition: function (left, right) {
                var epsilon = 1.e-5 * (right - left);
                return _.random(left + epsilon, right - epsilon);
            },

            _updatePosition: function (obj) {
                var me = this,
                    itemModel = me.collection.get(obj.item.data('id')),
                    prevPosition = (!_.isUndefined(obj.item.prev('li').data('id')) ? me.collection.get(obj.item.prev('li').data('id')).getPosition() : 0),
                    nextPosition = (!_.isUndefined(obj.item.next('li').data('id')) ? me.collection.get(obj.item.next('li').data('id')).getPosition() : 1),
                    newPosition = me._calcPosition(prevPosition, nextPosition);

                console.log(prevPosition);
                console.log(' --> ' + newPosition);
                console.log(nextPosition);

                itemModel.setPosition(newPosition);
                me.collection.sort();
            },

            _removeFromLoop: function (offer) {
                var me = this;
                offer.removeFromLoop();
                me.collection.remove(offer);
                me.render();
            },

            _backToDisplayGroups: function (event) {
                event && event.preventDefault();
                App.appRouter.navigate('loop-management', {trigger: true});
            }

        });
    });
