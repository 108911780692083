// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Payments/SubscriptionPlans',['App',
        'backbone',
        'hbs!templates/views/Payments/SubscriptionPlans',
        'views/Payments/SubscriptionPlan'
        ],
    function (
        App,
        Backbone,
        template,
        SubscriptionPlan
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: SubscriptionPlan,

            className: 'quasi-table',
            childViewContainer: 'ul',

            events: {

            },

            initialize: function () {
                var me = this;
                me.collection = App.currentCompany.getSubscriptionPlans();
                me.collection = App.currentCompany.getSubscriptionPlans();
            },

            onRender: function () {
                var me = this;
            },

            filter: function (child, index, collection) {
                // do not show DEMO subscription plan
                return child.get('type') !== 'DEMO';
            }

        });
    });
