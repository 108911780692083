// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleVolumetrics',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleVolumetrics', 'jquery-knob'],
    function (App, $, _, Backbone, _OfferScheduleBase, template) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {

            },

            initialize: function() {
                var me = this;

                me.impactSet = ['0', '50 K', '200 K', '500 K', '2 M'];
                me.impressionsSet = ['0', '100 K', '200 K', '500 K', '1 M'];
                me.frequencySet = ['Low', '', '', '', '', '', '', '', '', 'High'];
                me.GRPSet = ['0', '20', '40', '60', '80', '100%'];
                me.slidersSet = {'impact':me.impactSet, 'impressions':me.impressionsSet, 'frequency':me.frequencySet, 'GRP':me.GRPSet};
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                // set slide bars after certain delay
                setTimeout(function () {

                    me.setSlider('impact', true, [$('#impactInputMin').val(), $('#impactInputMax').val()], false);
                    me.setSlider('impressions', 'min', $('#impressionsInput').val(), false);
                    me.setSlider('frequency', 'min', $('#frequencyInput').val(), false);
                    me.setSlider('GRP', 'min', $('#GRPInput').val(), true, 1);

                }, 100);

                // set initial values to inputs
                for(var item in me.slidersSet) {
                    me.$el.find('[data-control="' + item + '"]#' + item + 'Input')
                        .attr('data-value', me.model.get(item)).val(me.model.get(item + '_range')[0]);
                }

                return result;
            },

            setSlider: function (feature, range, value_s, valueCloud, step) {
                var me = this,
                    sliderHandler = $( "#slider-"+feature ),
                    labelIndex = me[feature+'Set'],
                    gap = 100/(labelIndex.length - 1);

                sliderHandler.slider({
                    range: range,
                    min: 0,
                    max: 100,
                    step: gap,
                    slide: function( event, ui ) {
                        var delay = function() {
                            $('.value-'+feature).html( ui.value + '%' );
                        };

                        // wait for the ui.handle to set its position
                        setTimeout(delay, 5);
                    },
                    stop: function( event, ui ) {
                        if(value_s.length>1) {
                            me.actualiseFeature( ui, me, feature, true );
                        }else{
                            me.actualiseFeature( ui, me, feature );
                        }
                    }
                });

                if(value_s.length > 1)
                    sliderHandler.slider( "option", "values", value_s );
                else
                    sliderHandler.slider( "option", "value", value_s );
                if(step)
                    sliderHandler.slider( "option", "step", step );

                if(valueCloud)
                    $( "#slider-"+feature+" a" ).append('<div class="value-volumetrics value-'+feature+'">'+value_s+'%</div>');

                var label = '';
                for(var i in labelIndex){
                    label += '<label style="left: ' + i*gap + '%">' +
                        '<span>' + labelIndex[i] + '</span>' +
                        '</label>';
                }

                sliderHandler.append('<div class="label-volumetrics">'+label+'</div>');

            },

            actualiseFeature: function (ui, me, feature, range) {

                if(range){

                    ui.values[1] = Math.round(ui.values[1]);
                    ui.values[2] = Math.round(ui.values[2]);
                    var featureRatio = $('#' + feature + 'InputMax');

                    me.model.set(featureRatio.data('control'), ui.values[1] !== 0 ? featureRatio.data('value') : '');
                    me.model.set(featureRatio.data('control') + '_range', [ui.values[0], ui.values[1]]);
                }
                else{
                    var value = Math.round(ui.value),
                        featureRatio = $('#'+feature+'Input');

                    me.model.set(featureRatio.data('control'), value !== 0 ? featureRatio.data('value') : '');
                    me.model.set(featureRatio.data('control') + '_range', [value, 100]);
                }
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('impact'))
                    && _.isEmpty(me.model.get('impressions'))
                    && _.isEmpty(me.model.get('frequency'))
                    && _.isEmpty(me.model.get('GRP'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            }

        });
    });
