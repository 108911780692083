// -*- coding: utf-8 -*-

/*global define,sessionData*/
define('views/Offers/OfferSchedules',['App',
        'backbone',
        'hbs!templates/views/Offers/OfferSchedules',
        'views/Offers/OfferScheduleEmpty',
        'behaviors/Permissions',

        'views/Offers/OfferScheduleTime',
        'views/Offers/OfferScheduleWeather',
        'views/Offers/OfferScheduleDisruptions',
        'views/Offers/OfferScheduleAIM',
        'views/Offers/OfferSchedulePollen',
        'views/Offers/OfferScheduleSensor',
        'views/Offers/OfferScheduleVolumetrics',
        'views/Offers/OfferScheduleEPos'
        ],
    function (
        App,
        Backbone,
        template,
        OfferScheduleEmpty,
        Permissions,

        OfferScheduleTime,
        OfferScheduleWeather,
        OfferScheduleDisruptions,
        OfferScheduleAIM,
        OfferSchedulePollen,
        OfferScheduleSensor,
        OfferScheduleVolumetrics,
        OfferScheduleEPos
    ) {

        'use strict';
        return Backbone.Marionette.SortedCompositeView.extend({
            template: template,

            _itemViews: {
                'DbOfferScheduleTime' : OfferScheduleTime,
                'DbOfferScheduleWeather' : OfferScheduleWeather,
                'DbOfferScheduleDisruptions' : OfferScheduleDisruptions,
                'DbOfferScheduleAIM' : OfferScheduleAIM,
                'DbOfferSchedulePollen' : OfferSchedulePollen,
                'DbOfferScheduleSensor' : OfferScheduleSensor,
                'DbOfferScheduleVolumetrics' : OfferScheduleVolumetrics,
                'DbOfferScheduleEPos' : OfferScheduleEPos
            },

            childViewContainer: '.schedules',

            ui: {
                loadingMask: '.offer-schedules-loading-mask'
            },

            emptyView: OfferScheduleEmpty,

            behaviors: {
                Permissions:{
                    behaviorClass: Permissions,
                    App: App
                }
            },

            events: {
                'click .arrow-navigation' : 'scrollTabs',
                'click .scheduler-tabs li' : 'tabCheck'
            },

            initialize: function () {
                var me = this;

                if (App.currentOffer) {
                    me.instantiationFlag = true;

                    me.listenTo(App.vent, 'App.currentOffer.schedule.updateActiveSchedule', _.bind(me._updateActiveSchedule, me));
                }
            },

            onRender: function () {
                var me = this;

                this.collection = App.currentOffer.getOfferSchedules();
                $.when(me.collection.promise).then(function () {
                    me.collection.setDefaults();
                    if( me.instantiationFlag ){
                        me.instantiationFlag = false;
                        me.setTab();
                        me.render();
                    }
                });

                $('#scroll-left').css('display', 'none');
            },

            setTab: function(){
                var me = this;
                var tabs = me.$el.find('.nav-tabs li');
                var shown = false;

                 $.when(App.currentUser.getPermissions().promise).then(function () {
                     var permissions = App.currentUser.getPermissions();
                     tabs.each(function(i, element){
                         var elPermission = $(element).attr('data-permission');
                         var elType = $(element).attr('data-type');
                         if(permissions.get(elPermission)=='active' && me.collection.findWhere({type: elType})){
                            $(element).find("a[data-toggle='tab']").tab('show');
                             shown = true;
                             return false;
                         }
                     });
                     //hiding scheduler if no tab is active
                     if(!shown) me.$el.find('.tab-content').hide();
                });

            },

            tabCheck: function (event) {
                var target = $(event.currentTarget),
                    arrowWidth = $('#scroll-right').width(),
                    leftTabEdge,
                    rightTabEdge,
                    rightParentsEdge,
                    overflowing,
                    newOverflowing;

                var actualiseVariables = function () {
                    leftTabEdge = target.position().left;
                    rightTabEdge = leftTabEdge + target.width();
                    rightParentsEdge = target.parent().parent().width();
                    overflowing = rightTabEdge - rightParentsEdge;
                };

                actualiseVariables();

                //closer to the left
                if (leftTabEdge < rightParentsEdge - rightTabEdge) {
                    if (target.prev('li').length) {
                        target = target.prev('li');
                        actualiseVariables();
                    }
                }
                //closer to the right
                else {
                    if (target.next('li').length) {
                        target = target.next('li');
                        actualiseVariables();
                    }
                }

                // scroll left
                if(target.position().left < arrowWidth){
                    newOverflowing = parseFloat( target.parent().css('margin-left') ) - target.position().left;
                    if( target.prev('li').length )
                        newOverflowing += arrowWidth;
                    target.parent().css('margin-left', newOverflowing);
                }
                // scroll right
                else if(overflowing > -arrowWidth) {
                    newOverflowing = parseFloat( target.parent().css('margin-left') ) - overflowing;
                    if( target.next('li').length )
                        newOverflowing -= arrowWidth;
                    target.parent().css('margin-left', newOverflowing);
                }


                this.dis_appearArrows(target);

            },

            dis_appearArrows: function (target) {
                if( parseInt(target.parent().css('margin-left')) >= 0 )
                    $('#scroll-left').css('display', 'none');
                else
                    $('#scroll-left').css('display', '');
                var x = parseInt( target.parent().parent().width() ),
                    y = parseInt( target.parent().css('margin-left') ),
                    z = parseInt( target.parent().width() );
                if( x >= y+z )
                    $('#scroll-right').css('display', 'none');
                else
                    $('#scroll-right').css('display', '');
            },

            /*
                divs make $('.scheduler-tabs') move left and right inside its parent using "margin-left"
             */
            scrollTabs: function (event) {
                var target = $(event.currentTarget),
                    step = 100,
                    schedulerTabs = $('.scheduler-tabs'),
                    left = parseInt( schedulerTabs.first().css('margin-left') );
                if(target.attr('id') === 'scroll-left'){

                    $('#scroll-right').css('display', '');
                    if(left+step >= 0){
                        schedulerTabs.css('margin-left', 0);
                        target.css('display', 'none');
                    }else {
                        schedulerTabs.css('margin-left', left + step);
                    }
                }else{
                    var x = schedulerTabs.width();
                    var y = schedulerTabs.parent().width();
                    var dilatation = y - x;
                    $('#scroll-left').css('display', '');

                    if(left-step <= dilatation){
                        schedulerTabs.css('margin-left', (dilatation-1));
                        target.css('display', 'none');
                    }else {
                        schedulerTabs.css('margin-left', left - step);
                    }
                }

            },

            getChildView: function (schedule) {
                return this._itemViews[schedule.get('type')];
            },

            addChild: function (child, ChildView, index){
                this._updateActiveSchedule(child.get('type'), child.get('active'));
                this.$el.find('li[data-type="' + child.get('type') + '"]').show();
                Marionette.CollectionView.prototype.addChild.apply(this, arguments);
            },

            _updateActiveSchedule: function (type, active) {
                var tab = this.$el.find('li[data-type="' + type + '"]');
                active ? tab.addClass('active-scheduler') : tab.removeClass('active-scheduler');
            }

        });
    });
