// -*- coding: utf-8 -*-


/*global define*/
define('collections/OfferTemplates',["backbone", "models/OfferTemplate"],
    function (Backbone, OfferTemplate) {

        'use strict';
        var OfferTemplates = Backbone.Collection.extend({

            model: OfferTemplate,

            getSelected: function () {
                return this.find(function (template) { return template.get('selected'); });
            },

            getByUri: function (uri) {
                return this.find(function (template) { return template.get('uri') === uri; });
            }

        });

        return OfferTemplates;
    });
