
/* START_TEMPLATE */
define('hbs!templates/views/InternalReports/CreditsPurchased',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"inner\">\n    <div class=\"widget__header\">\n        <h5>&nbsp;<i class=\"fa fa-money\" aria-hidden=\"true\"></i> Credits Purchased</h5>\n    </div>\n    <div class=\"widget__body\">\n        <h1 style=\"margin-top: 0px;\">";
  if (helper = helpers.count) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.count); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " in ";
  if (helper = helpers.events) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.events); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " events</h1>\n\n        <div class=\"internal-reports-loading-mask\">\n            <div class=\"radial-progress waiting\">\n                <div class=\"inf\"></div>\n            </div>\n        </div>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
