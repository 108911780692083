// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/ArtworkSpecification',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
		'hbs!templates/views/Dashboard/ArtworkSpecification'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template) {

        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'widget full_height',

            ui: {
                fileType: '.file_type',
                maxSize: ".max_size",
                resolution:".resolution"
            },

			initialize: function (specification) {
                this.specification = specification;
            },

            onRender: function () {
                this.ui.fileType.html(this.specification.fileType);
                this.ui.maxSize.html(this.specification.maxSize);
                this.ui.resolution.html(this.specification.resolution);
            }
        });
    });

