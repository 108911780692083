// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/decimal',['App', 'handlebars', 'underscore'], function (App, Handlebars, _) {

    'use strict';
    function decimal(number) {
        return _.numberFormat(parseFloat(number), 2, '.', '');
    }

    Handlebars.registerHelper('decimal', decimal);
    return decimal;
});
