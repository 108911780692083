// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Payments/CreditsPurchase',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/CreditsPurchase', 'ladda-bootstrap',
        'stripe', 'views/Payments/StripeModal', 'bootstrap-dialog', 'behaviors/Intercom'],
    function (App, Backbone, _, $, template, Ladda, Stripe, StripeModal, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                creditsInfoContainer: ".credits-info-container",
                creditsInfo: "#credits-info"
            },

            events: {
                'click .buy-credits-btn': 'buyCredits',
                'click .credits-package label': 'selectCreditsPackage'
            },

            initialize: function (options) {
                this.plan = options && options.plan;
                this.model = App.currentCompany.getSubscriptionPlans().getCurrent();
                this.model.attributes.creditsFeeExtended = {};
                this.model.attributes.creditsFeeExtended.GBP = {};

                for(var fee in this.model.attributes.creditFee.GBP){
                    this.model.attributes.creditsFeeExtended.GBP[fee] = {
                        credits: this.model.attributes.creditFee.GBP[fee],
                        description: this.model.attributes.creditFeeDescription.GBP[fee]
                    }
                }

                console.info(this.model.attributes.creditsFeeExtended.GBP);
                setTimeout(function () {
                    var descs = $('.credits-description');

                    for(var i=0; i<descs.length; ++i){
                        $(descs[i]).html( $(descs[i]).attr('content') );
                        console.info(descs[i]);
                    }
                })
            },

            onRender: function () {
                var me = this;

                // select first package
                //me.$el.find('.credits-package label:first-child').addClass('active');
                //me.$el.find('.credits-package label:first-child input').prop('checked', 'checked');
            },
            selectCreditsPackage: function(event){
                $('.credits-package label').addClass('unselected');
                $(event.currentTarget).removeClass('unselected');

                console.log(event);
                this.ui.creditsInfoContainer.show();
                this.ui.creditsInfo.html($(event.currentTarget).children('input')[0].value)
            },
            handleStripe: function (data) {
                var token;
                if (data.redirect_url && data.redirect_url.indexOf('stripe') != -1) {
                    token = data.redirect_url.slice(7);

                    App.mainRegion.currentView.modalRegion.$el.removeClass('fade');
                    App.mainRegion.currentView.modalRegion.$el.modal('hide');
                    App.mainRegion.currentView.modalRegion.$el.addClass('fade');

                    var modalView = new StripeModal({'token': token, 'uri': App.enrichApiUrl(data.uri)});
                    var callback = function () {
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    };
                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);
                    } else {
                        callback();
                    }

                }
            },

            buyCredits: function (event) {
                var me = this,
                    data = {},
                    creditsVolume = parseInt(me.$el.find('[name=credits-package]:checked').val()),
                    l = Ladda.create(document.querySelector('.modal-content .buy-credits-btn')),
                    headers = {'X-Alt-Referer': App.appUrl + '/#profile/payments'};

                data = {
                    "credits": creditsVolume
                };

                l.start();
                me.$el.find('button').addClass('disabled');

                $.ajax({
                    url: App.enrichApiUrl(App.currentCompany.get('uris').Payments),
                    type: 'POST',
                    dataType: 'json',
                    headers: headers,
                    data: JSON.stringify(data),

                    success: function (data, textStatus, jqXHR) {
                        intercomReportEvent('order_credits', {
                            timestamp: (new Date()).toString(),
                            old_credits: credits,
                            new_credits: credits + data.credits
                        });

                        if (data.payment_provider == "Stripe" && data.redirect_url && data.redirect_url.indexOf('stripe') != -1) {
                            me.handleStripe(data);
                        } else if (!_.isNull(data.redirect_url) && data.redirect_url.indexOf('stripe') == -1) {
                            window.location.href = data.redirect_url;
                        } else if (!data.redirect_url || data.redirect_url.indexOf('stripe') == -1) {
                            var currCredits = App.currentCompany.get('credits') || 0,
                                credits = currCredits + data.credits;
                            App.currentCompany.set('credits', credits);
                            App.vent.trigger('App.layout.paymentStatus.show', 'You have successfully purchased ' + data.credits + ' credits.');
                            me.destroy();

                            var ngIframe = document.getElementById('ng-iframe');
                            if(ngIframe){

                                var answerBody = ['buy_more_credits', App.currentCompany.get('credits')];
                                var answerDestination = window.appUrl + '/ng/dist/index.html';

                                if(ngIframe.contentWindow)
                                    ngIframe.contentWindow
                                        .postMessage(answerBody, answerDestination);

                                else if(ngIframe.children[0].contentWindow)
                                    ngIframe.children[0].contentWindow
                                        .postMessage(answerBody, answerDestination);

                            }

                            BootstrapDialog.alert('You have successfully bought credits!');
                        }
                    },

                    error: function (jqXHR, textStatus, errorThrown) {
                        var errorMsg = 'Sorry. An error occurred while processing your payment. Please try again later or contact Flow.City Administrator.';
                        if (jqXHR.status == 402 && jqXHR.responseJSON.reason) {
                            errorMsg = jqXHR.responseJSON.reason
                        }
                        App.vent.trigger('App.layout.paymentStatus.show', errorMsg, 'alert-danger');
                        me.destroy();
                    }
                });


            }

        });
    });
