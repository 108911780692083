// -*- coding: utf-8 -*-

/*global define,appurl*/
define('collections/Profiles',["backbone", "models/Display"],

    function (Backbone) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var Profiles = Backbone.Collection.extend({
            model: Backbone.Model,
            searchUri: '/data/profiles'
        });

        return Profiles;
    });
