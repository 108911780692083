// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/Retailer',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/Retailer', 'backbone'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'retailer',

            modelEvents: {
                'change': 'render'
            },

            events: {
                'click' : '_selectRetailer'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                var me = this;

                if (me.model.get('has_private_screens')) {
                    me.$el.addClass('private');
                }

                me.$el.attr('data-id', me.model.get('uri'));

            },

            _selectRetailer: function (event) {
                var me = this;
                event.preventDefault();
                App.vent.trigger('App.retailers.showDetails', me.model.get('uri'));
            }

        });
    });
