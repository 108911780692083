// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/Invoice',["App", "backbone"],
    function (App, Backbone) {
        'use strict';
        var Invoice = Backbone.Model.extend({
            uriStencil: 'invoices'
        });

        return Invoice;
    }
);
