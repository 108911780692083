
/* START_TEMPLATE */
define('hbs!templates/views/Offers/MultiOfferPreviewItem',['hbs','handlebars','templates/helpers/multiOfferItemThumbnail'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"inner\" style=\"background-image: url("
    + escapeExpression((helper = helpers.multiOfferItemThumbnail || (depth0 && depth0.multiOfferItemThumbnail),options={hash:{},data:data},helper ? helper.call(depth0, depth0, options) : helperMissing.call(depth0, "multiOfferItemThumbnail", depth0, options)))
    + ")\">\n    <div class=\"uploading-progress\">\n        <div class=\"radial-progress\" data-progress=\"0\">\n            <div class=\"circle\">\n                <div class=\"mask full\">\n                    <div class=\"fill\"></div>\n                </div>\n                <div class=\"mask half\">\n                    <div class=\"fill\"></div>\n                    <div class=\"fill fix\"></div>\n                </div>\n                <div class=\"shadow\"></div>\n            </div>\n            <div class=\"inset\">\n                <div class=\"percentage\"></div>\n            </div>\n            <div class=\"inf\"></div>\n        </div>\n    </div>\n    <div class=\"delete-btn\" data-action=\"delete\" data-draft=\"true\">&#10005;</div>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
