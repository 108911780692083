define('ga',['require', 'backbone'],
    function (require, Backbone) {
        // code based on stackoverflow.com/questions/6818814/issue-using-google-analytics-with-require-js
        // old ga.js approach did not work 
        function googleAnalyticsStub (callback) {
            console.debug('analytics.js stub: ', Array.prototype.slice.call(arguments, 0));
            if (callback instanceof Function) { // ga.send(function(tracker){...}) handler
                console.debug('analytics.js stub: callback');
                var trackerStub = { 
                    send: function() { 
                        console.debug('analytics.js stub.send(): ', Array.prototype.slice.call(arguments, 0)); 
                    },
                    set: function() { 
                        console.debug('analytics.js stub.set(): ', Array.prototype.slice.call(arguments, 0)); 
                    },
                    get: function() {
                        console.debug('analytics.js stub.get(): ', Array.prototype.slice.call(arguments, 0)); 
                    }
                };
                return callback(trackerStub);
            }
        };
        var gaWrapper = googleAnalyticsStub;
        
        if (osEnableGA) {
            // Setup temporary Google Analytics objects
            window.GoogleAnalyticsObject = "ga";
            window.ga = function () { (window.ga.q = window.ga.q || []).push(arguments); };
            window.ga.l = 1 * new Date();
            
            var cookieDomain = (window.location.hostname == 'localhost' ? 'none' : 'auto'); 
            
            var gaOptions = {
                allowLinker: true,
                cookieDomain: cookieDomain
            };
            
            /* UserID feature causes loosing statistics in GA since 10.08.2014.
             * Do not enable unless you know what you are doing. 
            if (sessionData.user && sessionData.user.uri) {
                gaOptions.userId = sessionData.user.uri;
            } */
            // This is an old Google Analytics code that is not connected anymore
            //ga('create', 'UA-12949788-4', gaOptions);
            // And this is a new one.
            ga('create', 'UA-73651750-1', gaOptions);
    
            // inpage
            ga('require', 'linkid', 'linkid.js');
            // autolinker for cross-domains
            ga('require', 'linker');
            ga('linker:autoLink', ['flow.city', 'boldmind.co.uk'], false, true);  // false - do not use hash part; true - autolink forms too

            // demographics and interest reports
            ga('require', 'displayfeatures');
            
            // Create a function that wraps `window.ga`.
            // This allows dependant modules to use `window.ga` without knowingly
            // programming against a global object.
            gaWrapper = function () {
                if (gaWrapper.forceStub) {
                    // dirty method to deal with async load error of analytics.js - GA is already returned so must switch it here inside 
                    return googleAnalyticsStub.apply(this, arguments); 
                } else {
                    return window.ga.apply(this, arguments);
                }
            };
        
            // Asynchronously load Google Analytics, letting it take over our `window.ga`
            // object after it loads. This allows us to add events to `window.ga` even
            // before the library has fully loaded.
            try {
                require(["//www.google-analytics.com/analytics.js"], 
                        function gaCallback() {}, 
                        function gaErrback() {
                            console.warn('Could not load //www.google-analytics.com/analytics.js. Falling back to stub to ensure queued callbacks still work.');
                            gaWrapper.forceStub = true;
                        });
            } catch (e) {
                console.warn('Could not launch require //www.google-analytics.com/analytics.js');
                gaWrapper.forceStub = true;
            }
        };
        
        gaWrapper.trackProjectPlanEngagement = function(task, evtAction, evtLabel) {
            var project = task.getProject();
            if (project === undefined || project === null) {
                return;
            }
            
            var GA = gaWrapper,
                tasks = project.getTasks();
            
            // event Engagement:Initiated:Project
            // New Initiated Project: have >= 10 tasks
            if (evtAction == 'Create' && evtLabel == 'Task') {
                console.log('Engagement:Initiated:Project - tasks.length:', tasks.length);
                if (tasks.length == 10) {
                    GA('send', 'event', 'Engagement', 'Initiated', 'Project');
                }
            }

            if (tasks.length >= 10) {
                if (evtAction = 'Edit' && (evtLabel=='Task.owner' || evtLabel == 'Task.due_date')) {
                    // event Engagement:Planned:Project
                    // New Planned Project: have >= 10 tasks AND 50%+ tasks have deadline OR have owner
                    var assignedOrDatedCount = tasks.filter(function filterAssignedOrDatedTasks(tsk) {
                        return tsk.get('due_date') || tsk.get('owner');
                    }).length;
                    
                    console.log('Engagement:Planned:Project - assignedOrDatedCount, tasks.length:', assignedOrDatedCount, tasks.length);
                    if (assignedOrDatedCount >= (tasks.length / 2) && 
                        (assignedOrDatedCount - 1) < (tasks.length / 2) && 
                        (task.get('due_date') || task.get('owner'))) {
                        GA('send', 'event', 'Engagement', 'Planned', 'Project');
                    }
                }

                if (evtAction = 'Edit' && evtLabel == 'Task.status') {
                    // event Engagement:Executed:Project
                    // New Executing Project: have >= 10 tasks AND 25%+ tasks in progress or done
                    var inProgressOrDoneCount = tasks.filter(function filterInProgressOrDoneTasks(tsk) {
                        return tsk.get('status') == 'working' || tsk.get('status') == 'closed'; 
                    }).length;
                    
                    console.log('Engagement:Executed:Project - inProgressOrDoneCount, tasks.length:', inProgressOrDoneCount, tasks.length);
                    if (inProgressOrDoneCount >= (tasks.length / 4) && 
                        (inProgressOrDoneCount - 1) < (tasks.length / 4) &&
                        (task.get('status') == 'working' || task.get('status') == 'closed')) {
                        GA('send', 'event', 'Engagement', 'Executed', 'Project');
                    }

                    // event Engagement:Delivered:Project
                    // New Delivered Projects: have >= 10 tasks AND 80%+ tasks done
                    var doneCount = tasks.filter(function filterInProgressOrDoneTasks(tsk) {
                        return tsk.get('status') == 'closed';
                    }).length;

                    console.log('Engagement:Delivered:Project - doneCount, tasks.length:', doneCount, tasks.length);
                    if (doneCount >= (0.75 * tasks.length) &&
                        (doneCount - 1) < (0.75 * tasks.length) && 
                        task.get('status') == 'closed') {
                        GA('send', 'event', 'Engagement', 'Delivered', 'Project');
                    }
                }
            }
        };
        
        gaWrapper.track = function(url) {
            var GA = gaWrapper;
            if (typeof sessionData != 'undefined') {
                if (sessionData.company) {
                    var companyName = sessionData.company.name || sessionData.company.uri;
                    var planName = sessionData.company.standard_trial ? 'trial' : 'paid.' + (sessionData.company.active_plan && sessionData.company.active_plan.subscription_plan) || '';
                    GA('set', 'dimension1', companyName);
                    GA('set', 'dimension7', sessionData.company.uri);
                    GA('set', 'dimension9', planName);
                }
                if (sessionData.user && sessionData.user.uri) {
                    GA('set', 'dimension2', sessionData.user.uri);
                }
            }
            GA('set', 'dimension3', '1');
            var regClass = App.os.getRegistrationCategory(false); 
            GA('set', 'dimension4', regClass[0]);
            GA('set', 'dimension5', regClass[1]);
            GA('set', 'dimension6', regClass[2]);
            GA('set', 'contentGroup1', 'application');

            /* if (sessionData.user && sessionData.user.uri) {
                GA('set', '&uid', sessionData.user.uri); // Set the user ID using signed-in user_id.
            } */
            
            var referrer = document.referrer;
            if (referrer) {
                var referrersBlackList = {
                    'facebook.com/dialog/oauth': 'facebook',
                    'facebook.com/v1.0/dialog/oauth/write': 'facebook',
                    'facebook.com/checkpoint': 'facebook',
                    'accounts.google.com/o/oauth2/auth': 'google',
                    'accounts.google.com/ServiceLoginAuth': 'google',
                    'linkedin.com/uas/oauth2/authorization': 'linkedin'                    
                };

                var authService = _.find(referrersBlackList, function(v, k) {
                    return referrer.indexOf(k) >= 0;
                });
                
                if (authService) {
                    var authUrl = '/auth-service/' + authService;
                    GA('set', 'referrer', '');
                    GA('set', 'dimension10', referrer);
                    GA('send', 'pageview', authUrl);
                    GA('set', 'referrer', appUrl + authUrl);
                    console.log('Overwritten blacklisted referrer to not split session on auth service', authService, referrer);
                } else {
                    console.log('Referrer is not blacklisted', referrer);
                }
            } else {
                console.log('Referrer not found in document.referrer', document);
            }          
            
            // get path from anchor, unless provided as param
            url = (url !== undefined ? url : Backbone.history.getFragment());
            // track pageview
            url = url.replace(/(\/\d+)/g, '');
            
            GA('send', 'pageview', '/' + url);
            // to properly set page in events sent later
            GA('set', 'page', '/' + url); 
        };
        return gaWrapper;
    });
