// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/AirtimeReport',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/AirtimeReport', 'backbone', 'moment',
        'views/Reports/SectionDisplayGroups',
        'views/Reports/SectionDateRangeSelector',
        'views/Reports/SectionOfferList',
        'behaviors/Intercom'],

    function (App, $, _, template, Backbone, moment,
              SectionDisplayGroups,
              SectionDateRangeSelector,
              SectionOfferList) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            events: {
                'click .generate-report-btn': '_generateReport',
                'change [name="granularity_time"]': '_updateReportParam',
                'change [name="granularity_geo"]': '_updateReportParam'
            },

            _updateReportParam: function (event) {
                if (event) {
                    var param = $(event.currentTarget);
                    this.reportParams[param.attr('name')] = param.val();
                }
            },

            regions: {
                sectionDisplayGroups: '[data-region="section-display-groups"]',
                sectionDateRangeSelector: '[data-region="section-date-range-selector"]',
                sectionOfferList: '[data-region="section-offer-list"]'
            },

            onRender: function () {
                this.$el.fadeIn('fast');
                this.reportParams = {
                    'type': 'DbReportAirtime',
                    'display_groups': [],
                    'granularity_time': 'all',
                    'granularity_geo': 'all',
                    'offers': []
                };
            },

            onShow: function () {
                var me = this;
                me.sectionDisplayGroups.show(new SectionDisplayGroups({parent: me}));
                me.sectionDateRangeSelector.show(new SectionDateRangeSelector({parent: me}));
                me.sectionOfferList.show(new SectionOfferList({parent: me}));
            },

            _generateReport: function () {
                var report = App.currentCompany.getReports().create(this.reportParams, {
                    wait: true,
                    silent: true,
                    success: function() {
                        intercomReportEvent('airtime_report');
                        App.notifyBrowser('Started report generation', 'Your report is being generated. You can see the status of your report in Generated Reports tab.');
                    },
                    error: function() {
                        App.notifyBrowser('Report generation error', 'An error occurred during report generation. Please try again or contact Flow.City administrator.');
                    }
                });
                App.vent.listenTo(report, 'change', function (model, options) {
                    if (options.fromChannel && model.get('ready_state') == 'ready') {
                        App.notifyBrowser('Report generation finished', 'Your report is ready!');
                    }
                });
            }

        });
    });
