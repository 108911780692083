
/* START_TEMPLATE */
define('hbs!templates/views/PasswordReset/PasswordChangeError',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div id=\"login-page\">\n	  	<div class=\"container\">\n\n            <div class=\"top-logo\">\n                <img src=\"/static/images/flow-ads-logo-cover.png\" alt=\"Flow.Ads\">\n            </div>\n\n              <form class=\"form-login\" id=\"forgot-password-form\" method=\"post\" action=\"";
  if (helper = helpers.appurl) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.appurl); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "/data/password-resets\" target=\"_parent\">\n		        <h2 class=\"form-login-heading\" style=\"background-color: red;\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "password change error", options) : helperMissing.call(depth0, "trans", "password change error", options)))
    + "</h2>\n		        <div class=\"login-wrap\">\n                    <p>An error occurred while updating your password.</p>\n                    <p>Please contact Administrator for further instructions.</p>\n                    <p class=\"text-center\"><a href=\"#login\">Back to login page</a></p>\n		        </div>\n\n\n		      </form>\n\n	  	</div>\n	  </div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
