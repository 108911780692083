// -*- coding: utf-8 -*-

/*jslint nomen: true*/
define('models/Forecast',["App",
        "backbone"],
    function (App,
              Backbone) {

        'use strict';
        var Forecast = Backbone.Model.extend({
            uriStencil: 'forecasts'

        });

        return Forecast;
    }
    );
