// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryAIMDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryAIMDesc(obj) {
        var output = "",
            strMap = {
                'male' : "men",
                'female' : "women",
                'child' : "under 24's",
                'young_adult' : "young adults",
                'older_adult' : "adults",
                'senior' : "elderly people"
            };

        if (!_.isUndefined(obj)) {
            obj.active && (output = '<br>and will increase priority ');
            if (!_.isEmpty(obj.gender)) {
                output += '<br>when the ratio of <b>' + strMap[obj.gender] + '</b> that is greater than <b>' + obj.gender_range[0] + '%</b>'
            }

            if (!_.isEmpty(obj.age)) {
                output += '<br>' + (!_.isEmpty(obj.gender) ? ' and ' : 'when the ratio ');
                output += 'shows <b>' + obj.age_range[0] + '%</b> of <b>' + strMap[obj.age] + '</b>';
            }

            obj.active && (output += ' of all audience detected');

        }

        return output;
    }

    Handlebars.registerHelper('summaryAIMDesc', summaryAIMDesc);
    return summaryAIMDesc;
});

