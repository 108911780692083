// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferPreview',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferPreview', 'backbone', 'jquery.zoomer'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            previewWidth: 400,

            modelEvents: {
                'change:media_file': '_refreshPreview'
            },

            aspectRatio: [16,9],

            ui: {
                aspectRatioSelector: '.aspect-ratio-selector',
                offerPreviewOverlay: '.offer-preview-overlay',
                iFramePraview: '#offer-preview',
                offerPreview: '.offer-preview-window',
                staticPreview: '.static-preview-container',
                downloadBtn: '.download-mediafile-btn',
                zoomerSmall: '.zoomer-small',
                placeholder: '.offer-preview-placeholder'
            },

            events: {
                'change input[name="aspectRatio"]' : '_previewAspectRatio',
                'click @ui.offerPreviewOverlay' : '_zoomArtwork',
                'click @ui.downloadBtn' : '_downloadMediaFile',
                'change #fileToUpload': '_startUpload',
                'mouseenter video': 'enterVideo',
                'mouseleave video': 'leaveVideo'
            },

            initialize: function () {
                var me = this;
                me.listenTo(App.vent, 'App.layout.offer.refreshPreview', _.bind(me._refreshPreview, me));
                me.listenTo(App.vent, 'App.offer.artwork.download', _.bind(me._downloadMediaFile, me));
            },
            onShow:function(){
                var me = this;


            },

            getPreviewWidth: function() {
                var me = this;
                me.previewWidth = parseInt($(me.ui.offerPreview).closest('.offer-preview').width());
                if (me.previewWidth===0) { me.previewWidth=270; }
                if (me.previewWidth>500) { me.previewWidth =400; }
            },

            _onRender: function() {
                var me = this,
                    el,
                    aspectRatio = me.aspectRatio || [16,9];
                me.getPreviewWidth();
                var offer_variant = App.currentOffer.get('offer_variant');
                switch(offer_variant){
                    case 'taxi_top':
                        aspectRatio = [640,192];
                        break;
                    case 'd6_portrait':
                        aspectRatio = [1080,1920]; me.previewWidth =100;
                        break;
                    case '36_iconic':
                        aspectRatio = [2732,1152];
                        break;
                    case '48_roadside':
                        aspectRatio = [1280,448];
                }

                //$('.offer-preview-window').css({'width':me.previewWidth});
                if (App.currentOffer.get('offer_type') !== 'video') {

                        me.ui.staticPreview.remove();
                        el = me.$el.find('input[name="aspectRatio"][value="' + aspectRatio.join(':') +'"]');
                        el.prop('checked');
                        el.parent().addClass('active');
                        var height  =  Math.round((aspectRatio[1] * me.previewWidth)/aspectRatio[0]);
                        /*$.ajax({
                            url: App.enrichApiUrl(me.model.get('uris').Render),
                            type: 'GET'
                        }).done(function(data) {
                           me.$el.find('iframe').attr('srcdoc',data);
                        });
                        console.log(me.previewWidth);
                        console.log(height);
                        me.$el.find('iframe').zoomer({
                            zoom: 1,
                            width: me.previewWidth,
                            height: height
                        });
                        */
                        var mediaSrc = App.enrichApiUrl(me.model.get('media_file')+'&os_user='+window.sessionStorage.getItem('X-FlowCity-Session-User'));

                        $(me.ui.offerPreview).css({
                            'width':me.previewWidth,
                            'height':height,
                            'background':'url('+mediaSrc+') no-repeat center center',
                            'background-size':'cover',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'margin-bottom': '10px'
                        });
                    /*
                    if (!App.currentOffer.getMediaRequirements()) {
                    } else {
                        me.ui.iFramePraview.remove();
                        me.ui.aspectRatioSelector.remove();
                        me.ui.offerPreviewOverlay.remove();
                        me._setStaticPreviewSize();
                    }
                    */
                } else {
                        me.ui.staticPreview.remove();
                        el = me.$el.find('input[name="aspectRatio"][value="' + aspectRatio.join(':') +'"]');
                        el.prop('checked');
                        el.parent().addClass('active');
                        var height  =  Math.round((aspectRatio[1] * me.previewWidth)/aspectRatio[0]);

                    //     $.ajax({
                    //         url: App.enrichApiUrl(me.model.get('uris').Render),
                    //         type: 'GET'
                    //     }).done(function(data) {
                    //         //alert(data);
                    //        me.$el.find('iframe').attr('srcdoc',data);
                    //     });
                    //
                    //     me.$el.find('iframe').zoomer({
                    //         zoom: 1,
                    //         width: me.previewWidth,
                    //         height: height
                    //     });


                    var mediaSrc = App.enrichApiUrl(me.model.get('media_file')+'&os_user='+window.sessionStorage.getItem('X-FlowCity-Session-User'));

                    // $(me.ui.offerPreviewOverlay).find('.zoomer-small').css({'width':me.previewWidth, 'height':height});
                        setTimeout(function() {
                            $(me.ui.offerPreview).css({'width': me.previewWidth + 'px', 'height': (height  + 30)+ 'px', 'margin-left': 'auto' ,'margin-right': 'auto', 'margin-bottom': '10px'});
                        }, 0);

                        if (!me.model.get('media_file_transcode_inprogress') && me.model.get('media_file')) {
                            me.ui.placeholder.remove();
                            $(me.ui.offerPreviewOverlay).css({'overflow': 'hidden'})
                                .append(
                                    '<video style="height: 100%; width: 100%; background-color: black;">' +
                                    '<source src="' + mediaSrc + '" type="video/mp4">' +
                                    '</video>');
                        } else if (me.model.get('media_file_transcode_inprogress')) {
                            var checkTranscodingState = function (bypassChecking) {
                                if (bypassChecking || (window._checkingTranscoding===undefined || !window._checkingTranscoding)) {
                                    window._checkingTranscoding = true;
                                    console.log('Checking transcoding state');
                                    $.when(me.model.fetch()).then(function (offer) {
                                        if (!offer.media_file_transcode_inprogress) {
                                            delete(window._checkingTranscoding);
                                            console.log('=> transcoding finished');
                                            me.model.set(offer);
                                            App.vent.trigger('App.layout.offer.refreshPreview');
                                        } else {
                                            console.log('=> transcoding NOT finished');
                                            setTimeout(function () {
                                                checkTranscodingState(true)
                                            }, 2000);
                                        }
                                    });
                                }
                            };

                            checkTranscodingState();
                        }

                        // var t = setTimeout(function(){
                        //     if(window.refreshFlag<1 || window.refreshFlag==undefined){
                        //         App.vent.trigger('App.layout.offer.refreshPreview');
                        //         window.refreshFlag=1;
                        //     }
                        // },30000);
                }

                if (App.currentOffer.get('offer_type') !== 'video') {
                    this.$el.find('#fileToUpload').attr('accept', 'image/png,image/jpg,image/jpeg');
                } else {
                    this.$el.find('#fileToUpload').attr('accept', 'video/mp4,video/x-m4v,video/*');
                }


            },

            onRender: function () {
                var me = this;
                setTimeout(function() { me._onRender(); }, 0);
                me._manageDraftMode();
            },

            leaveVideo: function(){
                $('video')[0].removeAttribute("controls")
            },

            enterVideo: function(){
                $('video')[0].setAttribute("controls", "controls")
            },

            _refreshPreview: function () {
                this.render();
            },

            _zoomArtwork: function () {
                var me = this;
                if (me.model.get('offer_type') === 'image') {
                    App.vent.trigger('App.offer.artwork.zoom');
                } else {
                    App.vent.trigger('App.offer.artwork.download');
                }
            },

            _previewAspectRatio: function (event) {
                if (App.currentOffer.get('offer_type') !== 'link') {
                    var me = this;
                    me.aspectRatio = $(event.currentTarget).val().split(':');
                    App.vent.trigger('App.layout.offer.refreshPreview');
                }
            },

            _downloadMediaFile: function (event) {
                event && event.preventDefault();
                var me = this;
                console.log(App);
                var mediaSrc = App.enrichApiUrl(me.model.get('media_file')+'&os_user='+window.sessionStorage.getItem('X-FlowCity-Session-User'));
                if (App.os.isAppleMobile()) {
                    // workaround for iOS devices
                    window.open(mediaSrc, '_blank');
                } else {
                    $('#download-frame').attr('src', mediaSrc);
                }
            },

            _startUpload: function () {
                console.log(11111);
                App.vent.trigger('App.offer.artwork.upload');
            },

            _manageDraftMode: function () {
                var me = this;
                if (App.currentOffer.isDraft()) {
                    me.$el.find('[data-draft="true"]')
                        .prop('disabled', false)
                        .removeClass('hidden');
                    me.$el.find('[data-draft="false"]').remove();
                }
            },

            _setStaticPreviewSize: function () {
                var me = this,
                    cssStyles = {};

                if (App.currentOffer.getMediaRequirements()) {
                    if (App.currentOffer.getMediaRequirements()['width']) {
                        cssStyles['width'] = 300;

                        // Rescale the image to fit the preview window
                        if (App.currentOffer.getMediaRequirements()['height']) {
                            cssStyles['height'] = Math.round(App.currentOffer.getMediaRequirements()['height'] * (me.previewWidth / App.currentOffer.getMediaRequirements()['width']));
                        }

                        // apply CSS styles
                        me.ui.staticPreview.css(cssStyles);
                    }

                }
            }


        });
    });
