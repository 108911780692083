// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/PasswordReset/ForgotPasswordChange',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore', 'hbs!templates/views/PasswordReset/ForgotPasswordChange', 'jquery-validation'],
    function (App, Backbone, Marionette, $, _, template) {
        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,
            model: new Backbone.Model(),

            className: 'form-panel profile-form col-md-12',

            events: {
                "click #std-password-reset-btn": "login"
            },

            login: function() {
                $.ajax({
                    url: App.enrichApiUrl('/data/') + Backbone.history.fragment,
                    type: 'POST',
                    data: this.$el.find("#forgot-password-change-form").serialize(),
                    includeSessHeader: false
                }).done(function (data, status, request) {
                    $.cookie('X-FlowCity-Session-User', request.getResponseHeader('X-FlowCity-Session-User'), { expires: 7*500, path: '/' });
                    App.os.initializeTabSession('X-FlowCity-Session-User', true);
                    window.location.href = App.appUrl;
                })
                .fail(function () {
                    window.location.href = App.appUrl + "/#password-change-error"
                });
            },

            onRender: function () {
                var me = this,
                    form = me.$el.find("#forgot-password-change-form");

                $('body').css('background', 'transparent');


                form.validate({
                    errorElement: "small",
                    errorClass: 'error',
                    onkeyup: false,
                    onfocusin: false,
                    onfocusout: function (element) {
                        $(element).valid();
                    },
                    rules: {
                        password: {
                            required: true
                        },
                        passwordRetyped: {
                            required: true,
                            equalTo: "#password"
                        }
                    },
                    messages: {
                        password: {
                            required: "Enter your new password"
                        },
                        passwordRetyped: {
                            required: "Enter again your new password"
                        }
                    }
                });

                $.backstretch("/static/images/login_background.jpg", {speed: 500});
            }

        });
    });
