
/* START_TEMPLATE */
define('hbs!templates/views/Payments/CreditsPurchase',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <label class=\"btn btn-default col-xs-3\">\n                <input type=\"radio\" name=\"credits-package\" autocomplete=\"off\" value=\""
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">\n                <div class=\"credits-circle\">\n                    <div class=\"credits-abs\">\n                        <span>"
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n                        <br>credits\n                    </div>\n                </div>\n                <div class=\"credits-cost\">&pound;"
    + escapeExpression(((stack1 = (depth0 && depth0.credits)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n                <p class=\"credits-description\" content=\""
    + escapeExpression(((stack1 = (depth0 && depth0.description)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"></p>\n            </label>\n        ";
  return buffer;
  }

  buffer += "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-header\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">Buy more credits</div>\n            <p>Choose the package</p>\n        </div>\n    </div>\n\n    <div class=\"modal-body\">\n\n        <div class=\"credits-package row\" data-toggle=\"buttons\">\n        ";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 && depth0.creditsFeeExtended)),stack1 == null || stack1 === false ? stack1 : stack1.GBP), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n\n\n    </div>\n\n    <div class=\"modal-footer\">\n        <div class=\"credits-info-container\" style=\"display: none;\">\n            <p>\n                You are going to buy <span id=\"credits-info\"></span> credits.\n                Request an invoice. Your credits will be updated automatically when payment is received.\n            </p>\n        </div>\n        <p>* All prices exclude VAT.</p>\n        <button type=\"button\" class=\"btn btn-default cancel-credits-btn\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Cancel", options) : helperMissing.call(depth0, "trans", "Cancel", options)))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary buy-credits-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">Buy</span></button>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
