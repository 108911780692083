// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Displays/DisplayGroups',['App',
        'backbone',
        'hbs!templates/views/Displays/DisplayGroups',
        'views/Displays/DisplayGroup'
        ],
    function (
        App,
        Backbone,
        template,
        DisplayGroup
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            viewComparator: 'sortViewByDisplayName',

            childView: DisplayGroup,

            childViewContainer: 'ul',

            collectionEvents: {
                //'reset' : '_mergeWithTargets'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'li',

                    getTemplate: function () {
                        var templateHtmlNoScreens = '<i class="fa fa-spin fa-spinner"></i>',
                            templateHtmlDemo = '<li class="display no-display-groups"><a href="#profile/payments"><div class="inner"><h2>DEMO MODE<small>Upgrade your plan</small></h2></div></a></li>',
                            template;

                        if (!App.currentCompany.isSetupFeePaid()) {
                            template = Handlebars.compile(templateHtmlDemo);
                        } else {
                            template = Handlebars.compile(templateHtmlNoScreens);
                        }
                        return template;
                    }

                });

                return new EmptyView();
            },

            sortViewByDisplayName: function (model) {
                return model.get('display_name');
            },

            setPanelAvailability: function() {
                // disable selection on transcoding
                if (App.currentOffer && App.currentOffer.get('media_file_transcode_inprogress')) {
                    console.log('disabled');
                    this.$el.css({pointerEvents: 'none'});
                } else {
                    this.$el.css({pointerEvents: 'initial'});
                }
            },

            setOfferDisplayGroups: function () {
                var me = this;

                if (App.currentOffer) {
                    App.currentOffer.getOfferTargets();

                    $.when(App.currentUser.getApproverDisplayGroups().promise).then(function () {
                        if (App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER) {
                            me.baseCollection = App.currentUser.getApproverDisplayGroups();
                        }
                        else if(App.currentUser.getDepartments().get(App.currentOffer.get('department')) != undefined) {
                            me.baseCollection = App.currentUser.getDepartments().get(App.currentOffer.get('department')).getDisplayGroups();
                        }

                        if(me.baseCollection != undefined){
                            $.when(App.currentOffer.getOfferTargets().promise, me.baseCollection.promise).then(function () {
                                me.collection.reset(me.baseCollection.models);
                                me.listenTo(me.baseCollection, 'add remove', _.bind(me._resetCollection, me));
                                me._mergeWithTargets();
                            });
                        }
                    });
                }
            },

            initialize: function (options) {
                var me = this,
                    disabled = options && options.disabled;

                me.collection = new Backbone.Collection();
                me.listenTo(App.vent, 'App.currentOffer.scheduleSummary.update', _.bind(me.setOfferDisplayGroups, me));
                me.listenTo(App.vent, 'App.displays.refresh', _.bind(me.setOfferDisplayGroups, me));
                me.listenTo(App.vent, 'App.layout.offer.refreshPreview', _.bind(me.setPanelAvailability, me))
                // me.listenTo(App.vent, 'App.displays.hideMenu', _.bind(me._hideDisplayMenus, me));
            },

            onRender: function () {
                var me = this;

                if (!_.isUndefined(App.currentOffer)) {
                    $.when(App.currentOffer.getOfferTargets().promise).then(function () {

                        if (App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER && _.size(_.where(me.children._views, function (childView) { return childView.$el.hasClass('display'); })) === 0) {
                            App.currentOffer.deleteOfferTargetsCollection();
                            App.currentCompany.getSubmittedOffers().remove(App.currentOffer, {silent: true});
                            App.appRouter.navigate('submitted-offers', {trigger: true});
                        }
                    });
                }


                if (me.collection.size() === 0) {
                    me.$el.find('.no-items').html("No screens found");
                }

            },

            prepareSingleAvailabilityTest: function (descriptionString) {
                var noFunc = function () { return false; };

                var elements = descriptionString.split(',');
                if (elements.length === 0) {
                    console.warn("Empty description string for availability test.");
                    return noFunc;
                }

                var testName = elements[0];
                var args = elements.slice(1);

                var tests = {
                    "type": function (scheduleType) {
                        return function (offer) {
                            return _.chain(offer.getOfferSchedules().models)
                                .filter(function (item) { return item.attributes.active; })
                                .pluck('attributes')
                                .pluck('type')
                                .contains(scheduleType);
                        };
                    },
                    "now": function () {
                        return function (offer) {
                            return _.find(offer.getOfferSchedules().models, function (item) {
                                return item.attributes.type === 'DbOfferScheduleTime';
                            }).attributes.active === false;
                        };
                    }
                };

                var func = tests[testName];
                if (func === undefined) {
                    console.error("Invalid test name: " + testName);
                    return noFunc;
                } else {
                    return func.apply(this, args);
                }
            },

            prepareAvailabilityTests: function (displayGroup) {
                var mapDescription = _.bind(this.prepareSingleAvailabilityTest, this);
                var whitelist = _.map(displayGroup.attributes.schedule_whitelist, mapDescription);
                var blacklist = _.map(displayGroup.attributes.schedule_blacklist, mapDescription);

                return function (offer) {
                    var runTest = function (test) { return test(offer); };
                    return _.all(whitelist, runTest) && (whitelist.length > 0 || !_.any(blacklist, runTest));
                };
            },

            filter: function (child, index, collection) {
                if (!App.currentCompany.isSetupFeePaid() || _.isUndefined(App.currentOffer)) {
                    return false;
                }

                if (!this.prepareAvailabilityTests(child)(App.currentOffer))
                    return false;

                if (App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER) {
                    return (child.isApprover() && App.currentOffer.getOfferTargets().getByDisplayGroupUri(child.get('uri')) && App.currentOffer.getOfferTargets().getByDisplayGroupUri(child.get('uri')).get('status') === 'submitted');
                } else {
                    if (!_.isEmpty(App.currentOffer.get('offer_variant'))) {
                        return child.get('external_consumer') === App.currentOffer.get('offer_variant');
                    } else {
                        // return _.isEmpty(child.get('external_consumer')) || child.get('external_consumer') === 'broadsign';
                        return true
                    }
                }
            },

            onBeforeAddChild: function (childView) {
                if (!childView.model.get('active')) {
                    childView.$el.hide();
                } else {
                    childView.$el.show();
                }
            },

            _mergeWithTargets: function () {
                var me = this,
                    target;

                if (!_.isUndefined(App.currentOffer)) {

                    me.collection.each(function (targetGroup) {

                        target = App.currentOffer.getOfferTargets().getByDisplayGroupUri(targetGroup.get('uri'));

                        if (_.isObject(target)) {
                            target.getScheduleStatus();
                            targetGroup.set('status', target.get('status'));
                            targetGroup.set('display_time', target.get('display_time'));

                        } else {
                            targetGroup.set('status', 'draft');
                            targetGroup.unset('display_time');
                        }
                    });

                    me.render();

                }
            },

            _resetCollection: function () {
                var me = this;
                me.collection.reset(me.baseCollection.models);
            },

            _hideDisplayMenus: function (currentViewUri) {
                var me = this;
                if (!_.isUndefined(currentViewUri)) {

                    App.vent.trigger('App.layout.loadingMask.show', function loadingMaskOnClick() {
                        me.children.each(function (view) {
                            view.$el.removeClass('display__active-menu display__not-active');
                            App.vent.trigger('App.layout.loadingMask.hide');
                        });
                    });

                    me.children.each(function (view) {
                        if (view.model.get('uri') !== currentViewUri) {
                            view.$el
                                .removeClass('display__active-menu')
                                .addClass('display__not-active');
                        }
                    });
                } else {
                    if (_.size(me.children.filter(function (view) { return view.$el.hasClass('display__active-menu'); })) === 0) {
                        App.vent.trigger('App.layout.loadingMask.hide');
                        me.children.each(function (view) {
                            view.$el.removeClass('display__not-active');
                        });
                    }
                }
            }


        });
    });

