
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/EyeTrackingWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"widget__header\">\n    <h5><span class=\"icon icon--camera\"></span>Camera: Views</h5>\n</div>\n\n<div class=\"widget__body\">\n    <div>\n        <div class=\"eye-tracking__duration\">\n            <figure></figure>\n            <div class=\"desc__duration\">\n                <span class=\"value\">0<span>%</span></span>\n                <div>Dwell</div>\n            </div>\n        </div>\n        <div class=\"eye-tracking__attention\">\n            <figure></figure>\n            <div class=\"desc__duration\">\n                <span class=\"value\">0<span>%</span></span>\n                <div>Attention</div>\n            </div>\n        </div>\n    </div>\n\n    <span class=\"widget__loader\">\n        <div class=\"spinner\"></div>\n    </span>\n\n    <span class=\"widget__body--no-camera\">No camera selected</span>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
