// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog*/
define('views/ApplicationLayout/ApplicationSearchBar',['App', 'backbone', 'backbone.marionette', 'hbs!templates/views/ApplicationLayout/ApplicationSearchBar', 'switchery', 'bootstrap-select', 'magicsuggest'],
    function (App, Backbone, Marionette, template, Switchery) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            _config: {},

            filter: ['created'],

            filterValues: {},

            ui: {
                searchQuery: '#app-search-query',
                sortBySelector: '#app-search-query-order',
                queryFilter: '#app-search-query-filter'
            },

            events: {
                'keyup @ui.searchQuery': 'onSearchQueryChangeDebounced',
                'change @ui.sortBySelector': 'onSearchQueryChangeDebounced'
            },

            initialize: function (options) {
                this._config = options && options.config;
                this.onSearchQueryChangeDebounced = _.debounce(this.onSearchQueryChange, 1000);
                this.searchBarId = Backbone.history.getFragment();

                if (_.isUndefined(App.searchBarConfig[this.searchBarId])) {
                    App.searchBarConfig[this.searchBarId] = {};
                    App.searchBarConfig[this.searchBarId]['sortBy'] = '-created';
                }
                // this.filterValues['sort'] = '-created';
            },

            onRender: function () {
                var me = this;

                // update query from stored data
                me._getStoredValue(me._config.fields[0]) && me.ui.searchQuery.val(me._getStoredValue(me._config.fields[0]));

                // Setup sorting field

                if (me._config.sortBy) {
                    var grp = $('<optgroup label="Order by">');
                    _.each(me._config.sortBy, function (value, key, list) {
                        if(value!='Oldest'){
                            grp.append('<option data-icon="fa-arrow-down" value="' + key + '">' + value + ' Ascending</option>');
                            grp.append('<option data-icon="fa-arrow-up" value="-' + key + '">' + value + ' Descending</option>');
                            grp.append('<option data-divider="true"></option>');
                        } else {
                            grp.append('<option data-icon="fa-arrow-down" value="' + key + '">Oldest</option>');
                            grp.append('<option data-icon="fa-arrow-up" value="-' + key + '">Newest</option>');
                            grp.append('<option data-divider="true"></option>');                            
                        }
                    });
                    me.ui.sortBySelector.append(grp);
                    me.ui.sortBySelector.selectpicker({
                        iconBase: 'fa',
                        tickIcon: 'fa-check'
                    });
                    me._getStoredValue('sortBy') && me.ui.sortBySelector.selectpicker('val', me._getStoredValue('sortBy'));
                    me.ui.sortBySelector.selectpicker('render');
                } else {
                    me.ui.sortBySelector.parent().remove();
                }

                // Setup filters
                if (me._config.filter) {
                    _.each(me._config.filter, function (filterConfig) {
                        if (filterConfig.type === 'magicsuggest') {
                            me._setupMagicSuggestFilter(filterConfig)
                        } else if (filterConfig.type === 'checkbox') {
                            me._setupCheckboxFilter(filterConfig);
                        } else {
                            return;
                        }
                    });
                }

            },

            onSearchQueryChange: function () {
                var me = this;

                if (me && !me.isDestroyed) {

                    var searchString = me.ui.searchQuery.val(),
                        sortBy = me.ui.sortBySelector.val(),
                        query = {};

                    console.debug('## Search query updated', searchString);

                    if (!_.isEmpty(searchString)) {
                        _.each(me._config.fields, function (field) {
                            query[field] = me.ui.searchQuery.val()
                        });
                    }

                    query = $.extend(query, me.filterValues);

                    App.searchBarConfig[me.searchBarId] = _.clone(query);
                    App.searchBarConfig[me.searchBarId].sortBy = sortBy;

                    App.vent.trigger('App.search.query', query, sortBy);
                }
            },

            onShow: function () {
                var me = this;
                _.delay(function () {
                    me.$el.find('.js-switch').each(function (idx, el) {
                        new Switchery($(el)[0], {color: '#5bc0de', size: 'small'});
                    });
                    me.$el.find('.dropdown-toggle').dropdown();
                }, 100);
            },

            _getStoredValue: function (field) {
                var me = this,
                    result;
                if (App.searchBarConfig[me.searchBarId]) {
                    if (App.searchBarConfig[me.searchBarId][field]) {
                        result = App.searchBarConfig[me.searchBarId][field];
                    }
                }
                return result;
            },

            _setupMagicSuggestFilter: function (config) {
                var me = this;
                me.ui.searchQuery.parent().after('<div style="width: 200px;"><div data-filter="' + config.field + '"></div></div>');
                me.filter[config.field] = me.$el.find('div[data-filter="' + config.field + '"]').magicSuggest({
                    allowFreeEntries: false,
                    hideTrigger: true,
                    minChars: 1,
                    placeholder: config.placeholder || 'Start typing',

                    queryParam: 'dummy',
                    method: 'get',
                    displayField: 'value',
                    valueField: 'uri',
                    data: App.enrichApiUrl(config.url),
                    resultsField: 'objects',
                    maxSelection: 1,
                    cls: 'search-bar-selector'
                });

                $(me.filter[config.field]).on('beforeload', function(c){
                    var data = {};
                    if (!_.isEmpty(me.filter[config.field].getRawValue())) {
                        data[config.searchField] = me.filter[config.field].getRawValue();
                    }
                    me.filter[config.field].setDataUrlParams({ query: JSON.stringify(data) });
                });

                $(me.filter[config.field]).on('selectionchange', function () {
                    if (!_.isEmpty(this.getValue()[0])) {
                        me.filterValues[config.field] = this.getValue()[0];
                    } else {
                        delete me.filterValues[config.field];
                    }
                    me.onSearchQueryChangeDebounced();
                });

            },

            _setupCheckboxFilter: function (config) {
                var me = this;
                me.ui.searchQuery.parent().after('<div><label class="search-bar-checkbox">' + config.placeholder + '&nbsp;&nbsp;<input type="checkbox" name="' + config.field + '" class="js-switch" data-filter="' + config.field + '" /> <div data-filter="' + config.field + '"></div></label></div>');

                me.filter[config.field] = me.$el.find('[data-filter="' + config.field + '"]');

                $(me.filter[config.field]).on('change', function () {
                    if ($(me.filter[config.field]).is(':checked')) {
                        console.debug('CHECKED');
                        me.filterValues[config.field] = 'True';
                    } else {
                        console.debug('UNCHECKED');
                        delete me.filterValues[config.field];
                    }
                    me.onSearchQueryChangeDebounced();
                });

                me._getStoredValue(config.field) && me._getStoredValue(config.field) === 'True' && $(me.filter[config.field]).prop('checked', 'checked');
            },

            onDestroy: function () {
                delete App.searchBar;
            }

        });
    }
);

