// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Retailers/DepartmentLocationAdd',['App', 'backbone', 'backbone.marionette', 'hbs!templates/views/Retailers/DepartmentLocationAdd'
         ],
    function (App, Backbone, Marionette, template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template
        });
    });

