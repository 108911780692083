// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/subscriptionPlan',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function subscriptionPlan() {
        var options = arguments[arguments.length - 1],
            plans = [];

        _.each(arguments, function (el) { _.isString(el) && plans.push(el); });

        if (($.inArray(App.currentCompany.getActivePlan(), plans)) > -1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    }
    Handlebars.registerHelper('subscriptionPlan', subscriptionPlan);
    return subscriptionPlan;
});
