// -*- coding: utf-8 -*-


/*global define*/
define('collections/Locations',["backbone",
        "models/Location"],
    function (Backbone,
              Location
              ) {

        'use strict';
        return Backbone.Collection.extend({
            model: Location
        });
    });
