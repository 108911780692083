// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/DisplayGroups',["backbone", "models/DisplayGroup"],
    function (Backbone, DisplayGroup) {

        'use strict';
        var DisplayGroups = Backbone.Collection.extend({
            url: '/data/display-groups',
            model: DisplayGroup,

            comparator: function (model) {
                return model.get('display_name');
            },

            getList: function () {
                return this.map(function (group) {
                    return {value: group.get('uri'), text: group.get('display_name')}
                });
            },

            hasExternalConsumerGroup: function (extConsumer) {
                return !_.isUndefined(this.find(function (group) { return group.get('external_consumer') === extConsumer; }));
            },

            getById: function (id) {
                return this.find(function (grp) { return grp.get('name') === parseInt(id); });
            }
        });

        return DisplayGroups;
    });
