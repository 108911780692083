
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionDateRangeSelector',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Select report range</h4>\n\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange1\" value=\"this-week\" checked><label for=\"dateRange1\">This week</label></div>\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange2\" value=\"last-week\"><label for=\"dateRange2\">Last week</label></div>\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange3\" value=\"this-month\"><label for=\"dateRange3\">This month</label></div>\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange4\" value=\"last-month\"><label for=\"dateRange4\">Last month</label></div>\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange5\" value=\"this-year\"><label for=\"dateRange5\">This year</label></div>\n<div class=\"radio radio-info\"><input type=\"radio\" name=\"dateRange\" id=\"dateRange6\" value=\"custom\"><label for=\"dateRange6\">Custom range:</label></div>\n\n<div class=\"input-daterange input-group\" id=\"datepicker\" style=\"display: none\">\n    <form class=\"form-horizontal\">\n        <div class=\"form-group\">\n            <label for=\"dateRangeFrom\" class=\"col-sm-2 col-sm-push-1 control-label\">From:</label>\n            <div class=\"col-sm-9 col-sm-push-1\">\n                <input class=\"form-control report-cal\" name=\"start\" id=\"dateRangeFrom\"/>\n            </div>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"dateRangeTo\" class=\"col-sm-2 col-sm-push-1 control-label\">To:</label>\n            <div class=\"col-sm-9 col-sm-push-1\">\n                <input class=\"form-control report-cal\" name=\"end\" id=\"dateRangeTo\"/>\n            </div>\n        </div>\n    </form>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
