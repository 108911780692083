
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentUserApproverGroups',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h5>This user can approve offers in the following groups</h5>\n<table class=\"table\">\n    <thead>\n        <tr>\n            <th>Approver display group name</th>\n            <th class=\"opt text-center\"></th>\n        </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n<p>Add approver display groups:</p>\n<div class=\"row\">\n    <div class=\"col-lg-6\">\n        <div class=\"suggest-display-groups\"></div>\n    </div>\n    <div class=\"col-lg-6\">\n        <button class=\"btn btn-default addGroupsBtn\" disabled>Add groups</button>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
