// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleEPos',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleEPos', 'jquery-knob'],
    function (App, $, _, Backbone, _OfferScheduleBase, template) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'click .btn-calendar' : 'btnCalendarHandler'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                setTimeout(function () {
                        me.setInitialButtonsStates();
                    },
                    100);

                return result;

            },

            setInitialButtonsStates: function () {
                var me = this;

                me._setRadioState('stock_level', me.model.get('stock_level'));
                me._setRadioState('shelf_live', me.model.get('shelf_live'));
                me._setRadioState('desirability', me.model.get('desirability'));
                if(me.model.get('tracking'))
                    $('#onEPosTrackingLabel').addClass('active');
                else
                    $('#offEPosTrackingLabel').addClass('active');
                if(me.model.get('monitor'))
                    $('#onEPosMonitorLabel').addClass('active');
                else
                    $('#offEPosMonitorLabel').addClass('active');
            },

            btnCalendarHandler: function (event) {
                var me = this,
                    handler = event.currentTarget,
                    feature = $(handler).find('input').attr('name'),
                    handlerValue = $(handler).find('input').attr('value');

                $('#'+feature+'Ratio').attr('data-value', handlerValue)
                    .data('value', handlerValue);
                me.model.set(feature, handlerValue);
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('sensor'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            }

        });
    });
