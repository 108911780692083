
/* START_TEMPLATE */
define('hbs!templates/views/PasswordReset/PasswordInstructionsSent',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"login-page\">\n	  	<div class=\"container\">\n\n            <div class=\"top-logo\">\n                <img src=\"/static/images/flow-ads-logo-cover.png\" alt=\"Flow.Ads\">\n            </div>\n\n              <form class=\"form-login\" id=\"forgot-password-form\">\n		        <h2 class=\"form-login-heading\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "password reset", options) : helperMissing.call(depth0, "trans", "password reset", options)))
    + "</h2>\n		        <div class=\"login-wrap\">\n		            <h3>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Check your e-mail!", options) : helperMissing.call(depth0, "trans", "Check your e-mail!", options)))
    + "</h3>\n                     <p>";
  stack1 = (helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "You should have your <strong>password reset instructions</strong> in your inbox.", options) : helperMissing.call(depth0, "trans", "You should have your <strong>password reset instructions</strong> in your inbox.", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</p>\n		        </div>\n\n\n		      </form>\n\n	  	</div>\n	  </div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
