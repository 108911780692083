// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/DisplayGroups/DisplayGroups',['App',
        'backbone',
        'hbs!templates/views/DisplayGroups/DisplayGroups',
        'collections/Companies',
        'views/DisplayGroups/DisplayGroup',
        'views/DisplayGroups/CreateDisplayGroup',

        'bootstrap-select'
        ],
    function (
        App,
        Backbone,
        template,
        CompaniesCollection,
        DisplayGroup,
        CreateDisplayGroup
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: DisplayGroup,

            childViewContainer: 'ul',

            scrollContext: '.displays-scroll-area',

            searchBar: {
                fields: ['display_name'],
                sortBy: {
                    'display_name': 'Display Name'
                }
            },

            events: {
                'click .create-btn' : 'createDisplayGroup'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                var me = this;

                me.childViewOptions = {
                    parent: me
                };

                me.collection = App.currentUser.getAdminDisplayGroups({fetch: false});
                me.listenTo(App.vent, 'App.displayGroups.refresh', _.bind(me.refresh, me));
            },

            onRender: function () {
                var me = this;
                if (!me.collection.beenQueried()) {
                    $.when(me.collection.query()).then(function () {
                        me.$el.find('.no-items').html('<i class="fa fa fa-desktop"></i> No display groups found');
                    });
                } else {
                    if (me.collection._moreResults) { me._moreItems(); }
                    if (!_.size(me.collection)) { me.$el.find('.no-items').html('<i class="fa fa fa-desktop"></i> No display groups found'); }
                }
            },

            createDisplayGroup: function (event) {
                event && event.preventDefault();

                var modalView = new CreateDisplayGroup();
                App.mainRegion.currentView.modalRegion.show(modalView);
            },

            refresh: function() {
                var me = this;
                $.when(me.collection.query()).then(function () {
                    me.$el.find('.no-items').html('<i class="fa fa fa-desktop"></i> No display groups found');
                });
            }

        });
    });
