// // -*- coding: utf-8 -*-
//
//
// /*jslint nomen: true*/
// /*global define,console,sessionData*/
// define(['App', 'jquery', 'underscore', 'hbs!templates/views/Payments/SubscriptionPlan', 'backbone',
//         'views/Payments/ChangePlan', 'behaviors/Intercom'
//     ],
//     function (App, $, _, template, Backbone,
//               ChangePlan) {
//
//         'use strict';
//         return Backbone.Marionette.ItemView.extend({
//             template: template,
//
//             tagName: 'li',
//             className: 'subscription-plan',
//
//             events: {
//                 'click .change-plan-btn': '_changePlan',
//                 'click .plan-type': '_changePlan'
//             },
//
//             onRender: function () {
//                 var me = this;
//                 var type = me.model.get('type').toLowerCase();
//                 me.$el.addClass(type);
//                 me.$el.attr('data-permission', 'subs_' + type.substring(0, 4) + '_info');
//
//                 // indicate current plan
//                 if (me.model.get('type') === App.currentCompany.get('active_plan')) {
//                     me.$el.addClass('current');
//                 }
//
//             },
//
//             _changePlan: function (event) {
//                 event && event.preventDefault();
//
//                 var me = this,
//                     modalView,
//                     callback;
//
//                 if (me.model.get('type') !== App.currentCompany.get('active_plan')) {
//
//                     console.log('IIIIIIIIIIIIIIIIII');
//                     console.log(me.model.get('type'));
//                     console.log('IIIIIIIIIIIIIIIIII');
//                     modalView = new ChangePlan({plan: me.model.get('type')});
//                     callback = function () {
//                         App.mainRegion.currentView.modalRegion.show(modalView);
//                     };
//
//                     intercomReportEvent('changed_subscription_plan', {
//                         timestamp: (new Date()).toString(),
//                         subscription_new: me.model.get('type'),
//                         subscription_old: App.currentCompany.get('active_plan')
//                     });
//
//                     if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
//                         App.vent.trigger('App.layout.billingInfo.show', callback);
//                     } else {
//                         callback();
//                     }
//                 }
//             }
//         });
//     });

// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Payments/SubscriptionPlan',['App', 'jquery', 'underscore', 'hbs!templates/views/Payments/SubscriptionPlan', 'backbone',
        'views/Payments/ChangePlan', 'behaviors/Intercom'
    ],
    function (App, $, _, template, Backbone,
              ChangePlan) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'subscription-plan',

            events: {
                'click .change-plan-btn': '_changePlan',
                'click .plan-type': '_changePlan'
            },

            onRender: function () {
                var me = this;

                var type = me.model.get('type').toLowerCase();
                me.$el.addClass(type);
                me.$el.attr('data-permission', 'subs_' + type.substring(0, 4) + '_info');

                // indicate current plan
                if (me.model.get('type') === App.currentCompany.get('active_plan')) {
                    me.$el.addClass('current');
                }
            },

            _changePlan: function (event) {
                event && event.preventDefault();

                var me = this,
                    modalView,
                    callback;

                if (me.model.get('type') !== App.currentCompany.get('active_plan')) {

                    modalView = new ChangePlan({plan: me.model.get('type')});
                    callback = function () {
                        if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                            App.mainRegion.currentView.modalRegion.show(modalView);
                    };

                    intercomReportEvent('changed_subscription_plan', {
                        timestamp: (new Date()).toString(),
                        subscription_new: me.model.get('type'),
                        subscription_old: App.currentCompany.get('active_plan')
                    });

                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);

                    } else {
                        callback();
                    }
                }
            }

        });
    });
