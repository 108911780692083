// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/loopItemScheduleStatus',['handlebars', 'moment'], function (Handlebars, moment) {
    'use strict';
    function loopItemScheduleStatus(items) {
        var out = '',
            res = '<div class="scheduler-status ';

        if (items.length === 1) {
            out += items[0]['is_displayed'] ? "Online" : "Offline";
            if (!_.isEmpty(items[0]['next_change'])) {
                res += '">';
                out += "<br><span>" + moment(items[0]['next_change']).format("D MMM YYYY, h:mm A") + "</span>"
            } else {
                res += 'no-next-change">';
            }
        }
        return res + out + "</div>";
    }
    Handlebars.registerHelper('loopItemScheduleStatus', loopItemScheduleStatus);
    return loopItemScheduleStatus;
});

