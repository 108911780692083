// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('timezones',["underscore", "App"], function (_, App) {

    'use strict';
    var g_countries,
        g_zones,
        zones,
        obj_zones;

    g_countries = [
        ["AD", App.trans("Andorra")], // en: Andorra, es: Andorra, de: Andorra, fr: Andorre
        ["AE", App.trans("United Arab Emirates")], // en: United Arab Emirates, es: Emiratos Árabes Unidos, de: Vereinigte Arabische Emirate, fr: Émirats arabes unis
        ["AF", App.trans("Afghanistan")], // en: Afghanistan, es: Afganistán, de: Afghanistan, fr: Afghanistan
        ["AG", App.trans("Antigua & Barbuda")], // en: Antigua & Barbuda, es: Antigua & Barbuda, de: Antigua & Barbuda, fr: Antigua & Barbuda
        ["AI", App.trans("Anguilla")], // en: Anguilla, es: Anguila, de: Anguilla, fr: Anguilla
        ["AL", App.trans("Albania")], // en: Albania, es: Albania, de: Albanien, fr: Albanie
        ["AM", App.trans("Armenia")], // en: Armenia, es: Armenia, de: Armenien, fr: Arménie
        ["AN", App.trans("Netherlands Antilles")], // en: Netherlands Antilles, es: Antillas holandesas, de: Niederländische Antillen, fr: Antilles néerlandaises
        ["AO", App.trans("Angola")], // en: Angola, es: Angola, de: Angola, fr: Angola
        ["AQ", App.trans("Antarctica")], // en: Antarctica, es: Antarctica, de: Antarctica, fr: Antarctica
        ["AR", App.trans("Argentina")], // en: Argentina, es: Argentina, de: Argentinien, fr: Argentine
        ["AS", App.trans("Samoa (American)")], // en: Samoa (American), es: Samoa (American), de: Samoa (American), fr: Samoa (American)
        ["AT", App.trans("Austria")], // en: Austria, es: Austria, de: Österreich, fr: Autriche
        ["AU", App.trans("Australia")], // en: Australia, es: Australia, de: Australien, fr: Australie
        ["AW", App.trans("Aruba")], // en: Aruba, es: Aruba, de: Aruba, fr: Aruba
        ["AX", App.trans("Aaland Islands")], // en: Aaland Islands, es: Aaland Islands, de: Aaland Islands, fr: Aaland Islands
        ["AZ", App.trans("Azerbaijan")], // en: Azerbaijan, es: Azerbaiyán, de: Aserbaidschan, fr: Azerbaïdjan
        ["BA", App.trans("Bosnia & Herzegovina")], // en: Bosnia & Herzegovina, es: Bosnia & Herzegovina, de: Bosnia & Herzegovina, fr: Bosnia & Herzegovina
        ["BB", App.trans("Barbados")], // en: Barbados, es: Barbados, de: Barbados, fr: Barbade
        ["BD", App.trans("Bangladesh")], // en: Bangladesh, es: Bangladesh, de: Bangladesch, fr: Bangladesh
        ["BE", App.trans("Belgium")], // en: Belgium, es: Bélgica, de: Belgien, fr: Belgique
        ["BF", App.trans("Burkina Faso")], // en: Burkina Faso, es: Burkina Faso, de: Burkina-Faso, fr: Burkina Faso
        ["BG", App.trans("Bulgaria")], // en: Bulgaria, es: Bulgaria, de: Bulgarien, fr: Bulgarie
        ["BH", App.trans("Bahrain")], // en: Bahrain, es: Bahrain, de: Bahrein, fr: Bahreïn
        ["BI", App.trans("Burundi")], // en: Burundi, es: Burundi, de: Burundi, fr: Burundi
        ["BJ", App.trans("Benin")], // en: Benin, es: Benin, de: Benin, fr: Bénin
        ["BL", App.trans("St Barthelemy")], // en: St Barthelemy, es: St Barthelemy, de: St Barthelemy, fr: St Barthelemy
        ["BM", App.trans("Bermuda")], // en: Bermuda, es: Bermuda, de: Bermuda, fr: Bermudes
        ["BN", App.trans("Brunei")], // en: Brunei, es: Brunei, de: Brunei, fr: Brunei
        ["BO", App.trans("Bolivia")], // en: Bolivia, es: Bolivia, de: Bolivien, fr: Bolivie
        ["BR", App.trans("Brazil")], // en: Brazil, es: Brasil, de: Brasilien, fr: Brésil
        ["BS", App.trans("Bahamas")], // en: Bahamas, es: Bahamas, de: Bahamas, fr: Bahamas
        ["BT", App.trans("Bhutan")], // en: Bhutan, es: Bután, de: Bhutan, fr: Bhoutan
        ["BW", App.trans("Botswana")], // en: Botswana, es: Botswana, de: Botswana, fr: Botswana
        ["BY", App.trans("Belarus")], // en: Belarus, es: Bielorrusia, de: Weißrussland, fr: Biélorussie
        ["BZ", App.trans("Belize")], // en: Belize, es: Belize, de: Belize, fr: Bélize
        ["CA", App.trans("Canada")], // en: Canada, es: Canadá, de: Kanada, fr: Canada
        ["CC", App.trans("Cocos (Keeling) Islands")], // en: Cocos (Keeling) Islands, es: Islas Cocos (Keeling), de: Cocos(Keeling)-Inseln, fr: Îles Cocos (Keeling)
        ["CD", App.trans("Congo (Dem. Rep.)")], // en: Congo (Dem. Rep.), es: Congo (Dem. Rep.), de: Congo (Dem. Rep.), fr: Congo (Dem. Rep.)
        ["CF", App.trans("Central African Rep.")], // en: Central African Rep., es: Central African Rep., de: Central African Rep., fr: Central African Rep.
        ["CG", App.trans("Congo (Rep.)")], // en: Congo (Rep.), es: Congo (Rep.), de: Congo (Rep.), fr: Congo (Rep.)
        ["CH", App.trans("Switzerland")], // en: Switzerland, es: Suiza, de: Schweiz, fr: Suisse
        ["CI", App.trans("Cote d'Ivoire")], // en: Cote d'Ivoire, es: Cote d'Ivoire, de: Cote d'Ivoire, fr: Cote d'Ivoire
        ["CK", App.trans("Cook Islands")], // en: Cook Islands, es: Islas Cook, de: Cook-Inseln, fr: Îles Cook
        ["CL", App.trans("Chile")], // en: Chile, es: Chile, de: Chile, fr: Chili
        ["CM", App.trans("Cameroon")], // en: Cameroon, es: Cameroon, de: Kamerun, fr: Cameroun
        ["CN", App.trans("China")], // en: China, es: China, de: China, fr: Chine
        ["CO", App.trans("Colombia")], // en: Colombia, es: Colombia, de: Kolumbien, fr: Colombie
        ["CR", App.trans("Costa Rica")], // en: Costa Rica, es: Costa Rica, de: Costa Rica, fr: Costa Rica
        ["CU", App.trans("Cuba")], // en: Cuba, es: Cuba, de: Kuba, fr: Cuba
        ["CV", App.trans("Cape Verde")], // en: Cape Verde, es: Cabo Verde, de: Kapverden, fr: Cap-Vert
        ["CX", App.trans("Christmas Island")], // en: Christmas Island, es: Isla Christmas, de: Weihnachtsinsel, fr: Île Christmas
        ["CY", App.trans("Cyprus")], // en: Cyprus, es: Chipre, de: Zypern, fr: Chypre
        ["CZ", App.trans("Czech Republic")], // en: Czech Republic, es: República Checa, de: Tschechische Republik, fr: République tchèque
        ["DE", App.trans("Germany")], // en: Germany, es: Alemania, de: Deutschland, fr: Allemagne
        ["DJ", App.trans("Djibouti")], // en: Djibouti, es: Djibouti, de: Djibouti, fr: Djibouti
        ["DK", App.trans("Denmark")], // en: Denmark, es: Dinamarca, de: Dänemark, fr: Danemark
        ["DM", App.trans("Dominica")], // en: Dominica, es: Dominica, de: Dominica, fr: Dominique
        ["DO", App.trans("Dominican Republic")], // en: Dominican Republic, es: República Dominicana, de: Dominikanische Republik, fr: République dominicaine
        ["DZ", App.trans("Algeria")], // en: Algeria, es: Argelia, de: Algerien, fr: Algérie
        ["EC", App.trans("Ecuador")], // en: Ecuador, es: Ecuador, de: Ekuador, fr: Équateur
        ["EE", App.trans("Estonia")], // en: Estonia, es: Estonia, de: Estland, fr: Estonie
        ["EG", App.trans("Egypt")], // en: Egypt, es: Egipto, de: Ägypten, fr: Égypte
        ["EH", App.trans("Western Sahara")], // en: Western Sahara, es: Sáhara Occidental, de: Westsahara, fr: Sahara occidental
        ["ER", App.trans("Eritrea")], // en: Eritrea, es: Eritrea, de: Eritrea, fr: Érythrée
        ["ES", App.trans("Spain")], // en: Spain, es: España, de: Spanien, fr: Espagne
        ["ET", App.trans("Ethiopia")], // en: Ethiopia, es: Etiopía, de: Äthiopien, fr: Éthiopie
        ["FI", App.trans("Finland")], // en: Finland, es: Finlandia, de: Finnland, fr: Finlande
        ["FJ", App.trans("Fiji")], // en: Fiji, es: Fiji, de: Fidschi, fr: Fidji
        ["FK", App.trans("Falkland Islands")], // en: Falkland Islands, es: Falkland Islands, de: Falkland Islands, fr: Falkland Islands
        ["FM", App.trans("Micronesia")], // en: Micronesia, es: Micronesia, de: Micronesia, fr: Micronesia
        ["FO", App.trans("Faroe Islands")], // en: Faroe Islands, es: Islas Faroe, de: Faröer-Inseln, fr: Îles Féroé
        ["FR", App.trans("France")], // en: France, es: Francia, de: Frankreich, fr: France
        ["GA", App.trans("Gabon")], // en: Gabon, es: Gabon, de: Gabun, fr: Gabon
        ["GB", App.trans("Great Britain (UK)")], // en: Britain (UK), es: Britain (UK), de: Britain (UK), fr: Britain (UK)
        ["GD", App.trans("Grenada")], // en: Grenada, es: Granada, de: Grenada, fr: Grenade
        ["GE", App.trans("Georgia")], // en: Georgia, es: Georgia, de: Georgia, fr: Georgia
        ["GF", App.trans("French Guiana")], // en: French Guiana, es: Guyana Francesa, de: Französisch-Guyana, fr: Guyane
        ["GG", App.trans("Guernsey")], // en: Guernsey, es: Guernsey, de: Guernsey, fr: Guernesey
        ["GH", App.trans("Ghana")], // en: Ghana, es: Ghana, de: Ghana, fr: Ghana
        ["GI", App.trans("Gibraltar")], // en: Gibraltar, es: Gibraltar, de: Gibraltar, fr: Gibraltar
        ["GL", App.trans("Greenland")], // en: Greenland, es: Groenlandia, de: Grönland, fr: Groenland
        ["GM", App.trans("Gambia")], // en: Gambia, es: Gambia, de: Gambia, fr: Gambie
        ["GN", App.trans("Guinea")], // en: Guinea, es: Guinea, de: Guinea, fr: Guinée
        ["GP", App.trans("Guadeloupe")], // en: Guadeloupe, es: Guadalupe, de: Guadeloupe, fr: Guadeloupe
        ["GQ", App.trans("Equatorial Guinea")], // en: Equatorial Guinea, es: Equatorial Guinea, de: Äquatorial-Guinea, fr: Guinée équatoriale
        ["GR", App.trans("Greece")], // en: Greece, es: Grecia, de: Griechenland, fr: Grèce
        ["GS", App.trans("South Georgia & the South Sandwich Islands")], // en: South Georgia & the South Sandwich Islands, es: South Georgia & the South Sandwich Islands, de: South Georgia & the South Sandwich Islands, fr: South Georgia & the South Sandwich Islands
        ["GT", App.trans("Guatemala")], // en: Guatemala, es: Guatemala, de: Guatemala, fr: Guatemala
        ["GU", App.trans("Guam")], // en: Guam, es: Guam, de: Guam, fr: Guam
        ["GW", App.trans("Guinea-Bissau")], // en: Guinea-Bissau, es: Guinea-Bissau, de: Guinea-Bissau, fr: Guinée-Bissau
        ["GY", App.trans("Guyana")], // en: Guyana, es: Guyana, de: Guyana, fr: Guyana
        ["HK", App.trans("Hong Kong")], // en: Hong Kong, es: Hong Kong, de: Hong Kong, fr: Hong Kong
        ["HN", App.trans("Honduras")], // en: Honduras, es: Honduras, de: Honduras, fr: Honduras
        ["HR", App.trans("Croatia")], // en: Croatia, es: Croacia, de: Kroatien, fr: Croatie
        ["HT", App.trans("Haiti")], // en: Haiti, es: Haití, de: Haiti, fr: Haïti
        ["HU", App.trans("Hungary")], // en: Hungary, es: Hungría, de: Ungarn, fr: Hongrie
        ["ID", App.trans("Indonesia")], // en: Indonesia, es: Indonesia, de: Indonesien, fr: Indonésie
        ["IE", App.trans("Ireland")], // en: Ireland, es: Irlanda, de: Irland, fr: Irlande
        ["IL", App.trans("Israel")], // en: Israel, es: Israel, de: Israel, fr: Israël
        ["IM", App.trans("Isle of Man")], // en: Isle of Man, es: Isla de Man, de: Isle of Man, fr: Île de Man
        ["IN", App.trans("India")], // en: India, es: India, de: Indien, fr: Inde
        ["IO", App.trans("British Indian Ocean Territory")], // en: British Indian Ocean Territory, es: British Indian Ocean Territory, de: Britisches Territorium im Indischen Ozean, fr: Territoire britannique de l'océan Indien
        ["IQ", App.trans("Iraq")], // en: Iraq, es: Irak, de: Irak, fr: Irak
        ["IR", App.trans("Iran")], // en: Iran, es: Irán, de: Iran, fr: Iran
        ["IS", App.trans("Iceland")], // en: Iceland, es: Islandia, de: Island, fr: Islande
        ["IT", App.trans("Italy")], // en: Italy, es: Italia, de: Italien, fr: Italie
        ["JE", App.trans("Jersey")], // en: Jersey, es: Jersey, de: Jersey, fr: Jersey
        ["JM", App.trans("Jamaica")], // en: Jamaica, es: Jamaica, de: Jamaika, fr: Jamaïque
        ["JO", App.trans("Jordan")], // en: Jordan, es: Jordan, de: Jordan, fr: Jordan
        ["JP", App.trans("Japan")], // en: Japan, es: Japón, de: Japan, fr: Japon
        ["KE", App.trans("Kenya")], // en: Kenya, es: Kenya, de: Kenia, fr: Kenya
        ["KG", App.trans("Kyrgyzstan")], // en: Kyrgyzstan, es: Kyrgyzstan, de: Kirgisien, fr: Kirghizistan
        ["KH", App.trans("Cambodia")], // en: Cambodia, es: Cambodia, de: Kambodscha, fr: Cambodge
        ["KI", App.trans("Kiribati")], // en: Kiribati, es: Kiribati, de: Kiribati, fr: Kiribati
        ["KM", App.trans("Comoros")], // en: Comoros, es: Comoros, de: Komoren, fr: Comores
        ["KN", App.trans("St Kitts & Nevis")], // en: St Kitts & Nevis, es: St Kitts & Nevis, de: St Kitts & Nevis, fr: St Kitts & Nevis
        ["KP", App.trans("Korea (North)")], // en: Korea (North), es: Korea (North), de: Korea (North), fr: Korea (North)
        ["KR", App.trans("Korea (South)")], // en: Korea (South), es: Korea (South), de: Korea (South), fr: Korea (South)
        ["KW", App.trans("Kuwait")], // en: Kuwait, es: Kuwait, de: Kuwait, fr: Kuwait
        ["KY", App.trans("Cayman Islands")], // en: Cayman Islands, es: Islas Caimán, de: Cayman-Inseln, fr: Îles Caïmans
        ["KZ", App.trans("Kazakhstan")], // en: Kazakhstan, es: Kazakhstán, de: Kasachstan, fr: Kazakhstan
        ["LA", App.trans("Laos")], // en: Laos, es: Laos, de: Laos, fr: Laos
        ["LB", App.trans("Lebanon")], // en: Lebanon, es: Lebanon, de: Lebanon, fr: Lebanon
        ["LC", App.trans("St Lucia")], // en: St Lucia, es: St Lucia, de: St Lucia, fr: St Lucia
        ["LI", App.trans("Liechtenstein")], // en: Liechtenstein, es: Liechtenstein, de: Liechtenstein, fr: Liechtenstein
        ["LK", App.trans("Sri Lanka")], // en: Sri Lanka, es: Sri Lanka, de: Sri Lanka, fr: Sri Lanka
        ["LR", App.trans("Liberia")], // en: Liberia, es: Liberia, de: Liberia, fr: Liberia
        ["LS", App.trans("Lesotho")], // en: Lesotho, es: Lesoto, de: Lesotho, fr: Lesotho
        ["LT", App.trans("Lithuania")], // en: Lithuania, es: Lituania, de: Litauen, fr: Lituanie
        ["LU", App.trans("Luxembourg")], // en: Luxembourg, es: Luxembourg, de: Luxembourg, fr: Luxembourg
        ["LV", App.trans("Latvia")], // en: Latvia, es: Letonia, de: Lettland, fr: Lettonie
        ["LY", App.trans("Libya")], // en: Libya, es: Libia, de: Libyen, fr: Libye
        ["MA", App.trans("Morocco")], // en: Morocco, es: Marruecos, de: Marokko, fr: Maroc
        ["MC", App.trans("Monaco")], // en: Monaco, es: Monaco, de: Monaco, fr: Monaco
        ["MD", App.trans("Moldova")], // en: Moldova, es: Moldavia, de: Moldawien, fr: Moldavie
        ["ME", App.trans("Montenegro")], // en: Montenegro, es: Montenegro, de: Montenegro, fr: Monténégro
        ["MF", App.trans("St Martin (French part)")], // en: St Martin (French part), es: St Martin (French part), de: St Martin (French part), fr: St Martin (French part)
        ["MG", App.trans("Madagascar")], // en: Madagascar, es: Madagascar, de: Madagaskar, fr: Madagascar
        ["MH", App.trans("Marshall Islands")], // en: Marshall Islands, es: Marshall Islands, de: Marshall-Inseln, fr: Îles Marshall
        ["MK", App.trans("Macedonia")], // en: Macedonia, es: Macedonia, de: Mazedonien, fr: Macédoine
        ["ML", App.trans("Mali")], // en: Mali, es: Mali, de: Mali, fr: Mali
        ["MM", App.trans("Myanmar (Burma)")], // en: Myanmar (Burma), es: Myanmar (Burma), de: Myanmar (Burma), fr: Myanmar (Burma)
        ["MN", App.trans("Mongolia")], // en: Mongolia, es: Mongolia, de: Mongolei, fr: Mongolie
        ["MO", App.trans("Macau")], // en: Macau, es: Macau, de: Macau, fr: Macau
        ["MP", App.trans("Northern Mariana Islands")], // en: Northern Mariana Islands, es: Islas Marianas del Norte, de: Nördliche Marianen, fr: Îles Mariannes du Nord
        ["MQ", App.trans("Martinique")], // en: Martinique, es: Martinique, de: Martinique, fr: Martinique
        ["MR", App.trans("Mauritania")], // en: Mauritania, es: Mauritania, de: Mauretanien, fr: Mauritanie
        ["MS", App.trans("Montserrat")], // en: Montserrat, es: Montserrat, de: Montserrat, fr: Montserrat
        ["MT", App.trans("Malta")], // en: Malta, es: Malta, de: Malta, fr: Malta
        ["MU", App.trans("Mauritius")], // en: Mauritius, es: Mauritius, de: Mauritius, fr: Maurice
        ["MV", App.trans("Maldives")], // en: Maldives, es: Maldivas, de: Malediven, fr: Maldives
        ["MW", App.trans("Malawi")], // en: Malawi, es: Malawi, de: Malawi, fr: Malawi
        ["MX", App.trans("Mexico")], // en: Mexico, es: México, de: Mexiko, fr: Mexique
        ["MY", App.trans("Malaysia")], // en: Malaysia, es: Malasia, de: Malaysia, fr: Malaisie
        ["MZ", App.trans("Mozambique")], // en: Mozambique, es: Mozambique, de: Mosambik, fr: Mozambique
        ["NA", App.trans("Namibia")], // en: Namibia, es: Namibia, de: Namibia, fr: Namibie
        ["NC", App.trans("New Caledonia")], // en: New Caledonia, es: Nueva Caledonia, de: Neukaledonien, fr: Nouvelle-Calédonie
        ["NE", App.trans("Niger")], // en: Niger, es: Niger, de: Niger, fr: Niger
        ["NF", App.trans("Norfolk Island")], // en: Norfolk Island, es: Norfolk Island, de: Norfolk-Inseln, fr: Île Norfolk
        ["NG", App.trans("Nigeria")], // en: Nigeria, es: Nigeria, de: Nigerien, fr: Nigeria
        ["NI", App.trans("Nicaragua")], // en: Nicaragua, es: Nicaragua, de: Nicaragua, fr: Nicaragua
        ["NL", App.trans("Netherlands")], // en: Netherlands, es: Holanda, de: Niederlande, fr: Pays-Bas
        ["NO", App.trans("Norway")], // en: Norway, es: Noruega, de: Norwegen, fr: Norvège
        ["NP", App.trans("Nepal")], // en: Nepal, es: Nepal, de: Nepal, fr: Népal
        ["NR", App.trans("Nauru")], // en: Nauru, es: Nauru, de: Nauru, fr: Nauru
        ["NU", App.trans("Niue")], // en: Niue, es: Niue, de: Niue, fr: Niué
        ["NZ", App.trans("New Zealand")], // en: New Zealand, es: Nueva Zelanda, de: Neuseeland, fr: Nouvelle-Zélande
        ["OM", App.trans("Oman")], // en: Oman, es: Omán, de: Oman, fr: Oman
        ["PA", App.trans("Panama")], // en: Panama, es: Panamá, de: Panama, fr: Panama
        ["PE", App.trans("Peru")], // en: Peru, es: Peru, de: Peru, fr: Peru
        ["PF", App.trans("French Polynesia")], // en: French Polynesia, es: Polinesia Francesa, de: Französisch-Polynesien, fr: Polynésie française
        ["PG", App.trans("Papua New Guinea")], // en: Papua New Guinea, es: Papúa Nueva Guinea, de: Papua-Neuguinea, fr: Papouasie - Nouvelle-Guinée
        ["PH", App.trans("Philippines")], // en: Philippines, es: Filipinas, de: Philippinen, fr: Philippines
        ["PK", App.trans("Pakistan")], // en: Pakistan, es: Pakistán, de: Pakistan, fr: Pakistan
        ["PL", App.trans("Poland")], // en: Poland, es: Polonia, de: Polen, fr: Pologne
        ["PM", App.trans("St Pierre & Miquelon")], // en: St Pierre & Miquelon, es: St Pierre & Miquelon, de: St Pierre & Miquelon, fr: St Pierre & Miquelon
        ["PN", App.trans("Pitcairn")], // en: Pitcairn, es: Islas Pitcairn, de: Pitcairn, fr: Îles Pitcairn
        ["PR", App.trans("Puerto Rico")], // en: Puerto Rico, es: Puerto Rico, de: Puerto Rico, fr: Porto Rico
        ["PS", App.trans("Palestine")], // en: Palestine, es: Palestina, de: Palestina, fr: Palestine
        ["PT", App.trans("Portugal")], // en: Portugal, es: Portugal, de: Portugal, fr: Portugal
        ["PW", App.trans("Palau")], // en: Palau, es: Palau, de: Palau, fr: Palaos
        ["PY", App.trans("Paraguay")], // en: Paraguay, es: Paraguay, de: Paraguay, fr: Paraguay
        ["QA", App.trans("Qatar")], // en: Qatar, es: Qatar, de: El-Katar, fr: Qatar
        ["RE", App.trans("Reunion")], // en: Reunion, es: Reunion, de: Reunion, fr: Reunion
        ["RO", App.trans("Romania")], // en: Romania, es: Rumanía, de: Rumänien, fr: Roumanie
        ["RS", App.trans("Serbia")], // en: Serbia, es: Serbia, de: Serbien, fr: Serbie
        ["RU", App.trans("Russia")], // en: Russia, es: Rusia, de: Russland, fr: Russie
        ["RW", App.trans("Rwanda")], // en: Rwanda, es: Rwanda, de: Ruanda, fr: Rwanda
        ["SA", App.trans("Saudi Arabia")], // en: Saudi Arabia, es: Arabia Saudí, de: Saudi-Arabien, fr: Arabie Saoudite
        ["SB", App.trans("Solomon Islands")], // en: Solomon Islands, es: Islas Salomón, de: Salomonen, fr: Îles Salomon
        ["SC", App.trans("Seychelles")], // en: Seychelles, es: Seychelles, de: Seychellen, fr: Seychelles
        ["SD", App.trans("Sudan")], // en: Sudan, es: Sudan, de: Sudan, fr: Soudan
        ["SE", App.trans("Sweden")], // en: Sweden, es: Suecia, de: Schweden, fr: Suède
        ["SG", App.trans("Singapore")], // en: Singapore, es: Singapore, de: Singapore, fr: Singapore
        ["SH", App.trans("St Helena")], // en: St Helena, es: St Helena, de: St Helena, fr: St Helena
        ["SI", App.trans("Slovenia")], // en: Slovenia, es: Eslovenia, de: Slowenien, fr: Slovénie
        ["SJ", App.trans("Svalbard & Jan Mayen")], // en: Svalbard & Jan Mayen, es: Svalbard & Jan Mayen, de: Svalbard & Jan Mayen, fr: Svalbard & Jan Mayen
        ["SK", App.trans("Slovakia")], // en: Slovakia, es: Eslovaquia, de: Slowakei, fr: Slovaquie
        ["SL", App.trans("Sierra Leone")], // en: Sierra Leone, es: Sierra Leona, de: Sierra Leone, fr: Sierra Leone
        ["SM", App.trans("San Marino")], // en: San Marino, es: San Marino, de: San Marino, fr: San Marino
        ["SN", App.trans("Senegal")], // en: Senegal, es: Senegal, de: Senegal, fr: Sénégal
        ["SO", App.trans("Somalia")], // en: Somalia, es: Somalia, de: Somalia, fr: Somalie
        ["SR", App.trans("Suriname")], // en: Suriname, es: Surinám, de: Suriname, fr: Suriname
        ["ST", App.trans("Sao Tome & Principe")], // en: Sao Tome & Principe, es: Sao Tome & Principe, de: Sao Tome & Principe, fr: Sao Tome & Principe
        ["SV", App.trans("El Salvador")], // en: El Salvador, es: El Salvador, de: El Salvador, fr: El Salvador
        ["SY", App.trans("Syria")], // en: Syria, es: Syria, de: Syrien, fr: Syrie
        ["SZ", App.trans("Swaziland")], // en: Swaziland, es: Swaziland, de: Swasiland, fr: Swaziland
        ["TC", App.trans("Turks & Caicos Is")], // en: Turks & Caicos Is, es: Turks & Caicos Is, de: Turks & Caicos Is, fr: Turks & Caicos Is
        ["TD", App.trans("Chad")], // en: Chad, es: Chad, de: Tschad, fr: Tchad
        ["TF", App.trans("French Southern & Antarctic Lands")], // en: French Southern & Antarctic Lands, es: French Southern & Antarctic Lands, de: French Southern & Antarctic Lands, fr: French Southern & Antarctic Lands
        ["TG", App.trans("Togo")], // en: Togo, es: Togo, de: Togo, fr: Togo
        ["TH", App.trans("Thailand")], // en: Thailand, es: Tailandia, de: Thailand, fr: Thaïlande
        ["TJ", App.trans("Tajikistan")], // en: Tajikistan, es: Tajikistan, de: Tadschikistan, fr: Tadjikistan
        ["TK", App.trans("Tokelau")], // en: Tokelau, es: Tokelau, de: Tokelau, fr: Tokelau
        ["TL", App.trans("East Timor")], // en: East Timor, es: East Timor, de: East Timor, fr: East Timor
        ["TM", App.trans("Turkmenistan")], // en: Turkmenistan, es: Turkmenistan, de: Turkmenistan, fr: Turkménistan
        ["TN", App.trans("Tunisia")], // en: Tunisia, es: Tunisia, de: Tunesien, fr: Tunisie
        ["TO", App.trans("Tonga")], // en: Tonga, es: Tonga, de: Tonga, fr: Tonga
        ["TR", App.trans("Turkey")], // en: Turkey, es: Turquía, de: Türkei, fr: Turquie
        ["TT", App.trans("Trinidad & Tobago")], // en: Trinidad & Tobago, es: Trinidad & Tobago, de: Trinidad & Tobago, fr: Trinidad & Tobago
        ["TV", App.trans("Tuvalu")], // en: Tuvalu, es: Tuvalu, de: Tuvalu, fr: Tuvalu
        ["TW", App.trans("Taiwan")], // en: Taiwan, es: Taiwán, de: Taiwan, fr: Taïwan
        ["TZ", App.trans("Tanzania")], // en: Tanzania, es: Tanzania, de: Tansania, fr: Tanzanie
        ["UA", App.trans("Ukraine")], // en: Ukraine, es: Ucrania, de: Ukraine, fr: Ukraine
        ["UG", App.trans("Uganda")], // en: Uganda, es: Uganda, de: Uganda, fr: Ouganda
        ["UM", App.trans("US minor outlying islands")], // en: US minor outlying islands, es: US minor outlying islands, de: US minor outlying islands, fr: US minor outlying islands
        ["US", App.trans("United States")], // en: United States, es: Estados Unidos, de: Vereinigte Staaten von Amerika, fr: États-Unis
        ["UY", App.trans("Uruguay")], // en: Uruguay, es: Uruguay, de: Uruguay, fr: Uruguay
        ["UZ", App.trans("Uzbekistan")], // en: Uzbekistan, es: Uzbekistán, de: Usbekistan, fr: Ouzbékistan
        ["VA", App.trans("Vatican City")], // en: Vatican City, es: Ciudad del Vaticano, de: Vatikanstadt, fr: Cité du Vatican
        ["VC", App.trans("St Vincent")], // en: St Vincent, es: St Vincent, de: St Vincent, fr: St Vincent
        ["VE", App.trans("Venezuela")], // en: Venezuela, es: Venezuela, de: Venezuela, fr: Venezuela
        ["VG", App.trans("Virgin Islands (UK)")], // en: Virgin Islands (UK), es: Virgin Islands (UK), de: Virgin Islands (UK), fr: Virgin Islands (UK)
        ["VI", App.trans("Virgin Islands (US)")], // en: Virgin Islands (US), es: Virgin Islands (US), de: Virgin Islands (US), fr: Virgin Islands (US)
        ["VN", App.trans("Vietnam")], // en: Vietnam, es: Vietnam, de: Vietnam, fr: Vietnam
        ["VU", App.trans("Vanuatu")], // en: Vanuatu, es: Vanuatu, de: Vanuatu, fr: Vanuatu
        ["WF", App.trans("Wallis & Futuna")], // en: Wallis & Futuna, es: Wallis & Futuna, de: Wallis & Futuna, fr: Wallis & Futuna
        ["WS", App.trans("Samoa (western)")], // en: Samoa (western), es: Samoa (western), de: Samoa (western), fr: Samoa (western)
        ["YE", App.trans("Yemen")], // en: Yemen, es: Yemen, de: Jemen, fr: Yémen
        ["YT", App.trans("Mayotte")], // en: Mayotte, es: Mayotte, de: Mayotte, fr: Mayotte
        ["ZA", App.trans("South Africa")], // en: South Africa, es: Sudáfrica, de: Südafrika, fr: Afrique du Sud
        ["ZM", App.trans("Zambia")], // en: Zambia, es: Zambia, de: Sambia, fr: Zambie
        ["ZW", App.trans("Zimbabwe")]];// en: Zimbabwe, es: Zimbabwe, de: Simbabwe, fr: Zimbabwe

    g_zones = {
        AD: [
            ["Europe/Andorra", App.trans("GMT+02:00 Europe/Andorra")] // es: Europa/Andorra, de: Europa/Andorra, fr: Europe/Andorre
        ],
        AE: [
            ["Asia/Dubai", App.trans("GMT+04:00 Asia/Dubai")] // es: Asia/Dubai, de: Asien/Dubai, fr: Asie/Dubai
        ],
        AF: [
            ["Asia/Kabul", App.trans("GMT+04:30 Asia/Kabul")] // es: Asia/Kabul, de: Asien/Kabul, fr: Asie/Kabul
        ],
        AG: [
            ["America/Antigua", App.trans("GMT-04:00 America/Antigua")] // es: América/Antigua, de: Amerika/Antigua, fr: Amérique/Antigua
        ],
        AI: [
            ["America/Anguilla", App.trans("GMT-04:00 America/Anguilla")] // es: América/Anguilla, de: Amerika/Anguilla, fr: Amérique/Anguilla
        ],
        AL: [
            ["Europe/Tirane", App.trans("GMT+02:00 Europe/Tirane")] // es: Europa/Tirana, de: Europa/Tirana, fr: Europe/Tirana
        ],
        AM: [
            ["Asia/Yerevan", App.trans("GMT+05:00 Asia/Yerevan")] // es: Asia/Yerevan, de: Asien/Jerevan, fr: Asie/Yerevan
        ],
        AN: [
            ["America/Curacao", App.trans("GMT-04:00 America/Curacao")] // es: América/Curaçao, de: Amerika/Curacao, fr: Amérique/Curacao
        ],
        AO: [
            ["Africa/Luanda", App.trans("GMT+01:00 Africa/Luanda")] // es: África/Luanda, de: Afrika/Luanda, fr: Afrique/Luanda
        ],
        AQ: [
            ["Antarctica/McMurdo", App.trans("GMT+12:00 Antarctica/McMurdo")], // es: Antártida/McMurdo, de: Antarktis/McMurdo, fr: Antarctique/McMurdo
            ["Antarctica/South_Pole", App.trans("GMT+12:00 Antarctica/South_Pole")], // es: Antártida/Polo_Sur, de: Antarktis/Südpol, fr: Antarctique/South_Pole
            ["Antarctica/Rothera", App.trans("GMT-03:00 Antarctica/Rothera")], // es: Antarctica/Rothera, de: Antarctica/Rothera, fr: Antarctica/Rothera
            ["Antarctica/Palmer", App.trans("GMT-04:00 Antarctica/Palmer")], // es: Antártida/Palmer, de: Antarktis/Palmer, fr: Antarctique/Palmer
            ["Antarctica/Mawson", App.trans("GMT+05:00 Antarctica/Mawson")], // es: Antártida/Mawson, de: Antarktis/Mawson, fr: Antarctique/Mawson
            ["Antarctica/Davis", App.trans("GMT+07:00 Antarctica/Davis")], // es: Antártida/Davis, de: Antarktis/Davis, fr: Antarctique/Davis
            ["Antarctica/Casey", App.trans("GMT+08:00 Antarctica/Casey")], // es: Antártida/Casey, de: Antarktis/Casey, fr: Antarctique/Casey
            ["Antarctica/Vostok", App.trans("GMT+06:00 Antarctica/Vostok")], // es: Antártida/Vostok, de: Antarktis/Vostok, fr: Antarctique/Vostok
            ["Antarctica/DumontDUrville", App.trans("GMT+10:00 Antarctica/DumontDUrville")], // es: Antártida/DumontDUrville, de: Antarktis/DumontDUrville, fr: Antarctique/DumontDurville
            ["Antarctica/Syowa", App.trans("GMT+03:00 Antarctica/Syowa")] // es: Antártida/Syowa, de: Antarktis/Syowa, fr: Antarctique/Syowa
        ],
        AR: [
            ["America/Argentina/Buenos_Aires", App.trans("GMT-03:00 America/Argentina/Buenos_Aires")], // es: America/Argentina/Buenos_Aires, de: America/Argentina/Buenos_Aires, fr: America/Argentina/Buenos_Aires
            ["America/Argentina/Cordoba", App.trans("GMT-03:00 America/Argentina/Cordoba")], // es: America/Argentina/Cordoba, de: America/Argentina/Cordoba, fr: America/Argentina/Cordoba
            ["America/Argentina/Salta", App.trans("GMT-03:00 America/Argentina/Salta")], // es: America/Argentina/Salta, de: America/Argentina/Salta, fr: America/Argentina/Salta
            ["America/Argentina/Jujuy", App.trans("GMT-03:00 America/Argentina/Jujuy")], // es: America/Argentina/Jujuy, de: America/Argentina/Jujuy, fr: America/Argentina/Jujuy
            ["America/Argentina/Tucuman", App.trans("GMT-03:00 America/Argentina/Tucuman")], // es: America/Argentina/Tucuman, de: America/Argentina/Tucuman, fr: America/Argentina/Tucuman
            ["America/Argentina/Catamarca", App.trans("GMT-03:00 America/Argentina/Catamarca")], // es: America/Argentina/Catamarca, de: America/Argentina/Catamarca, fr: America/Argentina/Catamarca
            ["America/Argentina/La_Rioja", App.trans("GMT-03:00 America/Argentina/La_Rioja")], // es: America/Argentina/La_Rioja, de: America/Argentina/La_Rioja, fr: America/Argentina/La_Rioja
            ["America/Argentina/San_Juan", App.trans("GMT-03:00 America/Argentina/San_Juan")], // es: America/Argentina/San_Juan, de: America/Argentina/San_Juan, fr: America/Argentina/San_Juan
            ["America/Argentina/Mendoza", App.trans("GMT-03:00 America/Argentina/Mendoza")], // es: America/Argentina/Mendoza, de: America/Argentina/Mendoza, fr: America/Argentina/Mendoza
            ["America/Argentina/San_Luis", App.trans("GMT-03:00 America/Argentina/San_Luis")], // es: America/Argentina/San_Luis, de: America/Argentina/San_Luis, fr: America/Argentina/San_Luis
            ["America/Argentina/Rio_Gallegos", App.trans("GMT-03:00 America/Argentina/Rio_Gallegos")], // es: America/Argentina/Rio_Gallegos, de: America/Argentina/Rio_Gallegos, fr: America/Argentina/Rio_Gallegos
            ["America/Argentina/Ushuaia", App.trans("GMT-03:00 America/Argentina/Ushuaia")] // es: America/Argentina/Ushuaia, de: America/Argentina/Ushuaia, fr: America/Argentina/Ushuaia
        ],
        AS: [
            ["Pacific/Pago_Pago", App.trans("GMT-11:00 Pacific/Pago_Pago")] // es: Pacífico/Pago_Pago, de: Pazifik/Pago_Pago, fr: Pacifique/Pago_Pago
        ],
        AT: [
            ["Europe/Vienna", App.trans("GMT+02:00 Europe/Vienna")] // es: Europa/Viena, de: Europa/Wien, fr: Europe/Vienne
        ],
        AU: [
            ["Australia/Lord_Howe", App.trans("GMT+10:30 Australia/Lord_Howe")], // es: Australia/Lord_Howe, de: Australien/Lord_Howe, fr: Australie/Lord_Howe
            ["Australia/Hobart", App.trans("GMT+10:00 Australia/Hobart")], // es: Australia/Hobart, de: Australien/Hobart, fr: Australie/Hobart
            ["Australia/Currie", App.trans("GMT+10:00 Australia/Currie")], // es: Australia/Currie, de: Australia/Currie, fr: Australia/Currie
            ["Australia/Melbourne", App.trans("GMT+10:00 Australia/Melbourne")], // es: Australia/Melbourne, de: Australien/Melbourne, fr: Australie/Melbourne
            ["Australia/Sydney", App.trans("GMT+10:00 Australia/Sydney")], // es: Australia/Sydney, de: Australien/Sydney, fr: Australie/Sydney
            ["Australia/Broken_Hill", App.trans("GMT+09:30 Australia/Broken_Hill")], // es: Australia/Broken_Hill, de: Australien/Broken_Hill, fr: Australie/Broken_Hill
            ["Australia/Brisbane", App.trans("GMT+10:00 Australia/Brisbane")], // es: Australia/Brisbane, de: Australien/Brisbane, fr: Australie/Brisbane
            ["Australia/Lindeman", App.trans("GMT+10:00 Australia/Lindeman")], // es: Australia/Lindeman, de: Australien/Lindeman, fr: Australie/Lindeman
            ["Australia/Adelaide", App.trans("GMT+09:30 Australia/Adelaide")], // es: Australia/Adelaida, de: Australien/Adelaide, fr: Australie/Adélaïde
            ["Australia/Darwin", App.trans("GMT+09:30 Australia/Darwin")], // es: Australia/Darwin, de: Australien/Darwin, fr: Australie/Darwin
            ["Australia/Perth", App.trans("GMT+08:00 Australia/Perth")], // es: Australia/Perth, de: Australien/Perth, fr: Australie/Perth
            ["Australia/Eucla", App.trans("GMT+08:45 Australia/Eucla")] // es: Australia/Eucla, de: Australia/Eucla, fr: Australia/Eucla
        ],
        AW: [
            ["America/Aruba", App.trans("GMT-04:00 America/Aruba")] // es: América/Aruba, de: Amerika/Aruba, fr: Amérique/Aruba
        ],
        AX: [
            ["Europe/Mariehamn", App.trans("GMT+03:00 Europe/Mariehamn")] // es: Europe/Mariehamn, de: Europe/Mariehamn, fr: Europe/Mariehamn
        ],
        AZ: [
            ["Asia/Baku", App.trans("GMT+05:00 Asia/Baku")] // es: Asia/Baku, de: Asien/Baku, fr: Asie/Baku
        ],
        BA: [
            ["Europe/Sarajevo", App.trans("GMT+02:00 Europe/Sarajevo")] // es: Europa/Sarajevo, de: Europa/Sarajevo, fr: Europe/Sarajevo
        ],
        BB: [
            ["America/Barbados", App.trans("GMT-04:00 America/Barbados")] // es: América/Barbados, de: Amerika/Barbados, fr: Amérique/Barbade
        ],
        BD: [
            ["Asia/Dhaka", App.trans("GMT+06:00 Asia/Dhaka")] // es: Asia/Dhaka, de: Asien/Dhaka, fr: Asie/Dhaka
        ],
        BE: [
            ["Europe/Brussels", App.trans("GMT+02:00 Europe/Brussels")] // es: Europa/Bruselas, de: Europa/Brüssel, fr: Europe/Bruxelles
        ],
        BF: [
            ["Africa/Ouagadougou", App.trans("GMT+00:00 Africa/Ouagadougou")] // es: África/Ouagadougou, de: Afrika/Ouagadougou, fr: Afrique/Ouagadougou
        ],
        BG: [
            ["Europe/Sofia", App.trans("GMT+03:00 Europe/Sofia")] // es: Europa/Sofía, de: Europa/Sofia, fr: Europe/Sofia
        ],
        BH: [
            ["Asia/Bahrain", App.trans("GMT+03:00 Asia/Bahrain")] // es: Asia/Bahrain, de: Asien/Bahrein, fr: Asie/Barhain
        ],
        BI: [
            ["Africa/Bujumbura", App.trans("GMT+02:00 Africa/Bujumbura")] // es: África/Bujumbura, de: Afrika/Bujumbura, fr: Afrique/Bujumbura
        ],
        BJ: [
            ["Africa/Porto-Novo", App.trans("GMT+01:00 Africa/Porto-Novo")] // es: África/Porto-Novo, de: Afrika/Porto-Novo, fr: Afrique/Porto-Novo
        ],
        BL: [
            ["America/St_Barthelemy", App.trans("GMT-04:00 America/St_Barthelemy")] // es: America/St_Barthelemy, de: America/St_Barthelemy, fr: America/St_Barthelemy
        ],
        BM: [
            ["Atlantic/Bermuda", App.trans("GMT-03:00 Atlantic/Bermuda")] // es: Atlántico/Bermudas, de: Atlantik/Bermudas, fr: Atlantique/Bermudes
        ],
        BN: [
            ["Asia/Brunei", App.trans("GMT+08:00 Asia/Brunei")] // es: Asia/Brunei, de: Asien/Brunei, fr: Asie/Brunei
        ],
        BO: [
            ["America/La_Paz", App.trans("GMT-04:00 America/La_Paz")] // es: América/La_Paz, de: Amerika/La_Paz, fr: Amérique/La Paz
        ],
        BR: [
            ["America/Noronha", App.trans("GMT-02:00 America/Noronha")], // es: América/Noronha, de: Amerika/Noronha, fr: Amérique/Noronha
            ["America/Belem", App.trans("GMT-03:00 America/Belem")], // es: América/Belem, de: Amerika/Belem, fr: Amérique/Belem
            ["America/Fortaleza", App.trans("GMT-03:00 America/Fortaleza")], // es: América/Fortaleza, de: Amerika/Fortaleza, fr: Amérique/Fortaleza
            ["America/Recife", App.trans("GMT-03:00 America/Recife")], // es: América/Recife, de: Amerika/Recife, fr: Amérique/Recife
            ["America/Araguaina", App.trans("GMT-03:00 America/Araguaina")], // es: América/Araguaina, de: Amerika/Araguania, fr: Amérique/Araguaina
            ["America/Maceio", App.trans("GMT-03:00 America/Maceio")], // es: América/Maceio, de: Amerika/Maceio, fr: Amérique/Maceio
            ["America/Bahia", App.trans("GMT-03:00 America/Bahia")], // es: America/Bahia, de: America/Bahia, fr: America/Bahia
            ["America/Sao_Paulo", App.trans("GMT-03:00 America/Sao_Paulo")], // es: América/Sao_Paulo, de: Amerika/Sao_Paulo, fr: Amérique/Sao_Paulo
            ["America/Campo_Grande", App.trans("GMT-04:00 America/Campo_Grande")], // es: America/Campo_Grande, de: America/Campo_Grande, fr: America/Campo_Grande
            ["America/Cuiaba", App.trans("GMT-04:00 America/Cuiaba")], // es: América/Cuiaba, de: Amerika/Cuiaba, fr: Amérique/Cuiaba
            ["America/Santarem", App.trans("GMT-03:00 America/Santarem")], // es: America/Santarem, de: America/Santarem, fr: America/Santarem
            ["America/Porto_Velho", App.trans("GMT-04:00 America/Porto_Velho")], // es: América/Porto_Velho, de: Amerika/Porto_Velho, fr: Amérique/Porto Velho
            ["America/Boa_Vista", App.trans("GMT-04:00 America/Boa_Vista")], // es: América/Boa_Vista, de: Amerika/Boa_Vista, fr: Amérique/Boa_Vista
            ["America/Manaus", App.trans("GMT-04:00 America/Manaus")], // es: América/Manaus, de: Amerika/Manaus, fr: Amérique/Manaus
            ["America/Eirunepe", App.trans("GMT-04:00 America/Eirunepe")], // es: América/Eirunepe, de: Amerika/Eirunepe, fr: Amérique/Eirunepe
            ["America/Rio_Branco", App.trans("GMT-04:00 America/Rio_Branco")] // es: América/Rio_Branco, de: Amerika/Rio_Branco, fr: Amérique/Rio_Branco
        ],
        BS: [
            ["America/Nassau", App.trans("GMT-04:00 America/Nassau")] // es: América/Nassau, de: Amerika/Nassau, fr: Amérique/Nassau
        ],
        BT: [
            ["Asia/Thimphu", App.trans("GMT+06:00 Asia/Thimphu")] // es: Asia/Thimphu, de: Asien/Thimphu, fr: Asie/Thimphu
        ],
        BW: [
            ["Africa/Gaborone", App.trans("GMT+02:00 Africa/Gaborone")] // es: África/Gaborone, de: Afrika/Gaboron, fr: Afrique/Gaborone
        ],
        BY: [
            ["Europe/Minsk", App.trans("GMT+03:00 Europe/Minsk")] // es: Europa/Minsk, de: Europa/Minsk, fr: Europe/Minsk
        ],
        BZ: [
            ["America/Belize", App.trans("GMT-06:00 America/Belize")] // es: América/Bélice, de: Amerika/Belize, fr: Amérique/Belize
        ],
        CA: [
            ["America/St_Johns", App.trans("GMT-03:30 America/St_Johns")], // es: América/St_Johns, de: Amerika/St._Johns, fr: Amérique/St_Johns
            ["America/Halifax", App.trans("GMT-03:00 America/Halifax")], // es: América/Halifax, de: Amerika/Halifax, fr: Amérique/Halifax
            ["America/Glace_Bay", App.trans("GMT-03:00 America/Glace_Bay")], // es: América/Glace_Bay, de: Amerika/Glace_Bay, fr: Amérique/Glace_Bay
            ["America/Moncton", App.trans("GMT-03:00 America/Moncton")], // es: America/Moncton, de: America/Moncton, fr: America/Moncton
            ["America/Goose_Bay", App.trans("GMT-03:00 America/Goose_Bay")], // es: América/Goose_Bay, de: Amerika/Goose_Bay, fr: Amérique/Goose_Bay
            ["America/Blanc-Sablon", App.trans("GMT-04:00 America/Blanc-Sablon")], // es: America/Blanc-Sablon, de: America/Blanc-Sablon, fr: America/Blanc-Sablon
            ["America/Montreal", App.trans("GMT-04:00 America/Montreal")], // es: América/Montreal, de: Amerika/Montreal, fr: Amérique/Montréal
            ["America/Toronto", App.trans("GMT-04:00 America/Toronto")], // es: America/Toronto, de: America/Toronto, fr: America/Toronto
            ["America/Nipigon", App.trans("GMT-04:00 America/Nipigon")], // es: América/Nipigon, de: Amerika/Nipigon, fr: Amérique/Nipigon
            ["America/Thunder_Bay", App.trans("GMT-04:00 America/Thunder_Bay")], // es: América/Thunder_Bay, de: Amerika/Thunder_Bay, fr: Amérique/Thunder_Bay
            ["America/Iqaluit", App.trans("GMT-04:00 America/Iqaluit")], // es: América/Iqaluit, de: Amerika/Iqaluit, fr: Amérique/Iqaluit
            ["America/Pangnirtung", App.trans("GMT-04:00 America/Pangnirtung")], // es: América/Pangnirtung, de: Amerika/Pangnirtung, fr: Amérique/Pangnirtung
            ["America/Resolute", App.trans("GMT-05:00 America/Resolute")], // es: America/Resolute, de: America/Resolute, fr: America/Resolute
            ["America/Atikokan", App.trans("GMT-05:00 America/Atikokan")], // es: America/Atikokan, de: America/Atikokan, fr: America/Atikokan
            ["America/Rankin_Inlet", App.trans("GMT-05:00 America/Rankin_Inlet")], // es: América/Rankin_Inlet, de: Amerika/Rankin_Inlet, fr: Amérique/Rankin Inlet
            ["America/Winnipeg", App.trans("GMT-05:00 America/Winnipeg")], // es: América/Winnipeg, de: Amerika/Winnipeg, fr: Amérique/Winnipeg
            ["America/Rainy_River", App.trans("GMT-05:00 America/Rainy_River")], // es: América/Rainy_River, de: Amerika/Rainy_River, fr: Amérique/Rainy River
            ["America/Regina", App.trans("GMT-06:00 America/Regina")], // es: América/Regina, de: Amerika/Regina, fr: Amérique/Regina
            ["America/Swift_Current", App.trans("GMT-06:00 America/Swift_Current")], // es: América/Swift_Current, de: Amerika/Swift_Current, fr: Amérique/Swift_Current
            ["America/Edmonton", App.trans("GMT-06:00 America/Edmonton")], // es: América/Edmonton, de: Amerika/Edmonton, fr: Amérique/Edmonton
            ["America/Cambridge_Bay", App.trans("GMT-06:00 America/Cambridge_Bay")], // es: América/Cambridge_Bay, de: Amerika/Cambridge_Bay, fr: Amérique/Cambridge_Bay
            ["America/Yellowknife", App.trans("GMT-06:00 America/Yellowknife")], // es: América/Yellowknife, de: Amerika/Yellowknife, fr: Amérique/Yellowknife
            ["America/Inuvik", App.trans("GMT-06:00 America/Inuvik")], // es: América/Inuvik, de: Amerika/Inuvik, fr: Amérique/Inuvik
            ["America/Dawson_Creek", App.trans("GMT-07:00 America/Dawson_Creek")], // es: América/Dawson_Creek, de: Amerika/Dawson_Creek, fr: Amérique/Dawson_Creek
            ["America/Vancouver", App.trans("GMT-07:00 America/Vancouver")], // es: América/Vancouver, de: Amerika/Vancouver, fr: Amérique/Vancouver
            ["America/Whitehorse", App.trans("GMT-07:00 America/Whitehorse")], // es: América/Whitehorse, de: Amerika/Whitehorse, fr: Amérique/Whitehorse
            ["America/Dawson", App.trans("GMT-07:00 America/Dawson")] // es: América/Dawson, de: Amerika/Dawson, fr: Amérique/Dawson
        ],
        CC: [
            ["Indian/Cocos", App.trans("GMT+06:30 Indian/Cocos")] // es: Índico/Cocos, de: Indisch/Cocos, fr: Indian/Cocos
        ],
        CD: [
            ["Africa/Kinshasa", App.trans("GMT+01:00 Africa/Kinshasa")], // es: África/Kinshasa, de: Afrika/Kinshasa, fr: Afrique/Kinshasa
            ["Africa/Lubumbashi", App.trans("GMT+02:00 Africa/Lubumbashi")] // es: África/Lubumbashi, de: Afrika/Lubumbashi, fr: Afrique/Lubumbashi
        ],
        CF: [
            ["Africa/Bangui", App.trans("GMT+01:00 Africa/Bangui")] // es: África/Bangui, de: Afrika/Bangui, fr: Afrique/Bangui
        ],
        CG: [
            ["Africa/Brazzaville", App.trans("GMT+01:00 Africa/Brazzaville")] // es: África/Brazzaville, de: Afrika/Brazzaville, fr: Afrique/Brazzaville
        ],
        CH: [
            ["Europe/Zurich", App.trans("GMT+02:00 Europe/Zurich")] // es: Europa/Zúrich, de: Europa/Zürich, fr: Europe/Zurich
        ],
        CI: [
            ["Africa/Abidjan", App.trans("GMT+00:00 Africa/Abidjan")] // es: África/Abidjan, de: Afrika/Abidjan, fr: Afrique/Abidjan
        ],
        CK: [
            ["Pacific/Rarotonga", App.trans("GMT-10:00 Pacific/Rarotonga")] // es: Pacífico/Rarotonga, de: Pazifik/Rarotonga, fr: Pacifique/Rarotonga
        ],
        CL: [
            ["America/Santiago", App.trans("GMT-04:00 America/Santiago")], // es: América/Santiago, de: Amerika/Santiago, fr: Amérique/Santiago
            ["Pacific/Easter", App.trans("GMT-06:00 Pacific/Easter")] // es: Pacífico/Pascua, de: Pazifik/Easter, fr: Pacifique/Easter
        ],
        CM: [
            ["Africa/Douala", App.trans("GMT+01:00 Africa/Douala")] // es: África/Douala, de: Afrika/Douala, fr: Afrique/Douala
        ],
        CN: [
            ["Asia/Shanghai", App.trans("GMT+08:00 Asia/Shanghai")], // es: Asia/Shanghai, de: Asien/Shanghai, fr: Asie/Shanghai
            ["Asia/Harbin", App.trans("GMT+08:00 Asia/Harbin")], // es: Asia/Harbin, de: Asien/Harbin, fr: Asie/Harbin
            ["Asia/Chongqing", App.trans("GMT+08:00 Asia/Chongqing")], // es: Asia/Chongqing, de: Asien/Chongqing, fr: Asie/Chungqing
            ["Asia/Urumqi", App.trans("GMT+08:00 Asia/Urumqi")], // es: Asia/Urumqi, de: Asien/Urumqi, fr: Asie/Urumqi
            ["Asia/Kashgar", App.trans("GMT+08:00 Asia/Kashgar")] // es: Asia/Kashgar, de: Asien/Kashgar, fr: Asie/Kashgar
        ],
        CO: [
            ["America/Bogota", App.trans("GMT-05:00 America/Bogota")] // es: América/Bogotá, de: Amerika/Bogota, fr: Amerique/Bogota
        ],
        CR: [
            ["America/Costa_Rica", App.trans("GMT-06:00 America/Costa_Rica")] // es: América/Costa_Rica, de: Amerika/Costa_Rica, fr: Amérique/Costa_Rica
        ],
        CU: [
            ["America/Havana", App.trans("GMT-04:00 America/Havana")] // es: América/La_Habana, de: Amerika/Havana, fr: Amérique/La Havane
        ],
        CV: [
            ["Atlantic/Cape_Verde", App.trans("GMT-01:00 Atlantic/Cape_Verde")] // es: Atlántico/Capo_verde, de: Atlantik/Kap_Verde, fr: Atlantique/Cap-Vert
        ],
        CX: [
            ["Indian/Christmas", App.trans("GMT+07:00 Indian/Christmas")] // es: Índico/Christmas, de: Indisch/Christmas, fr: Indian/Christmas
        ],
        CY: [
            ["Asia/Nicosia", App.trans("GMT+03:00 Asia/Nicosia")] // es: Asia/Nicosia, de: Asien/Nicosia, fr: Asie/Nicosie
        ],
        CZ: [
            ["Europe/Prague", App.trans("GMT+02:00 Europe/Prague")] // es: Europa/Praga, de: Europa/Prag, fr: Europe/Prague
        ],
        DE: [
            ["Europe/Berlin", App.trans("GMT+02:00 Europe/Berlin")] // es: Europa/Berlín, de: Europa/Berlin, fr: Europe/Berlin
        ],
        DJ: [
            ["Africa/Djibouti", App.trans("GMT+03:00 Africa/Djibouti")] // es: África/Djibouti, de: Afrika/Dschibuti, fr: Afrique/Djibouti
        ],
        DK: [
            ["Europe/Copenhagen", App.trans("GMT+02:00 Europe/Copenhagen")] // es: Europa/Copenague, de: Europa/Kopenhagen, fr: Europe/Copenhague
        ],
        DM: [
            ["America/Dominica", App.trans("GMT-04:00 America/Dominica")] // es: América/Dominica, de: Amerika/Dominica, fr: Amérique/Dominica
        ],
        DO: [
            ["America/Santo_Domingo", App.trans("GMT-04:00 America/Santo_Domingo")] // es: América/Santo_Domingo, de: Amerika/Santo_Domingo, fr: Amérique/Santo_Domingo
        ],
        DZ: [
            ["Africa/Algiers", App.trans("GMT+01:00 Africa/Algiers")] // es: África/Argel, de: Afrika/Algier, fr: Afrique/Alger
        ],
        EC: [
            ["America/Guayaquil", App.trans("GMT-05:00 America/Guayaquil")], // es: América/Guayaquil, de: Amerika/Guayaquil, fr: Amérique/Guayaquil
            ["Pacific/Galapagos", App.trans("GMT-06:00 Pacific/Galapagos")] // es: Pacífico/Galápagos, de: Pazifik/Galapagos, fr: Pacifique/Galapagos
        ],
        EE: [
            ["Europe/Tallinn", App.trans("GMT+03:00 Europe/Tallinn")] // es: Europa/Tallinn, de: Europa/Tallinn, fr: Europe/Tallinn
        ],
        EG: [
            ["Africa/Cairo", App.trans("GMT+03:00 Africa/Cairo")] // es: África/Cairo, de: Afrika/Kairo, fr: Afrique/Caire
        ],
        EH: [
            ["Africa/El_Aaiun", App.trans("GMT+00:00 Africa/El_Aaiun")] // es: África/El_Aaiun, de: Afrika/El_Aaium, fr: Afrique/El_Aaiun
        ],
        ER: [
            ["Africa/Asmara", App.trans("GMT+03:00 Africa/Asmara")] // es: Africa/Asmara, de: Africa/Asmara, fr: Africa/Asmara
        ],
        ES: [
            ["Europe/Madrid", App.trans("GMT+02:00 Europe/Madrid")], // es: Europa/Madrid, de: Europa/Madrid, fr: Europe/Madrid
            ["Africa/Ceuta", App.trans("GMT+02:00 Africa/Ceuta")], // es: África/Ceuta, de: Afrika/Ceuta, fr: Afrique/Ceuta
            ["Atlantic/Canary", App.trans("GMT+01:00 Atlantic/Canary")] // es: Atlántico/Canarias, de: Atlantik/Kanaren, fr: Atlantique/Canaries
        ],
        ET: [
            ["Africa/Addis_Ababa", App.trans("GMT+03:00 Africa/Addis_Ababa")] // es: África/Addis Abeba, de: Afrika/Addis_Ababa, fr: Afrique/Addis_Ababa
        ],
        FI: [
            ["Europe/Helsinki", App.trans("GMT+03:00 Europe/Helsinki")] // es: Europa/Helsinki, de: Europa/Helsinki, fr: Europe/Helsinki
        ],
        FJ: [
            ["Pacific/Fiji", App.trans("GMT+12:00 Pacific/Fiji")] // es: Pacífico/Fiji, de: Pazifik/Fidschi, fr: Pacifique/Fidji
        ],
        FK: [
            ["Atlantic/Stanley", App.trans("GMT-04:00 Atlantic/Stanley")] // es: Atlántico/Stanley, de: Atlantik/Stanley, fr: Atlantique/Stanley
        ],
        FM: [
            ["Pacific/Truk", App.trans("GMT+10:00 Pacific/Truk")], // es: Pacífico/Truk, de: Pazifik/Truk, fr: Pacifique/Truk
            ["Pacific/Ponape", App.trans("GMT+11:00 Pacific/Ponape")], // es: Pacífico/Ponape, de: Pazifik/Ponape, fr: Pacifique/Ponape
            ["Pacific/Kosrae", App.trans("GMT+11:00 Pacific/Kosrae")] // es: Pacífico/Kosrae, de: Pazifik/Kosrae, fr: Pacifique/Kosrae
        ],
        FO: [
            ["Atlantic/Faroe", App.trans("GMT+01:00 Atlantic/Faroe")] // es: Atlantic/Faroe, de: Atlantic/Faroe, fr: Atlantic/Faroe
        ],
        FR: [
            ["Europe/Paris", App.trans("GMT+02:00 Europe/Paris")] // es: Europa/París, de: Europa/Paris, fr: Europe/Paris
        ],
        GA: [
            ["Africa/Libreville", App.trans("GMT+01:00 Africa/Libreville")] // es: África/Libreville, de: Afrika/Libreville, fr: Afrique/Libreville
        ],
        GB: [
            ["Europe/London", App.trans("GMT+01:00 Europe/London")] // es: Europa/Londres, de: Europa/London, fr: Europe/Londres
        ],
        GD: [
            ["America/Grenada", App.trans("GMT-04:00 America/Grenada")] // es: América/Granada, de: Amerika/Grenada, fr: Amérique/Grenade
        ],
        GE: [
            ["Asia/Tbilisi", App.trans("GMT+04:00 Asia/Tbilisi")] // es: Asia/Tbilisi, de: Asien/Tbilisi, fr: Asie/Tbilisi
        ],
        GF: [
            ["America/Cayenne", App.trans("GMT-03:00 America/Cayenne")] // es: América/Cayenne, de: Amerika/Cayenne, fr: Amérique/Cayenne
        ],
        GG: [
            ["Europe/Guernsey", App.trans("GMT+01:00 Europe/Guernsey")] // es: Europe/Guernsey, de: Europe/Guernsey, fr: Europe/Guernsey
        ],
        GH: [
            ["Africa/Accra", App.trans("GMT+00:00 Africa/Accra")] // es: África/Accra, de: Afrika/Accra, fr: Afrique/Accra
        ],
        GI: [
            ["Europe/Gibraltar", App.trans("GMT+02:00 Europe/Gibraltar")] // es: Europa/Gibraltar, de: Europa/Gibraltar, fr: Europe/Gibraltar
        ],
        GL: [
            ["America/Godthab", App.trans("GMT-02:00 America/Godthab")], // es: América/Godthab, de: Amerika/Godthab, fr: Amérique/Godthab
            ["America/Danmarkshavn", App.trans("GMT+00:00 America/Danmarkshavn")], // es: América/Danmarkshavn, de: Amerika/Danmarkshavn, fr: Amérique/Danmarkshavn
            ["America/Scoresbysund", App.trans("GMT+00:00 America/Scoresbysund")], // es: América/Scoresbysund, de: Amerika/Scoresbysund, fr: Amérique/Scoresbysund
            ["America/Thule", App.trans("GMT-03:00 America/Thule")] // es: América/Thule, de: Amerika/Thule, fr: Amérique/Thule
        ],
        GM: [
            ["Africa/Banjul", App.trans("GMT+00:00 Africa/Banjul")] // es: África/Banjul, de: Afrika/Banjul, fr: Afrique/Banjul
        ],
        GN: [
            ["Africa/Conakry", App.trans("GMT+00:00 Africa/Conakry")] // es: África/Conakry, de: Afrika/Conakry, fr: Afrique/Conakry
        ],
        GP: [
            ["America/Guadeloupe", App.trans("GMT-04:00 America/Guadeloupe")] // es: América/Guadalupe, de: Amerika/Guadeloupe, fr: Amérique/Guadeloupe
        ],
        GQ: [
            ["Africa/Malabo", App.trans("GMT+01:00 Africa/Malabo")] // es: África/Malabo, de: Afrika/Malabo, fr: Afrique/Malabo
        ],
        GR: [
            ["Europe/Athens", App.trans("GMT+03:00 Europe/Athens")] // es: Europa/Atenas, de: Europa/Athen, fr: Europe/Athènes
        ],
        GS: [
            ["Atlantic/South_Georgia", App.trans("GMT-02:00 Atlantic/South_Georgia")] // es: Atlántico/South_Georgia, de: Atlantik/South_Georgia, fr: Atlantique/South_Georgia
        ],
        GT: [
            ["America/Guatemala", App.trans("GMT-06:00 America/Guatemala")] // es: América/Guatemala, de: Amerika/Guatemala, fr: Amérique/Guatemala
        ],
        GU: [
            ["Pacific/Guam", App.trans("GMT+10:00 Pacific/Guam")] // es: Pacífico/Guam, de: Pazifik/Guam, fr: Pacifique/Guam
        ],
        GW: [
            ["Africa/Bissau", App.trans("GMT+00:00 Africa/Bissau")] // es: África/Bissau, de: Afrika/Bissau, fr: Afrique/Bissau
        ],
        GY: [
            ["America/Guyana", App.trans("GMT-04:00 America/Guyana")] // es: América/Guyana, de: Amerika/Guyana, fr: Amérique/Guyane
        ],
        HK: [
            ["Asia/Hong_Kong", App.trans("GMT+08:00 Asia/Hong_Kong")] // es: Asia/Hong_Kong, de: Asien/Hong_Kong, fr: Asie/Hong_Kong
        ],
        HN: [
            ["America/Tegucigalpa", App.trans("GMT-06:00 America/Tegucigalpa")] // es: América/Tegucigalpa, de: Amerika/Tegucigalpa, fr: Amérique/Tegucigalpa
        ],
        HR: [
            ["Europe/Zagreb", App.trans("GMT+02:00 Europe/Zagreb")] // es: Europa/Zagreb, de: Europa/Zagreb, fr: Europe/Zagreb
        ],
        HT: [
            ["America/Port-au-Prince", App.trans("GMT-05:00 America/Port-au-Prince")] // es: América/Port-au-Prince, de: Amerika/Port-au-Prince, fr: Amérique/Port-au-Prince
        ],
        HU: [
            ["Europe/Budapest", App.trans("GMT+02:00 Europe/Budapest")] // es: Europa/Budapest, de: Europa/Budapest, fr: Europe/Budapest
        ],
        ID: [
            ["Asia/Jakarta", App.trans("GMT+07:00 Asia/Jakarta")], // es: Asia/Jakarta, de: Asien/Jakarta, fr: Asie/Jakarta
            ["Asia/Pontianak", App.trans("GMT+07:00 Asia/Pontianak")], // es: Asia/Pontianak, de: Asien/Pontianak, fr: Asie/Pontianak
            ["Asia/Makassar", App.trans("GMT+08:00 Asia/Makassar")], // es: Asia/Makassar, de: Asien/Makassar, fr: Asie/Makassar
            ["Asia/Jayapura", App.trans("GMT+09:00 Asia/Jayapura")] // es: Asia/Jayapura, de: Asien/Jayapura, fr: Asie/Jayapura
        ],
        IE: [
            ["Europe/Dublin", App.trans("GMT+01:00 Europe/Dublin")] // es: Europa/Dublín, de: Europa/Dublin, fr: Europe/Dublin
        ],
        IL: [
            ["Asia/Jerusalem", App.trans("GMT+03:00 Asia/Jerusalem")] // es: Asia/Jerusalem, de: Asien/Jerusalem, fr: Asie/Jérusalem
        ],
        IM: [
            ["Europe/Isle_of_Man", App.trans("GMT+01:00 Europe/Isle_of_Man")] // es: Europe/Isle_of_Man, de: Europe/Isle_of_Man, fr: Europe/Isle_of_Man
        ],
        IN: [
            ["Asia/Kolkata", App.trans("GMT+05:30 Asia/Kolkata")] // es: Asia/Kolkata, de: Asia/Kolkata, fr: Asia/Kolkata
        ],
        IO: [
            ["Indian/Chagos", App.trans("GMT+06:00 Indian/Chagos")] // es: Índico/Chagos, de: Indisch/Chagos, fr: Indian/Chagos
        ],
        IQ: [
            ["Asia/Baghdad", App.trans("GMT+03:00 Asia/Baghdad")] // es: Asia/Baghdad, de: Asien/Baghdad, fr: Asie/Baghdad
        ],
        IR: [
            ["Asia/Tehran", App.trans("GMT+04:30 Asia/Tehran")] // es: Asia/Teherán, de: Asien/Tehran, fr: Asie/Téhéran
        ],
        IS: [
            ["Atlantic/Reykjavik", App.trans("GMT+00:00 Atlantic/Reykjavik")] // es: Atlántico/Reykjavik, de: Atlantik/Reykjavik, fr: Atlantique/Reykjavik
        ],
        IT: [
            ["Europe/Rome", App.trans("GMT+02:00 Europe/Rome")] // es: Europa/Roma, de: Europa/Rom, fr: Europe/Rome
        ],
        JE: [
            ["Europe/Jersey", App.trans("GMT+01:00 Europe/Jersey")] // es: Europe/Jersey, de: Europe/Jersey, fr: Europe/Jersey
        ],
        JM: [
            ["America/Jamaica", App.trans("GMT-05:00 America/Jamaica")] // es: América/Jamaica, de: Amerika/Jamaika, fr: Amérique/Jamaïque
        ],
        JO: [
            ["Asia/Amman", App.trans("GMT+03:00 Asia/Amman")] // es: Asia/Amman, de: Asien/Amman, fr: Asie/Amman
        ],
        JP: [
            ["Asia/Tokyo", App.trans("GMT+09:00 Asia/Tokyo")] // es: Asia/Tokio, de: Asien/Tokyo, fr: Asie/Tokyo
        ],
        KE: [
            ["Africa/Nairobi", App.trans("GMT+03:00 Africa/Nairobi")] // es: África/Nairobi, de: Afrika/Nairobi, fr: Afrique/Nairobi
        ],
        KG: [
            ["Asia/Bishkek", App.trans("GMT+06:00 Asia/Bishkek")] // es: Asia/Bishkek, de: Asien/Bishkek, fr: Asie/Bishkek
        ],
        KH: [
            ["Asia/Phnom_Penh", App.trans("GMT+07:00 Asia/Phnom_Penh")] // es: Asia/Phnom_Penh, de: Asien/Phnom_Penh, fr: Asie/Phnom_Penh
        ],
        KI: [
            ["Pacific/Tarawa", App.trans("GMT+12:00 Pacific/Tarawa")], // es: Pacífico/Tarawa, de: Pazifik/Tarawa, fr: Pacifique/Tarawa
            ["Pacific/Enderbury", App.trans("GMT+13:00 Pacific/Enderbury")], // es: Pacífico/Enderbury, de: Pazifik/Enderbury, fr: Pacifique/Enderbury
            ["Pacific/Kiritimati", App.trans("GMT+14:00 Pacific/Kiritimati")] // es: Pacífico/Kiritimati, de: Pazifik/Kiritimati, fr: Pacifique/Kiritimati
        ],
        KM: [
            ["Indian/Comoro", App.trans("GMT+03:00 Indian/Comoro")] // es: Índico/Comores, de: Indisch/Komoren, fr: Indian/Comores
        ],
        KN: [
            ["America/St_Kitts", App.trans("GMT-04:00 America/St_Kitts")] // es: América/St_Kitts, de: Amerika/St._Kitts, fr: Amérique/St_Kitts
        ],
        KP: [
            ["Asia/Pyongyang", App.trans("GMT+09:00 Asia/Pyongyang")] // es: Asia/Pyongyang, de: Asien/Pyongyang, fr: Asie/Pyongyang
        ],
        KR: [
            ["Asia/Seoul", App.trans("GMT+09:00 Asia/Seoul")] // es: Asia/Seúl, de: Asien/Seoul, fr: Asie/Séoul
        ],
        KW: [
            ["Asia/Kuwait", App.trans("GMT+03:00 Asia/Kuwait")] // es: Asia/Kuwait, de: Asien/Kuwait, fr: Asie/Koweït
        ],
        KY: [
            ["America/Cayman", App.trans("GMT-05:00 America/Cayman")] // es: América/Caimán, de: Amerika/Cayman, fr: Amérique/Cayman
        ],
        KZ: [
            ["Asia/Almaty", App.trans("GMT+06:00 Asia/Almaty")], // es: Asia/Almaty, de: Asien/Almaty, fr: Asie/Almaty
            ["Asia/Qyzylorda", App.trans("GMT+06:00 Asia/Qyzylorda")], // es: Asia/Qyzylorda, de: Asien/Qyzylorda, fr: Asie/Qyzylorda
            ["Asia/Aqtobe", App.trans("GMT+05:00 Asia/Aqtobe")], // es: Asia/Aqtobe, de: Asien/Aqtobe, fr: Asie/Aqtobe
            ["Asia/Aqtau", App.trans("GMT+05:00 Asia/Aqtau")], // es: Asia/Aqtau, de: Asien/Aqtau, fr: Asie/Aqtau
            ["Asia/Oral", App.trans("GMT+05:00 Asia/Oral")] // es: Asia/Oral, de: Asien/Oral, fr: Asie/Oral
        ],
        LA: [
            ["Asia/Vientiane", App.trans("GMT+07:00 Asia/Vientiane")] // es: Asia/Vientiane, de: Asien/Vientiane, fr: Asie/Vientiane
        ],
        LB: [
            ["Asia/Beirut", App.trans("GMT+03:00 Asia/Beirut")] // es: Asia/Beirut, de: Asien/Beirut, fr: Asie/Beyrouth
        ],
        LC: [
            ["America/St_Lucia", App.trans("GMT-04:00 America/St_Lucia")] // es: América/St_Lucia, de: Amerika/St._Lucia, fr: Amérique/St_Lucia
        ],
        LI: [
            ["Europe/Vaduz", App.trans("GMT+02:00 Europe/Vaduz")] // es: Europa/Vaduz, de: Europa/Vaduz, fr: Europe/Vaduz
        ],
        LK: [
            ["Asia/Colombo", App.trans("GMT+05:30 Asia/Colombo")] // es: Asia/Colombo, de: Asien/Colombo, fr: Asie/Colombo
        ],
        LR: [
            ["Africa/Monrovia", App.trans("GMT+00:00 Africa/Monrovia")] // es: África/Monrovia, de: Afrika/Monrovia, fr: Afrique/Monrovia
        ],
        LS: [
            ["Africa/Maseru", App.trans("GMT+02:00 Africa/Maseru")] // es: África/Maseru, de: Afrika/Maseru, fr: Afrique/Maseru
        ],
        LT: [
            ["Europe/Vilnius", App.trans("GMT+03:00 Europe/Vilnius")] // es: Europa/Vilnius, de: Europa/Vilnius, fr: Europe/Vilnius
        ],
        LU: [
            ["Europe/Luxembourg", App.trans("GMT+02:00 Europe/Luxembourg")] // es: Europa/Luxemburgo, de: Europa/Luxemburg, fr: Europe/Luxembourg
        ],
        LV: [
            ["Europe/Riga", App.trans("GMT+03:00 Europe/Riga")] // es: Europa/Riga, de: Europa/Riga, fr: Europe/Riga
        ],
        LY: [
            ["Africa/Tripoli", App.trans("GMT+02:00 Africa/Tripoli")] // es: África/Trípoli, de: Afrika/Tripoli, fr: Afrique/Tripoli
        ],
        MA: [
            ["Africa/Casablanca", App.trans("GMT+01:00 Africa/Casablanca")] // es: África/Casablanca, de: Afrika/Casablanca, fr: Afrique/Casablanca
        ],
        MC: [
            ["Europe/Monaco", App.trans("GMT+02:00 Europe/Monaco")] // es: Europa/Mónaco, de: Europa/Monaco, fr: Europe/Monaco
        ],
        MD: [
            ["Europe/Chisinau", App.trans("GMT+03:00 Europe/Chisinau")] // es: Europa/Chisinau, de: Europa/Chisinau, fr: Europe/Chisinau
        ],
        ME: [
            ["Europe/Podgorica", App.trans("GMT+02:00 Europe/Podgorica")] // es: Europe/Podgorica, de: Europe/Podgorica, fr: Europe/Podgorica
        ],
        MF: [
            ["America/Marigot", App.trans("GMT-04:00 America/Marigot")] // es: America/Marigot, de: America/Marigot, fr: America/Marigot
        ],
        MG: [
            ["Indian/Antananarivo", App.trans("GMT+03:00 Indian/Antananarivo")] // es: Índico/Antananarivo, de: Indisch/Antananarivo, fr: Indian/Tananarive
        ],
        MH: [
            ["Pacific/Majuro", App.trans("GMT+12:00 Pacific/Majuro")], // es: Pacífico/Majuro, de: Pazifik/Majuro, fr: Pacifique/Majuro
            ["Pacific/Kwajalein", App.trans("GMT+12:00 Pacific/Kwajalein")] // es: Pacífico/Kwajalein, de: Pazifik/Kwajalein, fr: Pacifique/Kwajalein
        ],
        MK: [
            ["Europe/Skopje", App.trans("GMT+02:00 Europe/Skopje")] // es: Europa/Skopje, de: Europa/Skopje, fr: Europe/Skopje
        ],
        ML: [
            ["Africa/Bamako", App.trans("GMT+00:00 Africa/Bamako")] // es: África/Bamako, de: Afrika/Bamako, fr: Afrique/Bamako
        ],
        MM: [
            ["Asia/Rangoon", App.trans("GMT+06:30 Asia/Rangoon")] // es: Asia/Rangún, de: Asien/Rangoon, fr: Asie/Rangoon
        ],
        MN: [
            ["Asia/Ulaanbaatar", App.trans("GMT+08:00 Asia/Ulaanbaatar")], // es: Asia/Ulaanbaatar, de: Asien/Ulaanbaatar, fr: Asie/Ulaanbaatar
            ["Asia/Hovd", App.trans("GMT+07:00 Asia/Hovd")], // es: Asia/Hovd, de: Asien/Hovd, fr: Asie/Hovd
            ["Asia/Choibalsan", App.trans("GMT+08:00 Asia/Choibalsan")] // es: Asia/Choibalsan, de: Asien/Choibalsan, fr: Asie/Choibalsan
        ],
        MO: [
            ["Asia/Macau", App.trans("GMT+08:00 Asia/Macau")] // es: Asia/Macau, de: Asien/Macau, fr: Asie/Macau
        ],
        MP: [
            ["Pacific/Saipan", App.trans("GMT+10:00 Pacific/Saipan")] // es: Pacífico/Saipán, de: Pazifik/Saipan, fr: Pacifique/Saipan
        ],
        MQ: [
            ["America/Martinique", App.trans("GMT-04:00 America/Martinique")] // es: América/Martinica, de: Amerika/Martinique, fr: Amérique/Martinique
        ],
        MR: [
            ["Africa/Nouakchott", App.trans("GMT+00:00 Africa/Nouakchott")] // es: África/Nouakchott, de: Afrika/Nouakchott, fr: Afrique/Nouakchott
        ],
        MS: [
            ["America/Montserrat", App.trans("GMT-04:00 America/Montserrat")] // es: América/Montserrat, de: Amerika/Montserrat, fr: Amérique/Montserrat
        ],
        MT: [
            ["Europe/Malta", App.trans("GMT+02:00 Europe/Malta")] // es: Europa/Malta, de: Europa/Malta, fr: Europe/Malte
        ],
        MU: [
            ["Indian/Mauritius", App.trans("GMT+04:00 Indian/Mauritius")] // es: Índico/Mauricio, de: Indisch/Mauritius, fr: Indian/Mauritius
        ],
        MV: [
            ["Indian/Maldives", App.trans("GMT+05:00 Indian/Maldives")] // es: Índico/Maldivas, de: Indisch/Malediven, fr: Indian/Maldives
        ],
        MW: [
            ["Africa/Blantyre", App.trans("GMT+02:00 Africa/Blantyre")] // es: África/Blantyre, de: Afrika/Blantyre, fr: Afrique/Blantyre
        ],
        MX: [
            ["America/Mexico_City", App.trans("GMT-05:00 America/Mexico_City")], // es: América/Ciudad_de_México, de: Amerika/Mexico_City, fr: Amérique/Mexico
            ["America/Cancun", App.trans("GMT-05:00 America/Cancun")], // es: América/Cancún, de: Amerika/Cancun, fr: Amérique/Cancun
            ["America/Merida", App.trans("GMT-05:00 America/Merida")], // es: América/Mérida, de: Amerika/Merida, fr: Amérique/Merida
            ["America/Monterrey", App.trans("GMT-05:00 America/Monterrey")], // es: América/Monterrey, de: Amerika/Monterrey, fr: Amérique/Monterrey
            ["America/Matamoros", App.trans("GMT-05:00 America/Matamoros")], // es: America/Matamoros, de: America/Matamoros, fr: America/Matamoros
            ["America/Mazatlan", App.trans("GMT-06:00 America/Mazatlan")], // es: América/Mazatlán, de: Amerika/Mazatlan, fr: Amérique/Mazatlan
            ["America/Chihuahua", App.trans("GMT-06:00 America/Chihuahua")], // es: América/Chihuahua, de: Amerika/Chihuahua, fr: Amérique/Chihuahua
            ["America/Ojinaga", App.trans("GMT-06:00 America/Ojinaga")], // es: America/Ojinaga, de: America/Ojinaga, fr: America/Ojinaga
            ["America/Hermosillo", App.trans("GMT-07:00 America/Hermosillo")], // es: América/Hermosillo, de: Amerika/Hermosillo, fr: Amérique/Hermosillo
            ["America/Tijuana", App.trans("GMT-07:00 America/Tijuana")], // es: América/Tijuana, de: Amerika/Tijuana, fr: Amérique/Tijuana
            ["America/Santa_Isabel", App.trans("GMT-07:00 America/Santa_Isabel")] // es: America/Santa_Isabel, de: America/Santa_Isabel, fr: America/Santa_Isabel
        ],
        MY: [
            ["Asia/Kuala_Lumpur", App.trans("GMT+08:00 Asia/Kuala_Lumpur")], // es: Asia/Kuala_Lumpur, de: Asien/Kuala_Lumpur, fr: Asie/Kuala_Lumpur
            ["Asia/Kuching", App.trans("GMT+08:00 Asia/Kuching")] // es: Asia/Kuching, de: Asien/Kuching, fr: Asie/Kuching
        ],
        MZ: [
            ["Africa/Maputo", App.trans("GMT+02:00 Africa/Maputo")] // es: África/Maputo, de: Afrika/Maputo, fr: Afrique/Maputo
        ],
        NA: [
            ["Africa/Windhoek", App.trans("GMT+01:00 Africa/Windhoek")] // es: África/Windhoek, de: Afrika/Windhoek, fr: Afrique/Windhoek
        ],
        NC: [
            ["Pacific/Noumea", App.trans("GMT+11:00 Pacific/Noumea")] // es: Pacífico/Noumea, de: Pazifik/Noumea, fr: Pacifique/Noumea
        ],
        NE: [
            ["Africa/Niamey", App.trans("GMT+01:00 Africa/Niamey")] // es: África/Niamey, de: Afrika/Niamey, fr: Afrique/Niamey
        ],
        NF: [
            ["Pacific/Norfolk", App.trans("GMT+11:30 Pacific/Norfolk")] // es: Pacífico/Norfolk, de: Pazifik/Norfolk, fr: Pacifique/Norfolk
        ],
        NG: [
            ["Africa/Lagos", App.trans("GMT+01:00 Africa/Lagos")] // es: África/Lagos, de: Afrika/Lagos, fr: Afrique/Lagos
        ],
        NI: [
            ["America/Managua", App.trans("GMT-06:00 America/Managua")] // es: América/Managua, de: Amerika/Managua, fr: Amérique/Managua
        ],
        NL: [
            ["Europe/Amsterdam", App.trans("GMT+02:00 Europe/Amsterdam")] // es: Europa/Amsterdam, de: Europa/Amsterdam, fr: Europe/Amsterdam
        ],
        NO: [
            ["Europe/Oslo", App.trans("GMT+02:00 Europe/Oslo")] // es: Europa/Oslo, de: Europa/Oslo, fr: Europe/Oslo
        ],
        NP: [
            ["Asia/Kathmandu", App.trans("GMT+05:45 Asia/Kathmandu")] // es: Asia/Kathmandu, de: Asia/Kathmandu, fr: Asia/Kathmandu
        ],
        NR: [
            ["Pacific/Nauru", App.trans("GMT+12:00 Pacific/Nauru")] // es: Pacífico/Nauru, de: Pazifik/Nauru, fr: Pacifique/Nauru
        ],
        NU: [
            ["Pacific/Niue", App.trans("GMT-11:00 Pacific/Niue")] // es: Pacífico/Niue, de: Pazifik/Niue, fr: Pacifique/Niue
        ],
        NZ: [
            ["Pacific/Auckland", App.trans("GMT+12:00 Pacific/Auckland")], // es: Pacífico/Auckland, de: Pazifik/Auckland, fr: Pacifique/Auckland
            ["Pacific/Chatham", App.trans("GMT+12:45 Pacific/Chatham")] // es: Pacífico/Chatham, de: Pazifik/Chatham, fr: Pacifique/Chatham
        ],
        OM: [
            ["Asia/Muscat", App.trans("GMT+04:00 Asia/Muscat")] // es: Asia/Muscat, de: Asien/Muscat, fr: Asie/Muscat
        ],
        PA: [
            ["America/Panama", App.trans("GMT-05:00 America/Panama")] // es: América/Panamá, de: Amerika/Panama, fr: Amérique/Panama
        ],
        PE: [
            ["America/Lima", App.trans("GMT-05:00 America/Lima")] // es: América/Lima, de: Amerika/Lima, fr: Amérique/Lima
        ],
        PF: [
            ["Pacific/Tahiti", App.trans("GMT-10:00 Pacific/Tahiti")], // es: Pacífico/Tahití, de: Pazifik/Tahiti, fr: Pacifique/Tahiti
            ["Pacific/Marquesas", App.trans("GMT-10:30 Pacific/Marquesas")], // es: Pacífico/Marquesas, de: Pazifik/Marquesas, fr: Pacifique/Marquesas
            ["Pacific/Gambier", App.trans("GMT-09:00 Pacific/Gambier")] // es: Pacífico/Gambier, de: Pazifik/Gambier, fr: Pacifique/Gambier
        ],
        PG: [
            ["Pacific/Port_Moresby", App.trans("GMT+10:00 Pacific/Port_Moresby")] // es: Pacífico/Port_Moresby, de: Pazifik/Port_Moresby, fr: Pacifique/Port_Moresby
        ],
        PH: [
            ["Asia/Manila", App.trans("GMT+08:00 Asia/Manila")] // es: Asia/Manila, de: Asien/Manila, fr: Asie/Manille
        ],
        PK: [
            ["Asia/Karachi", App.trans("GMT+05:00 Asia/Karachi")] // es: Asia/Karachi, de: Asien/Karachi, fr: Asie/Karachi
        ],
        PL: [
            ["Europe/Warsaw", App.trans("GMT+02:00 Europe/Warsaw")] // es: Europa/Varsovia, de: Europa/Warschau, fr: Europe/Varsovie
        ],
        PM: [
            ["America/Miquelon", App.trans("GMT-02:00 America/Miquelon")] // es: América/Miquelon, de: Amerika/Miquelon, fr: Amérique/Miquelon
        ],
        PN: [
            ["Pacific/Pitcairn", App.trans("GMT-08:00 Pacific/Pitcairn")] // es: Pacífico/Pitcairn, de: Pazifik/Pitcairn, fr: Pacifique/Pitcairn
        ],
        PR: [
            ["America/Puerto_Rico", App.trans("GMT-04:00 America/Puerto_Rico")] // es: América/Puerto_Rico, de: Amerika/Puerto_Rico, fr: Amérique/Puerto Rico
        ],
        PS: [
            ["Asia/Gaza", App.trans("GMT+03:00 Asia/Gaza")] // es: Asia/Gaza, de: Asien/Gaza, fr: Asie/Gaza
        ],
        PT: [
            ["Europe/Lisbon", App.trans("GMT+01:00 Europe/Lisbon")], // es: Europa/Lisboa, de: Europa/Lissabon, fr: Europe/Lisbonne
            ["Atlantic/Madeira", App.trans("GMT+01:00 Atlantic/Madeira")], // es: Atlántico/Madeira, de: Atlantik/Madeira, fr: Atlantique/Madère
            ["Atlantic/Azores", App.trans("GMT+00:00 Atlantic/Azores")] // es: Atlántico/Azores, de: Atlantik/Azoren, fr: Atlantique/Açores
        ],
        PW: [
            ["Pacific/Palau", App.trans("GMT+09:00 Pacific/Palau")] // es: Pacífico/Palau, de: Pazifik/Palau, fr: Pacifique/Palau
        ],
        PY: [
            ["America/Asuncion", App.trans("GMT-04:00 America/Asuncion")] // es: América/Asunción, de: Amerika/Asuncion, fr: Amérique/Asuncion
        ],
        QA: [
            ["Asia/Qatar", App.trans("GMT+03:00 Asia/Qatar")] // es: Asia/Qatar, de: Asien/Katar, fr: Asie/Qatar
        ],
        RE: [
            ["Indian/Reunion", App.trans("GMT+04:00 Indian/Reunion")] // es: Índico/Reunión, de: Indisch/Reunion, fr: Indian/Réunion
        ],
        RO: [
            ["Europe/Bucharest", App.trans("GMT+03:00 Europe/Bucharest")] // es: Europa/Bucarest, de: Europa/Bukarest, fr: Europe/Bucarest
        ],
        RS: [
            ["Europe/Belgrade", App.trans("GMT+02:00 Europe/Belgrade")] // es: Europa/Belgrado, de: Europa/Belgrad, fr: Europe/Belgrade
        ],
        RU: [
            ["Europe/Kaliningrad", App.trans("GMT+03:00 Europe/Kaliningrad")], // es: Europa/Kaliningrado, de: Europa/Kaliningrad, fr: Europe/Kaliningrad
            ["Europe/Moscow", App.trans("GMT+04:00 Europe/Moscow")], // es: Europa/Moscú, de: Europa/Moskau, fr: Europe/Moscou
            ["Europe/Volgograd", App.trans("GMT+04:00 Europe/Volgograd")], // es: Europe/Volgograd, de: Europe/Volgograd, fr: Europe/Volgograd
            ["Europe/Samara", App.trans("GMT+04:00 Europe/Samara")], // es: Europa/Samara, de: Europa/Samara, fr: Europe/Samara
            ["Asia/Yekaterinburg", App.trans("GMT+06:00 Asia/Yekaterinburg")], // es: Asia/Yekaterinburg, de: Asien/Jekaterinburg, fr: Asie/Yekaterinburg
            ["Asia/Omsk", App.trans("GMT+07:00 Asia/Omsk")], // es: Asia/Omsk, de: Asien/Omsk, fr: Asie/Omsk
            ["Asia/Novosibirsk", App.trans("GMT+07:00 Asia/Novosibirsk")], // es: Asia/Novosibirsk, de: Asien/Novosibirsk, fr: Asie/Novosibirsk
            ["Asia/Novokuznetsk", App.trans("GMT+07:00 Asia/Novokuznetsk")], // es: Asia/Novokuznetsk, de: Asia/Novokuznetsk, fr: Asia/Novokuznetsk
            ["Asia/Krasnoyarsk", App.trans("GMT+08:00 Asia/Krasnoyarsk")], // es: Asia/Krasnoyarsk, de: Asien/Krasnojarsk, fr: Asie/Krasnoyarsk
            ["Asia/Irkutsk", App.trans("GMT+09:00 Asia/Irkutsk")], // es: Asia/Irkutsk, de: Asien/Irkutsk, fr: Asie/Irkutsk
            ["Asia/Yakutsk", App.trans("GMT+10:00 Asia/Yakutsk")], // es: Asia/Yakutsk, de: Asien/Jakutsk, fr: Asie/Yakutsk
            ["Asia/Vladivostok", App.trans("GMT+11:00 Asia/Vladivostok")], // es: Asia/Vladivostok, de: Asien/Wladiwostok, fr: Asie/Vladivostok
            ["Asia/Sakhalin", App.trans("GMT+11:00 Asia/Sakhalin")], // es: Asia/Sakhalin, de: Asien/Sachalin, fr: Asie/Sakhalin
            ["Asia/Magadan", App.trans("GMT+12:00 Asia/Magadan")], // es: Asia/Magadán, de: Asien/Magadan, fr: Asie/Magadan
            ["Asia/Kamchatka", App.trans("GMT+12:00 Asia/Kamchatka")], // es: Asia/Kamchatka, de: Asien/Kamtschatka, fr: Asie/Kamchatka
            ["Asia/Anadyr", App.trans("GMT+12:00 Asia/Anadyr")] // es: Asia/Anadyr, de: Asien/Anadyr, fr: Asie/Anadyr
        ],
        RW: [
            ["Africa/Kigali", App.trans("GMT+02:00 Africa/Kigali")] // es: África/Kigali, de: Afrika/Kigali, fr: Afrique/Kigali
        ],
        SA: [
            ["Asia/Riyadh", App.trans("GMT+03:00 Asia/Riyadh")] // es: Asia/Riyadh, de: Asien/Riyadh, fr: Asie/Riyadh
        ],
        SB: [
            ["Pacific/Guadalcanal", App.trans("GMT+11:00 Pacific/Guadalcanal")] // es: Pacífico/Guadalcanal, de: Pazifik/Guadalcanal, fr: Pacifique/Guadalcanal
        ],
        SC: [
            ["Indian/Mahe", App.trans("GMT+04:00 Indian/Mahe")] // es: Índico/Mahe, de: Indisch/Mahe, fr: Indian/Mahe
        ],
        SD: [
            ["Africa/Khartoum", App.trans("GMT+03:00 Africa/Khartoum")] // es: África/Khartoum, de: Afrika/Khartoum, fr: Afrique/Khartoum
        ],
        SE: [
            ["Europe/Stockholm", App.trans("GMT+02:00 Europe/Stockholm")] // es: Europa/Estocolmo, de: Europa/Stockholm, fr: Europe/Stockholm
        ],
        SG: [
            ["Asia/Singapore", App.trans("GMT+08:00 Asia/Singapore")] // es: Asia/Singapur, de: Asien/Singapur, fr: Asie/Singapour
        ],
        SH: [
            ["Atlantic/St_Helena", App.trans("GMT+00:00 Atlantic/St_Helena")] // es: Atlántico/St_Helena, de: Atlantik/St._Helena, fr: Atlantique/Sainte_Hélène
        ],
        SI: [
            ["Europe/Ljubljana", App.trans("GMT+02:00 Europe/Ljubljana")] // es: Europa/Ljubljana, de: Europa/Ljubljana, fr: Europe/Ljubljana
        ],
        SJ: [
            ["Arctic/Longyearbyen", App.trans("GMT+02:00 Arctic/Longyearbyen")] // es: Ártico/Longyearbyen, de: Arktis/Longyearbyen, fr: Arctique/Longyearbyen
        ],
        SK: [
            ["Europe/Bratislava", App.trans("GMT+02:00 Europe/Bratislava")] // es: Europa/Bratislava, de: Europa/Bratislava, fr: Europe/Bratislava
        ],
        SL: [
            ["Africa/Freetown", App.trans("GMT+00:00 Africa/Freetown")] // es: África/Freetown, de: Afrika/Freetown, fr: Afrique/Freetown
        ],
        SM: [
            ["Europe/San_Marino", App.trans("GMT+02:00 Europe/San_Marino")] // es: Europa/San_Marino, de: Europa/San_Marino, fr: Europe/Saint_Marin
        ],
        SN: [
            ["Africa/Dakar", App.trans("GMT+00:00 Africa/Dakar")] // es: África/Dakar, de: Afrika/Dakar, fr: Afrique/Dakar
        ],
        SO: [
            ["Africa/Mogadishu", App.trans("GMT+03:00 Africa/Mogadishu")] // es: África/Mogadiscio, de: Afrika/Mogadishu, fr: Afrique/Mogadishu
        ],
        SR: [
            ["America/Paramaribo", App.trans("GMT-03:00 America/Paramaribo")] // es: América/Paramaribo, de: Amerika/Paramaribo, fr: Amérique/Paramaribo
        ],
        ST: [
            ["Africa/Sao_Tome", App.trans("GMT+00:00 Africa/Sao_Tome")] // es: África/Sao_Tome, de: Afrika/Sao_Tome, fr: Afrique/Sao_Tome
        ],
        SV: [
            ["America/El_Salvador", App.trans("GMT-06:00 America/El_Salvador")] // es: América/El_Salvador, de: Amerika/El_Salvador, fr: Amérique/El_Salvador
        ],
        SY: [
            ["Asia/Damascus", App.trans("GMT+03:00 Asia/Damascus")] // es: Asia/Damasco, de: Asien/Damaskus, fr: Asie/Damascus
        ],
        SZ: [
            ["Africa/Mbabane", App.trans("GMT+02:00 Africa/Mbabane")] // es: África/Mbabane, de: Afrika/Mbabane, fr: Afrique/Mbabane
        ],
        TC: [
            ["America/Grand_Turk", App.trans("GMT-04:00 America/Grand_Turk")] // es: América/Grand_Turk, de: Amerika/Grand_Turk, fr: Amérique/Grand_Turk
        ],
        TD: [
            ["Africa/Ndjamena", App.trans("GMT+01:00 Africa/Ndjamena")] // es: África/Ndjamena, de: Afrika/Ndjamena, fr: Afrique/Ndjamena
        ],
        TF: [
            ["Indian/Kerguelen", App.trans("GMT+05:00 Indian/Kerguelen")] // es: Índico/Kerguelen, de: Indisch/Kerguelen, fr: Indian/Kerguelen
        ],
        TG: [
            ["Africa/Lome", App.trans("GMT+00:00 Africa/Lome")] // es: África/Lome, de: Afrika/Lome, fr: Afrique/Lome
        ],
        TH: [
            ["Asia/Bangkok", App.trans("GMT+07:00 Asia/Bangkok")] // es: Asia/Bangkok, de: Asien/Bangkok, fr: Asie/Bangkok
        ],
        TJ: [
            ["Asia/Dushanbe", App.trans("GMT+05:00 Asia/Dushanbe")] // es: Asia/Dushanbe, de: Asien/Dushanbe, fr: Asie/Dushanbe
        ],
        TK: [
            ["Pacific/Fakaofo", App.trans("GMT-10:00 Pacific/Fakaofo")] // es: Pacífico/Fakaofo, de: Pazifik/Fakaofo, fr: Pacifique/Fakaofo
        ],
        TL: [
            ["Asia/Dili", App.trans("GMT+09:00 Asia/Dili")] // es: Asia/Dili, de: Asien/Dili, fr: Asie/Dili
        ],
        TM: [
            ["Asia/Ashgabat", App.trans("GMT+05:00 Asia/Ashgabat")] // es: Asia/Ashgabat, de: Asien/Ashgabat, fr: Asie/Ashgabat
        ],
        TN: [
            ["Africa/Tunis", App.trans("GMT+01:00 Africa/Tunis")] // es: África/Túnez, de: Afrika/Tunis, fr: Afrique/Tunis
        ],
        TO: [
            ["Pacific/Tongatapu", App.trans("GMT+13:00 Pacific/Tongatapu")] // es: Pacífico/Tongatapu, de: Pazifik/Tongatapu, fr: Pacifique/Tongatapu
        ],
        TR: [
            ["Europe/Istanbul", App.trans("GMT+03:00 Europe/Istanbul")] // es: Europa/Estanbul, de: Europa/Istanbul, fr: Europe/Istanbul
        ],
        TT: [
            ["America/Port_of_Spain", App.trans("GMT-04:00 America/Port_of_Spain")] // es: América/Puerto_España, de: Amerika/Port_of_Spain, fr: Amérique/Port of Spain
        ],
        TV: [
            ["Pacific/Funafuti", App.trans("GMT+12:00 Pacific/Funafuti")] // es: Pacífico/Funafuti, de: Pazifik/Funafuti, fr: Pacifique/Funafuti
        ],
        TW: [
            ["Asia/Taipei", App.trans("GMT+08:00 Asia/Taipei")] // es: Asia/Taipei, de: Asien/Taipei, fr: Asie/Taipei
        ],
        TZ: [
            ["Africa/Dar_es_Salaam", App.trans("GMT+03:00 Africa/Dar_es_Salaam")] // es: África/Dar_es_Salaam, de: Afrika/Dar_es_Salaam, fr: Afrique/Dar_es_Salaam
        ],
        UA: [
            ["Europe/Kiev", App.trans("GMT+03:00 Europe/Kiev")], // es: Europa/Kiev, de: Europa/Kiev, fr: Europe/Kiev
            ["Europe/Uzhgorod", App.trans("GMT+03:00 Europe/Uzhgorod")], // es: Europa/Uzhgorod, de: Europa/Ushgorod, fr: Europe/Uzhgorod
            ["Europe/Zaporozhye", App.trans("GMT+03:00 Europe/Zaporozhye")], // es: Europa/Zaporozhye, de: Europa/Saporoshje, fr: Europe/Zaporozhye
            ["Europe/Simferopol", App.trans("GMT+03:00 Europe/Simferopol")] // es: Europa/Simferopol, de: Europa/Simferopol, fr: Europe/Simferopol
        ],
        UG: [
            ["Africa/Kampala", App.trans("GMT+03:00 Africa/Kampala")] // es: África/Kampala, de: Afrika/Kampala, fr: Afrique/Kampala
        ],
        UM: [
            ["Pacific/Johnston", App.trans("GMT-10:00 Pacific/Johnston")], // es: Pacífico/Johnston, de: Pazifik/Johnston, fr: Pacifique/Johnston
            ["Pacific/Midway", App.trans("GMT-11:00 Pacific/Midway")], // es: Pacífico/Midway, de: Pazifik/Midway, fr: Pacifique/Midway
            ["Pacific/Wake", App.trans("GMT+12:00 Pacific/Wake")] // es: Pacífico/Wake, de: Pazifik/Wake, fr: Pacifique/Wake
        ],
        US: [
            ["America/New_York", App.trans("GMT-04:00 America/New_York")], // es: América/New_York, de: Amerika/New_York, fr: Amérique/New York
            ["America/Detroit", App.trans("GMT-04:00 America/Detroit")], // es: América/Detroit, de: Amerika/Detroit, fr: Amérique/Detroit
            ["America/Kentucky/Louisville", App.trans("GMT-04:00 America/Kentucky/Louisville")], // es: América/Kentucky/Louisville, de: Amerika/Kentucky/Louisville, fr: Amérique/Kentucky/Louisville
            ["America/Kentucky/Monticello", App.trans("GMT-04:00 America/Kentucky/Monticello")], // es: América/Kentucky/Monticello, de: Amerika/Kentucky/Monticello, fr: Amérique/Kentucky/Monticello
            ["America/Indiana/Indianapolis", App.trans("GMT-04:00 America/Indiana/Indianapolis")], // es: América/Indiana/Indianápolis, de: Amerika/Indiana/Indianapolis, fr: Amérique/Indiana/Indianapolis
            ["America/Indiana/Vincennes", App.trans("GMT-04:00 America/Indiana/Vincennes")], // es: America/Indiana/Vincennes, de: America/Indiana/Vincennes, fr: America/Indiana/Vincennes
            ["America/Indiana/Winamac", App.trans("GMT-04:00 America/Indiana/Winamac")], // es: America/Indiana/Winamac, de: America/Indiana/Winamac, fr: America/Indiana/Winamac
            ["America/Indiana/Marengo", App.trans("GMT-04:00 America/Indiana/Marengo")], // es: América/Indiana/Marengo, de: Amerika/Indiana/Marengo, fr: Amérique/Indiana/Marengo
            ["America/Indiana/Petersburg", App.trans("GMT-04:00 America/Indiana/Petersburg")], // es: America/Indiana/Petersburg, de: America/Indiana/Petersburg, fr: America/Indiana/Petersburg
            ["America/Indiana/Vevay", App.trans("GMT-04:00 America/Indiana/Vevay")], // es: América/Indiana/Vevay, de: Amerika/Indiana/Vevay, fr: Amérique/Indiana/Vevay
            ["America/Chicago", App.trans("GMT-05:00 America/Chicago")], // es: América/Chicago, de: Amerika/Chicago, fr: Amérique/Chicago
            ["America/Indiana/Tell_City", App.trans("GMT-05:00 America/Indiana/Tell_City")], // es: America/Indiana/Tell_City, de: America/Indiana/Tell_City, fr: America/Indiana/Tell_City
            ["America/Indiana/Knox", App.trans("GMT-05:00 America/Indiana/Knox")], // es: América/Indiana/Knox, de: Amerika/Indiana/Knox, fr: Amérique/Indiana/Knox
            ["America/Menominee", App.trans("GMT-05:00 America/Menominee")], // es: América/Menominee, de: Amerika/Menominee, fr: Amérique/Menominee
            ["America/North_Dakota/Center", App.trans("GMT-05:00 America/North_Dakota/Center")], // es: América/Dakota_del_norte/Center, de: Amerika/North_Dakota/Center, fr: Amérique/North_Dakota/Center
            ["America/North_Dakota/New_Salem", App.trans("GMT-05:00 America/North_Dakota/New_Salem")], // es: America/North_Dakota/New_Salem, de: America/North_Dakota/New_Salem, fr: America/North_Dakota/New_Salem
            ["America/Denver", App.trans("GMT-06:00 America/Denver")], // es: América/Denver, de: Amerika/Denver, fr: Amérique/Denver
            ["America/Boise", App.trans("GMT-06:00 America/Boise")], // es: América/Boise, de: Amerika/Boise, fr: Amérique/Boise
            ["America/Shiprock", App.trans("GMT-06:00 America/Shiprock")], // es: América/Shiprock, de: Amerika/Shiprock, fr: Amérique/Shiprock
            ["America/Phoenix", App.trans("GMT-07:00 America/Phoenix")], // es: América/Phoenix, de: Amerika/Phoenix, fr: Amérique/Phoenix
            ["America/Los_Angeles", App.trans("GMT-07:00 America/Los_Angeles")], // es: América/Los_Ángeles, de: Amerika/Los_Angeles, fr: Amérique/Los Angeles
            ["America/Anchorage", App.trans("GMT-08:00 America/Anchorage")], // es: América/Anchorage, de: Amerika/Anchorage, fr: Amérique/Anchorage
            ["America/Juneau", App.trans("GMT-08:00 America/Juneau")], // es: América/Juneau, de: Amerika/Juneau, fr: Amérique/Juneau
            ["America/Yakutat", App.trans("GMT-08:00 America/Yakutat")], // es: América/Yakutat, de: Amerika/Yakutat, fr: Amérique/Yakutat
            ["America/Nome", App.trans("GMT-08:00 America/Nome")], // es: América/Nome, de: Amerika/Nome, fr: Amérique/Nome
            ["America/Adak", App.trans("GMT-09:00 America/Adak")], // es: América/Adak, de: Amerika/Adak, fr: Amérique/Adak
            ["Pacific/Honolulu", App.trans("GMT-10:00 Pacific/Honolulu")] // es: Pacífico/Honolulú, de: Pazifik/Honolulu, fr: Pacifique/Honolulu
        ],
        UY: [
            ["America/Montevideo", App.trans("GMT-03:00 America/Montevideo")] // es: América/Montevideo, de: Amerika/Montevideo, fr: Amérique/Montevideo
        ],
        UZ: [
            ["Asia/Samarkand", App.trans("GMT+05:00 Asia/Samarkand")], // es: Asia/Samarcanda, de: Asien/Samarkand, fr: Asie/Samarkand
            ["Asia/Tashkent", App.trans("GMT+05:00 Asia/Tashkent")] // es: Asia/Tashkent, de: Asien/Tashkent, fr: Asie/Tashkent
        ],
        VA: [
            ["Europe/Vatican", App.trans("GMT+02:00 Europe/Vatican")] // es: Europa/Vaticano, de: Europa/Vatikan, fr: Europe/Vatican
        ],
        VC: [
            ["America/St_Vincent", App.trans("GMT-04:00 America/St_Vincent")] // es: América/St_Vincent, de: Amerika/St._Vincent, fr: Amérique/St_Vincent
        ],
        VE: [
            ["America/Caracas", App.trans("GMT-05:30 America/Caracas")] // es: América/Caracas, de: Amerika/Karakas, fr: Amérique/Caracas
        ],
        VG: [
            ["America/Tortola", App.trans("GMT-04:00 America/Tortola")] // es: América/Tórtola, de: Amerika/Tortola, fr: Amérique/Tortola
        ],
        VI: [
            ["America/St_Thomas", App.trans("GMT-04:00 America/St_Thomas")] // es: América/St_Thomas, de: Amerika/St._Thomas, fr: Amérique/St_Thomas
        ],
        VN: [
            ["Asia/Ho_Chi_Minh", App.trans("GMT+07:00 Asia/Ho_Chi_Minh")] // es: Asia/Ho_Chi_Minh, de: Asia/Ho_Chi_Minh, fr: Asia/Ho_Chi_Minh
        ],
        VU: [
            ["Pacific/Efate", App.trans("GMT+11:00 Pacific/Efate")] // es: Pacífico/Efate, de: Pazifik/Efate, fr: Pacifique/Efate
        ],
        WF: [
            ["Pacific/Wallis", App.trans("GMT+12:00 Pacific/Wallis")] // es: Pacífico/Wallis, de: Pazifik/Wallis, fr: Pacifique/Wallis
        ],
        WS: [
            ["Pacific/Apia", App.trans("GMT-11:00 Pacific/Apia")] // es: Pacífico/Apia, de: Pazifik/Apia, fr: Pacifique/Apia
        ],
        YE: [
            ["Asia/Aden", App.trans("GMT+03:00 Asia/Aden")] // es: Asia/Aden, de: Asien/Aden, fr: Asie/Aden
        ],
        YT: [
            ["Indian/Mayotte", App.trans("GMT+03:00 Indian/Mayotte")] // es: Índico/Mayotte, de: Indisch/Mayotte, fr: Indian/Mayotte
        ],
        ZA: [
            ["Africa/Johannesburg", App.trans("GMT+02:00 Africa/Johannesburg")] // es: África/Johannesburgo, de: Afrika/Johannesburg, fr: Afrique/Johannesburg
        ],
        ZM: [
            ["Africa/Lusaka", App.trans("GMT+02:00 Africa/Lusaka")] // es: África/Lusaka, de: Afrika/Lusaka, fr: Afrique/Lusaka
        ],
        ZW: [
            ["Africa/Harare", App.trans("GMT+02:00 Africa/Harare")] // es: África/Harare, de: Afrika/Harare, fr: Afrique/Harare
        ]
    };

    zones = [];
    obj_zones = [];

    _.each(g_zones, function (zone) { zones = _.union(zones, zone); });
    _.each(zones, function (zone) { obj_zones.push({ 'code' : zone[0], 'name' : zone[1]}); });

    return obj_zones;
});

