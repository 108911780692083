
/* START_TEMPLATE */
define('hbs!templates/views/Reports/OverviewReport',['hbs','handlebars','templates/helpers/iif','templates/helpers/formatDate','templates/helpers/parse','templates/helpers/decimal'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, self=this, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  
  return "\n    Airtime report\n    ";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.type), "DbReportAudience", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.type), "DbReportAudience", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program4(depth0,data) {
  
  
  return "\n    Audience report\n    ";
  }

function program6(depth0,data) {
  
  
  return "\n    Footfall report\n    ";
  }

function program8(depth0,data) {
  
  
  return "\n    <i class=\"fa fa-spinner fa-pulse\"></i> working...\n    ";
  }

function program10(depth0,data) {
  
  
  return "\n    <span style=\"color: green\"><i class=\"fa fa-check-circle\" style=\"color: green\" aria-hidden=\"true\"></i> ready</span>\n    ";
  }

function program12(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            ";
  if (helper = helpers.company_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.company_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        ";
  return buffer;
  }

function program14(depth0,data) {
  
  
  return "\n            -\n        ";
  }

function program16(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            ";
  if (helper = helpers.num_of_plays) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.num_of_plays); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        ";
  return buffer;
  }

function program18(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n            &pound; <label>"
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.cost_per_view), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.cost_per_view), options)))
    + "</label>\n        ";
  return buffer;
  }

function program20(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n        &pound; <label>"
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.daily_cost), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.daily_cost), options)))
    + "</label>\n        ";
  return buffer;
  }

function program22(depth0,data) {
  
  
  return "\n          -\n        ";
  }

function program24(depth0,data) {
  
  
  return "Whole range";
  }

function program26(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(29, program29, data),fn:self.program(27, program27, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "day", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "day", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program27(depth0,data) {
  
  
  return "By day";
  }

function program29(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.noop,fn:self.program(30, program30, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "hour", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "hour", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program30(depth0,data) {
  
  
  return "By hour";
  }

function program32(depth0,data) {
  
  
  return "Whole group";
  }

function program34(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(37, program37, data),fn:self.program(35, program35, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "country", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "country", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program35(depth0,data) {
  
  
  return "Country";
  }

function program37(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(40, program40, data),fn:self.program(38, program38, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "region", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "region", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program38(depth0,data) {
  
  
  return "Region";
  }

function program40(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(43, program43, data),fn:self.program(41, program41, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "city", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "city", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program41(depth0,data) {
  
  
  return "City";
  }

function program43(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(46, program46, data),fn:self.program(44, program44, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "store", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "store", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program44(depth0,data) {
  
  
  return "Store";
  }

function program46(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.noop,fn:self.program(47, program47, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "display-group", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "display-group", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program47(depth0,data) {
  
  
  return "per Display Group";
  }

function program49(depth0,data) {
  
  
  return "\n    <button data-permission=\"repo_down_btn\" class=\"btn btn-sm btn-default print-invoice-btn\">Download</button>\n    <button data-permission=\"repo_dele_btn\" class=\"btn btn-sm btn-danger delete-btn\"><i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></button>\n    ";
  }

  buffer += "<div class=\"checkbox checkbox-info checkbox-inline\" style=\"width: 50px;\">\n    <input type=\"checkbox\" name=\"check_report\" data-field=\"check_report\" value=\"1\"><label></label>\n</div>\n<div class=\"overview-campaign-name\">\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.type), "DbReportAirtime", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.type), "DbReportAirtime", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " generated on "
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), "YYYY-MM-DD HH:mm:ss", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.created), "YYYY-MM-DD HH:mm:ss", options)))
    + "\n</div>\n<div class=\"overview-status\">\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(10, program10, data),fn:self.program(8, program8, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.ready_state), "working", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.ready_state), "working", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n<div class=\"overview-company-name\">\n    <span>\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.company_name), {hash:{},inverse:self.program(14, program14, data),fn:self.program(12, program12, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </span>\n</div>\n<div class=\"overview-format\">\n    <span>\n        "
    + escapeExpression((helper = helpers.parse || (depth0 && depth0.parse),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.parameters), "format", options) : helperMissing.call(depth0, "parse", (depth0 && depth0.parameters), "format", options)))
    + "\n    </span>\n</div>\n<div class=\"overview-start-date\">\n    <span>\n        <label class=\"timestamp_from-date\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.timestamp_from), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.timestamp_from), "YYYY-MM-DD", options)))
    + "</label>\n    </span>\n</div>\n<div class=\"overview-end-date\">\n    <span>\n        <label class=\"timestamp_to-date\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.timestamp_to), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.timestamp_to), "YYYY-MM-DD", options)))
    + "</label>\n    </span>\n</div>\n<div class=\"overview-impressions\">\n    <span class=\"pull-right\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.num_of_plays), {hash:{},inverse:self.program(14, program14, data),fn:self.program(16, program16, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </span>\n</div>\n<div class=\"overview-impact\">\n    <span class=\"pull-right\">\n        -\n    </span>\n</div>\n<div class=\"overview-CPV\">\n    <span class=\"pull-right\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.cost_per_view), {hash:{},inverse:self.program(14, program14, data),fn:self.program(18, program18, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </span>\n</div>\n<div class=\"overview-daily-spend\">\n    <span class=\"pull-right\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.daily_cost), {hash:{},inverse:self.program(22, program22, data),fn:self.program(20, program20, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </span>\n</div>\n<!--<div class=\"report-details\">-->\n    <!--<span>-->\n        <!--Date range:-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(26, program26, data),fn:self.program(24, program24, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "all", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "all", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n\n    <!--&nbsp;&nbsp;Grouping:-->\n    <!--";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(34, program34, data),fn:self.program(32, program32, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "all", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "all", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--</span>-->\n<!--</div>-->\n\n<div class=\"toolbox\">\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.download_link), {hash:{},inverse:self.noop,fn:self.program(49, program49, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
