// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Payments/BillingInfoModal',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/BillingInfoModal', 'ladda-bootstrap', 'bootstrap-dialog', 'flagstrap'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            required: true,

            events: {
                'click .cancel-btn': '_cancelBillingInfo',
                'click .save-btn': '_saveBillingInfo'
            },

            initialize: function (options) {
                if (options && options.callback && _.isFunction(options.callback)) {
                    this.callback = options.callback;
                }
                this.model = App.currentCompany.getBillingInfo();
                Backbone.Validation.bind(this);
            },

            onRender: function () {
                var me = this;

                $.when(me.model.promise)
                    .then(function () {
                        me.$el.find('.modal-loading').hide();

                        // fields prefiller
                        _.isEmpty(me.model.get('company_name')) && me.$el.find('[name=company_name]').val(App.currentCompany.get('name'));
                        _.isEmpty(me.model.get('first_name')) && me.$el.find('[name=first_name]').val(App.currentUser.get('firstname'));
                        _.isEmpty(me.model.get('last_name')) && me.$el.find('[name=last_name]').val(App.currentUser.get('lastname'));
                    });

                _.delay(function () {
                    me.$el.find('#countryCode').flagStrap({ selectedCountry: me.model.get('country_code') || 'GB' });
                    me.addBinding(null, '[name=country_code]', 'country_code');
                }, 200);
            },

            _saveBillingInfo: function () {
                var me = this;

                // update model
                me.model.set({
                    'company_name': me.$el.find('[name=company_name]').val(),
                    'first_name': me.$el.find('[name=first_name]').val(),
                    'last_name': me.$el.find('[name=last_name]').val(),
                    'billing_taxid': me.$el.find('[name=billing_taxid]').val(),
                    'billing_address1': me.$el.find('[name=billing_address1]').val(),
                    'billing_address2': me.$el.find('[name=billing_address2]').val(),
                    'billing_postcode': me.$el.find('[name=billing_postcode]').val(),
                    'billing_town': me.$el.find('[name=billing_town]').val(),
                    'country_code': me.$el.find('[name=country_code]').val()
                });

                if (me.model.isValid(true)) {
                    me.$el.find('.modal-loading').show();
                }
                else {
                    deleteTemporaryModelSets(this);
                    return false;
                }

                $.when(me.model.save())
                    .then(function () {
                        me.destroy();
                    });

            },

            _cancelBillingInfo: function () {

                deleteTemporaryModelSets(this);

            }


        });
    });

function deleteTemporaryModelSets(me) {

    me.model.unset('company_name');
    me.model.unset('first_name');
    me.model.unset('last_name');
    me.model.unset('billing_taxid');
    me.model.unset('billing_address1');
    me.model.unset('billing_address2');
    me.model.unset('billing_postcode');
    me.model.unset('billing_town');
    me.model.unset('country_code');
};
