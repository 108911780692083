// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,document*/
define('views/Reports/Report',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Reports/Report', 'bootstrap-dialog', 'jquery.cookie'],
    function (App, Backbone, _, $, template, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            tagName: 'li',

            className: 'report',

            modelEvents: {
                'change': 'render'
            },

            events: {
                'click .print-invoice-btn': '_downloadInvoice',
                'click .delete-btn': '_delete'
            },

            _downloadInvoice: function (event) {
                event && event.preventDefault();
                var me = this;
                var downloadUrl = App.enrichApiUrl(me.model.get('uris').Download + '?os_user=' + $.cookie('X-FlowCity-Session-User'));

                // check if this browser is chrome
                if(!!window.chrome && !!window.chrome.webstore){
                    var link = document.createElement("a");
                    link.download = '_blank';
                    link.href = downloadUrl;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }else{
                    window.open(downloadUrl);
                }
            },

            _delete: function () {
                var me = this;

                 BootstrapDialog.confirm('Do you really want to delete "'+ me.el.children[1].innerText +'"?', function(result){
                    if(result) {
                        me.model.destroy();
                    }
                });

            }

        });
    });

