// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/Displays',["backbone", "models/Display"],
    function (Backbone, Display) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var Displays = Backbone.SearchableCollection.extend({
            model: Display,
            searchUri: '/data/search/displays/normal'
        });

        return Displays;
    });
