
/* START_TEMPLATE */
define('hbs!templates/views/LoopManagement/Loop',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "block";
  }

function program3(depth0,data) {
  
  
  return "none";
  }

  buffer += "<div class=\"loop-management-top-section\">\n    <div class=\"display-group-icon\">\n        <img src=\"\" class=\"svg-inject blue-icon\">\n    </div>\n    ";
  if (helper = helpers.display_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.display_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n    <button class=\"btn btn-default btn-lg btn-top-bar pull-right btn-back\"><i class=\"fa fa-chevron-left\" aria-hidden=\"true\"></i> Back</button>\n</div>\n\n<div class=\"loop-scroll-area\">\n    <ul class=\"offers\" style=\"display: ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.display_name), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"></ul>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
