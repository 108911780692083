// -*- coding: utf-8 -*-


// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/timeFrom',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function timeFrom(dt, nullString) {
        if (dt === undefined || dt === null) {
            return App.trans(nullString) || "";
        } else {
            return moment(dt).fromNow();
        }
    }

    Handlebars.registerHelper('timeFrom', timeFrom);
    return timeFrom;
});
