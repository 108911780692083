
/* START_TEMPLATE */
define('hbs!templates/views/PasswordReset/ForgotPasswordChange',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"login-page\">\n    <div class=\"container\">\n        <div class=\"top-logo\">\n            <img src=\"/static/images/flow-ads-logo-cover.png\" alt=\"Flow.Ads\">\n        </div>\n\n        <form class=\"form-login\" id=\"forgot-password-change-form\">\n		    <h2 class=\"form-login-heading\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "password reset", options) : helperMissing.call(depth0, "trans", "password reset", options)))
    + "</h2>\n		        <div class=\"login-wrap\">\n		            <p>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Enter your new password twice.", options) : helperMissing.call(depth0, "trans", "Enter your new password twice.", options)))
    + "</p>\n\n		            <input type=\"password\" name=\"password\" id=\"password\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Your NEW password", options) : helperMissing.call(depth0, "trans", "Your NEW password", options)))
    + "\" autocomplete=\"off\">\n		            <br>\n                    <input type=\"password\" name=\"passwordRetyped\" id=\"passwordRetyped\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Your NEW password", options) : helperMissing.call(depth0, "trans", "Your NEW password", options)))
    + "\" autocomplete=\"off\">\n                    <br>\n                    <div id=\"std-password-reset-btn\" class=\"btn btn-theme btn-block\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "RESET PASSWORD", options) : helperMissing.call(depth0, "trans", "RESET PASSWORD", options)))
    + "</div>\n		        </div>\n\n        </form>\n 	</div>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
