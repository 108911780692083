
/* START_TEMPLATE */
define('hbs!templates/views/Profile/UserDepartmentSelector',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<label>Current department</label>\n<select id=\"department-selector-field\" class=\"selectpicker\" data-width=\"100%\"></select>";
  });
return t;
});
/* END_TEMPLATE */
;
