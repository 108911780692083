// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Payments/PaymentsLayout',['App', 'jquery', 'underscore',
        'hbs!templates/views/Payments/PaymentsLayout',
        'backbone',
        'bootstrap-dialog',
        'views/Payments/SubscriptionPlans',
        'views/Payments/CreditsPurchase',
        'views/Payments/PrivateScreenKitPurchase'
    ],
    function (App, $, _,
              template,
              Backbone,
              BootstrapDialog,
              SubscriptionPlans,
              CreditsPurchase,
              PrivateScreenKitPurchase
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'form-panel profile-form row payments no-background',

            regions: {
                subscriptionPlans: '.subscription-plans'
            },

            ui: {
                credits: '[data-id="credits"]',
                paymentStatus: '.payment-alert',
                paymentStatusAlert: '.alert'
            },

            modelEvents: {
                'change:credits': function (model, value, options) { this.ui.credits.html(value)},
                'change:private_screens': function (model, value, options) { this.render(); }
            },

            events: {
                'click .show-invoices-btn': '_showInvoices',
                'click @ui.paymentStatusAlert .close': function (event) { event && event.preventDefault(); this.ui.paymentStatusAlert.hide(); },
                'click .buy-private-screen-kit-btn': '_buyPrivateScreenKit',
                'click .cancel-subscription-btn': '_cancelSubscription'
            },

            recalculateProportions: function () {

                var pricingElements = $('.pricing_title'),
                    height = 0;
                for (var i = 0; i < pricingElements.length; ++i) {
                    $(pricingElements[i]).height('unset')
                }
                for (var i = 0; i < pricingElements.length; ++i) {
                    if ($(pricingElements[i]).height() > height)
                        height = $(pricingElements[i]).height();
                }
                for (var i = 0; i < pricingElements.length; ++i) {
                    $(pricingElements[i]).height(height)
                }
            },

            initialize: function (options) {
                var me = this;
                me.model = App.currentCompany;
                App.currentCompany.getBillingInfo();
                me.listenTo(App.vent, 'App.layout.paymentStatus.show', _.bind(me._showStatusBar, me));

                $(window).resize(me.recalculateProportions);
            },

            onRender: function () {
                var me = this;
                me.updateStatus();
                me.subscriptionPlans.show(new SubscriptionPlans());

                setTimeout(function () {
                    me.recalculateProportions();
                }, 300);
            },

            updateStatus: function () {
                var me = this,
                    successTxt = 'Thank you. We have successfully accepted your payment request.',
                    failureTxt = 'Sorry. An error occurred while processing your payment. Please try again later or contact Flow.City Administrator.';

                if (App.os.queryParams && App.os.queryParams.success) {

                    // payment committed
                    if (App.os.queryParams.success === "1") {
                        me._showStatusBar(successTxt);
                    } else {
                        me._showStatusBar(failureTxt, 'alert-danger');
                    }
                    delete(App.os.queryParams.success);
                }
            },

            _showStatusBar: function (caption, className) {
                var me = this;
                console.log(me.ui.paymentStatusAlert);
                me.ui.paymentStatusAlert.removeClass('alert-danger alert-success')
                me.ui.paymentStatusAlert.find('strong').html(caption);
                me.ui.paymentStatusAlert.addClass(className || 'alert-success');
                me.ui.paymentStatusAlert.show();
            },

            _buyPrivateScreenKit: function (event) {
                event && event.preventDefault();

                var modalView = new PrivateScreenKitPurchase();
                App.mainRegion.currentView.modalRegion.show(modalView);

            },

            _cancelSubscription: function (event) {
                event && event.preventDefault();

                var me = this;

                BootstrapDialog.confirm(App.trans("Do you really want to cancel your subscription?"), function (result) {
                    if (result) {

                        $.ajax({
                            url: App.enrichApiUrl(App.currentCompany.get('uris').Payments),
                            type: 'POST',
                            dataType: 'json',
                            headers: { 'X-Alt-Referer': App.appUrl + '/#profile/payments' },
                            data: JSON.stringify({ "plan_name" : "DEMO" }),

                            success: function (data, textStatus, jqXHR) {
                                Backbone.history.stop();
                                window.location.reload();
                            },

                            error: function () {
                                App.vent.trigger('App.layout.paymentStatus.show', 'An error occurred during plan cancellation. Please try again.', 'alert-danger');
                            }
                        });
                    }
                });

            },

            _showBillingInfo: function (callback) {
                $.when(App.currentCompany.getBillingInfo())
                    .then(function () {
                        var modalView = new BillingInfoModal({ callback: callback });
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    });
            },

            _showInvoices: function (event) {
                event && event.preventDefault();

            }

        });
    });
