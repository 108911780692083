// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryPollenDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryPollenDesc(obj) {
        var output = "",
            strMap = {
                'any' : "any",
                'low' : "low",
                'medium' : "medium",
                'high' : "high",
                'very_high' : "very high"
            };

        if (!_.isUndefined(obj)) {
            obj.active && (output = '<br>and will increase priority ');
            if (!_.isEmpty(obj.pollen)) {
                output += '<br>when pollen index is <b>' + strMap[obj.pollen] + '</b>';
            }
        }

        return output;
    }

    Handlebars.registerHelper('summaryPollenDesc', summaryPollenDesc);
    return summaryPollenDesc;
});

