// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment,console*/
define('models/DisplayGroup',["App", "backbone",
        "collections/OnlineOffers",
        "collections/Demographics"],
    function (App, Backbone,
              OnlineOffers,
              Demographics) {

        'use strict';
        var DisplayGroup = Backbone.Model.extend({
            uriStencil: 'displays',
            defaultIcon: '/static/images/icon-screen-white.png',

            initialize: function () {
                this.getMobileReach();
            },

            isApprover: function () {
                return $.inArray(this.get('uri'), App.currentUser.getApproverGroups()) > -1;
            },

            getIcon: function () {
                //var me = this,
                //    icon;
                //if (!_.isUndefined(me.get('icon_link'))) {
                //    $.ajax({
                //        url: this.get('icon_link'),
                //        method: 'get',
                //        success: function (data) {
                //            console.log('data:image/png;base64,' + data);
                //            return 'data:image/png;base64,' + data;
                //        },
                //        error: function () {
                //            console.log(me.defaultIcon);
                //            return me.defaultIcon;
                //        }
                //    })
                //} else {
                //    return me.defaultIcon;
                //}
                return _.isUndefined(this.get('icon_link')) ? this.defaultIcon : this.get('icon_link');
            },

            getOnlineOffers: function (options) {
                return this.getSubCollection(OnlineOffers, 'OnlineOffers', options);
            },

            getMobileReach: function () {
                var me = this;
                if (me.get('mobile')) {

                    if (!me.get('mobile_reach')) {

                        $.ajax({
                            async: false,
                            type: "GET",
                            url: "https://pinpointlive.azurewebsites.net/api/customreach",
                            success: function (jqXHR, textStatus) {
                                if (jqXHR.responseJSON.locations) {
                                    me.set('mobile_reach', jqXHR.responseJSON.locations[0]);
                                }
                            },
                            error: function () {
                                console.debug('Cannot get mobile reach data');
                                me.set('mobile_reach', '-');
                            }
                        });
                    }
                }

            },

            getDemographics: function (options) {
                return this.getSubCollection(Demographics, 'Demographics', options);
            },

            getDepartmentConnection: function (departmentId) {
                if (!_.isUndefined(this.get('department_connections'))) {
                    return new Backbone.Model(_.find(this.get('department_connections'), function (d) { return d.department == departmentId }));
                }
            }

        });

        return DisplayGroup;
    }
);
