// -*- coding: utf-8 -*-


// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/trans',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function trans(msgid, args) {
        // return App.gt.strargs(App.gt.gettext(msgid), args);
        return msgid;
    }
    
    Handlebars.registerHelper('trans', trans);
    return trans;
});



