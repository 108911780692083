// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/SectionProduct',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/SectionProduct', 'backbone', 'magicsuggest'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            ui: {
                displayGroupsList: '.report-product-groups-list',
                displayGroupsFilterOpt: '[name="displayGroupsFilter"]'
            },

            events: {
                'change @ui.displayGroupsFilterOpt' : '_onDisplayGroupsFilterOptChange',
                'click [data-id="filter-by-product-group"]' : 'clickSelected'
            },

            initialize: function (options) {
                this.parent = options && options.parent;
            },

            clickSelected: function () {
                $('#productFilter3').click();
            },

            onBeforeShow: function () {
                var me = this;

                me.displayGroupsList = me.ui.displayGroupsList.magicSuggest({
                    allowFreeEntries: false,
                    placeholder: 'Start typing product name',
                    data: function (query) {
                        return App.currentUser.getDisplayGroups().toSuggestionList('display_name').filter(function(d) { return (d.name).toLowerCase().indexOf(query.toLowerCase()) > -1; });
                    }
                });

                $(me.displayGroupsList).on('selectionchange', function(e,m){
                    me.parent.reportParams.display_groups = this.getValue();

                    me.parent._updateReportParameter_child(e);
                });
            },

            _onDisplayGroupsFilterOptChange: function (event) {
                var me = this,
                    target = event.currentTarget;
                if ($(target).val() === 'selected') {
                    me.$el.find('[data-id="filter-by-product-group"]').show();
                } else {
                    me.displayGroupsList.clear();
                    me.$el.find('[data-id="filter-by-product-group"]').hide();
                }
            }

        });
    });
