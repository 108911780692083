
/* START_TEMPLATE */
define('hbs!templates/views/Reports/Report',['hbs','handlebars','templates/helpers/iif','templates/helpers/formatDate'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  
  return "\n    Airtime report\n    ";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.type), "DbReportAudience", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.type), "DbReportAudience", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program4(depth0,data) {
  
  
  return "\n    Audience report\n    ";
  }

function program6(depth0,data) {
  
  
  return "\n    Footfall report\n    ";
  }

function program8(depth0,data) {
  
  
  return "Whole range";
  }

function program10(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(13, program13, data),fn:self.program(11, program11, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "day", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "day", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program11(depth0,data) {
  
  
  return "By day";
  }

function program13(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.noop,fn:self.program(14, program14, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "hour", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "hour", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program14(depth0,data) {
  
  
  return "By hour";
  }

function program16(depth0,data) {
  
  
  return "Whole group";
  }

function program18(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(21, program21, data),fn:self.program(19, program19, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "country", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "country", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program19(depth0,data) {
  
  
  return "Country";
  }

function program21(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(24, program24, data),fn:self.program(22, program22, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "region", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "region", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program22(depth0,data) {
  
  
  return "Region";
  }

function program24(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(27, program27, data),fn:self.program(25, program25, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "city", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "city", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program25(depth0,data) {
  
  
  return "City";
  }

function program27(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(30, program30, data),fn:self.program(28, program28, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "store", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "store", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program28(depth0,data) {
  
  
  return "Store";
  }

function program30(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.noop,fn:self.program(31, program31, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "display-group", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "display-group", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  return buffer;
  }
function program31(depth0,data) {
  
  
  return "per Display Group";
  }

function program33(depth0,data) {
  
  
  return "\n    <button data-permission=\"repo_down_btn\" class=\"btn btn-sm btn-default print-invoice-btn\">Download</button>\n    <button data-permission=\"repo_dele_btn\" class=\"btn btn-sm btn-danger delete-btn\"><i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></button>\n    ";
  }

  buffer += "<div class=\"checkbox checkbox-info checkbox-inline\" style=\"width: 50px;\">\n    <input type=\"checkbox\" name=\"check_report\" data-field=\"check_report\" value=\"1\"><label></label>\n</div>\n<div class=\"report-campaign-name\">\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.type), "DbReportAirtime", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.type), "DbReportAirtime", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " generated on "
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), "YYYY-MM-DD HH:mm:ss", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.created), "YYYY-MM-DD HH:mm:ss", options)))
    + "\n</div>\n<div class=\"report-company-name\">\n    <span>\n        Firma\n    </span>\n</div>\n<div class=\"report-date-range\">\n    <span>\n        <label class=\"timestamp_from-date\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.timestamp_from), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.timestamp_from), "YYYY-MM-DD", options)))
    + "</label> - <label class=\"timestamp_to-date\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.timestamp_to), "YYYY-MM-DD", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.timestamp_to), "YYYY-MM-DD", options)))
    + "</label>\n    </span>\n</div>\n<div class=\"report-details\">\n    <span>\n        Date range:\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(10, program10, data),fn:self.program(8, program8, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_time), "all", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_time), "all", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n    &nbsp;&nbsp;Grouping:\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(18, program18, data),fn:self.program(16, program16, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.granularity_geo), "all", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.granularity_geo), "all", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </span>\n</div>\n\n<div class=\"toolbox\">\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.download_link), {hash:{},inverse:self.noop,fn:self.program(33, program33, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
