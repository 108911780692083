// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/feature',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function feature(featureId, options) {

        /*
        "weatherWidget"
        "timeScheduler"
        "creditsAquisition"
        "displayGroups"
        "tflDisruptions"
        "customSuggestions"
        "genericCustomTemplates"
        "tflScheduler"
        "customTemplates"
        "multiBrand"
         */
        if (App.currentCompany.getSubscriptionPlans().getCurrent().hasFeature(featureId)) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    }
    Handlebars.registerHelper('feature', feature);
    return feature;
});
