// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,window,appurl,_gaq*/
define('views/SignIn',['App', 'backbone', 'jquery', 'hbs!templates/views/SignIn', 'jquery-validation'],
    function (App, Backbone, $, template) {

        //ItemView provides some default rendering logic
        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            ui: {
                'loginEmail': '#login-email',
                'loginPassword': '#login-password'
            }, 
            
            events: {
                'click #fb-login-btn' : 'facebookLogin',
                'click #show-password' : 'showHidePassword'
            },

            showHidePassword: function () {
                if( $('#login-password').attr('type') === 'password' ){
                    $('#login-password').attr('type', 'text');
                    $('#show-password').html('Hide Password');
                }
                else{
                    $('#login-password').attr('type', 'password');
                    $('#show-password').html('Show Password');
                }
            },

            initialize: function () {
                this.model = this.model || new Backbone.Model();
                localStorage.setItem('firstTimeUserLogin',0);
            },

            onRender: function () {
                console.log('onRender'); 
                
                localStorage.setItem('firstTimeUserLogin', 0);

                var me = this,
                    email_msg = App.trans("Please enter a valid email address"),
                    password_required_msg = App.trans("Please provide a password");
                    
                var defaultEmail = App.os.getQueryParam('email') || App.os.decodeB64Cookie('os-user-info').email || "";
                console.log('defaultEmail: ', defaultEmail);
                
                $('body').css('background', 'transparent');
                if (defaultEmail) {
                    setTimeout(function loginViewPrefillEmail () {
                        me.ui.loginEmail.val(defaultEmail);
                        me.ui.loginPassword.focus();
                    }, 100);
                } 
                
                this.$el.find("#login-form").validate({
                    errorElement: "small",
                    errorClass: 'error',
                    rules: {
                        "login-email": {
                            required: true,
                            email: true
                        },
                        "login-password": "required"
                    },
                    messages: {
                        "std-login-email": email_msg,
                        "login-password": password_required_msg
                    },
                    submitHandler: function () {
                        var promoMappingFn = function(param, paramVal) {
                            return paramVal ? [param, paramVal] : null;
                        };
                        
                        var paramsValues = _.object(App.os.getPromoParams(promoMappingFn));
                        var loginData = {
                            email: me.$el.find('#login-email').val(),
                            password: me.$el.find('#login-password').val(),
                            template: me.$el.find('[name="template"]').val(),
                            promo_params: paramsValues
                        };

                        // Sign in user using ajax request
                        $.ajax({
                            url: App.enrichApiUrl('/data/login'),
                            type: 'POST',
                            dataType: 'json',
                            data: JSON.stringify(loginData),
                            includeSessHeader: false
                        })
                            .done(function (data, status, request) {
                                $.cookie('X-FlowCity-Session-User', request.getResponseHeader('X-FlowCity-Session-User'), { expires: 7*500, path: '/' });
                                App.os.initializeTabSession('X-FlowCity-Session-User', true);
                                var reloadFunc = function(tracker) {
                                    var toUrl = me.$el.find("[name='to-url']").val();
                                    App.os.forceReload(toUrl);
                                };
                                Backbone.history.stop();
                                if (App.GA) {
                                    App.GA(function(tracker) {
                                        tracker.send('event', 'SignIn', 'Submit.password', '');
                                        reloadFunc();
                                    });
                                } else {
                                    reloadFunc();
                                }
                            })
                            .fail(function () {
                                me.$el.find('.regular-login-error').show();
                                me.$el.find('#login-email').focus().select();
                            });

                    }
                });

                // set template
                if (!_.isEmpty(App.os.getQueryParam('template'))) {
                    this.$el.find('[name="template"]').val(App.os.getQueryParam('template'));
                }

                // Set redirect after login
                var redirUrl = App.os.getQueryParam('to-url') || window.goToUrl;
                if (redirUrl) {
                    this.$el.find("[name='to-url']").val(redirUrl);
                }

                $.backstretch("/static/images/login_background.jpg", {speed: 500});
            },

            showSignupForm: function (e) {
                e.preventDefault();
                App.appRouter.navigate('signup', true);
            },
            
            showForgotPasswordForm: function (e) {
                e.preventDefault();
                App.appRouter.navigate('forgot-password', true);                
            },

            oauthSignIn: function (event) {
                var provider = $(event.currentTarget).attr('data-provider');
                App.os.oAuthLogin(this, provider);
            },

            facebookLogin: function(e) {
                var me = this;
                var getFacebookData = function(authResponse) {
                    return { facebook_id: authResponse.userID };
                };
                var createSession = function(facebookData) {
                    $.ajax({
                        url: App.enrichApiUrl('/data/fb-login'),
                        type: 'POST',
                        dataType: 'json',
                        data: JSON.stringify(facebookData),
                        includeSessHeader: false
                    })
                    .done(function(data, status, request) {
                        $.cookie('X-FlowCity-Session-User', request.getResponseHeader('X-FlowCity-Session-User'), { expires: 7*500, path: '/' });
                        App.os.initializeTabSession('X-FlowCity-Session-User', true);
                        Backbone.history.stop();
                        var toUrl = me.$el.find("[name='to-url']").val();
                        App.os.forceReload(toUrl);
                    })
                    .fail(function() {
                        me.$el.find('.fb-login-error').show();
                    });
                };
                // check login status before sending login request to facebook
                FB.getLoginStatus(function(response) {
                    if (response.status === 'connected') {
                        createSession(getFacebookData(response.authResponse));
                    } else {
                        FB.login(function(response) {
                            if (response.authResponse) {
                                createSession(getFacebookData(response.authResponse));
                            }
                        });
                    }
                });
            }
        });
    });
