// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/RetailersFilter',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/RetailersFilter', 'backbone'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'filter-box',

            ui: {
                filterValue: '.filter-value',
                clearFilter: '.clear-filter'
            },

            events: {
                'keyup @ui.filterValue' : '_updateFilterField',
                'click @ui.clearFilter' : '_clearFilter'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                var me = this;
            },

            _updateFilterField: function () {
                var me = this;
                if (!_.isEmpty(me.ui.filterValue.val())) {
                    me.ui.clearFilter.show();
                } else {
                    me.ui.clearFilter.hide();
                }
                App.vent.trigger('App.retailers.filter', me.ui.filterValue.val());

            },

            _clearFilter: function () {
                var me = this;
                me.ui.filterValue.val('');
                me.ui.clearFilter.hide();
                App.vent.trigger('App.retailers.filter', me.ui.filterValue.val());
            }

        });
    });
