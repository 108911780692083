// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/formatTime',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function formatTime(dt, format) {
        return (!_.isEmpty(dt) ? moment("2015-01-01T" + dt).format(format ? format : "HH:mm:ss" ) : '-');
    }

    Handlebars.registerHelper('formatTime', formatTime);
    return formatTime;
});
