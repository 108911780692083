// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryVolumetricsDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryVolumetricsDesc(obj) {
        var output = "";

        if (!_.isUndefined(obj)) {
            // obj.active && (output = '<br>and will increase priority ');
            if (!_.isEmpty(obj.impact)) {
                output += '<br>' + (!_.isEmpty(obj.impact) ? ' and ' : 'when the ratio ');
                output += 'shows <b>' + obj.impact_range[0] + '% - '+ obj.impact_range[1] +'</b> of <b>impact</b>';
            }
            if (!_.isEmpty(obj.impressions)) {
                output += '<br>' + (!_.isEmpty(obj.impressions) ? ' and ' : 'when the ratio ');
                output += 'shows <b>' + obj.impressions_range[0] + '%</b> of <b>impressions</b>';
            }
            if (!_.isEmpty(obj.frequency)) {
                output += '<br>' + (!_.isEmpty(obj.frequency) ? ' and ' : 'when the ratio ');
                output += 'shows <b>' + obj.frequency_range[0] + '%</b> of <b>frequency</b>';
            }
            if (!_.isEmpty(obj.GRP)) {
                output += '<br>' + (!_.isEmpty(obj.grp) ? ' and ' : 'when the ratio ');
                output += 'shows <b>' + obj.GRP_range[0] + '%</b> of <b>grp</b>';
            }

            obj.active && (output += ' of all audience detected');

        }

        return output;
    }

    Handlebars.registerHelper('summaryVolumetricsDesc', summaryVolumetricsDesc);
    return summaryVolumetricsDesc;
});

