// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,OfferModel*/
define('models/Department',["App",
        "backbone",
        "underscore",
        "collections/OffersSearchable",
        //"collections/Users",
        "collections/DisplayGroups",
        "collections/Locations"
    ],
    function (App,
              Backbone,
              _,
              OffersSearchable,
              //Users,
              DisplayGroups,
              Locations
    ) {

        'use strict';
        var Department = Backbone.Model.extend({
            uriStencil: 'departments',

            validation: {
                'name': {
                    required: true,
                    minLength: 1,
                    msg: "Please enter valid department name"
                }
            },

            initialize: function () {
                var me = this;

                require(['collections/Users'], function (Users) {
                    me.getUsers = function (options) {
                        return this.getSubCollection(Users, 'Users', options);
                    }
                });


            },

            useCompanyCredits: function () {
                return this.get('use_company_credits');
            },

            getOffers: function (options) {
                return this.getSubCollection(OffersSearchable, 'Offers', options);
            },

            //getUsers: function (options) {
            //    return this.getSubCollection(Users, 'Users', options);
            //},

            getDisplayGroups: function (options) {
                return this.getSubCollection(DisplayGroups, 'DisplayGroups', options);
            },

            getLocations: function (options) {
                return this.getSubCollection(Locations, 'Locations', options);
            }

        });

        return Department;
    }
    );
