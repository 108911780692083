
/* START_TEMPLATE */
define('hbs!templates/views/Displays/Displays',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"ng-iframe\" style=\"display: flex;position: absolute;top: 0;left: 0;right: 0;bottom: 0;\">\n    <iframe src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n</div>\n\n<!--<div class=\"admin-screen-list\">-->\n    <!--<div class=\"displays-list content-panel\" style=\"top: 0;\">-->\n        <!--<div class=\"scroll-area displays-scroll-area\">-->\n            <!--<ul class=\"displays\"></ul>-->\n            <!--<div class=\"col-lg-12\">-->\n                <!--<a class=\"servernext infinite-more-link load-next\" id=\"page-load-more\" href=\"#\" style=\"display: none\">-->\n                    <!--<span class=\"txt\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Show More", options) : helperMissing.call(depth0, "trans", "Show More", options)))
    + "</span>-->\n                    <!--<div class=\"next-page-loader\"></div>-->\n                <!--</a>-->\n            <!--</div>-->\n        <!--</div>-->\n    <!--</div>-->\n<!--</div>-->";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
