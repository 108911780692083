// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Notifications/PublicTransportDisruption',['App', 'jquery', 'underscore', 'hbs!templates/views/Notifications/PublicTransportDisruption'],
function (App, $, _, template) {

    'use strict';
    return Backbone.Marionette.ItemView.extend({
        template: template,

        className: 'col-md-12'

    });
});
