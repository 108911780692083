
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferScheduleDisruptions',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"offer-schedule\">\n\n    <h5 class=\"pull-left mb\">This campaign will play on Public Transport conditions:</h5>\n\n    <div class=\"row\">\n        <div class=\"col-sm-12 text-center\">\n            <div class=\"btn-group date-range-selector\" data-toggle=\"buttons\">\n\n                <label class=\"btn btn-default btn-disruption\">\n                    <input type=\"radio\" name=\"disruptions\" autocomplete=\"off\" value=\"\">\n                    Any\n                </label>\n\n                <label class=\"btn btn-default btn-disruption icon-transport-no\">\n                    <input type=\"radio\" name=\"disruptions\" autocomplete=\"off\" value=\"none\">\n                    No disruptions\n                </label>\n\n                <label class=\"btn btn-default btn-disruption icon-transport-medium\">\n                    <input type=\"radio\" name=\"disruptions\" autocomplete=\"off\" value=\"all\">\n                    Disruptions\n                </label>\n\n                <label class=\"btn btn-default btn-disruption icon-transport-major\">\n                    <input type=\"radio\" name=\"disruptions\" autocomplete=\"off\" value=\"important\">\n                    Major only disruptions\n                </label>\n\n            </div>\n        </div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
