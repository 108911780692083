// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('behaviors/Onboarding',['App',
        'backbone',
        'backbone.marionette',
        'jquery',
        'hopscotch'
    ],
    function (
        App,
        Backbone,
        marionette,
        $,
        hopscotch
    ) {
        'use strict';
        return marionette.Behavior.extend({

            initialize: function(options){
                var me = this;
                me.regions = App.getRegions().mainRegion;
                me.variant = options.variant;
                    var onboardingStarted;
                    onboardingStarted = App.os.getLocalStorageKey('ONBOARDING_STARTED_'+me.variant.toUpperCase());
                    if(!App.currentCompany.isSetupFeePaid() && !onboardingStarted) {
                        me.listenTo(App.vent, 'App.dashboard.create.loaded', _.bind(me.checkAndRun, me));
                    }
            },

            onRender: function() {
                var me = this;

                    //var t= setTimeout(function(){me.checkAndRun(0) },5000 );

            },

            checkAndRun: function(i){
                var me = this;
                if(i==0 || i== undefined){
                    console.log($('.logo'));
                    console.log(me.regions.$el.find('.create-campaign-row .col-sm-8')[0]);
                    if(me.variant=="Dashboard") {
                        me.tour = {
                            id: "dashboard",
                            steps: [
                                {
                                    title: "Welcome to Flow.City",
                                    content: 'Hey there!' +
                                                'Let’s take a step-by-step tour through your Flow.City app and see how it works.<br>' +
                                                'Go ahead, advertise yourself with an image or a video.<br>' +
                                                'Adding a campaign is now as easy as 1-2-3!<br>',
                                    target: me.regions.$el.find('#map')[0],
                                    placement: "top",
                                    xOffset: 'center',
                                    zindex: 1001
                                },
                                {
                                    title: "Where to Begin",
                                    content: "Click on “Add campaign” and take your campaign live in just 3 clicks.",
                                    target: me.regions.$el.find('#ADD-CAMPAIGNS')[0],
                                    placement: "top",
                                    xOffset: 'center',
                                    onNext: function () {
                                        var stepNum = hopscotch.getCurrStepNum();
                                        var i = 0;
                                        $('#ADD-CAMPAIGNS').click();
                                        hopscotch.endTour();
                                        var checkExist = setInterval(function () {
                                            var $element = me.regions.$el.find('.create-campaign-formats'); //element of the next step
                                            console.debug("$element", $element);
                                            if ($element.is(':visible')) {
                                                clearInterval(checkExist);
                                                me.tour.steps[stepNum].target = $element[0];
                                                var t= setTimeout( function() { hopscotch.startTour(me.tour, stepNum); },500);
                                            }
                                            if (i > 50) {
                                                clearInterval(checkExist);
                                                console.error("Tour could not proceed");
                                            }
                                            i++;
                                        }, 100);
                                    },
                                    multipage: true,
                                    orphan: true,
                                    fixedElement: true,
                                    zindex: 1001
                                },
                                {
                                    title: "Define Your Campaign Details",
                                    content: "Select the desired screen from the list and upload artwork from your file (you can check it in the preview window) or let us design (contact us to set all the details).<br>Next select your target group from available audiences.",
                                    target: me.regions.$el.find('.create-campaign-formats')[0],
                                    placement: "left",
                                    xOffset: '-20',
                                    showNextButton: false,
                                    onShow: function () {
                                        var stepNum = hopscotch.getCurrStepNum();
                                        var i = 0;

                                        var checkExist = setInterval(function () {
                                            var $element = me.regions.$el.find('.create-campaign-formats .selected'); //element of the next step
                                            if($element[0]){
                                                hopscotch.endTour();
                                            }
                                            var $element = me.regions.$el.find('.audience-row .selected'); //element of the next step
                                            if($element[0]){
                                               var t= setTimeout( function() { hopscotch.startTour(me.tour, stepNum+1); },500);
                                                clearInterval(checkExist);
                                            }
                                            i++;
                                        }, 100);
                                    },
                                    onNext: function(){

                                    },
                                    zindex: 1050
                                },
                                {
                                    title: "Determine Your Budget",
                                    content: "The campaign budget is the total amount covering expenses across your campaign.",
                                    target: me.regions.$el.find('.create-campaign-budget')[0],
                                    placement: "left",
                                    xOffset: '-20',
                                    zindex: 1050
                                },
                                {
                                    title: "Review Your Campaign",
                                    content: "Here you can review all the selected criteria under which the campaign will be displayed, before submitting it.",
                                    target: me.regions.$el.find('.selected-screens-summary')[0],
                                    placement: "left",
                                    xOffset: '-20',
                                    zindex: 1050
                                },
                                {
                                    title: "You're All Set",
                                    content: "Congratulations!<br>\
                                        Go and publish some great ads by clicking Start Now or click Advanced Settings for more options.",
                                    target: me.regions.$el.find('#create-campaign-button')[0],
                                    placement: "left",
                                    xOffset: '-20',
                                    zindex: 1050
                                }
                            ]
                        };
                    }else if (me.variant=='Offer'){
                        me.tour = {
                            id: "Offer_view",
                            steps: [
                                {
                                    title: "Upload Your Content",
                                    content: 'Click on "Upload new" and chose image or video from your file ' +
                                    '(in the preview window you can check different screen ratios of uploaded content simply by clicking on it: 16:9, 9:16, 4:3)',
                                    target: me.view.$el.find('.offer-preview-area .fileinput')[0],
                                    placement: "left",
                                    zindex: 1001,
                                },
                                {
                                    title: "Your Campaign Details",
                                    content: 'Here you can change your Campaign Name and set Advanced Options.',
                                    target: me.view.$el.find('.offer-details')[0],
                                    placement: "top",
                                    zindex: 1001,
                                    xOffset:'center'
                                    /*,
                                    onNext: function () {
                                        var stepNum = hopscotch.getCurrStepNum();
                                        var i = 0;
                                        hopscotch.endTour();
                                        var checkExist = setInterval(function () {
                                            var $element = me.regions.$el.find('.offer-schedules .scheduler-tabs li'); //element of the next step
                                            console.debug("$element", $element);
                                            if ($element.is(':visible')) {
                                                clearInterval(checkExist);
                                                me.tour.steps[stepNum].target = $element[0];
                                                hopscotch.startTour(me.tour, stepNum);
                                            }
                                            if (i > 50) {
                                                clearInterval(checkExist);
                                                console.error("Tour could not proceed");
                                            }
                                            i++;
                                        }, 100);
                                    },
                                    */
                                },
                                {
                                    title: "Specify the Display Schedule",
                                    content: 'Specific display conditions include as follow:<br>' +
                                    '“Date and Time” - select on which days your campaign should be displayed and when.<br>' +
                                    '“Weather” - select weather conditions and temperature that will trigger the campaign to display.',
                                    target: me.view.$el.find('.offer-schedules')[0],
                                    placement: "top",
                                    zindex: 1001
                                },
                                {
                                    title: "Change Basic Settings",
                                    content: 'Here you can change format, audience and budget settings for your campaign.',
                                    target: me.view.$el.find('.budget-tab')[0],
                                    placement: "top",
                                    zindex: 1001,
                                    xOffset:'center'
                                },
                                {
                                    title: "You're All Set!",
                                    content: 'Your campaign is all set up and will go live as scheduled.',
                                    target: me.regions.$el.find('.publish-campaign-button')[0],
                                    placement: "bottom",
                                    zindex: 1001
                                },
                            ]
                        };
                    }else{
                        return false;
                    }

                    //onboardingStarted = App.os.getLocalStorageKey('ONBOARDING_STARTED_'+me.variant.toUpperCase());
                    //if(!App.currentCompany.isSetupFeePaid() && !onboardingStarted) {

                    console.log(me.tour);
                        hopscotch.startTour(me.tour);

                   // }

                    console.debug("me.regions.$el.find('#display-list-bg')", me.regions.$el.find('#display-list-bg'));

                } else {

                    if(i == undefined) i = 0;
                    var target = $(me.tour.steps[0].target);
                        hopscotch.startTour(me.tour);
                        App.os.setLocalStorageKey('ONBOARDING_STARTED_'+me.variant.toUpperCase(), true);
                        console.debug("Tour started, attempt number: " + i);
                    if(i<50){
                        setTimeout(function(){me.checkAndRun(i+1)}, 100);
                   }
                        //console.error("Tour could not start");
                   
                }
            },

        });
    });

