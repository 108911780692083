// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleEmpty',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferScheduleEmpty', 'backbone', 'moment'],
    function (App, $, _, template, Backbone, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'offer-schedule',

            events: {
                'change .specific-dates-drawer': '_createSchedule'
            },

            initialize: function () {
                this.model = new Backbone.Model({ "locked" : App.currentOffer.get("locked") });
            },

            onRender: function () {
                var me = this;
            },

            _createSchedule: function () {
                App.currentOffer.getOfferSchedules().create({
                    "type":"DbOfferScheduleTime",
                    "schedule_start": moment().format("YYYY-MM-DDT00:00"),
                    "schedule_stop": moment().endOf('isoWeek').format("YYYY-MM-DDT23:59"),
                    "weekdays": [0,1,2,3,4,5,6],
                    "time_start": null,
                    "time_stop": null,
                    "all_day":true,
                    "active": true
                });
            }

        });
    });
