// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleDisruptions',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleDisruptions', 'moment'],
    function (App, $, _, Backbone, _OfferScheduleBase, template, moment) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'change [name="disruptions"]': '_updateModelFromRadio'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                // set current values
                me._setRadioState('disruptions', me.model.get('disruptions'));

                return result;

            },

            _updateActiveState: function () {
                var me = this;
                _.isEmpty(me.model.get('disruptions')) ? me.model.set('active', false) : me.model.set('active', true);
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
            }

        });
    });
