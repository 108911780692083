// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint plusplus: true */
/*global define,console,window,navigator,sessionData,kwargs,document,BootstrapDialog,appurl,os_locale_data,Gettext,setTimeout*/
define('App',['domReady',
    'require',
        'jquery',
        'circliful',
        'backbone',
        'backbone.marionette',
        'underscore',
        'underscore.string',
        'ga',
        'offiserv-common',
        'moment',
        'bootstrap-dialog',
        'behaviors/Permissions',
        'imagesloaded',
        'handlebars',
        'waypoints',
        'backbone-model-factory', 'backbone.stickit', 'backbone.validation', 'bootstrap', 'jquery-validation', 'jquery.cookie', "jasny-bootstrap", "checkboxButton", "jqueryui-touch-punch", "perfect-scrollbar",
        'jquery.backstretch', 'bootstrap-switch', 'svginject'
    ],
    function (
        domReady,
        require,
        $,
        circliful,
        Backbone,
        Marionette,
        _,
        str,
        GoogleAnalytics,
        OffiServ,
        moment,
        BootstrapDialog,
        Permissions,
        imagesLoaded,
        Handlebars
    ) {

        'use strict';
        var App = new Backbone.Marionette.Application();
        window.App = App;
        App.GA = GoogleAnalytics;
        App.os = OffiServ;

        App.apiUrl = apiUrl;
        App.appUrl = appUrl;
        App.webAppUrl = webAppUrl;

        App.flowAdsStyle = sessionData ? sessionData.user.color_scheme : "default";
        Handlebars.registerHelper('flowAdsStyle', function () {
            return App.flowAdsStyle;
        });
        App.setFlowAdsStyle = function (style) {
            App.flowAdsStyle = style;

            document.getElementById("flowAdsStyle").setAttribute("href", cssPathTemplate.replace('STYLE', style));
            App.vent.trigger('App.refreshStyle');
        };

        function isMobile() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return ((/iPhone|iPod|iPad|Android|BlackBerry|Opera Mini|IEMobile/).test(userAgent));
        }

        App.enrichApiUrl = function (url) {
            try {
                if (url.indexOf(App.apiUrl) === -1) {
                    return App.apiUrl + url;
                } else {
                    return url;
                }
            }catch (err){
                console.error(err);
            }
        };

        App.os.initializeTabSession('X-FlowCity-Session-User');
        
        // Import Underscore.string to separate object, because there are conflict functions (include, reverse, contains)
        _.mixin(str.exports());
        _.mixin(_.string.exports());

        // prepare imagesLoaded as JQUery plugin
        imagesLoaded.makeJQueryPlugin($);

        $.validator.setDefaults({
            highlight: function (element) {
                $(element).closest('.form-group').addClass('has-error');
            },
            unhighlight: function (element) {
                $(element).closest('.form-group').removeClass('has-error');
            },
            errorElement: 'span',
            errorClass: 'help-block',
            errorPlacement: function (error, element) {
                if (element.parent('.input-group').length) {
                    error.insertAfter(element.parent());
                } else {
                    error.insertAfter(element);
                }
            }
        });

        _.extend(moment.fn, {
            toISOLocalString: function () {
                var m = this;
                if (0 < m.year() && m.year() <= 9999) {
                    return m.format('YYYY-MM-DD[T]HH:mm:ss.SSS');
                } else {
                    return m.format('YYYYYY-MM-DD[T]HH:mm:ss.SSS');
                }
            }
        });

        _.extend(Backbone.View.prototype, {
            svgInject: function () {
                this.$el.find('.svg-inject').each(function (idx, el) {
                    if ((/\.(svg)$/i).test($(el).attr('src'))) {
                        $(el).svgInject();
                    }
                });
            }
        });

        // Added before and after callbacks and authentication wrapper
        _.extend(Backbone.Router.prototype, {

            initialize: function () {
                this.history = [];
            },
            
            before: function () {
                // this will extract and remove search query, so *must* be run after GA code to ensure utm_ params are sent to server.
                App.os.loadSearchQuery();                
            },

            after: function () {
                this.storeRoute();
            },

            route: function (route, name, callback) {
                var me = this;
                if (!_.isRegExp(route)) { route = this._routeToRegExp(route); }
                if (_.isFunction(name)) {
                    callback = name;
                    name = '';
                }
                if (!callback) { callback = this[name]; }

                var router = this;
                
                // Check if user is authenticated
                callback = _.wrap(callback, function (cb) {
                    var slice = Array.prototype.slice,
                        args = slice.call(arguments, 1),
                        // it's a dirty hack but works. 
                        // might stop working after lodash=>underscore change
                        sessionRequired = !cb.__bindData__[0].noSessionRequired,
                        paymentRequired = !cb.__bindData__[0].allowedOnExpiredAccount;
                    
                    if (sessionRequired) {// prevent loop
                        if (OffiServ.isLoggedIn() && window.location.href.indexOf("#foregin-login") === -1) {

                            // trial expired - redirect user to payment page
                            if (OffiServ.isAccountExpired() && paymentRequired) {
                                console.log("Trial expired - callback.allowedOnExpiredAccount is true");
                                this.navigate('payments', {trigger: true});
                            } else {
                                cb.apply(arguments[0], args);
                            }
                        } else {
                            if(window.location.href.indexOf("#foregin-login") !== -1) {

                                window.sessionStorage.removeItem('X-FlowCity-Session-User');
                                document.cookie = "X-FlowCity-Session-User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                                var GETValues = me.parseURLParams(window.location.href);

                                if(GETValues && GETValues['session-id'] && !window.sessionStorage.getItem('X-FlowCity-Session-User')) {
                                    $.cookie('X-FlowCity-Session-User', GETValues["session-id"][0], {
                                        expires: 7 * 500,
                                        path: '/'
                                    });

                                    App.os.initializeTabSession('X-FlowCity-Session-User', true);
                                    window.location.href = '';
                                    location.host.reload();
                                }
                            }
                            else if (window.sessionStorage.getItem('X-FlowCity-Session-User')) {
                                App.os.initializeCookieSession('X-FlowCity-Session-User');
                                window.sessionStorage.removeItem('X-FlowCity-Session-User'); // to avoid infinite loop
                                console.warn("User not authenticated - attempting to reload with after setting session cookie from tab storage.");
                                window.location.reload();
                            }

                            console.warn("User not authenticated - redirecting to login page.");
                            var goToUrl = window.location.pathname;
                            if (window.location.search) {
                                goToUrl += (window.location.search);
                            }
                            if (window.location.hash) {
                                goToUrl += (window.location.hash);
                            }
                            window.goToUrl = goToUrl; // encodeURIComponent(goToUrl);
                            console.log("go to url = %s", goToUrl);

                            // route template requests to signup page by default
                            if (goToUrl.indexOf("#templates/") !== -1) {
                                App.os.queryParams.template = goToUrl.split(/[//]+/).pop();
                                this.navigate('signup', {trigger: true});
                            } else {
                                this.navigate('login', {trigger: true});
                            }
                        }
                    } else {
                        console.log("User not authenticated - callback.noSessionRequired is true");
                        cb.apply(arguments[0], args);
                    }
                });

                Backbone.history.route(route, function (fragment) {
                    var args = router._extractParameters(route, fragment);

                    if (_(router.before).isFunction()) {
                        router.before.apply(router, args);
                    }

                    /*ignore jslint start*/
                    callback && callback.apply(router, args);
                    /*ignore jslint end*/

                    router.trigger.apply(router, ['route:' + name].concat(args));
                    router.trigger('route', name, args);
                    Backbone.history.trigger('route', router, name, args);

                    if (_(router.after).isFunction()) {
                        router.after.apply(router, args);
                    }
                });
                return this;
            },

            parseURLParams: function(url) {
                var queryStart = url.indexOf("?") + 1,
                    queryEnd   = url.length + 1,
                    query = url.slice(queryStart, queryEnd - 1),
                    pairs = query.replace(/\+/g, " ").split("&"),
                    parms = {}, i, n, v, nv;

                if (query === url || query === "") return;

                for (i = 0; i < pairs.length; i++) {
                    nv = pairs[i].split("=", 2);
                    n = decodeURIComponent(nv[0]);
                    v = decodeURIComponent(nv[1]);

                    if (!parms.hasOwnProperty(n)) parms[n] = [];
                    parms[n].push(nv.length === 2 ? v : null);
                }
                return parms;
            },

            navigate: function (fragment, options) {
                var options = options || {};
                fragment = App.os.getRoutingUrl(fragment);
                if (typeof options === 'boolean') {
                    // new backbone options format for navigate method
                    options = {trigger: options};
                }
                var modelView = (options || {}).modelView,
                    trigger = (options || {}).trigger;
                if (modelView) {
                    fragment += ('/+' + modelView);
                }
//                if (! trigger) {
//                    // for non-triggering navigate calls, this won't happen in route callbacks
//                    App.GA && App.GA.track && App.GA.track(fragment);
//                }
                console.log("Backbone.Router.navigate: routing to: ", fragment);
                this._currentUrl = fragment;
                Backbone.history.navigate(fragment, options);
                
                if (options && options.trigger === false) {
                    this.storeRoute();
                }
                return this;
            },

            storeRoute: function () {
                console.log('storeRoute: /', Backbone.history.fragment);
                dataLayer.push({
                  'event':'pageview',
                  'virtualUrl': '/'+Backbone.history.fragment
                });
                console.log('Google Tag Manager Pageview /'+Backbone.history.fragment);
                this.history.push(Backbone.history.fragment);
            },

            getPreviousUrl: function (n) {
                n = n || 1;
                if (this.history.length >= n) {
                    return this.history[this.history.length - n];
                }
            },
            
            getCurrentUrl: function () {
                return this._currentUrl;
            },

            previous: function () {
                if (this.history.length > 1) {
                    this.navigate(this.history[this.history.length - 1], {
                        trigger: true
                    });
                    this.history = this.history.slice(0, -1);
                }
            }
        });

        Backbone.Stickit.addHandler({
            selector: 'input',
            events: ['blur']
        });


        Backbone.Stickit.addHandler({
            selector: '.money',
            events: ['blur'],
            onGet: function (value, options) {
                return _.numberFormat(parseFloat(value), 2, '.', '');
            },
            onSet: function (value, options) {
                options.view.$el.find('[name="' + options.observe + '"]').val(_.numberFormat(parseFloat(value), 2, '.', ''));
                return parseFloat(_.numberFormat(parseFloat(value), 2, '.', ''));
            }
        });


        Backbone.Stickit.addHandler({
            selector: 'textarea',
            events: ['blur']
        });

        Backbone.Stickit.addHandler({
            selector: 'input[type=radio]',
            events: ['change']
        });

        Backbone.Stickit.addHandler({
            selector: 'input[type=checkbox]',
            events: ['change']
        });

        App.subGetter = function(CollectionClass, urisId) {
            return function (options) {
                return this.getSubCollection(CollectionClass, urisId, options);
            };
        };

        var _old_Backbone_Model_prototype_get = Backbone.Model.prototype.get;
        var _old_Backbone_Model_prototype_set = Backbone.Model.prototype.set;
        var _old_Backbone_Model_prototype_save = Backbone.Model.prototype.save;
        var _old_Backbone_Model_prototype_revert = Backbone.Model.prototype.revert;
        _.extend(Backbone.Model.prototype, {
            defaultUrl: "",
            idAttribute: 'uri',
            _debouncedSave: false,

            get: function (fld) {
                if (fld === 'id') {
                    console.error("Do not use 'id' field. Converted to 'uri'");
                    fld = 'uri';
                }
                return _old_Backbone_Model_prototype_get.call(this, fld);
            },

            set: function (key, val, options) {
                var attrs, silent, updatePrev, result, _setting, resetPrevious=false;
                if (key == null) return this;
                // Handle both `"key", value` and `{key: value}` -style arguments.
                if (typeof key === 'object') {
                  attrs = key;
                  options = val;
                } else {
                  (attrs = {})[key] = val;
                }
                options || (options = {});
                silent          = options.silent;
                updatePrev = options.updatePrev

                if (options.xhr || options.fromChannel || ! this._changing) {
                    this._previousAttributes = _.clone(this.attributes);
                    if (options.xhr || options.fromChannel) {
                        resetPrevious = true; // required for proper working of .revert function
                    }
                    this.changed = {};
                    this._changing = true;
                }

                _setting = this._setting;
                if (! this._setting) {
                    this._setting = true;
                }

                result = _old_Backbone_Model_prototype_set.apply(this, arguments);
                if (resetPrevious) {
                    this._previousAttributes = _.clone(this.attributes);
                }

                if (_setting) {
                    return result;
                }

                if (updatePrev) {
                    this._previousAttributes = _.clone(this.attributes);
                }

                if (!silent) {
                    while (this._pending) {
                      this._pending = false;
                      this.trigger('change', this, options);
                    }
                }
                this._pending = false;
                this._setting = false;
                return result;
            },

            save: function() {
                this._changing = false;
                this._debouncedSave = false;
                return _old_Backbone_Model_prototype_save.apply(this, arguments)
            },

            /** Revert model to last synced state. Useful for "undo"/"cancel edit" operations.
             *
             */
            revert: function(options) {
                // from net discussions it seems possible that revert will be introduced in next backbone. make us future-proof.
                if (_old_Backbone_Model_prototype_revert !== undefined) {
                    return _old_Backbone_Model_prototype_revert.apply(this, arguments);
                }

                var me = this,
                    previous = me.previousAttributes();
                var oldValues = _.object(_.map(me.changedAttributes(), function(newVal, attr) {
                    return [attr, previous[attr]];
                }));
                console.warn('Model.revert: ', oldValues)
                return me.set(oldValues, options);
            },

            generateUri: function (idPath) {
                var idPth = _.clone(idPath),
                    currId = idPth.pop(),
                    parentUri = "/data",
                    uri;

                if (this.uriStencilParent) {
                    parentUri = this.uriStencilParent.prototype.generateUri(idPth);
                }
                uri = [parentUri, this.uriStencil, currId].join('/');
                // console.log('generateUri: ' + uri);
                return uri;
            },

            sync: function (method, model, options) {
                options = options || {};

                this.defaultUrl = this.url;

                if (method.toLowerCase() !== 'create' && this.get('uri') !== undefined) {
                    this.url = this.attributes.uri;
                } else {
                    this.url = this.defaultUrl;
                }
                this.url = options.url || this.url;

                options = _.extend(options, {
                    url: App.enrichApiUrl(_.result(model, 'url'))
                });

                return Backbone.sync.apply(this, arguments);
            },

            parse: function (obj) {
                var dateTimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
                _.each(obj, function (value, key, obj) {
                    if (typeof value === 'string' && dateTimeRegex.test(value)) {
                        obj[key] = new Date(value);
                    }
                });
                return obj;
            },

            addUri: function (uriName, uri) {
                /** Adds uri to uris collection.
                 * @param uriName: uri name
                 * @param uri: uri
                 */

                var uris = this.get('uris') || [];
                uris[uriName] = uri;
                this.set('uris', uris, {silent: true});
            },

            getUri: function (uriName) {
                return this.get('uris')[uriName];
            },

            getSubCollection: function (collectionClass, subId, options) {
                /** retrieves Collection of Models.
                 * @param collectionClass: Backbone.Collection subclass,
                 * @param subId: string id, as returned in ``uris`` metadata (the same as defined in python code by @OffiServModel.subcollection_uri())
                 * @param options.fetch: boolean or string
                 *  * true or undefined: collection.fetch() will be launched immediately; put depending code in $.when(collection.promise).then(function() { ... });
                 *  * 'event_id': collection.fetch() will be launched *once* when App.vent.trigger('event_id') is triggered.
                 *  * false: collection will not be fetched; your code must do that
                 *  * NOTE: fetch() is not called if the collection already exists, or when cannot resolve subId to collection URI
                 * @param options.onAutoCreate: callback used by Milestone.Tasks. Quick&Dirty
                 */
                options = options || {};
                this._subCollections = this._subCollections || {};
                var uri = this.get('uris') && this.get('uris')[subId];

                var fetch = (options.fetch !== undefined ? options.fetch : true),
                    collection = this._subCollections[subId] || (uri && App.collections[uri]),
                    fetchEarlier = false;

                if (collection && collection._fetch_flag && _.isString(collection._fetch_flag)) {
                    if (fetch === true && ! App.contentStage[collection._fetch_flag]) {
                        // we want to fetch earlier
                        fetchEarlier = true;
                    }
                }

                if (_.isUndefined(collection) || _.isUndefined(collection.promise) || fetchEarlier) {
                    if (_.isUndefined(collection)) {
                        console.log('No collection found - creating for ', this.get('uri'), this.get('text'), subId, uri, options);
                        options.parentModel = this;
                        collection = new collectionClass([], options);
                        App.collections[uri] = collection;
                    } else {
                        console.log('Not yet fetched collection found for ', this.get('uri'), this.get('text'), subId, uri, options);
                    }
                    collection._fetch_flag = fetch;
                    if (uri) {
                        collection.url = uri;
                        if (fetch) {
                            collection.url = App.enrichApiUrl(collection.url);
                            var _fetchCollection = function() {
                                var fetchPromise = collection.fetch(options);
                                $.when(fetchPromise).then(function(jqXHR) {
                                    collection._deferredFetch.resolve(jqXHR);
                                });
                            };
                            if (_.isString(fetch) && ! App.contentStage[fetch]) { // fetch is App.vent's event_id
                                collection._deferredFetch = $.Deferred();
                                collection.promise = collection._deferredFetch.promise();
                                this.listenToOnce(App.vent, fetch, _fetchCollection);
                            } else { // either fetch immediately or for content stage which already happened
                                collection.promise = collection.fetch(options);
                            }
                        }
                        if (_.isFunction(options.onAutoCreate)) {
                            console.log('calling onAutoCreate');
                            options.onAutoCreate.call(this, collection);
                        }
                    }
                };
                this._subCollections[subId] = collection;
                return collection;
            },

            setSubCollection: function(subId, collection) {
                this._subCollections = this._subCollections || {};
                this._subCollections[subId] = collection;
                collection.url = collection.url || collection._superset.url;
            },

            saveDebounced: function (delay) {
                var me = this,
                    delay = delay || 4000;
                if (!this.saveLater) {
                    this.saveLater = _.debounce(_.bind(function () {
                        if (this._debouncedSave) {
                            this.save();
                        }
                    }, me), delay);

                }

                this._debouncedSave = true;
                return this.saveLater();
            }

        });

        var _old_Backbone_Collection_prototype_get = Backbone.Collection.prototype.get;
        _.extend(Backbone.Collection.prototype, {
            // Some extension for OS REST Collection
            parse: function (obj) {
                if (obj.records !== undefined) {
                    return obj.records;  // for old REST requests
                }
                return obj;
            },
            
            get: function (id) {
                /** overriden to allow providing array of integers for GAE nested ancestor paths. 
                 *  @param id: can be string URI or array of integers
                 */
                if (_.isArray(id)) {
                    id = this.model.prototype.generateUri(id);
                }
                
                var model = _old_Backbone_Collection_prototype_get.call(this, id);
                // will help to use the same code regardless the model was fetched or got from collection.  
                if (model && _.isUndefined(model.promise)) {
                    model.promise = $.Deferred().resolve().promise();
                }
                return model;
            },

            /** Get a model from collection or fetch it independently
             * If the collection is already loaded, get Model from it (or fallback model if not found).
             * If it's not loaded yet, fetch the single model (or fallback model if not found).
             * @param idPath: array with path of id's, or string uri
             * @param fallbackIdPath: array with path of id's, or string uri; if provided, will be loaded in case loading/finding the idPath fails; 
             */
            getOrFetch: function (idPath, fallbackIdPath, options) {
                var me = this,
                    model,
                    fallbackModel;
                
                // first try to get model from the collection
                model = me.get(idPath);
                if (! _.isUndefined(model)) { 
                    // will help to use the same code regardless the model was fetched or got from collection.  
                    model.promise = model.promise || $.Deferred().resolve().promise();
                    return model;
                }

                if (fallbackIdPath) {
                    // fallback model path provided - try to get it from collection
                    fallbackModel = me.get(fallbackIdPath);
                    if (! _.isUndefined(fallbackModel)) {
                        fallbackModel.promise = fallbackModel.promise || $.Deferred().resolve().promise();
                    }
                }
                
                // now check if there is any chance that collection is not (fully) loaded yet. If so, fetch the model individually
                if (_.isUndefined(me.promise) || me.promise.state() != 'resolved') {
                    var uri = _.isArray(idPath) ? me.model.prototype.generateUri(idPath) : idPath;
                    model = new me.model({uri: uri}, options);
                    model._deferredFetch = $.Deferred();
                    model.promise = model._deferredFetch.promise();
                    $.when(model.fetch()).then(
                        function onGetOrFetchDone(jqXHR) {
                            model.options = options;
                            me.add([model], options);
                            model._deferredFetch.resolve(jqXHR);
                        }, function onGetOrFetchFail(jqXHR) {
                            if (fallbackIdPath) {
                                // fallback model path provided - if not got it from collection, then just return it
                                if (_.isUndefined(fallbackModel)) {
                                    // and now try to fetch it.
                                    uri = _.isArray(fallbackIdPath) ? me.model.prototype.generateUri(fallbackIdPath) : fallbackIdPath;
                                    model.set('uri', uri);
                                    model.options = options;
                                    $.when(model.fetch()).then(function onGetOrFetchFallbackDone(jqXHR) {
                                        me.add([model], options);
                                        model._deferredFetch.resolve(jqXHR);
                                    }, function getOrFetchFallbackFail(){ 
                                        model._deferredFetch.reject(jqXHR);
                                    });
                                } else {
                                    model = fallbackModel;
                                }
                            } else {
                                model._deferredFetch.reject(jqXHR);
                            }
                        }
                    );
                } else {
                    model = fallbackModel;
                }
                return model;
            },
            
            initialize: function (models, options) {
                if (options !== undefined) {
                    this.options = options;
                    if (options.parentModel) {
                        this.parentModel = options.parentModel
                    }
                }
                if (this.url) {
                    App.collections[this.url] = this;
                    this._url = this.url;
                }
                Object.defineProperty(this, 'url', {
                    get: function () {
                        return this._url;
                    },
                    set: function (url) {
                        this._url = url;
                        App.collections[url] = this;
                    }
                });
            },

            toSuggestionList: function (field) {
                field = field || 'text';
                return this.map(function (model) {
                    return {id: model.get('uri'), name: model.get(field)}
                });
            }
        });

        // Custom model validators
        _.extend(Backbone.Validation.validators, {
            emailExists: function (value, attr, customValue, model) {
                console.log(model);
                console.log(value);
                if (value !== model.get('initialEmail')) {
                    if (!App.os.checkEmail(value)) {
                        return App.trans('This email address already exists');
                    }
                }
            }
        });

        _.extend(Backbone.Validation.callbacks, {
            valid: function (view, attr, selector) {
                // remove error element if any
                view.$('[name="' + attr + '"]')
                    .removeClass('invalid')
                    .next("span.error")
                    .remove();
            },
            invalid: function (view, attr, error, selector) {
                // remove error element if any
                view.$('[name="' + attr + '"]')
                    .next("span.error")
                    .remove();

                // add error element
                view.$('[name="' + attr + '"]')
                    .addClass('invalid')
                    .after('<span class="error">' + error + '</span>');

                var editButton = view.$('[name="' + attr + '"]').find(".input-group-btn button i");
                if (editButton !== undefined) {
                    editButton.removeClass("glyphicon-pencil").addClass("glyphicon-floppy-save");
                }

                // focus field
                view.$('[name="' + attr + '"]').focus();
            }
        });
        
        // this will silent out "ViewDestroyed" exceptions. They can be harmful while usually rendering destroyed view should be just silently ignored
        var safeRenderDestroyedViewClasses = [Marionette.ItemView, Marionette.CollectionView, Marionette.CompositeView, Marionette.LayoutView];
        _.each(safeRenderDestroyedViewClasses, function(MarionetteViewClass) {
            var oldMarionetteViewClass_render = MarionetteViewClass.prototype.render;
            _.extend(MarionetteViewClass.prototype, {
               render: function() {
                   if (!this.isDestroyed) {
                       return oldMarionetteViewClass_render.apply(this, arguments);
                   }
                   console.warn('Marionette view render called for destroyed view; skipping', this);
                   return this;
               }
            });
        });

        _.extend(Marionette.CollectionView.prototype, {

            onBeforeRender: function () {
                if (this.searchBar) {
                    this.listenTo(App.vent, 'App.search.query', _.bind(this._queryCollection, this));
                    this.listenTo(App.vent, 'App.search.query.lastPage', _.bind(this._noMoreItems, this));
                    this.listenTo(App.vent, 'App.search.query.moreItems', _.bind(this._moreItems, this));
                    this.listenTo(App.vent, 'App.search.query.noResults', _.bind(this._noResults, this));
                    this._addLoadNextPageWaypoint();
                }
            },

            _noResults: function () {
                this._isLoading = false;
                if (this.collection) {
                    this.collection._moreResults = false;
                }
                this.$el.find('#page-load-more').removeClass('loading-more').hide();
                this.$el.find('.empty-collection').html('No results found');
            },

            _noMoreItems: function () {
                this._isLoading = false;
                if (this.collection) { this.collection._moreResults = false; }
                this.$el.find('#page-load-more').removeClass('loading-more').hide();
            },

            _moreItems: function () {
                var me = this;

                    me._isLoading = false;
                    if (me.collection) {
                        me.collection._moreResults = true;
                    }
                    me.$el.find('#page-load-more').removeClass('loading-more').show();
                    me._addLoadNextPageWaypoint();
            },

            _queryCollection: function (query, sortBy) {
                this.$el.find('.empty-collection').html('<div class="spinner" style="margin: 0 auto"></div>');
                this.collection.query(query, sortBy);
            },

            _loadNext: function () {
                var me = this;
                if (!me._isLoading) {
                    me.$el.find('#page-load-more').addClass('loading-more');
                    me._isLoading = true;
                    me.collection.getNextPage({reset: false});
                }
            },

            _addLoadNextPageWaypoint: function () {
                var me = this,
                    wp;

                _.defer(function () {

                    if (me.$el.find('#page-load-more').length > 0) {
                        wp = new Waypoint({
                            context: _.isString(me.scrollContext) ? me.$el.find(me.scrollContext)[0] : window,
                            element: me.$el.find('#page-load-more'),
                            handler: function (direction) {
                                if (direction === 'down') {
                                    if (!me.isDestroyed) {
                                        me._loadNext();
                                    }
                                }
                            },
                            offset: '100%'
                        });
                    }
                });
            }

        });

        _.extend(Marionette.ItemView.prototype, {


            events: {
                'keydown input': 'keyDownOnInput'
            },

            behaviors: {
                Permissions:{
                    behaviorClass: Permissions,
                    App: App
                }
            },

            keyDownOnInput: function (event) {
                if (event.keyCode === 13) {
                    event.currentTarget.blur();
                    event.currentTarget.focus();
                }
            }
        });

        Marionette.SortedCompositeView = Marionette.CompositeView.extend({
            appendHtml : function (collectionView, childView, index) {
                var childrenContainer = collectionView.childViewContainer ? collectionView
                        .$(collectionView.childViewContainer)
                        : collectionView.$el,
                    children = childrenContainer.children();
                if (children.size() <= index) {
                    childrenContainer.append(childView.el);
                } else {
                    children.eq(index).before(childView.el);
                }
            }
        });

        Backbone.SearchableCollection = Backbone.Collection.extend({

            _query: {},

            _beenQueried: false,
            _moreResults: false,

            departmentDependant: false,


            initialize: function (options) {
                var me =  this,
                    parentCollection = this,
                    result = Backbone.Collection.prototype.initialize.apply(me, arguments);

                if (options && options.searchUri) {
                    me.searchUri = options.searchUri
                }

                var _intCollection = Backbone.PageableCollection.extend({

                    model: me.model,

                    mode: 'infinite',

                    state: {
                        firstPage: 1,
                        currentPage: 1,
                        pageSize: 24
                    },

                    queryParams: {
                        pageSize: "limit",
                        currentPage: null,
                        query: JSON.stringify(me._query),
                        objects: 1
                    },

                    parseRecords: function (resp) {
                        return resp.objects;
                    },

                    parseLinks: function (resp, xhr) {
                        var me = this;
                        return {
                            first: this.url,
                            next: !_.isNull(resp.next_uri) ? App.enrichApiUrl(resp.next_uri) : this.url,
                            prev: this.url
                        };
                    },

                    query: function (queryString, sortBy) {
                        var me = this,
                            promise;
                        this.queryParams.query = JSON.stringify(queryString);
                        this.queryParams.sort = sortBy || null;

                        this.fullCollection.reset();
                        promise = this.fetch({ url: this.url }).done(function (results) {
                            if (_.size(results.objects) === 0) {
                                App.vent.trigger('App.search.query.noResults');
                            } else if (!_.isNull(results.next_uri)) {
                                App.vent.trigger('App.search.query.moreItems');
                            } else {
                                App.vent.trigger('App.search.query.lastPage');
                            }
                        });
                        return promise;
                    }

                });

                me._internal = new _intCollection();

                me.listenTo(me._internal, 'reset', me._mergeResults);

                return result;
            },

            fetch: function (options) {
                return false;
            },

            _mergeResults: function () {
                var me = this;
                this.add(this._internal.models, {merge: true});
                // if (_.size(this) === 0) {
                //     me._moreResults = false;
                //     App.vent.trigger('App.search.query.noResults');
                // }
            },

            getNextPage: function () {
                var me = this;

                if (me._moreResults) {
                    me._internal.getNextPage()
                        .done(function (results) {
                            if (_.size(results.objects) === 0) {
                                me._moreResults = false;
                                App.vent.trigger('App.search.query.noResults');
                            } else if (!_.isNull(results.next_uri)) {
                                me._moreResults = true;
                                App.vent.trigger('App.search.query.moreItems');
                            } else {
                                me._moreResults = false;
                                App.vent.trigger('App.search.query.lastPage');
                            }
                        });
                }
            },

            beenQueried: function () {
                return this._beenQueried;
            },

            resetCollection: function () {
                this._beenQueried = false;
                this._moreResults = false;
                this.reset();
            },

            query: function (query, sortBy) {
                this._beenQueried = true;
                this._query = _.isObject(query) ? query : {};

                if (this.departmentDependant) {
                    this._query['department_and_ancestors'] = App.currentDepartment.get('uri');
                }

                this._internal.url = this.searchUri;
                this._internal.url = App.enrichApiUrl(this._internal.url);
                this.reset();
                return this._internal.query(this._query, sortBy);
            }

        });


        $(document).ajaxError(function (event, jqxhr, settings, exception) {
            
            if (jqxhr.status === 403) {
                if (settings && settings.url && settings.url.indexOf('/data') == 0) {
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: App.trans("You do not have permission to change the object"),
                        message: App.trans("Your latest request to the server was rejected.<br><br><b>You do not have permission to change the object.</b><br><br>Click the below button to refresh your browser"),
                        closable: false,
                        buttons: [{
                            label: App.trans("Refresh"),
                            action: function (dialog) {
                                setTimeout(function () { window.location.reload(); }, 100);
                            }
                        }]
                    });
                }
            } else if (jqxhr.status === 401) {
                if (!_.str.include(window.location.hash, "#login")) {
                    sessionData = undefined;
                    $.removeCookie("X-FlowCity-Session-User");
                    $.removeCookie("X-FlowCity-Session-User", {domain: ".flow.city"});
                    App.os.clearTabSession('X-FlowCity-Session-User');
                    window.location.href = App.webAppUrl;
                }
            }
        });

        // prevent Backbone requests from being cached
        $.ajaxSetup({
            cache: false,
            xhrFields: {
                withCredentials: true
            }
        });

        var inputMask = $.fn.inputmask.noConflict();
        $.fn.inputMask = inputMask;

        App.addRegions({
            headerRegion: "header",
            mainRegion: "#main"
        });

        App.collections = {};
        App.searchBarConfig = {};

        App.currentProject = null;
        App.currentView = null;
        App.projectSelector = null;

        /* Fire all registered resize functions
           Add function by: App.os.onResize.add(name, callback);
           Remove function by: App.os.onResize.remove(name);
        */
        App.resizeFunctions = [];
        $(window).on("resize", function() {
            _.each(App.resizeFunctions, function(func) {
                func.func();
            });
        });
        $(window).on('focus', function() {
            // ensure cookie contains the right session e.g. on page reload. 
            App.os.initializeCookieSession('X-FlowCity-Session-User', true);
        });

        // default currency
        App.currency = 'GBP';

        App.addInitializer(function () {
            Backbone.history.start();
        });

        $.event.fixHooks.drop = { props: [ "dataTransfer" ] };

        // App.gt = new Gettext({domain: 'OffiServ', locale_data: os_locale_data});
        // App.trans = function (msgid) { return App.gt.gettext(msgid); };
        App.trans = function (msgid) { return msgid; };
        App.mobile = isMobile();


        if (typeof Notification !== 'undefined') {
            App.notifyBrowser = function (title, body) {
                var notification = new Notification((title ? title : "Flow.City" ), {
                    icon: '/static/images/fc.png',
                    body: (body ? body : "boom!")
                });
            }
        } else {
            App.notifyBrowser = _.noop;
        }

        // Default user context
        App.userContext = App.os.ROLE_CONTEXT.RETAILER;

        // sometimes views may hook too late for these important events. store them as flags.
        App.contentStage = {'offer-tiles-loaded': false, 'content-ready': false};
        App.listenToOnce(App.vent, 'offer-tiles-loaded', function() {
            App.contentStage['offer-tiles-loaded'] = true;
        });

        /*
        App.listenToOnce(App.vent, 'content-ready', function() {
            App.contentStage['base-content-ready'] = true;
            App.contentStage['content-ready'] = true;
        });
        */
        
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_DEFAULT] = App.trans('Information');
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_INFO] = App.trans('Information');
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_PRIMARY] = App.trans('Information');
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_SUCCESS] = App.trans('Success');
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_WARNING] = App.trans('Warning');
        BootstrapDialog.DEFAULT_TEXTS[BootstrapDialog.TYPE_DANGER] = App.trans('Danger');
        
        var _old_BootstrapDialog_confirm = BootstrapDialog.confirm; 
        BootstrapDialog.confirm = function(message, callback) {
            return new BootstrapDialog({
                title: App.trans('Confirmation'),
                message: message,
                closable: false,
                data: {
                    'callback': callback
                },
                buttons: [{
                        label: App.trans('Cancel'),
                        action: function(dialog) {
                            typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(false);
                            dialog.close();
                        }
                    }, {
                        label: App.trans('OK'),
                        cssClass: 'btn-primary',
                        action: function(dialog) {
                            typeof dialog.getData('callback') === 'function' && dialog.getData('callback')(true);
                            dialog.close();
                        }
                    }]
            }).open();
        };
        BootstrapDialog.confirm.originalFn = _old_BootstrapDialog_confirm;
        return App;
    });

window.backboneNavigate = function(fragment, trigger) {
    Backbone.history.navigate(fragment, trigger);
};
