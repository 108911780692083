
/* START_TEMPLATE */
define('hbs!templates/views/Payments/SubscriptionPlans',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<ul class=\"row\"></ul>\n\n<div style=\"margin-top: 20px;margin-left:  10px;\">\n    <p style=\"margin-left: 20px;\">\n        *) BUSINESS DECISION MODELS\n    </p>\n    <p style=\"margin-left: 20px; MARGIN-TOP: -10px;\">\n        **) PRICES EXCLUDE VAT\n    </p>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
