/*global define*/
define('collections/OnlineOffers',["backbone", "models/Offer"],
    function (Backbone, Offer) {

        'use strict';

        var Offers = Backbone.Collection.extend({
            model: Offer,

            comparator: function (model) {
                return model.get('targets')[0].position;
            }
        });

        return Offers;
    });
