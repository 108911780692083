// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/CreateCampaignBudget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment', 'ladda-bootstrap',
         'hbs!templates/views/Dashboard/CreateCampaignBudget',
         'skycons'
         ],
    function (App, Backbone, _, Marionette, $, moment, Ladda,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'create-campaign-budget widget ar-1-1',
            ui: {

            },
            events: {
            },

            initialize: function (options) {
                var me = this;
                
                App.listenTo(App.vent, 'recalculate-intensity', function() {
                    me.createTimeSlider(1);
                });
            },

            onShow: function () {
                var me = this;
                window.timeslidermax = 10;
                window.intensity =1;
                if(App.currentOffer!=undefined){
                    window.intensity = App.currentOffer.get('intensity');
                    window.intensityValue = App.currentOffer.get('intensity');
                }
                var t = setTimeout( function(){
                    var val = $('[name="budget"]').val();
                    $('#range').html(val);
                                $("#credit_slider").slider({
                                    range: false,
                                    min:0,
                                    max:parseInt(App.currentCompany.get('credits')),
                                    value: val,
                                    step: 1,
                                    stop:function (event, ui) {
                                        if(ui.value>0) window.step3 =true;
                                        if(window.campaignFormatCount==undefined) window.campaignFormatCount = 710;

                                        if(window.campaignFormatCount != undefined && window.campaignAudiencePoints  != undefined) {
                                            window.timeslidermax = 10; // intensity = 1
                                            window.timeslidermin = 0; // intensity = 10
                                            window.timeslidervalue =0.0833;
                                            window.timesliderstep = 0.0833;

                                            var impressionsMin = (window.campaignFormatCount/5)*600;
                                            var impressionsMax = (window.campaignFormatCount/50)*60;
                                            var priceperhourMax = impressionsMax*window.campaignAudiencePoints/100000;
                                            var priceperhourMin = impressionsMin*window.campaignAudiencePoints/100000;
                                            window.timeslidermax = (ui.value/priceperhourMax)*0.0833;
                                            window.timeslidermin = (ui.value/priceperhourMin)*0.0833;
                                            window.timeslidervalue =0.0833;
                                            window.budget = ui.value;

                                            //if(window.timeslidermax >10 )window.timeslidermax=10;
                                            if(ui.value>0 && window.step1 == true && window.step2 == true){
                                                $('#create-campaign-button').removeClass('disabled');
                                                $('#advanced-campaign-button').removeClass('disabled');
                                            } else {
                                                $('#create-campaign-button').attr('href','#').addClass('disabled');
                                                $('#advanced-campaign-button').attr('href','#').addClass('disabled');
                                            }
                                            $('[name="budget"]').val(ui.value);
                                            $('[name="budget"]').focus().focusout();
                                            me.createTimeSlider();
                                        }


                                        // if new offer already created on dashboard, modify its value
                                        if(window.page === 'dashboard' && window.newOfferAttributes) {
                                            $.ajax({
                                                url: App.enrichApiUrl(window.newOfferAttributes.urlAddress),
                                                type: 'PUT',
                                                dataType: "json",
                                                data: JSON.stringify({
                                                    budget: Math.round( ui.value ).toString()
                                                })
                                            }).done(function (data) {
                                                console.log('success');
                                            }).fail(function (data) {
                                                console.log('error');
                                            });
                                        }
                                    },
                                    slide: function (event, ui) {
                                        var val = Math.abs(ui.value);
                                        $("#range").html(val);
                                        if(window.page === 'campaign')
                                            $("#budget-summary").html(val);
                                    }
                                });
                        me.createTimeSlider(1);
                },1000);
            },

            createTimeSlider: function(init){
                var days = window.timeslidermax;
                var s = (days>1)?'s':'';
                var intensity ='low';
                if(window.page!='dashboard')
                    window.budget = $('[name="budget"]').val();
                // first time 
                if(init==1){
                    var intensityValue = parseFloat( $('[name="intensity"]').val() );
                    if(isNaN(intensityValue)) intensityValue=1;
                    var impressions = (window.campaignFormatCount/((10-intensityValue+1)*5))*60*intensityValue;
                    var priceperhour = impressions*window.campaignAudiencePoints/100000;
                    console.log(window.campaignFormatCount+' '+intensityValue+'  '+window.campaignAudiencePoints);
                    var val = parseInt(Math.abs(intensityValue));
                    days = parseInt((window.budget/priceperhour)*0.0833);
                    var hours = parseInt(window.budget/priceperhour);
                    if(isNaN(days)){
                        days=10;
                        hours=50;
                        intensityValue =1;
                        console.log(days);
                    }

                    if(intensityValue>3)    intensity ='medium';
                    if(intensityValue>7)    intensity ='high';
                    var s = (days>1)?'s':'';

                    if(days<1){
                        if(hours>0){
                           var sh =  (hours>1)?'s':'';
                            $("#range-time").html(hours+' hour'+sh);
                            $('#selected-days').html(hours+' hour'+sh);
                        } else {
                            $("#range-time").html('<1 hour');
                            $('#selected-days').html('<1 hour');
                        }
                    } else {
                        //console.log(days);
                        $("#range-time").html(days+' day'+s);
                        $('#selected-days').html(days+' day'+s);
                    }
                } else {
                    var intensityValue = window.timeslidervalue;
                    //console.log(window.timeslidermax);
                    var addressees = $("#range-time, #selected-days, #estimated-time-summary");
                    if(window.timeslidermax>=1){
                        addressees.html(parseInt(window.timeslidermax)+' day'+s);
                    } else{
                       var hours = parseInt(window.timeslidermax/0.0833);
                       var s = (hours>1)?'s':'';
                        if(hours>0){
                            addressees.html(parseInt(hours)+' hour'+s);
                        } else {
                            addressees.html('<1 hour');
                        }
                    }
                    if(intensityValue>3) intensity ='medium';
                    if(intensityValue>7) intensity ='high';
                }

                //console.log(intensityValue);

                                $("#time_slider").slider({
                                    range: false,
                                    min:1,
                                    max:10,
                                    value:intensityValue,
                                    step: window.timesliderstep,
                                    slide:function (event, ui) {
                                        var impressions = (window.campaignFormatCount/((10-ui.value+1)*5))*60*ui.value;
                                        var priceperhour = impressions*window.campaignAudiencePoints/100000;
                                        var val = parseInt(Math.abs(ui.value));
                                        var days = parseInt((window.budget/priceperhour)*0.0833);
                                        var hours = parseInt(window.budget/priceperhour);

                                        if(isNaN(days)){ days = '-'; }
                                        var intensity ='low';
                                        if(ui.value>3) intensity ='medium';
                                        if(ui.value>7) intensity ='high';
                                        var s = (days>1)?'s':'';
                                        if(hours<=11){
                                            if(hours>0){
                                                var sh =  (hours>1)?'s':'';
                                                $("#range-time").html(hours+' hour'+sh);
                                                $('#selected-days').html(hours+' hour'+sh);
                                            } else {
                                                $("#range-time").html('<1 hour');
                                                $('#selected-days').html('<1 hour');
                                            }
                                        } else {
                                            $("#range-time").html(days+' day'+s);
                                            $('#selected-days').html(days+' day'+s);
                                        }
                                        $('#selected-intensity').html(intensity);
                                        $('.selected-screens-summary p:eq(2)').show();

                                        if(window.page === 'campaign')
                                            $("#estimated-time-summary").html($("#range-time").html());
                                    },
                                    stop:function (event, ui) {
                                        window.intensity = ui.value;
                                        window.intensityValue = ui.value;
                                        console.log('=============');
                                        console.log('intensity:'+ui.value);
                                        var impressions = (window.campaignFormatCount/((10-ui.value+1)*5))*60*ui.value;
                                        console.log('impressions:('+window.campaignFormatCount+'/((10-'+ui.value+'+1)*5))*60*'+ui.value+'==='+impressions);
                                        var priceperhour = impressions*window.campaignAudiencePoints/100000;
                                        console.log('priceperhour:'+priceperhour);
                                        $('[name="intensity"]').val(ui.value);
                                        $('[name="intensity"]').focus().focusout();

                                        // if new offer already created on dashboard, modify its value
                                        if(window.page === 'dashboard' && window.newOfferAttributes) {
                                            $.ajax({
                                                url: App.enrichApiUrl(window.newOfferAttributes.urlAddress),
                                                type: 'PUT',
                                                dataType: "json",
                                                data: JSON.stringify({
                                                    intensity: Math.round( ui.value ).toString()
                                                })
                                            }).done(function (data) {
                                                console.log('success');
                                            }).fail(function (data) {
                                                console.log('error');
                                            });
                                        }
                                    }
                                });

                var selectedIntensityInterval = setInterval(function () {
                    if($('#selected-intensity').length > 0) {
                        $('#selected-intensity').html(intensity);
                        $('.selected-screens-summary p:eq(2)').show();
                        clearInterval(selectedIntensityInterval);
                    }
                }, 300);
            },

            onDestroy: function () {
                var me = this;
            
            }

        });
    });

