// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,require,sessionData,console,window,BootstrapDialog*/
define('controllers/controller',['App', 'jquery', 'underscore', 'backbone',

        // Layouts
        'layouts/ApplicationLayout',
        'layouts/Dashboard',
        'layouts/DashboardNew',
        'views/Dashboard/CreateCampaign',
        'layouts/RetailersLayout',
        'layouts/LoopManagement',
        'layouts/ProfileLayout',
        'views/Reports/ReportsLayout',
        'views/InternalReports/InternalReportsLayout',

        // Views
        'views/SignIn',
        'views/SignUp',
        'views/SignUpFacebook',
        'views/PasswordReset/ForgotPassword',
        'views/PasswordReset/ForgotPasswordChange',
        'views/PasswordReset/PasswordInstructionsSent',
        'views/PasswordReset/PasswordChangeError',
        'views/Offers/Offers',
        'views/Offers/OffersNG',
        'views/Offers/Offer',
        'views/Offers/OfferNG',
        'views/ScreenPlanning-mockup',
        'views/Displays/Displays',
        'views/Notifications/PublicTransportDisruptions',
        'views/Notifications/PublicTransportDisruptionsHistory',
        'views/LoopManagement/DisplayGroupsSelector',
        'views/DisplayGroups/DisplayGroups',
        'views/Dashboard/CreativeSpec',
        'views/LoopManagement/NGLoopManagement',

        // Collections
        'collections/Companies',

        // Models
        'models/User',
        'models/Company',
        'models/Department',
        'models/Offer'

    ],
    function (App, $, _, Backbone,

        // Layouts
        ApplicationLayout,
        Dashboard,
        DashboardNew,
        CreateCampaign,
        RetailersLayout,
        LoopManagement,
        ProfileLayout,
        ReportsLayout,
        InternalReportsLayout,

        // Views
        SignIn,
        SignUp,
        SignUpFacebook,
        ForgotPassword,
        ForgotPasswordChange,
        PasswordInstructionsSent,
        PasswordChangeError,
        Offers,
        OffersNG,
        Offer,
        OfferNG,
        ScreenPlanning,
        Displays,
        PublicTransportDisruptions,
        PublicTransportDisruptionsHistory,
        DisplayGroupsSelector,
        DisplayGroups,
        CreativeSpec,
        NGLoopManagement,

        // Collections
        Retailers,

        // Models
        User,
        Company,
        Department,
        OfferModel

        ) {

        'use strict';
        
        var noSessionRequired = function (fn) {
                fn.noSessionRequired = true;
                return fn;
            },

            allowedOnExpiredAccount = function (fn) {
                fn.allowedOnExpiredAccount = true;
                return fn;
            };

        return Backbone.Marionette.Controller.extend({
            initialize: function () {
                var me = this;
                if (App.os.isLoggedIn()) {

                    App.currentCompany = new Company(sessionData.company);
                    App.currentCompany.url = sessionData.company.uri;

                    // only MULTI can access this site
                    if (App.currentCompany.get('active_plan') !== 'MULTI') {
                        window.location.href = App.webAppUrl;
                    }

                    App.currentCompany.getSubscriptionPlans();
                    App.currentCompany.getOffers({fetch: false});
                    App.currentUser = new User(sessionData.user);
                    App.currentUser.url = sessionData.user.uri;
                    App.currentUser.set('company_name', App.currentCompany.get('name'), {silent: true});
                    App.currentUser.getDepartments();
                    App.userAdded = true;

                    App.currentDepartment = new Department();
                    App.currentDepartment.url = App.currentUser.get('department');

                    App.currentDepartmentPromise = App.currentDepartment.fetch();
                    $.when(App.currentDepartmentPromise)
                        .then(function onUserDepartmentLoaded () {
                            // prepare offers collection
                            // App.currentDepartment.getDisplayGroups();
                            App.currentDepartment.getLocations();
                            App.currentDepartment.getOffers({fetch: false, searchUri: '/data/search/offers/normal'});
                        });

                    // Admin
                    if (App.currentUser.hasRole(App.os.roles.userAdmin)) {
                        App.currentUser.addUri('Retailers', '/data/companies');
                        $.extend(App.currentUser, {
                            getRetailers: function (options) {
                                var result = this.getSubCollection(Retailers, 'Retailers', options);
                                return result;
                            }
                        })
                    }

                    // Get current disruptions list and setup notification counter
                    App.currentUser.getPublicTransportDisruptions();

                    // set locale
                    if (App.currentUser.get('lang') === 'pl') {
                        require(["moment-pl"]);
                    }

                    // Ask for notification permission
                    if (typeof Notification !== 'undefined') {
                        if (Notification.permission !== "granted") {
                            Notification.requestPermission();
                        }
                    }

                    // Add app vent listeners
                    me.listenTo(App.vent, 'App.logout', me.logout);

                    _.delay(function () {
                        App.vent.trigger('App.layout.lowCredits.info');
                    }, 3000);
                }
            },

            logout: function () {
                $.ajax({
                    url: App.enrichApiUrl('/data/logout'),
                    type: 'POST',
                    dataType: "json",
                    data: JSON.stringify({})
                }).done(function () {
                    $.removeCookie("X-FlowCity-Session-User");
                    $.removeCookie("X-FlowCity-Session-User", {domain: ".flow.city"});
                    App.os.clearTabSession("X-FlowCity-Session-User");
                    App.os.clearTabSession("auth-token");
                    window.location.href = App.webAppUrl + '/signout'; // temp solution for removing firebase session
                });
            },

            _setupApplicationLayout: function () {
                var layout;
                if (App.mainRegion.currentView === undefined) {

                    // Setup Project view layout
                    layout = new ApplicationLayout();

                    // Add layout to main region of the application
                    App.mainRegion.show(layout);
                } else {
                    layout = App.mainRegion.currentView;
                }

                return layout;
            },

            login: noSessionRequired(function login() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    window.location.href = App.webAppUrl;
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            signup: noSessionRequired(function signup() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    window.location.href = App.webAppUrl;
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            signupfb: noSessionRequired(function signupfb() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    window.location.href = App.webAppUrl;
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            forgotPassword: noSessionRequired(function forgotPassword() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    window.location.href = App.webAppUrl;
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            passwordInstructions: noSessionRequired(function passwordInstructions() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    App.mainRegion.show(new PasswordInstructionsSent());
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            changePassword: noSessionRequired(function changePassword(skey) {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    App.mainRegion.show(new ForgotPasswordChange({ skey: skey }));
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            passwordChangeError: noSessionRequired(function passwordChangeError() {
                // check if user is authenticated
                if (!App.os.isLoggedIn()) {
                    App.mainRegion.show(new PasswordChangeError());
                } else {
                    App.appRouter.navigate("", true);
                }
            }),

            screenMap: function () {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'screenMap');
                layout.layoutMain.show(new ScreenPlanning());
            },

            submittedOffers: function () {
                if (App.currentUser.hasRole(App.os.roles.approver)) {
                    var me = this,
                        layout = this._setupApplicationLayout();

                    App.vent.trigger('App.layout.changeView', 'submittedOffers');

                    // switch user context
                    App.userContext = App.os.ROLE_CONTEXT.APPROVER;

                    layout.layoutMain.show(new OffersNG());
                } else {
                    App.appRouter.navigate("", true);
                }
            },

            offersNg: function(companyId) {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'offers');

                // switch user context
                App.userContext = App.os.ROLE_CONTEXT.RETAILER;

                // App.currentDepartment.getOffers({fetch: false});
                layout.layoutMain.show(new OffersNG());
            },

            offers: function (companyId) {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'offers');

                // switch user context
                App.userContext = App.os.ROLE_CONTEXT.RETAILER;

                layout.layoutMain.show(new OffersNG());
            },

            offer: function (companyId, offerId, advanced) {
                var me = this,
                    layout = this._setupApplicationLayout(),
                    offers = App.userContext === App.os.ROLE_CONTEXT.RETAILER ? App.currentDepartment.getOffers({fetch: false}) : App.currentCompany.getSubmittedOffers({fetch: false, searchUri: '/data/search/offers/submitted'}),
                    offer;
                if(advanced=='advanced') window.open_advanced_campaign = true;

                App.vent.trigger('App.layout.changeView', App.userContext === App.os.ROLE_CONTEXT.RETAILER ? 'offers' : 'submittedOffers');
                $.when(offers.promise)
                    .then(function () {
                        // redirect to specific offer or come back to offers list
                        offer = offers.get([companyId, offerId]);
                        if (!_.isUndefined(offer)) {
                            App.currentOffer = offer;
                            if (App.currentUser.hasRole(App.os.roles.newOfferScreen)) {
                                layout.layoutMain.show(new OfferNG({model: offer}));
                            } else {
                                layout.layoutMain.show(new Offer({model: offer}));
                            }
                        } else {
                            offer = new OfferModel();
                            offer.url = offer.generateUri([companyId, offerId]);
                            $.when(offer.fetch()).then(function (){
                                if (App.currentUser.hasRole(App.os.roles.newOfferScreen)) {
                                    layout.layoutMain.show(new OfferNG({model: offer}));
                                } else {
                                    layout.layoutMain.show(new Offer({model: offer}));
                                }
                            });

                        }
                    });
            },

            profile: function (profileTab) {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'profile');
                layout.layoutMain.show(new ProfileLayout({ profileTab: !_.isUndefined(profileTab) ? profileTab : 'password-change' }));
            },

            dashboard: function (popup) {
                var me = this;
                 var layout = this._setupApplicationLayout();
                window.popup=popup;
                App.vent.trigger('App.layout.changeView', 'dashboard');

                if(window.popup=='creative-spec'){
                     layout.layoutMain.show(new CreativeSpec());
                } else {
                   
                $.when(App.currentDepartmentPromise)
                    .then(function onUserDepartmentLoaded () {
                        layout.layoutMain.show(new Dashboard());
                    });
                }
            },

            audienceDashboard: function (groupId) {
                var me = this,
                    layout = this._setupApplicationLayout();

                if (!App.currentUser.hasRole(App.os.roles.audienceDashboardAccess)) {
                    me.campaignsDashboard();
                } else {
                    $.when(App.currentDepartmentPromise).then(function () {
                        $.when(App.currentDepartment.getDisplayGroups().promise).then(function () {
                            var displayGroup = groupId ? App.currentDepartment.getDisplayGroups().getById(groupId) : App.currentDepartment.getDisplayGroups().at(0);
                            layout.layoutMain.show(new Dashboard({displayGroup: displayGroup}));
                            App.vent.trigger('App.layout.changeView', 'dashboard/' + displayGroup.get('name'));
                        })
                    });
                }
            },

            campaignsDashboard: function() {
                var me = this;
                var layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'campaigns-dashboard');

                $.when(App.currentDepartmentPromise)
                    .then(function onUserDepartmentLoaded () {
                        layout.layoutMain.show(new DashboardNew());
                    });
            },

            newCampaign: function () {
                var me = this;

                var layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'new-campaign');

                $.when(App.currentDepartmentPromise)
                    .then(function onUserDepartmentLoaded () {
                        layout.layoutMain.show(new CreateCampaign());
                    });
            },

            creative: function () {
                var me = this;
                 var layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'creative-spec');


                $.when(App.currentDepartmentPromise)
                    .then(function onUserDepartmentLoaded () {
                        layout.layoutMain.show(new CreativeSpec());
                    });

            },

            notifications: function () {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'notifications');
                layout.layoutMain.show(new PublicTransportDisruptions());
            },

            notificationsHistory: function () {
                var me = this,
                    layout = this._setupApplicationLayout();

                App.vent.trigger('App.layout.changeView', 'notifications');
                layout.layoutMain.show(new PublicTransportDisruptionsHistory());
            },

            defaultRoute: function () {
                this.dashboard();
            },

            /* ADIMIN METHODS */

            screens: function () {
                // verify admin permissions
                if (App.currentUser.hasRole(App.os.roles.superUser) || App.currentUser.hasRole(App.os.roles.myPlayers)) {
                    var me = this,
                        layout = this._setupApplicationLayout();

                    App.vent.trigger('App.layout.changeView', 'screens');
                    layout.layoutMain.show(new Displays());
                } else {
                    App.appRouter.navigate("", true);
                }
            },

            retailers: function () {
                // verify admin permissions
                if (App.currentUser.hasRole(App.os.roles.userAdmin)) {
                    var me = this,
                        layout = this._setupApplicationLayout();

                    App.vent.trigger('App.layout.changeView', 'retailers');
                    layout.layoutMain.show(new RetailersLayout());
                } else {
                    App.appRouter.navigate("", true);
                }
            },

            loopManagement: function (displayGroupId) {
                var me = this,
                    layout = this._setupApplicationLayout();
                App.appRouter.navigate('loop-management');
                App.vent.trigger('App.layout.changeView', 'loopManagement');
                if (App.currentUser.hasRole(App.os.roles.newLoopManagement)) {
                    layout.layoutMain.show(new NGLoopManagement());
                } else {
                    layout.layoutMain.show(new DisplayGroupsSelector());
                }
            },

            reports: function () {
                if (App.currentUser.hasRole(App.os.roles.reportsViewer) || App.currentUser.hasRole(App.os.roles.superUser)) {

                    var layout = this._setupApplicationLayout();

                    // Reports available only in MULTI subscription PLAN
                    if ($.inArray(App.currentCompany.getActivePlan(), ['DEMO']) === -1) {
                        App.vent.trigger('App.layout.changeView', 'reports');
                        layout.layoutMain.show(new ReportsLayout());
                    } else {
                        App.appRouter.navigate("", true);
                    }
                } else {
                    App.appRouter.navigate("", true);
                }
            },

            internalReports: function () {
                if (App.currentUser.hasRole(App.os.roles.userAdmin)) {
                    var me = this,
                        layout = this._setupApplicationLayout();

                    App.vent.trigger('App.layout.changeView', 'internalReports');
                    layout.layoutMain.show(new InternalReportsLayout());
                } else {
                    App.appRouter.navigate("", true);
                }
            },

            displayGroups: function() {
                if (App.currentUser.hasRole(App.os.roles.userAdmin)) {
                    var me = this,
                        layout = this._setupApplicationLayout();

                    App.vent.trigger('App.layout.changeView', 'displayGroups');
                    layout.layoutMain.show(new DisplayGroups());
                } else {
                    App.appRouter.navigate("", true);
                }
            }
        });
    });

