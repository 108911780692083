// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Profile/ProfileLocationSettings',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Profile/ProfileLocationSettings', 'flagstrap'],
    function (App, Backbone, _, $, template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'form-panel profile-form row no-background',

            onRender: function () {

                this.srcReplacer();
            },

            receiveMessage: function(event)
            {
                var me = this,
                    a = document.getElementById('ng-iframe').contentWindow;
                a.postMessage(App.currentCompany.attributes[event.data], window.appUrl + '/ng/dist/index.html');
            },

            srcReplacer: function(){
                var me = this;

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        $('#ng-iframe').attr('src', window.appUrl + '/ng/dist/index.html?tab=location');

                        // waits for requests for window.App sending to iframe
                        window.addEventListener("message", me.receiveMessage, false);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }
        });
    });

