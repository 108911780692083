// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Offers/CreateOffer',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Offers/CreateOffer', 'collections/Offers',
        'bootstrap-dialog',
        'ladda-bootstrap',
        'behaviors/Intercom'],
    function (App, Backbone, _, $, template, Projects, BootstrapDialog, Ladda) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal old-style',

            ui: {
                'offerName': 'input[name="offerName"]'
            },

            events: {
                'click .create-btn': 'createOffer'
            },

            checkButton: function () {
                var me = this;
                $.when(App.currentUser.getPermissions().promise).then(function () {
                    var permissions = App.currentUser.getPermissions();
                    var buttons = me.$el.find('.btn-group label');
                    buttons.each(function (i, element) {
                        var elPermission = $(element).attr('data-permission');
                        if (permissions.get(elPermission) == 'active') {
                            $(element).addClass('active');
                            $(element).find('input').attr('checked', true);
                            return false;
                        }
                    })
                });
            },

            onRender: function () {
                var me = this;

                // manage special offers types for external consumers
                _.each(App.os.EXTERNAL_CONSUMER_TYPE, function (extConsumer) {
                    if (!App.currentUser.getDisplayGroups().hasExternalConsumerGroup(extConsumer)) {
                        me.$el.find('input[data-consumer="' + extConsumer + '"]').parent().remove();
                    }
                });
                me.checkButton();
            },

            onShow: function () {
                var me = this;
                setTimeout(function () {
                    if (!me.isDestroyed) {
                        me.ui.offerName.focus();
                    }
                }, 200);
            },

            createOffer: function (event) {
                var me = this,
                    newOffer,
                    newOfferData,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    fromTemplate = false,
                    offerType = me.$el.find('input[name="offerType"]:checked').val() || 'image',
                    extConsumer = me.$el.find('input[name="offerType"]:checked').data('consumer') || '';

                if (_.isEmpty(me.ui.offerName.val())) {
                    App.os.showFieldError('input[name="offerName"]', App.trans('Please enter campaign name'));
                    return;
                }

                l.start();

                this.ui.offerName.prop('disabled', true);
                this.$el.find('button').addClass('disabled');

                newOfferData = {
                    'name': me.ui.offerName.val(),
                    'source_template': (offerType === 'image' ? defaultOfferImageTemplate : (offerType === 'video' ? defaultOfferVideoTemplate : null)),
                    'offer_type': offerType,
                    'offer_variant': extConsumer
                };

                newOffer = App.currentDepartment.getOffers({fetch: false}).create(newOfferData, {
                    at: 0,
                    wait: true,
                    success: function () {
                        intercomReportEvent('offer_created', {
                            data: JSON.stringify(newOfferData),
                            timestamp: (new Date()).toString()
                        });

                        App.os.setLocalStorageKey('CREATIVE_DONE', true);
                        if (me.greetings) me.greetings.close();
                        App.appRouter.navigate(newOffer.get('uri'), {trigger: true});
                        App.mainRegion.currentView.modalRegion.currentView.destroy();
                    },
                    error: function () {
                        me.destroy();
                        BootstrapDialog.show({
                            type: BootstrapDialog.TYPE_DANGER,
                            title: App.trans('Houston! We have a problem!'),
                            message: App.trans("Unfortunatelly an error occurred while creating your new offer.<br><br>Try again."),
                            buttons: [{
                                label: App.trans("Close"),
                                action: function (dialog) {
                                    dialog.close();
                                }
                            }]
                        });
                    }
                });
            }

        });
    });
