// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Reports/Overview',['App',
        'backbone',
        'hbs!templates/views/Reports/Overview',
        'views/Reports/OverviewReport',
        'hbs!templates/views/Reports/UnderOverviewList',
        'hbs!templates/views/Reports/OverviewHeader',
        'bootstrap-dialog'
        ],
    function (
        App,
        Backbone,
        template,
        OverviewReport,
        UnderOverviewList,
        OverviewHeader
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: OverviewReport,

            className: '',

            childViewContainer: 'ul',

            events: {
                'click .print-invoice-btn-lower': '_downloadInvoice',
                'click .compare-btn-lower': '_compare',
                'click #all-campaigns a': 'allCampaigns',
                'click #all-companies a': 'allCompanies',
                'click #selected-range a': 'selectedRange',
                'click #show-10-records a': 'show10Records',
                'click #sort-campaign-name': 'sortCampaignsByCampaignName',
                'click #sort-status': 'sortCampaignsByStatus',
                'click #sort-company-name': 'sortCampaignsByCompanyName',
                'click #sort-format': 'sortCampaignsByFormat',
                'click #sort-start-date': 'sortCampaignsDateStart',
                'click #sort-end-date': 'sortCampaignsDateEnd',
                'click #sort-impressions': 'sortOverviewImpressions',
                'click #sort-impact': 'sortOverviewImpact',
                'click #sort-CPV': 'sortCampaignsCPV',
                'click #sort-daily-spend': 'sortCampaignsByDailySpend',
                'keyup #type-to-search': 'searchFilter'
            },

            allCampaigns: function (event) {
                event.preventDefault();
                $('#all-campaigns').children('button').children('label').html( $(event.currentTarget).html() );
            },
            allCompanies: function (event) {
                event.preventDefault();
                $('#all-companies').children('button').children('label').html( $(event.currentTarget).html() );
            },
            selectedRange: function (event) {
                event.preventDefault();
                var me = this,
                    since,
                    to;
                var clickedText = $(event.currentTarget).html();
                $('#selected-range').children('button').children('label').html( clickedText );

                var getMonday = function (d) {
                    d = new Date(d);
                    var day = d.getDay(),
                        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
                    return new Date(d.setDate(diff));
                };

                $('.date-selector').css('display', 'none');

                if( clickedText === 'This week' ) {
                    since = getMonday( new Date() );
                    to = new Date(since);
                    to.setDate(to.getDate() + 6);
                }
                else if( clickedText === 'Last week' ) {
                    since = getMonday( new Date() );
                    to = new Date(since);
                    since.setDate(since.getDate() - 7);
                    to.setDate(to.getDate() - 1);
                }
                else if( clickedText === 'This month' ) {
                    since = new Date();
                    since.setDate(1);
                    to = new Date(since);
                    to.setMonth(to.getMonth()+1);
                    to.setDate(to.getDate()-1);
                }
                else if( clickedText === 'Last month' ) {
                    since = new Date();
                    since.setDate(1);
                    since.setMonth(since.getMonth() - 1);
                    to = new Date(since);
                    to.setMonth(to.getMonth()+1);
                    to.setDate(to.getDate()-1);
                }

                window.filterInputData.since = since;
                window.filterInputData.to = to;

                if( clickedText === 'Selected range' ) {
                    $('.date-selector').css('display', '');
                    $('.date-selector input').map(function(){
                        this.value = '';
                    });
                }

                me.filterList();
            },
            show10Records: function (event) {
                event.preventDefault();
                var clickedText = $(event.currentTarget).html();
                $('#show-10-records').find('label').html(clickedText);

                this.filterList( parseInt(clickedText) );
            },

            returnSortValue: function (keyA, keyB, direction) {
                if (keyA < keyB) return -1 * direction;
                if (keyA > keyB) return 1 * direction;
                return 0;
            },
            sortCampaignsByCampaignName: function () {

                var me = this,
                    direction = 'campaignSort',
                    arrow = $('.report-header').children('.overview-campaign-name').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".overview-campaign-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".overview-campaign-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsByStatus: function () {

                var me = this,
                    direction = 'statusSort',
                    arrow = $('.report-header').children('.overview-status').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".overview-status").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".overview-status").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsByCompanyName: function () {
                var me = this,
                    direction = 'companySort',
                    arrow = $('.report-header').children('.overview-company-name').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".overview-company-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".overview-company-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsByFormat: function () {
                var me = this,
                    direction = 'formatSort',
                    arrow = $('.report-header').children('.overview-format').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".overview-format").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".overview-format").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsDateStart: function () {
                var me = this,
                    direction = 'startSort',
                    arrow = $('.report-header').children('.overview-start-date').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = new Date( $(a).children(".overview-start-date").find('.timestamp_from-date').html() );
                    var keyB = new Date( $(b).children(".overview-start-date").find('.timestamp_from-date').html() );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsDateEnd: function () {
                var me = this,
                    direction = 'endSort',
                    arrow = $('.report-header').children('.overview-end-date').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = new Date( $(a).children(".overview-end-date").find('.timestamp_to-date').html() );
                    var keyB = new Date( $(b).children(".overview-end-date").find('.timestamp_to-date').html() );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortOverviewImpressions: function () {

                var me = this,
                    direction = 'impressionsSort',
                    arrow = $('.report-header').children('.overview-impressions').children('span').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = parseFloat( $(a).children(".overview-impressions").find('span').html().replace(',', '.').replace(/\./g, '') );
                    var keyB = parseFloat( $(b).children(".overview-impressions").find('span').html().replace(',', '.').replace(/\./g, '') );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortOverviewImpact: function () {

                var me = this,
                    direction = 'impactSort',
                    arrow = $('.report-header').children('.overview-impact').children('span').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = parseFloat( $(a).children(".overview-impact").find('span').html().replace(',', '.').replace(/\./g, '') );
                    var keyB = parseFloat( $(b).children(".overview-impact").find('span').html().replace(',', '.').replace(/\./g, '') );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsCPV: function () {

                var me = this,
                    direction = 'CPVSort',
                    arrow = $('.report-header').children('.overview-CPV').children('span').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = parseFloat( $(a).children(".overview-CPV").find('label').html().replace(',', '.') );
                    var keyB = parseFloat( $(b).children(".overview-CPV").find('label').html().replace(',', '.') );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            sortCampaignsByDailySpend: function () {

                var me = this,
                    direction = 'campaignSort',
                    arrow = $('.report-header').children('.overview-daily-spend').children('span').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = parseFloat( $(a).children(".overview-daily-spend").find('label').html().replace(',', '.') );
                    var keyB = parseFloat( $(b).children(".overview-daily-spend").find('label').html().replace(',', '.') );

                    return me.returnSortValue(keyA, keyB, me[direction]);
                });

                me.fitToNewOrder(elements, arrow, direction);
            },
            fitToNewOrder: function (elements, arrow, sortDirection) {
                this[sortDirection] *= -1;

                var ul = $('.reports-list'),
                    sortDirection = this[sortDirection];

                $.each(elements, function(i, li){
                    ul.append(li); /* This removes li from the old spot and moves it */
                });
                ul.find('.fa-angle-up, .fa-angle-down').css('color', 'grey');
                arrow.css('color', '#104F82');

                if(sortDirection > 0)
                    arrow.removeClass('fa fa-angle-down').addClass('fa fa-angle-up');
                else
                    arrow.removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
            },

            searchFilter: function () {

                window.filterInputData.name = $("#type-to-search")[0].value.toUpperCase();

                this.filterList();
            },

            _downloadInvoice: function (event) {

                var all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(len>0)
                    for(var i=1; i<len; ++i){
                        if(all_checkboxes[i].checked)
                            all_checkboxes[i].parentElement.parentElement.getElementsByClassName("toolbox")[0].children[0].click();
                    }
            },

            _compare: function () {

                // TODO    comparation
                // var all_checkboxes = $('[name="check_report"]'),
                //     len = all_checkboxes.length;
                //
                // if(len>0)
                //     for(var i=1; i<len; ++i){
                //         if(all_checkboxes[i].checked)
                //             all_checkboxes[i].parentElement.parentElement.getElementsByClassName("toolbox")[0].children[1].click();
                //     }
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>';

                        return Handlebars.compile(templateHtml);
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                this.collection = App.currentCompany.getReports({fetch: false});
                this.campaignSort = 1;
                this.statusSort = 1;
                this.companySort = 1;
                this.formatSort = 1;
                this.startSort = 1;
                this.endSort = 1;
                this.impressionsSort = 1;
                this.impactSort = 1;
                this.CPVSort = 1;
                this.dailySpendSort = 1;

                window.filterInputData = {
                    since: undefined,
                    to: undefined,
                    name: undefined
                };


                var from = new Date();
                from.setDate(1);
                var to = new Date();
                to.setMonth(to.getMonth()+1);
                to.setDate(1);
                to.setDate(to.getDate() - 1);


                this.query = {
                    "timestamp_from": from.toLocaleDateString().replace(/[//]/g, "-"),
                    "timestamp_to": to.toLocaleDateString().replace(/[//]/g, "-")
                };
            },

            filterList: function (show) {
                var li, listElementTitle, since, to;
                li = $(".reports-list").find("li");

                if(show === undefined || isNaN(show))
                    show = li.length;

                if(window.filterInputData.name === undefined)    window.filterInputData.name = '';

                for (var i = 1; i < li.length; i++) {
                    listElementTitle = $(li[i]).children('.overview-campaign-name').html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    since = new Date( $(li[i]).children('.overview-start-date').find('label').html() );
                    to = new Date( $(li[i]).children('.overview-end-date').find('label').html() );
                    if(window.filterInputData.since === undefined)    window.filterInputData.since = since;
                    if(window.filterInputData.to === undefined)    window.filterInputData.to = to;

                    if (listElementTitle.indexOf(window.filterInputData.name) > -1 &&
                        (since >= window.filterInputData.since && since <= window.filterInputData.to ||
                        to >= window.filterInputData.since && to <= window.filterInputData.to) &&
                        i <= show) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            onRender: function () {
                var me = this;

                if (me.collection.url.indexOf(App.apiUrl) === -1) {
                    me.collection.url = App.enrichApiUrl(me.collection.url);
                }
                var extendedReports = me.collection.url.replace('reports', 'extended-reports');
                // App.enrichApiUrl( extendedReports + '?query=' + encodeURIComponent(JSON.stringify(me.query)) );

                $.ajax({
                    url: App.enrichApiUrl( extendedReports + '?query=' + encodeURIComponent(JSON.stringify(me.query)) ),
                    dataType: "json",

                    success: function (data) {
                        console.log(data);

                        $('#no-of-locations').find('h2').html(data.num_of_displays);
                        $('#no-of-plays').find('h2').html(data.num_of_plays);
                        $('#impact').find('h2').html();
                        $('#total-spend').find('span').html(data.total_spend_credits);
                        $('#average-cpv').find('span').html(data.average_cost_per_play);
                    },
                    error: function (data) {
                        console.log(data);
                        //alert(data);
                    }
                });

                $.when(me.collection.fetch())
                    .then(function() {
                        if (_.size(me.collection) === 0) {
                            me.$el.find('.no-items').html('<i class="fa fa-book"></i> You have no reports');
                        }
                        else {
                            if( !$('.report.report-header').length ) {
                                $('#reports-panel').find('ul').not('.dropdown-menu').prepend(OverviewHeader);
                                $('.under-reports-list').append(UnderOverviewList);

                                $( function() {
                                    $( "#datepicker-from" ).datepicker({
                                        format: 'yyyy/mm/dd'
                                    })
                                        .on('changeDate', function (selected) {
                                            var minDate = new Date(selected.date.valueOf()),
                                                endDate = new Date( $('#datepicker-to')[0].value );

                                            window.filterInputData.since = minDate;

                                            if( endDate < minDate) {
                                                $('#datepicker-to').datepicker('setDate', minDate);
                                                window.filterInputData.to = minDate;
                                            }

                                            $('#datepicker-to').datepicker('setStartDate', minDate);
                                            me.filterList();
                                        });

                                    $( "#datepicker-to" ).datepicker({
                                        format: 'yyyy/mm/dd'
                                    })
                                        .on('changeDate', function (selected) {

                                            window.filterInputData.to = new Date(selected.date.valueOf());
                                            me.filterList();
                                        });
                                });
                            }
                        }
                    });
            }

        });
    });
