// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/offerVariant',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function offerVariant() {
        var options = arguments[arguments.length - 1],
            offerVariant = [].shift.apply(arguments),
            variants = [];

        _.each(arguments, function (el) { _.isString(el) && variants.push(el); });

        if (($.inArray(offerVariant, variants)) > -1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }


    }
    Handlebars.registerHelper('offerVariant', offerVariant);
    return offerVariant;
});
