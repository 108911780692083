// -*- coding: utf-8 -*-

/*global define,appurl*/
define('collections/PublicTransportDisruptions',["backbone", "models/PublicTransportDisruption"],
    function (Backbone, PublicTransportDisruption) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var PublicTransportDisruptions = Backbone.Collection.extend({
            model: PublicTransportDisruption,

            comparator : function(item1, item2) {
                var item1severity = item1.get('status_severity'),
                    item2severity = item2.get('status_severity'),
                    item1created = item1.get('created'),
                    item2created = item2.get('created');

                if (item1severity > item2severity) {
                    return 1;
                } else if (item1severity < item2severity) {
                    return -1;
                } else {
                    return (item1created > item2created ? -1 : 1);
                }
            }
        });

        return PublicTransportDisruptions;        
    });
