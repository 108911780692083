// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/VisitsWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/VisitsWidget',
         'collections/Demographics',
         'views/Dashboard/WidgetBase',
         'amcharts.serial',
         'bootstrap-select'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template,
             Demographics,
             _WidgetBase) {

        'use strict';
        var WidgetBase = _WidgetBase.prototype;
        return _WidgetBase.extend({

            template: template,

            templateHelpers: function() {
                return {
                    'chartId': this.chartId,
                    'title': this.title,
                    'availableGranularities': this.availableGranularities
                };
            },

            camera_stubData: [
                {
                    'from': '2019-02-01T00:00:00.000000',
                    'to': '2019-02-01T01:00:00.000000',
                    'visits': 0,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T01:00:00.000000',
                    'to': '2019-02-01T02:00:00.000000',
                    'visits': 0,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T02:00:00.000000',
                    'to': '2019-02-01T03:00:00.000000',
                    'visits': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T03:00:00.000000',
                    'to': '2019-02-01T04:00:00.000000',
                    'visits': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T04:00:00.000000',
                    'to': '2019-02-01T05:00:00.000000',
                    'visits': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T05:00:00.000000',
                    'to': '2019-02-01T06:00:00.000000',
                    'visits': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T06:00:00.000000',
                    'to': '2019-02-01T07:00:00.000000',
                    'visits': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T07:00:00.000000',
                    'to': '2019-02-01T08:00:00.000000',
                    'visits': 200,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T08:00:00.000000',
                    'to': '2019-02-01T09:00:00.000000',
                    'visits': 500,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T09:00:00.000000',
                    'to': '2019-02-01T10:00:00.000000',
                    'visits': 700,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T10:00:00.000000',
                    'to': '2019-02-01T11:00:00.000000',
                    'visits': 1000,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T11:00:00.000000',
                    'to': '2019-02-01T12:00:00.000000',
                    'visits': 2300,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T12:00:00.000000',
                    'to': '2019-02-01T13:00:00.000000',
                    'visits': 2500,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T13:00:00.000000',
                    'to': '2019-02-01T14:00:00.000000',
                    'visits': 1200,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T14:00:00.000000',
                    'to': '2019-02-01T15:00:00.000000',
                    'visits': 1600,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T15:00:00.000000',
                    'to': '2019-02-01T16:00:00.000000',
                    'visits': 2000,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T16:00:00.000000',
                    'to': '2019-02-01T17:00:00.000000',
                    'visits': 1900,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T17:00:00.000000',
                    'to': '2019-02-01T18:00:00.000000',
                    'visits': 2500,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T18:00:00.000000',
                    'to': '2019-02-01T19:00:00.000000',
                    'visits': 1200,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T19:00:00.000000',
                    'to': '2019-02-01T20:00:00.000000',
                    'visits': 800,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T20:00:00.000000',
                    'to': '2019-02-01T21:00:00.000000',
                    'visits': 400,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T21:00:00.000000',
                    'to': '2019-02-01T22:00:00.000000',
                    'visits': 260,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T22:00:00.000000',
                    'to': '2019-02-01T15:23:00.000000',
                    'visits': 160,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T23:00:00.000000',
                    'to': '2019-02-01T24:00:00.000000',
                    'visits': 15,
                    'viewing': 0
                }

            ],
            
            wifi_stubData: [
                {
                    'from': '2019-02-01T00:00:00.000000',
                    'to': '2019-02-01T01:00:00.000000',
                    'passers_by': 0,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T01:00:00.000000',
                    'to': '2019-02-01T02:00:00.000000',
                    'passers_by': 0,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T02:00:00.000000',
                    'to': '2019-02-01T03:00:00.000000',
                    'passers_by': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T03:00:00.000000',
                    'to': '2019-02-01T04:00:00.000000',
                    'passers_by': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T04:00:00.000000',
                    'to': '2019-02-01T05:00:00.000000',
                    'passers_by': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T05:00:00.000000',
                    'to': '2019-02-01T06:00:00.000000',
                    'passers_by': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T06:00:00.000000',
                    'to': '2019-02-01T07:00:00.000000',
                    'passers_by': 40,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T07:00:00.000000',
                    'to': '2019-02-01T08:00:00.000000',
                    'passers_by': 200,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T08:00:00.000000',
                    'to': '2019-02-01T09:00:00.000000',
                    'passers_by': 500,
                    'viewing': 34
                },
                {
                    'from': '2019-02-01T09:00:00.000000',
                    'to': '2019-02-01T10:00:00.000000',
                    'passers_by': 700,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T10:00:00.000000',
                    'to': '2019-02-01T11:00:00.000000',
                    'passers_by': 1000,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T11:00:00.000000',
                    'to': '2019-02-01T12:00:00.000000',
                    'passers_by': 2300,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T12:00:00.000000',
                    'to': '2019-02-01T13:00:00.000000',
                    'passers_by': 2500,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T13:00:00.000000',
                    'to': '2019-02-01T14:00:00.000000',
                    'passers_by': 1200,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T14:00:00.000000',
                    'to': '2019-02-01T15:00:00.000000',
                    'passers_by': 1600,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T15:00:00.000000',
                    'to': '2019-02-01T16:00:00.000000',
                    'passers_by': 2000,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T16:00:00.000000',
                    'to': '2019-02-01T17:00:00.000000',
                    'passers_by': 1900,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T17:00:00.000000',
                    'to': '2019-02-01T18:00:00.000000',
                    'passers_by': 2500,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T18:00:00.000000',
                    'to': '2019-02-01T19:00:00.000000',
                    'passers_by': 1200,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T19:00:00.000000',
                    'to': '2019-02-01T20:00:00.000000',
                    'passers_by': 800,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T20:00:00.000000',
                    'to': '2019-02-01T21:00:00.000000',
                    'passers_by': 400,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T21:00:00.000000',
                    'to': '2019-02-01T22:00:00.000000',
                    'passers_by': 260,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T22:00:00.000000',
                    'to': '2019-02-01T15:23:00.000000',
                    'passers_by': 160,
                    'viewing': 0
                },
                {
                    'from': '2019-02-01T23:00:00.000000',
                    'to': '2019-02-01T24:00:00.000000',
                    'passers_by': 15,
                    'viewing': 0
                }

            ],

            className: 'white-panel pn chart-area widget widget--footfall',
            widgetName: 'VisitsWidget',
            widgetType: 'visits',

            granularity: 'hour',

            ui: {
                chartTitle: '.chart-title',
                chartCanvas: '.chart-canvas',
                granularitySelector: '#visits-granularity-filter'
            },

            events: {
                'change @ui.granularitySelector': '_fetchData'
            },

            initialize: function (options) {
                var me = this;
                me.data = [];
                me.title = options && options.title || 'Chart Title';
                me.widgetType = options && options.type || me.widgetType;
                me.availableGranularities = options && options.availableGranularities || [
                        { val: 'day', title: 'Day' },
                        { val: 'hour', title: 'Hour' },
                        { val: '5m', title: '5 minutes' }
                    ];

                me.chartId = me.title + '-chart';
                me.mapResult = options.mapResult;
                me.category = options.category;
                me.graphs = options.graphs;

                me.listenTo(App.vent, 'App.dashboard.currentSensorGroup.change', _.bind(me._onSensorGroupChange, me));

                return WidgetBase.initialize.apply(me, arguments);
            },

            onRender: function () {
                var me = this;

                $.when(App.currentDepartment.getDisplayGroups().promise).then(function onDisplayGroupsReady () {
                    if (!me.isDestroyed) {
                        if (!_.isNull(App.currentSensorGroup)) {
                            if (!me._demographics) {
                                _.defer(function () { me._fetchData(); });
                            }
                        } else {
                            me.$el.find('.widget__loader').hide();
                            me.$el.addClass('widget--no-camera');
                        }
                    }
                });

                me.$el.find('.selectpicker').selectpicker();
                _.defer(function () {
                    me.$el.find('.selectpicker').selectpicker('val', me.granularity);
                });
            },

            adjustCanvasSize: function () {
                var me = this;
                me.ui.chartCanvas.attr('width', me.$el.width() + 'px');
                me.ui.chartCanvas.attr('height', '210px');
                me.ui.chartCanvas.css({
                    width: me.$el.width() + 'px',
                    height: '210px'
                });
            },

            onDestroy: function () {
                var me = this;
                $(window).off('.chartWidget');
            },

            _onSensorGroupChange: function () {
                var me = this;
                me._demographics = new Demographics({fillGaps: true, granularity: me.granularity});
                !_.isNull(App.currentSensorGroup) && (me._demographics.url = App.currentSensorGroup.get('uris').Demographics);
                me._fetchData();
            },

            _fetchData: function () {
                var me = this;

                if (_.isNull(App.currentSensorGroup)) {
                    me.$el.find('.widget__loader').hide();
                    me.$el.find('.widget__body--no-camera');
                    me.$el.addClass('widget--no-camera');
                    return;
                }

                // update granularity from selector if any (Tofify data only available on daily granularity)
                // me.granularity = me.ui.granularitySelector.val() || 'hour';
                me.granularity = 'hour';

                // if (!_.isNull(App.currentSensorGroup) && !me._demographics) {
                //     me._demographics = new Demographics({fillGaps: false, granularity: me.granularity});
                //     me._demographics.url = App.currentSensorGroup.get('uris').Demographics;
                // }

                me._demographics = new Demographics({fillGaps: false, granularity: me.granularity});

                // request data
                me.$el.removeClass('widget--no-camera widget--no-data');
                me.$el.find('.widget__loader').show();
                me._demographics.granularity = me.granularity;
                //me._demographics.url = App.enrichApiUrl(App.currentSensorGroup.get('uris').Demographics);

                me._demographics.reset(me.widgetType === 'wifi' ? me.wifi_stubData : me.camera_stubData);


                me._demographics.granularity = me.granularity;
                me.data = me._demographics.map(me.mapResult);

                console.log('Footfall', me.data);
                console.log(me.granularity);

                me._update();

                // $.when(me._demographics.fetch({
                //     data: {
                //         type: me.widgetType,
                //         from: moment(App.currentSensorGroupFilter.get('from')).format('YYYY-MM-DDTHH:mm:ss'),
                //         to: moment(App.currentSensorGroupFilter.get('to')).format('YYYY-MM-DDTHH:mm:ss'),
                //         granularity: me.granularity,
                //         detail: 1
                //     }})).then(function widgetDataLoaded () {
                //     if (_.size(me._demographics) > 0) {
                //             me.data = me._demographics.map(me.mapResult);
                //
                //             if (!me.isDestroyed) {
                //                 me._update();
                //             }
                //         } else {
                //             me.$el.find('.widget__loader').hide();
                //             me.$el.find('.widget__body--no-camera').html('Data not available');
                //             me.$el.addClass('widget--no-camera widget--no-data');
                //         }
                //     }, function widgetDataLoadingError () {
                //             me.$el.find('.widget__loader').hide();
                //             me.$el.find('.widget__body--no-camera').html('Data not available');
                //             me.$el.addClass('widget--no-camera');
                //         });
            },

            _update: function () {
                var me = this;

                var graphs_template = [{
                    "id": "g2",
                    "title": undefined,
                    "fillAlphas": 0.4,
                    "lineColor": "#016eaf",
                    "fillColors": "#06b8e4",
                    "valueField": undefined,
                    "balloonText": ["<div style='margin:5px; font-size:12px;'>", ":<b>[[value]]</b></div>"],
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }, {
                    "id": "g1",
                    "title": undefined,
                    "fillAlphas": 0.4,
                    "lineColor": "#058282",
                    "fillColors": "#82FAFA",
                    "valueField": undefined,
                    "balloonText": ["<div style='margin:5px; font-size:12px;'>", ":<b>[[value]]</b></div>"],
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }, {
                    "id": "g3",
                    "title": undefined,
                    "fillAlphas": 0.4,
                    "lineColor": "#918BC9",
                    "fillColors": "#918BC9",
                    "valueField": undefined,
                    "balloonText":["<div style='margin:5px; font-size:12px;'>", ":<b>[[value]]</b></div>"],
                    "lineThickness": 2,
                    "type": "smoothedLine"
                }];

                var graphs = [];
                for (var i = 0; i < me.graphs.length; ++i) {
                    graphs_template[i].title = me.graphs[i].name;
                    graphs_template[i].valueField = me.graphs[i].column;
                    var schema = graphs_template[i].balloonText;
                    graphs_template[i].balloonText = schema[0] + me.graphs[i].balloon + schema[1];
                    graphs.push(graphs_template[i]);
                }

                me.chart = AmCharts.makeChart(me.chartId, {
                        "type": "serial",
                        "theme": "light",
                        "path": "/js/libs/amcharts/",
                        "dataProvider": me.data,

                        "fontFamily": "Ruda",
                        "fontSize:": "11",

                        "valueAxes": [{
                            "position": "left",
                            "title": "Crowd Level"
                        }],
                        "graphs": graphs,

                        "responsive": {
                            "enabled": true
                        },

                        "chartScrollbar": {
                            "graph": "g1",
                            "scrollbarHeight": 40,
                            "backgroundAlpha": 0,
                            "selectedBackgroundAlpha": 0.1,
                            "selectedBackgroundColor": "#888888",
                            "graphFillAlpha": 0,
                            "graphLineAlpha": 0.5,
                            "selectedGraphFillAlpha": 0,
                            "selectedGraphLineAlpha": 1,
                            "autoGridCount": true,
                            "color": "#AAAAAA"
                        },
                        "chartCursor": {
                            "categoryBalloonDateFormat": "EEEE, YYYY-MM-DD, JJ:NN", //"YYYY-MM-DD", // hard to find, http://www.amcharts.com/tutorials/formatting-dates/
                            "cursorPosition": "mouse"
                        },
                        "categoryField": me.category,
                        "categoryAxis": {
                            "minPeriod": me.granularity === 'day' ? "1DD" : me.granularity === 'hour' ? "1hh" : "5mm",
                            "parseDates": true,
                            "dateFormats": [
                                {period:'fff',format:'JJ:NN:SS'},
                                {period:'ss',format:'JJ:NN:SS'},
                                {period:'mm',format:'JJ:NN'},
                                {period:'hh',format:'JJ:NN'},
                                {period:'DD',format:'EEE JJ:NN'},
                                {period:'WW',format:'MMM DD'},
                                {period:'MM',format:'MMM'},
                                {period:'YYYY',format:'YYYY'}]
                        },
                        "legend": {
                            "useGraphSettings": true,
                            "position": "absolute",
                            "right": 100,
                            "width": 300,
                            "top": 58,
                            "backgroundAlpha": 0.6,
                            "backgroundColor": "white"
                        },
                        "export": {
                            "enabled": true
                        }
                    });

                me.zoomChart();
                me.chart.addListener("rendered", _.bind(me.zoomChart, me));
                me.$el.find('.widget__loader').hide();

            },

            _onResize: function () {
                var me = this;
            },

            zoomChart: function () {
                var me = this,
                    now = moment(),
                    weekday = now.day(),
                    to = moment(_.last(me.data)['Timestamp']).toDate(),
                    since;

                switch(me.granularity) {
                    case '5m':
                        since = moment(_.last(me.data)['Timestamp']).subtract(1, 'day').toDate();
                        break;
                    case 'hour':
                        since = moment(_.last(me.data)['Timestamp']).subtract(1, 'day').toDate();
                        break;
                    case 'day':
                        since = moment(_.last(me.data)['Timestamp']).subtract(1, 'day').toDate();
                        break;
                    default:
                        since = moment(_.last(me.data)['Timestamp']).subtract(7, 'day').toDate();
                }

                //me.chart.zoomToDates(since, to);
            }
        });
    });


