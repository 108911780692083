// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/Permissions',["App", "backbone"],
    function (App, Backbone) {
        'use strict';
        var Permissions = Backbone.Model.extend({

        });

        return Permissions;
    }
);
