// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Displays/DisplayGroup',['App', 'jquery', 'underscore', 'hbs!templates/views/Displays/DisplayGroup', 'backbone', 'bootstrap-dialog'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'display',

            ui: {
                inner: '.inner',
                actions: '.action-bar',
                menuTrigger: 'input.menu-open',
                menuMask: '.menu__mask'
            },

            events: {
                'click .menu-item' : '_setDisplayStatus',
                'mouseenter @ui.inner' : '_triggerBubbleMenu',
                'mouseenter .menu-item' : '_triggerBubbleMenu',
                'mouseenter .menu__mask' : '_triggerBubbleMenu',
                'mouseleave @ui.inner' : '_triggerBubbleMenu',
                'mouseleave .menu-item' : '_triggerBubbleMenu',
                'mouseleave .menu__mask' : '_triggerBubbleMenu',
                'click @ui.inner' : '_triggerBubbleMenu'
            },

            modelEvents: {
                'change': 'render'
            },

            onRender: function () {
                var me = this;

                me.$el.addClass(me.model.get('display_name'));
                me.updateActiveState();

                me.$el.removeClass("draft submitted rejected approved online");
                !_.isEmpty(me.model.get('status')) && me.$el.addClass(me.model.get('status'));


                // add tooltip with name
                me.$el.attr('title', (me.model.get('uri') === '/data/display-groups/mall' ? 'mall (BroadSign)' : me.model.get('display_name')) + ' - ' + me.model.get('status'));

                // add display icon
                me.ui.inner.css('background-image', 'url(' + me.model.getIcon() + ')');

                me._setupView();

                if (App.currentOffer && App.currentOffer.getOfferTargets().getByDisplayGroupUri(me.model.get('uri'))) {
                    me.listenTo(App.currentOffer.getOfferTargets().getByDisplayGroupUri(me.model.get('uri')), 'change', function () {
                        App.vent.trigger("App.displays.refresh");
                        App.vent.trigger("App.layout.offer.refresh");
                    });
                };
            },

            updateActiveState: function () {
                var me = this;

                if (!_.isEmpty(me.model.get('active'))) {
                    if (me.model.get('active')) {
                        me.$el.show();
                    } else {
                        me.$el.hide();
                    }
                } else {
                    // active field is not supported (groups)
                    me.$el.show();
                }
            },

            selectDisplay: function () {
                var me = this;

                if (!me.$el.hasClass('online')) {
                    me.$el.toggleClass('selected');
                    if (me.$el.hasClass('selected')) {
                        me.model.set('selected', true);
                    } else {
                        me.model.unset('selected');
                    }
                    App.vent.trigger('App.displays.updateDisplays');
                }
            },

            _setDisplayStatus: function (event) {
                event && event.preventDefault();
                var me = this,
                    el = $(event.currentTarget),
                    data = {},
                    finishUpdate = function (dialog) {
                        me.model.set('status', el.data('status'));
                        dialog && dialog.close();
                        App.vent.trigger('App.displays.refresh');
                        App.vent.trigger("App.layout.offer.refresh");
                    };

                if (el.hasClass('hide-menu')) {
                    me._triggerBubbleMenu(event, true);
                    return;
                }

                if (!_.isUndefined(App.currentOffer)) {

                    if (!App.currentOffer.isValid(true)) {
                        return false;
                    } else {

                        if ((App.currentOffer.get('offer_type') === 'video' || App.currentOffer.get('offer_type') === 'image') && !App.currentOffer.get('mobile_offer') && _.isEmpty(App.currentOffer.get('media_file'))) {
                            if (!_.isEmpty(App.currentOffer.get('source_template')) && (!_.isNull(App.currentOffer['_template']) && App.currentOffer['_template'].get('type') !== 'none')) {
                                BootstrapDialog.alert("Please upload artwork file.");
                                return false;
                            }
                        }

                        if (App.currentOffer.get('offer_type') === 'link' && _.isEmpty(App.currentOffer.get('link_url'))) {
                            BootstrapDialog.alert("Please provide offer link.");
                            return false;
                        }

                        if (App.currentOffer.get('offer_type') === 'no-artwork' && _.isEmpty(App.currentOffer.get('source_template'))) {
                            BootstrapDialog.alert("Please select offer template.");
                            return false;
                        }

                        if (App.currentOffer.get('offer_type') === 'multi' && _.isEmpty(App.currentOffer.get('multi_offer_artworks'))) {
                            BootstrapDialog.alert("Please upload at least one image");
                            return false;
                        }

                        if (App.currentOffer.get('offer_type') === 'video' && !_.isNumber(App.currentOffer.get('display_time'))) {
                            BootstrapDialog.alert("Cannot determine video length. Possibly wrong video format/codec.");
                            return false;
                        }

                        // adding new display group to offer targets
                        if (_.isEmpty(me.model.get('status')) || me.model.get('status') === 'draft') {

                            if (!App.currentCompany.get('has_private_screens') && !me.model.isApprover()) {
                                
                                console.log(me.model.get('uri'));
                                App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'), {display_time: App.currentOffer.get('display_time')});
                                me.model.set('status', el.data('status'));
                                if(App.currentCompany.getSubscriptionPlans().getCurrent().attributes.type!='MULTI'){
                                    App.vent.trigger("App.layout.offer.refresh");
                                   
                                   
                                    BootstrapDialog.show({
                                        title: 'Submit offer for review',
                                        message: 'Are you sure you want to submit this offer for <b>' + me.model.get('display_name') + '</b> approval?',
                                        buttons: [{
                                            label: 'Cancel',
                                            action: function (dialog) {
                                                dialog.close();
                                                return false;
                                            }
                                        }, {
                                            label: 'Submit',
                                            action: function (dialog) {
                                                App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'), {display_time: App.currentOffer.get('display_time')});
                                                me.model.set('status', el.data('status'));
                                                App.vent.trigger("App.layout.offer.refresh");
                                                dialog.close();
                                            }
                                        }]
                                    });
                                }
                            } else {
                                App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'), {display_time: App.currentOffer.get('display_time')});
                                me.model.set('status', el.data('status'));
                                App.vent.trigger("App.layout.offer.refresh");
                            }

                        } else if (el.data('status') === 'rejected') {
                            BootstrapDialog.show({
                                title: 'Offer rejection',
                                message: $('<textarea id="approver-rejection-reason-field" class="form-control" placeholder="Rejection reason..."></textarea>'),
                                buttons: [{
                                    label: 'Cancel',
                                    cssClass: 'btn-default',
                                    hotkey: 27,
                                    action: function (dialog) {
                                        dialog.close();
                                        return false;
                                    }
                                }, {
                                    label: 'Reject offer',
                                    cssClass: 'btn-danger',
                                    hotkey: 13, // Enter.
                                    action: function (dialog) {
                                        App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'), {rejection_reason: !_.isEmpty($('#approver-rejection-reason-field').val()) ? $('#approver-rejection-reason-field').val() : 'Not provided by Approver'});
                                        finishUpdate(dialog);

                                    }
                                }],
                                onshown: function (dialog) {
                                    $('#approver-rejection-reason-field').focus();
                                }
                            });
                        } else if (el.data('status') === 'accepted') {
                            App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'), {display_time: App.currentOffer.get('display_time')});
                            finishUpdate();
                        } else {
                            App.currentOffer.setTargetStatus(me.model.get('uri'), el.data('status'));
                            finishUpdate();
                        }

                    }


                }


            },

            _setupView: function () {
                var me = this,
                    status = !_.isEmpty(me.model.get('status')) ? me.model.get('status') : "draft";

                me.ui.actions.find('button:not([data-' + status + '="true"])').remove();

                // private only
                if (App.currentCompany.get('has_private_screens')) {
                    me.ui.actions.find('button:not([data-private="true"])').remove();
                } else {
                    me.ui.actions.find('button[data-private="true"]').remove();
                }

                // remove approver role elements
                if (!me.model.isApprover()) {
                    me.ui.actions.find('button[data-approver="true"]').remove();
                } else {
                    me.ui.actions.find('button[data-approver="false"]').remove();
                }
            },

            _triggerBubbleMenu: function (event, forceHide) {
                var me = this;

                if (forceHide) {
                    me.$el.clearQueue();
                    me.ui.menuMask.css('left', '40px');
                    me.$el.removeClass('display__active-menu');
                    App.vent.trigger('App.displays.hideMenu');
                } else if (!App.os.isMobile()) {
                    if (!me.$el.hasClass('display__active-menu') && event.type !== 'mouseleave') {
                        App.vent.trigger('App.displays.hideMenu', me.model.get('uri'));
                        me.$el
                            .addClass('display__active-menu')
                            .removeClass('display__not-active')
                            .clearQueue();

                        // menu mask
                        if (_.size(me.$el.find('.hide-menu')) > 0) {
                            _.delay(function () {
                                try {
                                    me.ui.menuMask.css('left', (me.$el.find('.hide-menu').position().left - 50) + 'px');
                                } catch(e) {
                                    ;
                                }
                            }, 300);
                        }

                    } else if (event && event.type === 'mouseleave') {
                        me.$el
                            .stop()
                            .delay(200)
                            .queue(function (next) {
                                $(this).removeClass('display__active-menu');
                                App.vent.trigger('App.displays.hideMenu');
                                // menu mask
                                if (_.size(me.$el.find('.hide-menu')) > 0) {
                                    me.ui.menuMask.css('left', '40px');
                                }
                                next();
                            });
                    } else if (event && event.type === 'mouseenter') {
                        me.$el.clearQueue();
                    } else if ((event && event.type !== 'mouseenter' && event.type !== 'mouseover')) {
                        me.$el.removeClass('display__active-menu');
                        App.vent.trigger('App.displays.hideMenu');
                    }
                } else {
                    if (event && event.type !== 'mouseenter' && event.type !== 'mouseleave') {
                        if (!me.$el.hasClass('display__active-menu')) {
                            App.vent.trigger('App.displays.hideMenu', me.model.get('uri'));
                            me.$el
                                .addClass('display__active-menu')
                                .removeClass('display__not-active');
                        } else {
                            me.$el.removeClass('display__active-menu');
                            App.vent.trigger('App.displays.hideMenu');
                        }
                    }
                }


            }

        });
    });

