// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Retailers/CreateDepartment',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Retailers/CreateDepartment', 'ladda-bootstrap', 'bootstrap-dialog'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                'departmentName' : 'input[name="departmentName"]'
            },

            events: {
                'click .create-btn' : 'createDepartment'
            },

            initialize: function (options) {
                this.departments = options && options.departments;
                this.parentNode = options  && options.parentNode;
            },

            onShow: function () {
                var me = this;
                setTimeout(function () { me.ui.departmentName.focus(); }, 200);
            },

            createDepartment: function (event) {
                var me = this,
                    newRetailer,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    name = me.ui.departmentName.val();


                if (_.isEmpty(name)) {
                    App.os.showFieldError('input[name="departmentName"]', App.trans('Please enter Department name'));
                    return;
                }

                l.start();

                this.ui.departmentName.prop('disabled', true);
                this.$el.find('button').addClass('disabled');

                newRetailer = me.departments.create({
                    'name' : name,
                    'append_to' : !_.isNull(me.parentNode) ? me.parentNode : ''
                }, {
                    wait: true,
                    success: function (model, response, options) {
                        App.vent.trigger('App.departments.refresh', newRetailer.get('uri'), me.parentNode);
                        App.vent.trigger('App.departments.showDetails', newRetailer.get('uri'));
                        App.mainRegion.currentView.modalRegion.currentView.destroy();
                    },
                    error: function (model, response, options) {
                        me.destroy();
                        BootstrapDialog.show({
                            type: BootstrapDialog.TYPE_DANGER,
                            title: App.trans('Huston! We have a problem!'),
                            message: App.trans("Unfortunately an error occurred while creating new Department.<br><br>") + '<b>' + response.responseJSON.reason + '</b>',
                            buttons: [{
                                label: App.trans("Close"),
                                action: function (dialog) {
                                    dialog.close();
                                }
                            }]
                        });
                    }
                });
            }

        });
    });

