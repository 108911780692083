// -*- coding: utf-8 -*-


/*global define*/
define('collections/Users',["backbone", "models/User"],
    function (Backbone, User) {

        'use strict';
        var Users = Backbone.Collection.extend({

            model: User,

            comparator: function (model) {
                return model.get('displayName');
            }

        });

        return Users;
    });
