
/* START_TEMPLATE */
define('hbs!templates/views/Reports/UnderOverviewList',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"row reports-list-tip\">\n    <span class=\"pull-left\">* Cost per mille (thousand)</span>\n</div>\n<div class=\"row\">\n    <div id=\"show-10-records\" class=\"show-10-rec pull-left dropdown\">\n        <button class=\"\" type=\"button\" data-toggle=\"dropdown\" aria-expanded=\"false\" >\n            Show <label>20</label> records on a page\n            <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>\n        </button>\n        <div class=\"dropdown-menu\">\n            <li><a href=\"#\">10</a></li>\n            <li><a href=\"#\">20</a></li>\n            <li><a href=\"#\">50</a></li>\n            <li><a href=\"#\">all</a></li>\n        </div>\n    </div>\n    <div class=\"pull-right download-delete\">\n        <button data-permission=\"repo_down_btn\" class=\"btn btn-sm btn-default print-invoice-btn-lower\">\n            <i class=\"fa fa-angle-double-down\" aria-hidden=\"true\"></i> Download selected\n        </button>\n        <button data-permission=\"repo_dele_btn\" class=\"btn btn-sm btn-default compare-btn-lower\">\n            <i class=\"fa fa-angle-double-right\" aria-hidden=\"true\"></i> Compare selected\n        </button>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
