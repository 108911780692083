// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,defaultOfferTemplate*/
define('views/ApplicationLayout/ApplicationSidebar',['App', 'backbone', 'backbone.marionette', 'hbs!templates/views/ApplicationLayout/ApplicationSidebar',

    'views/Offers/CreateOffer',
    'views/ApplicationLayout/TopUserInfo',
    'views/Payments/TopCreditsArea'

],

    function (App, Backbone, Marionette, template,

        CreateOffer,
        TopUserInfo,
        TopCreditsArea
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            tagName: 'ul',
            className: 'sidebar-menu',

            regions: {
                userInfo: '[data-region="user-info"]',
                topCreditsArea: '#top-credits-area'
            },

            events: {
                'click [data-action="create-offer"]': 'createOffer',
                'click .sidebar-switch' : '_toggleSidebar',
                'click li a' : 'navigateTo',
                'click .upload-multiple-offers' : 'createMultipleOffers',
                'change #multiple-offers-upload-form input.filenames': 'onFilenamesFieldChange'
            },

            initialize: function () {
                var me = this;
                me.listenTo(App.vent, 'App.layout.changeView', _.bind(me.updateSidebar, me));
                me.listenTo(App.vent, 'App.layout.showCreateOffer', _.bind(me.createOffer, me));
                me.listenTo(App.vent, 'App.layout.create_campaign', _.bind(me.createCampaign, me));
            },

            onRender: function () {
                var me = this;

                me.userInfo.show(new TopUserInfo({ model: App.currentUser }));

                // Set DOM element ID
                me.$el.attr('id', 'nav-accordion');

                if (App.currentCompany.isPayable()) {

                    $.when(App.currentDepartmentPromise).then(function () {
                        me.topCreditsArea.show(new TopCreditsArea({model: App.currentDepartment.useCompanyCredits() ? App.currentCompany : App.currentDepartment}));
                        me.$el.find('#top-credits-area').show();
                    });
                }
                
            },

            _toggleSidebar: function (event) {
                event.preventDefault();
                App.vent.trigger('App.layout.toggleSidebar');
            },

            navigateTo: function (event) {
                event.preventDefault();
                var me = this;

                var hash = $(event.currentTarget).data('hash');
                if (!_.isUndefined(hash)) {

                    var ng = document.getElementById('ng-iframe');
                    if(ng &&
                        ng.contentWindow &&
                        window.location.href.includes('/offers') &&
                        hash.includes('/offers') &&
                        ng.contentWindow.location.href.includes('/offers')
                    ){
                        ng.contentWindow.postMessage('toOffers', window.appUrl + '/ng/dist/index.html');
                        return;
                    }

                    // update sidebar options
                    me.updateSidebar($(event.currentTarget).data('view'));

                    App.vent.trigger('App.layout.sidebar.hideForMobile');

                    // notify user on low credits state
                    App.vent.trigger('App.layout.lowCredits.info');

                    // redirect view
                    App.appRouter.navigate(hash, {trigger:true});
                }
            },

            createOffer: function (event) {
                event && event.preventDefault();

                // switch user context
                App.userContext = App.os.ROLE_CONTEXT.RETAILER;

                App.vent.trigger('App.layout.sidebar.hideForMobile');

                var modalView = new CreateOffer();
                App.mainRegion.currentView.modalRegion.show(modalView);
            },

            createCampaign: function (event) {
                event && event.preventDefault();

                $('[data-hash="new-campaign"]').click();
            },
            
            createMultipleOffers: function (event) {
                var me = this,
                    Offer = App.currentDepartment.getOffers({fetch: false}).model,
                    supportedTypesList = _.flatten(_.values(Offer.prototype.supportedMimeTypes)),
                    $filenamesField = $('#multiple-offers-upload-form').children('input.filenames')

                // switch user context
                App.userContext = App.os.ROLE_CONTEXT.RETAILER;

                $filenamesField.attr('accept', supportedTypesList.join(','));
                $filenamesField.trigger('click');
            },
            
            onFilenamesFieldChange: function (event) {
                var me = this,
                    files = event.target.files,
                    offers = App.currentDepartment.getOffers({fetch: false}),
                    Offer = offers.model,
                    supportedTypesList = _.flatten(_.values(Offer.prototype.supportedMimeTypes));
                
                console.log('onFilenamesFieldChange for files: ', files);
                if (_.size(files)) {
                    App.appRouter.navigate(App.currentCompany.get('uris').Offers, true);
                    _.each(files, function(file) {
                        var mimeType = file.type || 'application/octet-stream',
                            offerSource = mimeType.indexOf('video/') == 0 ? defaultOfferVideoTemplate : defaultOfferImageTemplate,
                            offerType = mimeType.indexOf('video/') == 0 ? 'video' : 'image'; 
    
                        if (supportedTypesList.indexOf(mimeType) < 0) {
                            console.error('Skipping file of unsupported type', mimeType, ': ', file.name);
                            return;
                        }
                        
                        offers.create({
                            'name' : "Offer from " + file.name,
                            'source_template' : offerSource,
                            'offer_type' : offerType
                        }, { at: 0, wait: true }).once('add', function(offer) {
                            offer.set('uploadingProgress', 0);
                            $.ajax({
                                url: offer.get('uris').Files,
                                type: 'POST',
                                dataType: "json",
                                data: JSON.stringify({
                                    name: file.name
                                })
                            }).done(function (data) {
                                if (data) {
                                    offer.uploadFile(
                                        file, data.upload_method, data.uris.Upload, data.uris.UploadedFiles, 'POST',
                                        function onSetProgress (progress) {
                                            // set options.xhr to non-false value to ensure it's not saved to server
                                            offer.set({
                                                'uploadingProgress': progress
                                            }, {xhr: {}});
                                        },
                                        function() {
                                            console.log('onUploadSuccess');
                                            if (offerType !== 'image') {
                                                offer.unset('uploadingProgress');
                                            }
                                        },
                                        function() {console.log('onError')},
                                        function() {console.log('onMediaFileUpdated')} );
                                } else {
                                    console.log('error');
                                }
                            }).fail(function (data) {
                                console.log('error');
                            });
                        });
                    });
                }
            },

            updateSidebar: function (attrs) {
                var me = this,
                    view = attrs[0],
                    anchor = me.$el.find('li a[data-view="' + attrs + '"]');

                me.$el.find('li a').removeClass('active');
                anchor.addClass('active');

                App.vent.trigger('App.layout.updateTitle', [anchor.data('title')]);
            }

        });
    }
);
