// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/yesOrNo',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function yesOrNo(bool) {
        if (bool) {
            return 'Yes';
        }
        else {
            return 'No';
        }
    }
    Handlebars.registerHelper('yesOrNo', yesOrNo);
    return yesOrNo;
});
