
/* START_TEMPLATE */
define('hbs!templates/views/ApplicationLayout/ApplicationSidebar',['hbs','handlebars','templates/helpers/subscriptionPlan'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n<li class=\"sub-menu\">\n    <a href=\"#\" data-permission=\"menu_repo_btn\" data-hash=\"reports\" data-title=\"Reports\" data-view=\"reports\" data-private=\"true\">\n        <span>Reports</span>\n    </a>\n</li>\n";
  }

  buffer += "<div class=\"sidebar-switch\" data-permission=\"topm_hamb_btn\" data-placement=\"right\" data-original-title=\"Toggle Navigation\" style=\"\n    top: 140px;\n    position: absolute;\n    right: 5px;\n    font-size: 25px;\n    cursor: pointer;\n\">\n    <i class=\"fa fa-caret-left\" style=\"\n    margin-left: 2px;\n    margin-right:  3px;\n\"></i>\n</div>\n\n<div data-region=\"user-info\"></div>\n\n<div class=\"separator\"></div>\n\n<div class=\"credits-info hidden\" data-permission=\"topm_cred_btn\" id=\"top-credits-area\"></div>\n\n<li class=\"\" style=\"\n    margin-top:  18px;\n\">\n    <a class=\"active\" href=\"#\" data-hash=\"dashboard\" data-permission=\"menu_dash_btn\" data-title=\"Dashboard\" data-view=\"dashboard\" data-private=\"true\">\n        <span>Dashboard</span>\n    </a>\n</li>\n\n<li class=\"\" style=\"display: none;\">\n    <a class=\"active\" href=\"#\" data-hash=\"new-campaign\" data-permission=\"menu_dash_btn\" data-title=\"New Campaign\" data-view=\"new-campaign\" data-private=\"true\">\n        <span>new-campaign</span>\n    </a>\n</li>\n\n<li class=\"sub-menu\">\n    <a href=\"#\" data-hash=\""
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.uris)),stack1 == null || stack1 === false ? stack1 : stack1.Offers)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\" data-permission=\"menu_your_btn\" data-title=\"My Campaigns\" data-view=\"offers\" data-private=\"true\">\n        <span>My Campaigns</span>\n    </a>\n</li>\n\n";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, "DEMO", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n<li class=\"\">\n    <a  href=\"/#creative-spec\" data-hash=\"creative-spec\" data-permission=\"menu_dash_btn\" data-title=\"Dashboard\" data-view=\"creative-spec\" data-private=\"true\">\n        <span>Creative Spec</span>\n    </a>\n</li>\n\n\n<li>\n    <a class=\"header-btn\" href=\"#profile/user-details\" data-hash=\"profile\" data-view=\"profile\">\n        <span>Account Details</span>\n    </a>\n</li>\n\n<li>\n    <a class=\"header-btn\" href=\"/#profile/billing_info\" data-hash=\"profile/billing_info\" data-view=\"profile/billing_info\">\n        <span>Settings</span>\n    </a>\n</li>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
