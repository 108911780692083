// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/isApprover',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function isApprover(displayGroupUri, options) {

        if ($.inArray(displayGroupUri, App.currentUser.getApproverGroups()) > -1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    }
    Handlebars.registerHelper('isApprover', isApprover);
    return isApprover;
});
