
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/Greeting',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"greetings\">\n    <div class=\"row\">\n\n        <div id=\"payment-col\" class=\"col-sm-6\">\n            <p class=\"title\">\n                <i class=\"fa fa-money\" aria-hidden=\"true\"></i>\n                Choose payment method:\n            </p>\n            <ul>\n                <li><span>Bank</span></li>\n                <li><span>Credit</span></li>\n                <li><span>Speak to us</span></li>\n                <li><a href=\"#profile/payments\"><span id=\"plans-btn\" class=\"link\" >Subscription plans</span></a></li>\n            </ul>\n        </div>\n\n        <div id=\"offer-col\" class=\"col-sm-6\">\n            <p class=\"title\">\n                <i class=\"fa fa-paint-brush\" aria-hidden=\"true\"></i>\n                Create artwork:\n            </p>\n            <ul>\n                <li><span>Speak to us if you need help creating artwork</span></li>\n                <li><span id=\"create-btn\" class=\"link\">Create offer</span></li>\n                <li><span id=\"specs-btn\" class=\"link\">Design specification</span></li>\n            </ul>\n        </div>\n\n    </div>\n</div>\n\n";
  });
return t;
});
/* END_TEMPLATE */
;
