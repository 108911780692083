
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentUser',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "style=\"background-image: url('";
  if (helper = helpers.avatarUri) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.avatarUri); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "')\"";
  return buffer;
  }

  buffer += "<div class=\"item-header\">\n    <figure class=\"avatar\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.avatarUri), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "></figure>\n\n    <div class=\"profile-avatar\">\n        <div class=\"profile-avatar__inner\">\n            <div class=\"profile-avatar__drop-zone picDropZone\" style=\"background-image: url(";
  if (helper = helpers.avatarUri) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.avatarUri); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ")\"></div>\n\n            <div class=\"profile-avatar__file-input fileinput fileinput-new\" data-provides=\"fileinput\">\n                <span class=\"profile-avatar__btn btn-file\">\n                    <span class=\"fileinput-new\"></span><span class=\"fileinput-exists disabled\">Uploading...</span>\n                    <input type=\"file\" name=\"avatarPic\">\n                </span>\n            </div>\n            <div class=\"profile-avatar__loading-mask\">\n                <div class=\"radial-progress waiting\">\n                    <div class=\"inf\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n\n\n    <div class=\"info\">\n        ";
  if (helper = helpers.displayName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.displayName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        <small>";
  if (helper = helpers.email) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.email); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</small>\n    </div>\n    <button class=\"btn btn-circle pull-right expandBtn\"><i class=\"fa fa-angle-down\"></i></button>\n    <button class=\"btn btn-circle pull-right expandBtn expanded\"><i class=\"fa fa-angle-up\"></i></button>\n</div>\n<div class=\"user-configuration\"></div>\n<div class=\"loading-mask\">\n    <div class=\"spinner\" style=\"margin: 0 auto\"></div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
