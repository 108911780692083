// -*- coding: utf-8 -*-

/*global require*/
require(["jquery", "jquery.cookie", "backbone", 'backbone.marionette', "jqueryui", "bootstrap", "backbone.validation", "gettext", 'backbone.stickit'],
    function () {

        'use strict';

        var setupGlobalVariables = function (data) {
            // os_locale_data = data['osLocaleData'].domains;
            sessionData = data['sessionData'];
            // kwargs = data['kwargs'];
            // window.kwargs = kwargs;
            osEnableGA = true; // data['osEnableGA'];
            defaultOfferImageTemplate = data['defaultOfferImageTemplate'];
            defaultOfferVideoTemplate = data['defaultOfferVideoTemplate'];
            // variant = data['variant'];
        };

        var setupInitialStyle = function () {
            var cssLink = document.getElementById('flowAdsStyle');
            var currentHref = cssLink.getAttribute("href");
//            var path = cssPathTemplate.replace('STYLE', sessionData.user.color_scheme);
            var path = cssPathTemplate.replace('STYLE', 'default');

            if (path !== currentHref) {
                cssLink.setAttribute("href", path);
            }
        };

        var setupFacebookSdk = function() {
            // FB.init({
            //     appId      : facebookAppId,
            //     cookie     : true,
            //     status     : true,
            //     version    : 'v2.8'
            //  });
        };

        // var helpcrunchBoot = function () {
        //     var data = {
        //         applicationId: helpCrunchConfig.applicationId,
        //         applicationSecret: helpCrunchConfig.applicationSecret,
        //         user: {
        //             email: sessionData.user.email,
        //             name: sessionData.user.firstname || sessionData.user.lastname ? (sessionData.user.firstname + ' ' + sessionData.user.lastname) : sessionData.user.email,
        //             user_id: window.btoa(sessionData.user.email),
        //             company: sessionData.company.name,
        //             custom_data: {
        //                 active_plan: sessionData.company.active_plan,
        //                 credits: sessionData.company.credits
        //             }
        //         }
        //     };
        //
        //     window.HelpCrunch('init', helpCrunchConfig.organization, data);
        //     window.HelpCrunch('showChatWidget');
        // };

        // var intercomBoot = function () {
        //     //var intercomAppId = "rnojr4or";
        //     var intercomAppId = window.intercomSettings.app_id;
        //     var user = sessionData.user;
        //     var now = Date.now();
        //     var createdTimestamp = Math.floor(sessionData.company.created.iLocalMillis/1000);
        //
        //     window.Intercom("boot", {
        //         app_id: intercomAppId,
        //         name: user.firstname + ' ' + user.lastname,
        //         email: user.email, // Email address
        //         company_name: sessionData.company.name,
        //         //created_at: now.toString()
        //         created_at: createdTimestamp,
        //         signed_up_at: createdTimestamp
        //     });
        //
        //     Backbone.history.on('all', function(route, router) {
        //         try {
        //             window.Intercom("update");
        //         } catch (e) {
        //             console.error('error while updating intercom' + e.message);
        //         }
        //     })
        // };

        // var intercomSendEvent = function (eventname, args) {
        //     window.Intercom('trackEvent', eventname, args);
        // };
        // var helpcrunchSendEvent = function (eventName, args) {
        //     window.HelpCrunch('trackEvent', eventName);
        // };
//        var setupFacebookSdk = function() {
//            FB.init({
//                appId      : '632681606939784',
//                cookie     : true,
//                status     : true,
//                version    : 'v2.8'
//             });
//        };

        var setupSessionMonitor = function () {
            var oneMinute = 1000 * 60;
            console.log("Starting session monitor");
            var registerActivity = function () {
                $.ajax({
                    url: sessionMonitorServiceUrl + "/register/activity",
                    method: "POST",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                    }
                });
            };
            registerActivity();
            setInterval(registerActivity, oneMinute);
        };

        var authWithCustomToken = function(token) {
          console.log('Authenticating Firebase with custom token');
          var deferred = $.Deferred();
          firebase.auth().signInWithCustomToken(token)
            .then(function(userCredential) {
              var user = userCredential.user;
              user.getIdToken().then(function(idToken) {
                window.sessionStorage.setItem('auth-token', idToken);
                deferred.resolve(true);
              });
          })
          .catch(function(error) { console.log(error); deferred.resolve(); });
          return deferred.promise();
        }

        firebase.initializeApp(firebaseConfig);

        $.ajax({
            url: sessionServiceUrl,
            method: 'GET',
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function (xhr) {
                if (window.sessionStorage.getItem('auth-token')) {
                  xhr.setRequestHeader('Authorization', 'Bearer ' + window.sessionStorage.getItem('auth-token'));
                } else {
                  $.cookie('X-FlowCity-Session-User')  && xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                }
            }
        }).done(function (data, textStatus, jqXHR) {
            setupGlobalVariables(data);
            if (sessionData) {

              var startApp = function() {
                require([
                    "App", /*"ga", */"routers/router", "controllers/controller"], function (App, /*GA, */AppRouter, AppController) {

                    setupFacebookSdk();

                    App.appRouter = new AppRouter({
                      controller: new AppController()
                    });

                    // Start Marionette Application in desktop mode (default)
                    App.start();
                  });
              }

              if (window.sessionStorage.getItem('auth-token')) {
                setupInitialStyle();
                startApp();
              } else {
                var token = jqXHR.getResponseHeader("x-flowcity-token")
                if (token) {
                  console.log(token);
                  authWithCustomToken(token)
                    .then(function () {
                      setupInitialStyle();
                      startApp();
                    });
                } else {
                  setupInitialStyle();
                  startApp();
                }
              }
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            require(["App"], function (App) {
                sessionData = undefined;
                $.removeCookie("X-FlowCity-Session-User");
                $.removeCookie("X-FlowCity-Session-User", {domain: ".flow.city"});
                App.os.clearTabSession('X-FlowCity-Session-User');
                window.location.href = App.webAppUrl;
            });
        });
    });
define("init/init", function(){});

