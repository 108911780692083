/**
 * Created by golechwi on 20.03.2017.
 */

var intercomReportEvent = function(postfix, args) {
    try {
        window.Intercom('trackEvent', 'event_' + postfix, args);
    } catch (e) {
        console.log(e.message);
    }
};

define("behaviors/Intercom", function(){});

