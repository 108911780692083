// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/OfferSchedules',["App", "backbone", "underscore", "moment", "models/OfferSchedule"],
    function (App, Backbone, _, moment, OfferSchedule) {

        'use strict';
        var OfferSchedules = Backbone.Collection.extend({

            model: OfferSchedule,

            _scheduleTypes: [
                'DbOfferScheduleTime',
                'DbOfferScheduleWeather',
                'DbOfferScheduleDisruptions',
                'DbOfferScheduleAIM',
                'DbOfferSchedulePollen',
                'DbOfferScheduleSensor',
                'DbOfferScheduleVolumetrics',
                'DbOfferScheduleEPos'
            ],

            _schedulerDefaults: {
                'DbOfferScheduleTime' : {
                    "type":"DbOfferScheduleTime",
                    "active": false,
                    "schedule_start": moment().format("YYYY-MM-DDT00:00"),
                    "schedule_stop": moment().endOf('isoWeek').format("YYYY-MM-DDT23:59"),
                    "weekdays": [0,1,2,3,4,5,6],
                    "time_start": null,
                    "time_stop": null,
                    "all_day":true
                },

                'DbOfferScheduleWeather' : {
                    "type":"DbOfferScheduleWeather",
                    "active": false,
                    "sky_condition" : "",
                    "temperature" : ""
                },

                'DbOfferScheduleDisruptions' : {
                    "type":"DbOfferScheduleDisruptions",
                    "active": false,
                    "disruptions" : ""
                },

                'DbOfferScheduleAIM' : {
                    "type":"DbOfferScheduleAIM",
                    "active": false,
                    "gender": "",
                    "gender_range": [0, 100],
                    "age": "",
                    "age_range": [0, 100]
                },

                'DbOfferSchedulePollen' : {
                    "type":"DbOfferSchedulePollen",
                    "active": false,
                    "pollen": ""
                },

                'DbOfferScheduleSensor' : {
                    "type":"DbOfferScheduleSensor",
                    "active": false,
                    "sensor": ""
                },

                'DbOfferScheduleVolumetrics' : {
                    "type":"DbOfferScheduleVolumetrics",
                    "active": false,
                    "impact": "",
                    "impact_range": [0, 100],
                    "impressions": "",
                    "impressions_range": [0, 100],
                    "frequency": "",
                    "frequency_range": [0, 100],
                    "GRP": "",
                    "GRP_range": [0, 100]
                },

                'DbOfferScheduleEPos' : {
                    "type":"DbOfferScheduleEPos",
                    "active": false,
                    "stock_level": "",
                    "shelf_live": "",
                    "desirability": "",
                    "tracking": "",
                    "monitor": ""
                }

            },

            comparator: function (model) {
                return model.get('schedule_start');
            },

            _checkScheduleType: function(scheduleType) {
                return App.currentCompany.hasFeature(scheduleType) &&
                        _.contains(App.currentOffer.attributes.supported_schedules, scheduleType);
            },

            _getAvailableSchedulerTypes: function (scheduler) {
                var availSchedulesTypes = [];

                availSchedulesTypes.push('DbOfferScheduleTime');
                availSchedulesTypes.push('DbOfferScheduleWeather');
                // this._checkScheduleType('weatherScheduler') && availSchedulesTypes.push('DbOfferScheduleWeather');
                // this._checkScheduleType('tflScheduler') && availSchedulesTypes.push('DbOfferScheduleDisruptions');
                availSchedulesTypes.push('DbOfferScheduleDisruptions');
                availSchedulesTypes.push('DbOfferScheduleAIM');
                availSchedulesTypes.push('DbOfferSchedulePollen');
                availSchedulesTypes.push('DbOfferScheduleSensor');
                availSchedulesTypes.push('DbOfferScheduleVolumetrics');
                availSchedulesTypes.push('DbOfferScheduleEPos');
                // this._checkScheduleType('demographicsScheduler') && availSchedulesTypes.push('DbOfferScheduleAIM');

                return availSchedulesTypes;
            },

            setDefaults: function () {
                var me = this,
                    currentRealScheduleTypes = [],
                    missingScheduleTypes;

                this.each(function (model) {
                    currentRealScheduleTypes.push(model.get('type'));
                });

                // Add default missing types of schedules
                _.each(_.difference(me._getAvailableSchedulerTypes(), currentRealScheduleTypes), function (type) {
                    me.add(me._schedulerDefaults[type], { silent: true });
                });
            },

            getSummaryModelAttrs: function () {
                var summaryModelAttrs = [];

                this.each(function (schedule) {
                    summaryModelAttrs[schedule.get('type')] = schedule.attributes;
                });

                return summaryModelAttrs;
            }

        });

        return OfferSchedules;
    });
