// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/OfferTemplate',["App", "backbone"],
    function (App, Backbone) {

        'use strict';
        var OfferTemplate = Backbone.Model.extend({
            uriStencil: 'offer-templates'
        });

        return OfferTemplate;
    }
);
