// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/Reports',["backbone", "models/Report", "moment"],
    function (Backbone, Report, moment) {

        'use strict';
        var Reports = Backbone.Collection.extend({
            model: Report,

            comparator: function (model) {
                return -moment(model.get('created'))
            }
        });

        return Reports;
    });
