
/* START_TEMPLATE */
define('hbs!templates/views/ApplicationLayout/TopUserInfo',['hbs','handlebars','templates/helpers/userDisplayName'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  
  return "\n        <img id=\"cust_avatar\" src=\"\">\n        ";
  }

function program3(depth0,data) {
  
  
  return "\n        <img class=\"def_avatar\" src=\"/static/images/avatar.svg\">\n        ";
  }

function program5(depth0,data) {
  
  var stack1, helper;
  if (helper = helpers.firstname) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.firstname); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  return escapeExpression(stack1);
  }

function program7(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += " ";
  if (helper = helpers.lastname) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.lastname); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1);
  return buffer;
  }

  buffer += "<a href=\"#profile/user-details\">\n\n    <figure class=\"avatar-wrapper\" data-permission=\"menu_thum_btn\">\n    	<span id=\"initials\" style=\"display: none;\"></span>\n\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.is_avatar_uploaded), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </figure>\n\n    <div class=\"user-info__desc\">\n        <span data-permission=\"menu_user_btn\">"
    + escapeExpression((helper = helpers.userDisplayName || (depth0 && depth0.userDisplayName),options={hash:{}},helper ? helper.call(depth0, depth0, options) : helperMissing.call(depth0, "userDisplayName", depth0, options)))
    + "</span>\n        <small data-permission=\"menu_comp_btn\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.departmentObj)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</small>\n    </div>\n    <!--<div class=\"user-info__desc\">-->\n        <!--<span data-permission=\"menu_user_btn\">";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.firstname), {hash:{},inverse:self.noop,fn:self.program(5, program5, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.lastname), {hash:{},inverse:self.noop,fn:self.program(7, program7, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>-->\n        <!--<small data-permission=\"menu_comp_btn\" style=\"display: none;\">";
  if (helper = helpers.company_name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.company_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</small>-->\n    <!--</div>-->\n\n</a>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
