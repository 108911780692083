// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('layouts/Dashboard',['App',
        'backbone',
        'backbone.marionette',
        'behaviors/Greeting',
        'behaviors/Permissions',
        'behaviors/Onboarding',
        'hbs!templates/layouts/Dashboard',

        'views/Dashboard/ChartWidget',
        'views/Dashboard/ForecastWidget',
        'views/Dashboard/CreateCampaign',
        'views/Dashboard/AgeDistributionWidget',
        'views/Dashboard/GenderDistributionWidget',
        'views/Dashboard/EyeTrackingWidget',
        'views/Dashboard/EmotionsWidget',
        'views/Dashboard/VisitsWidget',
        // 'views/Dashboard/AvailableAudiencesWidget',
        // 'views/Dashboard/AvailableAudiencesWidgetBelow',
        'views/Dashboard/YourLocationWidget',
        // 'views/Dashboard/AvailableImpressionsWidget',
        // 'views/Dashboard/NearbyReachWidget',
        // 'views/Dashboard/AvailableFormatsWidget',

        'views/Notifications/Widget',

        'views/Payments/CreditsPurchase',

        // 'views/Dashboard/CreateCampaignAudience',
        // 'views/Dashboard/CreateCampaignFormats',
        // 'views/Dashboard/CreateCampaignBudget',

        'moment',

        'featherlight',
        'bootstrap-select'

        ],
    function (
        App,
        Backbone,
        Marionette,
        Greeting,
        Permissions,
        Onboarding,
        template,

        ChartWidget,
        ForecastWidget,
        CreateCampaign,
        AgeDistributionWidget,
        GenderDistributionWidget,
        EyeTrackingWidget,
        EmotionsWidget,
        VisitsWidget,
        // AvailableAudiencesWidget,
        // AvailableAudiencesWidgetBelow,
        YourLocationWidget,
        // AvailableImpressionsWidget,
        // NearbyReachWidget,
        // AvailableFormatsWidget,
        NotificationsWidget,
        CreditsPurchase,

        // CreateCampaignAudience,
        // CreateCampaignFormats,
        // CreateCampaignBudget,
        moment

    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            templateHelpers: function() {
                return {
                    isProdEnv: appEnv === 'prod'
                }
            },

            tagName: 'section',
            className: 'wrapper',

            regions: {
                // notificationsWidget: '#notificationsWidget',
                chartBuildingOccupancyWidget: '#chartBuildingOccupancyWidget',
                chartWifiFootfallDistribution: '#chartWifiFootfallDistribution',
                // forecastWidget: '#forecastWidget',
                // createCampaign: '#createCampaign',
                // availableAudiencesWidget: '#availableAudiencesWidget',
                // availableAudiencesWidgetBelow: '#availableAudiencesWidgetBelow',
                yourLocationWidget: '#yourLocationWidget',
                // availableImpressionsWidget: '#availableImpressionsWidget',
                // nearbyReachWidget: '#nearbyReachWidget',
                // availableFormatsWidget: '#availableFormatsWidget',
                ageDistributionWidget: '[data-widget="AgeDistributionWidget"]',
                genderDistributionWidget: '[data-widget="GenderDistributionWidget"]',
                eyeTrackingWidget: '[data-widget="EyeTrackingWidget"]',
                // emotionsWidget: '[data-widget="EmotionsWidget"]'
                // createCampaignAudience: '.create-campaign-audience',
                // createCampaignFormats: '.create-campaign-formats',
                // createCampaignBudget: '.create-campaign-budget'
            },

            ui: {
                sensorFilter: '#sensor-filter',
                dateRangePicker: '#dateRange',
                ngBasic: '#ng-iframe'
            },

            events: {
                'click .add-offer' : 'clickCampaignButton',
                'change @ui.sensorFilter' : '_changeCurrentSensorGroup',
                // 'click #ADD-CAMPAIGNS': 'clickCampaignButton',
                'click #buy-credits-here':'_buyCredits'
            },

            behaviors: {
                // Greeting:{
                //     behaviorClass: Greeting,
                //     variant: 'Dashboard'
                // },
                Permissions:{
                    behaviorClass: Permissions,
                    App: App
                },
                Onboarding:{
                    behaviorClass: Onboarding,
                    variant: 'Dashboard'
                }
            },
            // clickCampaignButton: function(){
            //     var hiddenPart = $('#hidden-part-campaigns');
            //
            //     if( hiddenPart.css('display')==='none' ){
            //         hiddenPart.fadeIn(500, "swing");
            //         $("#dashboard-main").fadeOut(500, "swing");
            //         $("#createCampaign").css({'position':'absolute','top':'100%', 'z-index':99, 'width':'calc(100% - 30px)'})
            //         .fadeIn("slow", "swing").animate({'top':0},500,
            //         function(){
            //             $("#createCampaign").css({'position':'relative','top':'auto','left':'auto', 'width':'auto'});
            //             $("#ADD-CAMPAIGNS").find("i").removeClass('fa-caret-right').addClass('fa-caret-down');
            //         });
            //     } else {
            //         $("#dashboard-main").fadeIn(500, "swing");
            //         hiddenPart.fadeOut(500, "swing");
            //         $("#createCampaign").css({'position':'absolute','top':'0', 'width':'calc(100% - 30px)'}).animate({'top':'100%'},500,
            //         function(){
            //             $("#createCampaign").css({'position':'relative','top':'auto','left':'auto', 'width':'auto'});
            //             $("#ADD-CAMPAIGNS").find("i").removeClass('fa-caret-right').addClass('fa-caret-right');
            //         });
            //     }
            // },
            initialize: function (options) {
                var me = this;
                if(parseInt(localStorage.getItem('firstTimeUserLogin')) === 1) {
                    /*
                    var script = document.createElement( 'script' );
                    script.type = 'text/javascript';
                    script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-WS4C48G');";
                    $("head").append( script );
                    var noscript = document.createElement( 'noscript' );
                    noscript.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WS4C48G' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
                    $("body").prepend( noscript );
                    */

                	try {
	                    dataLayer.push({
	                      'event':'pageview',
	                      'virtualUrl': '/welcome'
	                    });
                	} catch(e) {

                	}

                }

                App.currentSensorGroup = options && options.displayGroup;


                var welcomeAnimScript = '<script type="text/javascript" charset="utf-8" async="" data-requirecontext="_" data-requiremodule="behaviors/Greeting" src="/js/app/behaviors/welcomeAnim.js"></script>';
                $("head").append( welcomeAnimScript );

                App.currentSensorGroupFilter = new Backbone.Model({
                    'from': moment().subtract(7, 'day').startOf('day').format('YYYY-MM-DD'),
                    'to': moment().add(1, 'day').startOf('day').format('YYYY-MM-DD')
                });

                // me.listenTo(App.vent, 'App.dashboard.updateSensorData', _.bind(me._loadDemographics, me));

                // $.when(App.currentDepartment.getDisplayGroups().promise)
                //     .then(function onDisplayGroupsLoaded () {
                //         App.currentSensorGroup = App.currentDepartment.getDisplayGroups().findWhere({has_sensors: true}) || null;
                //         // me._loadDemographics(true);
                //         if (App.currentSensorGroup) {
                //             if (_.isString(App.currentSensorGroup.get('map_uri'))) {
                //                 me.$el.find('.sensor-map-title').html('<span></span> Current location');
                //                 me.$el.find('.sensor-map').css('background-image', 'url(' + App.currentSensorGroup.get('map_uri') + ')');
                //             } else {
                //                 me.$el.find('.sensor-map-title').html('<span></span> Current surge');
                //                 me.$el.find('.sensor-map').removeAttr('style');
                //             }
                //         }
                //     });

                me.listenTo(App.vent, 'App.departments.changeCurrentDepartment', _.bind(me.render, me));

            },

            onRender: function () {
                var me = this;

                window.page = 'dashboard';


                if ($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) !== -1) {
                    // me.forecastWidget.show(new ForecastWidget());
                    me.ui.ngBasic.remove();
                    me._loadDemographics(true);

                    // me.notificationsWidget.show(new NotificationsWidget());
                    me._setupSensorFilter();
                    me.chartBuildingOccupancyWidget.show(new VisitsWidget({
                        title: App.trans("Camera: Footfall distribution"),
                        category: 'Timestamp',
                        mapResult: function(d) {
                            return {'Timestamp': moment(d.get('from')).toDate(), 'visits': d.get('visits')};
                        },
                        graphs: [
                            { name: 'Visits', column: 'visits', balloon: 'Visits' }
                        ]
                    }));
                    me.chartWifiFootfallDistribution.show(new VisitsWidget({
                        title: App.trans("Wifi: Footfall distribution"),
                        type: 'wifi',
                        category: 'from',
                        mapResult: function(d) {
                            return {
                                'from': moment(d.get('from')),
                                'Timestamp': moment(d.get('from')),
                                'buyers': d.get('passers_by') * (40 + (Math.floor(Math.random() * 30))) / 100,
                                'visitors': d.get('passers_by') * (70 + (Math.floor(Math.random() * 20))) / 100,
                                'passers_by': d.get('passers_by')
                            };
                        },
                        graphs: [
                            { column: "buyers", name: "Buyers", balloon: "Buyers" },
                            { column: "passers_by", name: "Passers By", balloon: "Passers By" },
                            { column: "visitors", name: "Visitors", balloon: "Visitors" }
                        ],
                        availableGranularities: [
                            { val: 'day', title: 'Day' },
                            { val: 'hour', title: 'Hour' }
                        ]
                    }));
                    me.ageDistributionWidget.show(new AgeDistributionWidget());
                    me.genderDistributionWidget.show(new GenderDistributionWidget());
                    me.eyeTrackingWidget.show(new EyeTrackingWidget());
                    // me.emotionsWidget.show(new EmotionsWidget());
                }
                // TODO this needs to be fixed properly - this used to be a placeholder previously.
                // else {
                //     me.availableAudiencesWidget.show(new AvailableAudiencesWidget());
                //     me.availableFormatsWidget.show(new AvailableFormatsWidget());
                //     me.yourLocationWidget.show(new YourLocationWidget());
                //     me.createCampaign.show(new CreateCampaign());
                //     me.createCampaignFormats.show(new CreateCampaignFormats());
                //     me.createCampaignAudience.show(new CreateCampaignAudience());
                //     me.createCampaignBudget.show(new CreateCampaignBudget());
                //
                //
                //  // me.availableImpressionsWidget.show(new AvailableImpressionsWidget());
                //     // me.nearbyReachWidget.show(new NearbyReachWidget());
                // }

                /*
                else if (appEnv === 'prod1') {
					me.notificationsWidget.show(new NotificationsWidget());
                    me.chartBuildingOccupancyWidget.show(new ChartWidget({
                        title: App.trans("Camera: Footfall distribution"),
                        url: "/file-data/CanaryWharfRetail.csv",
                        category: "Timestamp",
                        graphs: [
                            { column: "jubilee_walk", name: "Jubilee Walk", balloon: "Crowd at Jubilee Walk Entrance" },
                            { column: "one_canada_square", name: "One Canada Square", balloon: "Crowd at One Canada Square Entrance" },
                            { column: "waitrose", name: "Canada Walk/Waitrose", balloon: "Crowd at Canada Walk/Waitrose Entrance" }
                        ]
                    }));
                    me.chartWifiFootfallDistribution.show(new ChartWidget({
                        title: App.trans("Wifi: Footfall distribution"),
                        url: "/file-data/WifiFootfall.csv",
                        category: "from",
                        graphs: [
                            { column: "buyers", name: "Buyers", balloon: "Buyers" },
                            { column: "passers_by", name: "Passers By", balloon: "Passers By" },
                            { column: "visitors", name: "Visitors", balloon: "Visitors" }
                        ]
                    }));
                    me.availableAudiencesWidget.show(new AvailableAudiencesWidget());
                    me.availableFormatsWidget.show(new AvailableFormatsWidget());
                    me.yourLocationWidget.show(new YourLocationWidget());
                }
                */
                else
                    this.srcReplacer();

                if (App.currentCompany.hasLimitedPlan()) {
                    me.setDemoMode();

                }
            },

            setDemoMode: function () {
                var me = this;
                me.$el.find('[data-demo="true"]').addClass('demo');
            },

            _setupSensorFilter: function () {
                var me = this,
                    sensorGroups;

                me.$el.find('.selectpicker').selectpicker({ dropdownAlignRight: true });

                // // update select picker
                // $.when(App.currentDepartment.getDisplayGroups().promise)
                //     .then(function onDisplayGroupsLoaded () {
                //         if (!me.isDestroyed) {
                //             sensorGroups = App.currentDepartment.getDisplayGroups().filter(function (group) {
                //                 // estimated_audience
                //                 return group.get('has_sensors');
                //             });
                //
                //             if (_.size(sensorGroups) > 0) {
                //
                //                 _.each(sensorGroups, function (group) {
                //                     me.ui.sensorFilter.append('<option value="' + group.get('uri') + '">' + (group.get('display_name') || 'Unknown') + '</option>');
                //                 });
                //                 me.$el.find('.selectpicker').selectpicker('val', App.currentSensorGroup.get('uri'));
                //             } else {
                //                 me.ui.sensorFilter.append('<option value="">No sensors</option>');
                //             }
                //             me.ui.sensorFilter.selectpicker('refresh');
                //         }
                // });

                me.ui.dateRangePicker.find('span').html(App.currentSensorGroupFilter.get('from') + ' - ' + App.currentSensorGroupFilter.get('to'));

                me.ui.dateRangePicker.daterangepicker({
                    ranges: {
                        'Previous month': [moment().subtract(1, 'month').startOf('day'), moment().endOf('day')],
                        'Previous week': [moment().subtract(7, 'day').startOf('day').endOf('day'), moment()],
                        'Today': [moment().startOf('day'), moment().add(1, 'days').startOf('day')]
                    },
                    locale: {
                        "format": "YYYY-MM-DD"
                    },
                    timePicker: true,
                    timePicker24Hour: true,
                    startDate: App.currentSensorGroupFilter.get('from'),
                    endDate: App.currentSensorGroupFilter.get('to')
                });

                me.ui.dateRangePicker.on('apply.daterangepicker', function(ev, picker) {
                    me._updateDateRange(picker.startDate, picker.endDate);
                });

            },

            _updateDateRange: function (start, end) {
                var me = this;
                App.currentSensorGroupFilter.set({
                    'from': start.format('YYYY-MM-DDTHH:mm:ss'),
                    'to': end.format('YYYY-MM-DDTHH:mm:ss')
                });
                me.ui.dateRangePicker.find('span').html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
                App.vent.trigger('App.dashboard.updateSensorData');
            },

            _changeCurrentSensorGroup: function () {
                var me = this;
                App.currentSensorGroup = App.currentDepartment.getDisplayGroups().get(me.ui.sensorFilter.val());
                me._loadDemographics(true);
                if (App.currentSensorGroup) {
                    if (_.isString(App.currentSensorGroup.get('map_uri'))) {
                        me.$el.find('.sensor-map-title').html('<span></span> Current location');
                        me.$el.find('.sensor-map').css('background-image', 'url(' + App.currentSensorGroup.get('map_uri') + ')');
                    } else {
                        me.$el.find('.sensor-map-title').html('<span></span> Current surge');
                        me.$el.find('.sensor-map').removeAttr('style');
                    }
                }
            },

            _loadDemographics: function (initial) {

                var options = {
                    data: {
                        granularity: 'day',
                        from: moment(App.currentSensorGroupFilter.get('from')).format('YYYY-MM-DDTHH:mm:ss'),
                        to: moment(App.currentSensorGroupFilter.get('to')).format('YYYY-MM-DDTHH:mm:ss')
                    }
                };

                App.vent.trigger('App.dashboard.setLoading');
                //if (App.currentSensorGroup) {
                //     if (initial && !App.currentSensorGroup.getDemographics({fetch: false}).promise) {
                //         $.when(App.currentSensorGroup.getDemographics(options))
                //             .then(function () {
                //                 App.vent.trigger('App.dashboard.currentSensorGroup.change');
                //             });
                //     } else {
                //         $.when(App.currentSensorGroup.getDemographics().fetch(options))
                //             .then(function onDemographicsReady() {
                //                 App.vent.trigger('App.dashboard.currentSensorGroup.change');
                //             });
                //     }
                // } else {
                //     App.vent.trigger('App.dashboard.currentSensorGroup.change');
                // }
                App.vent.trigger('App.dashboard.currentSensorGroup.change');
            },

            _buyCredits: function (event) {
                event && event.preventDefault();

                if (App.currentCompany.getSubscriptionPlans().getCurrent().hasFeature('creditsAquisition')) {

                    var modalView = new CreditsPurchase(),
                        callback = function () {
                            if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                                App.mainRegion.currentView.modalRegion.show(modalView);
                        };

                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);
                    } else {
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    }
                }
            },

            onShow: function(){
                if(window.popup == 'campaign-created'){
                    $('#campaign-created').show();
                }

                $('.fadeout-screen, #campaign-created .upload-button').click(function(){
                    $(this).hide();
                    $('#campaign-created').hide();
                });


                $('.popover-icon').each(function(){
                    var title = $(this).attr('title');
                    var text = $(this).attr('text');
                    $(this).append('<div class="popover-wrap"><h4>'+title+'</h4>'+text+'</div>');
                });
            },

            srcReplacer: function(){

                setTimeout(function () {
                    if( $('#ng-iframe').find('iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html';

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }
        });
    });
