
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/EmotionsWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"widget__header\">\n    <h5><span class=\"icon icon--camera\"></span>Camera: Emotions</h5>\n</div>\n\n<div class=\"widget__body\">\n    <div>\n        <div class=\"emotions__positive-emotions\">\n            <figure>\n                <canvas></canvas>\n            </figure>\n            <p class=\"value\">-</p>\n            <h4>Positive emotions</h4>\n        </div>\n        <div class=\"emotions__avg-smile-time\">\n            <figure>\n                <p class=\"value\">-</p>\n            </figure>\n            <h4>Average smile time</h4>\n        </div>\n    </div>\n\n    <span class=\"widget__loader\">\n        <div class=\"spinner\"></div>\n    </span>\n\n    <span class=\"widget__body--no-camera\">No camera selected</span>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
