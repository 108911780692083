
/* START_TEMPLATE */
define('hbs!templates/views/Payments/Invoice',['hbs','handlebars','templates/helpers/formatDate','templates/helpers/decimal'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", self=this;

function program1(depth0,data) {
  
  
  return "\n    <button data-permission=\"invo_prin_btn\" class=\"btn btn-sm btn-default print-invoice-btn\">Print</button>\n    ";
  }

  buffer += "<div class=\"checkbox checkbox-info checkbox-inline\">\n    <input type=\"checkbox\" name=\"check_report\" data-field=\"check_report\" value=\"1\"><label></label>\n</div>\n<div class=\"invoice-date\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), "DD MMM YYYY", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.created), "DD MMM YYYY", options)))
    + "</div>\n<div class=\"invoice-no\">Invoice #";
  if (helper = helpers.invoice_number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.invoice_number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n\n<!--<div class=\"invoice-details\" id=\"sort-company-name\">-->\n    <!---->\n<!--</div>-->\n\n<div class=\"invoice-details\">&pound; "
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.amount_gross), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.amount_gross), options)))
    + "</div>\n\n<div class=\"invoice-price\">&pound; "
    + escapeExpression((helper = helpers.decimal || (depth0 && depth0.decimal),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.amount_gross), options) : helperMissing.call(depth0, "decimal", (depth0 && depth0.amount_gross), options)))
    + "</div>\n\n<div class=\"invoice-buttons\">\n    <button data-permission=\"invo_prin_btn\" class=\"btn btn-sm btn-default download-invoice-btn\">Download</button>\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.download_link), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
