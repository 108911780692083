// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/LoopManagement/NGLoopManagement',['App',
        'backbone',
        'hbs!templates/views/LoopManagement/NGLoopManagement'
        ],
    function (
        App,
        Backbone,
        template
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            onRender: function () {
                this.srcReplacer();
            },

            srcReplacer: function() {
                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=loop-management';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }

        });
    });

