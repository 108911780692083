
/* START_TEMPLATE */
define('hbs!templates/layouts/Dashboard',['hbs','handlebars','templates/helpers/subscriptionPlan'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  
  return "\n<span class=\"widget__loader\" style=\"\n    height:  fit-content;\n    position:  absolute;\n    left:  50%;\n    top:  50%;\n    z-index: -1;\n    transform: translate(-50%, -50%);\n\">\n    <div class=\"spinner\"></div>\n  </span>\n";
  }

function program3(depth0,data) {
  
  
  return "\n<div class=\"top-panel filter-panel\">\n    <!--<div data-permission=\"dash_sour_drop\" style=\"float: right; width: 200px; margin: 25px 0 0 0;\">-->\n        <!--<select id=\"sensor-filter\" class=\"selectpicker bottom-margin\" data-width=\"100%\"></select>-->\n    <!--</div>-->\n\n    <div id=\"dateRange\" data-permission=\"dash_date_drop\" class=\"pull-right\">\n        <i class=\"glyphicon glyphicon-calendar fa fa-calendar\"></i>&nbsp;\n        <span></span> <b class=\"caret\"></b>\n    </div>\n\n</div>\n<div style=\"clear: both\"></div>\n";
  }

function program5(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "-->\n            <!--<div class=\"row ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" id=\"notificationsWidget\"></div>-->\n        <!--";
  return buffer;
  }
function program6(depth0,data) {
  
  
  return "demo";
  }

function program8(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n            <div class=\"row\">\n                <div class=\"col-md-12 mb\" data-permission=\"dash_age_widg\" data-widget=\"AgeDistributionWidget\"></div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-md-6 mb\" data-permission=\"dash_eyet_widg\" data-widget=\"EyeTrackingWidget\"></div>\n                <!-- <div class=\"col-md-6 mb\" data-permission=\"dash_emot_widg\" data-widget=\"EmotionsWidget\"></div>-->\n                <div class=\"col-md-6 mb\" data-permission=\"dash_gend_widg\" data-widget=\"GenderDistributionWidget\"></div>\n            </div>\n\n            <!-- GRAPHS -->\n            <div class=\"row\" data-permission=\"dash_foot_widg\">\n                <div class=\"col-md-12\">\n                    <ul class=\"nav nav-tabs chart-tabs\" role=\"tablist\">\n                        <li role=\"presentation\"\n                            class=\"active\" style=\"padding: 0 40px;\">\n                            <a href=\"#chartBuildingOccupancyWidget\"\n                               data-toggle=\"tab\">Impacts\n                            </a>\n                        </li>\n\n                        <li role=\"presentation\"\n                            class=\"\" style=\"padding: 0 40px;\">\n                            <a href=\"#chartWifiFootfallDistribution\"\n                               data-toggle=\"tab\">Ratings\n                            </a>\n                        </li>\n                    </ul>\n                </div>\n\n                <div class=\"col-md-12 mb tab-pane chart-tab tab-content fade in active ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" id=\"chartBuildingOccupancyWidget\">\n                </div>\n\n                <div class=\"col-md-12 mb tab-pane chart-tab tab-content fade ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" id=\"chartWifiFootfallDistribution\">\n                </div>\n            </div>\n            <!--<div class=\"col-md-6 col-lg-4\" id=\"forecastWidget\" data-permission=\"dash_weat_widg\"></div>-->\n            <!-- TWEET ANALYSIS MOCKUP -->\n            <!--<div id=\"tweetAnalysis\" class=\"col-md-6 col-lg-4  mb ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-permission=\"dash_twit_widg\">-->\n                <!--<div class=\"white-panel widget ar-16-9 height-equaliser\">-->\n                    <!--<div class=\"inner\">-->\n                        <!--<div class=\"widget__header\">-->\n                            <!--<h5>&nbsp;<i class=\"fa fa-twitter\"></i> Tweet analysis in your area</h5>-->\n                        <!--</div>-->\n                        <!--<div class=\"widget__body keep_proportions\">-->\n                            <!--<img src=\"static/images/_mockup-hashtags-trends.png\" />-->\n                        <!--</div>-->\n                        <!--<a href=\"#profile/payments\" class=\"upgrade\">UPGRADE</a>-->\n                    <!--</div>-->\n                <!--</div>-->\n            <!--</div>-->\n\n            <!-- HEATMAP MOCKUP -->\n            <!--<div id=\"heatmapMockup\" class=\"col-md-6 col-lg-4 mb ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-permission=\"dash_map_widg\">-->\n                <!--<div class=\"white-panel widget ar-16-9 height-equaliser\">-->\n                    <!--<div class=\"inner\">-->\n                        <!--<div class=\"widget__header\">-->\n                            <!--<h5 class=\"sensor-map-title\"><span></span> Current surge</h5>-->\n                        <!--</div>-->\n                        <!--<div class=\"widget__body keep_proportions\">-->\n                            <!--<img src=\"static/images/_mockup-heatmap.png\" />-->\n                        <!--</div>-->\n                        <!--<a href=\"#profile/payments\" class=\"upgrade\">UPGRADE</a>-->\n                    <!--</div>-->\n                <!--</div>-->\n            <!--</div>-->\n\n            <!-- DUMMY PLUS BUTTON -->\n            <!--<div class=\"col-md-6 col-lg-4  mb\" data-permission=\"dash_new_widg\">-->\n                <!--<div class=\"dummy-panel ar-16-9\">-->\n                    <!--<div class=\"inner\">-->\n                        <!--<i class=\"fa fa-plus fa-4x\"></i>-->\n                    <!--</div>-->\n                <!--</div>-->\n            <!--</div>-->\n            ";
  return buffer;
  }

function program10(depth0,data) {
  
  
  return "-->\n    		<!--<div class=\"row no-margin\" id=\"audiences-and-formats\">-->\n\n    			<!--&lt;!&ndash; AVAILABLE AUDIENCES MOCKUP &ndash;&gt;-->\n    			<!--<div class=\"mb\" style=\"padding-left: 0\" id=\"availableAudiencesWidget\" data-permission=\"dash_audi_widg\"></div>-->\n\n    			<!--&lt;!&ndash; AVAILABLE FORMATS &ndash;&gt;-->\n                <!--<div class=\"mb\" style=\"padding-right: 0\" id=\"availableFormatsWidget\" data-permission=\"dash_frmt_widg\"></div>-->\n    		<!--</div>-->\n    		<!--<div class=\"row\" id=\"location-and-weather\">-->\n    			<!--&lt;!&ndash; YOUR LOCATION &ndash;&gt;-->\n    			<!--<div class=\"mb\" style=\"padding-left: 0\" id=\"yourLocationWidget\" data-permission=\"dash_loc_widg\"></div>-->\n\n    			<!--&lt;!&ndash; WEATHER PANEL &ndash;&gt;-->\n    			<div style=\"display: none;\" id=\"forecastWidget\"></div>\n\n    			<!--&lt;!&ndash; AVAILABLE IMPRESSIONS &ndash;&gt;-->\n    			<!--&lt;!&ndash; <div class=\"col-md-6 col-lg-4 mb\" id=\"availableImpressionsWidget\" data-permission=\"dash_impr_widg\"></div> &ndash;&gt;-->\n\n    			<!--&lt;!&ndash; NEARBY REACH &ndash;&gt;-->\n    			<!--&lt;!&ndash; <div class=\"col-md-6 col-lg-4 mb\" id=\"nearbyReachWidget\" data-permission=\"dash_reach_widg\"></div> &ndash;&gt;-->\n\n            <!--</div>-->\n\n        <!--";
  }

  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", "PLUS", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", "PLUS", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n<div id=\"ng-iframe\" style=\"display: flex;position: absolute;top: 0;left: 0;right: 0;bottom: 0;\">\n    <iframe src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n</div>\n\n";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data},helper ? helper.call(depth0, "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n<div class=\"row\">\n    <div class=\"col-lg-12\">\n\n        <div id=\"dashboard-main\" >\n\n\n        <!--<div id=\"campaign-created\" >-->\n            <!--<div class=\"white-panel\">-->\n                <!--<h4>Your campaign has now been created.</h4>-->\n                <!--<p>We will send you an email with confirmation shortly and notify you when your campaign has gone online.</p>-->\n                <!--<a href=\"javascript:void(0)\" class=\"upload-button\">Close</a>-->\n            <!--</div>-->\n            <!--<div class=\"fadeout-screen\"></div>-->\n        <!--</div>-->\n\n        <!--";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data},helper ? helper.call(depth0, "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n\n            <div class=\"mt\"></div>\n\n            ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data},helper ? helper.call(depth0, "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n            <!--";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(10, program10, data),data:data},helper ? helper.call(depth0, "DEMO", "BASIC", "PLUS", "ADMIN", options) : helperMissing.call(depth0, "subscriptionPlan", "DEMO", "BASIC", "PLUS", "ADMIN", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n\n        </div>\n        <div id=\"createCampaign\" data-permission=\"dash_audi_widg\" ></div>\n\n    </div>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
