
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/ForecastWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"widget__subtitle\">\n	<h3 class=\"inner_header\" style=\"margin-top: -5px; margin-left: 0;\">\n		LOCATION\n		<i class=\"fa fa-question popover-icon\" title=\"Location\" text=\"\" style=\"color: inherit; margin-top: 0\"></i>\n	</h3>\n</div>\n\n<div class=\"inner forecast-widget no-padding\" style=\"display: flow-root;\">\n	<canvas id=\"weather-icon\" width=\"145\" height=\"145\"></canvas>\n    <h2 class=\"city\"></h2>\n	<h4>\n		<span class=\"summary\"></span>\n		<span class=\"temp nowrap\"><span>-</span></span>\n	</h4>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
