// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/CreativeSpec',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery','hbs!templates/views/Dashboard/CreativeSpec', 'moment'],
    function (App, Backbone, _, Marionette, $,
            template, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            events: {
                'click #toggle-more':'openMore',
                'click .formats-wrap-toggle':'animateWrapToggle',
                'click .creative-guide-toggle':'animateGuideToggle'
            },

            ui: {
                panel2:'#accordion .panel-default:eq(2) .panel-heading a',
                panel1i: '#accordion .panel-default:eq(1) .panel-heading a i',
                panel1: '#accordion .panel-default:eq(1) .panel-heading a',
                panel0: '#accordion .panel-default:eq(0) .panel-heading a i',
                artworkButton: '#artwork-next-button',
                audienceButton: '#audience-next-button',
                sidebar2: '.sidebar-info:eq(2)',
                widgetContent: '.content',
                taxitop: "#TAXI-TOP",
                d6cinema:"#D6-CINEMA",
                twentyfive1:"#25-1",
                twentyfive2:"#25-2",
                letusdesign:"#letusdesign",
                count:'.count',
                addedImg: '#added_object img',
                screenLocations: '.screen-locations',
                creativeGuide:'.creative-guide-wrap',
                formatsWrap:'.creative-spec-formats-wrap',
                toggleMoreArrows:'#toggle-more i',
                animateWrapArrows:'.formats-wrap-toggle i',
                animateGuideArrows:'.creative-guide-toggle i'

            },
            initialize: function (options) {
                var me = this;

            },
            openMore:function(){
                $(this.ui.screenLocations).toggleClass('hidden');
                $(this.ui.toggleMoreArrows).toggleClass('transformed-arrows');
            },
            animateWrapToggle:function(){
                $(this.ui.animateWrapArrows).toggleClass('transformed-arrows');
            },
            animateGuideToggle:function(){
                $(this.ui.animateGuideArrows).toggleClass('transformed-arrows');
            },
            onRender: function () {
                var me = this;

                $('.title-row span').html('CREATIVE SPECS');
            },

            onShow: function () {
                var me = this;

                window.taxitop = 710;
                window.d6cinema= 2545;
                window.twentyfive1 = 4664;
                window.twentyfive2 = 3452;


                // $('.toggle-more').click(function(){
                //     $(me.ui.screenLocations).toggleClass('hidden');
                // });
                /*
                $('.creative-guide-toggle, .formats-wrap-toggle').click(function(){
                    $(me.ui.creativeGuide).toggleClass('hidden');
                    $(me.ui.formatsWrap).toggleClass('hidden');
                });
                */
                

                $(me.ui.count).each(function () {
                    $(this).prop('Counter',0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 4000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
                
                 
                var total = 1000;   
                    $(me.ui.taxitop).circliful({
                        foregroundBorderWidth: 7,
                        backgroundBorderWidth:7,
                        pointSize: 5,
                        percent: window.taxitop/total*100,
                        backgroundColor: '#F4F5F7',
                        foregroundColor: '#0D4B7C',
                        fontColor: '#333'
                    });
                    
                    $(me.ui.taxitop).find('text').first().remove();
                    // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                    // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                    $(me.ui.taxitop)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-26.svg" class="diagram_icon" ></div>');
                    
                 
                var total = 3000;     
                    $(me.ui.d6cinema).circliful({
                       foregroundBorderWidth: 7,
                       backgroundBorderWidth:7,
                        percent: window.d6cinema/total*100,
                        backgroundColor: '#F4F5F7',
                        foregroundColor: '#0D4B7C',
                        fontColor: '#333'
                    });
                    
                    $(me.ui.d6cinema).find('text').first().remove();
                    // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                    // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                    $(me.ui.d6cinema)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-27.svg" class="diagram_icon" ></div>');
                    
                 
                var total = 5000;     
                    $(me.ui.twentyfive1).circliful({
                       foregroundBorderWidth: 7,
                       backgroundBorderWidth:7,
                        percent: window.twentyfive1/total*100,
                        backgroundColor: '#F4F5F7',
                        foregroundColor: '#0D4B7C',
                        fontColor: '#000'
                    });
                    
                    $(me.ui.twentyfive1).find('text').first().remove();
                    // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                    // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                    $(me.ui.twentyfive1)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-SLIM-D6.svg" class="diagram_icon" ></div>');
                    
                 
                var total = 4000;     
                    $(me.ui.twentyfive2).circliful({
                       foregroundBorderWidth: 7,
                       backgroundBorderWidth:7,
                        percent: window.twentyfive2/total*100,
                        backgroundColor: '#F4F5F7',
                        foregroundColor: '#0D4B7C',
                        fontColor: '#000'
                    });
                    
                    $(me.ui.twentyfive2).find('text').first().remove();
                    // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                    // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                    $(me.ui.twentyfive2)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-29.svg" class="diagram_icon"></div>');
                
                    $(me.ui.letusdesign).circliful({
                        foregroundBorderWidth: 7,
                        backgroundBorderWidth:7,
                        pointSize: 5,
                        percent: 100,
                        backgroundColor: '#F4F5F7',
                        foregroundColor: '#0D4B7C',
                        fontColor: '#333'
                    });
                    
                    $(me.ui.letusdesign).find('text').first().remove();
                    // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                    // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                    $(me.ui.letusdesign)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-30.svg" class="diagram_icon" ></div>');
                    
            },

            _onResize: function () {
                var me = this;
            },

            onDestroy: function () {
                var me = this;

            }

        });
    });

