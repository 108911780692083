// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,OfferModel*/
define('models/Company',["App",
        "backbone",
        "underscore",
        "collections/Offers",
        "collections/OffersSearchable",
        "collections/SubmittedOffersSearchable",
        //"collections/Users",
        "collections/OfferTemplates",
        "collections/SubscriptionsPlans",
        "collections/Invoices",
        "collections/Departments",
        "collections/Reports",
        "collections/Locations",
        "models/Offer",
        "models/BillingInfo"
    ],
    function (App,
              Backbone,
              _,
              Offers,
              OffersSearchable,
              SubmittedOffersSearchable,
              //Users,
              OfferTemplates,
              SubscriptionPlans,
              Invoices,
              Departments,
              Reports,
              Locations,
              Offer,
              BillingInfo
    ) {

        'use strict';
        var Company = Backbone.Model.extend({
            uriStencil: 'companies',

            initialize: function () {
                this.addUri('DefaultLocations', '/data/locations?defaults=1');
            },

            getOffers: function (options) {
                return this.getSubCollection(OffersSearchable, 'Offers', options);
            },

            getSubmittedOffers: function (options) {
                return this.getSubCollection(SubmittedOffersSearchable, 'SubmittedOffers', options);
            },

            getOfferTemplates: function (options) {
                return this.getSubCollection(OfferTemplates, 'OfferTemplates', options);
            },

            getDepartments: function (options) {
                return this.getSubCollection(Departments, 'Departments', options);
            },

            isPrivate: function () {
                return this.get('has_private_screens');
            },

            hasLimitedPlan: function () {
                return this.get('active_plan') === 'BASIC';
            },

            getSubscriptionPlans: function () {
                var me = this;

                if (!me.subscriptionPlans) {
                    me.subscriptionPlans = new SubscriptionPlans();
                    me.subscriptionPlans.setFromSessionData(sessionData.subscription_plans);
                }
                return me.subscriptionPlans;
            },

            isSetupFeePaid: function () {
                return this.get('setup_fee_paid');
            },

            getActivePlan: function () {
                return this.get('active_plan');
            },

            hasFeature: function (featureId) {
                return this.getSubscriptionPlans().getCurrent().hasFeature(featureId);
            },

            hasUnlimitedCredits: function () {
                return this.get('unlimited_credits');
            },

            isPayable: function () {
                return this.get('payable');
            },

            getBillingInfo: function () {
                var me = this;

                if (!me.billingInfo) {
                    me.billingInfo = new BillingInfo();
                    me.billingInfo.url = me.get('uris').BillingInfo;
                    me.billingInfo._deferredFetch = $.Deferred();
                    me.billingInfo.promise = me.billingInfo._deferredFetch.promise();
                    $.when(me.billingInfo.fetch())
                        .then(function (jqXHR) {
                            me.billingInfo._deferredFetch.resolve(jqXHR);
                        }, function (jqXHR){
                            me.billingInfo._deferredFetch.reject(jqXHR);
                        });
                }
                return me.billingInfo;
            },

            getInvoices: function (options) {
                return this.getSubCollection(Invoices, 'Invoices', options);
            },

            getReports: function (options) {
                return this.getSubCollection(Reports, 'Reports', options);
            },

            getDefaultLocations: function (options) {
                return this.getSubCollection(Locations, 'DefaultLocations', options);
            }
        });

        Offer.prototype.uriStencilParent = Company;

        return Company;
    }
    );
