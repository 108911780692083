// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Profile/ProfileBillingInfo',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Profile/ProfileBillingInfo', 'ladda-bootstrap', 'bootstrap-dialog', 'flagstrap'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'form-panel profile-form row no-background',

            ui: {
                updateStatus: '.update-status',
                phone: '[name="phone"]',
                company_name: ('[name=company_name]'),
                first_name: ('[name=first_name]'),
                last_name: ('[name=last_name]'),
                billing_taxid: ('[name=billing_taxid]'),
                billing_address1: ('[name=billing_address1]'),
                billing_postcode: ('[name=billing_postcode]'),
                billing_town: ('[name=billing_town]'),
                country_code: ('[name=country_code]')
            },

            events: {
                'click .save-billing-info-btn': '_saveBillingInfo',
                'change @ui.phone': '_saveBillingInfo',
                'change @ui.company_name': '_saveBillingInfo',
                'change @ui.first_name': '_saveBillingInfo',
                'change @ui.last_name': '_saveBillingInfo',
                'change @ui.billing_taxid': '_saveBillingInfo',
                'change @ui.billing_address1': '_saveBillingInfo',
                'change @ui.billing_postcode': '_saveBillingInfo',
                'change @ui.billing_town': '_saveBillingInfo',
                'change @ui.country_code': '_saveBillingInfo'
            },

            modelEvents: {
                'change': 'render'
            },

            initialize: function (options) {
                this.model = App.currentCompany.getBillingInfo();
                Backbone.Validation.bind(this);
            },

            onRender: function () {
                var me = this;

                $.when(me.model.promise)
                    .then(function () {
                        // fields prefiller
                        _.isEmpty(me.model.get('company_name')) && me.$el.find('[name=company_name]').val(App.currentCompany.get('name'));
                        _.isEmpty(me.model.get('first_name')) && me.$el.find('[name=first_name]').val(App.currentUser.get('firstname'));
                        _.isEmpty(me.model.get('last_name')) && me.$el.find('[name=last_name]').val(App.currentUser.get('lastname'));
                    });


                _.delay(function () {
                    me.$el.find('#countryCode').flagStrap({ selectedCountry: me.model.get('country_code') });
                    me.addBinding(null, '[name=country_code]', 'country_code');
                }, 200);

                setTimeout(function profileUserDetailsAfterOnRender () {
                    _.isFunction(me.ui.phone.intlTelInput) && me.ui.phone.intlTelInput({
                        defaultCountry: 'gb',
                        preferredCountries: _.uniq(["us", "gb", sessionData.company.country.toLowerCase()])
                    });
                }, 200);
            },

            _saveBillingInfo: function () {
                var me = this,
                    l = Ladda.create(document.querySelector('.save-billing-info-btn'));

                // update model
                me.model.set({
                    'company_name': me.$el.find('[name=company_name]').val(),
                    'first_name': me.$el.find('[name=first_name]').val(),
                    'last_name': me.$el.find('[name=last_name]').val(),
                    'billing_taxid': me.$el.find('[name=billing_taxid]').val(),
                    'billing_address1': me.$el.find('[name=billing_address1]').val(),
                    // 'billing_address2': me.$el.find('[name=billing_address2]').val(),
                    'billing_postcode': me.$el.find('[name=billing_postcode]').val(),
                    'billing_town': me.$el.find('[name=billing_town]').val(),
                    'country_code': me.$el.find('[name=country_code]').val(),
                    'phone': me.$el.find('[name="phone"]').val()
                });

                if (me.model.isValid(true)) {
                    l.start();
                } else {
                    return false;
                }

                me.model.save({}, {
                    success: function() {
                        App.os.setLocalStorageKey('PAYMENT_DONE', true);
                        me.ui.updateStatus.html('<div class="alert alert-success">Your data has been saved.</div>');
                        l.stop();
                    },
                    error: function (model, response, options) {
                        me.ui.updateStatus.html('<div class="alert alert-success">Unfortunatelly an error occurred while saving your data.<br><br>' + '<b>' + response.responseJSON.reason + '</b></div>');
                        l.stop();
                    }
                });


            }


        });
    });

