// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Retailers/CreateDepartmentLocation',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Retailers/CreateDepartmentLocation', 'ladda-bootstrap', 'bootstrap-dialog'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                'postcode'       : 'input[name="postcode"]'
            },

            events: {
                'click .create-btn' : 'createDepartmentLocation'
            },

            initialize: function (options) {
                this.department = options.department;
            },

            onShow: function () {
                var me = this;
                setTimeout(function () { me.ui.postcode.focus(); }, 200);
            },

            createDepartmentLocation: function() {
                var me = this,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    postcode = me.ui.postcode.val();


                if (_.isEmpty(postcode)) {
                    App.os.showFieldError('input[name="postcode"]', App.trans('Please enter postcode'));
                    return;
                }

                l.start();

                this.ui.postcode.prop('disabled', true);
                this.$el.find('button').addClass('disabled');


                // fetch location details

                $.ajax({
                    url: locationServiceUrl,
                    type: 'GET',
                    data: {"postcode": postcode},
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                    }
                }).done(function(data, status, request) {
                    me.department.getLocations().create({
                        'name' : data.address,
                        'latitude' : data.latitude,
                        'longitude': data.longitude,
                        'location': data.address,
                        'city': data.city,
                        'country': data.country,
                        'postcode' : postcode
                    }, {
                        wait: true,
                        success: function (model, response, options) {
                            App.mainRegion.currentView.modalRegion.currentView.destroy();
                            App.vent.trigger('App.departments.showDetails', me.department.get('uri'));
                        },
                        error: function (model, response, options) {
                            me.destroy();
                            BootstrapDialog.show({
                                type: BootstrapDialog.TYPE_DANGER,
                                title: App.trans('Huston! We have a problem!'),
                                message: App.trans("Unfortunately an error occurred while creating new location.<br><br>") + '<b>' + response.responseJSON.reason + '</b>',
                                buttons: [{
                                    label: App.trans("Close"),
                                    action: function (dialog) {
                                        dialog.close();
                                    }
                                }]
                            });
                        }
                    });
                }).fail(function() {
                    App.os.showFieldError('input[name="postcode"]', App.trans('We were unable to fetch location details for given postcode'));
                    me.destroy();
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: App.trans('Huston! We have a problem!'),
                        message: App.trans("We were unable to fetch location details for given postcode.<br><br>"),
                        buttons: [{
                            label: App.trans("Close"),
                            action: function (dialog) {
                                dialog.close();
                            }
                        }]
                    });
                });

            }

        });
    });

