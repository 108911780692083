// -*- coding: utf-8 -*-

/*global define*/
define('collections/PublicTransportDisruptionsHistory',["backbone", "models/PublicTransportDisruption", "moment", "backbone.paginator"],
    function (Backbone, PublicTransportDisruption, moment) {

        'use strict';
        var PublicTransportDisruptionsHistory = Backbone.PageableCollection.extend({
            mode: 'infinite',

            model: PublicTransportDisruption,

            comparator: function (model) {
                return -(moment(model.get('created')).format('X'));
            },

            state: {
                firstPage: 1,
                currentPage: 1,
                perPage: 10,
                totalPages: 10
            },

            queryParams: {
                currentPage: null,
                per_page: null,
                totalPages: null,
                max_items: function () { return this.state.perPage; },
                before: function () {
                    var last = _.last(this.models);
                    return (last !== undefined) ? last.get('created') : null;
                }
            },

            parse: function (response) {
                this.totalRecords = this.totalPages * this.perPage;
                return Backbone.Collection.prototype.parse.call(this, response);
            },

            parseLinks: function (resp, xhr) {
                return {
                    first: this.url,
                    next: this.url,
                    prev: this.url
                };
            }
        });

        return PublicTransportDisruptionsHistory;
    }
    );
