'use strict';

define('views/InternalReports/LoggedUsers',[
        'App',
        'backbone',
        'underscore',
        'backbone.marionette',
        'jquery',
        'moment',
        'hbs!templates/views/InternalReports/LoggedUsers'
    ],
    function (App,
              Backbone,
              _,
              Marionette,
              $,
              moment,
              template) {

        return Backbone.Marionette.ItemView.extend({

            template: template,
            templateHelpers: function () {
                return {
                    users: this.users,
                    usersEmpty: this.users === undefined || this.users.length === 0
                };
            },

            className: 'white-panel widget ar-16-9',

            ui: {
                spinner: ".internal-reports-loading-mask"
            },

            initialize: function () {
                this.listenTo(App.vent, 'App.internalReports.dateRangeChanged', _.bind(this.loadStats, this));
            },

            loadStats: function () {
                var me = this;

                me.ui.spinner.addClass('spinning');
                $.ajax({
                    url: sessionMonitorServiceUrl + '/query/logged',
                    method: "GET",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                    }
                }).done(function (data) {
                    me.users = data;
                    console.log("Loaded logged users data", me.users);
                    me.render();
                });
            }
        });
    });

