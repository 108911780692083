
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionAreaLocation',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Area/location:</h4>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"area\" id=\"areaLocationFilter1\" value=\"none\">\n    <label for=\"areaLocationFilter1\">None</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"area\" id=\"areaLocationFilter2\" value=\"all\" checked>\n    <label for=\"areaLocationFilter2\">All locations</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"area\" id=\"areaLocationFilter3\" value=\"selected\">\n    <label for=\"areaLocationFilter3\">Selected:</label>\n    <div class=\"local-dropdown\" data-id=\"filter-by-area-group\">\n        <div class=\"ms-ctn form-control ms-ctn-focus\">\n            <div class=\"ms-sel-ctn\">\n                <input type=\"text\" id=\"areaLocation-format\" placeholder=\"Start typing location name\" style=\"width: 125px;\">\n            </div>\n            <div class=\"ms-trigger\">\n                <div class=\"ms-trigger-ico\"></div>\n            </div>\n        </div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
