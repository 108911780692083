// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/RetailersDepartmentSelector',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/RetailersDepartmentSelector', 'backbone',
    'bootstrap-dialog',
    'views/Retailers/Departments',
    'views/Retailers/RetailersFilter'],
    function (App, $, _, template, Backbone,
        BootstrapDialog,
        Departments,
        RetailersFilter) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            regions: {
                filterBox: '#departments-filter-box',
                retailersList: '#departments-list'
            },

            events: {
                'click .create-btn' : '_createRetailer',
                'click .btn-back' : '_backToRetailersList',
                'click .delete-btn' : '_removeRetailer'
            },

            onRender: function () {
                var me = this;
                //me.filterBox.show(new RetailersFilter());
                me.retailersList.show(new Departments({ model: me.model }));

                if (me.model) {
                    me.$el.find('.name').editable({
                        url: '',
                        pk: 1,
                        send: 'never',
                        mode: 'inline',
                        onblur: 'submit',
                        savenochange: true,
                        showbuttons: false,
                        value: me.model.get('name'),
                        success: function onXEditableSubTaskTitle (resp, newVal) {
                            me.$el.removeClass('xeditable-shown');
                            me.model.set('name', newVal);
                            if (me.model.hasChanged('name')) {
                                me.model.save();
                                me.render();
                            } else {
                                me.render();
                            }
                        }
                    });

                    if (me.model.get('uri') === App.currentCompany.get('uri')) {
                        me.$el.find('.delete-btn').remove();
                    }
                }
            },

            _createRetailer: function (event) {
                event.stopPropagation();
                event.preventDefault();
                App.vent.trigger('App.departments.create');
            },

            _backToRetailersList: function (event) {
                App.appRouter.navigate('companies', {trigger: true});
            },

            _removeRetailer: function (event) {
                event.stopPropagation();
                event.preventDefault();
                var me = this;

                if (me.model.get('uri') !== App.currentDepartment.get('uri')) {
                    BootstrapDialog.confirm(App.trans("Do you really want to delete this Retailer?"), function (result) {
                        if (result) {

                            me.model.destroy({
                                success: function () {
                                    delete(me.model);
                                    App.appRouter.navigate('companies', {trigger: true});
                                },
                                error: function () {
                                    me.render();
                                }
                            })

                        }
                    });
                }
            }
        });
    });
