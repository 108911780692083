// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/DepartmentUsers',['App',
        'backbone',
        'hbs!templates/views/Retailers/DepartmentUsers',
        'views/Retailers/DepartmentUser',
        'views/Retailers/CreateUser',
        'bootstrap-dialog'

        ],
    function (
        App,
        Backbone,
        template,
        DepartmentUser,
        CreateUser,
        BootstrapDialog
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: DepartmentUser,

            className: 'department-users',

            childViewContainer: 'ul',

            ui: {
                'childViewContainer' : 'ul'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'li',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            events: {
                'click .addUserBtn': '_addUser',

                'user.expand': '_expandUser'
            },

            filter: function (child, index, collection) {
                var me = this;
                return true;
            },

            initialize: function (options) {
                var me = this;
                me.parentView = options && options.parentView;
                if (me.parentView.model) {
                    me.model = me.parentView.model;

                    me.childViewOptions = {
                        department: me.model
                    }
                }
                me.collection = me.model.getUsers();
                $.when(me.collection.promise).then(function () {
                    me.$el.find('.no-items').html('No users');
                });

                me.listenTo(App.vent, 'App.retailers.users.delete', _.bind(me._deleteUser, me));

            },

            onRender: function () {
                var me = this;
                console.log(me.collection);
            },

            _expandUser: function (event, model) {
                var me = this,
                    selectedView = me.children.findByModel(model);

                if (selectedView.$el.hasClass('expanded')) {
                    selectedView.$el.removeClass('expanded');
                    me.ui.childViewContainer.removeClass('expanded');
                } else {
                    selectedView.$el.addClass('expanded');
                    me.ui.childViewContainer.addClass('expanded');
                }

                me.children.each(function(view) {
                    if (view !== selectedView) {
                        view.$el.removeClass('expanded');
                    }
                });

            },

            _addUser: function (event) {
                event && event.preventDefault();

                var me = this,
                    modalView = new CreateUser({ parentView: me });
                App.mainRegion.currentView.modalRegion.show(modalView);
            },

            _deleteUser: function (userUri) {
                var me = this;
                BootstrapDialog.confirm(App.trans("Do you really want to delete this user?"), function (result) {
                    if (result) {
                        me.collection.get(userUri) && me.collection.get(userUri).destroy();
                        me.ui.childViewContainer.removeClass('expanded');
                    }
                });
            }

        });
    });
