// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/SectionOfferList',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/SectionOfferList', 'backbone', 'switchery', 'magicsuggest'],
    function (App, $, _, template, Backbone, Switchery) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            ui: {
                offerList: '.report-offers-list',
                filterByOfferSwitch: '[name="filter-by-offer"]'
            },

            events: {
                'change @ui.filterByOfferSwitch' : '_onFilterByOfferSwitchChange'
            },

            initialize: function (options) {
                this.parent = options && options.parent;
                this.listenTo(App.vent, 'App.departments.changeCurrentDepartment', _.bind(this.render, this));
            },

            onRender: function () {
                var me = this;
                App.currentDepartment.getOffers().query();

                _.delay(function () {

                    // setup switchery
                    new Switchery(me.$el.find('.js-switch')[0], {
                        color: '#5bc0de',
                        size: 'small'
                    });

                    me.offerList = me.ui.offerList.magicSuggest({
                        allowFreeEntries: false,
                        minChars: 1,
                        placeholder: 'Start typing campaign name',
                        queryParam: 'dummy',
                        method: 'get',
                        displayField: 'value',
                        valueField: 'uri',
                        data: App.enrichApiUrl('/data/search/offers/normal'),
                        resultsField: 'objects'
                    });

                    $(me.offerList).on('beforeload', function () {
                        var data = {};
                        if (!_.isEmpty(me.offerList.getRawValue())) {
                            data['name'] = me.offerList.getRawValue();
                        }
                        me.offerList.setDataUrlParams({ query: JSON.stringify(data) });
                    });

                    $(me.offerList).on('selectionchange', function(e, m) {
                        me.parent.reportParams.offers = this.getValue();
                    });


                }, 100);
            },

            _onFilterByOfferSwitchChange: function (event) {
                var me = this,
                    target = event.currentTarget;
                if ($(target).is(':checked')) {
                    me.$el.find('[data-id="filter-by-offer"]').show();
                } else {
                    me.offerList.clear();
                    me.$el.find('[data-id="filter-by-offer"]').hide();
                }
            }

        });
    });
