// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferTile',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferTile', 'backbone', 'bootstrap-dialog'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'figure',

            events: {
                'click' : 'showOffer',
                'click .delete-offer' : 'deleteOffer'
            },

            modelEvents: {
                'change': 'onOfferChange'
            },

            initialize: function () {
                var me = this;

                    me.model.getOfferTargets().reset(null);
                
                    // prepare OfferTargets collection
                    me.model.getOfferTargets({
                        // fetch: 'offer-tiles-loaded',
                        onAutoCreate: function () {
                            if (_.size(me.model.get('targets')) > 0) {
                                me.model.getOfferTargets().reset(me.model.get('targets'));
                            }
                        }
                    });

                me.listenTo(me.model.getOfferTargets({fetch: false}), 'change', _.bind(me.render, me));
                me.listenTo(me.model.getOfferTargets({fetch: false}), 'reset', _.bind(me.render, me));
            },

            onOfferChange: function (model, options) {
                var me = this;

                if (!_.isUndefined(model.changed.uploadingProgress) || !_.isUndefined(model.changed.media_file_thumbnail_inprogress)) {

                    if (me.$el.find('.radial-progress').is(':hidden')) {
                        me.render();
                    } else {

                        if (!_.isUndefined(model.changed.uploadingProgress)) {
                            me.$el.find('.radial-progress').attr('data-progress', model.get('uploadingProgress'));
                        } else if (model.changed.media_file_thumbnail_inprogress) {
                            me.$el.find('.radial-progress').addClass('waiting');
                        } else {
                            model.unset('uploadingProgress');
                            me.render();
                        }

                    }

                } else {
                    me.model.getOfferTargets().fetch();
                    me.render();
                }
            },

            onRender: function () {
                var me = this;

                if (_.isNumber(me.model.get('uploadingProgress'))) {
                    me.$el.find('.radial-progress')
                        .attr('data-progress', me.model.get('uploadingProgress'))
                        .show();
                    if (me.model.get('media_file_thumbnail_inprogress')) {
                        me.$el.find('.radial-progress').addClass('waiting');
                    }
                } else {
                    me.$el.find('.offer-ready').show();

                    // remove ability to delete offer by Approver
                    if (App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER) {
                        me.$el.find('.delete-offer').remove();
                        me.$el.find('figcaption').html(me.model.get('name') + '<br><small>' + me.model.get('company_name') + '</small>');
                    }

                    me.model.getOfferTargets().fetch();
                    $.when(me.model.getOfferTargets({fetch: false}).promise).then(function () {
                        me._manageDeleteBtn();
                        me._setTargetStatus();
                    });

                }
            },

            showOffer: function () {
                var me = this;
                if (me.$el.find('.radial-progress').is(':hidden')) {
                    App.appRouter.navigate(me.model.get('uri'), {trigger: true});
                    window.location.replace( window.location.href );
                }
            },

            _manageDeleteBtn: function () {
                var me = this;
                if (!me.model.isAnyTargetOnline()) {
                    me.$el.find('.delete-offer').show();
                } else {
                    me.$el.find('.inner').removeClass('inactive');
                    me.$el.find('.delete-offer').remove();
                }
            },

            _setTargetStatus: function () {
                var me = this,
                    currDisplayGroup;

                me.model.getOfferTargets({fetch: false}).each(function (target) {
                    console.log(target.get('status'));
                    if (target.get('status') !== 'draft') {
                        currDisplayGroup = target.get('target');
                        var status = target.get('status');

                        var permission='';
                        permission = 'data-permission="your_' + status.substring(0, 4) + '_info"';

                        me.$el.find('.targets-status')
                            .append('<div class="' + status + '" ' + permission + '" style="background-image: url(\'/static/images/icons/display-generic.svg\')" title="' + currDisplayGroup.display_name + ' - ' + App.os.dicts.displayGroupStatuses[target.get('status')] + '"></div>');
                    }
                });
            },

            deleteOffer: function (event) {
                event.stopPropagation();
                var me = this;

                if (!(App.currentUser.hasRole(App.os.roles.approver) && App.userContext === App.os.ROLE_CONTEXT.APPROVER)) {
                    if (!me.model.isAnyTargetOnline()) {
                        BootstrapDialog.confirm(App.trans("Do you really want to delete this campaign?"), function (result) {
                            if (result) {
                                me.model.destroy();
                            }
                        });
                    } else {
                        BootstrapDialog.alert(App.trans("Sorry the campaign is online, contact an administrator to take your campaign offline."), function (result) {
                            if (result) {
                                //me.model.destroy();
                            }
                        });

                    }
                }
            }

        });
    });

