
/* START_TEMPLATE */
define('hbs!templates/views/Payments/TopCreditsArea',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<a id=\"number-of-credits\" class=\"header-btn\" style=\"cursor: pointer !important;\">\n    ";
  if (helper = helpers.credits) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.credits); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n    <div class=\"caption\" title=\"Buy more credits\">\n        CREDITS\n    </div>\n</a>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
