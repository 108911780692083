// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Payments/PrivateScreenKitPurchase',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/PrivateScreenKitPurchase', 'ladda-bootstrap', 'stripe', 'views/Payments/StripeModal', 'bootstrap-dialog', 'bootstrap-touchspin'],
    function (App, Backbone, _, $, template, Ladda,  Stripe, StripeModal, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                qtn: '#quantity'
            },

            events: {
                'click .buy-credits-btn': 'buyCredits',
                'touchspin.on.stopspin @ui.qtn': '_updatePrice',
                'change @ui.qtn': '_updatePrice'
            },

            initialize: function (options) {
                this.plan = options && options.plan;
                this.model = App.currentCompany.getSubscriptionPlans().getCurrent();
            },

            onRender: function () {
                var me = this;

                me.$el.find('#quantity').TouchSpin({
                    initval: 1,
                    min: 1,
                    max: 10,
                    step: 1
                });

                me._updatePrice();
            },

            handleStripe: function(data){
                var token;
                if(data.redirect_url && data.redirect_url.indexOf('stripe')!=-1){
                    token = data.redirect_url.slice(7);

                    App.mainRegion.currentView.modalRegion.$el.removeClass('fade');
                    App.mainRegion.currentView.modalRegion.$el.modal('hide');
                    App.mainRegion.currentView.modalRegion.$el.addClass('fade');

                    var modalView = new StripeModal({'token': token, 'uri': data.uri});
                    var callback = function () {
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    };
                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);
                    } else {
                        callback();
                    }

                }
            },

            _updatePrice: function () {
                var me = this;
                if (_.isNumber(parseInt(me.ui.qtn.val()))) {
                    me.$el.find('.final-price').html(parseInt(me.ui.qtn.val()) * me.model.get('privateScreenFee')[App.currency]);
                }
            },

            buyCredits: function (event) {
                var me = this,
                    data = {},
                    quantity = parseInt(me.ui.qtn.val()),
                    l = Ladda.create(document.querySelector('.modal-content .buy-credits-btn')),
                    headers = { 'X-Alt-Referer': App.appUrl + '/#profile/payments' };

                data = {
                    "private_screens" : quantity
                };

                l.start();
                me.$el.find('button').addClass('disabled');

                $.ajax({
                    url: App.enrichApiUrl(App.currentCompany.get('uris').Payments),
                    type: 'POST',
                    dataType: 'json',
                    headers: headers,
                    data: JSON.stringify(data),

                    success: function (data, textStatus, jqXHR) {

                        if (data.payment_provider=="Stripe" && data.redirect_url && data.redirect_url.indexOf('stripe')!=-1) {
                            me.handleStripe(data);
                        } else if (!_.isNull(data.redirect_url) && data.redirect_url.indexOf('stripe')==-1) {
                            window.location.href = data.redirect_url;
                        } else {
                            var currKits = App.currentCompany.get('private_screens') || 0,
                                kits = currKits + data.private_screens;

                                // update private screens manually
                                App.currentCompany.set('private_screens', kits);

                                App.vent.trigger('App.layout.paymentStatus.show', 'You have successfully purchased ' + data.private_screens + ' In-store Screen Kit' + (data.private_screens > 1 ? 's' : '') );
                                me.destroy();
                        }
                    },

                    error: function (jqXHR, textStatus, errorThrown) {
                        var errorMsg = 'Sorry. An error occurred while processing your payment. Please try again later or contact Flow.City Administrator.';
                        if (jqXHR.status == 402 && jqXHR.responseJSON.reason) {
                            errorMsg = jqXHR.responseJSON.reason
                        }
                        App.vent.trigger('App.layout.paymentStatus.show', errorMsg, 'alert-danger');
                        me.destroy();
                    }
                });


            }

        });
    });

