
/* START_TEMPLATE */
define('hbs!templates/views/InternalReports/SessionLengths',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                    <tr class=\"session-length-single-cluster\">\n                        <td>\n                            "
    + escapeExpression(((stack1 = (depth0 && depth0.minSessionLength)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " min\n                            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.maxSessionLength), {hash:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                        </td>\n\n                        <td class=\"session-length-bar\">\n                            <div style=\"width: "
    + escapeExpression(((stack1 = (depth0 && depth0.percent)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "%\"><p>"
    + escapeExpression(((stack1 = (depth0 && depth0.count)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</p></div>\n                        </td>\n                    </tr>\n                ";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                                - "
    + escapeExpression(((stack1 = (depth0 && depth0.maxSessionLength)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " min\n                            ";
  return buffer;
  }

function program4(depth0,data) {
  
  
  return "\n                                +\n                            ";
  }

  buffer += "<div class=\"inner\">\n    <div class=\"widget__header\">\n        <h5>&nbsp;<i class=\"fa fa-clock-o\" aria-hidden=\"true\"></i> Session Lengths</h5>\n    </div>\n    <div class=\"widget__body\">\n        <div class=\"session-length-clusters\">\n            <table>\n                <tbody>\n                ";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.clusters), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </tbody>\n            </table>\n\n        </div>\n\n        <div class=\"internal-reports-loading-mask\">\n            <div class=\"radial-progress waiting\">\n                <div class=\"inf\"></div>\n            </div>\n        </div>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
