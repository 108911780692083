// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Payments/Invoices',['App',
        'backbone',
        'hbs!templates/views/Payments/Invoices',
        'hbs!templates/views/Payments/InvoiceHeader',
        'views/Payments/Invoice'
        ],
    function (
        App,
        Backbone,
        template,
        InvoiceHeader,
        Invoice
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: Invoice,

            className: 'form-panel profile-form row no-background',

            childViewContainer: 'ul',

            events:{
                'click #download-invoices': '_downloadInvoices',
                'click #print-invoices': '_printInvoices',
                'click [name="check_report"]': 'selectionWindow',
                'click #show-10-records a': 'show10Records'
            },

            selectionWindow: function (event) {
                var currentTarget = $(event.currentTarget).first(),
                    all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if( currentTarget.parents('.invoice-header').length ) {

                    if(currentTarget[0].checked)
                        for(var i=0; i<len; ++i)
                            all_checkboxes[i].checked = true;
                    else
                        for(var i=0; i<len; ++i)
                            all_checkboxes[i].checked = false;
                }
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items">Loading...</div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            _downloadInvoices: function () {

                var all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(len>0)
                    for(var i=1; i<len; ++i){
                        if(all_checkboxes[i].checked)
                            $(all_checkboxes[i]).parents('.invoice').find('.download-invoice-btn').click();
                    }
            },

            _printInvoices: function () {

                var all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(len>0)
                    for(var i=1; i<len; ++i){
                        if(all_checkboxes[i].checked)
                            $(all_checkboxes[i]).parents('.invoice').find('.print-invoice-btn').click();
                    }
            },

            initialize: function () {
                this.collection = App.currentCompany.getInvoices({fetch: false});

                window.invoicesData = {
                    since: undefined,
                    to: undefined,
                    name: undefined
                };
            },

            onRender: function () {
                var me = this;

                me.collection.url = App.enrichApiUrl(me.collection.url);
                $.when(me.collection.fetch())
                    .then(function() {
                        if (_.size(me.collection) === 0) {
                            me.$el.find('.no-items').html('No invoices so far');
                        }
                        else if ( !$('.invoice-header').length ){
                            $('.invoices_div').find('.invoices').not('.dropdown-menu').prepend(InvoiceHeader);

                            $( function() {
                                var datepickerFrom = $( "#datepicker-from" ),
                                    datepickerTo = $( "#datepicker-to" );

                                datepickerFrom.datepicker({
                                    format: 'yyyy/mm/dd'
                                })
                                    .on('changeDate', function (selected) {
                                        var minDate = new Date(selected.date.valueOf()),
                                            endDate = new Date( datepickerTo[0].value );

                                        window.invoicesData.since = minDate;

                                        if( endDate < minDate) {
                                            datepickerTo.datepicker('setDate', minDate);
                                            window.invoicesData.to = minDate;
                                        }

                                        datepickerTo.datepicker('setStartDate', minDate);
                                        me.filterList();
                                    });

                                datepickerTo.datepicker({
                                    format: 'yyyy/mm/dd'
                                })
                                    .on('changeDate', function (selected) {

                                        window.invoicesData.to = new Date(selected.date.valueOf());
                                        me.filterList();
                                    });
                            });
                        }
                    });
            },
            show10Records: function (event) {
                event.preventDefault();
                var clickedText = $(event.currentTarget).html();
                $('#show-10-records').find('label').html(clickedText);

                this.filterList( parseInt(clickedText) );
            },

            filter: function (child, index, collection) {
                // do not show invoices without actual document
                return _.isString(child.get('download_link'));
            },


            filterList: function (show) {
                var li, listElementTitle, date, to;
                li = $(".invoices").find("li").not('.invoice-header');;

                if(show === undefined || isNaN(show))
                    show = li.length;

                if(window.invoicesData.name === undefined)    window.invoicesData.name = '';

                for (var i = 0; i < li.length; i++) {
                    listElementTitle = $(li[i]).children('.invoice-details').html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    date = new Date( $(li[i]).children('.invoice-date').html() );

                    if (listElementTitle.indexOf(window.invoicesData.name) > -1 &&
                        (date >= window.invoicesData.since || window.invoicesData.since === undefined) &&
                        (date <= window.invoicesData.to || window.invoicesData.to === undefined) &&
                        i <= show)
                    {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

        });
    });
