
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/CreateCampaignBudget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"budget\" class=\"widget text-uppercase no-padding\"> \n	<div class=\"budget-top\" style=\"height: 102px;\">\n		<div class=\"widget text-uppercase panel-heading\" role=\"tab\">\n			<h4 class=\"section-title\" style=\" color:#fff\">\n				Set the budget <i class=\"fa fa-question popover-icon\" title=\"Set the budget\" text=\"Control how much you spend on your campaign.\"></i>\n			</h4>\n		</div>\n		<h1 class=\"text-center range-wrapper\">\n			<span  id=\"range\">0</span>\n		</h1>\n		<a href=\"/#profile/payments\" id=\"buy-credits-here\" class=\"buy-credits-here\">buy more credits</a>\n		<div id=\"credit_slider\"></div>\n	</div>\n	<div class=\"budget-bottom\">\n		<div class=\"panel-footer\" style=\"border: 0; background-color: white; padding: 0; height: 100%;\">\n			<div class=\"widget text-uppercase panel-heading\">\n				<h4 class=\"section-title\" style=\"font-size: 16px;\">\n					Estimated Time <i class=\"fa fa-question popover-icon\" title=\"Estimated Time\" text=\"Flow.City app allows you to control how quickly your budget is spent. Increasing or decreasing intensity will impact the length of your campaign.\"></i>\n				</h4>\n			</div>\n			<h1 class=\"text-center range-wrapper range-wrapper-bottom\" >\n				<span  id=\"range-time\">10 days</span>\n			</h1>\n			<a href=\"#\" id=\"intensity-link\" style=\"width: 100%;\">intensity</a>\n			<div id=\"time_slider\" style=\"width: 100%;\"></div>\n		</div>\n	</div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
