// -*- coding: utf-8 -*-

/*jslint nomen: true*/
define('models/SubscriptionPlan',["App",
        "backbone"
    ],
    function (App,
              Backbone) {

        'use strict';
        var SubscriptionPlan = Backbone.Model.extend({
            uriStencil: '',

            hasFeature: function (featureId) {
                return $.inArray(featureId, this.get('features')) > -1;
            }

        });

        return SubscriptionPlan;
    }
    );
