// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/ApplicationLayout/TopUserInfo',['App', 'jquery', 'underscore', 'hbs!templates/views/ApplicationLayout/TopUserInfo'],
function (App, $, _, template) {

    'use strict';
    return Backbone.Marionette.ItemView.extend({
        template: template,

        className: 'user-info',

        modelEvents: {
            'change' : 'render'
        },

        ui: {
            initials: '#initials'
        },
        initialize: function(){
            var me = this;

            me.listenTo(App.vent, 'App.avatar.loaded', _.bind(me.afterLoad, me));

            me.afterLoad();
        },
        afterLoad: function() {
            var me = this;
            setTimeout(function () {
                if(($('.avatar-wrapper img').attr('src')).indexOf('&s')!==-1){
                    $('.avatar-wrapper').addClass('avatar-uploaded');

                } else {

                        
                    if (_.isEmpty(me.model.get("firstname")) && _.isEmpty(me.model.get("lastname"))) {
                        me.$el.find('br').remove();
                        me.$el.addClass('email-only');
                    } else {
                        $(me.ui.initials).html((me.model.get("firstname")).charAt(0)+(me.model.get("lastname")).charAt(0));
                        me.$el.removeClass('email-only');
                    }
                }

                $('#cust_avatar').attr('src',
                    App.enrichApiUrl(
                        me.model.attributes.uris.Avatar +
                        '?s=' + 100 +
                        '&_=' + Math.floor(Math.random() * 1000000)
                    )
                );
            },1000);
        }

    });
});
