// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/templateName',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function templateName(name) {
        return name.indexOf('default_offer') > -1 ? 'Default template' : name;
    }

    Handlebars.registerHelper('templateName', templateName);
    return templateName;
});
