// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/OfferTarget',["App", "backbone"],
    function (App, Backbone) {
        'use strict';
        var OfferTarget = Backbone.Model.extend({
            uriStencil: 'offer-targets',

            scheduleStatusField: '_schedule-status',

            initialize: function () {
                this.getScheduleStatus = _.debounce(this._getScheduleStatus, 100, true);
                this.getScheduleStatus();
            },

            unsetScheduleStatus: function () {
                this.unset(this.scheduleStatusField);
            },

            _getScheduleStatus: function () {
                var me = this,
                    scheduleStatusUri;

                if (!_.isUndefined(me.get('uris'))) {
                    scheduleStatusUri = App.enrichApiUrl(me.get('uris').ScheduleStatus);

                    if (me.get('status') === 'online') {
                        $.ajax({
                            type: "GET",
                            url: scheduleStatusUri,
                            success: function (data, textStatus, jqXHR) {
                                me.set(me.scheduleStatusField, data);
                            }
                        });
                    }
                }
            }
        });

        return OfferTarget;
    }
);
