
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionOfferList',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Filter by campaign title&nbsp;&nbsp;<input type=\"checkbox\" name=\"filter-by-offer\" class=\"js-switch\" checked /></h4>\n<div data-id=\"filter-by-offer\">\n    <div class=\"report-offers-list\"></div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
