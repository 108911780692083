// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Offers/OfferUploadTemplate',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment', 'ladda-bootstrap', 'bootstrap-dialog',
        'hbs!templates/views/Offers/OfferUploadTemplate',
        'skycons'
    ],
    function (App, Backbone, _, Marionette, $, moment, Ladda, BootstrapDialog,
              template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'create-campaign widget ar-1-1',
            ui: {
                panel2:'#accordion .panel-default:eq(2) .panel-heading a',
                panel1i: '#accordion .panel-default:eq(1) .panel-heading a i',
                panel1: '#accordion .panel-default:eq(1) .panel-heading a',
                panel0: '#accordion .panel-default:eq(0) .panel-heading a i',
                artworkButton: '#artwork-next-button',
                audienceButton: '#audience-next-button',
                sidebar2: '.sidebar-info:eq(2)',
                widgetContent: '.content',
                taxitop: "#TAXI-TOP-CC",
                d6cinema:"#D6-CINEMA-CC",
                twentyfive1:"#25-1-CC",
                twentyfive2:"#25-2-CC",
                letusdesign:"#letusdesign",
                count:'.count',
                addedImg: '#added_object img',
                formatsBlocks: '#all-formats .audiences-blocks',
                allFormats:'#all-formats'
            },
            events: {
                'click .formats-buttons': 'selectFormat',
                'change input': 'readURL',
                'change #credit_slider': 'showCreditChart',
                'click .artwork-screen-sizes .artwork_option': 'selectScreenSize',
                'click #accordion .panel-heading a' : 'nextSection'
            },

            initialize: function (options) {
                var me = this;

                this.audienceData = [];
                this.audienceLabels = [];
                this.audienceColors = [];
                this.flagScreenSize = false;
                this.flagArtwork = false;
                this.currentSection = 1;

                // required dimensions of images in pixels
                this.requiredDim = {
                    taxi_top: {width: 640, height: 192},
                    d6_portrait: {width: 768, height: 1360},
                    // d6_portrait: {width: 1080, height: 1920},
                    iconic_36: {width: 2732, height: 1152},
                    roadside_48: {width: 1280, height: 448}
                }
            },
            onShow:function (){
                var me = this;

            },
            setCustomDate: function(){

            },

            // checks width and height of image comparing it to required values and warns user when they are too small
            imageSizeWarning: function () {
                var me = this;

                var variant = $('div.audiences-blocks.formats-buttons.selected')[0].getAttribute('variant');

                if(variant === "letus-popup")   return;

                var requiredWidth, requiredHeight;
                [requiredWidth, requiredHeight] = me.getRequiredSize(variant);

                if(requiredWidth > window.proposedImageWidth || requiredHeight > window.proposedImageHeight)
                    BootstrapDialog.alert('The proposed image size is ' + window.proposedImageWidth + '*' + window.proposedImageHeight +
                        '\nRequired size is ' + requiredWidth + '*' + requiredHeight);
            },

            selectFormat: function(event){
                var me = this;
                var target = $(event.currentTarget);
                target.closest('.row').find('.audiences-blocks').removeClass('selected').addClass('unselected');
                target.addClass('selected').removeClass('unselected');
                window.campaignFormatCount = parseInt(target.find('.count').html());
                window.selectedFormat = target.find('h4.mo-margin').html();
                window.variant = target.attr('variant');
                $('#offer_variant').attr('value', target.attr('variant'));
                $('#offer_variant').val(target.attr('variant')).focus().focusout();

                if(window.page === 'campaign'){
                    $('.added-popup-wrap').hide();
                    var tileName = target.attr('popup-name');
                    if(tileName === 'letus-popup') {
                        $('#' + tileName).css({'opacity': 0}).show().animate({
                            'top': '-69px',
                            'opacity': 1
                        }, 300);
                    }
                }

                if(window.page === 'dashboard'){
                    // hide popup with "Browse" and "Cancel" buttons
                    $('.added-popup-wrap').hide();
                    if( $('#upload-new-button').hasClass('disabled') ) {
                        $('#'+target.attr('popup-name')).css({'opacity':0}).show().animate({'top':'-69px','opacity':1},300);
                        $('.artwork-preview-text').hide();
                    }

                    // if last added image doesn't fit to new dimensions, warn user
                    if( $('#added_object').html().includes('background')){
                        me.imageSizeWarning();
                    }

                    var selectedOption = $('#all-formats .selected'),
                        addedObject = $('#added_object'),
                        uploadFile = $('#upload-file');
                    // if selected TAXI TOP, only images allowed;
                    // if previously video was added, it is being removed
                    if( selectedOption.attr('variant') === 'taxi_top' ) {
                        uploadFile.attr("accept", "image/*");
                        if( addedObject.html().includes('video'))
                            addedObject.html('<div class="artwork-preview-text">ARTWORK PREVIEW</div>');
                    }
                    // if else, allow both videos and images
                    else
                        uploadFile.attr("accept", "/*");

                    me.width = selectedOption.attr('width');
                    me.height = selectedOption.attr('height');
                    addedObject.css({'width':me.width,'height':me.height});

                    if(parseInt(me.width)>parseInt(me.height)){
                        addedObject.css({'margin-left':'0'});
                        addedObject.find('div').css({'width':me.width,'height':'100%'});
                        $('.added_object_wrapper').css({'text-align':'left'});
                    } else{
                        addedObject.css({'margin-left':'81px'});
                        addedObject.find('div').css({'width':'100%','height':me.height});
                        $('.added_object_wrapper').css({'text-align':'center'});
                    }
                }
                if((window.step2 && window.step3) || window.page!=='dashboard'){
                    console.log(window.campaignFormatCount );
                    App.vent.trigger('recalculate-intensity');
                    App.vent.trigger('App.layout.offer.refreshPreview');
                }

            },

            nextSection: function(event){
                var me = this;
                var href = $(event.currentTarget).attr('href');
                if(href===''){
                    event.preventDefault();
                } else {
                    if(href==='#artwork'){
                        me.currentSection=1;
                    }
                    else if(href==='#audienceLayer'){
                        me.currentSection=2;
                    }
                    else if(href==='#budget'){
                        me.currentSection=3;
                        this.showCreditChart();
                    }
                }

            },


            selectScreenSize: function(event){
                var me = this;
                $('.artwork-screen-sizes .artwork_option').removeClass('faded-in');
                $('#'+event.currentTarget.id).closest('.artwork_option').addClass('faded-in');
                me.flagScreenSize = true;
                me.finishStep1();
            },

            getRequiredSize: function (variant) {
                var requiredHeight, requiredWidth;

                switch (variant){
                    case "taxi_top":
                        requiredHeight = this.requiredDim.taxi_top.height;
                        requiredWidth = this.requiredDim.taxi_top.width;
                        break;
                    case "d6_portrait":
                        requiredHeight = this.requiredDim.d6_portrait.height;
                        requiredWidth = this.requiredDim.d6_portrait.width;
                        break;
                    case "36_iconic":
                        requiredHeight = this.requiredDim.iconic_36.height;
                        requiredWidth = this.requiredDim.iconic_36.width;
                        break;
                    case "48_roadside":
                        requiredHeight = this.requiredDim.roadside_48.height;
                        requiredWidth = this.requiredDim.roadside_48.width;
                }

                return [requiredWidth, requiredHeight];
            },

            // invoked when <input /> gets new element
            readURL:function(event) {
                var me = this,
                    selectedOption = $('#all-formats .selected');
                var input = document.getElementById(event.target.id);
                me.selectedFormatText = selectedOption.find('h4.no-margin').text();
                $('.added-inner-text').hide();
                me.width = selectedOption.attr('width');
                me.height = selectedOption.attr('height');
                if (input.files && input.files[0]) {
                    var reader = new FileReader();

                    // load file (image) from its source
                    reader.onload = function (e) {
                        var src = e.target.result;

                        var newImg = new Image();
                        var requiredHeight, requiredWidth,
                            variant = $('div.audiences-blocks.formats-buttons.selected')[0].getAttribute('variant');

                        if(variant === "letus-popup")
                            return;

                        [requiredWidth, requiredHeight] = me.getRequiredSize(variant);

                        // load image
                        newImg.onload = function() {
                            var height = newImg.height;
                            var width = newImg.width;
                            window.proposedImageHeight = newImg.height;
                            window.proposedImageWidth = newImg.width;

                            // check if dimensions meet requirements
                            if(height < requiredHeight || width < requiredWidth) {
                                BootstrapDialog.alert('The image size is ' + width + '*' + height +
                                    '\nRequired size is ' + requiredWidth + '*' + requiredHeight);

                                $('#upload-file').val('');
                            }
                            else{
                                console.log('WIDTH OF IMAGE: '+me.width);
                                if(src.indexOf('data:video')!=-1){
                                    me.createOffer(newImg, 'video');
                                    $('#added_object').html('<video controls ><source src="'+src+'" type="video/mp4"></video>');
                                } else{
                                    me.createOffer(newImg, 'image');
                                    $('#added_object').html('<div style="background:url('+src+') no-repeat center center; background-size:cover;" ></div>');
                                    me.width = selectedOption.attr('width');
                                    me.height = selectedOption.attr('height');
                                    if(parseInt(me.width)>parseInt(me.height)){
                                        $('#added_object').css({'margin-left':'0'});
                                        $('#added_object div').css({'width':me.width,'height':'100%'});
                                        $('.added_object_wrapper').css({'text-align':'left'});
                                    } else{
                                        $('#added_object').css({'margin-left':'81px'});
                                        $('#added_object div').css({'width':'100%','height':me.height});
                                        $('.added_object_wrapper').css({'text-align':'center'});
                                    }
                                }

                                $( '#progressbar').show();
                            }
                        }
                        newImg.src = src;

                    }
                    reader.readAsDataURL(input.files[0]);
                }
            },

            finishStep1: function(){
                var me = this;
                if(me.flagArtwork==true && me.flagScreenSize==true){
                    $(this.ui.panel1).attr('href',$(this.ui.panel1).attr('hreftarget'));
                    $(this.ui.panel1).click();
                    $(this.ui.panel0).css('opacity',1);
                } else {
                    if(me.flagArtwork==true ){
                        $('#add-artwork-popup').hide();
                    } else {
                        $('#add-artwork-popup').show();
                    }
                }
            },

            passLink:function() {
                var link = prompt("Paste your link here:");
                if (link != null) {
                    $("#added_object").html('<a href="'+link+'" target="_blank">'+link+'</a>');
                    artworkToAudience();
                }
            },


            showCreditChart: function(){
                $('#budget-credits').html('£'+App.currentCompany.get('credits'));
                var creditsArr = [74,56,34,23,11,0];

                if(this.currentSection<3) return;
                $(this.ui.sidebar2).removeClass('hide').click();

                var myChart = echarts.init(document.getElementById('credits-chart'));

                var option = {
                    title: {
                        text: 'Credit balance',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c}'
                    },
                    legend: {
                        left: 'left',
                        data: ['In days']
                    },
                    xAxis: {
                        type: 'category',
                        name: 'x',
                        splitLine: {show: false},
                        data: ['1', '3', '5', '7', '9', '11']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    yAxis: {
                        type: 'log',
                        name: 'y'
                    },
                    series: [
                        {
                            name: '£',
                            type: 'line',
                            data: creditsArr
                        }
                    ]
                };

                myChart.setOption(option);
            },

            onRender: function () {
                var me = this,
                    total;

                window.taxitop = 710;
                window.d6cinema= 2545;
                window.twentyfive1 = 4664;
                window.twentyfive2 = 3452;





                total = 1000;
                $(me.ui.taxitop).circliful({
                    foregroundBorderWidth: 7,
                    backgroundBorderWidth:7,
                    pointSize: 5,
                    percent: window.taxitop/total*100,
                    backgroundColor: '#F4F5F7',
                    foregroundColor: '#0D4B7C',
                    fontColor: '#333'
                });

                $(me.ui.taxitop).find('text').first().remove();
                // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                $(me.ui.taxitop)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-26.svg" class="diagram_icon" ></div>');


                total = 3000;
                $(me.ui.d6cinema).circliful({
                    foregroundBorderWidth: 7,
                    backgroundBorderWidth:7,
                    percent: window.d6cinema/total*100,
                    backgroundColor: '#F4F5F7',
                    foregroundColor: '#0D4B7C',
                    fontColor: '#333'
                });

                $(me.ui.d6cinema).find('text').first().remove();
                // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                $(me.ui.d6cinema)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-27.svg" class="diagram_icon" ></div>');


                total = 5000;
                $(me.ui.twentyfive1).circliful({
                    foregroundBorderWidth: 7,
                    backgroundBorderWidth:7,
                    percent: window.twentyfive1/total*100,
                    backgroundColor: '#F4F5F7',
                    foregroundColor: '#0D4B7C',
                    fontColor: '#000'
                });

                $(me.ui.twentyfive1).find('text').first().remove();
                // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                $(me.ui.twentyfive1)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-SLIM-D6.svg" class="diagram_icon" ></div>');


                total = 4000;
                $(me.ui.twentyfive2).circliful({
                    foregroundBorderWidth: 7,
                    backgroundBorderWidth:7,
                    percent: window.twentyfive2/total*100,
                    backgroundColor: '#F4F5F7',
                    foregroundColor: '#0D4B7C',
                    fontColor: '#000'
                });

                $(me.ui.twentyfive2).find('text').first().remove();
                // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                $(me.ui.twentyfive2)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-29.svg" class="diagram_icon"></div>');

                $(me.ui.letusdesign).circliful({
                    foregroundBorderWidth: 7,
                    backgroundBorderWidth:7,
                    pointSize: 5,
                    percent: 100,
                    backgroundColor: '#F4F5F7',
                    foregroundColor: '#0D4B7C',
                    fontColor: '#333'
                });

                $(me.ui.letusdesign).find('text').first().remove();
                // $("#Nearby").find('text').remove();  UNCOMMENT TO REMOVE PERCENT NUMBER
                // $("#Nearby").find('g').remove();     UNCOMMENT TO REMOVE STICT BETWEEN PERCENT ANG CIRCLE
                $(me.ui.letusdesign)
                    .append('<div class="img-container"><img src="../../../../../static/images/icons/dashboard-30.svg" class="diagram_icon" ></div>');

                // select the appropriate offers/campaigns
                //console.log($(me.ui.formatsBlocks));
                if(App.currentOffer!==undefined && window.page==='campaign'){
                    $(me.ui.formatsBlocks).removeClass('selected').addClass('unselected');
                    if(App.currentOffer.get('offer_variant') !==''){
                        window.selectedFormat = App.currentOffer.get('offer_variant');
                        window.campaignFormatCount = parseInt( $(me.ui.allFormats).find('div[variant="'+App.currentOffer.get('offer_variant')+'"] .count').html());
                        console.log(window.campaignFormatCount);
                        $(me.ui.allFormats).find('div[variant="'+App.currentOffer.get('offer_variant')+'"]').addClass('selected').removeClass('unselected');
                    } else {
                        $(me.ui.allFormats).find('.formats-buttons:eq(0)').addClass('selected').removeClass('unselected');
                    }
                }

                $(me.ui.count).each(function () {
                    $(this).prop('Counter',0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 4000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
            },

            createOffer: function (image, offerType) {
                var me = this,
                    newOffer,
                    newOfferData,
                    l = Ladda.create(document.querySelector('#added_object')),
                    fromTemplate = false,
                    // extConsumer = me.$el.find('input[name="offerType"]:checked').data('consumer') || '';
                    extConsumer = $('div.audiences-blocks.formats-buttons.selected')[0].getAttribute('variant');

                var d = String(new Date());
                var dArr = d.split(" ");
                $('.selected-screens-summary').show();
                $('.selected-screens-summary #selected-screen-type').html(me.selectedFormatText);
                $('.selected-screens-summary p:eq(0)').show();
                l.start();

                newOfferData = {
                    'name': 'Campaign created on ' + (dArr[2] + ' ' + dArr[1] + ' ' + dArr[3] + ' ' + dArr[4]),
                    'source_template': (offerType === 'image' ? defaultOfferImageTemplate : (offerType === 'video' ? defaultOfferVideoTemplate : null)),
                    'offer_type': offerType,
                    'offer_variant': extConsumer,
                    'budget': '0',
                    'intensity': '1',
                    'audience': 'Nearby'
                };


                if( !$('#added_object').html().includes('ARTWORK PREVIEW') ){

                    var data,
                        file = me.$el.find('#all-formats input')[0].files[0],
                        uploadUrl = App.enrichApiUrl(me.model.get('uris').Files),
                        uploadMethod = 'POST',
                        update = false,
                        type = me.model.get('offer_type'),
                        allowedMimeTypes = App.currentOffer.getMediaRequirements() && _.size(App.currentOffer.getMediaRequirements().format) > 0 ? App.currentOffer.getMediaRequirements().format : me.model.supportedMimeTypes[type],
                        allowedMimeTypesTxt = [],
                        dimensions;
                    if(file==undefined) return;
                    console.log(App.enrichApiUrl(uploadUrl));
                    _.each(allowedMimeTypes, function (item, idx, arr) { allowedMimeTypesTxt.push(item.split('/')[1]) });
                    allowedMimeTypesTxt = allowedMimeTypesTxt.join(', ');

                    /*
                     if ($.inArray(file.type, allowedMimeTypes) === -1) {
                     var msg = (type === 'image' ? App.trans("This offer supports only image formats: " + allowedMimeTypesTxt) : App.trans("This offer supports only video formats: " + allowedMimeTypesTxt));
                     BootstrapDialog.alert(msg + App.trans("<br><br>File upload aborted. Try to upload different file."));
                     return false;
                     }
                     */

                    var _startUpload = function () {
                        App.vent.trigger("App.layout.loadingMask.show");

                        if (!_.isUndefined(me.model.getAttachments().at(0))) {
                            update = true;
                            uploadUrl = me.model.getAttachments().at(0).get('uris').Upload;
                            uploadMethod = 'PUT';
                        }

                        $.ajax({
                            url: App.enrichApiUrl(uploadUrl),
                            type: uploadMethod,
                            dataType: "json",
                            data: JSON.stringify({
                                name: file.name
                            })
                        }).done(function (data) {
                            if (data) {
                                me.model.uploadFile(
                                    file,
                                    data.upload_method,
                                    data.uris.Upload,
                                    (update ? data.uris.Uploaded : data.uris.UploadedFiles),
                                    uploadMethod,
                                    _.bind(me.setProgressBar, me),
                                    _.bind(me.finishUploadOnSuccess, me),
                                    _.bind(me.finishUploadError, me),
                                    _.bind(me.finishUploadOnMediaFileUpdated, me));
                            } else {
                                console.log('error');
                            }
                        }).fail(function (data) {
                            console.log('error');
                        });
                    };

                    // get file dimensions
                    if (type === 'image') {

                        //Check whether HTML5 is supported.
                        if (window.File && window.FileReader) {

                            var reader = new FileReader();

                            reader.readAsDataURL(file);
                            reader.onload = function (e) {

                                var image = new Image();
                                //Set the Base64 string return from FileReader as source.
                                image.src = e.target.result;

                                //Validate the File Height and Width.
                                image.onload = function () {
                                    _startUpload();
                                };
                            }
                        } else {
                            _startUpload();
                        }
                    } else {
                        _startUpload();
                    }

                }
                else {
                    console.log(App.currentDepartment);
                    newOffer = App.currentDepartment.getOffers({fetch: false}).create(newOfferData, {
                        at: 0,
                        wait: true,
                        success: function (response) {

                            var url = (response.attributes.uri).replace('/data/', '/#');
                            window.newOfferCreated = response;
                            window.advancedlink = url + '/advanced';
                            me.startUpload(offerType, response);
                            intercomReportEvent('offer_created', {
                                data: JSON.stringify(newOfferData),
                                timestamp: (new Date()).toString()
                            });

                            App.os.setLocalStorageKey('CREATIVE_DONE', true);
                            if (me.greetings) me.greetings.close();
                            //App.appRouter.navigate(newOffer.get('uri'), {trigger: true});
                            //App.mainRegion.currentView.modalRegion.currentView.destroy();
                        },
                        error: function () {
                            me.destroy();
                            BootstrapDialog.show({
                                type: BootstrapDialog.TYPE_DANGER,
                                title: App.trans('Houston! We have a problem!'),
                                message: App.trans("Unfortunatelly an error occurred while creating your new offer.<br><br>Try again."),
                                buttons: [{
                                    label: App.trans("Close"),
                                    action: function (dialog) {
                                        dialog.close();
                                    }
                                }]
                            });
                        }
                    });
                }
            },

            // Upload - first stage
            startFileUpload: function (file) {

                var me = this,
                    fileView;

                // Create temporary model for upload purposes
                this.tempModel = new Attachment({
                    name: file.name,
                    mime_type: file.type
                });

                this.collection.add(this.tempModel);

                fileView = this.children.findByModel(this.tempModel);
                fileView.setUploadMode();

                me.disableForm();

                App.GA('send', 'event', 'Engagement', 'Upload', 'Document', 3);

                $.ajax({
                    url: (_.isUndefined(me.parentModel) ? App.currentView.model.get('uris').Files : me.parentModel.get('uris').Files),
                    type: 'POST',
                    dataType: "json",
                    data: JSON.stringify({
                        name: file.name
                    })
                }).done(function (data) {
                    if (data) {
                        App.msg.info(App.trans('Started uploading your file. You will be notified on finish'));
                        me.uploadFile(file, data.upload_method, data.uris.Upload, data.uris.UploadedFiles);
                    } else {
                        App.msg.error(App.trans('There was a problem uploading your file. Try again later.'), App.trans('Upload error'));
                    }
                }).fail(function (data) {
                    App.msg.error(App.trans('There was a problem uploading your file. Try again later.'), App.trans('Upload error'));
                });
            },

            // Upload - second stage
            uploadFile: function (file, method, uploadUri, finishUri) {

                var me = this,
                    fileView;

                if (!_.isUndefined(file)) {

                    fileView = this.children.findByModel(this.tempModel);

                    $.ajax({

                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();

                            //Upload progress
                            xhr.upload.addEventListener("progress", function (evt) {
                                if (evt.lengthComputable) {
                                    var percentComplete = (evt.loaded / evt.total) * 100;
                                    fileView.setUploadProgress(percentComplete);
                                }
                            }, false);
                            return xhr;
                        },

                        url: uploadUri,
                        type: method,
                        data: file,
                        cache: false,
                        crossDomain: true,
                        contentType: false,
                        dataType: 'json',
                        processData: false,
                        headers: {
                            'X-Upload-Content-Type': file.type
                        },

                        success: function (data, textStatus, jqXHR) {
                            if (_.isUndefined(data.error)) {
                                me.finishUpload(finishUri, data, jqXHR.status);
                            } else {
                                // Handle errors here
                                console.log('ERRORS: ' + data.error);
                                me.enableForm();
                                me.collection.remove(this.tempModel, {silent: true});
                                App.msg.error(App.trans('There was a problem uploading your new file. Try again later.'));
                            }
                        },
                        error: function (data, textStatus, errorThrown) {
                            console.log('ERRORS: ' + data.error);
                            me.enableForm();
                            me.collection.remove(this.tempModel, {silent: true});
                            App.msg.error(App.trans('There was a problem uploading your new file. Try again later.'));

                        }
                    });

                }
            },

            // Upload - third stage (last)
            finishUpload: function (finishUri, data, statusCode) {
                var me = this;
                $.ajax({
                    url: finishUri,
                    type: 'POST',
                    dataType: "json",
                    data: JSON.stringify({
                        'response_content': data,
                        'status_code': statusCode
                    }),
                    cache: false,

                    success: function (data, textStatus, jqXHR) {
                        me.collection.remove(me.tempModel, {silent: true});
                        me.collection.add(data);
                        me.enableForm();
                        App.msg.success(App.trans('Your file has been uploaded.'));
                    },
                    error: function (data, textStatus, errorThrown) {
                        console.log('ERRORS: ' + data.error);
                        me.enableForm();
                        me.collection.remove(this.tempModel, {silent: true});
                        App.msg.error(App.trans('There was a problem uploading your new file. Try again later.'));
                    }
                });
            },


            startUpload: function (type, model) {
                App.currentOffer = model;
                var me = this;
                me.model = model;
                var file = me.$el.find('#all-formats input')[0].files[0],
                    uploadUrl = App.enrichApiUrl(me.model.get('uris').Files),
                    uploadMethod = 'POST',
                    update = false,
                    allowedMimeTypes = App.currentOffer.getMediaRequirements() && _.size(App.currentOffer.getMediaRequirements().format) > 0 ? App.currentOffer.getMediaRequirements().format : me.model.supportedMimeTypes[type],
                    allowedMimeTypesTxt = [];

                console.log(type);
                _.each(allowedMimeTypes, function (item, idx, arr) { allowedMimeTypesTxt.push(item.split('/')[1]) });
                allowedMimeTypesTxt = allowedMimeTypesTxt.join(', ');

                // checks if file has proper format - doesn't work properly
                // if ($.inArray(file.type, allowedMimeTypes) === -1) {
                //     console.warn('--- bad file.type ---');
                //     var msg = (type === 'image' ? App.trans("This offer supports only image formats: " + allowedMimeTypesTxt) : App.trans("This offer supports only video formats: " + allowedMimeTypesTxt));
                //     BootstrapDialog.alert(msg + App.trans("<br><br>File upload aborted. Try to upload different file."));
                //     return false;
                // }

                var _startUpload = function () {
                    //App.vent.trigger("App.layout.loadingMask.show");

                    if (!_.isUndefined(me.model.getAttachments().at(0))) {
                        update = true;
                        uploadUrl = me.model.getAttachments().at(0).get('uris').Upload;
                        uploadMethod = 'PUT';
                    }

                    $.ajax({
                        url: App.enrichApiUrl(uploadUrl),
                        type: uploadMethod,
                        dataType: "json",
                        data: JSON.stringify({
                            name: file.name
                        })
                    }).done(function (data) {
                        if (data) {
                            window.step1 = true;
                            if(window.step2 === true && window.step3 === true){
                                $('#create-campaign-button').removeClass('disabled');
                                $('#advanced-campaign-button').removeClass('disabled');
                            } else {
                                $('#create-campaign-button').attr('href','#').addClass('disabled');
                                $('#advanced-campaign-button').attr('href','#').addClass('disabled');
                            }
                            $('#upload-new-button').removeClass('disabled');
                            $('.audience-row .white-panel').animate({'opacity':'1'},300);
                            $('.audience-row .white-panel-fadeout').hide();
                            me.model.uploadFile(
                                file,
                                data.upload_method,
                                data.uris.Upload,
                                (update ? data.uris.Uploaded : data.uris.UploadedFiles),
                                uploadMethod,
                                _.bind(me.setProgressBar, me),
                                _.bind(me.finishUploadOnSuccess, me),
                                _.bind(me.finishUploadError, me),
                                _.bind(me.finishUploadOnMediaFileUpdated, me));
                        } else {
                            console.log('error');
                        }
                    }).fail(function (data) {
                        console.log('error');
                    });
                };

                // get file dimensions
                if (type === 'image') {

                    //Check whether HTML5 is supported.
                    if (window.File && window.FileReader) {

                        var reader = new FileReader();

                        reader.readAsDataURL(file);
                        reader.onload = function (e) {

                            var image = new Image();
                            //Set the Base64 string return from FileReader as source.
                            image.src = e.target.result;

                            //Validate the File Height and Width.
                            image.onload = function () {

                                if (App.currentOffer.getMediaRequirements()) {

                                    var width = App.currentOffer.getMediaRequirements().width ? parseInt(App.currentOffer.getMediaRequirements().width) : null,
                                        height = App.currentOffer.getMediaRequirements().height ? parseInt(App.currentOffer.getMediaRequirements().height) : null;

                                    // if image is smaller than required size, it is rejected
                                    if (( width && this.width < width) || (height && this.height < height)) {
                                        BootstrapDialog.alert("Mobile offer requires " + App.currentOffer.getMediaRequirements().width
                                            + "x"
                                            + App.currentOffer.getMediaRequirements().height
                                            + "px image. Try to upload different file.");

                                        $('#added_object').html('<div class="artwork-preview-text">ARTWORK PREVIEW</div>');
                                        $( '#progressbar').hide();

                                        return false;
                                    } else {
                                        _startUpload();
                                    }

                                } else {
                                    /*
                                    if (this.width < 1080 || this.height < 1080) {
                                        BootstrapDialog.alert("Offer image should have at least 1920x1080px. Try to upload differen file.");
                                        return false;
                                    } else {
                                        _startUpload();
                                    }*/
                                    _startUpload();
                                }
                            };

                        }
                    } else {
                        _startUpload();
                    }
                } else {
                    _startUpload();
                }




            },

            setProgressBar: function (percentComplete) {
                var me = this;

                if (!me.isDestroyed) {

                    me.model.set({
                        'uploadingProgress': percentComplete
                    }, {xhr: {}});

                    if(percentComplete===100){
                        me.finishUploadOnSuccess();
                    }

                    $( '#progressbar').find('.radial-progress').attr('data-progress', percentComplete);

                }

            },

            finishUploadOnSuccess: function () {
                var me = this;
                $('#added_object').find('img').css({'opacity':1});
                console.log('success');
                if (!me.isDestroyed) {
                    $( '#progressbar').hide();
                    me.setProgressBar('0%');
                    me.model.unset('uploadingProgress');
                }
                App.vent.trigger("App.layout.loadingMask.hide");
            },

            finishUploadError: function () {
                var me = this;
                console.log('error');
                if (!me.isDestroyed) {
                    $( '#progressbar').hide();
                    me.setProgressBar('0%');
                    me.model.unset('uploadingProgress');
                }
                App.vent.trigger("App.layout.loadingMask.hide");
                BootstrapDialog.alert("An external error occured while processing your request. Please try again or contact Flow.City administrator.");
            },

            finishUploadOnMediaFileUpdated: function () {
                var me = this;
                console.log('update media');
                if (!me.isDestroyed) {
                    App.vent.trigger('App.layout.offer.refreshPreview');
                }
            },

            onDestroy: function () {
                var me = this;

            }

        });
    });
