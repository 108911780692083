
/* START_TEMPLATE */
define('hbs!templates/views/ScreenPlanning-mockup',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"row\">\n    <div class=\"col-lg-12\" style=\"background-color: #fff;\">\n        <img src=\"static/images/screen-planning.png\" alt=\"Under development\" class=\"img-responsive\" style=\"width: 100%\">\n        <br>\n        <img src=\"static/images/budget.png\" alt=\"Under development\" class=\"img-responsive\" style=\"width: 100%\">\n    </div>\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
