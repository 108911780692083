// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,window,console,setTimeout*/
define('layouts/ModalRegion',['App', 'jquery', 'underscore', 'backbone'],
    function (App, $, _, Backbone) {

        'use strict';
        return Backbone.Marionette.Region.extend({

            el: "#modal",

            constructor: function () {
                _.bindAll(this);
                Backbone.Marionette.Region.prototype.constructor.apply(this, arguments);
                this.on("show", this.showModal, this);
            },

            getEl: function (selector) {
                var $el = $(selector);
                $el.on("hidden", this.close);
                return $el;
            },

            showModal: function (view) {
                var me = this,
                    modalData = me.$el.data()['bs.modal'];

                if (_.isUndefined(modalData)) {
                    if (view.required) {
                        me.$el.modal({backdrop: 'static', keyboard: false});
                    }
                } else {
                    if (view.required) {
                        modalData.options.backdrop = 'static';
                        modalData.options.keyboard = false;
                    } else {
                        modalData.options.backdrop = true;
                        modalData.options.keyboard = true;
                    }
                }

                view.on("destroy", this.hideModal, this);
                if (view.callback) {
                    this.callback = view.callback;
                }

                this.$el.on('hidden.bs.modal', function (e) {
                    if (me.callback) {
                        me.callback();
                        delete(me.callback);
                    }
                });

                this.$el.modal('show');
            },

            hideModal: function () {
                this.$el.modal('hide');
            }
        });
    });
