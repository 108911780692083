
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferTile',['hbs','handlebars','templates/helpers/offerThumbnail','templates/helpers/formatDateTime'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"inner ";
  if (helper = helpers.offer_type) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.offer_type); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " inactive\" data-permission=\"your_list_btn\">\n    <div class=\"radial-progress\" data-progress=\"0\">\n        <div class=\"circle\">\n            <div class=\"mask full\">\n                <div class=\"fill\"></div>\n            </div>\n            <div class=\"mask half\">\n                <div class=\"fill\"></div>\n                <div class=\"fill fix\"></div>\n            </div>\n            <div class=\"shadow\"></div>\n        </div>\n        <div class=\"inset\">\n            <div class=\"percentage\"></div>\n        </div>\n        <div class=\"inf\"></div>\n    </div>\n    <div class=\"offer-ready\">\n        <div class=\"offer-thumbnail\" style=\"background-image: url("
    + escapeExpression((helper = helpers.offerThumbnail || (depth0 && depth0.offerThumbnail),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.media_file_thumbnail), (depth0 && depth0.offer_type), options) : helperMissing.call(depth0, "offerThumbnail", (depth0 && depth0.media_file_thumbnail), (depth0 && depth0.offer_type), options)))
    + ");\"></div>\n        <div class=\"targets-status\"></div>\n        <div class=\"delete-offer\" data-permission=\"your_dele_btn\">&times;</div>\n    </div>\n    <figcaption>";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        <div class=\"creator\" title=\"Offer created by "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.creator)),stack1 == null || stack1 === false ? stack1 : stack1.displayName)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " on "
    + escapeExpression((helper = helpers.formatDateTime || (depth0 && depth0.formatDateTime),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.created), options) : helperMissing.call(depth0, "formatDateTime", (depth0 && depth0.created), options)))
    + "\"><div class=\"\" style=\"background-image: url("
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.creator)),stack1 == null || stack1 === false ? stack1 : stack1.avatarUri)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ")\"></div>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.creator)),stack1 == null || stack1 === false ? stack1 : stack1.displayName)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n    </figcaption>\n</div>\n\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
