/*jslint nomen: true*/
/*global define*/
define('templates/helpers/offerThumbnail',['App',
        'underscore',
        'handlebars',
        'moment'],
        function (
                App,
                _,
                Handlebars,
                moment) {
    'use strict';

    function offerThumbnail(mediaFileThumbnail, offerType) {

        var videoThumbnail = '/static/images/video-cover.jpg',
            urlThumbnail = '/static/images/url-cover.jpg',
            emptyThumbnail = '/static/images/empty-cover.jpg',
            multiThumbnail = '/static/images/multi-cover.jpg',
            textThumbnail = '/static/images/text-cover.jpg';

        if (offerType === 'video') {
            return mediaFileThumbnail ? mediaFileThumbnail : videoThumbnail;
        } else if (offerType === 'link') {
            return urlThumbnail;
        } else if (offerType === 'multi') {
            return multiThumbnail;
        } else if (offerType === 'no-artwork') {
            return textThumbnail;
        } else if (offerType === 'image') {
            if (mediaFileThumbnail) {
                return mediaFileThumbnail;
            } else {
                return emptyThumbnail;
            }
        } else {
            return emptyThumbnail;
        }
    }

    Handlebars.registerHelper('offerThumbnail', offerThumbnail);
    return offerThumbnail;
});
