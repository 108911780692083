
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionDisplayGroups',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Select display groups</h4>\n<div class=\"radio radio-info radio-inline\"><input type=\"radio\" name=\"displayGroupsFilter\" id=\"displayGroupsFilter1\" value=\"all\"><label for=\"displayGroupsFilter1\">All groups</label></div>\n<div class=\"radio radio-info radio-inline\"><input type=\"radio\" name=\"displayGroupsFilter\" id=\"displayGroupsFilter2\" value=\"selected\" checked><label for=\"displayGroupsFilter2\">Selected:</label></div>\n<div data-id=\"filter-by-display-group\" style=\"margin-top: 20px;\">\n    <div class=\"report-display-groups-list\"></div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
