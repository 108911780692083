// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleTime',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleTime', 'moment', 'bootstrap-daterangepicker'],
    function (App, $, _, Backbone, _OfferScheduleBase, template, moment) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane active',

            weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

            template: template,

            baseTime: moment("2015-01-01 00:00:00"),

            ui: {
                dateRangeInput: '.range-input',
                dateRangeInputCustom: '[data-range="custom"]',
                dateRangeInputUnpubslished: '[data-range="until-unpublished"]',
                dateRangeCalendar: '.range-calendar',
                timeSchedulerType: 'input[name="timeScheduleType"]',
                weekdays: '[name="weekdays[]"]',
                allDay: '[name="all_day"]',
                timeframeSlider: '.schedule-timeframe-slider',
                everyday: '[name="everyday"]',
                weekdaysSelector: '.weekdays-selector'
            },

            bindings: {
                '[data-field="schedule_start"]': {
                    observe: 'schedule_start',
                    onGet: '_formatDate'
                },
                '[data-field="schedule_stop"]': {
                    observe: 'schedule_stop',
                    onGet: '_formatDate'
                },
                '[data-field="time_start"]': {
                    observe: 'time_start',
                    onGet: '_formatTime'
                },
                '[data-field="time_stop"]': {
                    observe: 'time_stop',
                    onGet: '_formatTime'
                }
            },

            events: {
                'change @ui.timeSchedulerType': '_switchDrawer',
                'apply.daterangepicker @ui.dateRangeInput' : '_applySchedulerDates',
                'click .date-range-selector button' : '_setDateRange',
                'click @ui.weekdays' : '_updateWeekdays',
                'change [name="all_day"]' : '_toggleTimeframeSlider',
                'change @ui.everyday' : '_toggleWeekdays'
            },

            initialize: function () {
                var me = this;
                $(window).on("resize", _.bind(me._updateTimeframeSliderLabels, me));
                me.model.set("locked", App.currentOffer.get("locked"), {silent: true});
                me.listenTo(App.vent, 'App.currentOffer.schedule.save', _.bind(me._saveNow, me));
                me.listenTo(App.vent, 'App.currentOffer.schedule.render', _.bind(me.render, me));
            },
            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                this.stickit();
                me._setupWeekdaysCtrl();
                me._getPlayableWeekdays();


                _.defer(function () {

                    if (!me.isDestroyed && me.ui.dateRangeCalendar.length > 0) {

                        me.ui.dateRangeCalendar.empty();

                        me.ui.dateRangeInput.daterangepicker({
                            "autoApply": true,
                            "fcSchedulerHack": true,
                            "parentEl": '.range-calendar',
                            "startDate": moment(me.model.get("schedule_start")),
                            "endDate": !_.isNull(me.model.get("schedule_stop")) ? moment(me.model.get("schedule_stop")) : moment(me.model.get("schedule_start")).endOf('month'),
                            "minDate": moment(),
                            "opens": "left",
                            "drops": "down",
                            "buttonClasses": "btn btn-sm",
                            "applyClass": "btn-success",
                            "cancelClass": "btn-default"
                        });

                        me.ui.dateRangeCalendar.find('> div.daterangepicker').removeClass('dropdown-menu');

                        // enable proper drawer on render
                        if (!me.model.get('active')) {
                            me.$el.find('.scheduler-option .on-demand-drawer').trigger('click');
                        } else {
                            me.$el.find('.scheduler-option .specific-dates-drawer').trigger('click');
                        }

                        me.$el.find('.time-slider').slider({
                            range: true,
                            min: 0,
                            max: 1439,
                            step: 15,
                            values: [
                                !_.isEmpty(me.model.get('time_start')) ? moment("2015-01-01T" + me.model.get('time_start')).diff(me.baseTime, 'minutes') : 480,  // default 8am
                                !_.isEmpty(me.model.get('time_stop')) ? moment("2015-01-01T" + me.model.get('time_stop')).diff(me.baseTime, 'minutes') : 1020  // default 5pm
                            ],
                            start: function(){
                            },
                            slide: function (e, ui) {

                                if (( ui.values[0]) >= ui.values[1]) {
                                    return false;
                                }

                                var time1 = me.baseTime.clone().add(ui.values[0], "minutes"),
                                    time2 = me.baseTime.clone().add(ui.values[1], "minutes");

                                $('.slider-time').html(me._formatTime(time1));
                                $('.slider-time2').html(me._formatTime(time2));

                                me.model.set({
                                    "time_start": time1.format("HH:mm:ss"),
                                    "time_stop": time2.format("HH:mm:ss")
                                });

                                App.vent.trigger("App.currentOffer.scheduleSummary.update");

                                var delay = function () {
                                    var handleIndex = $(ui.handle).data('uiSliderHandleIndex');
                                    var label = handleIndex == 0 ? '#min' : '#max';
                                    $(label).html(me._formatTime(me.baseTime.clone().add(ui.value, "minutes"))).position({
                                        my: 'center bottom',
                                        at: 'center top',
                                        of: ui.handle,
                                        offset: "0, -20px"
                                    });
                                };

                                _.delay(delay, 5);
                            }
                        });


                          
                        //me._updateTimeframeSliderLabels();

                        if (me.model.get('all_day')) {
                            me.ui.timeframeSlider.hide();
                        }

                        console.log(me.model.get('schedule_stop'));
                        if(!me.model.get('schedule_stop')){
                            $(me.ui.dateRangeInputUnpubslished).click();
                        } else {
                           $(me.ui.dateRangeInputCustom).click();
                            me.ui.dateRangeCalendar.slideDown();
                        }
                    }
                });


                                var start = !_.isEmpty(me.model.get('time_start')) ? moment("2015-01-01T" + me.model.get('time_start')).diff(me.baseTime, 'minutes') : 480;
                                var end = !_.isEmpty(me.model.get('time_stop')) ? moment("2015-01-01T" + me.model.get('time_stop')).diff(me.baseTime, 'minutes') : 1020;
                                console.log($(me.$el.find('#max')).css('left'));
                                $(me.$el.find('#min')).css({'left': ((start/1440)*100)+'%'});
                                 $(me.$el.find('#max')).css({'left': ((end/1440)*100)+'%'});
                                  console.log($(me.$el.find('#max')).css('left'));

                return result;
            },

            _updateTimeframeSliderLabels: function () {
                var me = this;
                _.defer(function() {

                    if (!me.isDestroyed) {

                        me.$el.find('#min').html(
                            me._formatTime(
                                me.baseTime.clone().add(
                                    me.$el.find('.time-slider').slider('values', 0), "minutes"
                                )
                            )
                        ).position({
                            my: 'center top',
                            at: 'center bottom',
                            of: me.$el.find('.time-slider a:eq(0)'),
                            offset: "0, 10"
                        });

                        me.$el.find('#max').html(me._formatTime(me.baseTime.clone().add(me.$el.find('.time-slider').slider('values', 1), "minutes"))).position({
                            my: 'center top',
                            at: 'center bottom',
                            of: me.$el.find('.time-slider a:eq(1)'),
                            offset: "0, 10"
                        });
                    }
                })

            },

            _applySchedulerDates: function (event, picker) {
                var me = this;
                me.model.set({
                    "schedule_start" : picker.startDate.format("YYYY-MM-DDT00:00"),
                    "schedule_stop" : picker.endDate.format("YYYY-MM-DDT23:59")
                });
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            },

            _formatDate: function (value, options) {
                return !_.isNull(value) ? moment(value).format("D MMM YYYY") : 'unpublished';
            },

            _formatTime: function (value, options) {
                return !_.isEmpty(value) ? moment(value, "HH:mm:ss").format("hh:mm A") : '-';
            },

            _switchDrawer: function () {
                var me = this,
                    drawerSelector = me.$el.find('input[name="timeScheduleType"]:checked');

                me.$el.find('.drawer').hide();
                me.$el.find(drawerSelector.val()).show(0, function () {
                    _.defer(function () { me._updateTimeframeSliderLabels(); });
                });

                if (drawerSelector.data('drawer') === 'on-demand') {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
            },

            _setDateRange: function (event) {
                var me = this,
                    el = $(event.currentTarget),
                    datePicker = me.ui.dateRangeInput.data('daterangepicker'),
                    dateRange;

                if (el.data('range') === 'custom') {
                    me.ui.dateRangeInput.trigger('click');
                    me.ui.dateRangeCalendar.slideDown();
                } else {
                    me.ui.dateRangeCalendar.slideUp();
                }

                switch(el.data('range')) {
                    case 'this-week':
                        dateRange = [moment().startOf('isoWeek'), moment().endOf('isoWeek')];
                        break;
                    case 'next-week':
                        dateRange = [moment().add(1, 'weeks').startOf('week'), moment().add(1, 'weeks').endOf('isoWeek')];
                        break;
                    case 'this-month':
                        dateRange = [moment().startOf('month'), moment().endOf('month')];
                        break;
                    case 'next-month':
                        dateRange = [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')];
                        break;
                    case 'until-unpublished':
                        dateRange = [moment(), null];
                        break;
                }

                if (!_.isUndefined(dateRange)) {
                    datePicker.setStartDate(dateRange[0]);
                    if (!_.isNull(dateRange[1])) {
                        datePicker.setEndDate(dateRange[1]);
                        datePicker.updateView();
                        datePicker.clickApply();
                    } else {
                        me.model.set({
                            "schedule_start" :dateRange[0].format("YYYY-MM-DDT00:00"),
                            "schedule_stop" : null
                        });
                    }
                }
                App.vent.trigger("App.currentOffer.scheduleSummary.update");

            },

            _toggleWeekdays: function (event) {
                var me = this,
                    el = $(event.currentTarget);

                if (el.is(':checked')) {
                    me.model.set('weekdays', [0,1,2,3,4,5,6]);
                    _.each(me.ui.weekdays, function(w) { $(w).prop('checked', true); });
                    me._getPlayableWeekdays();
                    me.ui.weekdaysSelector.slideUp();
                } else {
                    me.ui.weekdaysSelector.slideDown();
                }
                App.vent.trigger("App.currentOffer.scheduleSummary.update");

            },

            _setupWeekdaysCtrl: function () {
                var me = this;

                _.each(me.ui.weekdays, function(w) {
                    if ($.inArray(parseInt($(w).val()), me.model.get('weekdays')) > -1) {
                        $(w).prop('checked', true);
                    }
                });

                if (_.size(me.model.get('weekdays')) === 7) {
                    me.ui.everyday.prop('checked', true);
                    me.ui.weekdaysSelector.hide();
                } else {
                    me.ui.weekdaysSelector.show();
                }
            },

            _updateWeekdays: function (event) {
                var me = this,
                    el = $(event.currentTarget),
                    weekday = parseInt(el.val()),
                    currentWeekdays = me.model.get('weekdays');

                if (el.is(':checked')) {
                    if ($.inArray(weekday, currentWeekdays) === -1) {
                        currentWeekdays.push(weekday);

                        if (_.size(currentWeekdays) === 7) {
                            me.ui.weekdaysSelector.slideUp();
                            me.ui.everyday.prop('checked', true);
                        }
                    }
                } else {

                    if (_.size(me.$el.find('[name="weekdays[]"]:checked')) === 0) {
                        el.prop('checked', true);
                    } else {
                        if ($.inArray(weekday, currentWeekdays) > -1) {
                            currentWeekdays = _.without(currentWeekdays, weekday);
                        }
                    }
                }

                me.model.set('weekdays', currentWeekdays);
                me._getPlayableWeekdays();

                App.vent.trigger("App.currentOffer.scheduleSummary.update");

            },

            _toggleTimeframeSlider: function (event) {
                var me = this,
                    el = $(this.ui.allDay);

                if (el.is(':checked')) {
                    me.ui.timeframeSlider.slideUp();
                    me.model.set('all_day', true);
                    me._updateTimeframeSliderLabels();
                } else {
                    me.model.set('all_day', false);

                    if (_.isEmpty(me.model.get('time_start'))) {
                        me.model.set('time_start', '08:00:00');
                    }

                    if (_.isEmpty(me.model.get('time_stop'))) {
                        me.model.set('time_stop', '17:00:00');
                    }

                    me.ui.timeframeSlider.slideDown(function () {
                        me._updateTimeframeSliderLabels();
                    });
                        me._updateTimeframeSliderLabels();

                }
                App.vent.trigger("App.currentOffer.scheduleSummary.update");

            },

            _getPlayableWeekdays: function () {
                var me = this,
                    tempArr = [];

                $.each(me.weekdays, function (index, weekday) {
                    if ($.inArray(index, me.model.get('weekdays')) > -1) {
                        tempArr.push(weekday);
                    }
                });
            },

            destroy: function () {
                var me = this,
                    result = OfferScheduleBase.destroy.apply(me, arguments);

                $(window).off("resize", _.bind(me._updateTimeframeSliderLabels, me));
                if (_.isObject(me.ui.dateRangeInput.data('daterangepicker'))) {
                    me.ui.dateRangeInput.data('daterangepicker').remove();
                }

                return result;
            }

        });

    });

