// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/if_type',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function if_type(type) {
        if(type === 'BASIC') {
            var msg = $.ajax({type: "GET", url: "js/app/templates/views/Payments/BasicPlan.html", async: false}).responseText;
            return msg;
        } else  if(type === 'PLUS'){
            var msg = $.ajax({type: "GET", url: "js/app/templates/views/Payments/PlusPlan.html", async: false}).responseText;
            return msg;
        }else  if(type === 'MULTI'){
            var msg = $.ajax({type: "GET", url: "js/app/templates/views/Payments/MultiPlan.html", async: false}).responseText;
            return msg;
        }
    }
    Handlebars.registerHelper('if_type', if_type);
    return if_type;
});
