// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/AdvancedSettings',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/AdvancedSettings', 'backbone', 'moment',
        'views/Reports/SectionProduct',
        'views/Reports/SectionAreaLocation',
        'views/Reports/SectionFormat',
        'views/Reports/SectionAudiencesReached',

        'behaviors/Intercom'],

    function (App, $, _, template, Backbone, moment,
              SectionProduct,
              SectionAreaLocation,
              SectionFormat,
              SectionAudiencesReached
            ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: '',

            events: {
                'click .generate-report-btn': '_generateReport',
                'click #selected-range li': 'selectedRange',
                'change [name="product"]': '_updateReportParameter',
                'change [name="area"]': '_updateReportParameter',
                'change [name="format"]': '_updateReportParameter',
                'change [name="audiences"]': '_updateReportParameter',
                'change [name="campaign_spend"]': '_updateReportDataOutput',
                'change [name="options"]': '_updateReportDataOptions'
            },

            selectedRange: function (event) {
                var clickedText = $(event.currentTarget).find('label').html();
                $('#selected-range').find('button').children('label').html( clickedText );


                if( clickedText.indexOf('Selected range') > -1 ) {
                    $('.date-selector').css('display', '');
                    $('.date-selector input').map(function(){
                        var today = new Date();
                        this.value = today.toLocaleDateString().replace(/\./g, '/');
                    });
                } else {
                    $('.date-selector').css('display', 'none');
                }
            },
            _updateReportParameter: function (event) {
                if (event) {
                    var param = $(event.currentTarget);
                    if( param.val() === 'selected' ) {
                        // param.siblings('div').children('div').attr('disabled', false);
                        // this.reportParams.parameters[param.attr('name')] = 'none';
                    }
                    else {
                        param.parent().parent().find('.local-dropdown > div').attr('disabled', true);
                        this.reportParams.parameters[param.attr('name')] = param.val();
                    }
                }
            },
            _updateReportParameter_child: function (e) {

                var amount = $(e.currentTarget.container).find('.ms-sel-item').length;
                var textsArray = [];

                for(var i=0; i<amount; ++i){
                    textsArray
                        .push( $(e.currentTarget.container).find('.ms-sel-item')[i].textContent );
                }

                var param = $(e.currentTarget.container).parents('.radio').children('input').attr('name');

                this.reportParams.parameters[param] = textsArray.toString();

            },
            _updateReportDataOutput: function (event) {
                if (event) {
                    var param = $(event.currentTarget);
                    this.reportParams[param.attr('name')] = param.val();
                }
            },
            _updateReportDataOptions: function (event) {
                if (event) {
                    var param = $(event.currentTarget);
                    this.reportParams.options[param.val()] = param[0].checked;
                }
            },
            _updateReportParam: function (event) {
                if (event) {
                    var param = $(event.currentTarget);
                    this.reportParams[param.attr('name')] = param.val();
                }
            },

            regions: {
                sectionProduct: '[data-region="section-product"]',
                sectionAreaLocation: '[data-region="section-area-location"]',
                sectionFormat: '[data-region="section-format"]',
                sectionAudiencesReached: '[data-region="section-audiences-reached"]'
            },

            onRender: function () {
                var me = this;
                this.$el.fadeIn('fast');
                this.reportParams = {
                    'type': 'DbReportCampaign',
                    'display_groups': [],
                    'granularity_time': 'all',
                    'granularity_geo': 'all',
                    'offers': [],
                    'parameters': {
                        'product': 'all',
                        'area': 'all',
                        'format': 'all',
                        'audiences': 'all'
                    },
                    'campaign_spend': 'monthly',
                    'options': {
                        'plays': true,
                        'impact': true,
                        'time_of_plays': true,
                        'CPV': true,
                        'campaign_spent': true
                    }
                };

                setTimeout( function() {
                    $( "#datepicker-from" ).datepicker({
                        format: 'yyyy/mm/dd'
                    })
                        .on('changeDate', function (selected) {
                            var minDate = new Date(selected.date.valueOf()),
                                endDate = new Date( $('#datepicker-to')[0].value );

                            window.filterInputData.since = minDate;
                            me.reportParams['timestamp_from'] = minDate;
                            if( endDate < minDate) {
                                $('#datepicker-to').datepicker('setDate', minDate);
                                window.filterInputData.to = minDate;
                            }

                            $('#datepicker-to').datepicker('setStartDate', minDate);
                            me.filterList();

                        });

                    $( "#datepicker-to" ).datepicker({
                        format: 'yyyy/mm/dd'
                    })
                        .on('changeDate', function (selected) {

                            window.filterInputData.to = new Date(selected.date.valueOf());
                            me.reportParams['timestamp_to'] = new Date(selected.date.valueOf());
                            me.filterList();
                        });
                }, 300);
            },

            onShow: function () {
                var me = this;
                me.sectionProduct.show(new SectionProduct({parent: me}));
                me.sectionAreaLocation.show(new SectionAreaLocation({parent: me}));
                me.sectionFormat.show(new SectionFormat({parent: me}));
                me.sectionAudiencesReached.show(new SectionAudiencesReached({parent: me}));
            },

            _generateReport: function () {
                var report = App.currentCompany.getReports().create(this.reportParams, {
                    wait: true,
                    silent: true,
                    success: function() {
                        intercomReportEvent('airtime_report');
                        App.notifyBrowser('Started report generation', 'Your report is being generated. You can see the status of your report in Generated Reports tab.');
                    },
                    error: function() {
                        App.notifyBrowser('Report generation error', 'An error occurred during report generation. Please try again or contact Flow.City administrator.');
                    }
                });
                App.vent.listenTo(report, 'change', function (model, options) {
                    if (options.fromChannel && model.get('ready_state') == 'ready') {
                        App.notifyBrowser('Report generation finished', 'Your report is ready!');
                    }
                });
            }

        });
    });
