// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/PasswordReset/PasswordChangeError',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore', 'hbs!templates/views/PasswordReset/PasswordChangeError'],
    function (App, Backbone, Marionette, $, _, template) {
        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            onRender: function () {
                $.backstretch("/static/images/login_background.jpg", {speed: 500});
            }
        });
    });
