
/* START_TEMPLATE */
define('hbs!templates/views/Profile/ProfileUserDetails',['hbs','handlebars','templates/helpers/avatar'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"white-panel tabs-left\">\n    <div class=\"col-lg-12 detailed\">\n        <h4 class=\"mb\">User details</h4>\n\n        <span class=\"update-status\"></span>\n\n        <div class=\"row\">\n\n            <div class=\"col-md-3\" data-permission=\"user_thum_btn\">\n                <div class=\"profile-avatar\">\n                    <div class=\"profile-avatar__inner\">\n                        <div class=\"profile-avatar__drop-zone picDropZone\" style=\"background-image: url("
    + escapeExpression((helper = helpers.avatar || (depth0 && depth0.avatar),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.avatarUri), 200, options) : helperMissing.call(depth0, "avatar", (depth0 && depth0.avatarUri), 200, options)))
    + ")\"></div>\n\n                        <div class=\"profile-avatar__file-input fileinput fileinput-new\" data-provides=\"fileinput\">\n                            <span class=\"profile-avatar__btn btn-file\">\n                                <span class=\"fileinput-new\"></span><span class=\"fileinput-exists disabled\">Uploading...</span>\n                                <input type=\"file\" id=\"avatarPic\" name=\"avatarPic\">\n                            </span>\n                        </div>\n\n                        <div class=\"profile-avatar__loading-mask\">\n                            <div class=\"radial-progress waiting\">\n                                <div class=\"inf\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-md-9\">\n                <form role=\"form\" class=\"form-horizontal\">\n                    <div class=\"form-group hidden\">\n                        <label class=\"col-lg-2 control-label\">Enable new theme</label>\n\n                        <div class=\"col-lg-4\">\n                            <input type=\"checkbox\" name=\"enable-new-ui\" class=\"js-switch\" />\n                        </div>\n                    </div>\n\n                    <div class=\"form-group\" data-permission=\"user_firs_fiel\">\n                        <label class=\"col-lg-2 control-label\">First name</label>\n\n                        <div>\n                            <input type=\"text\" class=\"form-control editable\" name=\"firstname\" value=\"";
  if (helper = helpers.firstname) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.firstname); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Your first name\" style=\"max-width: 500px;\">\n                        </div>\n                    </div>\n\n                    <div class=\"form-group\" data-permission=\"user_last_fiel\">\n                        <label class=\"col-lg-2 control-label\">Last name</label>\n\n                        <div>\n                            <input type=\"text\" class=\"form-control editable\" name=\"lastname\" value=\"";
  if (helper = helpers.lastname) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.lastname); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Your last name\" style=\"max-width: 500px;\">\n                        </div>\n                    </div>\n\n                    <div class=\"form-group\" data-permission=\"user_phon_fiel\">\n                        <label class=\"col-lg-2 control-label\">Phone No.</label>\n\n                        <div>\n                            <input type=\"text\" class=\"form-control editable\" name=\"phone\" value=\"";
  if (helper = helpers.phone) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.phone); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Phone number\" style=\"max-width: 300px;\">\n                        </div>\n                    </div>\n\n                    <div class=\"form-group hidden\" data-permission=\"user_save_btn\">\n                        <div class=\"col-lg-offset-2 col-lg-10\">\n                            <button type=\"button\" class=\"btn btn-theme save-user-details ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">Save</span></button>\n                        </div>\n                    </div>\n\n                </form>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"col-lg-12 password-change-area\"></div>\n    <div id=\"system-info-footer\">\n        <span><i class=\"fa fa-info\"></i>Environment: ";
  if (helper = helpers.environment) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.environment); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n        <span><i class=\"fa fa-exchange\"></i>API: ";
  if (helper = helpers.api) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.api); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
