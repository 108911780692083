// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Notifications/Notification',['App', 'jquery', 'underscore', 'hbs!templates/views/Notifications/Notification'],
function (App, $, _, template) {

    'use strict';
    return Backbone.Marionette.ItemView.extend({
        template: template,

        events: {
            'click' : '_showNotifications'
        },

        onRender: function() {
            console.log('views/Notifications/Notification.onRender', arguments);
        },

        _showNotifications: function () {
            if (App.currentCompany.hasFeature('tflDisruptions')) {
                App.appRouter.navigate('notifications', true);
            }
        },

        _addOffer: function (event) {
            event.preventDefault();
            event.stopPropagation();
            App.vent.trigger('App.layout.showCreateOffer');
        }
    });
});
