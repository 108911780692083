
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/RetailersFilter',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<input type=\"text\" class=\"form-control filter-value\" placeholder=\"Search Retailer\">\n<span class=\"fa fa-search\"></span>\n<span class=\"fa fa-trash-o clear-filter\"></span>";
  });
return t;
});
/* END_TEMPLATE */
;
