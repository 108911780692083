
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/RetailersDepartmentSelector',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div id=\"departments-filter-box\" class=\"panel-filter-box\">\n    <button class=\"btn btn-default btn-lg btn-top-bar btn-back\"><i class=\"fa fa-chevron-left\"></i></button>\n    <div style=\"float: right;width: 230px;height: 100%;line-height: 58px;\"><b><span class=\"name\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span></b></div>\n    <button class=\"btn btn-default btn-lg btn-top-bar delete-btn\" style=\"position: absolute; right: 0;\"><i class=\"fa fa-trash-o\"></i></button>\n\n</div>\n<div id=\"departments-list\" class=\"panel-list-box\"></div>\n<div id=\"create-department-box\" class=\"panel-footer-box\">\n    <button class=\"btn btn-default btn-lg btn-top-bar pull-right btn-bottom create-btn\"><i class=\"fa fa-plus\"></i> Add new department</button>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
