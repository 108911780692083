
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferScheduleSensor',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"offer-schedule\">\n\n    <h5 class=\"text-left\">I want to play my campaign on displays with specific sensors:</h5>\n\n    <div class=\"offer-schedule-content\">\n        <div class=\"row\">\n            <div class=\"col-sm-12 text-center\">\n                <div class=\"btn-group date-range-selector\" data-toggle=\"buttons\">\n                    <label id=\"offSensorLabel\" class=\"btn btn-default btn-sensor\">\n                        <input type=\"radio\" name=\"sensor\" class=\"sky\" autocomplete=\"off\" value=\"\">\n                        Off\n                    </label>\n                    <label id=\"anySensorLabel\" class=\"btn btn-default btn-sensor\">\n                        <input type=\"radio\" name=\"sensor\" class=\"sky\" autocomplete=\"off\" value=\"any\">\n                        Any\n                    </label>\n                    <label id=\"motionSensorLabel\" class=\"btn btn-default btn-sensor btn-sensor-motion\">\n                        <input type=\"radio\" name=\"sensor\" class=\"sky\" autocomplete=\"off\" value=\"motion\">\n                        Motion\n                    </label>\n                    <label id=\"touchSensorLabel\" class=\"btn btn-default btn-sensor btn-sensor-touch\">\n                        <input type=\"radio\" name=\"sensor\" class=\"sky\" autocomplete=\"off\" value=\"touch\">\n                        Touch\n                    </label>\n                    <label id=\"eyeTrackingSensorLabel\" class=\"btn btn-default btn-sensor btn-sensor-eye\">\n                        <input type=\"radio\" name=\"sensor\" class=\"sky\" autocomplete=\"off\" value=\"eye_tracking\">\n                        Eye Tracking\n                    </label>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
