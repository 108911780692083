// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/DepartmentUserApproverGroups',['App',
        'backbone',
        'hbs!templates/views/Retailers/DepartmentUserApproverGroups',
        'views/Retailers/DepartmentUserApproverGroup',
        'magicsuggest'
        ],
    function (
        App,
        Backbone,
        template,
        RetailerDisplayGroup
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: RetailerDisplayGroup,

            childViewContainer: 'tbody',

            className: 'retailers-display-groups',

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'tr',

                    getTemplate: function () {
                        var templateHtml = '<td colspan="3" class="text-center">No groups configured</td>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            events: {
                'click .addGroupsBtn' : 'onAddGroups'
            },

            onRender: function () {
                var me = this;

                me.suggestedDisplayGroups = me.$el.find('.suggest-display-groups').magicSuggest({
                    allowFreeEntries: false,
                    hideTrigger: true,
                    minChars: 1,
                    placeholder: 'Start typing group name',
                    data: function(query){
                        var availDisplayGroups = me.department.getDisplayGroups().filter(
                            function (group) {
                                return !group.get('admin_group') && $.inArray(group.get('uri'), me.parent.user.getApproverGroups()) == -1;
                            }
                        );
                        var displayGroups = availDisplayGroups.map(function (group) { return { 'id': group.get('uri'), 'name': group.get('display_name') }  });
                        return displayGroups.filter(function(d) { return (d.name).toLowerCase().indexOf(query.toLowerCase()) > -1; });
                    }
                });

                $(me.suggestedDisplayGroups).on('selectionchange', function(e, m) {
                    var btn = me.$el.find('.addGroupsBtn');
                    if (_.size(this.getValue()) > 0) {
                        btn.prop('disabled', false);
                    } else {
                        btn.prop('disabled', true);
                    }
                });

            },

            filter: function (child, index, collection) {
                var me = this;
                return !child.get('admin_group') && $.inArray(child.get('uri'), me.parent.user.getApproverGroups()) > -1;
            },

            initialize: function (options) {
                var me = this;
                me.parent = options && options.parent;
                me.retailerUserUri = options && options.retailerUserUri;
                me.department = options && options.department;
                me.collection = me.department.getDisplayGroups();

                me.childViewOptions = {
                    parent: me
                }
            },

            onAddGroups: function () {
                var me = this;

                _.each(me.suggestedDisplayGroups.getSelection(), function (group) {
                    var approverGroups = _.union(me.parent.user.getApproverGroups(), [group.id]);
                    me._saveApproverGroups(approverGroups);
                });
                me.render();
            },

            onApproverGroupRemove: function (groupUri) {
                this._saveApproverGroups(_.without(this.parent.user.getApproverGroups(), groupUri));
            },

            _saveApproverGroups: function (arr) {
                var me = this;
                !_.isUndefined(arr) && this.parent.user.set('approver_in', arr);
                this.parent.user.save().done(function () {
                    me.render();
                });
            },

            onDestroy: function () {
                $(this.suggestedDisplayGroups).off('selectionchange');
            }

        });
    });
