// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/ForecastWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/ForecastWidget',
         'skycons'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: '',

            ui: {
                forecastIcon: '#weather-icon',
                forecastSummary: '.summary',
                forecastCity: '.city',
                forecastTemp: '.temp'
            },

            url: "https://api.forecast.io/forecast/b007e5322bf867c6667c039471b98a2a/",

            initialize: function (options) {
                this.city = options && options.city || 'London';
                this.units = options && options.units || 'si';
                this.latitude = options && options.latitude || '51.5072';
                this.longitude = options && options.longitude || '0.1275';

                if (options && options.className) {
                    this.$el.addClass(options.className);
                }     

            },

            onRender: function () {
                var me = this;
                me.skycons = new Skycons({"color": "#124D80"});

                setTimeout(function () {
                    if($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) !== -1) {
                        $('.inner.forecast-widget').css({
                            "margin-top": "15px",
                            "color": "#124D80;"
                        }).parent()
                            .addClass('white-panel widget height-equaliser')
                            .css('box-shadow', '0 2px 1px rgba(0,0,0,0.2)')
                            .find('.widget__subtitle').remove();
                    }
                    else{
                        $('.inner.forecast-widget').css('padding', '3.5em 0.5em 0 0.5em');
                    }
                }, 100);
            },

            onShow: function () {
                var me = this,
                    updateForecast = function (forecast) {
                        console.log('Forecast: ', forecast);
                        if (forecast!=null && !me.isDestroyed) {

                            _.defer(function () {
                                me.skycons.add("weather-icon", Skycons[_.underscored(forecast.get('icon')).toUpperCase()]);
                                me.ui.forecastSummary.html(forecast.get('summary'));
                                me.ui.forecastTemp.html(Math.round(forecast.get('temperature')) + (me.units === 'si' ? 'º C' : 'º F'));
                                var location = App.currentDepartment.getLocations().at(0);
                                me.ui.forecastCity.html((location && location.get('city')+' '+ (location.get('postcode') ? location.get('postcode') : '')  || 'Canary Wharf'));
                                me.skycons.play();
                            })

                        }
                    },
                    fetchDefaultLocation = function () {
                        App.currentCompany.getDefaultLocations();
                        $.when(App.currentCompany.getDefaultLocations().promise).then(function () {
                            var forecast = App.currentCompany.getDefaultLocations().at(0).getLatestForecast();
                            $.when(forecast.promise).then(function () {
                                updateForecast(forecast);
                            });
                        });
                    }


                me.skycons = new Skycons({"color": "#124D80"});
                var isCachedForecast = true;

                $.when(App.currentDepartment.getLocations().promise).then(function () {
                    if (App.currentDepartment.getLocations().length>0) {
                        var forecast = App.currentDepartment.getLocations().at(0).getLatestForecast();
                        $.when(forecast.promise).then(function () {
                            if (!_.isEmpty(forecast.get('icon'))) {
                                updateForecast(forecast);
                            } else {
                                fetchDefaultLocation();
                            }
                        });
                    } else {
                        fetchDefaultLocation();
                    }
                });
            },



            onDestroy: function () {
                var me = this;
                if (me.skycons) {
                    me.skycons.pause();
                    me.skycons.remove();
                }
            }

        });
    });

