// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentDetailsLayout',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentDetailsLayout', 'backbone', 'bootstrap-dialog',
    'views/Retailers/DepartmentDetails',
    'views/Retailers/DepartmentUsers',
    'views/Retailers/DepartmentDisplayGroups',
    'views/Retailers/CompanyDetails'],
    function (App, $, _, template, Backbone, BootstrapDialog,
        DepartmentDetails,
        DepartmentUsers,
        DepartmentDisplayGroups,
        CompanyDetails) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            currentRetailerUri: null,

            regions: {
                departmentDetailsPanel: '#department-details-panel'
            },

            ui: {
                topMenuPanel: '#department-details-menu',
                topMenu: 'ul'
            },

            tabs: {
                'department-details' : DepartmentDetails,
                'department-displaygroups' : DepartmentDisplayGroups,
                'department-users' : DepartmentUsers
            },

            events: {
                'click ul li a': '_openProfileTab'
            },

            initialize: function (options) {
                var me = this;
                this.currentTab = 'department-details';
                me.listenTo(App.vent, 'App.retailers.setCurrent', _.bind(me._setCurrentRetailer, me));
                me.listenTo(App.vent, 'App.departments.showDetails', _.bind(me._showDetails, me));
            },

            onRender: function () {
                var me = this,
                    tabView = me.tabs[me.currentTab];

                if (tabView) {
                    me.ui.topMenu.find('li[data-id="' + me.currentTab + '"]').addClass('active');
                    me.departmentDetailsPanel.show(new tabView({parentView: me}));
                } else {
                    me.currentTab = 'user-details';
                    me.render();
                }

                if (!_.isEmpty(me.departmentUri)) {
                    me.ui.topMenuPanel.show();
                }
            },

            _openProfileTab: function (event) {
                event.preventDefault();
                var me = this,
                    tabEl = $(event.currentTarget).parent(),
                    tab = tabEl.data('id'),
                    tabView = me.tabs[tab];

                // clear previous selection
                me.ui.topMenu.find('li').removeClass('active');
                me.ui.topMenu.find('li[data-id="' + tab + '"]').addClass('active');
                me.departmentDetailsPanel.show(new tabView({parentView: me}));
            },

            _showDetails: function (departmentUri) {
                var me = this;
                console.log('App.department.showDetails: ', departmentUri);
                me.departmentUri = departmentUri;
                //App.vent.trigger('App.retailers.setActive', retailerUri);
                me.model = App.currentUser.getRetailers().get(me.currentRetailerUri).getDepartments().get(departmentUri);
                me.render();
            },

            _setCurrentRetailer: function (retailerUri) {
                var me = this;
                me.currentRetailerUri = retailerUri || null;

                me.departmentDetailsPanel.show(new CompanyDetails({company: App.currentUser.getRetailers().get(retailerUri)}));
            }
        });
    });
