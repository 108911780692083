// -*- coding: utf-8 -*-


/*global define*/
define('collections/Departments',["backbone", "models/Department"],
    function (Backbone, Department) {

        'use strict';
        return Backbone.Collection.extend({
            model: Department,

            comparator: function (department) {
                return _.size(department.get('ancestors'));
            },

            getDepartmentTree: function () {
                var me = this,
                    map = {},
                    tree = [],
                    node,
                    departments = me.map(function (department) {
                        return {
                            'text': department.get('name'),
                            'id' : department.get('uri'),
                            'ancestors': department.get('ancestors')
                        }
                    });

                for (var i = 0; i < departments.length; i += 1) {
                    node = departments[i];
                    map[node.id] = i;
                    if (_.size(node.ancestors) > "0") {
                        // add children if necessary
                        if (_.isUndefined(departments[map[_.last(node.ancestors)]]['children'])) { departments[map[_.last(node.ancestors)]]['children'] = [] }
                        departments[map[_.last(node.ancestors)]]['children'].push(node);
                    } else {
                        tree.push(node);
                    }
                }

                return tree;

            }

        });
    });
