
/* START_TEMPLATE */
define('hbs!templates/views/Payments/PaymentsLayout',['hbs','handlebars','templates/helpers/subscriptionPlan','templates/helpers/formatDate','templates/helpers/feature'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this, functionType="function";

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.payable), {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.next_period_start), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  return buffer;
  }
function program3(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                    <p>You are paid up through <strong>"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.next_period_start), "MMMM Do YYYY", "", options) : helperMissing.call(depth0, "formatDate", (depth0 && depth0.next_period_start), "MMMM Do YYYY", "", options)))
    + "</strong></p>\n                ";
  return buffer;
  }

function program5(depth0,data) {
  
  
  return "\n        <div class=\"separator\"></div>\n        <div data-permission=\"subs_buyc_btn\" class=\"buy-credits-btn\">\n            <button class=\"btn btn-sm\">Buy More Credits</button>\n        </div>\n        ";
  }

  buffer += "<div class=\"col-lg-12 detailed no-padding\" style=\"color: #10447D;\">\n    <div class=\"row\">\n        <div class=\"profile-title col-md-12\">\n            <div class=\"pull-left white-panel perm-active tabs-left\" data-permission=\"subs_plan_info\">\n                <div class=\"col-lg-12\">\n                    <p style=\"margin-bottom: 1em;\">Choose your subscription plan or buy credits.</p>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <!-- status alert -->\n    <div class=\"payment-alert\">\n        <div class=\"alert\" style=\"display: none\">\n            <a href=\"#\" class=\"close\" aria-label=\"close\">&times;</a>\n            <strong></strong>\n        </div>\n    </div>\n\n    <!-- current payment status -->\n    <div class=\"payment-status\">\n        <div class=\"payment-status-info white-panel\" data-permission=\"subs_plan_info\">\n            <p>You are currently using <strong>Flow.City ";
  if (helper = helpers.active_plan) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.active_plan); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</strong> plan</p>\n            ";
  stack1 = (helper = helpers.subscriptionPlan || (depth0 && depth0.subscriptionPlan),options={hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, "BASIC", "PLUS", "MULTI", options) : helperMissing.call(depth0, "subscriptionPlan", "BASIC", "PLUS", "MULTI", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n            <p>You have <strong>";
  if (helper = helpers.credits) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.credits); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</strong> credits</p>\n        </div>\n\n        ";
  stack1 = (helper = helpers.feature || (depth0 && depth0.feature),options={hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data},helper ? helper.call(depth0, "creditsAquisition", options) : helperMissing.call(depth0, "feature", "creditsAquisition", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </div>\n\n    <!-- subscription plans selector -->\n    <div class=\"subscription-plans white-panel\"></div>\n\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
