// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/Display',["App", "backbone"],
    function (App, Backbone) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var Display = Backbone.Model.extend({
            uriStencil: 'displays',

            hasSensors: function () {
                return _.isBoolean(this.get('aim_sensor_connected'));
            },

            sensorStatus: function () {
                if (this.hasSensors()) {
                    return this.get('aim_sensor_connected');
                }
            }
        });

        return Display;
    }
);
