// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentUser',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentUser', 'backbone', 'views/Retailers/DepartmentUserConfiguration'],
    function (App, $, _, template, Backbone, DepartmentUserConfiguration) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            tagName: 'li',
            className: 'department-user__item',

            regions: {
                userConfiguration: '.user-configuration'
            },

            ui: {
                avatarBox: '.profile-avatar',
                picDropZone: '.picDropZone',
                uploadBtn : '.fileinput'
            },

            events: {
                'click .expandBtn' : '_expand'
            },

            initialize: function (options) {
                var me = this;
                me.department = options && options.department;
            },

            onRender: function () {
                var me = this;

                me.ui.uploadBtn.on('change.bs.fileinput', function (event) {
                    if (event.namespace === "bs.fileinput") {
                        me.uploadAvatar(me.$el.find('[name="avatarPic"]')[0].files[0]);
                    }
                });
            },

            _expand: function () {
                var me = this;
                //me.$el.toggleClass('expanded');
                me.$el.trigger('user.expand', me.model);
                me.userConfiguration.show(new DepartmentUserConfiguration({ parent: me, retailerUserUri: me.model.get('uri'), department: me.department }));
            },

            uploadAvatar: function (file) {

                var me = this;

                if (!_.isUndefined(file)) {

                    me.ui.avatarBox.addClass('uploading');

                    $.ajax({
                        url: this.model.get('uri') + '/avatar',
                        type: 'POST',
                        data: file,
                        cache: false,
                        dataType: 'json',
                        processData: false,
                        contentType: "application/json; charset=utf-8",
                        success: function (data) {
                            if (_.isUndefined(data.error)) {
                                me.model.set('avatarUri', App.os.cutUrlParams(me.model.get('avatarUri')) + "?_=" + _.random(10000000, 99999999));
                                me.render();
                                me._expand();

                            } else {
                                console.log('ERRORS: ' + data.error);
                                me.ui.uploadBtn.fileinput('reset');
                                me.ui.avatarBox.removeClass('uploading');
                            }
                        },
                        error: function (data) {
                            console.log('ERRORS: ' + data.error);
                            me.ui.uploadBtn.fileinput('reset');
                            me.ui.avatarBox.removeClass('uploading');
                            // STOP LOADING SPINNER
                        }
                    });
                }
            },

            onLoadingMaskToggle: function (forceHide) {
                if (forceHide) {
                    this.$el.find('.loading-mask').hide();
                } else {
                    this.$el.find('.loading-mask').toggle();
                }
            }
        });
    });
