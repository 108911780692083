
/* START_TEMPLATE */
define('hbs!templates/views/Payments/MediaPlans',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"white-panel tabs-left\" style=\"height: 70vh;\">\n    <iframe id=\"ng-iframe\" src=\"http://localhost:9090/ng/dist/index.html?tab=mediaPlans\" frameborder=\"0\" style=\"width: 100%; height: 375px;\"></iframe>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
