
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/ChartWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"widget__body\">\n    <div id=\"";
  if (helper = helpers.chartId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.chartId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"  style=\"width: 100%; height: 100%\" ></div>\n</div>\n\n<a href=\"#profile/payments\" class=\"upgrade\">UPGRADE</a>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
