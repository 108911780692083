// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/SectionDateRangeSelector',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/SectionDateRangeSelector', 'backbone', 'moment', 'bootstrap-datepicker'],
    function (App, $, _, template, Backbone, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'date-range-section',

            ui: {
                dateRange: '#input-daterange',
                dateRangeFrom: '#dateRangeFrom',
                dateRangeTo: '#dateRangeTo',
                dateRangeOpt: '[name="dateRange"]'
            },

            dateRanges: {
                'this-week': [moment().startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss'), moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss')],
                'last-week': [moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DDTHH:mm:ss'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss')],
                'this-month': [moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'), moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')],
                'last-month': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss')],
                'this-year': [moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss'), moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss')]
            },

            events: {
                'change @ui.dateRangeOpt' : '_onDateRangeOptChange'
            },

            initialize: function (options) {
                this.parent = options && options.parent;
            },

            onRender: function () {
                var me = this;
                me._updateDateRange(me.dateRanges['this-week'][0], me.dateRanges['this-week'][1]);
            },

            onBeforeShow: function () {
                var me = this;

                _.delay(function () {

                    me.ui.dateRangeFrom.val(moment().format('YYYY/MM/DD'));
                    me.ui.dateRangeTo.val(moment().format('YYYY/MM/DD'));
                    me.ui.dateRange.datepicker({
                        format: "yyyy/mm/dd",
                        todayBtn: "linked",
                        orientation: "top right",
                        disableTouchKeyboard: true
                    }).on('changeDate', function(e) {
                        me._updateDateRange(moment(me.ui.dateRangeFrom.val(), 'YYYY/MM/DD').startOf('day').format('YYYY-MM-DDTHH:mm:ss'), moment(me.ui.dateRangeTo.val(), 'YYYY/MM/DD').endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
                    });
                }, 100);

            },

            _updateDateRange: function (startDate, endDate) {
                this.parent.reportParams.timestamp_from = startDate;
                this.parent.reportParams.timestamp_to = endDate;
            },

            _onDateRangeOptChange: function (event) {
                var me = this,
                    target = event.currentTarget;

                if ($(target).val() === 'custom') {
                    me.ui.dateRange.show();
                    me._updateDateRange(moment(me.ui.dateRangeFrom.val(), 'YYYY/MM/DD').startOf('day').format('YYYY-MM-DDTHH:mm:ss'), moment(me.ui.dateRangeTo.val(), 'YYYY/MM/DD').endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
                } else {
                    me._updateDateRange(me.dateRanges[$(target).val()][0], me.dateRanges[$(target).val()][1]);
                    me.ui.dateRange.hide();
                }
            }

        });
    });
