// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/LoopManagement/DisplayGroupsSelectorItem',['App', 'jquery', 'underscore', 'hbs!templates/views/LoopManagement/DisplayGroupsSelectorItem', 'backbone'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            className: 'display-groups-item-btn',

            ui: {
                inner: '.inner'
            },

            events: {
                'click' : '_loadLoop'
            },

            onRender: function () {
                var me = this;
                me.ui.inner.css('background-image', 'url(' + me.model.getIcon() + ')');
            },

            _loadLoop: function () {
                var me = this;
                console.log(me.model.get('uri'));
                App.vent.trigger("App.layout.loopManagement.showLoop", me.model);
            }

        });
    });
