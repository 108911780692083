// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/DisplayGroups/CreateDisplayGroup',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/DisplayGroups/CreateDisplayGroup', 'ladda-bootstrap', 'bootstrap-dialog'],
    function (App, Backbone, _, $, template, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                'displayGroupName' : 'input[name="displayGroupName"]'
            },

            events: {
                'click .create-btn' : 'createDisplayGroup'
            },

            onShow: function () {
                var me = this;
                setTimeout(function () { me.ui.displayGroupName.focus(); }, 200);
            },

            createDisplayGroup: function (event) {
                var me = this,
                    newDisplayGroup,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    display_name = me.ui.displayGroupName.val();


                if (_.isEmpty(display_name)) {
                    App.os.showFieldError('input[name="displayGroupName"]', App.trans('Please enter display group name'));
                    return;
                }

                l.start();

                this.ui.displayGroupName.prop('disabled', true);
                this.$el.find('button').addClass('disabled');

                newDisplayGroup = App.currentUser.getDisplayGroups().create({
                    'display_name' : display_name
                }, {
                    wait: true,
                    success: function (model, response, options) {
                        App.vent.trigger('App.displayGroups.refresh');
                        App.mainRegion.currentView.modalRegion.currentView.destroy();
                    },
                    error: function (model, response, options) {
                        me.destroy();
                        BootstrapDialog.show({
                            type: BootstrapDialog.TYPE_DANGER,
                            title: App.trans('Huston! We have a problem!'),
                            message: App.trans("Unfortunatelly an error occurred while creating new display group.<br><br>") + '<b>' + response.responseJSON.reason + '</b>',
                            buttons: [{
                                label: App.trans("Close"),
                                action: function (dialog) {
                                    dialog.close();
                                }
                            }]
                        });
                    }
                });
            }

        });
    });

