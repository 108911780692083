
/* START_TEMPLATE */
define('hbs!templates/views/InternalReports/LoggedUsers',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "\n        <h1 style=\"margin-top: 0px;\">No logged users</h1>\n        ";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <ul>\n            ";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.users), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </ul>\n        ";
  return buffer;
  }
function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <li>\n                "
    + escapeExpression(((stack1 = (depth0 && depth0.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n            </li>\n            ";
  return buffer;
  }

  buffer += "<div class=\"inner\">\n    <div class=\"widget__header\">\n        <h5>&nbsp;<i class=\"fa fa-history\" aria-hidden=\"true\"></i> Logged Users</h5>\n    </div>\n    <div class=\"widget__body\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.usersEmpty), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n        <div class=\"internal-reports-loading-mask\">\n            <div class=\"radial-progress waiting\">\n                <div class=\"inf\"></div>\n            </div>\n        </div>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
