
/* START_TEMPLATE */
define('hbs!templates/views/LoopManagement/LoopItem',['hbs','handlebars','templates/helpers/offerThumbnail','templates/helpers/loopItemScheduleStatus'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"loop-item-inner\" data-id=\"";
  if (helper = helpers.uri) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.uri); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" style=\"background-image: url("
    + escapeExpression((helper = helpers.offerThumbnail || (depth0 && depth0.offerThumbnail),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.media_file_thumbnail), (depth0 && depth0.offer_type), options) : helperMissing.call(depth0, "offerThumbnail", (depth0 && depth0.media_file_thumbnail), (depth0 && depth0.offer_type), options)))
    + ")\" title=\"";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n    <div class=\"delete-btn\"><i class=\"fa fa-times\"></i></div>\n    ";
  stack1 = (helper = helpers.loopItemScheduleStatus || (depth0 && depth0.loopItemScheduleStatus),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.targets), options) : helperMissing.call(depth0, "loopItemScheduleStatus", (depth0 && depth0.targets), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
