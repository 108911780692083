// -*- coding: utf-8 -*-


/*global define*/
define('collections/SubscriptionsPlans',["App", "backbone",
        "models/SubscriptionPlan"],
    function (App, Backbone,
              SubscriptionPlan
              ) {

        'use strict';
        var SubscriptionPlans = Backbone.Collection.extend({
            model: SubscriptionPlan,

            parse: function(response) {
                return _.map(response.default.subscriptionPlans, function (num, key) { return _.extend(num, {type: key}); });
            },

            setFromSessionData: function (data) {
                this.reset(_.map(data.default.subscriptionPlans, function (num, key) { return _.extend(num, {type: key}); }));
            },

            comparator: function (model) {
                return model.get('recurringFee')[App.currency].monthly;
            },

            getByType: function (type) {
                return this.find(function (plan) { return plan.get('type') === type; });
            },

            getCurrent: function () {
                return this.find(function (plan) { return plan.get('type') === App.currentCompany.get('active_plan'); });
            }


        });

        return SubscriptionPlans;
    });
