
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/VisitsWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                    <option value=\""
    + escapeExpression(((stack1 = (depth0 && depth0.val)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">"
    + escapeExpression(((stack1 = (depth0 && depth0.title)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</option>\n                ";
  return buffer;
  }

  buffer += "<div class=\"widget__body\">\n    <div style=\"float: right; width: 200px;margin: 20px 0 0 0;padding: 10px;\">\n        <select id=\"visits-granularity-filter\" class=\"selectpicker bottom-margin\" data-width=\"100%\">\n            <optgroup label=\"Granularity\">\n                ";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.availableGranularities), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </optgroup>\n        </select>\n    </div>\n    <div id=\"";
  if (helper = helpers.chartId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.chartId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"  style=\"width: 100%; overflow: hidden; position: absolute; bottom: 0; top: 75px;\" ></div>\n\n    <span class=\"widget__loader\">\n        <div class=\"spinner\"></div>\n    </span>\n\n    <span class=\"widget__body--no-camera\">No camera selected</span>\n</div>\n\n<a href=\"#profile/payments\" class=\"upgrade\">UPGRADE</a>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
