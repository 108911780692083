
/* START_TEMPLATE */
define('hbs!templates/views/Reports/ReportsLayout',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "\n<div id=\"ng-iframe\" style=\"display: flex;position: absolute;top: 0;left: 0;right: 0;bottom: 0;\">\n    <iframe src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n</div>\n\n\n<!--<div>-->\n    <!--<div id=\"reports-menu\">-->\n        <!--<ul class=\"reports__top-menu\">-->\n            <!--<li role=\"presentation\" data-permission=\"repo_gene_btn\" data-id=\"reports-overview\" class=\"report-icon report-combined\"><a href=\"#\">Overview</a></li>-->\n            <!--&lt;!&ndash;<li role=\"presentation\" data-permission=\"repo_airt_btn\" data-id=\"reports-airtime\" class=\"report-icon report-airtime\"><a href=\"#\">Airtime Reports</a></li>&ndash;&gt;-->\n            <!--<li role=\"presentation\" data-permission=\"repo_airt_btn\" data-id=\"advanced-settings\" class=\"report-icon report-airtime\"><a href=\"#\">Advanced Settings</a></li>-->\n            <!--&lt;!&ndash;<li role=\"presentation\" data-permission=\"repo_audi_btn\" data-id=\"reports-audience\" class=\"report-icon report-audience\"><a href=\"#\">Audience Reports</a></li>&ndash;&gt;-->\n            <!--&lt;!&ndash;<li role=\"presentation\" data-permission=\"repo_foot_btn\" data-id=\"reports-footfall\" class=\"report-icon report-footfall\"><a href=\"#\">Footfall Reports</a></li>&ndash;&gt;-->\n            <!--&lt;!&ndash; <li role=\"presentation\" data-id=\"reports-combined\" class=\"report-icon report-combined disabled\"><a href=\"#\">Combined Reports</a></li> &ndash;&gt;-->\n            <!--<li role=\"presentation\" data-permission=\"repo_gene_btn\" data-id=\"generated-reports\" class=\"report-icon report-combined\"><a href=\"#\">Generated Reports</a></li>-->\n        <!--</ul>-->\n    <!--</div>-->\n    <!--<div id=\"reports-panel\" class=\"\"></div>-->\n<!--</div>-->";
  });
return t;
});
/* END_TEMPLATE */
;
