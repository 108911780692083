// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/BillingInfo',["App", "backbone", "underscore"],
    function (App, Backbone, _) {
        'use strict';
        var BillingInfo = Backbone.Model.extend({

            validation: {

                'company_name': {
                    required: true
                },

                'first_name': {
                    required: true
                },

                'last_name': {
                    required: true
                },

                'billing_address1': {
                    required: true
                },

                'billing_postcode': {
                    required: true
                },

                'billing_taxid': {
                    required: false
                },

                'billing_town': {
                    required: true
                }

            },

            parse: function (response) {
                response.uri = App.currentCompany.get('uris').BillingInfo;
                return response;
            }
        });

        return BillingInfo;
    }
);
