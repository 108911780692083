// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Displays/Display',['App', 'jquery', 'underscore', 'hbs!templates/views/Displays/Display', 'backbone', 'models/Display', 'views/Displays/DisplayControl', 'x-editable', 'magicsuggest'],
    function (App, $, _, template, Backbone, Display, DisplayControl) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'display-unit',

            modelEvents: {
                'change:chargeable' : function (model, options) {
                    model.get('chargeable') ? this.ui.chargeableIcon.show() : this.ui.chargeableIcon.hide();
                },
                'change': '_save'
            },

            ui: {
                'deleteScreenBtn' : '.delete-screen-btn',
                'screenDetailsBtn' : '.show-screen-btn',
                'displayOffers' : '.display-offers',
                'loadingMask' : '.loading-mask2',
                'commandBtn' : '.command-btn',

                'offerPanel' : '.offers',

                'presenterPanelBtn' : '.show-presenter-panel-btn',
                'presenterPanel' : '.presenter-panel',

                'chargeableIcon' : '.chargeable-icon',
                'sensorIcon' : '.sensor-icon',
                'displayGroupSelector' : '.display-group-selector'

            },

            events: {
                'click @ui.deleteScreenBtn' : 'deleteScreen',
                'click @ui.screenDetailsBtn' : 'toggleDetails',
                'click @ui.commandBtn' : 'sendCommand',
                'click @ui.displayOffers' : 'displayOffer',
                'click @ui.presenterPanelBtn' : 'togglePresenterPanel',

                'click @ui.displayGroupSelector' : '_toggleDisplayGroupSelector'
            },

            bindings: {
                'input[name=rotation]' : {
                    observe: 'rotation',
                    onSet: function (val, options) {
                        return parseInt(val);
                    }
                },
                'input[name=chargeable]' : {
                    observe: 'chargeable',
                    onSet: function (val, options) {
                        return !_.isNull(val);
                    }
                }
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            setRadioButtons: function () {
                var me = this;
                me.$el.find('input[type=radio]:checked').parent().addClass('active');
            },

            onRender: function () {
                var me = this,
                    groupName = me.model.get('group_name');

                me.stickit();
                me.setRadioButtons();

                (me.model.get('chargeable')) ? me.ui.chargeableIcon.show() : me.ui.chargeableIcon.hide();

                // TODO
                if (me.model.hasSensors()) {
                    me.ui.sensorIcon.show();
                    if (!me.model.sensorStatus()) {
                        me.ui.sensorIcon
                            .addClass('sensor-icon--with-problem')
                            .attr('title', 'AIM Sensor - Not Connected')
                    } else {
                        me.ui.sensorIcon.attr('title', 'AIM Sensor - Connected')
                    }
                    !me.model.sensorStatus() && me.ui.sensorIcon.addClass('sensor-icon--with-problem');
                } else {
                    me.ui.sensorIcon.hide();
                }

                if (me.model.get('active')) {
                    me.$el.removeClass('active inactive').addClass('active');
                    me.ui.deleteScreenBtn.remove();
                } else {
                    me.$el.removeClass('active inactive').addClass('inactive');
                    me.ui.commandBtn.remove();
                    me.ui.presenterPanelBtn.remove();
                }

                if (groupName === 'unassigned') {
                    me.$el.find('.display-unit-icon').addClass(_.last(me.model.get('group').split('/')));
                } else {
                    me.$el.find('.display-unit-icon').css('background-image', 'url(' + me.model.get('group_icon') + ')');
                }

                // keep display expanded on render
                if (me.model.get('expanded')) {
                    me.$el.find('.expanded').show();
                }

                me.$el.find('.name span').editable({
                    url: '',
                    pk: 1,
                    send: 'never',
                    mode: 'inline',
                    onblur: 'submit',
                    savenochange: true,
                    showbuttons: false,
                    value: me.model.get('name'),
                    sourceError: 'Please, select value in first list',
                    success: function onXEditableSubTaskTitle (resp, newVal) {
                        me.$el.removeClass('xeditable-shown');
                        me.model.set('name', newVal);
                        if (me.model.hasChanged('name')) {
                            me.model.save();
                            me.render();
                        } else {
                            // for some reason the xeditable input is not properly hidden
                            // the simplest workaround is to rerender the view.
                            me.render();
                        }
                    }
                });

                if (me.model.get('expanded')) {
                    me.ui.offerPanel.show();
                }
            },

            deleteScreen: function (event) {
                var me = this;
                event.preventDefault();
                me.model.destroy();
            },

            toggleDetails: function (event) {
                var me = this,
                    offerPanelExpander = me.$el.find('.expanded'),
                    display = new Display(),
                    view;

                display.url = me.model.get('uri');

                event.preventDefault();

                if (offerPanelExpander.is( ":hidden" )) {
                   //me.ui.loadingMask && me.ui.loadingMask.show();
                    $.when(display.fetch({data : {recurse : '1'}})).then(function () {

                        display.set('offers', _.sortBy(display.get('offers'), 'position'));
                        App.currentUser.getDisplays().add(display, {merge: true, silent: true});
                        view = me.parent.children.findByModel(me.model);
                        me.ui.loadingMask && me.ui.loadingMask.hide();

                        // Select current displayed offer
                        if (_.isObject(display.get('current_offer')) && !_.isEmpty(display.get('current_offer').uri)) {
                            me._markCurrentOffer(display.get('current_offer').uri);
                        }

                        offerPanelExpander.slideDown("fast", function () { me.model.set({'expanded' : true}, {silent: true}); me.ui.offerPanel.show(); me.render(); });
                    });
                } else {
                    me.model.unset('expanded');
                    offerPanelExpander.hide(function () { me.ui.offerPanel.hide(); });
                }

            },

            sendCommand: function (event) {
                var me = this;
                if (!_.isUndefined(event) && !_.isUndefined($(event.currentTarget).data('screenCommand'))) {
                    App.os.sendCommand(me.model.get('uris').Control, $(event.currentTarget).data('screenCommand'));
                }
            },

            _markCurrentOffer: function (uri) {
                var me = this;
                me.$el.find('.display-offers').removeClass('current');
                me.$el.find('.display-offers[data-id="' + uri + '"]').addClass('current');
            },

            displayOffer: function (event) {
                var me = this;
                if (!_.isUndefined(event) && !_.isUndefined($(event.currentTarget).data('id'))) {
                    App.os.sendCommand(
                        me.model.get('uris').Control,
                        'show',
                        {
                            offer : $(event.currentTarget).data('id')
                        }
                    );
                    me._markCurrentOffer($(event.currentTarget).data('id'));
                }
            },

            togglePresenterPanel: function () {
                var me = this;
                App.mainRegion.currentView.presentationPanel.show(new DisplayControl({ model: me.model }));
                App.vent.trigger('App.layout.togglePresentationPanel');
            },

            _save: function (model, options) {
                if (options.fromChannel) {
                    this.render();
                } else if (!options.xhr) {
                    this.model.saveDebounced(1000);
                }
            },

            _toggleDisplayGroupSelector: function (event) {
                var me = this;

                event && event.stopImmediatePropagation();

                App.vent.trigger('App.layout.screenManagement.display.reset');
                App.currentDisplay = me.model;

                me.displayGroupSelector = me.ui.displayGroupSelector.magicSuggest({
                    allowFreeEntries: false,
                    hideTrigger: true,
                    minChars: 1,
                    placeholder: 'Type display group',

                    queryParam: 'dummy',
                    method: 'get',
                    displayField: 'value',
                    valueField: 'uri',
                    data: App.enrichApiUrl('/data/search/display-groups/admin'),
                    resultsField: 'objects',
                    maxSelection: 1,
                    cls: 'display-group-selector-field'
                });

                me.displayGroupSelector.addToSelection([{uri: me.model.get('group'), value: me.model.get('group_name')}]);

                $(me.displayGroupSelector).on('beforeload', function(c){
                    var data = {};
                    if (!_.isEmpty(me.displayGroupSelector.getRawValue())) {
                        data['display_name'] = me.displayGroupSelector.getRawValue();
                    }
                    me.displayGroupSelector.setDataUrlParams({ query: JSON.stringify(data) });
                });

                $(me.displayGroupSelector).on('selectionchange', function () {
                    if (!_.isEmpty(this.getValue()[0])) {
                        me.model.set('group', this.getValue()[0]);
                        if (me.model.hasChanged('group')) {
                            me.model.save().done(function () {
                                me.ui.loadingMask && me.ui.loadingMask.show();
                                App.vent.trigger('App.layout.screenManagement.display.reset');
                            })
                        }
                    } else {
                        return;
                    }
                });

                _.delay(function () {
                    $(document).one('click', _.bind(me._onClickDisplay, me));
                }, 500);
            },

            _onClickDisplay: function (event) {
                if(!$(event.target).closest('.display-group-selector-field').length &&
                    !$(event.target).is('.display-group-selector-field') &&
                    !$(event.target).is('.ms-close-btn') &&
                    !$(event.target).is('.ms-res-item')) {
                        console.log('sdfsdfsdfsfsf');
                        App.vent.trigger('App.layout.screenManagement.display.reset');
                }
            }

        });
    });
