// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/RetailersSelector',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/RetailersSelector', 'backbone',
    'views/Retailers/Retailers',
    'views/Retailers/RetailersFilter'],
    function (App, $, _, template, Backbone,
        Retailers,
        RetailersFilter) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            regions: {
                filterBox: '#retailers-filter-box',
                retailersList: '#retailers-list'
            },

            events: {
                'click .create-btn' : '_createRetailer'
            },

            onRender: function () {
                var me = this;
                me.filterBox.show(new RetailersFilter());
                me.retailersList.show(new Retailers());
            },

            _createRetailer: function (event) {
                event.stopPropagation();
                event.preventDefault();
                App.vent.trigger('App.retailers.create');
            }
        });
    });
