// -*- coding: utf-8 -*-


/*global define*/
define('collections/Companies',["backbone", "models/Company"],
    function (Backbone, Company) {

        'use strict';
        var Companies = Backbone.Collection.extend({
            url: '/data/companies',
            model: Company,

            comparator: function (company) {
                return company.get('name');
            },

            getPrivate: function () {
                var me = this;
                return _.map(me.filter(function(company) { return company.get('has_private_screens') === true; }), function (company) {
                    return {value: company.get('uri'), text: company.get('name')}
                });
            }

        });

        return Companies;
    });
