// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/LoopManagement/LoopItem',['App', 'jquery', 'underscore', 'hbs!templates/views/LoopManagement/LoopItem', 'backbone', 'bootstrap-dialog'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',
            className: 'loop-item',

            events: {
                'click .delete-btn' : '_removeFromTheLoop'
            },

            modelEvents: {
                'change': 'render'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                var me = this;
                me.$el.attr('data-id', me.model.get('uri'));
                if (me.model.get('targets')[0]['is_displayed']) {
                    me.$el.addClass('is-displayed');
                }
            },

            _removeFromTheLoop: function (event) {
                event && event.preventDefault();
                var me = this;

                BootstrapDialog.confirm("Do you really want to unpublish this offer?", function (result) {
                    if (result) {
                        App.vent.trigger('App.layout.loopManagement.removeFromLoop', me.model);
                    }
                });

            }

        });
    });
