// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryEPosDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryEPosDesc(obj) {
        var output = "";

        if (!_.isUndefined(obj)) {
            if (!_.isEmpty(obj.stock_level)) {
                output += '<br>when stock levels are <b>' + obj.stock_level + '</b>';
            }
            if (!_.isEmpty(obj.shelf_live)) {
                output += '<br>when shelf live is <b>' + obj.shelf_live + '</b> sensors';
            }
            if (!_.isEmpty(obj.desirability)) {
                output += '<br>when desirability from shoppers is <b>' + obj.desirability + '</b> sensors';
            }
            if (!_.isEmpty(obj.tracking)) {
                output += '<br>Tracking most effective offers';
            }
            if (!_.isEmpty(obj.monitor)) {
                output += '<br>Monitor effectiveness';
            }
        }

        return output;
    }

    Handlebars.registerHelper('summaryEPosDesc', summaryEPosDesc);
    return summaryEPosDesc;
});

