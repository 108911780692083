// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,window,console,setTimeout*/
define('layouts/LoopManagement',['App', 'jquery', 'underscore', 'hbs!templates/layouts/LoopManagement', 'backbone',

            'views/LoopManagement/DisplayGroupsSelector',
            'views/LoopManagement/Loop'
    ],

    function (App, $, _, template, Backbone,

            DisplayGroupsSelector,
            Loop

        ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'loop-management',

            regions: {
                topPanel: '.top-panel',
                loopSection: '.loop-section'
            },

            ui: {

            },

            events: {
            },


            initialize: function () {
            },


            onRender: function () {
                var me = this;
                me.topPanel.show(new DisplayGroupsSelector());
                me.loopSection.show(new Loop());
            }


        });
    });

