// -*- coding: utf-8 -*-

/*global define,sessionData*/
define('views/Notifications/PublicTransportDisruptions',['App',
        'backbone',
        'hbs!templates/views/Notifications/PublicTransportDisruptions',
        'behaviors/Permissions',
        'collections/PublicTransportDisruptions',
        'views/Notifications/PublicTransportDisruption'
        ],
    function (
        App,
        Backbone,
        template,
        Permissions,
        PublicTransportDisruptions,
        PublicTransportDisruptionView
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,
            tagName: 'section',
            className: 'wrapper',

            childView: PublicTransportDisruptionView,

            childViewContainer: '.notifications',

            events: {
                'click .show-history-link': '_showHistoricalData'
            },

            behaviors: {
                Permissions:{
                    behaviorClass: Permissions,
                    App: App
                }
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({

                    getTemplate: function () {
                        var templateHtml = '<div class="no-notifications"><i class="fa fa-bell-slash-o"></i> No current notifications</div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                var me = this;

                me.childViewOptions = {
                    parent: me
                };

                me.collection = App.currentUser.getPublicTransportDisruptions();

            },

            _showHistoricalData: function (event) {
                event.preventDefault();
                App.appRouter.navigate('notifications-history', {trigger: true});
            }

        });
    });
