// -*- coding: utf-8 -*-

/*jslint nomen: true*/
define('models/DemographicsData',["App",
        "backbone"],
    function (App,
              Backbone) {

        'use strict';
        var DemographicsData = Backbone.Model.extend({
            uriStencil: '',

            initialize: function () {
                var me = this;

                this.stubData = {
                    'age' : {
                        'children': 11.76,
                        'teenagers': 55.25,
                        'young': 24.12,
                        'adults' : 8.87,
                        'senior' : 8.87
                    },
                    'gender' : {
                        'male' : 75.89,
                        'female' : 24.11
                    },
                    'eye_tracking' : {
                        'duration' : 10.59,
                        'attention' : 5.83
                    },
                    'emotions' : {
                        'emotion' : 22.43,
                        'smile_time' : 0.78
                    },
                    'viewing': 10.59,
                    'cameraTime': {
                        'attention': 5.83
                    }
                };

            },

            rendomize: function () {
                var randombetween = function(min, max) {
                    return Math.random()*(max-min+1)+min;
                };
                this.stubData.age.children = randombetween(0, 20);
                this.stubData.age.teenagers = randombetween(0, 30);
                this.stubData.age.young = randombetween(0, 30);
                this.stubData.age.senior = randombetween(0, 10);
                this.stubData.age.adults = 100-this.stubData.age.children-this.stubData.age.teenagers-this.stubData.age.young-this.stubData.age.senior;
            },

            getData: function (dataType) {
                return this.stubData[dataType];
                //return this.get(dataType) || (this.get('topify') && this.get('topify')[dataType] || null);
            }

        });

        return DemographicsData;
    }
    );
