// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Retailers/DepartmentLocationWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Retailers/DepartmentLocationWidget'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            templateHelpers: function() {
                return {
                    locationAddress: this.location.get('location'),
                    locationLatitude: this.location.get('latitude'),
                    locationLongitude: this.location.get('longitude'),
                    locationCity: this.location.get('city'),
                    locationCountry: this.location.get('country')
                }
            },

            ui: {
                mapContainer    : '#map'
            },

            initialize: function (options) {
                this.department = options.department;
                this.location = options.location;
            },

            showMarker: function () {
                var me = this;
                var position = {lat: me.location.get('latitude'), lng: me.location.get('longitude')};

                var geocoder = new google.maps.Geocoder();
                var map = new google.maps.Map(this.ui.mapContainer[0], {
                  zoom: 15,
                  center: position,
                  mapTypeId: 'roadmap',
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  zoomControl: true,
                });

                var marker = new google.maps.Marker({
                  position: position,
                  map: map,
                  title: 'Click to zoom',
                  draggable: true
                });

                marker.addListener('click', function() {
                    if (marker.getAnimation() != null) {
                        marker.setAnimation(null);
                    } else {
                        marker.setAnimation(google.maps.Animation.BOUNCE);
                    }
                });

                marker.addListener('dragend', function() {
                    geocoder.geocode({
                        latLng: {lat: marker.getPosition().lat(), lng: marker.getPosition().lng()}
                    }, function(response) {
                        if (response && response.length > 0) {

                            var findProperty = function(results, name) {
                                var result =  _.find(results, function(obj) {
                                    return obj.types[0] === name;
                                });
                                return result ? result.short_name : null;
                            };
                            var addressComponents = response[0].address_components;
                            var country = findProperty(addressComponents, "country");
                            var city = findProperty(addressComponents, "locality") || findResult(addressComponents, "postal_town");
                            var postcode = findProperty(addressComponents, "postal_code");

                            me.location.set('latitude', marker.getPosition().lat());
                            me.location.set('longitude', marker.getPosition().lng());
                            me.location.set('location', response[0].formatted_address);
                            me.location.set('name', response[0].formatted_address);
                            me.location.set('postcode', postcode);
                            me.location.set('city', city);
                            me.location.set('country', country);
                            me.location.save({}, {url: me.department.get('uris').Locations}).done(function () {
                                me.render();
                            });
                        }
                    })
                });

                google.maps.event.addListener(map, "idle", function(){
                    map.setCenter(position);
                    google.maps.event.trigger(map, 'resize');
                });
            },

            onRender: function () {
                var me = this;

                if (typeof google != 'object')
                    $.getScript( "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFvujdonUFvzqhHygallawnLompguuRqg&libraries=visualization" )
                      .done(function( script, textStatus ) {
                          console.log(textStatus);
                          me.showMarker();
                      })
                      .fail(function( jqxhr, settings, exception ) {
                          console.log(exception);
                      });

                else {
                    me.showMarker();
                }
            }

        });
    });

