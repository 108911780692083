
/* START_TEMPLATE */
define('hbs!templates/views/SignUpFacebook',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"signup-page\">\n    <div class=\"container\">\n\n        <form class=\"form-login\" id=\"fb-signup-form\" method=\"post\" action=\"/data/signup\" target=\"_parent\">\n\n            <div class=\"login-wrap\">\n\n                <p class=\"see-again-header extend\">\n                    We will never post to Facebook without your permission<br/>\n                    Already a Flow.City user? <a href=\"#login\"><b>Log in here.</b></a>\n                </p>\n\n                <input type=\"checkbox\" name=\"accept-tnc\" id=\"personal-account\" style=\"top: 2px; position: relative;\">\n                <span id='personal-account-label' for='personal-account'>\n                    Personal account\n                </span>\n                <p class=\"login-error fb-registration-error\">Company associated with this Facebook account already exits</p>\n                <p class=\"login-error fb-registration-email-error\">You must provide email address</p>\n                <input type=\"text\" name=\"name\" value=\"\" id=\"signup-companyname\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Company name", options) : helperMissing.call(depth0, "trans", "Company name", options)))
    + "\" style=\"margin-top: 10px;\"/>\n                <br>\n                <input type=\"checkbox\" name=\"accept-tnc\" id=\"signup-accept-tnc\" class=\"validate[required]\" style=\"top: 2px; position: relative;\">\n                <span id='signup-accept-tnc-label' for='signup-accept-tnc'>\n                    I accept <a href=\"http://www.flow.city/app-terms-conditions/current\" target=\"_blank\">FlowCity Terms and Conditions</a>\n                </span>\n                <button id=\"std-signup-btn\" class=\"btn btn-facebook btn-block btn-goto-fb-login\" type=\"submit\" style=\"margin-top: 10px !important;\">\n                    <i class=\"fa fa-facebook\"></i> "
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Continue to Facebook", options) : helperMissing.call(depth0, "trans", "Continue to Facebook", options)))
    + "\n                </button>\n\n                <span class=\"text-center\" style=\"padding: 15px 15px 0 15px; display: block\">\n                    <a href=\"#\" class=\"back-button\">Go back</a>\n                </span>\n            </div>\n              <input type=\"hidden\" name=\"to-url\" value=\"/\" />\n        </form>\n\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
