
/* START_TEMPLATE */
define('hbs!templates/views/DisplayGroups/DisplayGroup',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "<i class=\"fa fa-exchange\"></i> External consumer: <b>";
  if (helper = helpers.external_consumer) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.external_consumer); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</b>";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <div class=\"row\">\n                <div class=\"col-md-1\">\n                    <b>Departments:</b>\n                </div>\n                <div class=\"col-md-2\">\n                    ";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.departments), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </div>\n            </div>\n            ";
  return buffer;
  }
function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                         <div>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.attributes)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n                    ";
  return buffer;
  }

function program6(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <div class=\"row\">\n                <div class=\"col-md-1\">\n                    <b>Online displays:</b>\n                </div>\n                <div class=\"col-md-2\">\n                    ";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.onlineDisplays), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </div>\n            </div>\n            ";
  return buffer;
  }
function program7(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n                         <div>";
  if (helper = helpers.text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n                    ";
  return buffer;
  }

function program9(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"row\">\n               <div class=\"col-md-1\">\n                   <b>Schedule whitelist:</b>\n               </div>\n               <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.schedule_whitelist) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.schedule_whitelist); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n               </div>\n           </div>\n            ";
  return buffer;
  }

function program11(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"row\">\n               <div class=\"col-md-1\">\n                   <b>Schedule blacklist:</b>\n               </div>\n               <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.schedule_blacklist) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.schedule_blacklist); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n               </div>\n           </div>\n            ";
  return buffer;
  }

function program13(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <div class=\"row\">\n                <div class=\"col-md-1\">\n                    <b>Location:</b>\n                </div>\n                <div class=\"col-md-2\">\n                    "
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.locationDetails)),stack1 == null || stack1 === false ? stack1 : stack1.attributes)),stack1 == null || stack1 === false ? stack1 : stack1.location)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n                </div>\n            </div>\n            ";
  return buffer;
  }

function program15(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"row\">\n               <div class=\"col-md-1\">\n                   <b>Public transport:</b>\n               </div>\n               <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.public_transport) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.public_transport); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n               </div>\n           </div>\n            ";
  return buffer;
  }

function program17(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"row\">\n               <div class=\"col-md-1\">\n                   <b>External consumer:</b>\n               </div>\n               <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.external_consumer) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.external_consumer); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n               </div>\n           </div>\n            ";
  return buffer;
  }

function program19(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"row\">\n                <div class=\"col-md-1\">\n                    <b>External consumer options</b>\n                </div>\n                <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.external_consumer_options) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.external_consumer_options); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n                </div>\n            </div>\n            ";
  return buffer;
  }

  buffer += "<div class=\"status\"></div>\n<div class=\"inner\">\n\n    <div class=\"name\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n\n    <div class=\"name display-name\"><span>";
  if (helper = helpers.display_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.display_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span></div>\n\n    <button class=\"btn btn-default pull-right show-display-group-btn\"><i class=\"fa fa-bars\"></i></button>\n\n    <div class=\"external-consumer-field\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.external_consumer), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </div>\n\n    <div class=\"expanded\" style=\"display: none;\">\n        <div class=\"details mt\">\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.departments), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.displays), {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.schedule_whitelist), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.schedule_blacklist), {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.locationDetails), {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            <div class=\"row\">\n               <div class=\"col-md-1\">\n                   <b>Timezone:</b>\n               </div>\n               <div class=\"col-md-2\">\n                    ";
  if (helper = helpers.timezone) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.timezone); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n               </div>\n           </div>\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.public_transport), {hash:{},inverse:self.noop,fn:self.program(15, program15, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.external_consumer), {hash:{},inverse:self.noop,fn:self.program(17, program17, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.external_consumer_options), {hash:{},inverse:self.noop,fn:self.program(19, program19, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n\n        <div class=\"toolbar\">\n\n            <div class=\"pull-right\">\n                <button class=\"btn btn-danger archive-display-group-btn\"><i class=\"fa fa-trash-o\"></i> Archive</button>\n            </div>\n        </div>\n\n    </div>\n\n    <div class=\"loading-mask\"></div>\n</div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
