// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Reports/ReportsLayout',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/ReportsLayout', 'backbone',
    'views/Reports/Overview',
    'views/Reports/AirtimeReport',
    'views/Reports/AdvancedSettings',
    'views/Reports/AudienceReport',
    'views/Reports/FootfallReport',
    'views/Reports/Reports'],
    function (App, $, _, template, Backbone,
        Overview,
        AirtimeReport,
        AdvancedSettings,
        AudienceReport,
        FootfallReport,
        Reports) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'reports wrapper',

            regions: {
                tabPanel: '#reports-panel'
            },

            ui: {
                topMenuPanel: '#reports-menu',
                topMenu: 'ul'
            },

            // tabs: {
            //     'reports-overview' : Overview,
            //     'reports-airtime' : AirtimeReport,
            //     'advanced-settings' : AdvancedSettings,
            //     'reports-audience' : AudienceReport,
            //     'reports-footfall' : FootfallReport,
            //     'reports-combined' : null,
            //     'generated-reports': Reports
            // },

            // events: {
            //     // 'click ul li a': '_openTab',
            //     // 'click [name="check_report"]': 'selectionWindow'
            // },

            // selectionWindow: function (event) {
            //     var currentTarget = $(event.currentTarget).first(),
            //         all_checkboxes = $('[name="check_report"]'),
            //         len = all_checkboxes.length;
            //
            //     if(currentTarget.parent().parent().hasClass('report-header')) {
            //
            //         if(currentTarget[0].checked)
            //             for(var i=0; i<len; ++i)
            //                 all_checkboxes[i].checked = true;
            //         else
            //             for(var i=0; i<len; ++i)
            //                 all_checkboxes[i].checked = false;
            //     }
            //     else{
            //         all_checkboxes[0].checked = true;
            //         for(var i=0; i<len; ++i)
            //             if( !all_checkboxes[i].checked ){
            //                 all_checkboxes[0].checked = false;
            //                 break;
            //         }
            //     }
            // },

            initialize: function (options) {
                var me = this;
                me.options = options;
            },

            // setTab: function(){
            //     var me = this;
            //     var tabs = me.ui.topMenu.find('li');
            //     $.when(App.currentUser.getPermissions().promise).then(function () {
            //         var permissions = App.currentUser.getPermissions();
            //         var desired = me.$el.find('[data-id='+me.options.profileTab+']');
            //         var desiredPerm = desired.attr('data-permission');
            //         if(permissions.get(desiredPerm)!='active') {
            //             tabs.each(function (i, element) {
            //                 var elPermission = $(element).attr('data-permission');
            //                 if (permissions.get(elPermission) == 'active') {
            //                     var id = $(element).attr('data-id');
            //                     var tabView = me.tabs[id];
            //                     if (!$(element).hasClass('disabled')) {
            //                             // clear previous selection
            //                             me.ui.topMenu.find('li').removeClass('active');
            //                             me.ui.topMenu.find('li[data-id="' + id + '"]').addClass('active');
            //                             me.tabPanel.show(new tabView({parentView: me}));
            //                     }
            //                     return false;
            //                 }
            //             })
            //         }else{
            //             me.profileTab = me.options.profileTab;
            //             me.profileView = me.tabs[me.profileTab];
            //             me.currentTab = me.tabs['reports-airtime'];
            //             me.render();
            //         }
            //     });
            // },

            onRender: function () {
                var me = this;
                    // tabView = me.tabs[me.currentTab];

                // me.setTab();
                //
                // if (tabView) {
                //     me.ui.topMenu.find('li[data-id="' + me.currentTab + '"]').addClass('active');
                //     me.tabPanel.show(new tabView({parentView: me}));
                // }

                if (!_.isEmpty(me.retailerUri)) {
                    me.ui.topMenuPanel.show();
                }

                this.srcReplacer();
            },

            // _openTab: function (event) {
            //     event.preventDefault();
            //     var me = this,
            //         tabEl = $(event.currentTarget).parent(),
            //         tab = tabEl.data('id'),
            //         tabView = me.tabs[tab];
            //
            //     if (!tabEl.hasClass('disabled')) {
            //         // clear previous selection
            //         me.ui.topMenu.find('li').removeClass('active');
            //         me.ui.topMenu.find('li[data-id="' + tab + '"]').addClass('active');
            //         me.tabPanel.show(new tabView({parentView: me}));
            //     }
            // },

            srcReplacer: function(){
                var me = this;

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=reports';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }

        });
    });
