// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/CompanyDetails',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/CompanyDetails', 'backbone', 'bootstrap-dialog', 'cropper'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            ui: {
                uploadBtn : '.fileinput'
            },

            modelEvents: {
                'change': 'render'
            },

            events: {
                'change .company-logo': 'logoFileUpdated',
                'click [data-action="upload-image"]': 'getCroppedImage',
                'click [data-action="change-image"]': 'changeLogo'
            },

            className: 'company-details',

            initialize: function (options) {
                this.model = options.company;
                this.model.unset('_changelogo', {silent: true});
            },

            onRender: function() {
                var me = this;
                me.ui.uploadBtn.on('change.bs.fileinput', function (event) {
                    if (event.namespace === "bs.fileinput") {
                        me.logoFileUpdated()
                    }
                });

                me.$el.find('.company-credits').editable({
                    url: '',
                    pk: 1,
                    send: 'never',
                    mode: 'inline',
                    onblur: 'submit',
                    savenochange: true,
                    showbuttons: false,
                    value: me.model.get('credits'),
                    success: function onXEditableSubTaskTitle (resp, newVal) {
                        me.$el.removeClass('xeditable-shown');
                        me.model.set('new_credits', parseInt(newVal));
                        if (parseInt(newVal) !== parseInt(me.model.get('credits'))) {
                            me.model.set('credits', parseInt(newVal));
                            me.model.save();
                            me.render();
                        } else {
                            me.render();
                        }
                    }
                });
            },

            changeLogo: function() {
                this.model.set('_changelogo', true, {silent: true});
                this.render();
            },

            logoFileUpdated: function() {
                console.log('image changed');
                var input = this.$el.find('input#companyLogo')[0],
                    me = this;
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    me.$el.find('[data-action="upload-image"]').show();

                    reader.onload = function(e) {
                      me.$el.find('#preview').attr('src', e.target.result);
                      me.initCropper();
                    };

                    reader.readAsDataURL(input.files[0]);
                } else {
                    me.$el.find('[data-action="upload-image"]').hide();
                }
            },

            initCropper: function() {
                var image = this.$el.find('#preview');
                image.cropper('destroy');
                image.cropper({
                    aspectRatio: 19 / 4,
                    crop: function(event) {
                        console.log(event.detail.x);
                        console.log(event.detail.y);
                        console.log(event.detail.width);
                        console.log(event.detail.height);
                        console.log(event.detail.rotate);
                        console.log(event.detail.scaleX);
                        console.log(event.detail.scaleY);
                    }
                });
            },

            getCroppedImage: function(event) {
                var me = this,
                    output = this.$el.find('#preview').cropper('getCroppedCanvas');

                event && event.preventDefault();
                output.toBlob(function (blob) {
                    var formData = new FormData();
                    formData.append('image', blob);
                    $.ajax(App.enrichApiUrl(me.model.getUri('CompanyLogo')), {
                        method: "POST",
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function() {
                            console.log('Upload success');
                            $.when(me.model.fetch())
                                .then(function () {
                                    me.model.unset('_changelogo', {silent: true});
                                    me.render();
                                });
                        },
                        error: function() {
                            console.log('Upload error');
                        },
                    });
                });
            }

        });
    });
