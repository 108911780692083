// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Retailers/DepartmentUserConfiguration',['App', 'jquery', 'underscore', 'hbs!templates/views/Retailers/DepartmentUserConfiguration', 'backbone',
        'views/Retailers/DepartmentUserApproverGroups',
        'views/Retailers/DepartmentUserProfiles'],
    function (App, $, _, template, Backbone,
              DepartmentUserApproverGroups,
              DepartmentUserProfiles) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'department-configuration',

            regions: {
                userProfiles: '.user-profiles',
                displayGroups: '.display-groups-configuration'
            },

            events: {
                'click .delete-btn'   : '_deleteUser'
            },

            initialize: function (options) {
                var me = this;
                me.parent = options && options.parent;
                me.retailerUserUri = options && options.retailerUserUri;
                me.department = options && options.department;
                me.user = me.department.getUsers().get(me.retailerUserUri);
            },

            onRender: function () {
                var me = this;
                me.userProfiles.show(new DepartmentUserProfiles({ parent: me, retailerUserUri: me.retailerUserUri, department: me.department }));
                me.displayGroups.show(new DepartmentUserApproverGroups({ parent: me, retailerUserUri: me.retailerUserUri, department: me.department }));
                me.triggerMethod('approver:groups:toggle')
            },

            onApproverGroupsToggle: function () {
                console.log('approver toggle');
                if (this.user.hasRole(App.os.roles.approver)) {
                    this.displayGroups.currentView.render();
                    this.$el.find('.display-groups-configuration').show();
                } else {
                    this.$el.find('.display-groups-configuration').hide();
                }
                this.onLoadingMaskToggle(true);
            },

            _deleteUser: function () {
                App.vent.trigger('App.retailers.users.delete', this.retailerUserUri);
            },

            onLoadingMaskToggle: function (forceHide) {
                this.parent.triggerMethod('loading:mask:toggle', forceHide);
            }

        });
    });
