// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('behaviors/Permissions',['backbone',
        'backbone.marionette',
        'jquery'
    ],
    function (
        Backbone,
        marionette,
        $
    ) {
        'use strict';
        return marionette.Behavior.extend({

            permissions:{
                "topm_hamb_btn": "active",
                "topm_flow_btn": "active",
                "topm_cred_btn": "active",
                "topm_noti_btn": "active",
                "topm_logo_btn": "active",
                "topm_brea_txt": "active",

                "menu_addn_btn": "active",
                "menu_addn_drop": "active",
                "menu_dash_btn": "active",
                "menu_your_btn": "active",
                "menu_scre_btn": "active",
                "menu_thum_btn": "active",
                "menu_comp_btn": "active",
                "menu_user_btn": "active",
                "menu_repo_btn": "active",
                "menu_mylo_btn": "active",

                "crea_name_fiel": "active",
                "crea_imag_btn": "active",
                "crea_movi_btn": "active",
                "crea_link_btn": "active",
                "crea_canc_btn": "active",
                "crea_crea_btn": "active",

                "offe_down_btn": "active",
                "offe_uplo_btn": "active",
                "offe_name_fiel": "active",
                "offe_dura_togg": "visible",
                "offe_16x9_btn": "active",
                "offe_9x16_btn": "active",
                "offe_4x3_btn": "active",
                "offe_what_fiel": "active",
                "offe_best_fiel": "active",
                "offe_keyw_fiel": "active",
                "offe_link_fiel": "active",
                "offe_draf_btn": "active",
                "offe_lock_info": "active",
                "offe_temp_btn": "active",

                "user_thum_btn": "active",
                "user_firs_fiel": "active",
                "user_last_fiel": "active",
                "user_phon_fiel": "active",
                "user_save_btn": "active",
                "user_newp_fiel": "active",
                "user_rety_fiel": "active",
                "user_chan_btn": "active",

                "bill_comp_fiel": "active",
                "bill_firs_fiel": "active",
                "bill_last_fiel": "active",
                "bill_vati_fiel": "active",
                "bill_1add_fiel": "active",
                "bill_2add_fiel": "active",
                "bill_post_fiel": "active",
                "bill_city_fiel": "active",
                "bill_coun_drop": "active",
                "bill_save_btn": "active",

                "dash_weat_widg": "active",
                "dash_camp_widg": "active",
                "dash_twit_widg": "active",
                "dash_new_widg": "active",
                "dash_map_widg": "active",
                "dash_age_widg": "active",
                "dash_gend_widg": "active",
                "dash_eyet_widg": "active",
                "dash_emot_widg": "active",
                "dash_foot_widg": "active",
                "dash_date_drop": "active",
                "dash_sour_drop": "active",

                "your_dele_btn": "active",
                "your_list_btn": "active",
                "your_subm_info": "active",
                "your_reje_info": "active",
                "your_acce_info": "active",
                "your_onli_info": "active",

                "sche_summ_info": "active",
                "sche_date_tab": "active",
                "sche_weat_tab": "active",
                "sche_tran_tab": "active",
                "sche_demo_tab": "active",
                "sche_poll_tab": "active",

                "subs_plan_info": "active",
                "subs_cred_info": "active",
                "subs_buyc_btn": "active",
                "subs_kito_info": "active",
                "subs_kito_btn": "active",
                "subs_canc_btn": "active",
                "subs_basi_info": "active",
                "subs_plus_info": "active",
                "subs_mult_info": "active",
                "subs_bcha_btn": "active",
                "subs_pcha_btn": "active",
                "subs_mcha_btn": "active",

                "invo_prin_btn": "active",
                "invo_list_btn": "active",

                "disr_show_btn": "active",

                "subm_user_btn": "active",
                "subm_bill_btn": "active",
                "subm_noti_btn": "active",
                "subm_subs_btn": "active",
                "subm_invo_btn": "active",

                "repo_airt_btn": "active",
                "repo_audi_btn": "active",
                "repo_foot_btn": "active",
                "repo_gene_btn": "active",
                "repo_down_btn": "active",
                "repo_dele_btn": "active"

            },

            checkPermission: function(el) {
                var label = el.attr('data-permission');
                var permission = this.permissions.get(label);
                if(permission) {
                    el.addClass('perm-' + permission);
                }else{
                    el.remove();
                }
                if(permission == 'visible'){
                    var type = label.split("_").pop();
                    if(type!='info') {
                        el.on('click', function () {
                            return false;
                        });
                    }
                    if(label.includes("tab") && label.includes("sche")){
                        el.addClass('disabled');
                    }
                    if(type=='btn'){
                        el.attr("disabled", true);
                    }
                    if(label=="dash_date_drop"){
                        el.children().on('click', function () {
                            return false;
                        }).css('color', 'grey');
                    }
                    el.find('button').attr("disabled", true);
                    el.find('input').attr("disabled", true);
                }
            },

            initialize: function(options){
                var me = this;
                me.app = options.App;
            },

            onRender: function() {
                var me = this;
                if(me.app.currentUser) {
                    var elements = me.$el.find('[data-permission]');
                    $.when(me.app.currentUser.getPermissions().promise).then(function () {
                        me.permissions = me.app.currentUser.getPermissions();
                        for (var i = 0; i < elements.length; i++) {
                            me.checkPermission($(elements[i]));
                        }
                    });
                }
            }

        });
    });
