
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferScheduleEmpty',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var stack1, self=this;

function program1(depth0,data) {
  
  
  return "\n<h5 class=\"scheduler-description\">This campaign will play <b>NOW</b></h5>\n";
  }

function program3(depth0,data) {
  
  
  return "\n<h5 class=\"pull-left\">This campaign will play </h5>\n<div class=\"btn-group scheduler-option\" data-toggle=\"buttons\" style=\"margin-left: 10px;\">\n    <label class=\"btn btn-default on-demand-drawer active\">\n        <input type=\"radio\" name=\"timeScheduleType\" value=\"\" data-drawer=\"on-demand\" autocomplete=\"off\" checked=\"checked\"> NOW\n    </label>\n    <label class=\"btn btn-default specific-dates-drawer\">\n        <input type=\"radio\" name=\"timeScheduleType\" class=\"weekdays-drawer\" data-drawer=\"weekdays\" value=\".weekdays, .timeframe\" autocomplete=\"off\"> when scheduled\n    </label>\n</div>\n";
  }

  stack1 = helpers['if'].call(depth0, (depth0 && depth0.locked), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  });
return t;
});
/* END_TEMPLATE */
;
