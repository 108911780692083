
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionAudiencesReached',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Audiences reached:</h4>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"audiences\" id=\"audiencesReachedFilter1\" value=\"none\">\n    <label for=\"audiencesReachedFilter1\">None</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"audiences\" id=\"audiencesReachedFilter2\" value=\"all\" checked>\n    <label for=\"audiencesReachedFilter2\">All audiences</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"audiences\" id=\"audiencesReachedFilter3\" value=\"selected\">\n    <label for=\"audiencesReachedFilter3\">Selected:</label>\n    <div class=\"local-dropdown\" data-id=\"filter-by-audiences-group\">\n        <div class=\"report-audiences-groups-list\"></div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
