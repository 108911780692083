// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Reports/Reports',['App',
        'backbone',
        'hbs!templates/views/Reports/Reports',
        'views/Reports/Report',
        'bootstrap-dialog'
        ],
    function (
        App,
        Backbone,
        template,
        Report,
        BootstrapDialog
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: Report,

            className: 'white-panel panel-padding tabs-left',

            childViewContainer: 'ul',

            events: {
                'click .print-invoice-btn-lower': '_downloadInvoice',
                'click .delete-btn-lower': '_delete',
                'click #all-companies a': 'allCampaigns',
                'click #selected-range a': 'selectedRange',
                'click #show-10-records a': 'show10Records',
                'click #sort-campaign-name': 'sortCampaignsByCampaignName',
                'click #sort-company-name': 'sortCampaignsByCompanyName',
                'click #sort-date-range': 'sortCampaignsDateRange',
                'keyup #type-to-search': 'searchFilter'
            },

            allCampaigns: function (event) {
                event.preventDefault();
                // TODO handle the All Campaigns button
            },
            selectedRange: function (event) {
                event.preventDefault();
                var me = this,
                    since,
                    to;
                var clickedText = $(event.currentTarget).html();
                $('#selected-range').children('button').children('label').html( clickedText );

                var getMonday = function (d) {
                    d = new Date(d);
                    var day = d.getDay(),
                        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
                    return new Date(d.setDate(diff));
                };

                $('.date-selector').css('display', 'none');

                if( clickedText === 'This week' ) {
                    since = getMonday( new Date() );
                    to = new Date(since);
                    to.setDate(to.getDate() + 6);
                }
                else if( clickedText === 'Last week' ) {
                    since = getMonday( new Date() );
                    to = new Date(since);
                    since.setDate(since.getDate() - 7);
                    to.setDate(to.getDate() - 1);
                }
                else if( clickedText === 'This month' ) {
                    since = new Date();
                    since.setDate(1);
                    to = new Date(since);
                    to.setMonth(to.getMonth()+1);
                    to.setDate(to.getDate()-1);
                }
                else if( clickedText === 'Last month' ) {
                    since = new Date();
                    since.setDate(1);
                    since.setMonth(since.getMonth() - 1);
                    to = new Date(since);
                    to.setMonth(to.getMonth()+1);
                    to.setDate(to.getDate()-1);
                }

                window.filterInputData.since = since;
                window.filterInputData.to = to;

                if( clickedText === 'Selected range' ) {
                    $('.date-selector').css('display', '');
                }

                me.filterList();
            },
            show10Records: function (event) {
                event.preventDefault();
                var clickedText = $(event.currentTarget).html();
                $('#show-10-records').find('label').html(clickedText);

                this.filterList( parseInt(clickedText) );
            },

            sortCampaignsByCampaignName: function () {

                var me = this,
                    arrow = $('.report-header').children('.report-campaign-name').children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".report-campaign-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".report-campaign-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    if (keyA < keyB) return -1 * me.campaignSort;
                    if (keyA > keyB) return 1 * me.campaignSort;
                    return 0;
                });

                me.fitToNewOrder(elements, arrow, me.campaignSort);
                me.campaignSort *= -1;
            },
            sortCampaignsByCompanyName: function () {
                var me = this,
                    arrow = $('.report-header').children('.report-company-name').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = $(a).children(".report-company-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    var keyB = $(b).children(".report-company-name").html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');

                    if (keyA < keyB) return -1 * me.companySort;
                    if (keyA > keyB) return 1 * me.companySort;
                    return 0;
                });

                me.fitToNewOrder(elements, arrow, me.companySort);
                me.companySort *= -1;
            },
            sortCampaignsDateRange: function () {
                var me = this,
                    arrow = $('.report-header').children('.report-date-range').children().children('.fa'),
                    elements = $('.reports-list').children('.report').not('.report-header');

                elements.sort(function(a,b){
                    var keyA = new Date( $(a).children(".report-date-range").find('.timestamp_from-date').html() );
                    var keyB = new Date( $(b).children(".report-date-range").find('.timestamp_from-date').html() );

                    if (keyA < keyB) return -1 * me.dateSort;
                    if (keyA > keyB) return 1 * me.dateSort;
                    return 0;
                });

                me.fitToNewOrder(elements, arrow, me.dateSort);
                me.dateSort *= -1;
            },
            fitToNewOrder: function (elements, arrow, sortDirection) {
                var ul = $('.reports-list');

                $.each(elements, function(i, li){
                    ul.append(li); /* This removes li from the old spot and moves it */
                });
                ul.find('.fa-angle-up, .fa-angle-down').css('color', 'grey');
                arrow.css('color', '#104F82');

                if(sortDirection > 0)
                    arrow.removeClass('fa fa-angle-down').addClass('fa fa-angle-up');
                else
                    arrow.removeClass('fa fa-angle-up').addClass('fa fa-angle-down');
            },

            searchFilter: function () {

                window.filterInputData.name = $("#type-to-search")[0].value.toUpperCase();

                this.filterList();
            },

            _downloadInvoice: function (event) {

                var all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(len>0)
                    for(var i=1; i<len; ++i){
                        if(all_checkboxes[i].checked)
                            all_checkboxes[i].parentElement.parentElement.getElementsByClassName("toolbox")[0].children[0].click();
                    }
            },

            _delete: function () {

                var all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(len>0)
                    for(var i=1; i<len; ++i){
                        if(all_checkboxes[i].checked)
                            all_checkboxes[i].parentElement.parentElement.getElementsByClassName("toolbox")[0].children[1].click();
                    }
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                this.collection = App.currentCompany.getReports({fetch: false});
                this.campaignSort = 1;
                this.companySort = 1;
                this.dateSort = 1;

                window.filterInputData = {
                    since: undefined,
                    to: undefined,
                    name: undefined
                };
            },

            filterList: function (show) {
                var li, listElementTitle, since, to;
                li = $(".reports-list").find("li");

                if(show === undefined || isNaN(show))
                    show = li.length;

                if(window.filterInputData.name === undefined)    window.filterInputData.name = '';

                for (var i = 1; i < li.length; i++) {
                    listElementTitle = $(li[i]).children('.report-campaign-name').html().trim().replace(/\r?\n|\r/g, " ").replace(/ +(?= )/g,'');
                    since = new Date( $(li[i]).children('.report-date-range').find('.timestamp_from-date').html() );
                    to = new Date( $(li[i]).children('.report-date-range').find('.timestamp_to-date').html() );
                    if(window.filterInputData.since === undefined)    window.filterInputData.since = since;
                    if(window.filterInputData.to === undefined)    window.filterInputData.to = to;

                    if (listElementTitle.indexOf(window.filterInputData.name) > -1 &&
                        (since >= window.filterInputData.since && since <= window.filterInputData.to ||
                            to >= window.filterInputData.since && to <= window.filterInputData.to) &&
                        i <= show) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            onRender: function () {
                var me = this;

                if (me.collection.url.indexOf(App.apiUrl) === -1) {
                    me.collection.url = App.enrichApiUrl(me.collection.url);
                }

                $.when(me.collection.fetch())
                    .then(function() {
                        if (_.size(me.collection) === 0) {
                            me.$el.find('.no-items').html('<i class="fa fa-book"></i> You have no reports');
                        }
                        else{
                            if( !$('.report.report-header').length ) {

                                $( function() {
                                    $( "#datepicker-from" ).datepicker({
                                        format: 'yyyy/mm/dd'
                                    })
                                        .on('changeDate', function (selected) {
                                            var minDate = new Date(selected.date.valueOf()),
                                                endDate = new Date( $('#datepicker-to')[0].value );

                                            window.filterInputData.since = minDate;

                                            if( endDate < minDate) {
                                                $('#datepicker-to').datepicker('setDate', minDate);
                                                window.filterInputData.to = minDate;
                                            }

                                            $('#datepicker-to').datepicker('setStartDate', minDate);
                                            me.filterList();
                                        });

                                    $( "#datepicker-to" ).datepicker({
                                        format: 'yyyy/mm/dd'
                                    })
                                        .on('changeDate', function (selected) {

                                            window.filterInputData.to = new Date(selected.date.valueOf());
                                            me.filterList();
                                        });
                                });
                            }
                        }
                    });
            }

        });
    });
