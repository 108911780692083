// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/Report',["App", "backbone"],
    function (App, Backbone) {
        'use strict';
        var Report = Backbone.Model.extend({
            uriStencil: 'reports',

            initialize: function () {
                this.get('_db_class') && this.set('type', this.get('_db_class')[0] || '');
            }


        });

        return Report;
    }
);
