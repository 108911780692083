// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/summaryDisruptionDesc',['App', 'handlebars'], function (App, Handlebars) {

    'use strict';
    function summaryDisruptionDesc(obj) {
        var output = "",
            strMap = {
                'important' : " only when <b>major disruption</b> occurs",
                'all' : " when <b>any disruption</b> occurs",
                'none' : " when there are <b>no disruptions</b> on public transport"
            }

        if (!_.isUndefined(obj)) {
            if (obj.disruptions !== '') {
                output = "<br>" + strMap[obj.disruptions];
            }
        }

        return output;
    }

    Handlebars.registerHelper('summaryDisruptionDesc', summaryDisruptionDesc);
    return summaryDisruptionDesc;
});

