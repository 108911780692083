// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Payments/StripeModal',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/StripeModal', 'stripe', 'ladda-bootstrap', 'moment', 'jquery-validation', 'bootstrap-select'],
    function (App, Backbone, _, $, template, Stripe, Ladda, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            required: true,

            events: {
                'click #submitBtn': 'onSubmit',
                'click #error-box button': 'hideError',
                'keypress [data-pattern="numbers"]': 'numbersPattern',
                'keypress [data-pattern="numbers-separators"]': 'numbersSeparatorsPattern'
            },

            ui:{
                'form':             '#stripe-form',
                'cardNumber':       '[name="card_number"]',
                'expirationMonth':  '[name="expiration_month"]',
                'expirationYear':   '[name="expiration_year"]',
                'cvc':              '[name="cvc"]',

                'name':             '[name="name"]',
                'address_line1':    '[name="address_line1"]',
                'address_line2':    '[name="address_line2"]',
                'address_zip':      '[name="address_zip"]',
                'address_city':     '[name="address_city"]',
                'address_country':  '#countryCode',

                'errorBox':         '#error-box',
                'submitBtn':        '#submitBtn',

                'monthSelect':      '#month-select',
                'yearSelect':       '#year-select',

                'expirationLabel':  '#expiration-label'
            },

            hideError: function(){
                this.ui.errorBox.fadeOut();
            },

            initialize: function (options) {
                this.token = options.token;
                this.paymentUri = options.uri;
            },

            numbersPattern: function(event){
                return ($.isNumeric(event.key));
            },

            numbersSeparatorsPattern: function(event){
                return (event.key===' ' || event.key==='-' || $.isNumeric(event.key));
            },

            disableForm: function(){
                this.ui.form.find('input').attr("disabled", true);
                this.ui.submitBtn.addClass('disabled');
                this.$el.find('.dropdown-toggle').addClass('disabled');
            },

            enableForm: function(){
                this.ui.form.find('input').attr("disabled", false);
                this.ui.submitBtn.removeClass('disabled');
                this.$el.find('.dropdown-toggle').removeClass('disabled');
            },

            selectizeYears: function(){
                var me = this;
                var currentYear = moment().year();

                for(var i=0; i<10; i++){
                    var newYear = currentYear+i;
                    var element = $('<option>' + newYear + '</option>');
                    me.ui.yearSelect.append(element);
                }
            },

            onRender: function () {
                var me = this;

                $.validator.addMethod("checkCard", function (value) {
                    return Stripe.card.validateCardNumber(value)
                });

                $.validator.addMethod("checkCVC", function (value) {
                    return Stripe.card.validateCVC(value)
                });

                $.validator.addMethod("checkExpiration", function () {
                    var month = me.ui.expirationMonth.val();
                    var year = me.ui.expirationYear.val();
                    console.debug("check expiration",month,year);
                    if (!year || !month) {
                        return false;
                    }else {
                        return Stripe.card.validateExpiry(month, year);
                    }
                });

                var required_msg = 'Required';
                var expiration_msg = 'Provided date is wrong';
                var card_msg = 'Provided card number is wrong';
                var cvc_msg = 'Provided CVC is wrong';

                me.validator = me.ui.form.validate({
                    ignore:[],
                    errorElement: "small",
                    errorClass: 'error',
                    rules: {
                        card_number: {
                            required: true,
                            checkCard: true
                        },
                        expiration_month: {
                            required: true,
                            checkExpiration: true
                        },
                        expiration_year: {
                            required: true,
                            checkExpiration: true
                        },
                        cvc: {
                            required: true,
                            checkCVC: true
                        }
                    },
                    // groups: {
                    //     expiration_date: "expiration_month expiration_year"
                    // },
                    messages: {
                        card_number: {
                            required: required_msg,
                            checkCard: card_msg
                        },
                        expiration_month: {
                            required: required_msg,
                            checkExpiration: expiration_msg
                        },
                        expiration_year: {
                            required: required_msg,
                            checkExpiration: expiration_msg
                        },
                        cvc: {
                            required: required_msg,
                            checkCVC: cvc_msg
                        }
                    },
                    highlight: function (element) {
                        if($(element).hasClass('hidden-input')){
                            var view = App.mainRegion.currentView.modalRegion.currentView;
                            if(element.name = 'expiration_month'){
                                view.$el.find('.month-dropdown').addClass('has-error');
                            }
                            if(element.name = 'expiration_year'){
                                view.$el.find('.year-dropdown').addClass('has-error');
                            }
                            view.ui.expirationLabel.addClass('has-error');
                        }else {
                            $(element).closest('.form-inside').addClass('has-error');
                        }
                    },
                    unhighlight: function (element) {
                        if($(element).hasClass('hidden-input')) {
                            var view = App.mainRegion.currentView.modalRegion.currentView;
                            if(element.name = 'expiration_month'){
                                view.$el.find('.month-dropdown').removeClass('has-error');
                            }
                            if(element.name = 'expiration_year'){
                                view.$el.find('.year-dropdown').removeClass('has-error');
                            }
                            view.ui.expirationLabel.removeClass('has-error');
                        } else {
                            $(element).closest('.form-inside').removeClass('has-error');
                        }
                    }
                });

                me.gatherBillingInfo();
                me.ui.monthSelect.addClass('month-dropdown').selectpicker();
                me.ui.monthSelect.on('change',function(e){
                    var selected = $(this).find("option:selected").val();
                    if(selected!='mm'){
                        me.ui.expirationMonth.val(selected);
                    }else{
                        me.ui.expirationMonth.val('');
                    }
                    if(me.ui.expirationYear.val()) {
                        me.ui.expirationMonth.valid();
                    }
                });

                me.selectizeYears();
                me.ui.yearSelect.addClass('year-dropdown').selectpicker();
                me.ui.yearSelect.on('change',function(e){
                    var selected = $(this).find("option:selected").val();
                    if(selected!='yyyy'){
                        me.ui.expirationYear.val(selected);
                    }else{
                        me.ui.expirationYear.val('');
                    }
                    if(me.ui.expirationMonth.val()) {
                        me.ui.expirationYear.valid();
                    }
                });

            },

            includeBillingInfo: function(){
                var me = this;
                var billingInfo = App.currentCompany.getBillingInfo();
                if(me.data) {
                    var address_line1 = me.ui.address_line1.val();
                    if (address_line1) me.data.address_line1 = address_line1;

                    var address_line2 = me.ui.address_line2.val();
                    if (address_line2) me.data.address_line2 = address_line2;

                    var address_city = me.ui.address_city.val();
                    if (address_city) me.data.address_city = address_city;

                    var address_zip = me.ui.address_zip.val();
                    if (address_zip) me.data.address_zip = address_zip;

                    var address_country = me.$el.find('[name="address_country"]').val();
                    if (address_country) me.data.address_country = address_country;
                }
            },

            gatherBillingInfo: function(){
                var me = this;

                var billingInfo = App.currentCompany.getBillingInfo();

                var address_line1 = billingInfo.get('billing_address1');
                if (address_line1) me.ui.address_line1.val(address_line1);

                var address_line2 = billingInfo.get('billing_address2');
                if (address_line2) me.ui.address_line2.val(address_line2);

                var address_city = billingInfo.get('billing_town');
                if (address_city) me.ui.address_city.val(address_city);

                var address_zip = billingInfo.get('billing_postcode');
                if (address_zip) me.ui.address_zip.val(address_zip);

                setTimeout(function() {
                    me.ui.address_country.flagStrap(
                        {
                            selectedCountry: billingInfo.get('country_code') || 'GB',
                        });
                },200);

            },

            onSubmit: function(){
                var me = this;

                var year = me.$el.find('.year-dropdown').find('button').attr('title');
                var month = me.$el.find('.month-dropdown').find('button').attr('title');

                me.data = {
                    number: me.ui.cardNumber.val(),
                    cvc: me.ui.cvc.val(),
                    name: me.ui.name.val()
                };

                if(year!='yyyy') me.data.exp_year = year;
                if(month!='mm') me.data.exp_month = month;

                me.includeBillingInfo();
                console.debug("Data: ", me.data);

                if(me.validator.form()) {
                    me.l = Ladda.create(me.ui.submitBtn[0]);
                    me.l.start();
                    me.disableForm();

                    Stripe.setPublishableKey(me.token);
                    Stripe.card.createToken(me.data, me.responseHandle);
                }
            },

            responseHandle: function(status,response){
                var view = App.mainRegion.currentView.modalRegion.currentView;
                
                if(response.error){
                    if(response.error.message){
                        view.ui.errorBox.find('p').text(response.error.message);
                    }
                    view.ui.errorBox.show();
                    if(view.l) view.l.stop();
                    view.enableForm();
                }else if(status == 200) {

                    var urlAddress = view.paymentUri;
                    if( !urlAddress.includes(App.apiUrl) ) {
                        urlAddress = App.apiUrl + urlAddress;
                    }

                    $.ajax({
                        url: urlAddress,
                        type: 'POST',
                        dataType: 'json',
                        beforeSend: function(request) {
                        	request.setRequestHeader('X-Alt-Referer', App.appUrl + '/#profile/payments');
                        },
                        data: JSON.stringify({'token': response.id}),

                        success: function (data, textStatus, jqXHR) {
                            console.debug("Success: ", data);
                            if(data.credits){
                                var currCredits = App.currentCompany.get('credits') || 0,
                                    credits = currCredits + data.credits;
                                App.currentCompany.set('credits', credits);
                                App.vent.trigger('App.layout.paymentStatus.show', 'You have successfully purchased ' + data.credits + ' credits.');
                            }else {
                                window.location.replace(App.appUrl + '/?success=1#profile/payments');
                            }
                            view.destroy();
                        },

                        error: function (data) {
                            console.debug("Error: ", data);
                            view.ui.errorBox.find('p').text(data.responseJSON.reason);
                            view.ui.errorBox.show();
                            if(view.l) view.l.stop();
                            view.enableForm();
                        }
                    });

                }
            }



        });
    });

