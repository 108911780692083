// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/CreateCampaignAudience',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery','circliful', 'moment',
         'hbs!templates/views/Dashboard/CreateCampaignAudience'
         ],
    function (App, Backbone, _, Marionette, $,circliful, moment,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'widget',


            ui: {
                widgetContent: '.content',


                Location: "#NearbyBelow",
                Shopper: "#ShopperBelow",
                Corporate: "#CorporateBelow",
                Travel: "#TravelBelow",
                Students: "#StudentsBelow",

                Sports: "#SportsBelow",
                Silver: "#SilverBelow",
                SATParents: "#SATParentsBelow",
                Families: "#FamiliesBelow",
                Quality: "#QualityBelow",

                Affluent: "#AffluentBelow",
                Grocery: "#GroceryBelow",
                Millennials: "#MillennialsBelow",
                Fit: "#FitBelow",
                Moto: "#MotoBelow",

                Health:"#HealthBelow",


                LocationButton: "#NearbyBelowButton",
                ShopperButton: "#ShopperBelowButton",
                CorporateButton: "#CorporateBelowButton",
                TravelButton: "#TravelBelowButton",
                StudentsButton: "#StudentsBelowButton",
                audienceButtons:'.audience-buttons',
                audienceRow:'.create-campaign-audience-row'
            },
            events: {
                'click .audience-buttons': 'pushMe'
            },

            initialize: function (options) {
                var me=this;
            },

            onRender: function () {
                var me = this;
                var i =0;
            },


            pushMe: function(event){
                var me = this; 
                window.step2 = true;
                $('.budget-row .white-panel').animate({'opacity':'1'},300);
                $('.budget-row .white-panel-fadeout').hide();
                window.campaignAudiencePoints = parseInt($(event.currentTarget).find('.points').html());
                    var button = $('#'+event.currentTarget.id);
                    window.selectedAudience =  ($(event.currentTarget).attr('id')).replace('BelowButton','');
                    var id = $(button).attr('id');
                    var points = parseInt($(button).find('.points').html());
                    var title = $(button).find('.title').html();
                    var color = $(button).attr('color');
                    me.selectedAudienceText = $(button).find('h4').text();
   		            $('[name="audience"]').attr('value', me.selectedAudienceText);
   		            $('[name="audience"]').val(me.selectedAudienceText).focus().focusout();
                    window.displayGroupId = $(button).attr('display-group-id');
                    $('#selected-audience').html(me.selectedAudienceText);
                    $('.selected-screens-summary p:eq(1)').show();
                    $('#availableAudiencesWidgetBelow').find('.audiences-blocks').removeClass('selected').addClass('unselected');
                    $('.create-campaign-audience').find('.audiences-blocks').removeClass('selected').addClass('unselected');
                    $(event.currentTarget).addClass('selected').removeClass('unselected');
                    if(window.step1 == true && window.step3 == true){
                        $('#create-campaign-button').removeClass('disabled');
                        $('#advanced-campaign-button').removeClass('disabled');
                    } else {
                    	$('#create-campaign-button').attr('href','#').addClass('disabled');
                        $('#advanced-campaign-button').attr('href','#').addClass('disabled');
                    }

	                if(window.step3 || window.page!='dashboard'){
	                      App.vent.trigger('recalculate-intensity');
	                }

                // if new offer already created on dashboard, modify its value
                if(window.page === 'dashboard' && window.newOfferAttributes) {
                    $.ajax({
                        url: App.enrichApiUrl(window.newOfferAttributes.urlAddress),
                        type: 'PUT',
                        dataType: "json",
                        data: JSON.stringify({
                            audience: me.selectedAudienceText
                        })
                    }).done(function (data) {
                        console.log('success');
                    }).fail(function (data) {
                        console.log('error');
                    });
                }
                        
            },
            

            onShow: function () {
                
                var me = this;
                if(window.landingPage==undefined)
                window.landingPage = window.page;
                //me.audienceInit =0;
                setTimeout(function () {

                    if(window.all_audiences == undefined && window.landingPage == window.page){
                        $.when(App.currentDepartment.getDisplayGroups().promise)
                            .then(function (data) {
                                window.all_audiences = data;
                                me.getAudiences(data);
                            });
                    } else {
                        me.getAudiences(window.all_audiences);
                    }

                }, 200);
            },
            getAudiences: function(data){
            	var me = this;

                    console.log(data);
                //if( me.audienceInit==0){
                    //me.audienceInit =1;
                    var iconLookup = {
                      "Location": "../../../../../static/images/icons/dashboard-31.svg",
                      "Shopper":"../../../../../static/images/icons/dashboard-32.svg",
                      "Corporate": "../../../../../static/images/icons/dashboard-33.svg",
                      "Travel":"../../../../../static/images/icons/dashboard-34.svg",
                      "Students":"../../../../../static/images/icons/dashboard-35.svg",

                      "Sports": "../../../../../static/images/icons/dashboard-SPORTS.svg",
                      "Silver":"../../../../../static/images/icons/dashboard-SILVER.svg",
                      "SATParents": "../../../../../static/images/icons/dashboard-SAT.svg",
                      "Families":"../../../../../static/images/icons/dashboard-FAMILIES.svg",
                      "Quality":"../../../../../static/images/icons/dashboard-QUALITY.svg",

                      "Affluent": "../../../../../static/images/icons/dashboard-AFFLUENT.svg",
                      "Grocery":"../../../../../static/images/icons/dashboard-GROCERY.svg",
                      "Millennials": "../../../../../static/images/icons/dashboard-MILLENNIALS.svg",
                      "Fit":"../../../../../static/images/icons/dashboard-FIT.svg",
                      "Moto":"../../../../../static/images/icons/dashboard-MOTO.svg",

                      "Health":"../../../../../static/images/icons/dashboard-BEAUTY.svg"
                    };

                    for(var iconData in iconLookup) {
                        // console.log(iconData + ' - ' + iconLookup[iconData]);

                        var icon = $(eval('me.ui.'+iconData));
                        if(icon.html()===''){
                            icon.circliful({
                                foregroundBorderWidth: 7,
                                backgroundBorderWidth:7,
                                percent: 50,
                                backgroundColor: '#F4F5F7',
                                foregroundColor: '#124d80',
                                fontColor: '#333'
                            });
                        }
                        icon.find('text').first().remove();
                        // $(eval('me.ui.'+(data[i].display_name)+'Button')).find('h4').html(data[i].display_name);
                        // $(eval('me.ui.'+(data[i].display_name)+'Button')).find('h3').html(estimated_audience);
                        icon.append('<div class="img-container"><img src="'+iconLookup[iconData]+'" class="diagram_icon"></div>');

                        icon.closest('[role="button"]').hide();
                    }

                    // TODO change when full JSON from server is ready
                    var i=0;
                    // data = ["Nearby", "Shopper", "Corporate", "Travel", "Students"];

                    data.push({
                        admin_group: false,
                        archived: false,
                        audience_group: true,
                        available_audience: 25,
                        credit_cost: 1,
                        display_name: "Shopper",
                        estimated_audience: 944});
                    $(data).each(function(){

                        if(!data[i].available_audience){i++; return;}
                        $(eval('me.ui.'+(data[i].display_name)+'Button')).attr('display-group-id',data[i].id);



                        if(window.selectedAudience!=undefined){
                            $('.create-campaign-audience .audiences-blocks').removeClass('selected').addClass('unselected');
                            $('#'+window.selectedAudience+'BelowButton').removeClass('unselected').addClass('selected');
                        }
                        var available_audience = data[i].available_audience;
                        var estimated_audience= data[i].estimated_audience;
                        var icon = $(eval('me.ui.'+(data[i].display_name)));
                        if(icon.html()===''){
                            icon.circliful({
                                foregroundBorderWidth: 7,
                                backgroundBorderWidth:7,
                                percent: available_audience/estimated_audience*100,
                                backgroundColor: '#F4F5F7',
                                foregroundColor: '#124d80',
                                fontColor: '#333'
                            });
                        }
                        icon.find('text').first().remove();
                        $(eval('me.ui.'+(data[i].display_name)+'Button')).find('h4').html(data[i].display_name);
                        $(eval('me.ui.'+(data[i].display_name)+'Button')).find('h3').html(estimated_audience);
                        // $("#Nearby").find('text').remove();	UNCOMMENT TO REMOVE PERCENT NUMBER
                        // $("#Nearby").find('g').remove();		UNCOMMENT TO REMOVE STICK BETWEEN PERCENT ANG CIRCLE

                        icon.closest('[role="button"]').show();
                        // .append('<div class="img-container"><img src="'+iconLookup[data[i].display_name]+'" class="diagram_icon"></div>');


                        i++;
                    });
                  	//}
                     if(window.page!=='dashboard'){
                         if(window.selectedAudience==undefined) window.selectedAudience = App.currentOffer.get('audience');
                            console.log(window.selectedAudience);
                            $(me.ui.audienceButtons).removeClass('selected').addClass('unselected');
                            $(me.ui.audienceRow).find('#'+window.selectedAudience+'BelowButton').addClass('selected').removeClass('unselected');
                            window.campaignAudiencePoints = $(me.ui.audienceRow).find('#'+window.selectedAudience+'BelowButton .points').html();
                     			var completeCounter  = setTimeout(function(){
                                    window.campaignAudiencePoints = parseInt($(me.ui.audienceRow).find('#'+window.selectedAudience+'BelowButton .points').html());
                                    //window.campaignFormatCount = parseInt($('[variant="'+($('#offer_variant').val())+'"]').find('.count').html());
                                    //window.intensity =  parseFloat($('[name="intensity"]').val());
                                    if(App.currentOffer != undefined) window.intensity = App.currentOffer.get('intensity');
                                    //me.calculateIntensity();
                				},4100);

                      }

            },
            calculateIntensity: function(){
            	var intensityValue = parseFloat(window.intensity);
	                    var impressions = (window.campaignFormatCount/((10-intensityValue+1)*5))*60*intensityValue; 
	                    var priceperhour = impressions*window.campaignAudiencePoints/100000;
	                    var val = parseInt(Math.abs(intensityValue));
	                    var days = parseInt((window.budget/priceperhour)*0.0833);
	                    var hours = parseInt(window.budget/priceperhour);
                    console.log(impressions+'*'+window.intensity);
                    console.log('priceperhour'+priceperhour);
	                    if(isNaN(days)){ 
	                        days=10; 
	                        hours=50; 
	                        intensityValue =1;
	                        console.log(days);
	                    }

	                    var intensity ='low';
	                    if(intensityValue>3) var intensity ='medium';
	                    if(intensityValue>7) var intensity ='high';
	                    var s = (days>1)?'s':'';
	                    if(days<1){
	                        if(hours>0){
	                           var sh =  (hours>1)?'s':''; 
	                            $("#range-time").html(hours+' hour'+sh);
	                            $('#selected-days').html(hours+' hour'+sh);
	                        } else {
	                            $("#range-time").html('<1 hour');
	                            $('#selected-days').html('<1 hour');
	                        }
	                    } else {
	                        //console.log(days);
	                        $("#range-time").html(days+' day'+s);
	                        $('#selected-days').html(days+' day'+s);
	                    }
	                    $("#time_slider").slider('value',intensityValue);
	        },
            onDestroy: function () {
                var me = this;
            }

        });
    });
	
