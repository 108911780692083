// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/DisplayGroupsAdminSearchable',["backbone", "models/DisplayGroup"],
    function (Backbone, DisplayGroup) {

        'use strict';
        var DisplayGroupsAdminSearchable = Backbone.SearchableCollection.extend({
            searchUri: '/data/search/display-groups/admin',
            model: DisplayGroup
        });

        return DisplayGroupsAdminSearchable;
    });
