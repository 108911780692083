// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global console*/
(function ($) {

    'use strict';

    var methods = {

        init: function (options) {

            return this.each(function () {

                var $widget = $(this),
                    update,
                    $button = $widget.find('button'),
                    $checkbox = $widget.find('input:checkbox'),
                    $color = $button.data('color'),
                    settings = {
                        on: {
                            icon: 'glyphicon glyphicon-check'
                        },
                        off: {
                            icon: 'glyphicon glyphicon-unchecked'
                        }
                    };


                // Event Handlers
                $button.on('click', function () {
                    $checkbox.prop('checked', !$checkbox.is(':checked'));
                    $checkbox.triggerHandler('change');
                    update();
                });

                $checkbox.on('change', function () {
                    update();
                });

                update = function () {
                    var isChecked = $checkbox.is(':checked');

                    // Set the button's state
                    $button.data('state', (isChecked) ? "on" : "off");

                    // Set the button's icon
                    $button.find('.state-icon')
                        .removeClass()
                        .addClass('state-icon ' + settings[$button.data('state')].icon);

                    // Update the button's color
                    if (isChecked) {
                        $button
                            .removeClass('btn-default')
                            .addClass('btn-' + $color + ' active');
                    } else {
                        $button
                            .removeClass('btn-' + $color + ' active')
                            .addClass('btn-default');
                    }
                };

                update();

                if ($button.find('.state-icon').length === 0) {
                    $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                }

            });
        },

        value: function () {
            return $(this).find('input:checkbox').is(':checked');
        }
    };

    $.fn.checkboxButton = function (method) {

        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist');
        }
    };

})(jQuery);


define("checkboxButton", ["jquery"], function(){});

