// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/parse',['App', 'handlebars', 'underscore'], function (App, Handlebars, _) {

    'use strict';
    function parse(field, toParse) {
        var parameters = JSON.parse(field);
        if(parameters)
            return parameters[toParse];
        else
            return '-';
    }

    Handlebars.registerHelper('parse', parse);
    return parse;
});
