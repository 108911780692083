// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/GenderDistributionWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/GenderDistributionWidget',
         'views/Dashboard/WidgetBase',
         'models/DemographicsData',

         'gauge'
         ],
    function (App, Backbone, _, Marionette, $, moment,
              template,
              _WidgetBase,
              DemographicsData) {

        'use strict';
        var WidgetBase = _WidgetBase.prototype;
        return _WidgetBase.extend({

            template: template,

            className: 'white-panel pn widget widget--gender-distribution-widget',
            widgetName: 'GenderDistributionWidget',
            widgetType: 'gender',

            onRender: function () {
                var me = this;

                var opts = {
                    lines: 12,
                    angle: 0.14,
                    lineWidth: 0.44,
                    pointer: {
                        length: 0.89,
                        strokeWidth: 0.051,
                        color: '#000000'
                    },
                    limitMax: 'false',
                    colorStart: '#14526B',
                    colorStop: '#14526B',
                    strokeColor: '#1F9D99',
                    generateGradient: true
                };

                me.gauge = new Gauge(me.$el.find('canvas')[0]).setOptions(opts);
                me.gauge.maxValue = 100;
                me.gauge.animationSpeed = 32;

                return WidgetBase.onRender.apply(me, arguments);

            },

            _update: function () {
                var me = this,
                    // data = App.currentSensorGroup.getDemographics().at(0);
                    data = new DemographicsData();

                if (!_.isUndefined(data) && !_.isNull(data.getData(me.widgetType))) {
                    me.gauge && me.gauge.set(data.getData(me.widgetType).male);
                    me.$el.find('.desc__males .value').html(data.getData(me.widgetType).male.toFixed(2) + '<span>%</span>');
                    me.$el.find('.desc__females .value').html(data.getData(me.widgetType).female.toFixed(2) + '<span>%</span>');
                } else {
                    me.$el.find('.widget__body--no-camera').html('Data not available');
                    me.$el.addClass('widget--no-camera');
                }
                me._onResize();
                me.$el.find('.widget__loader').hide();
            }

        });
    });

