// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleWeather',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleWeather', 'moment'],
    function (App, $, _, Backbone, _OfferScheduleBase, template, moment) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'change [name="sky_condition"]': '_updateModelFromRadio',
                'change [name="temperature"]': '_updateModelFromRadio'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                // set current values
                me._setRadioState('sky_condition', me.model.get('sky_condition'));
                me._setRadioState('temperature', me.model.get('temperature'));

                return result;
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('sky_condition')) && _.isEmpty(me.model.get('temperature'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }

                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
            }

        });

    });
