// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('routers/router',['App', 'backbone', 'backbone.marionette'],
    function (App, Backbone, Marionette) {

        'use strict';
        return Backbone.Marionette.AppRouter.extend({

            // blacklisted keywords - hashes containing these keywords should not be recorded as last_view
            blackList: [
                "login",
                "signup",
                "signupfb",
                "forgot-password",
                "password-instructions-sent",
                "password-change-error",
                "password-resets"
            ],

            //"index" must be a method in AppRouter's controller
            appRoutes: {
                "": "audienceDashboard",
                "dashboard/:groupid": "audienceDashboard",
                "campaigns-dashboard": "campaignsDashboard",
                "new-campaign": 'newCampaign',
                "login": 'login',
                "signup": 'signup',
                "signupfb": 'signupfb',
                "creative-spec": "creative",
                "dashboard/:popup": 'audienceDashboard',
                "companies/:cid/offers": 'offers',
                "companies/:cid/offers/:oid": 'offer',
                "companies/:cid/offers/:oid/:advanced": 'offer',
                "screen-map": 'screenMap',
                "submitted-offers": 'submittedOffers',
                "forgot-password": 'forgotPassword',
                "password-instructions-sent": 'passwordInstructions',
                "password-change-error": 'passwordChangeError',
                "password-resets/:skey": "changePassword",
                "loop-management": 'loopManagement',
                "loop-management/display-groups/:dgid": 'loopManagement',
                "notifications": 'notifications',
                "notifications-history": 'notificationsHistory',
                "profile" : 'profile',
                "profile/:tab" : 'profile',
                "offers-ng" : 'offersNg',

                // reports
                "reports" : 'reports',
                "internal-reports": "internalReports",

                // admin screens
                "screens": 'screens',
                "display-groups": 'displayGroups',
                "companies": 'retailers',

                // default path
                '*path':  'defaultRoute'
            },

            navigate: function (modelOrHref, options) {
                var href = App.os.getRoutingUrl(modelOrHref);
                window.href = App.os.getRoutingUrl(modelOrHref);
                window.mapLoaded = 0;
                return Backbone.Marionette.AppRouter.prototype.navigate.call(this, href, options);
            }
        });
    });

