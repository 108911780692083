/**
 * Extended support for CrashKit backtraces and global error handling for most
 * asynchronous jQuery functions. 
 */  

define('crashkit-jquery',['require', 'jquery', 'ua-parser-js'],
function (require, $, UAParser) {
    if (typeof CrashKit !== 'undefined') {
        var _oldEventAdd = $.event.add;
        $.event.add = function (elem, types, handler, data, selector) {
            var _handler,
                // asyncStack = TraceKit.computeStackTrace.ofCaller(),
                elemToStr = function (elem) {
                    var elStr = '';
                    if (elem) {
                        elStr = elem.tagName || '';
                        if (elem.id) {
                            elStr += ('#' + (elem.id || ''));
                        }
                        if (elem.className) {
                            elStr += ('.' + (elem.className || '').replace(' ', '.'));
                        }
                    }
                    return elStr;
                },
                eventInfo = {
                    elem: elemToStr(elem),
                    types: types,
                    selector: selector
                },
                fillEventInfo = function (evt) {
                    eventInfo.type = evt.type;
                    eventInfo.currentTarget = elemToStr(evt.currentTarget);
                    eventInfo.target = elemToStr(evt.target);
                    eventInfo.delegateTarget = elemToStr(evt.delegateTarget);
                    eventInfo.relatedTarget = elemToStr(evt.relatedTarget);
                    eventInfo.toElement = elemToStr(evt.toElement);
                    eventInfo.timeStamp = evt.timeStamp;
                    eventInfo.cancelable = evt.cancelable;
                    eventInfo.bubbles = evt.bubbles;
                    eventInfo.pageX = evt.pageX;
                    eventInfo.pageY = evt.pageY;
                };
                
            if (handler.handler) {
                _handler = handler.handler;
                handler.handler = function () {
                    try {
                        return _handler.apply(this, arguments);
                    }
                    catch (e) {
                        // e.asyncStack = asyncStack;
                        if (arguments.length && eventInfo) {
                            fillEventInfo(arguments[0]);
                        }
                        e.eventInfo = eventInfo;
                        TraceKit.report(e);
                        throw e;
                    }
                };
            } else {
                _handler = handler;
                handler = function () {
                    try {
                        return _handler.apply(this, arguments);
                    }
                    catch (e) {
                        // e.asyncStack = asyncStack;
                        if (arguments.length && eventInfo) {
                            fillEventInfo(arguments[0]);
                        }
                        e.eventInfo = eventInfo;
                        TraceKit.report(e);
                        throw e;
                    }
                };
            }
    
            // If the handler we are attaching doesn’t have the same guid as
            // the original, it will never be removed when someone tries to
            // unbind the original function later. Technically as a result of
            // this our guids are no longer globally unique, but whatever, that
            // never hurt anybody RIGHT?!
            if (_handler.guid) {
                handler.guid = _handler.guid;
            }
            else {
                handler.guid = _handler.guid = $.guid++;
            }
    
            return _oldEventAdd.call(this, elem, types, handler, data, selector);
        };
    
        var _oldReady = $.fn.ready;
        $.fn.ready = function (fn) {
            // var asyncStack = TraceKit.computeStackTrace.ofCaller();
            var _fn = function () {
                try {
                    return fn.apply(this, arguments);
                }
                catch (e) {
                    // e.asyncStack = asyncStack;
                    TraceKit.report(e);
                    throw e;
                }
            };
    
            return _oldReady.call(this, _fn);
        };
    
        var _oldAjax = $.ajax;
        $.fn.ajax = function (url, options) {
            // var asyncStack = TraceKit.computeStackTrace.ofCaller();

            // If url is an object, simulate pre-1.5 signature
            if ( typeof url === "object" ) {
                options = url;
                url = undefined;
            }
                
            if ($.isFunction(options.complete)) {
                var _oldComplete = options.complete;
                options.complete = function () {
                    try {
                        return _oldComplete.apply(this, arguments);
                    }
                    catch (e) {
                        // e.asyncStack = asyncStack;
                        TraceKit.report(e);
                        throw e;
                    }
                };
            }
    
            if ($.isFunction(options.error)) {
                var _oldError = options.error;
                options.error = function () {
                    try {
                        return _oldError.apply(this, arguments);
                    }
                    catch (e) {
                        // e.asyncStack = asyncStack;
                        TraceKit.report(e);
                        throw e;
                    }
                };
            }
    
            if ($.isFunction(options.success)) {
                var _oldSuccess = options.success;
                options.success = function () {
                    try {
                        return _oldSuccess.apply(this, arguments);
                    }
                    catch (e) {
                        // e.asyncStack = asyncStack;
                        TraceKit.report(e);
                        throw e;
                    }
                };
            }
    
            try {
                if (url !== undefined) {
                    return _oldAjax.call(this, url, options);
                } else {
                    // use old signature if url undefined
                    return _oldAjax.call(this, options)
                }
            }
            catch (e) {
                TraceKit.report(e);
                throw e;
            }
        };
    
        var _oldCallbacks = $.Callbacks;
        $.Callbacks = function(options) {
            var self = _oldCallbacks.call(this, options);
            var _oldAdd = self.add,
                _oldRemove = self.remove;
                // asyncStack = TraceKit.computeStackTrace.ofCaller();
    
            var origToWrapped = {};
            
            var crashWrap = function (fn) {
                var wrapped = function wrappedCallback() {
                    try {
                        return fn.apply(this, arguments);
                    }
                    catch (e) {
                        var err = e;
                        if (typeof(err) == 'string') {
                            // try to gather as much as possible from string exception
                            // the only info available is the wrapped function and its arguments.
                            try {
                                err = new Error(e);
                                err.wrappedFn = {
                                    funcName: fn.name,
                                    argsString: JSON.stringify(Array.prototype.slice.call(arguments, 0)),
                                    funcBody: fn.toString()
                                };
                            } catch(x) { ; }
                        }
                        // err.asyncStack = asyncStack;
                        CrashKit.report(err);
                        throw e;
                    }
                };
                wrapped.originalFn = fn;
                origToWrapped[fn] = wrapped;
                return wrapped;
            };
            
            self.add = function jqueryCallbacksAddCrashkitWrapped () {
                var wrappedArguments = [];
                (function crashWrapAll(args) {
                    jQuery.each(args, function(idx, arg) {
                        var type = jQuery.type(arg);
                        if (type === "function") {
                            wrappedArguments.push(crashWrap(arg));
                        } else if (arg && arg.length && type !== "string") {
                            // Inspect recursively
                            crashWrapAll(arg);
                        }
                    });
                })(arguments);
                
                return _oldAdd.apply(this, wrappedArguments);
            };
            
            self.remove = function jqueryCallbacksRemoveCrashkitWrapped() {
                var unwrappedArgs = [];
                jQuery.each(arguments, function(idx, arg) {
                    unwrappedArgs.push(origToWrapped[arg] || arg);
                });
                return _oldRemove.apply(this, unwrappedArgs);
            };
            
            return self;
        }
    
        function jsErrorPath(stackInfo) { 
            var userAgent = new UAParser(),
                browser = userAgent.getBrowser(),
                device = userAgent.getDevice(),
                os = userAgent.getOS();
                urlSuffix = '@' + [browser.name, browser.version, device.type || 'pc', os.name].join('/') + ',' + window.location.pathname || '/';
            
            if (window.location.hash) {
                /** e.g. "/companies/_/projects/_/+today" **/
                urlSuffix += ('@' + window.location.hash.replace(/(\/\d+)/g, '').replace('#', ''));
            }
            if (stackInfo) {
                if (stackInfo.message) {
                    var errorType = stackInfo.message.split(':', 2);
                    if (errorType && errorType.length) {
                        /** e.g. "/TypeError" **/  
                        urlSuffix += (',' + encodeURIComponent(errorType[0].replace(/\s+/g, '_')))
                    }
                }
            
                if (stackInfo.stack && stackInfo.stack.length && stackInfo.stack[0]) {
                    var stackFrame = stackInfo.stack[0];
                    if (stackInfo.stack.length > 1 && stackInfo.stack[1] && 
                        (stackFrame.url || '').indexOf('crashkit-javascript.js') >= 0 && 
                        (stackInfo.stack[1].url || '').indexOf('crashkit-javascript.js') < 0) {
                        // nothing useful in augmented stack frame 0 - will be probably throw err from crashkit itself
                        // unless next frame is crashkit-javascript.js too, then maybe it's error in crashkit itself.
                        stackFrame = stackInfo.stack[1];
                    }
                    var url = stackFrame.url || '',
                        func = stackFrame.func,
                        line = stackFrame.line;
                    /** e.g. "/filename.js" **/
                    if (url) {
                        url = url.split('/')
                        if (url.length) {
                            urlSuffix += ('@' + url.pop());
                        }
                    }
                    if (func) {
                        urlSuffix += (',' + func);
                    }
                    if (line) {
                        urlSuffix += (',' + line);
                    }
                }
            } else {
                urlSuffix += '/MISSING-STACK';
            }
            return urlSuffix;
        };
        
        CrashKit.report.subscribe(function (stackInfo) {
            require(['underscore', 
                     'source-map/source-map-consumer'], 
            function(_, 
                     SourceMapConsumer) {
                var stack = stackInfo.stack;
                console.error('stackInfo: ', stackInfo);
                
                function isSourceMappable (stackItem) {
                    return stackItem.column && stackItem.line && stackItem.url && stackItem.url.indexOf('flowads-app.js') >= 0;
                }
                
                function sendReport() {
                    for (var i = 0; i < stack.length; i++) {
                        if (stack[i] !== undefined && stack[i].context && stack[i].context.length) {
                            for (var j = 0; j < stack[i].context.length; j++) {
                                if (stack[i].context[j] && stack[i].context[j].length > 320)  {
                                    // truncate context lines longer than 4 typical source code screens 
                                    stack[i].context[j] = stack[i].context[j].slice(0,160) + '/*...cut by crashkit...*/' + stack[i].context[j].slice(-160);   
                                }
                            }
                        } 
                    }
                    
                    var description = {
                        stack: stack, 
                        wrappedFn: stackInfo.wrappedFn || '',
                    };
                    
                    if (stackInfo.eventInfo) {
                        description.eventInfo = stackInfo.eventInfo;
                    }
                    
                    var data = {
                        title: "Exception handled in " + stackInfo.mode + ": "+ stackInfo.message,
                        url: window.location.href,
                        page: document.title,
                    };
                    
                    console.error('CrashKit caught error:', data, stack);
                    data.description = JSON.stringify(description);

                    var sendError = false;
                    var jsErrorsUri = '/data/javascript-errors'; //fallback for site where it's no sessionData
                    jsErrorsUri += jsErrorPath(stackInfo);
                    
                    if (typeof sessionData != 'undefined' && sessionData.company !== undefined) {
                        // always send error for non ``test-`` companies
                        sendError = (sessionData.company.name.indexOf("test-") !== 0);
                    }
                    // send error for ``test-`` companies if message is not ``setting a property that has only a getter``
                    if (! sendError && stackInfo.message != "setting a property that has only a getter") {
                        sendError = true;
                    }
                    // ignore errors: 'Error loading script' for uservoice code
                    if (data.description.indexOf("cdn.uservoice.com/javascripts/widgets/tab.js") > 0) {
                        sendError = false;
                    }
                    
                    if (sendError) {
                        $.ajax({
                            type: "POST",
                            url: jsErrorsUri,
                            dataType: 'json',
                            contentType: 'application/json; charset=UTF-8',
                            data: JSON.stringify(data)
                        });  
                    }                    
                }
                
                if (stack && stack.length) {
                    // check if there is async stack stored too. 
                    // async stack is part of stack before AJAX or setTimeout/setInterval call
                    if (stackInfo && stackInfo.asyncStack && stackInfo.asyncStack.stack && stackInfo.asyncStack.stack.length) {
                        // filterout frame computeStackTraceOfCaller which is irrelevant overhead and add ASYNC marker frame
                        var asyncStack = _.filter(stackInfo.asyncStack.stack, function(as) { return as && as.func && as.func.indexOf('computeStackTraceOfCaller') < 0; });
                        asyncStack.unshift({
                            column: 0,
                            line: 0,
                            func: '========== ASYNC =========',
                            url: '',
                            context: []
                        });
                        stack = stack.concat(asyncStack);
                    }   
                    
                    
                    // if at least one stack item is from APPNAME-app.js and has meaningful line/column info, then source map will help to diagnose error so it's worth to load this big file
                    var sourceMapWillHelp = (undefined !== _.find(stack, isSourceMappable));
                    
                    if (sourceMapWillHelp) {
                        $.ajax({
                            type: "GET",
                            url: 'js/flowads-app.js.map',
                            dataType: 'json',
                            contentType: 'application/json; charset=UTF-8',
                        }).done(function(sourceMap, textStatus, jqxhr) {
                            //try {
                                var smc = new SourceMapConsumer(sourceMap);
                                _.each(stack, function (stackItem) {
                                    if (isSourceMappable(stackItem)) {
                                        var src = smc.originalPositionFor({line: stackItem.line, column: stackItem.column});
                                        stackItem.column = src.column;
                                        stackItem.line = src.line;
                                        stackItem.url = 'js/app/' + src.source;
                                        stackItem.name = src.name;
                                        stackItem.decoded = true;
                                        // swap context, as the original is not usefuly at all.
                                        stackItem.context = CrashKit.computeStackTrace.gatherContext(stackItem.url, stackItem.line);
                                    }
                                });
                            //} finally {
                                sendReport();
                            // }
                        });
                    } else {
                        sendReport();
                    }
                        
                }
                

            });
        });
    } 

    return $;
});

