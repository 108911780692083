
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentUsers',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"department-user-list\">\n    <div class=\"filter-panel top-panel\" style=\"padding: 0\">\n        <button class=\"btn btn-default btn-lg btn-top-bar pull-right addUserBtn\"><i class=\"fa fa-plus\"></i> Add new user</button>\n    </div>\n\n    <div class=\"content-panel\">\n        <div class=\"scroll-area department-users-scroll-area\">\n            <ul class=\"retailer-users\"></ul>\n        </div>\n    </div>\n\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
