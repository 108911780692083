
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentUserConfiguration',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<p>Configuration</p>\n<div class=\"user-profiles\"></div>\n<div class=\"display-groups-configuration\"></div>\n<div class=\"buttons\" style=\"padding: 0\">\n    <button class=\"btn btn-danger delete-btn\"><i class=\"fa fa-trash-o\"></i> Delete user</button>\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
