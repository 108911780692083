// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Offers/OfferTemplates',['App',
        'backbone',
        'hbs!templates/views/Offers/OfferTemplates',
        'views/Offers/OfferTemplate'
        ],
    function (
        App,
        Backbone,
        template,
        OfferTemplate
    ) {

        'use strict';
        return Backbone.Marionette.SortedCompositeView.extend({
            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary offer-templates-dialog',

            childView: OfferTemplate,

            childViewContainer: '.offer-templates',

            events: {
                'click .confirm-btn' : '_selectOfferTemplate'
            },

            initialize: function () {
                var me = this;
                me.collection = App.currentCompany.getOfferTemplates();
                me.listenTo(App.vent, 'App.offer.template.select', _.bind(me._selectTemplate, me));
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items"><i class="fa fa-book"></i> You have no offers</div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            onRender: function () {
                var me = this;

                me.$el.find('.no-items').html('');
                $.when(App.currentCompany.getOfferTemplates().promise).then(function () {
                    me.$el.find('.modal-loading').hide();
                    me.$el.find('.no-items').html('<i class="fa fa-book"></i> You have no templates');
                });
            },

            filter: function (child, index, collection) {
                return child.get('type') === App.currentOffer.get('offer_type') || child.get('type') === 'none';
            },

            onAddChild: function (view) {
                if (view.model.get('uri') === App.currentOffer.get('source_template')) {
                    view.model.set('selected', true);
                } else {
                    view.model.set('selected', false);
                }
            },

            _selectTemplate: function (templateUri) {
                var me = this;
                me.collection.getSelected() && me.collection.getSelected().set('selected', false);
                me.collection.getByUri(templateUri).set('selected', true);
            },

            _selectOfferTemplate: function (event) {
                event && event.preventDefault();

                var me = this;

                if (App.currentOffer.get('source_template') !== me.collection.getSelected().get('uri')) {
                    App.currentOffer.set('source_template', me.collection.getSelected().get('uri'));
                    App.currentOffer['_template'] = me.collection.getSelected();
                    App.currentOffer.save().done(function onSave() {
                        App.vent.trigger('App.layout.offer.refreshPreview');
                        me.destroy();
                    });
                } else {
                    me.destroy();
                }

            }



        });
    });

