// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/Demographics',["backbone", "models/DemographicsData", "moment"],
    function (Backbone, DemographicsData, moment) {

        'use strict';
        var Demographics = Backbone.Collection.extend({
            model: DemographicsData,

            preprocessed: false,
            fillGaps: false,

            granularityMap: {
                'hour'  : {baseFactor: 1, descFactor: 'hour'},
                'day'   : {baseFactor: 1, descFactor: 'day'},
                '5m'    : {baseFactor: 5, descFactor: 'minute'}
            },

            granularity: 'day',

            initialize: function (options) {
                this.fillGaps = options && options.fillGaps || false;
                this.granularity = options && options.granularity || 'day';
            },

            getFirstFromRange: function () {
                return this.findWhere({from: moment(App.currentSensorGroupFilter.get('from')).startOf('day').format('YYYY-MM-DDT00:00:00.000000') });
            },

            parse: function (data, options) {
                this.preprocessed = false;
                if (_.size(data) > 0 && this.fillGaps) {
                    console.debug('parse -> fillGaps: ', this.fillGaps);
                    return this._fillDataGaps(data);
                } else {
                    return data;
                }
            },

            _fillDataGaps: function (arr) {
                var me = this,
                    d = _.sortBy(arr, function(data){ return moment(data.from); }),
                    _last = arr[0].from;
                    //_last = moment(this.at(0).get('from'));

                if (!this.preprocessed) {
                    _.each(d, function (p) {
                        if (!_.isNull(_last)) {
                            var gap = moment(p.from).diff(_last, me.granularityMap[me.granularity].descFactor);
                            if (gap > me.granularityMap[me.granularity].baseFactor) {
                                for (var i = 1; i <= parseInt(gap/me.granularityMap[me.granularity].baseFactor) - 1; i++) {
                                    var newPoint = moment(_last).add(i * me.granularityMap[me.granularity].baseFactor, me.granularityMap[me.granularity].descFactor);
                                    d.push({
                                        "from": newPoint.format("YYYY-MM-DDTHH:mm:00.000000"),
                                        "to": newPoint.clone().add(me.granularityMap[me.granularity].baseFactor, me.granularityMap[me.granularity].descFactor).format("YYYY-MM-DDTHH:mm:00.000000"),
                                        "visits": 0,
                                        "viewing": 0
                                    });
                                }
                            }
                            _last = moment(p.from);
                        }
                    });
                    this.preprocessed = true;
                    d = _.sortBy(d, function(data){ return moment(data.from); });
                }
                return d;
            },

            comparator: function (model) {
                return model.get('from') ? moment(model.get('from')) : 1;
            }
        });

        return Demographics;
    });
