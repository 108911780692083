
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/CreateUser',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-header\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">Add new User</div>\n        </div>\n    </div>\n\n    <div class=\"modal-body\">\n        <div class=\"form-group\">\n            <label><span class=\"bullet\">1</span>Enter user email address</label>\n            <input type=\"text\" class=\"form-control editable\" name=\"email\" value=\"\" placeholder=\"email@address.com\" autocapitalize=\"off\">\n        </div>\n\n        <div class=\"form-group\">\n            <label><span class=\"bullet\">2</span>Enter user first name</label>\n            <input type=\"text\" class=\"form-control editable\" name=\"firstname\" value=\"\">\n        </div>\n\n        <div class=\"form-group\">\n            <label><span class=\"bullet\">3</span>Enter user last name</label>\n            <input type=\"text\" class=\"form-control editable\" name=\"lastname\" value=\"\">\n        </div>\n\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Cancel", options) : helperMissing.call(depth0, "trans", "Cancel", options)))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary create-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Create", options) : helperMissing.call(depth0, "trans", "Create", options)))
    + "</span></button>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
