
/* START_TEMPLATE */
define('hbs!templates/views/Displays/DisplayGroups',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h3>Target audiences</h3>\n<div class=\"display-groups-scroll-area\">\n    <ul></ul>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
