// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/MultiOfferPreviewItem',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/MultiOfferPreviewItem', 'backbone', 'bootstrap-dialog'],
    function (App, $, _, template, Backbone, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'li',

            className: 'multi-offer-item',

            ui: {
                progressBar: '.uploading-progress',
                progressBarRadial: '.uploading-progress .radial-progress',
            },

            events: {
                'click [data-action="delete"]': 'deleteItem'
            },

            modelEvents: {
                'change:uploadingProgress': 'updateProgressBar'
            },

            onRender: function () {
                if (this.model.get('_uid')) {
                    this.model.set('uploadingProgress', 0);
                } else {
                    this.ui.progressBar.hide();
                    this.$el.attr('data-id', this.model.get('uri'));
                }
            },

            updateProgressBar: function () {
                var me = this;
                if (!me.isDestroyed) {
                    if (me.ui.progressBarRadial) {
                        me.ui.progressBarRadial.attr('data-progress', me.model.get('uploadingProgress'));
                    }
                }
            },

            deleteItem: function () {
                event && event.preventDefault();
                var me = this;

                BootstrapDialog.confirm("Do you really want to remove this item?", function (result) {
                    if (result) {
                        me.$el.addClass('processing');
                        App.vent.trigger('App.layout.multiOffer.removeItem', me.model);
                    }
                });
            }


        });
    });
