// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/DepartmentUserProfiles',['App',
        'backbone',
        'views/Retailers/DepartmentUserProfile'
        ],
    function (
        App,
        Backbone,
        DepartmentUserProfile
    ) {

        'use strict';
        return Backbone.Marionette.CollectionView.extend({

            childView: DepartmentUserProfile,

            className: 'row',

            events: {

            },

            onRender: function () {
                var me = this;

            },

            initialize: function (options) {
                var me = this;
                me.parent = options && options.parent;
                me.retailerUserUri = options && options.retailerUserUri;
                me.department = options && options.department;
                me.collection = App.currentUser.getAdminDisplayGroupsFull();

                me.collection = App.currentUser.getProfiles();

                me.childViewOptions = {
                    parent: me
                };

                me.onUpdateProfiles = _.debounce(_.bind(me._saveUserProfiles, me), 1000);
            },

            _saveUserProfiles: function () {
                var me = this,
                    tempArr = [];

                this.parent.triggerMethod('loading:mask:toggle');

                _.each(this.$el.find('[name="profiles[]"]:checked'), function (input) {
                    tempArr.push($(input).val());
                });

                console.log(tempArr);
                this.parent.user.set('profiles', tempArr).save(null).done(function() {
                    me.parent.triggerMethod('approver:groups:toggle');
                });
            }

        });
    });
