'use strict';

define('views/InternalReports/InternalReportsLayout',[
    'App',
    'backbone',
    'backbone.marionette',
    'hbs!templates/views/InternalReports/InternalReportsLayout',

    'views/InternalReports/UserLogins',
    'views/InternalReports/UserRegistrations',
    'views/InternalReports/SessionLengths',
    'views/InternalReports/CreditsBurn',
    'views/InternalReports/CreditsPurchased',
    'views/InternalReports/UserRegistered',
    'views/InternalReports/LoggedUsers',

    'moment'
], function (App,
             Backbone,
             Marionette,
             template,
             //
             UserLogins,
             UserRegistrations,
             SessionLengths,
             CreditsBurn,
             CreditsPurchased,
             UserRegistered,
             LoggedUsers,
             //
             moment) {
    return Backbone.Marionette.LayoutView.extend({
        template: template,

        tagName: 'section',
        className: 'wrapper',

        regions: {
            userRegistrations: '#userRegistrations',
            userLogins: '#userLogins',
            sessionLengths: '#sessionLengths',
            creditsBurn: '#creditsBurn',
            creditsPurchased: '#creditsPurchased',
            registrationsList: '#registrationsList',
            loggedUsers: '#loggedUsers'
        },

        ui: {
            dateRangePicker: '#dateRangeInternalReports'
        },

        initialize: function () {
            this.from = moment().subtract(1, 'w');
            this.to = moment();
        },

        onRender: function () {
            this.userRegistrations.show(new UserRegistrations());
            this.userLogins.show(new UserLogins());
            this.sessionLengths.show(new SessionLengths());
            this.creditsBurn.show(new CreditsBurn());
            this.creditsPurchased.show(new CreditsPurchased());
            this.registrationsList.show(new UserRegistered());
            this.loggedUsers.show(new LoggedUsers());

            this._setupDateRangePicker();
        },

        _setupDateRangePicker: function () {
            var me = this;

            this._updateDateRange(this.from, this.to);
            this.ui.dateRangePicker.daterangepicker({
                ranges: {
                    'Previous quarter': [
                        moment().quarter(moment().quarter() - 1).startOf('quarter'),
                        moment().startOf('quarter')
                    ],
                    'Previous month': [moment().subtract(1, 'month').startOf('day'), moment().endOf('day')],
                    'Previous week': [moment().subtract(7, 'day').startOf('day').endOf('day'), moment()],
                    'Today': [moment().startOf('day'), moment().add(1, 'days').startOf('day')]
                },
                locale: {
                    "format": "YYYY-MM-DD"
                },
                timePicker: true,
                timePicker24Hour: true,
                startDate: this.from,
                endDate: this.to
            });

            this.ui.dateRangePicker.on('apply.daterangepicker', function(ev, picker) {
                me._updateDateRange(picker.startDate, picker.endDate);
            });
        },

        _updateDateRange: function (start, end) {
            this.from = moment(start);
            this.to = moment(end);
            this.ui.dateRangePicker.find('span')
                .html(this.from.format("YYYY-MM-DD") + ' - ' + this.to.format("YYYY-MM-DD"));
            App.vent.trigger('App.internalReports.dateRangeChanged', {from: this.from, to: this.to});
        }
    });
});

