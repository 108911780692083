// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/LoopManagement/DisplayGroupsSelector',['App',
        'backbone',
        'hbs!templates/views/LoopManagement/DisplayGroupsSelector',
        'views/LoopManagement/DisplayGroupsSelectorItem',
        'views/LoopManagement/Loop'
        ],
    function (
        App,
        Backbone,
        template,
        DisplayGroupsSelectorItem,
        Loop
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            className: 'loop-management',

            childView: DisplayGroupsSelectorItem,

            childViewContainer: '.display-groups',

            collectionEvents: {
                //'reset' : '_mergeWithTargets'
            },

            searchBar: {
                fields: ['display_name'],
                sortBy: {
                    'display_name': 'Display Group Name',
                    'created': 'Creation Time'
                }
            },

            ui: {
                filterSelect: '#display-group-filter'
            },

            events: {
                'change @ui.filterSelect' : '_fireCallback'
            },

            filter: function (child, index, collection) {
                return (App.currentUser.hasRole(App.os.roles.approver) && child.isApprover()) || App.currentCompany.isPrivate();
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    onRender: function () {
                        $('.load-next').hide();
                    },

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                var me = this;

                me.listenTo(App.vent, 'App.layout.loopManagement.showLoop', _.bind(this._showLoop, this));
                me.collection = App.currentUser.getDisplayGroupsApprover({fetch: false, searchUri: '/data/search/display-groups/approver'});
            },

            onRender: function () {
                var me = this;
                if (!me.collection.beenQueried()) {
                    $.when(me.collection.query()).then(function () {
                        me.$el.find('.no-items').html('<i class="fa fa-repeat"></i> No display groups');
                    });
                } else {
                    if (me.collection._moreResults) { me._moreItems(); }
                    if (!_.size(me.collection)) { me.$el.find('.no-items').html('<i class="fa fa-repeat"></i> No display groups'); }
                }
            },

            _showLoop: function (displayGroup) {
                App.appRouter.navigate('loop-management' + displayGroup.get('uri').replace("/data", ""));
                App.mainRegion.currentView.layoutMain.show(new Loop({model: displayGroup}));
            },

            _fireCallback: function (view) {
                var me = this;
                console.log(view.model.get('uri'));
                //App.vent.trigger("App.layout.loopManagement.showLoop", me.ui.filterSelect.val());
            }

        });
    });
