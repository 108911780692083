
/* START_TEMPLATE */
define('hbs!templates/views/ApplicationLayout/ApplicationHeaderMulti',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n        <img id=\"mainLogo\" src=\"";
  if (helper = helpers.company_logo) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.company_logo); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n        ";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n        <span class=\"logo-placeholder\">Flow City</span>\n        ";
  }

  buffer += "<div class=\"sidebar-toggle-box\">\n    <!--<div class=\"fa fa-bars tooltips\" data-permission=\"topm_hamb_btn\" data-placement=\"right\" data-original-title=\"Toggle Navigation\" style=\"-->\n    <!--cursor: pointer;-->\n    <!--display: inline-block;-->\n    <!--font-size: 20px;-->\n    <!--padding: 21px 15px 19px 29px;-->\n<!--\"   ></div>-->\n\n    <a href=\"#dashboard\" data-permission=\"topm_flow_btn\" class=\"logo\">\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.company_logo), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </a>\n</div>\n<div class=\"title-row\" style=\"float: left;\" data-permission=\"topm_brea_txt\"><span>Dashboard</span></div>\n\n<!--<div class=\"top-menu\">-->\n    <!--<ul class=\"nav pull-right top-menu\">-->\n        <!--<li style=\"float: left;\">-->\n            <!--<a class=\"header-btn\" href=\"/#profile/location-settings\">-->\n                <!--<img src=\"/static/images/icons/Location_big.svg\" style=\"height: 35%;\" title=\"Location settings\"/>-->\n            <!--</a>-->\n        <!--</li>-->\n        <!--<li style=\"float: left;\">-->\n            <!--<a class=\"header-btn\" href=\"/#profile/payments\">-->\n                <!--<img src=\"/static/images/icons/dashboard-09.svg\" title=\"Payments\"/>-->\n            <!--</a>-->\n        <!--</li>-->\n        <!--<li style=\"float: left;\">-->\n            <!--<a class=\"header-btn\" href=\"/#profile/user-details\">-->\n                <!--<img src=\"/static/images/icons/dashboard-10.svg\" title=\"Profile settings\"/>-->\n            <!--</a>-->\n        <!--</li>-->\n        <!--<li style=\"float: left;\" data-permission=\"topm_logo_btn\">-->\n            <!--<a class=\"header-btn logout logout-btn\" href=\"#\">-->\n                <!--<img src=\"/static/images/icons/dashboard-11.svg\" title=\"Logout\"/>-->\n            <!--</a>-->\n        <!--</li>-->\n    <!--</ul>-->\n<!--</div>-->\n\n<div class=\"user-info\" id=\"top-user-area\"></div>\n<div class=\"credits-info\" data-permission=\"topm_cred_btn\" id=\"top-credits-area\"></div>\n<div class=\"department-selector\" id=\"top-department-selector-area\" style=\"display: none;\"></div>\n\n<div class=\"powered-by\">\n    <small>Powered by</small>\n    Flow City\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
