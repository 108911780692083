// -*- coding: utf-8 -*-

/*jslint nomen: true*/
define('models/Location',["App",
        "backbone",
        "collections/Forecasts",
        "models/Forecast"
    ],
    function (App,
              Backbone,
              Forecasts,
              Forecast) {

        'use strict';
        return Backbone.Model.extend({
            uriStencil: 'shopping-malls',

            getForecasts: function (options) {
                return this.getSubCollection(Forecasts, 'Forecasts', options);
            },

            getLatestForecast: function () {
                var me = this;
                if (_.isUndefined(this.latestForecast)) {
                    me.latestForecast = new Forecast();
                    me.latestForecast.url = me.get('uris').Forecasts;

                    me.latestForecast._deferredFetch = $.Deferred();
                    me.latestForecast.promise = me.latestForecast._deferredFetch.promise();

                    $.when(me.latestForecast.fetch({data: {latest: 1}})).then(function () {
                        me.latestForecast._deferredFetch.resolve();
                    });
                }
                return this.latestForecast;
            }

        });
    }
    );
