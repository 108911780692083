// -*- coding: utf-8 -*-


define('views/ScreenPlanning-mockup',['App', 'backbone', 'jquery', 'hbs!templates/views/ScreenPlanning-mockup'],
    function (App, Backbone, $, template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'section',
            className: 'wrapper',

            onRender: function () {

            }
        });
    });
