
/* START_TEMPLATE */
define('hbs!templates/views/Reports/SectionProduct',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4>Product:</h4>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"product\" id=\"productFilter1\" value=\"none\">\n    <label for=\"productFilter1\">None</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"product\" id=\"productFilter2\" value=\"all\" checked>\n    <label for=\"productFilter2\">All products</label>\n</div>\n<div class=\"radio radio-info\">\n    <input type=\"radio\" name=\"product\" id=\"productFilter3\" value=\"selected\">\n    <label for=\"productFilter3\">Selected:</label>\n    <div class=\"local-dropdown\" data-id=\"filter-by-product-group\">\n        <div class=\"report-product-groups-list\"></div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
