// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferScheduleAIM',['App', 'jquery', 'underscore', 'backbone', 'views/Offers/OfferScheduleBase', 'hbs!templates/views/Offers/OfferScheduleAIM', 'jquery-knob'],
    function (App, $, _, Backbone, _OfferScheduleBase, template) {

        'use strict';
        var OfferScheduleBase = _OfferScheduleBase.prototype;
        return _OfferScheduleBase.extend({

            className: 'tab-pane',

            template: template,

            modelEvents: _.extend({}, OfferScheduleBase.modelEvents, {
                'change': (OfferScheduleBase.modelEvents.change || '') + ' _updateActiveState'
            }),

            events: {
                'click .btn-gender' : 'btnGenderHandler',
                'click .btn-age' : 'btnAgeHandler'
            },

            onRender: function () {
                var me = this,
                    result = OfferScheduleBase.onRender.apply(me, arguments);

                // set slide bar after certain delay
                setTimeout(function () {

                    me.setSlider('gender');
                    me.setSlider('age');

                    me.setInitialButtonsStates();

                }, 100);

                // Set initial values from model
                me.$el.find('[data-control="gender"]#genderRatio').attr('data-value', me.model.get('gender')).val(me.model.get('gender_range')[0]);
                me.$el.find('[data-control="age"]#ageRatio').attr('data-value', me.model.get('age')).val(me.model.get('age_range')[0]);

                return result;

            },

            setSlider: function (feature) {
                var me = this,
                    value = $('#'+feature+'Ratio').val();

                $( "#slider-"+feature ).slider({
                    range: "min",
                    min: 0,
                    max: 100,
                    step: 1,
                    value: value,
                    slide: function( event, ui ) {
                        var delay = function() {
                            $('.value-'+feature).html( ui.value + '%' );
                        };

                        // wait for the ui.handle to set its position
                        setTimeout(delay, 5);
                    },
                    stop: function( event, ui ) {
                        me.actualiseFeature( ui, me, feature );
                    }
                });

                $( "#slider-"+feature+" a" ).append('<div class="value-'+feature+'">'+value+'%</div>');

                var label = '<label style="left: 0;">0</label>';
                for(var i=20; i<=100; i=i+20) {
                    label += '<label style="left: ' + i + '%;"><span>';
                    if(i===100)
                        label += i + '%</span></label>';
                    else
                        label += i + '</span></label>';
                }

                $( "#slider-"+feature ).append('<div style="width: 87%">'+label+'</div>');

            },

            setInitialButtonsStates: function () {
                var me = this;

                switch(me.model.get('gender')){
                    case 'male':
                        $('#maleLabel').addClass('active');
                        break;
                    case 'female':
                        $('#femaleLabel').addClass('active');
                        break;
                    default:
                        $('#offLabel').addClass('active');
                        $('#slider-gender').slider({ disabled: true });
                }

                switch(me.model.get('age')){
                    case 'child':
                        $('#childLabel').addClass('active');
                        break;
                    case 'young_adult':
                        $('#youngAdultLabel').addClass('active');
                        break;
                    case 'older_adult':
                        $('#adultLabel').addClass('active');
                        break;
                    case 'senior':
                        $('#seniorLabel').addClass('active');
                        break;
                    default:
                        $('#offAgeLabel').addClass('active');
                        $('#slider-age').slider({ disabled: true });
                }

            },

            btnGenderHandler: function (event) {    this.btnHandler(event.currentTarget, 'gender'); },
            btnAgeHandler: function (event) {   this.btnHandler(event.currentTarget, 'age');    },

            btnHandler: function (handler, feature) {
                var me = this,
                    handlerValue = $(handler).find('input').attr('value');

                $('#'+feature+'Ratio').attr('data-value', handlerValue)
                    .data('value', handlerValue);
                me.model.set(feature, handlerValue);
                var ui = {
                    value: $('#slider-'+feature).slider("option", "value"),
                    handle: $('#slider-'+feature+' a')
                };
                me.actualiseFeature(ui, me, feature);
                if(handlerValue === '')
                    $('#slider-'+feature).slider({ disabled: true });
                else
                    $('#slider-'+feature).slider({ disabled: false });
            },

            actualiseFeature: function (ui, me, feature) {

                var value = Math.round(ui.value),
                    featureRatio = $('#'+feature+'Ratio');

                me.model.set(featureRatio.data('control'), value !== 0 ? featureRatio.data('value') : '');
                me.model.set(featureRatio.data('control') + '_range', [value, 100]);
            },

            _updateActiveState: function () {
                var me = this;
                if (_.isEmpty(me.model.get('gender')) && _.isEmpty(me.model.get('age'))) {
                    me.model.set('active', false);
                } else {
                    me.model.set('active', true);
                }
                App.vent.trigger("App.currentOffer.schedule.updateActiveSchedule", me.model.get('type'), me.model.get('active'));
                App.vent.trigger("App.currentOffer.scheduleSummary.update");
            }

        });
    });
