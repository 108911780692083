// -*- coding: utf-8 -*-

/*global define,sessionData*/
define('views/Notifications/PublicTransportDisruptionsHistory',['App',
        'backbone',
        'hbs!templates/views/Notifications/PublicTransportDisruptionsHistory',
        'collections/PublicTransportDisruptionsHistory',
        'views/Notifications/PublicTransportDisruption',
        'moment',
        'waypoints'
        ],
    function (
        App,
        Backbone,
        template,
        PublicTransportDisruptionsHistory,
        PublicTransportDisruptionView,
        moment
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,
            tagName: 'section',
            className: 'wrapper',

            childView: PublicTransportDisruptionView,

            childViewContainer: '.notifications',

            ui: {
                loadMore: 'a#notifications-load-more'
            },

            events: {
                'click @ui.loadMore': '_onNextPage'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({

                    getTemplate: function () {
                        var templateHtml = '<div class="no-notifications">Loading...</div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            initialize: function () {
                var me = this;

                me.collection = App.currentUser.getPublicTransportDisruptionsHistory();

                $.when(this.collection.promise).then(function () {
                    me.rootCollection = me.collection;
                    me.collection = me.rootCollection.fullCollection;

                    //me.listenTo(me.collection, 'add', _.bind(me._resort, me));

                    me.collection.comparator = function (model) {
                        return -(moment(model.get('created')).format('X'));
                    };

                    me.listenTo(App.currentUser.getPublicTransportDisruptions(), "add remove", function (model, collection, options) {
                        if (options.fromChannel) {
                            var now = _.now();
                            model.attributes.uri += now;
                            model.attributes.id = now;
                            me.collection.add(model.attributes);
                        }
                    });

                });

                me.childViewOptions = {
                    parent: me
                };

                me._isLoading = false;

            },

            onRender: function () {
                var me = this;

                $.when(this.collection.promise).then(function () {

                    if (!me.isDestroyed) {
                        if (me.collection.length < 10) {
                            if (me.ui.loadMore) {
                                me.ui.loadMore.hide();
                            }
                        } else {
                            me.ui.loadMore.show();
                        }
                    }

                    me._addLoadNextPageWaypoint();
                });

            },

            _addLoadNextPageWaypoint: function () {
                var me = this,
                    wp;
                if (me.ui.loadMore.length > 0) {
                    wp = new Waypoint({

                        element: me.$el.find('#notifications-load-more'),
                        handler: function (direction) {
                            if (direction === 'down') {
                                if (!me.isDestroyed) {
                                    me._onNextPage();
                                }
                            }
                        },
                        offset: '100%'
                    });
                }
            },

            _onNextPage: function (evt) {
                evt && evt.stopPropagation();
                evt && evt.preventDefault();
                var me = this;

                if (!me._isLoading) {

                    me.ui.loadMore.addClass('loading-more');
                    me._isLoading = true;

                    me.$el.find('.no-notifications').html("Loading...");

                    me.rootCollection.getNextPage()
                        .done(function (data) {
                            console.log('### Notifications - returned records: %s', data.length);
                            me.ui.loadMore.removeClass('loading-more');
                            if (data.length < 10) {
                                me.ui.loadMore.hide();

                                if (_.size(me.rootCollection) === 0) {
                                    me.$el.find('.no-notifications').html('<i class="fa fa-history"></i> Sorry, no historical data');
                                }

                            } else {
                                me._addLoadNextPageWaypoint();
                            }
                            me._isLoading = false;

                        })
                        .fail(function () { me._isLoading = false; });
                }

            },

            _nextPageLoaded: function (data) {
                console.log(data);
            }

        });
    });
