// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Offers/OfferSchedulesSummary',['App', 'jquery', 'underscore', 'hbs!templates/views/Offers/OfferSchedulesSummary', 'backbone', 'moment'],
    function (App, $, _, template, Backbone, moment) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            initialize: function () {
                var me = this;
                me.model = new Backbone.Model();
                me.listenTo(App.vent, 'App.currentOffer.scheduleSummary.update', _.bind(me._updateSummaryModel, me));
            },

            onRender: function () {
                var campaignAudienceName = $('.create-campaign-audience').find('.selected').attr('id');
                var campaignFormatsName = $('.create-campaign-formats').
                    find('.selected').
                    find('.cell-like-text').
                    find('h4').html();

                setTimeout(function () {

                    var budget = $("#range").html();
                    var rangeTime = $("#range-time").html();

                    if(campaignAudienceName) {
                        campaignAudienceName = campaignAudienceName.substring(0, campaignAudienceName.indexOf('BelowButton'));
                        $("#selected-audience").html(campaignAudienceName);
                    }
                    if(campaignFormatsName) {
                        $("#selected-screen-type").html(campaignFormatsName);
                    }
                    if(budget)
                        $("#budget-summary").html(budget);
                    if(rangeTime)
                        $("#estimated-time-summary").html(rangeTime);

                }, 500);
            },

            _updateSummaryModel: function () {
                this.model.clear({silent: true}).set(App.currentOffer.getOfferSchedules().getSummaryModelAttrs());
                this.render();
            }

        });
    });
