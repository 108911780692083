
/* START_TEMPLATE */
define('hbs!templates/views/Payments/PrivateScreenKitPurchase',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-header\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">Buy additional In-store Screen Kit</div>\n        </div>\n    </div>\n\n    <div class=\"modal-body\">\n        <p>How many <strong>In-store Screen Kit</strong> you want to order?</p>\n\n        <div class=\"btn-group btn-group-justified private-screen-kit-calc\" data-toggle=\"buttons\">\n            <div class=\"desc\">&pound;"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.privateScreenFee)),stack1 == null || stack1 === false ? stack1 : stack1.GBP)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "&nbsp;&nbsp;x&nbsp;&nbsp;</div>\n            <div style=\"width: 150px;\">\n                <div class=\"input-group input-group-lg\">\n                    <input type=\"text\" value=\"1\" id=\"quantity\" class=\"form-control input-lg text-center\">\n                </div>\n            </div>\n            <div class=\"desc\">&nbsp;&nbsp;=&nbsp;&nbsp;<strong>&pound;<span class=\"final-price\"></span></strong></div>\n        </div>\n\n\n    </div>\n\n    <div class=\"modal-footer\">\n        <p>*) All prices exclude VAT</p>\n        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Cancel", options) : helperMissing.call(depth0, "trans", "Cancel", options)))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary buy-credits-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">Buy</span></button>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
