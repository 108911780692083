'use strict';

define('views/InternalReports/CreditsBurn',[
        'App',
        'backbone',
        'underscore',
        'backbone.marionette',
        'jquery',
        'moment',
        'hbs!templates/views/InternalReports/CreditsBurn'
    ],
    function (App,
              Backbone,
              _,
              Marionette,
              $,
              moment,
              template) {

        return Backbone.Marionette.ItemView.extend({

            template: template,
            templateHelpers: function () {
                return {
                    count: this.creditsCount,
                    events: this.eventsCount
                };
            },

            className: 'white-panel widget ar-16-4',

            ui: {
                spinner: ".internal-reports-loading-mask"
            },

            initialize: function () {
                this.listenTo(App.vent, 'App.internalReports.dateRangeChanged', _.bind(this.loadStats, this));
            },

            loadStats: function (data) {
                var me = this;
                var from = data.from;
                var to = data.to;

                me.ui.spinner.addClass('spinning');
                var timeRange = from.format('YYYY-MM-DD') + '/' + to.format('YYYY-MM-DD');
                $.ajax({
                    url: creditsMonitorServiceUrl + '/query/burn/' + timeRange,
                    method: "GET",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                    }
                }).done(function (data) {
                    me.creditsCount = data.creditsCount;
                    me.eventsCount = data.eventsCount;
                    console.log("Got credits burn count: ", data);
                    me.render();
                });
            }
        });
    });

