// -*- coding: utf-8 -*-


/*global define*/
define('collections/Attachments',["backbone", "models/Attachment"],
    function (Backbone, Attachment) {

        'use strict';
        var Attachments = Backbone.Collection.extend({

            model: Attachment,

            sortAttr: 'modified',
            descending: true,

            sortByAttr: function (attr) {
                if (attr === this.sortAttr) {
                    this.descending = !this.descending;
                } else {
                    this.sortAttr = attr;
                }
                this.sort();
            },

            comparator: function (attachment1, attachment2) {
                var attr = 0;
                if (attachment1.get(this.sortAttr) > attachment2.get(this.sortAttr)) { attr = -1; }
                if (attachment2.get(this.sortAttr) > attachment1.get(this.sortAttr)) { attr =  1; }
                return (this.descending ? -attr : attr);
            }
        });

        return Attachments;
    });
