// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/DisplayGroupsSearchable',["backbone", "models/DisplayGroup"],
    function (Backbone, DisplayGroup) {

        'use strict';
        var DisplayGroupsSearchable = Backbone.SearchableCollection.extend({
            searchUri: '/data/search/display-groups/approver',
            model: DisplayGroup
        });

        return DisplayGroupsSearchable;
    });
