// -*- coding: utf-8 -*-


/*global define*/
define('collections/Offers',["backbone", "models/Offer"],
    function (Backbone, Offer) {

        'use strict';

        var Offers = Backbone.Collection.extend({
            model: Offer
        });

        return Offers;
    });
