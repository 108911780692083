// -*- coding: utf-8 -*-


/*global define*/
define('collections/Forecasts',["backbone", "models/Forecast"],
    function (Backbone, Forecast) {

        'use strict';
        var Forecasts = Backbone.Collection.extend({
            model: Forecast
        });

        return Forecasts;
    });
