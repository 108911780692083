
/* START_TEMPLATE */
define('hbs!templates/views/SignIn',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"login-page\">\n    <div class=\"container\">\n\n        <form class=\"form-login\" id=\"login-form\" method=\"post\" action=\"/login\" target=\"_parent\">\n                <div class=\"login-wrap\">\n\n                    <p class=\"see-again-header\">Nice to see you again!</p>\n\n                    <p class=\"login-error regular-login-error\">Wrong email or password</p>\n                    <p class=\"login-error fb-login-error\">Wrong Facebook account</p>\n\n                    <input type=\"text\" name=\"login-email\" id=\"login-email\" class=\"form-control validate[required,custom[email]]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Your e-mail", options) : helperMissing.call(depth0, "trans", "Your e-mail", options)))
    + "\" autofocus>\n                    <br>\n                    <input type=\"password\" name=\"login-password\" id=\"login-password\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Password", options) : helperMissing.call(depth0, "trans", "Password", options)))
    + " \">\n                    <div class=\"row no-margin\">\n                        <span>\n                            <a href=\"#forgot-password\">Forgot Password?</a>\n                        </span>\n                        <span id=\"show-password\" style=\"float: right;\">\n                            Show Password\n                        </span>\n                    </div>\n                    <br>\n                    <button id=\"std-login-btn\" class=\"btn btn-theme btn-block\" style=\"color: black;\" type=\"submit\"> "
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "LOGIN", options) : helperMissing.call(depth0, "trans", "LOGIN", options)))
    + " </button>\n                    <div class=\"row\">\n                        <div class=\"col-xs-5\"><hr/></div>\n                        <div class=\"col-xs-2\" style=\"line-height: 38px; text-align: center; color: white;\">\n                            or\n                        </div>\n                        <div class=\"col-xs-5\"><hr/></div>\n                    </div>\n                    <div id=\"fb-login-btn\" class=\"btn btn-facebook btn-block btn-goto-fb-login\" style=\"position: relative;\">\n                        <i class=\"fa fa-facebook\"></i> "
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "LOGIN WITH FACEBOOK", options) : helperMissing.call(depth0, "trans", "LOGIN WITH FACEBOOK", options)))
    + "\n                    </div>\n                    <span class=\"text-center\" style=\"padding: 15px 15px 0 15px; display: block \">\n                        New to Flow.City? <a href=\"#signup\"><b>Sign up here</b></a>\n                    </span>\n                </div>\n            <input type=\"hidden\" name=\"to-url\" value=\"/\" />\n            <input type=\"hidden\" name=\"rememberme\" value=\"1\"/>\n            <input type=\"hidden\" name=\"sso\" value=\"none\" />\n            <input type=\"hidden\" name=\"template\" value=\"\" />\n        </form>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
