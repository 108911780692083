// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Reports/SectionFormat',['App', 'jquery', 'underscore', 'hbs!templates/views/Reports/SectionFormat', 'backbone', 'magicsuggest'],
    function (App, $, _, template, Backbone) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            ui: {
                displayGroupsList: '.report-format-groups-list',
                displayGroupsFilterOpt: '[name="displayGroupsFilter"]',
                selectedFormat: '#selected-format'
            },

            events: {
                'change @ui.displayGroupsFilterOpt' : '_onDisplayGroupsFilterOptChange',
                'click @ui.selectedFormat' : 'clickSelected',
                'change @ui.selectedFormat' : '_onDisplayGroupsListChange'
            },

            initialize: function (options) {
                this.parent = options && options.parent;
            },

            clickSelected: function () {
                $('#formatFilter3').click();
            },

            _onDisplayGroupsListChange: function (event) {
                var param = $(event.currentTarget).parents('.radio').children('input').attr('name');;
                this.parent.reportParams.parameters[param] = $(event.currentTarget).val();
            },

            _onDisplayGroupsFilterOptChange: function (event) {
                var me = this,
                    target = event.currentTarget;
                if ($(target).val() === 'selected') {
                    me.$el.find('[data-id="filter-by-format-group"]').show();
                } else {
                    me.displayGroupsList.clear();
                    me.$el.find('[data-id="filter-by-format-group"]').hide();
                }
            }

        });
    });
