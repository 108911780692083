
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentDetails',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n    <form class=\"form-horizontal style-form mt\">\n\n        <div class=\"form-group retailer-details-line\">\n            <label class=\"control-label\">Department name</label>\n            <div>\n                <span class=\"name\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n            </div>\n        </div>\n\n    </form>\n\n    <form class=\"form-horizontal style-form mt\">\n\n        <div class=\"form-group retailer-details-line\">\n            <label class=\"control-label\">Department location</label>\n            <div id=\"locationWidget\"></div>\n            <div id=\"newLocationButton\"></div>\n        </div>\n\n    </form>\n\n    <div class=\"buttons\">\n        <button class=\"btn btn-danger delete-btn\"><i class=\"fa fa-trash-o\"></i> Delete department</button>\n    </div>\n\n    ";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n    <div class=\"text-center\">\n        <h4>Select Retailer and then Department from the list</h4>\n    </div>\n    ";
  }

  buffer += "<div class=\"form-panel\">\n\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.name), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
