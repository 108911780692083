// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/CreateCampaign',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment', 'ladda-bootstrap',
         'hbs!templates/views/Dashboard/CreateCampaign',
         'bootstrap-dialog',
         'skycons'
         ],
    function (App, Backbone, _, Marionette, $, moment, Ladda,
              template,
              BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({

            template: template,

            tagName: 'section',
            className: 'create-campaign widget ar-1-1',
            ui: {
                panel2:'#accordion .panel-default:eq(2) .panel-heading a',
                panel1i: '#accordion .panel-default:eq(1) .panel-heading a i',
                panel1: '#accordion .panel-default:eq(1) .panel-heading a',
                panel0: '#accordion .panel-default:eq(0) .panel-heading a i',
                artworkButton: '#artwork-next-button',
                audienceButton: '#audience-next-button',
                sidebar2: '.sidebar-info:eq(2)',
                widgetContent: '.content',
                taxitop: "#TAXI-TOP-CC",
                d6cinema:"#D6-CINEMA-CC",
                twentyfive1:"#25-1-CC",
                twentyfive2:"#25-2-CC",
                letusdesign:"#letusdesign",
                count:'.count',
                progressBar: '#progressbar',
                progressBarRadial: '#progressbar .radial-progress',
                addedImg: '#added_object img'
            },
            events: {
                'click .formats-buttons': 'selectFormat',
                'change #credit_slider': 'showCreditChart',
                'click .artwork-screen-sizes .artwork_option': 'selectScreenSize',
                'click #accordion .panel-heading a' : 'nextSection',
                'click #cancel-button':'cancelAction',
                'click #create-campaign-button':'saveCampaign',
                'click #advanced-campaign-button':'goToAdvancedScreen'
            },

            initialize: function (options) {
                
    
            },
            saveCampaign: function(e){
                var me = this;
                e.preventDefault();

                if( parseInt($('#range').html()) < 1 ) {
                    BootstrapDialog.alert("Set the budget.");
                    return;
                }

                App.currentOffer.getOfferSchedules().create({
                    "type":"DbOfferScheduleTime",
                    "schedule_start": moment().format("YYYY-MM-DDT00:00"),
                    "schedule_stop": moment().endOf('isoWeek').format("YYYY-MM-DDT23:59"),
                    "weekdays": [0,1,2,3,4,5,6],
                    "time_start": null,
                    "time_stop": null,
                    "all_day":true,
                    "active": true
                });

                $('#create-campaign-button i.fa-spin').show();
                window.newOfferCreated.attributes.budget = String(window.budget);
                window.newOfferCreated.attributes.intensity = String(window.intensity);
                window.newOfferCreated.attributes.audience = String(window.selectedAudience);
                window.newOfferCreated.attributes.offer_variant = window.variant;
                var uploadUrl = window.newOfferCreated.attributes.uri;
                var uploadMethod = 'PUT';
                    $.ajax({
                        url: App.enrichApiUrl(uploadUrl),
                        type: uploadMethod,
                        dataType: "json",
                        data: JSON.stringify(window.newOfferCreated.attributes)
                    }).done(function (data) {
                        $('#create-campaign-button i.fa-spin').hide();  

                        $.ajax({
                            url: App.enrichApiUrl(window.newOfferCreated.attributes.uris.OfferTargets),
                            type: 'POST',
                            dataType: "json",
                            data: JSON.stringify({
                                status:'submitted',
                                target:'/data/display-groups/'+window.displayGroupId
                            })
                        }).done(function (data) {
                            window.location = '/#dashboard/campaign-created'; 
                        });
                    });
            },
            goToAdvancedScreen: function(e){
                var me = this;
                e.preventDefault();
                $('#advanced-campaign-button i.fa-spin').show();
                window.newOfferCreated.attributes.budget = String(window.budget);
                window.newOfferCreated.attributes.intensity = String(window.intensity);
                window.newOfferCreated.attributes.offer_variant = window.variant;
                window.newOfferCreated.attributes.audience = String(window.selectedAudience);
                var uploadUrl = window.newOfferCreated.attributes.uri;
                var uploadMethod = 'PUT';
                    $.ajax({
                        url: App.enrichApiUrl(uploadUrl),
                        type: uploadMethod,
                        dataType: "json",
                        data: JSON.stringify(window.newOfferCreated.attributes)
                    }).done(function (data) {
                        $('#create-campaign-button i.fa-spin').hide();  
                         window.location =  window.advancedlink;
                    });

            },
            cancelAction: function(){
                    $('.added-inner-text').hide();
                    $('#all-formats .audiences-blocks').removeClass('selected unselected'); 
    

            },
            setCustomDate: function(){

            },

            nextSection: function(event){
                var me = this;
                var href = $(event.currentTarget).attr('href');
                if(href==''){
                    event.preventDefault();
                } else {
                    if(href=='#artwork'){
                        me.currentSection=1;
                    }
                    if(href=='#audienceLayer'){
                        me.currentSection=2;
                    }
                    if(href=='#budget'){
                        me.currentSection=3;
                        this.showCreditChart();
                    }
                }

             },

            selectScreenSize: function(event){
                var me = this;
                $('.artwork-screen-sizes .artwork_option').removeClass('faded-in');
                $('#'+event.currentTarget.id).closest('.artwork_option').addClass('faded-in');
                me.flagScreenSize = true; 
                me.finishStep1();
            },

            finishStep1: function(){
                var me = this;
                if(me.flagArtwork==true && me.flagScreenSize==true){
                    $(this.ui.panel1).attr('href',$(this.ui.panel1).attr('hreftarget'));
                    $(this.ui.panel1).click();
                    $(this.ui.panel0).css('opacity',1);
                } else {
                    if(me.flagArtwork==true ){
                        $('#add-artwork-popup').hide();
                    } else {
                        $('#add-artwork-popup').show();
                    }
                }
            },

            passLink:function() {
                    var link = prompt("Paste your link here:");
                    if (link != null) {
                        $("#added_object").html('<a href="'+link+'" target="_blank">'+link+'</a>');
                        artworkToAudience();
                    }
            },
                

            showCreditChart: function(){ 
                $('#budget-credits').html('£'+App.currentCompany.get('credits'));
                var creditsArr = [74,56,34,23,11,0];

                if(this.currentSection<3) return;
                $(this.ui.sidebar2).removeClass('hide').click();
                   
                        var myChart = echarts.init(document.getElementById('credits-chart'));

                        var option = {
                                title: {
                                    text: 'Credit balance',
                                    left: 'center'
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: '{a} <br/>{b} : {c}'
                                },
                                legend: {
                                    left: 'left',
                                    data: ['In days']
                                },
                                xAxis: {
                                    type: 'category',
                                    name: 'x',
                                    splitLine: {show: false},
                                    data: ['1', '3', '5', '7', '9', '11']
                                },
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                yAxis: {
                                    type: 'log',
                                    name: 'y'
                                },
                                series: [
                                    {
                                        name: '£',
                                        type: 'line',
                                        data: creditsArr
                                    }
                                ]
                            };
                        
                        myChart.setOption(option);
            },

            onRender: function () {
                var me = this;

                this.srcReplacer();
            },

            onShow: function () {
                var me = this;
            },

            onDestroy: function () {
                var me = this;
            
            },

            srcReplacer: function(){
                var me = this;

                setTimeout(function () {
                    if( $('#ng-iframe iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=new_campaign';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }
        });
    });

