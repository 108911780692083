
/* START_TEMPLATE */
define('hbs!templates/views/ApplicationLayout/ApplicationHeader',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"sidebar-toggle-box\">\n  <div class=\"sidebar-switch\" data-permission=\"topm_hamb_btn\" data-placement=\"right\" data-original-title=\"Toggle Navigation\" style=\"\n    position: absolute;\n    bottom: 0;\n    right: 10px;\n    font-size: 25px;\n    cursor: pointer;\n    color: white;\n  \">\n    <i class=\"fa fa-caret-down\" style=\"\n      margin-left: 2px;\n      margin-right:  3px;\n    \"></i>\n  </div>\n  <a href=\"#dashboard\" data-permission=\"topm_flow_btn\" class=\"logo perm-active\">\n    <span>Flow</span>\n    <span style=\"margin-left:  -4px;\">City</span>\n  </a>\n</div>\n<div class=\"topbar-info\">\n  <div class=\"title-row\"><p class=\"page_title\">Dashboard</p></div>\n  <div class=\"title-row desc_row\"><span class=\"page_desc\">Dashboard</span></div>\n\n  <button id=\"create_campaign\" class=\"btn\">Start a New Campaign</button>\n  <button id=\"generate_report\" class=\"btn\" style=\"display: none\">Generate Report</button>\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
