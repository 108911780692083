
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OffersNG',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"ng-iframe\" style=\"display: flex;position: absolute;top: 0;left: 0;right: 0;bottom: 0;\">\n    <iframe src=\"\" frameborder=\"0\" style=\"flex-grow: 1;\"></iframe>\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
