
/* START_TEMPLATE */
define('hbs!templates/views/Payments/BillingInfoModal',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"modal-content create-offer-modal\">\n    <div class=\"modal-header\">\n        <div class=\"bootstrap-dialog-header\">\n            <div class=\"bootstrap-dialog-title\">Billing info</div>\n        </div>\n    </div>\n\n    <div class=\"modal-body\" style=\"min-height: 130px;\">\n\n        <div class=\"modal-loading\">\n            <div class=\"spinner\"></div>\n        </div>\n\n        <form class=\"form-horizontal\">\n\n            <div class=\"form-group\">\n                <label for=\"companyName\" class=\"col-sm-2 control-label text-right\">Company</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"companyName\" name=\"company_name\" value=\"";
  if (helper = helpers.company_name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.company_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Company name\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"firstName\" class=\"col-sm-2 control-label text-right\">First name</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"firstName\" name=\"first_name\" value=\"";
  if (helper = helpers.first_name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.first_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"First name\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"lastName\" class=\"col-sm-2 control-label text-right\">Last name</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"lastName\" name=\"last_name\" value=\"";
  if (helper = helpers.last_name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.last_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\"Last name\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingVATID\" class=\"col-sm-2 control-label text-right\">VAT ID *</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"billingVATID\" name=\"billing_taxid\" placeholder=\"VAT ID\" value=\"";
  if (helper = helpers.billing_taxid) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.billing_taxid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n                <small style=\"padding-left: 15px; color: #01b7cd;\">if you don't have VAT ID - send us an email ads@flow.city</small>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingAddress1\" class=\"col-sm-2 control-label text-right\">Address</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"billingAddress1\" name=\"billing_address1\" placeholder=\"Address line 1\" value=\"";
  if (helper = helpers.billing_address1) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.billing_address1); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingAddress2\" class=\"col-sm-2 control-label text-right\"></label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"billingAddress2\" name=\"billing_address2\" placeholder=\"Address line 2\" value=\"";
  if (helper = helpers.billing_address2) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.billing_address2); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingPostCode\" class=\"col-sm-2 control-label text-right\">Post code</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"billingPostCode\" name=\"billing_postcode\" placeholder=\"Post code\" value=\"";
  if (helper = helpers.billing_postcode) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.billing_postcode); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingCity\" class=\"col-sm-2 control-label text-right\">City</label>\n                <div class=\"col-sm-10\">\n                    <input type=\"text\" class=\"form-control\" id=\"billingCity\" name=\"billing_town\" placeholder=\"City\" value=\"";
  if (helper = helpers.billing_town) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.billing_town); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"billingCountry\" class=\"col-sm-2 control-label text-right\">Country</label>\n                <div class=\"col-sm-10\">\n                    <div id=\"countryCode\" class=\"dropup\" data-input-name=\"country_code\" data-button-size=\"btn-md\" data-button-type=\"btn-default\" data-scrollable-height=\"250px\" data-scrollable=\"true\"></div>\n                </div>\n            </div>\n\n        </form>\n\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default cancel-btn\" data-dismiss=\"modal\">Cancel</button>\n        <button type=\"button\" class=\"btn btn-primary save-btn ladda-button\" data-style=\"zoom-out\"><span class=\"ladda-label\">SAVE</span></button>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
