// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/EmotionsWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/EmotionsWidget',
         'views/Dashboard/WidgetBase',
         'models/DemographicsData',

         'gauge'
         ],
    function (App, Backbone, _, Marionette, $, moment,
              template,
              _WidgetBase,
              DemographicsData) {

        'use strict';
        var WidgetBase = _WidgetBase.prototype;
        return _WidgetBase.extend({

            template: template,

            className: 'white-panel pn widget widget--emotions-widget',
            widgetName: 'AgeDistributionWidget',
            widgetType: 'emotions',

            onRender: function () {
                var me = this;

                var opts = {
                    lines: 12,
                    angle: 0.14,
                    lineWidth: 0.44,
                    pointer: {
                        length: 0.89,
                        strokeWidth: 0.04,
                        color: '#000000'
                    },
                    limitMax: 'false',
                    colorStart: '#009de0',
                    colorStop: '#009de0',
                    strokeColor: '#f3f3f3',
                    generateGradient: true
                };

                me.gauge = new Gauge(me.$el.find('canvas')[0]).setOptions(opts);
                me.gauge.maxValue = 100;
                me.gauge.animationSpeed = 32;

                return WidgetBase.onRender.apply(me, arguments);
            },

            _update: function () {
                var me = this,
                    // data = App.currentSensorGroup.getDemographics().at(0),
                    data = new DemographicsData(),
                    emotion,
                    smileTime;

                if (!_.isUndefined(data) && !_.isNull(data.getData(me.widgetType))) {
                    emotion = !_.isNull(data.getData(me.widgetType)) && !_.isNull(data.getData(me.widgetType).emotion) ? data.getData(me.widgetType).emotion : 0;
                    smileTime = !_.isNull(data.getData(me.widgetType)) && !_.isNull(data.getData(me.widgetType).smile_time) ? data.getData(me.widgetType).smile_time : 0;

                    me.gauge && me.gauge.set(emotion);
                    me.$el.find('.emotions__positive-emotions .value').html(emotion.toFixed(2) + '<span>s</span>');
                    me.$el.find('.emotions__avg-smile-time .value').html(smileTime.toFixed(2) + '<span>s</span>');
                } else {
                    me.$el.find('.widget__body--no-camera').html('Data not available');
                    me.$el.addClass('widget--no-camera');
                }
                me._onResize();
                me.$el.find('.widget__loader').hide();
            },

            _onResize: function () {
                var me = this,
                    width = me.$el.find('.emotions__positive-emotions figure').width() || 200;

                if (width < 200) {
                    me.$el.find('.emotions__positive-emotions canvas').css({
                        width: width + 'px',
                        height: Math.round(width / 2) + 'px'
                    })
                }
            }

        });
    });

