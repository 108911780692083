// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,document*/
define('views/Payments/Invoice',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Payments/Invoice', 'jquery.cookie'],
    function (App, Backbone, _, $, template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            tagName: 'li',

            className: 'invoice',

            events: {
                'click .download-invoice-btn': '_downloadInvoice',
                'click .print-invoice-btn': '_printInvoice',
                'click [name="check_report"]': 'selectionWindow'
            },

            selectionWindow: function (event) {
                var currentTarget = $(event.currentTarget).first(),
                    all_checkboxes = $('[name="check_report"]'),
                    len = all_checkboxes.length;

                if(currentTarget.parent().parent().hasClass('report-header')) {

                    if(currentTarget[0].checked)
                        for(var i=0; i<len; ++i)
                            all_checkboxes[i].checked = true;
                    else
                        for(var i=0; i<len; ++i)
                            all_checkboxes[i].checked = false;
                }
                else{
                    all_checkboxes[0].checked = true;
                    for(var i=0; i<len; ++i)
                        if( !all_checkboxes[i].checked ){
                            all_checkboxes[0].checked = false;
                            break;
                        }
                }
            },

            _printInvoice: function (event) {
                event && event.preventDefault();
                var me = this;
                var downloadUrl = App.enrichApiUrl(me.model.get('uris').Download + '?os_user=' + $.cookie('X-FlowCity-Session-User'));
                window.open(downloadUrl, '_blank');
            },

            _downloadInvoice: function (event) {
                event && event.preventDefault();
                var me = this;
                var downloadUrl = App.enrichApiUrl(me.model.get('uris').Download + '?os_user=' + $.cookie('X-FlowCity-Session-User'));

                this.downloadFile(downloadUrl);
            },

            downloadFile: function (downloadUrl) {
                // for non-IE
                if (!window.ActiveXObject) {
                    var save = document.createElement('a');
                    save.href = downloadUrl;
                    save.target = '_blank';
                    save.download = 'unknown';

                    var evt = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    });
                    save.dispatchEvent(evt);

                    (window.URL).revokeObjectURL(save.href);
                }

                // for IE < 11
                else if ( !! window.ActiveXObject && document.execCommand)     {
                    var _window = window.open(downloadUrl, '_blank');
                    _window.document.close();
                    _window.document.execCommand('SaveAs', true, fileName || downloadUrl);
                    _window.close();
                }
            }
        });
    });

