
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferSchedulesSummary',['hbs','handlebars','templates/helpers/getWeekdays','templates/helpers/formatDate','templates/helpers/formatTime','templates/helpers/summaryWeatherDesc','templates/helpers/summaryDisruptionDesc','templates/helpers/summaryAIMDesc','templates/helpers/summaryPollenDesc','templates/helpers/summarySensorDesc','templates/helpers/summaryVolumetricsDesc','templates/helpers/summaryEPosDesc'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n        <b>"
    + escapeExpression((helper = helpers.getWeekdays || (depth0 && depth0.getWeekdays),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.weekdays), options) : helperMissing.call(depth0, "getWeekdays", ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.weekdays), options)))
    + "</b><br>from <b><span class=\"scheduler-date-start\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.schedule_start), "D MMM YYYY", "now", options) : helperMissing.call(depth0, "formatDate", ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.schedule_start), "D MMM YYYY", "now", options)))
    + "</span></b> till <b><span class=\"scheduler-date-stop\">"
    + escapeExpression((helper = helpers.formatDate || (depth0 && depth0.formatDate),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.schedule_stop), "D MMM YYYY", "unpublished", options) : helperMissing.call(depth0, "formatDate", ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.schedule_stop), "D MMM YYYY", "unpublished", options)))
    + "</span></b>\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.all_day), {hash:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program2(depth0,data) {
  
  
  return "\n            <br>\n            <b data-allday=\"true\">all day long</b>\n        ";
  }

function program4(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n        <span data-allday=\"false\"><br>from <b><span class=\"scheduler-time-from\">"
    + escapeExpression((helper = helpers.formatTime || (depth0 && depth0.formatTime),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.time_start), "hh:mm A", options) : helperMissing.call(depth0, "formatTime", ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.time_start), "hh:mm A", options)))
    + "</span></b> till <b><span class=\"scheduler-time-to\">"
    + escapeExpression((helper = helpers.formatTime || (depth0 && depth0.formatTime),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.time_stop), "hh:mm A", options) : helperMissing.call(depth0, "formatTime", ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.time_stop), "hh:mm A", options)))
    + "</span></b></span>\n        ";
  return buffer;
  }

function program6(depth0,data) {
  
  
  return "\n    <b>now</b>\n    ";
  }

  buffer += "<h3 id=\"offerSchedulesSummary\" data-permission=\"sche_summ_info\">\nThis campaign will play\n    ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.DbOfferScheduleTime)),stack1 == null || stack1 === false ? stack1 : stack1.active), {hash:{},inverse:self.program(6, program6, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    <!--";
  stack1 = (helper = helpers.summaryWeatherDesc || (depth0 && depth0.summaryWeatherDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleWeather), options) : helperMissing.call(depth0, "summaryWeatherDesc", (depth0 && depth0.DbOfferScheduleWeather), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summaryDisruptionDesc || (depth0 && depth0.summaryDisruptionDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleDisruptions), options) : helperMissing.call(depth0, "summaryDisruptionDesc", (depth0 && depth0.DbOfferScheduleDisruptions), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summaryAIMDesc || (depth0 && depth0.summaryAIMDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleAIM), options) : helperMissing.call(depth0, "summaryAIMDesc", (depth0 && depth0.DbOfferScheduleAIM), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summaryPollenDesc || (depth0 && depth0.summaryPollenDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferSchedulePollen), options) : helperMissing.call(depth0, "summaryPollenDesc", (depth0 && depth0.DbOfferSchedulePollen), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summarySensorDesc || (depth0 && depth0.summarySensorDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleSensor), options) : helperMissing.call(depth0, "summarySensorDesc", (depth0 && depth0.DbOfferScheduleSensor), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summaryVolumetricsDesc || (depth0 && depth0.summaryVolumetricsDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleVolumetrics), options) : helperMissing.call(depth0, "summaryVolumetricsDesc", (depth0 && depth0.DbOfferScheduleVolumetrics), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n    <!--";
  stack1 = (helper = helpers.summaryEPosDesc || (depth0 && depth0.summaryEPosDesc),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.DbOfferScheduleEPos), options) : helperMissing.call(depth0, "summaryEPosDesc", (depth0 && depth0.DbOfferScheduleEPos), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n        <br/>for <b><span id=\"selected-audience\">corporate</span></b> audience\n        <!--<br/>with budget of <b><span id=\"budget-summary\">-</span></b> credits-->\n        <!--<br/>for <b><span id=\"estimated-time-summary\">-</span></b> with <b><span id=\"selected-intensity\">-</span></b> intensity-->\n\n</h3>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
