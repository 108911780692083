
/* START_TEMPLATE */
define('hbs!templates/views/Payments/SubscriptionPlan',['hbs','handlebars','templates/helpers/if_type'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  
  return "\n";
  }

  stack1 = (helper = helpers.if_type || (depth0 && depth0.if_type),options={hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.type), options) : helperMissing.call(depth0, "if_type", (depth0 && depth0.type), options));
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  });
return t;
});
/* END_TEMPLATE */
;
