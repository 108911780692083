// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,window,console,setTimeout*/
define('layouts/ApplicationLayout',['App', 'jquery', 'underscore', 'hbs!templates/layouts/ApplicationLayout', 'backbone',
        'layouts/ModalRegion',

        'views/ApplicationLayout/ApplicationHeader',
        'views/ApplicationLayout/ApplicationHeaderMulti',
        'views/ApplicationLayout/ApplicationSidebar',
        'views/ApplicationLayout/ApplicationSidebarMulti',
        'views/ApplicationLayout/ApplicationSearchBar',
        'views/Displays/DisplayGroups',
        'views/Payments/CreditsPurchase',

        'bootstrap-dialog',
        'views/Payments/ChangePlan',

        'jquery.nicescroll'
    ],
    function (App, $, _, template, Backbone,
        ModalRegion,

        ApplicationHeader,
        ApplicationHeaderMulti,
        ApplicationSidebar,
        ApplicationSidebarMulti,
        ApplicationSearchBar,
        DisplayGroups,
        CreditsPurchase,

        BootstrapDialog,
        ChangePlan
        ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            regions: {
                layoutHeader: 'header',
                layoutSidebar: '#sidebar #sidebar-list',
                layoutMain: '#main-content',
                presentationPanel: '#presentation-panel',
                searchBar: '#search-bar',
                layoutDisplayList: '#display-list',

                // old
                projectSummary: "#project-summary-panel",
                contentPanel: "#content-panel",
                rightPanel: "#right-panel",
                activityStream: "#activity-stream-panel",
                modalRegion: ModalRegion,
                overlayPanel: "#overlay-panel"
            },

            ui: {
                layoutDisplayList: '#display-list',
                draftModeNotification:'.offer-draft-info',
                offerMainWrapper:'.offer-main-wrapper',
                container: '#container',
                projectSummaryPanel: '#project-summary-panel',
                centerPanel: '#center-panel',
                layoutMain: '#main-content',
                contentPanel: '#content-panel',
                rightPanel: '#right-panel',
                basicLayout: '.basic-layout',
                presentationPanel: '#presentation-panel',
                loadingMask: '.loading-mask',
                searchBar: '#search-bar'
            },

            events: {
                'resize-view' : 'resizeLayout',
                // 'click .header-buy-button': '_buyCredits',
                'click .buy-credits-btn': '_buyCredits',
                'click [data-action="logout"]': '_logout'
                //'resize-right-panel' : 'resizeRightPanel',
            },

            _logout: function (event) {
                event && event.preventDefault();
                App.vent.trigger('App.logout');
            },

            _toggleLoadingMask: function () {
                var me = this;
                if (me.ui.loadingMask.is(':hidden')) {
                    me.ui.loadingMask.show();
                } else {
                    me.ui.loadingMask.hide();
                }
            },

            _showLoadingMask: function (clickFn) {
                this.ui.loadingMask.show();
                if (_.isFunction(clickFn)) {
                    this.ui.loadingMask.on('click', clickFn);
                }
            },

            _hideLoadingMask: function () {
                this.ui.loadingMask.hide();
                this.ui.loadingMask.off('click');
            },

            _toggleWaitForProject: function () {
                this.$el.find('.wait-for-project-notify').toggle();
            },

            initialize: function () {
                var me = this;

                this.model = App.currentCompany.getBillingInfo();
                Backbone.Validation.bind(this);

                App.os.onResize.add('resizeLayout', me.resizeLayout);
                me.listenTo(App.vent, 'content-loading', _.bind(me.showLoadingMask, me));
                me.listenTo(App.vent, 'content-ready', _.bind(me.hideLoadingMask, me));
                // Some views (e.g. projectSheet) uses 2-step content-ready to prioritize loading of ajax resources; 
                // loading mask can be hidden after 1st step which is base-content-ready event 
                me.listenTo(App.vent, 'base-content-ready', _.bind(me.hideLoadingMask, me));

                if($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) !== -1)
                    me.layoutMain.on("before:show", function(view, region, options){
                        if (view.searchBar) {
                            me._onSearchBarShow(view.searchBar);
                        } else {
                            me.searchBar.reset();
                            me.ui.container.removeClass('fc-search-bar-active');
                        }
                    });

                // Low credits notification debouced func - 10min delays
                me._lowCreditsNotification = _.debounce(me._lowCreditsNotificationBase, 600000, me);

                // Flow.Ads
                me.listenTo(App.vent, 'App.layout.responsive', _.bind(me.responsiveView, me));
                me.listenTo(App.vent, 'App.layout.toggleSidebar', _.bind(me.toggleSidebar, me));
                me.listenTo(App.vent, 'App.layout.displayList.show', _.bind(me.showDisplayList, me));
                me.listenTo(App.vent, 'App.layout.displayList.hide', _.bind(me.hideDisplayList, me));
                me.listenTo(App.vent, 'App.layout.sidebar.hideForMobile', _.bind(me._hideSidebarForMobile, me));
                me.listenTo(App.vent, 'App.layout.loadingMask.show', _.bind(me._showLoadingMask, me));
                me.listenTo(App.vent, 'App.layout.loadingMask.hide', _.bind(me._hideLoadingMask, me));
                me.listenTo(App.vent, 'App.layout.loadingMask.toggle', _.bind(me._toggleLoadingMask, me));
                me.listenTo(App.vent, 'App.layout.whiteBody.toggle', _.bind(me._toggleWhiteBody, me));
                me.listenTo(App.vent, 'App.layout.searchBar.show', _.bind(me._onSearchBarShow, me));

                me.listenTo(App.vent, 'App.layout.lowCredits.info', _.bind(me._lowCreditsNotification, me));

                me.listenTo(App.vent, 'App.layout.togglePresentationPanel', _.bind(me.togglePresentationPanel, me));

                $(window).on('load', _.bind(me.responsiveView, me));
                $(window).on('resize', _.bind(me.responsiveView, me));

            },


            onRender: function () {
                var me = this,
                    wSize = $(window).width(),
                    isRanFromHomeScreen = navigator.standalone;
                $('body').css({'background':'#f3f3f4','animation':'none','-webkit-animation':'none'});

                // Show application header
                if ($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) === -1) {
                    me.layoutHeader.show(new ApplicationHeader());
                }
                else {
                    me.layoutHeader.show(new ApplicationHeaderMulti());
                }

                // Show application sidebar
                if(App.currentCompany.getSubscriptionPlans().getCurrent().attributes.type === 'MULTI') {
                    me.layoutSidebar.show(new ApplicationSidebarMulti({model: App.currentCompany}));
                } else {
                    me.layoutSidebar.show(new ApplicationSidebar({model: App.currentCompany}));
                }

                // hide menu sidebar on small devices by default
                App.vent.trigger('App.layout.sidebar.hideForMobile');


                window.addEventListener("message", me.receiveMessage, false);
            },

            toggleSidebar: function () {
                var me = this;
                var a = null;

                var ngIframe = document.getElementById('ng-iframe');
                if(ngIframe){

                    if(ngIframe.contentWindow) {
                        a = ngIframe.contentWindow;
                    }
                    else if(ngIframe.children[0].contentWindow) {
                        a = ngIframe.children[0].contentWindow;
                    }

                }

                if (!me.$el.find('#container').hasClass('sidebar-open')) {
                    // me.ui.layoutMain.css({ 'margin-left': '0' });
                    // me.ui.searchBar.css({ 'margin-left': '0' });
                    // me.$el.find('#sidebar').css({
                    //     'margin-left': '-210px'
                    // });
                    // me.$el.find('#sidebar > sidebar-list > ul').hide();
                    me.$el.find("#container").addClass("sidebar-open");
                } else {
                    // me.ui.layoutMain.css({ 'margin-left': '210px !important' });
                    // me.ui.searchBar.css({ 'margin-left': '210px !important' });
                    //
                    // me.$el.find('#sidebar > sidebar-list > ul').show();
                    // me.$el.find('#sidebar').css({
                    //     'margin-left': '0'
                    // });
                    me.$el.find("#container").removeClass("sidebar-open");
                }

                if(a){
                    a.postMessage(
                        [
                            'toggleSidebar',
                            $('#container').hasClass('sidebar-open')
                        ],
                        window.appUrl + '/ng/dist/index.html');
                }
            },

            showDisplayList: function () {
                this.$el.find("#container").addClass('display-selector');
            },

            hideDisplayList: function () {
                this.$el.find("#container").removeClass('display-selector');
            },

            responsiveView: function () {
                var me = this,
                    wSize = $(window).width();

                if (wSize <= 768) {
                    me.$el.find('#container').addClass('sidebar-close');
                    me.$el.find('#sidebar > ul').hide();
                }

                if (wSize > 768) {
                    me.$el.find('#container').removeClass('sidebar-close');
                    me.$el.find('#sidebar > ul').show();
                }
            },

            onDestroy: function () {
                $(window).off('load', _.bind(this.responsiveView, this));
                $(window).off('resize', _.bind(this.responsiveView, this));
            },

            resizeLayout: function (event) {
                if (event) { event.stopImmediatePropagation(); }

                App.os.fitContentToParent();
            },

            toggleSummaryHeader: function (visible) {
                if (visible) {
                    this.ui.projectSummaryPanel.css('height', '60px').show();
                    this.ui.centerPanel.removeClass('no-summary');
                } else {
                    this.ui.projectSummaryPanel.css('height', 0).hide();
                    this.ui.centerPanel.addClass('no-summary');
                }
                App.os.fitContentToParent();
            },

            toggleNavigationBar: function (visible) {
                if (visible) {
                    this.$el.find("#content-panel").addClass('show-project-nav');
                } else {
                    this.$el.find("#content-panel").removeClass('show-project-nav');
                }
                App.os.fitContentToParent();
            },


            toggleRightPanel: function (visible) {
                console.log('toggle right panel %s', visible);
                if (visible) {
                    this.ui.rightPanel.show();
                    this.ui.centerPanel.removeClass('full-width');
                } else {
                    this.ui.rightPanel.hide();
                    this.ui.centerPanel.addClass('full-width');
                }
                this.resizeLayout();
            },


            /** Handler for App.vent.trigger('content-ready')
             * hide global loading mask.
             */ 
            hideLoadingMask: function () {
                this.$el.find('.app-loading-mask').fadeOut('slow');
            },

            // show global loading mask
            showLoadingMask: function () {
                this.$el.find('.app-loading-mask').show();
            },

            _hideSidebarForMobile: function () {
                var me = this,
                    wSize = $(window).width();

                if (wSize <= 768) {
                    me.$el.find('#container').addClass('sidebar-close');
                    me.$el.find('#sidebar > ul').hide();
                }
            },

            togglePresentationPanel: function () {
                var me = this;
                me.ui.presentationPanel.toggle();
            },

            _toggleWhiteBody: function () {
                var me = this;
                $('body').toggleClass('white');
            },

            _lowCreditsNotificationBase: function () {
                // notify user on low credits state
                if (App.currentCompany.get('low_credits_week')) {
                    App.notifyBrowser('Low credits alert', 'You are running out of credits. Make sure to buy additional credits soon.');
                }
            },

            _onSearchBarShow: function (searchBarConfig) {
                var me = this;
                me.searchBar.show(new ApplicationSearchBar({ config: searchBarConfig }));
                me.ui.container.addClass('fc-search-bar-active');
            },


            _buyCredits: function (event) {
                event && event.preventDefault();

                if (App.currentCompany.getSubscriptionPlans().getCurrent().hasFeature('creditsAquisition')) {

                    var modalView = new CreditsPurchase(),
                        callback = function () {
                            if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                                    App.mainRegion.currentView.modalRegion.show(modalView);
                        };

                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);
                    } else {
                        App.mainRegion.currentView.modalRegion.show(modalView);
                    }
                }
            },


            receiveMessage: function(event)
            {
                var a = null;

                var ngIframe = document.getElementById('ng-iframe');
                if(ngIframe){

                    if(ngIframe.contentWindow) {
                        a = ngIframe.contentWindow;
                    }
                    else if(ngIframe.children[0].contentWindow) {
                        a = ngIframe.children[0].contentWindow;
                    }
                    else
                        return;

                }

                if(event.data === 'credits') {
                    a.postMessage([event.data, App.currentCompany.get('credits')], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'currentDepartment') {
                    a.postMessage([event.data, App.currentDepartment.attributes], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'currentCompany') {
                    a.postMessage([event.data, App.currentCompany.attributes], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'buy_more_credits'){
                    $('#number-of-credits').click();
                }
                else if(event.data[0] === 'offerNr'){
                    window.ngAddress = event.data[1];
                    $('[data-title="My Campaigns"]').click();
                }
                else if(event.data === 'open_chat'){
                    var b = document.getElementsByClassName('intercom-launcher-discovery-frame')[0].contentWindow.document;
                    $('.intercom-launcher-discovery', b).click()
                }
                else if(event.data === 'token') {
                    a.postMessage([event.data, $.cookie('X-FlowCity-Session-User')], window.appUrl + '/ng/dist/index.html');
                }


                else if(event.data === 'payable') {
                    a.postMessage([event.data, App.currentCompany.isPayable()], window.appUrl + '/ng/dist/index.html');
                }
                else if(App[event.data] !== undefined) {
                    var response = App[event.data];

                    if(response.attributes)
                        response = response.attributes;

                    a.postMessage([event.data, response], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data[0] === 'save_model'){
                    App.currentUser.set(event.data[1], event.data[2]);

                    App.currentUser.save({}, {
                        success: function() {
                            console.info('model saved');
                        },
                        error: function (model, response, options) {
                            BootstrapDialog.warning('Model saving error');
                        }
                    });
                }
                else if (event.data[0] === 'set-password'){
                    App.currentUser.set('password', event.data[1]);
                    App.currentUser.set('passwordRetyped', event.data[1]);
                    App.currentUser.save({}, {
                        success: function() {
                            BootstrapDialog.success('Your password has been successfully updated');
                        },
                        error: function (model, response, options) {
                            BootstrapDialog.warning('Unfortunatelly an error occurred while changing your password');
                        }
                    });
                }
                else if(event.data[0] === 'post_avatar'){

                    var file = event.data[1];

                    if (!_.isUndefined(file)) {

                        // me.ui.avatarBox.addClass('uploading');
                        // console.log( App.enrichApiUrl(this.model.get('uris').Avatar));
                        $.ajax({
                            url: App.enrichApiUrl(App.currentUser.get('uris').Avatar),
                            type: 'POST',
                            data: file,
                            cache: false,
                            dataType: 'json',
                            processData: false,
                            crossDomain: true,
                            contentType: false,
                            headers: {'X-Requested-With': 'XMLHttpRequest'},
                            success: function (data) {
                                BootstrapDialog.success('Your avatar has been successfully updated');

                                if (_.isUndefined(data.error)) {
                                    App.currentUser.save();
                                    App.vent.trigger('App.avatar.loaded');
                                    a.postMessage([event.data[0], 'done'], window.appUrl + '/ng/dist/index.html');
                                } else {
                                    console.log('ERRORS: ' + data.error);
                                    a.postMessage([event.data[0], 'error'], window.appUrl + '/ng/dist/index.html');
                                }
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                BootstrapDialog.warning('Your avatar has NOT been updated');
                                a.postMessage([event.data[0], 'error'], window.appUrl + '/ng/dist/index.html');
                            }
                        });
                    }
                }
                else if(event.data === 'get_billing_info'){

                    var response = App.currentCompany.getBillingInfo().attributes;

                    a.postMessage([event.data, response], window.appUrl + '/ng/dist/index.html');

                }
                else if(event.data[0] === 'save_billing_info'){

                    var billingModel = App.currentCompany.getBillingInfo();
                    var info = event.data[1];

                    // update model
                    billingModel.set({
                        'company_name': info['company_name'],
                        'first_name': info['first_name'],
                        'last_name': info['last_name'],
                        'billing_taxid': info['billing_taxid'],
                        'billing_address1': info['billing_address1'],
                        'billing_postcode': info['billing_postcode'],
                        'billing_town': info['billing_town'],
                        'country_code': info['country_code'],
                        'phone': info['phone']
                    });

                    billingModel.save({}, {
                        success: function() {
                            App.os.setLocalStorageKey('PAYMENT_DONE', true);
                            a.postMessage([event.data[0], true], window.appUrl + '/ng/dist/index.html');
                        },
                        error: function (model, response, options) {
                            a.postMessage([event.data[0], false], window.appUrl + '/ng/dist/index.html');
                        }
                    });
                }
                else if(event.data[0] === 'changePlan'){

                    event && event.preventDefault();

                    var me = this,
                        modalView,
                        callback;

                    modalView = new ChangePlan({plan: event.data[1]});
                    callback = function () {
                        if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                            App.mainRegion.currentView.modalRegion.show(modalView);
                    };

                    intercomReportEvent('changed_subscription_plan', {
                        timestamp: (new Date()).toString(),
                        subscription_new: event.data[1],
                        subscription_old: App.currentCompany.get('active_plan')
                    });

                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);

                    } else {
                        callback();
                    }
                }
                else if(event.data === 'location_settings'){

                    event && event.preventDefault();

                    var me = this,
                        modalView,
                        callback;

                    modalView = new ChangePlan({plan: event.data[1]});
                    callback = function () {
                        if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                            App.mainRegion.currentView.modalRegion.show(modalView);
                    };

                    intercomReportEvent('changed_subscription_plan', {
                        timestamp: (new Date()).toString(),
                        subscription_new: event.data[1],
                        subscription_old: App.currentCompany.get('active_plan')
                    });

                    if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                        App.vent.trigger('App.layout.billingInfo.show', callback);

                    } else {
                        callback();
                    }
                }
                else if(event.data === 'invoices'){
                    var response = App.enrichApiUrl(App.currentCompany.attributes.uris.Invoices);

                    a.postMessage([event.data, response], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'companyUri'){
                    var uriString = App.currentCompany.get('uri');
                    a.postMessage([event.data, uriString], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'toggleSidebar'){
                    App.vent.trigger('App.layout.toggleSidebar');
                    a.postMessage([
                            event.data,
                            $('#container').hasClass('sidebar-open')
                        ],
                        window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data[0] === 'updateTitle'){
                    App.vent.trigger('App.layout.updateTitle', [event.data[1]]);
                    a.postMessage(event.data[0], window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'spread-iframe'){
                    $('iframe#ng-iframe').parent().css('top', '-90px');
                    a.postMessage(event.data, window.appUrl + '/ng/dist/index.html');
                }
                else if(event.data === 'shrink-iframe'){
                    $('iframe#ng-iframe').parent().css('top', '0');
                    a.postMessage(event.data, window.appUrl + '/ng/dist/index.html');
                }
            }

        });
    });

