
/* START_TEMPLATE */
define('hbs!templates/views/PasswordReset/ForgotPassword',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"login-page\">\n	  	<div class=\"container\">\n\n            <div class=\"top-logo\">\n                <img src=\"/static/images/flow-ads-logo-cover.png\" alt=\"Flow.Ads\">\n            </div>\n\n              <form class=\"form-login\" id=\"forgot-password-form\">\n		        <h2 class=\"form-login-heading\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "password reset", options) : helperMissing.call(depth0, "trans", "password reset", options)))
    + "</h2>\n		        <div class=\"login-wrap\">\n		            <p>"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Enter your e-mail address used when registering. You will receive password reset instructions.", options) : helperMissing.call(depth0, "trans", "Enter your e-mail address used when registering. You will receive password reset instructions.", options)))
    + "</p>\n		            <input type=\"text\" name=\"email\" id=\"email\" class=\"form-control validate[required,custom[email]]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Your E-mail", options) : helperMissing.call(depth0, "trans", "Your E-mail", options)))
    + "\" autocomplete=\"off\" autofocus>\n		            <br>\n                    <div id=\"std-password-reset-btn\" class=\"btn btn-theme btn-block\" type=\"submit\">"
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "RESET PASSWORD", options) : helperMissing.call(depth0, "trans", "RESET PASSWORD", options)))
    + "</div>\n                    <br>\n                    <p class=\"text-center\"><a href=\"#login\">Back to login page</a></p>\n		        </div>\n\n\n		      </form>\n\n	  	</div>\n	  </div>\n";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
