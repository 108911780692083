// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/AgeDistributionWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/AgeDistributionWidget',
         'views/Dashboard/WidgetBase',
         'models/DemographicsData',

         'jquery-knob'
         ],
    function (App, Backbone, _, Marionette, $, moment,
              template,
              _WidgetBase,
              DemographicsData) {

        'use strict';
        var WidgetBase = _WidgetBase.prototype;
        return _WidgetBase.extend({

            template: template,

            className: 'white-panel pn widget widget--age-distribution-widget',
            widgetName: 'AgeDistributionWidget',
            widgetType: 'age',

            onRender: function () {
                var me = this;
                me.$el.find(".dial").knob({
                    displayInput: false
                });

                return WidgetBase.onRender.apply(me, arguments);
            },

            _update: function () {
                var me = this,
                    data = new DemographicsData();

                data.rendomize();

                me.$el.find(".percent-gauge").each(function (idx, el) {
                        var gauge = $(el).find('.dial'),
                            // data = App.currentSensorGroup.getDemographics().at(0),
                            par;


                        par = $(el).find('p').detach();
                        $(el).find('>div').append(par);

                        console.log(data);
                        console.log(data.getData(me.widgetType));

                        if (!_.isUndefined(data) && !_.isNull(data.getData(me.widgetType))) {
                            $(el).find('p').html(data.getData(me.widgetType)[gauge.data('value')].toFixed(2) + '<span>%</span>');

                            _.defer(function () {
                                gauge.animate({value: data.getData(me.widgetType)[gauge.data('value')]}, {
                                    duration: 500,
                                    easing: 'swing',
                                    step: function () {
                                        gauge.val(this.value).trigger('change');
                                    }
                                });
                            })
                        } else {
                            me.$el.find('.widget__body--no-camera').html('Data not available');
                            me.$el.addClass('widget--no-camera');
                        }
                    });
                me._onResize();
                me.$el.find('.widget__loader').hide();

            },

            _onResize: function () {
                var me = this,
                    gauge;

                me.$el.find(".percent-gauge").each(function (idx, el) {
                    gauge = $(el).find('.dial');
                    gauge.trigger('configure', {
                        width: $(el).width(),
                        height: $(el).width()
                    });
                    $(el).find('p')
                        .css('line-height', $(el).width() + 'px')
                        .show();
                });
            }

        });
    });

