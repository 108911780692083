
/* START_TEMPLATE */
define('hbs!templates/layouts/RetailersLayout',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"retailers-selector\" id=\"retailers-selector\"></div>\n<div id=\"retailer-details\"></div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
