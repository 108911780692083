// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/OfferTargets',["backbone", "models/OfferTarget"],
    function (Backbone, OfferTarget) {

        'use strict';
        var OfferTargets = Backbone.Collection.extend({

            model: OfferTarget,

            comparator: function (model) {
                return model.get('name');
            },

            areAllInDraft: function () {
                return !this.find(function (target) { return target.get('status') !== 'draft'; });
            },

            getByStatus: function (status) {
                return this.find(function (target) { return target.get('status') === status; });
            },

            getByDisplayGroupUri: function (uri) {
                return this.find(function (target) {
                    if (typeof target.get('target') === 'string') {
                        return target.get('target') === uri;
                    } else {
                        return target.get('target').uri === uri;
                    }
                });
            },

            setStatusToAllTargets: function (status, options) {
                var options = options || {};
                this.each(function (target) {
                    console.log(status);
                    target.set('status', status, options);
                });
            }
        });

        return OfferTargets;
    });
