// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Payments/TopCreditsArea',['App', 'jquery', 'underscore',
        'hbs!templates/views/Payments/TopCreditsArea',
        'views/Payments/BillingInfoModal',
        'views/Payments/CreditsPurchase'],
function (App, $, _,
          template,
          BillingInfoModal,
          CreditsPurchase) {

    'use strict';
    return Backbone.Marionette.ItemView.extend({
        template: template,

        modelEvents: {
            'change:credits' : 'render'
        },

        events: {
            'click #number-of-credits' : '_buyCredits'
        },

        onRender: function () {
            var credits = $("#credit_slider");
            if(credits)
                credits.slider({max: App.currentCompany.get('credits')});
        },

        initialize: function (options) {
            var me = this;
            me.model = App.currentCompany;
            me.listenTo(App.vent, 'App.layout.billingInfo.show', _.bind(me._showBillingInfo, me));
        },

        _buyCredits: function (event) {
            event && event.preventDefault();

            if (App.currentCompany.getSubscriptionPlans().getCurrent().hasFeature('creditsAquisition')) {

                var modalView = new CreditsPurchase(),
                    callback = function () {
                        if( !_.isEmpty(App.currentCompany.getBillingInfo().get('company_name')) )
                            App.mainRegion.currentView.modalRegion.show(modalView);
                    };

                if (_.isEmpty(App.currentCompany.getBillingInfo().get('company_name'))) {
                    App.vent.trigger('App.layout.billingInfo.show', callback);
                } else {
                    App.mainRegion.currentView.modalRegion.show(modalView);
                }
            }
        },

        _showBillingInfo: function (callback) {
            $.when(App.currentCompany.getBillingInfo())
                .then(function () {
                    var modalView = new BillingInfoModal({ callback: callback });
                    App.mainRegion.currentView.modalRegion.show(modalView);
                });
        }
    });
});
