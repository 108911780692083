
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentDetailsLayout',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"department-details-menu\" class=\"top-tabs-menu\">\n    <ul class=\"top-department-profile-menu\">\n        <li data-id=\"department-details\"><a href=\"#\">Details</a></li>\n        <li data-id=\"department-displaygroups\"><a href=\"#\">Display Groups</a></li>\n        <li data-id=\"department-users\"><a href=\"#\">Users</a></li>\n    </ul>\n</div>\n<div id=\"department-details-panel\"></div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
