
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferUploadTemplate',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div>\n    Type template name or choose it from a list\n\n    <div class=\"row\"></div>\n\n    <div class=\"dropdown\">\n        <div class=\"btn btn-primary dropdown-toggle upload-template-button disabled\" data-toggle=\"dropdown\" aria-expanded=\"false\" >\n            <p>Start typing...</p>\n            <div>\n                <span class=\"fa fa-angle-down\"></span>\n            </div>\n\n        </div>\n        <ul class=\"dropdown-menu upload-template-dropdown\" >\n            <!--<li><a href=\"#\">Lorem </a></li>-->\n            <!--<li><a href=\"#\">ipsum </a></li>-->\n            <!--<li><a href=\"#\">dolor </a></li>-->\n            <!--<li><a href=\"#\">sit </a></li>-->\n        </ul>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
