
/* START_TEMPLATE */
define('hbs!templates/views/Dashboard/GenderDistributionWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"widget__header\">\n    <h5><span class=\"icon icon--camera\"></span>Camera: Gender distribution</h5>\n</div>\n\n<div class=\"widget__body\">\n    <div>\n        <canvas></canvas>\n    </div>\n    <div class=\"col-xs-12\">\n        <div class=\"desc\">\n            <div class=\"desc__males\">\n                <span class=\"value\">0<span>%</span></span>\n                <div>Males</div>\n            </div>\n            <div class=\"desc__females\">\n                <span class=\"value\">0<span>%</span></span>\n                <div>Females</div>\n            </div>\n        </div>\n    </div>\n\n    <span class=\"widget__loader\">\n        <div class=\"spinner\"></div>\n    </span>\n\n    <span class=\"widget__body--no-camera\">No camera selected</span>\n\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
