
/* START_TEMPLATE */
define('hbs!templates/views/Notifications/PublicTransportDisruptions',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"row\">\n    <div class=\"col-lg-12 notifications\"></div>\n    <div class=\"col-lg-12\" data-permission=\"disr_show_btn\">\n        <a class=\"show-history-link\" href=\"#\"><i class=\"fa fa-history\"></i> Show historical data</a>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
