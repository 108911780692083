
/* START_TEMPLATE */
define('hbs!templates/views/Profile/ProfilePasswordChange',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"detailed\">\n    <h4 class=\"formats-wrap-toggle collapsed\" data-toggle=\"collapse\" href=\"#password_change_body\">\n        <i class=\"fa fa-angle-right\"></i>\n        Password Change\n        <i class=\"fa fa-angle-right\"></i>\n    </h4>\n\n    <div id=\"password_change_body\" class=\"panel-collapse collapse\">\n\n        <p>To change password enter your new password twice and save changes by pressing CHANGE PASSWORD button.</p>\n\n        <span class=\"update-status\"></span>\n\n        <form role=\"form\" class=\"form-horizontal\">\n\n            <div class=\"form-group\" data-permission=\"user_newp_fiel\">\n                <label class=\"control-label\">NEW password</label>\n\n                <div>\n                    <input type=\"password\" class=\"form-control editable\" name=\"password\" value=\"\" placeholder=\"New password\" autocomplete=\"off\" autocapitalize=\"off\" style=\"max-width: 300px;\">\n                </div>\n            </div>\n\n            <div class=\"form-group\" data-permission=\"user_rety_fiel\">\n                <label class=\"control-label\">NEW password (retype)</label>\n\n                <div>\n                    <input type=\"password\" class=\"form-control editable\" name=\"passwordRetyped\" value=\"\" placeholder=\"Retyped password\" autocomplete=\"off\" autocapitalize=\"off\" style=\"max-width: 300px;\">\n                    <div id=\"passwordsIdentical\">\n                        <span></span>\n                    </div>\n                </div>\n            </div>\n\n\n            <div class=\"form-group\" data-permission=\"user_chan_btn\">\n                <div>\n                    <button type=\"button\" class=\"btn set-password ladda-button btn-fc\" data-style=\"zoom-out\" style=\"max-width: 300px;\"><span class=\"ladda-label\">Change password</span></button>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
