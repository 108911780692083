// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/EyeTrackingWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/EyeTrackingWidget',
         'views/Dashboard/WidgetBase',
         'models/DemographicsData',

         'gauge'
         ],
    function (App, Backbone, _, Marionette, $, moment,
              template,
              _WidgetBase,
              DemographicsData) {

        'use strict';
        var WidgetBase = _WidgetBase.prototype;
        return _WidgetBase.extend({

            template: template,

            className: 'white-panel pn widget widget--eye-tracking-widget',
            widgetName: 'EyeTrackingWidget',

            _update: function () {
                var me = this,
                    // data = App.currentSensorGroup.getDemographics().at(0);
                    data = new DemographicsData();

                if (!_.isUndefined(data) && (!_.isNull(data.getData('viewing') || !_.isNull(data.getData('cameraTime'))))) {
                    me.$el.find('.eye-tracking__duration .value').html(((!_.isNull(data.getData('viewing')) ? data.getData('viewing') : 0) / 1000).toFixed(2) + '<span>s</span>');
                    me.$el.find('.eye-tracking__attention .value').html(((( !_.isNull(data.getData('cameraTime')) && !_.isNull(data.getData('cameraTime').attention)) ? data.getData('cameraTime').attention : 0) / 1000).toFixed(2) + '<span>s</span>');
                } else {
                    me.$el.find('.widget__body--no-camera').html('Data not available');
                    me.$el.addClass('widget--no-camera');
                }
                me._onResize();
                me.$el.find('.widget__loader').hide();
            }

        });
    });

