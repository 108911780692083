// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Notifications/Widget',['App',
        'backbone',
        'collections/PublicTransportDisruptions',
        'views/Notifications/Notification'
        ],
    function (
        App,
        Backbone,
        PublicTransportDisruptions,
        NotificationView
    ) {

        'use strict';
        return Backbone.Marionette.CollectionView.extend({
            childView: NotificationView,

            initialize: function () {
                var me = this;

                me.childViewOptions = {
                    parent: me
                };

                me.collection = App.currentUser.getPublicTransportDisruptions();

            },

            collectionEvents: {
                'reset add remove': 'render'
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({

                    events: {
                        'click' : function () {
                            if (App.currentCompany.hasFeature('tflDisruptions')) {
                                App.appRouter.navigate('notifications', true);
                            }
                        }
                    },

                    getTemplate: function () {
                        var templateHtml = '<div class="col-md-12"><div class="suggestion mt">All lines are in good service</div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            filter: function (child, index, collection) {
                if (_.size(collection) > 1) {
                    child.set('disruption_count', _.size(collection) - 1, {silent: true});
                } else {
                    child.unset('disruption_count');
                }

                return index === 0;

            },

            onBeforeAddChild: function(childView){
                var me = this;
                if (_.size(me.children) === 1) {
                    childView.destroy();
                }
            },

            onRender: function () {
                var me = this;

            }

        });
    });
