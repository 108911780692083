
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentLocationWidget',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n  <div class=\"col-md-3\">\n      <div class=\"row\">\n          <div class=\"col-md-4\">Address</div>\n          <div class=\"col-md-8\"> ";
  if (helper = helpers.locationAddress) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.locationAddress); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </div>\n      </div>\n      <div class=\"row\">\n          <div class=\"col-md-4\">Latitude</div>\n          <div class=\"col-md-8\"> ";
  if (helper = helpers.locationLatitude) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.locationLatitude); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </div>\n      </div>\n      <div class=\"row\">\n          <div class=\"col-md-4\">Longitude</div>\n          <div class=\"col-md-8\"> ";
  if (helper = helpers.locationLongitude) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.locationLongitude); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </div>\n      </div>\n      <div class=\"row\">\n          <div class=\"col-md-4\">Country</div>\n          <div class=\"col-md-8\"> ";
  if (helper = helpers.locationCountry) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.locationCountry); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </div>\n      </div>\n      <div class=\"row\">\n          <div class=\"col-md-4\">City</div>\n          <div class=\"col-md-8\"> ";
  if (helper = helpers.locationCity) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.locationCity); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " </div>\n      </div>\n  </div>\n  <div class=\"col-md-9\">\n      <div id=\"map\"></div>\n  </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
