
/* START_TEMPLATE */
define('hbs!templates/views/InternalReports/InternalReportsLayout',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"top-panel filter-panel\">\n    <div id=\"dateRangeInternalReports\" class=\"pull-right\">\n        <i class=\"glyphicon glyphicon-calendar fa fa-calendar\"></i>&nbsp;\n        <span></span> <b class=\"caret\"></b>\n    </div>\n</div>\n<div style=\"clear: both\"></div>\n\n<div class=\"internal-reports-root\">\n    <div class=\"row\">\n        <div class=\"col-md-6 col-lg-4\">\n            <div class=\"row\">\n                <div class=\"col-md-12 col-lg-12 mb\" id=\"userRegistrations\" data-permission=\"int_repo_widg_usr_reg\"></div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-md-12 col-lg-12 mb\" id=\"userLogins\" data-permission=\"int_repo_widg_usr_log\"></div>\n            </div>\n        </div>\n\n        <div class=\"col-md-6 col-lg-4 mb\" id=\"sessionLengths\" data-permission=\"int_repo_widg_sess_len\"></div>\n\n        <div class=\"col-md-6 col-lg-4\">\n            <div class=\"row\">\n                <div class=\"col-md-12 col-lg-12 mb\" id=\"creditsBurn\" data-permission=\"int_repo_widg_cred_burn\"></div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-md-12 col-lg-12 mb\" id=\"creditsPurchased\" data-permission=\"int_repo_widg_cred_purch\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-md-6 col-lg-4 mb\" id=\"registrationsList\" data-permission=\"int_repo_widg_usr_reg\"></div>\n        <div class=\"col-md-6 col-lg-4 mb\" id=\"loggedUsers\" data-permission=\"int_repo_widg_usr_logged\"></div>\n    </div>\n</div>\n";
  });
return t;
});
/* END_TEMPLATE */
;
