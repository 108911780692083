
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/RetailersSelector',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"retailers-filter-box\" class=\"panel-filter-box\"></div>\n<div id=\"retailers-list\" class=\"panel-list-box\"></div>\n<div id=\"create-retailer-box\" class=\"panel-footer-box\">\n    <button class=\"btn btn-default btn-lg btn-top-bar pull-right btn-bottom create-btn\"><i class=\"fa fa-plus\"></i> Add new retailer</button>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
