// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*global define*/
define('templates/helpers/isPlural',['App', 'handlebars'], function (App, Handlebars) {
    'use strict';
    function isPlural(number, options) {
        if (number > 1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    }
    Handlebars.registerHelper('isPlural', isPlural);
    return isPlural;
});
