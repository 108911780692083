
/* START_TEMPLATE */
define('hbs!templates/views/Displays/DisplayControl',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"col-sm-3 col-sm-push-3\">\n    <button class=\"btn btn-lg presenter-btn command-btn\" data-screen-command=\"prev\"><i class=\"fa fa-backward\"></i></button>\n</div>\n<div class=\"col-sm-3 col-sm-push-3\">\n    <button class=\"btn btn-lg presenter-btn command-btn\" data-screen-command=\"next\"><i class=\"fa fa-forward\"></i></button>\n</div>\n\n<a href=\"#\" class=\"exit-btn\"><i class=\"fa fa-times\"></i></a>";
  });
return t;
});
/* END_TEMPLATE */
;
