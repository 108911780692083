// -*- coding: utf-8 -*-


/*global define*/
define('collections/SubmittedOffersSearchable',["backbone", "models/Offer"],
    function (Backbone, Offer) {

        'use strict';

        var Offers = Backbone.SearchableCollection.extend({
            model: Offer,
            searchUri: '/data/search/offers/submitted'
        });

        return Offers;
    });
