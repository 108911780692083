// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,sessionData*/
define('views/Displays/DisplayControl',['App', 'jquery', 'underscore', 'hbs!templates/views/Displays/DisplayControl', 'backbone', 'models/Display'],
    function (App, $, _, template, Backbone, Display) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({
            template: template,

            tagName: 'div',
            className: 'row',

            modelEvents: {
                'change': 'render'
            },

            ui: {
                'displayOffers' : '.display-offers',
                'commandBtn' : '.command-btn',
                'exitBtn' : '.exit-btn'
            },

            events: {
                'click @ui.commandBtn' : 'sendCommand',
                'click @ui.exitBtn' : 'exit'
            },

            initialize: function (options) {
                this.parent = this.options.parent;
            },

            onRender: function () {
                var me = this;

            },

            sendCommand: function (event) {
                var me = this;
                if (!_.isUndefined(event) && !_.isUndefined($(event.currentTarget).data('screenCommand'))) {
                    App.os.sendCommand(App.enrichApiUrl(me.model.get('uris').Control), $(event.currentTarget).data('screenCommand'));
                }
            },

            _markCurrentOffer: function (uri) {
                var me = this;
                me.$el.find('.display-offers').removeClass('current');
                me.$el.find('.display-offers[data-id="' + uri + '"]').addClass('current');
            },

            displayOffer: function (event) {
                var me = this;
                if (!_.isUndefined(event) && !_.isUndefined($(event.currentTarget).data('id'))) {
                    App.os.sendCommand(
                        me.model.get('uris').Control,
                        'show',
                        {
                            offer : $(event.currentTarget).data('id')
                        }
                    );
                    me._markCurrentOffer($(event.currentTarget).data('id'));
                }
            },

            exit: function (event) {
                var me = this;
                event.preventDefault();
                me.destroy();
            },

            onDestroy: function () {
                App.vent.trigger('App.layout.togglePresentationPanel');
            }

        });
    });
