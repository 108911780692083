'use strict';

define('views/InternalReports/SessionLengths',[
        'App',
        'backbone',
        'underscore',
        'backbone.marionette',
        'jquery',
        'moment',
        'hbs!templates/views/InternalReports/SessionLengths'
    ],
    function (App,
              Backbone,
              _,
              Marionette,
              $,
              moment,
              template) {

        return Backbone.Marionette.ItemView.extend({

            template: template,
            templateHelpers: function () {
                return {
                    test: this.test,
                    clusters: this.clusters
                };
            },

            className: 'white-panel widget ar-16-9',

            ui: {
                spinner: ".internal-reports-loading-mask"
            },

            initialize: function () {
                this.listenTo(App.vent, 'App.internalReports.dateRangeChanged', _.bind(this.loadStats, this));
            },

            loadStats: function (data) {
                var me = this;
                var from = data.from;
                var to = data.to;

                me.ui.spinner.addClass('spinning');
                var timeRange = Math.floor((+from) / 1000) + '/' + Math.floor((+to.add(1, 'day')) / 1000);
                $.ajax({
                    url: sessionMonitorServiceUrl + '/query/activity/' + timeRange,
                    method: "GET",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-FlowCity-Session-User', $.cookie('X-FlowCity-Session-User'));
                    }
                }).done(function (data) {
                    me.clusters = data;
                    var maxCount = 0;
                    me.clusters.forEach(function (item) {
                        if (maxCount < item.count)
                            maxCount = item.count;
                    });
                    if (maxCount > 0) {
                        me.clusters.forEach(function (item) {
                            item.percent = item.count / maxCount * 100;
                        });
                    }
                    console.log("Loaded session length data", me.clusters);
                    me.render();
                });
            }
        });
    });

