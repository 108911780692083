
/* START_TEMPLATE */
define('hbs!templates/views/Payments/InvoiceHeader',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<li class=\"invoice invoice-header\">\n    <div class=\"checkbox checkbox-info checkbox-inline\">\n        <input type=\"checkbox\" name=\"check_report\" data-field=\"check_report\" value=\"1\"><label></label>\n    </div>\n    <div class=\"invoice-date\" id=\"sort-campaign-name\">\n        Date <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"invoice-no\" id=\"sort-status\">\n        Invoice No <i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"invoice-details\" id=\"sort-company-name\">\n        <span>Details</span>\n    </div>\n    <div class=\"invoice-price\" id=\"sort-format\">\n        <span>Price</span>\n    </div>\n    <div class=\"invoice-buttons\">\n\n    </div>\n</li>";
  });
return t;
});
/* END_TEMPLATE */
;
