// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,console,Bloodhound,document,setTimeout,defaultOfferTemplate*/
define('views/Retailers/CreateUser',['App', 'backbone', 'underscore', 'jquery', 'hbs!templates/views/Retailers/CreateUser', 'models/User', 'ladda-bootstrap', 'bootstrap-dialog'],
    function (App, Backbone, _, $, template, User, Ladda, BootstrapDialog) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            className: 'modal-dialog bootstrap-dialog type-primary size-normal',

            ui: {
                'email' : 'input[name="email"]',
                'firstname' : 'input[name="firstname"]',
                'lastname' : 'input[name="lastname"]'
            },

            events: {
                'click .create-btn' : 'createUser'
            },

            initialize: function (options) {
                this.parentView = options && options.parentView;
            },

            onShow: function () {
                var me = this;
                setTimeout(function () { me.ui.email.focus(); }, 200);
            },

            createUser: function (event) {
                var me = this,
                    newUser,
                    l = Ladda.create(document.querySelector('.modal-content .create-btn')),
                    email = me.ui.email.val();

                if (!App.os.isValidEmail(email)) {
                    App.os.showFieldError('input[name="email"]', "Please enter valid email address");
                    return;
                }

                l.start();

                this.$el.find('input[type=text]').prop('disabled', true);
                this.$el.find('button').addClass('disabled');

                newUser = new User({
                    'email' : email,
                    'firstname' : me.ui.firstname.val(),
                    'lastname' : me.ui.lastname.val(),
                    'mobile' : '',
                    'phone' : ''
                });
                newUser.url = me.parentView.collection.url;
                newUser.save(null, {
                    success: function (model, response, options) {
                        me.parentView.collection.add(newUser);
                        App.mainRegion.currentView.modalRegion.currentView.destroy();
                    },
                    error: function (model, response, options) {
                        me.destroy();
                        BootstrapDialog.show({
                            type: BootstrapDialog.TYPE_DANGER,
                            title: App.trans('Huston! We have a problem!'),
                            message: App.trans("Unfortunately an error occurred while creating new User.<br><br>") + '<b>' + response.responseJSON.reason + '</b>',
                            buttons: [{
                                label: App.trans("Close"),
                                action: function (dialog) {
                                    dialog.close();
                                }
                            }]
                        });
                    }
                })

            }

        });
    });

