
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferPreview',['hbs','handlebars','templates/helpers/capitalize'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "<div class=\"offer-preview-overlay ";
  if (helper = helpers.offer_type) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.offer_type); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"></div>\n    ";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    <div class=\"offer-preview-placeholder ";
  if (helper = helpers.offer_variant) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.offer_variant); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n        <div class=\"offer-preview-wrapper\">\n            <i class=\"fa fa-picture-o\" aria-hidden=\"true\"></i><br>"
    + escapeExpression((helper = helpers.capitalize || (depth0 && depth0.capitalize),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.offer_type), options) : helperMissing.call(depth0, "capitalize", (depth0 && depth0.offer_type), options)))
    + " preview\n        </div>\n    </div>\n    ";
  return buffer;
  }

function program5(depth0,data) {
  
  
  return "<div class=\"offer-preview-transcoding\"><i class=\"fa fa-cogs\" aria-hidden=\"true\"></i><br>Transcoding in progress<br>Please wait</div>";
  }

function program7(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "style=\"background-image: url(";
  if (helper = helpers.media_file) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.media_file); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ");\"";
  return buffer;
  }

function program9(depth0,data) {
  
  
  return "disabled";
  }

  buffer += "\n<div class=\"offer-preview-window\">\n    <!--<iframe id=\"offer-preview\" width=\"100%\" height=\"300px\" frameborder=\"0\"></iframe>-->\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file_transcode_inprogress), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    <div  class=\"static-preview-container\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "></div>\n</div>\n\n<div class=\"offer-media-options\">\n    <div class=\"tdp hidden\" data-type-image=\"true\" data-type-video=\"true\" data-draft=\"true\">\n\n        <div class=\"fileinput-new\" style=\"position: relative; text-align: center;\">\n            <div>\n                <a  class=\"btn btn-lg btn-default btn-file\">\n                    <span class=\"fileinput-new\"><i class=\"fa fa-cloud-upload\"></i>&nbsp;Upload new</span>\n                    <span class=\"fileinput-exists\">Uploading...</span><input type=\"file\" id=\"fileToUpload\">\n                </a>\n                <a class=\"btn btn-lg btn-default download-mediafile-btn ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-permission=\"offe_down_btn\" >\n                    <i class=\"fa fa-cloud-download\"></i>&nbsp;Download\n                </a>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"tdp\" data-type-image=\"true\" data-type-video=\"true\" data-draft=\"false\" data-approver=\"true\" style=\"height: 59px;\">\n        <div class=\"fileinput-new\" style=\"position: relative; text-align: center;\">\n            <div>\n                <a class=\"btn btn-lg btn-default btn-file disabled\">\n                    <span class=\"fileinput-new\"><i class=\"fa fa-cloud-upload\"></i>&nbsp;Upload new</span>\n                </a>\n                <a class=\"btn btn-lg btn-default download-mediafile-btn ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-permission=\"offe_down_btn\" >\n                    <i class=\"fa fa-cloud-download\"></i>&nbsp;Download\n                </a>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"btn-group btn-group-justified aspect-ratio-selector\" data-toggle=\"buttons\">\n        <label data-permission=\"offe_16x9_btn\" class=\"btn btn-default\">\n            <input type=\"radio\" name=\"aspectRatio\" autocomplete=\"off\" value=\"16:9\">16:9\n        </label>\n        <label data-permission=\"offe_9x16_btn\" class=\"btn btn-default\">\n            <input type=\"radio\" name=\"aspectRatio\" autocomplete=\"off\" value=\"9:16\">9:16\n        </label>\n        <label data-permission=\"offe_4x3_btn\" class=\"btn btn-default\">\n            <input type=\"radio\" name=\"aspectRatio\" autocomplete=\"off\" value=\"4:3\">4:3\n        </label>\n    </div>\n</div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
