// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('templates/helpers/formatDateTime',['App', 'handlebars', 'moment'], function (App, Handlebars, moment) {

    'use strict';
    function formatDateTime(dt) {
        return (dt ? moment(dt).format(App.currentUser.get('datetime_fmt')) : "");
    }

    Handlebars.registerHelper('formatDateTime', formatDateTime);
    return formatDateTime;
});
