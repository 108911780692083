// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define*/
define('models/User',["jquery", "underscore", "backbone", "timezones",
        "App",
        "collections/DisplayGroups",
        "collections/DisplayGroupsSearchable",
        "collections/PublicTransportDisruptions",
        "collections/PublicTransportDisruptionsHistory",
        "models/PublicTransportDisruption",
        "collections/Displays",
        "collections/Departments",
        "collections/Profiles",
        "models/Permissions",
        "collections/DisplayGroupsAdminSearchable"],
    function ($, _, Backbone, tz,
              App,
              DisplayGroups,
              DisplayGroupsSearchable,
              PublicTransportDisruptions,
              PublicTransportDisruptionsHistory,
              PublicTransportDisruption,
              Displays,
              Departments,
              Profiles,
              Permissions,
              DisplayGroupsAdminSearchable
        ) {

        'use strict';
        var User = Backbone.Model.extend({
            url: null,
            uriStencil: 'users',

            initialize: function () {
                var me = this;
                me.addUri('DisplayGroups', '/data/display-groups');
                // me.getDisplayGroups();

                if (me.hasRole(App.os.roles.approver)) {
                    me.addUri('DisplayGroupsApprover', '/data/search/display-groups/approver');
                    App.currentCompany.addUri('SubmittedOffers', '/data/submitted-offers');
                    me.addUri('ApproverDisplayGroups', '/data/display-groups-approver');
                    me.getApproverDisplayGroups();
                }
                
                if (me.hasRole(App.os.roles.superUser) || me.hasRole(App.os.roles.userAdmin)) {
                    me.addUri('AdminDisplayGroupsFull', '/data/display-groups-admin');
                    me.addUri('AdminDisplayGroups', '/data/search/display-groups/admin');
                    me.getAdminDisplayGroups({fetch: false});

                    // permission profiles
                    me.addUri('Profiles', '/data/profiles');
                    me.getProfiles();
                }

                if (me.hasRole(App.os.roles.superUser)) {
                    me.addUri('Displays', '/data/displays');
                }

            },

            validation: {

                'email': {
                    required: true,
                    pattern: 'email'
                },

                'current_password': {
                    required: false,
                    minLength: 8
                },

                'password': {
                    required: false,
                    minLength: 8
                },

                'passwordRetyped': {
                    equalTo: 'password'
                }

            },

            hasRole: function (roleClass) {
                return $.inArray(roleClass, this.get('profiles_names')) > -1;
            },

            getDisplayGroups: function (options) {
                return this.getSubCollection(DisplayGroups, 'DisplayGroups', options);
            },

            getDisplayGroupsApprover: function (options) {
                return this.getSubCollection(DisplayGroupsSearchable, 'DisplayGroupsApprover', options);
            },

            getAdminDisplayGroups: function (options) {
                return this.getSubCollection(DisplayGroupsAdminSearchable, 'AdminDisplayGroups', options);
            },

            getAdminDisplayGroupsFull: function (options) {
                return this.getSubCollection(DisplayGroups, 'AdminDisplayGroupsFull', options);
            },

            getApproverDisplayGroups: function (options) {
                return this.getSubCollection(DisplayGroups, 'ApproverDisplayGroups', options);
            },

            getPublicTransportDisruptions : function (options) {
                return this.getSubCollection(PublicTransportDisruptions, 'PublicTransportDisruptions', options);
            },

            getPublicTransportDisruptionsHistory : function (options) {
                return this.getSubCollection(PublicTransportDisruptionsHistory, 'PublicTransportDisruptionsHistory', options);
            },

            getDisplays: function (options) {
                return this.getSubCollection(Displays, 'Displays', options);
            },

            getDepartments: function (options) {
                return this.getSubCollection(Departments, 'Departments', options);
            },

            getPermissions: function (options) {
                var me = this;
                if (_.isUndefined(me.permissions)) {
                    me.permissions = new Permissions();
                    me.permissions.url = me.get('uris').Permissions;

                    me.permissions._deferredFetch = $.Deferred();
                    me.permissions.promise = me.permissions._deferredFetch.promise();

                    $.when(me.permissions.fetch()).then(function () {
                        me.permissions._deferredFetch.resolve();
                    });
                }
                return me.permissions;
            },

            getProfiles: function (options) {
                if (this.hasRole(App.os.roles.superUser) || this.hasRole(App.os.roles.userAdmin)) {
                    return this.getSubCollection(Profiles, 'Profiles', options);
                } else {
                    return false;
                }
            },

            getApproverGroups: function () {
                return this.get('approver_in') || [];
            }

        });

        PublicTransportDisruption.prototype.uriStencilParent = User;
        return User;

    }
    );
