// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Offers/Offers',['App',
        'backbone',
        'hbs!templates/views/Offers/Offers',
        'views/Offers/OfferTile'
        ],
    function (
        App,
        Backbone,
        template,
        OfferTile
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({template: template,

            tagName: 'section',
            className: 'wrapper',

            childView: OfferTile,

            childViewContainer: '.offers-tiles',

            searchBar: {
                fields: ['name'],
                sortBy: {
                    'name': 'Campaign Name',
                    'created': 'Oldest'
                }
            },

            ui: {
                ngIframe: '#ng-iframe',
                pageLoadMore: '#page-load-more',
                offersTiles: '.offers-tiles'
            },

            events: {
                'click .load-next': '_loadNext'
            },

            collectionEvents: {
                'remove': '_noItems'
            },

            initialize: function () {
                if ($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) !== -1) {
                    var me = this;
                    me.collection.resetCollection();
                    me.listenTo(App.vent, 'App.departments.changeCurrentDepartment', _.bind(me._resetOffers, me));
                    if (!this.collection.beenQueried()) {
                        this.collection.reset();
                    }
                }
            },

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',

                    onRender: function () {
                        $('.load-next').hide();
                    },

                    getTemplate: function () {
                        var templateHtml = '',
                            // var templateHtml = '<div class="no-items empty-collection"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            onRender: function () {
                var me = this;

                if ($.inArray(App.currentCompany.getActivePlan(), ['MULTI']) !== -1) {

                    me.ui.ngIframe.parent().remove();

                    $.when(App.currentDepartmentPromise).then(function () {
                        if (!me.collection.beenQueried()) {

                            $.when(me.collection.query({}, '-created')).then(function () {

                                if (_.size(me.collection) === 0) {
                                    me.$el.find('.no-items').html('<i class="fa fa-book"></i> You have no offers');
                                } else {
                                    //me.render();
                                    // Trigger offer-tiles-loaded event when all thumbnails are loaded
                                    me.$el.find('.offer-thumbnail').imagesLoaded({background: true}, function () {
                                        console.debug('Offer thumbnails loaded');
                                        App.vent.trigger('offer-tiles-loaded');
                                    });
                                }
                            });
                        } else {
                            if (me.collection._moreResults) {
                                me._moreItems();
                            }
                            me._noItems();
                        }
                    });
                } else{
                    me.ui.offersTiles.remove();
                    me.ui.pageLoadMore.remove();

                    this.srcReplacer();
                }
            },

            _noItems: function () {
                var me = this;
                if (!_.size(this.collection)) {
                    _.defer(function () {
                        me.$el.find('.no-items').html('<i class="fa fa-book"></i> You have no offers');
                    })
                }
            },

            _resetOffers: function () {
                var me = this;
                delete(me.collection);
                Backbone.history.stop();
                Backbone.history.start();
            },

            srcReplacer: function(){

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=offers';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }
        });
    });

