// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,window,console,setTimeout*/
define('layouts/RetailersLayout',['App', 'jquery', 'underscore', 'hbs!templates/layouts/RetailersLayout', 'backbone',

        'views/Retailers/RetailersSelector',
        'views/Retailers/RetailersDepartmentSelector',
        'views/Retailers/DepartmentDetailsLayout',

        'jquery.nicescroll'
    ],
    function (App, $, _, template, Backbone,

              RetailersSelector,
              RetailersDepartmentSelector,
              DepartmentDetailsLayout

        ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            className: 'retailers-contacts',

            regions: {
                retailersSelector: '#retailers-selector',
                retailerDetails: '#retailer-details'
            },

            ui: {

            },

            events: {
                'click .create-btn' : '_createRetailer'
            },


            initialize: function () {
                var me = this;
                App.currentUser.getAdminDisplayGroupsFull();
                me.listenTo(App.vent, 'App.retailers.showDetails', _.bind(me._showDepartments, me));
            },


            onRender: function () {
                var me = this;
                me.retailersSelector.show(new RetailersSelector());
                me.retailerDetails.show(new DepartmentDetailsLayout());
            },

            _showDepartments: function (retailerUri) {
                var me = this;
                App.appRouter.navigate(retailerUri);
                me.retailersSelector.reset();
                App.vent.trigger('App.retailers.setCurrent', retailerUri);
                me.retailersSelector.show(new RetailersDepartmentSelector({ model: App.currentUser.getRetailers().get(retailerUri) }));
            }

        });
    });

