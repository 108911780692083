// -*- coding: utf-8 -*-


/*global define,appurl*/
define('collections/Invoices',["backbone", "models/Invoice", "moment"],
    function (Backbone, Invoice, moment) {

        /** ADMIN ROLE REQUIRED **/

        'use strict';
        var Invoices = Backbone.Collection.extend({
            model: Invoice,

            comparator: function (model) {
                return -moment(model.get('created'))
            }
        });

        return Invoices;
    });
