'use strict';

define('views/InternalReports/UserLogins',[
        'App',
        'backbone',
        'underscore',
        'backbone.marionette',
        'jquery',
        'moment',
        'hbs!templates/views/InternalReports/UserLogins'
    ],
    function (App,
              Backbone,
              _,
              Marionette,
              $,
              moment,
              template) {

        return Backbone.Marionette.ItemView.extend({

            template: template,
            templateHelpers: function () {
                return {
                    loginCount: this.loginCount
                };
            },

            className: 'white-panel widget ar-16-4',

            ui: {
                spinner: ".internal-reports-loading-mask"
            },

            initialize: function () {
                this.listenTo(App.vent, 'App.internalReports.dateRangeChanged', _.bind(this.loadStats, this));
            },

            loadStats: function (data) {
                var me = this;
                var from = data.from;
                var to = data.to;

                me.ui.spinner.addClass('spinning');
                $.get(App.enrichApiUrl('/data/logins/' + ((+from) / 1000) + '/' + ((+to) / 1000)), function (data) {
                    me.loginCount = parseInt(data);
                    console.log("Got logins count: ", me.loginCount);
                    me.render();
                });
            }
        });
    });

