// -*- coding: utf-8 -*-

/*jslint nomen: true*/
/*jslint es5: true*/
/*global define,console,document,setTimeout,appurl,window,sessionData,BootstrapDialog,_gaq*/
define('views/Profile/ProfileUserDetails',['App', 'backbone', 'backbone.marionette', 'jquery', 'underscore',
        'hbs!templates/views/Profile/ProfileUserDetails',
        'views/Profile/ProfilePasswordChange',
        'ladda-bootstrap',
        'switchery',
        'jquery-validation',
        'intl-tel-input'],
    function (App, Backbone, Marionette, $, _,
              template,
              ProfilePasswordChange,
              Ladda,
              Switchery) {
        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            templateHelpers: function() {
                return { environment: appEnv, api: apiUrl};
            },

            className: 'form-panel profile-form row no-background',

            regions: {
                'passwordChangeArea': '.password-change-area'
            },

            ui: {
                enableNewUi: '[name="enable-new-ui"]',
                firstname: '[name="firstname"]',
                lastname: '[name="lastname"]',
                phone: '[name="phone"]',
                updateStatus: '.update-status',
                saveBtn: '.save-user-details',
                avatarBox: '.profile-avatar',
                picDropZone: '.picDropZone',
                uploadBtn : '.fileinput'
            },

            modelEvents: {
                'change:avatarUri': 'render'
            },

            bindings: {
                '[name="firstname"]': 'firstname',
                '[name="lastname"]': 'lastname',
                '[name="phone"]': 'phonea'
            },

            events: {
                'click @ui.saveBtn': 'saveData',
                'change @ui.firstname': 'saveData',
                'change @ui.lastname': 'saveData',
                'change @ui.phone': 'saveData',
                'change @ui.enableNewUi': 'changeColorScheme'
            },

            initialize: function () {
                var me = this;

                this.model = App.currentUser;
                Backbone.Validation.bind(this);


            },

            onRender: function() {
                var me = this;

                me.ui.enableNewUi.prop('checked', me.model.attributes.color_scheme !== 'default');
                //var t = setTimeout ( function(){ console.log(me.model); },2000);
                // console.log(me.model);

                $.ajax({
                            url: App.enrichApiUrl(me.model.id),
                            type: 'GET',
                            dataType: "json"

                        }).done(function (data) {
                            // console.log(data);
                            if (data.reg_source === 'Regular') {
                                me.passwordChangeArea.show(new ProfilePasswordChange());
                            }
                        });

                me.stickit();

                me.ui.uploadBtn.on('change.bs.fileinput', function (event) {
                    if (event.namespace === "bs.fileinput") {
                        me.uploadAvatar($('#avatarPic')[0].files[0]);
                    }
                });

                setTimeout(function profileUserDetailsAfterOnRender () {
                    _.isFunction(me.ui.phone.intlTelInput) && me.ui.phone.intlTelInput({
                        defaultCountry: 'gb',
                        preferredCountries: _.uniq(["us", "gb", sessionData.company.country.toLowerCase()])
                    });
                }, 200);

                _.delay(function () {
                    new Switchery(me.$el.find('.js-switch')[0], {
                        color: '#5bc0de',
                        size: 'small'
                    });
                }, 100);
            },

            saveData: function (event) {
                var me = this,
                    l = Ladda.create(document.querySelector('.save-user-details'));

                if (me.model.isValid(true)) {
                    l.start();
                }
                me.model.set($(event.currentTarget).attr('name'), event.currentTarget.value);
                console.log(me.model);
                me.model.save({}, {
                    success: function() {
                        intercomReportEvent('changed_subscription_plan', {
                            timestamp: (new Date()).toString(),
                            email: me.model.get('email')
                        });

                        me.ui.updateStatus.html('<div class="alert alert-success">Your data has been saved.</div>');
                        l.stop();
                    },
                    error: function (model, response, options) {
                        me.ui.updateStatus.html('<div class="alert alert-success">Unfortunatelly an error occurred while saving your data.<br><br>' + '<b>' + response.responseJSON.reason + '</b></div>');
                        l.stop();
                    }
                });
            },

            changeColorScheme: function(event) {
                this.model.attributes.color_scheme = event.currentTarget.checked ? "new" : "default";
                App.setFlowAdsStyle(this.model.attributes.color_scheme);
            },

            uploadAvatar: function (file) {

                var me = this;

                if (!_.isUndefined(file)) {

                    me.ui.avatarBox.addClass('uploading');
                    console.log( App.enrichApiUrl(this.model.get('uris').Avatar));
                    $.ajax({
                        url: App.enrichApiUrl(this.model.get('uris').Avatar),
                        type: 'POST',
                        data: file,
                        cache: false,
                        dataType: 'json',
                        processData: false,
                        crossDomain: true,
                        contentType: false,
                        headers: {'X-Requested-With': 'XMLHttpRequest'},
                        success: function (data) {
                            if (_.isUndefined(data.error)) {
                                me.ui.picDropZone.css('background-image', "url('" + App.os.cutUrlParams(me.model.get('uris').Avatar) + "?s=200&_=" + _.random(10000000, 99999999) + "')");
                                me.model.set('avatarUri', App.os.cutUrlParams(me.model.get('uris').Avatar) + "?_=" + _.random(10000000, 99999999));
                                me.ui.uploadBtn.fileinput('reset');
                                me.ui.avatarBox.removeClass('uploading');

                            } else {
                                console.log('ERRORS: ' + data.error);
                                me.ui.uploadBtn.fileinput('reset');
                                me.ui.avatarBox.removeClass('uploading');
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log(errorThrown);
                            me.ui.uploadBtn.fileinput('reset');
                            me.ui.avatarBox.removeClass('uploading');
                            // STOP LOADING SPINNER
                        }
                    });
                }

            }


        });
    });
