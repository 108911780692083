
/* START_TEMPLATE */
define('hbs!templates/views/Offers/OfferMobilePreview',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "<a class=\"btn btn-lg btn-default download-mediafile-btn\"><i class=\"fa fa-cloud-download\"></i>&nbsp;Download</a>";
  }

function program3(depth0,data) {
  
  
  return "\n    <div class=\"tdp\" data-type-image=\"true\" data-type-video=\"true\" data-draft=\"false\" data-approver=\"true\" style=\"height: 59px;\">\n        <div class=\"btn-group btn-group-justified\">\n            <a class=\"btn btn-lg btn-default download-mediafile-btn\"><i class=\"fa fa-cloud-download\"></i>&nbsp;Download</a>\n        </div>\n    </div>\n";
  }

function program5(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "style=\"background-image: url(";
  if (helper = helpers.media_file) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.media_file); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ");\"";
  return buffer;
  }

  buffer += "<div>\n    <div class=\"tdp hidden\" data-type-image=\"true\" data-type-video=\"true\" data-draft=\"true\">\n\n        <div class=\"fileinput fileinput-new\" data-provides=\"fileinput\" style=\"position: relative; text-align: center;\">\n            <div class=\"btn-group btn-group-justified\">\n                <a class=\"btn btn-lg btn-default btn-file\">\n                    <span class=\"fileinput-new\"><i class=\"fa fa-cloud-upload\"></i>&nbsp;Upload new</span>\n                    <span class=\"fileinput-exists\">Uploading...</span><input type=\"file\" id=\"fileToUpload\">\n                </a>\n                ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </div>\n        </div>\n    </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n<div class=\"previewContainer previewContainer--w320\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.media_file), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "></div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
