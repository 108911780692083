
/*jslint nomen: true*/
/*global define,console*/
define('views/Dashboard/YourLocationWidget',['App', 'backbone', 'underscore', 'backbone.marionette', 'jquery', 'moment',
         'hbs!templates/views/Dashboard/YourLocationWidget'
         ],
    function (App, Backbone, _, Marionette, $, moment,
             template) {

        'use strict';
        return Backbone.Marionette.ItemView.extend({

            template: template,

            templateHelpers: function() {
                return {
                    locationPostcode: this.location && this.location.get('postcode')
                }
            },

            // className: 'white-panel widget ar-16-4',
            className: 'white-panel widget',

            ui: {
                yourLocation: '.your-location'
            },

            onShow: function () {
                var me = this;
                window.createCampaignClicked=0;
                window.mapLoaded = 0;
                $.when(App.currentDepartment.getLocations().promise).then(function () {
                    if (App.currentDepartment.getLocations().length > 0) {
                        me.location = App.currentDepartment.getLocations().at(0);
                        me.render();
                    }
                });
                if(window.mapInterval==undefined)
                window.mapInterval = setInterval(function(){
                    if($('#map').length && me.mapLoadingStarted!=1){
                        if (typeof google === 'object' && typeof google.maps === 'object') { } 
                        else{
                            me.getGMaps();
                        }
                        if(window.mapLoaded!=1 && me.mapLoadingStarted!=1) me.getGMaps();
                    }
                },1000);
                //me.getGMaps();
            }, 
            getGMaps: function (){
                var me = this;
                me.map; me.heatmap;
                me.past = 0;
                me.maxHistory = 96;
                me.mapLoadingStarted=1;

                if (typeof google === 'object' && typeof google.maps === 'object') {
                     $.ajaxSetup({async: true});
                            $.get( App.apiUrl +"/data/crowdedness" + "?past=" + me.past, function (data) {
                                //console.log(data);
                                window.mapLoaded = 1;
                                me.mapLoadingStarted=0;
                                if(window.popup=='create' && window.createCampaignClicked==0){
                                   $('#ADD-CAMPAIGNS').click(); window.createCampaignClicked =1;
                                }
                                //clearInterval(me.mapInterval);
                                $("#slider-label").text("Time: " + data["data_time"]);
                                me.ret_val = data['heat'].map(function (element) {
                                    return {location: new google.maps.LatLng(element[0], element[1]), weight: element[2]};
                                });
                                me.loadMap();
                            });
                } else {
                   $.getScript( "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFvujdonUFvzqhHygallawnLompguuRqg&libraries=visualization" )
                      .done(function( script, textStatus ) {
                        console.log(textStatus);
                            $.ajaxSetup({async: true});
                            $.get( App.apiUrl +"/data/crowdedness" + "?past=" + me.past, function (data) {
                                //console.log(data);
                                window.mapLoaded = 1;
                                me.mapLoadingStarted=0;
                                if(window.popup=='create' && window.createCampaignClicked==0){
                                   $('#ADD-CAMPAIGNS').click(); window.createCampaignClicked =1;
                                }
                                //clearInterval(me.mapInterval);
                                $("#slider-label").text("Time: " + data["data_time"]);
                                me.ret_val = data['heat'].map(function (element) {
                                    return {location: new google.maps.LatLng(element[0], element[1]), weight: element[2]};
                                });
                                me.loadMap();
                            });

                        //window.initMap();
                      })
                      .fail(function( jqxhr, settings, exception ) {
                        console.log(exception);
                    });
                       
                }

            },
            loadMap: function(){

                var me = this;


                                me.map = new google.maps.Map(document.getElementById('map'), {
                                    zoom: 13,
                                    center: {lat: 51.508, lng: -0.116667},
                                    mapTypeId: 'roadmap',
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    zoomControl: false,
                                    scrollwheel: false
                                });


                                me.heatmap = new google.maps.visualization.HeatmapLayer({
                                    data:me.ret_val,
                                    map: me.map
                                });

                                $("#slider").slider({
                                    range: "max",
                                    min: -me.maxHistory,
                                    max: 0,
                                    value:  -me.maxHistory,
                                    step: 1,
                                    stop: function (event, ui) {
                                        var step = Math.abs(ui.value);
                                        //console.log(step);
                                        if (step <= me.maxHistory) {
                                           me.getPoints(step);
                                        }
                                    }
                                });
                          
                                me.setHeatmapParams();  
            },
            setHeatmapParams: function() {
                var me = this;
                    var gradient = ['rgba(0, 255, 255, 0)', 'rgba(0, 255, 255, 1)', 'rgba(0, 191, 255, 1)',
                        'rgba(0, 127, 255, 1)', 'rgba(0, 63, 255, 1)', 'rgba(0, 0, 255, 1)',
                        'rgba(0, 0, 223, 1)', 'rgba(0, 0, 191, 1)', 'rgba(0, 0, 159, 1)',
                        'rgba(0, 0, 127, 1)', 'rgba(63, 0, 91, 1)', 'rgba(127, 0, 63, 1)',
                        'rgba(191, 0, 31, 1)', 'rgba(255, 0, 0, 1)'];
                    me.heatmap.set('gradient', gradient);
                    me.heatmap.set('radius', 100);
                    me.heatmap.set('dissipating', true);

            },

            getPoints: function(past) {
                var me = this;
                    var ret_val;
                    jQuery.ajaxSetup({async: true});
                    console.log(App.apiUrl +"/data/crowdedness" + "?past=" + past);
                    $.get(App.apiUrl +"/data/crowdedness" + "?past=" + past, function (data) {
                        $("#slider-label").text("Time: " + data["data_time"]);
                        ret_val = data['heat'].map(function (element) {
                            return {location: new google.maps.LatLng(element[0], element[1]), weight: element[2]};
                        });

                     me.heatmap.setData( ret_val);
                    });
            }
                
                

        });
    });
