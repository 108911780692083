
/* START_TEMPLATE */
define('hbs!templates/views/SignUp',['hbs','handlebars','templates/helpers/trans'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div id=\"signup-page\">\n	  	<div class=\"container\">\n\n            <form class=\"form-login\" id=\"signup-form\" method=\"post\" action=\"/data/signup\" target=\"_parent\">\n\n\n				<div class=\"login-wrap\">\n\n					<p class=\"see-again-header extend\">\n						Get a FREE account and reach new customers today!<br/>\n						We hate spam as much as you do, so we will send you only important informations.<br/>\n						Already a Flow.City user? <a href=\"#login\"><b>Log in here.</b></a>\n					</p>\n\n					<input type=\"checkbox\" name=\"accept-tnc\" id=\"personal-account\" style=\"top: 3px; position: relative;\">\n					<span id='personal-account-label' for='personal-account'>\n						Personal account\n					</span>\n					<input type=\"text\" name=\"name\" value=\"\" id=\"signup-companyname\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Company name", options) : helperMissing.call(depth0, "trans", "Company name", options)))
    + "\" style=\"margin-top: 10px;\"	/>\n					<br>\n					<input type=\"text\" name=\"email\" id=\"signup-email\" class=\"form-control validate[required,custom[email]]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Email address", options) : helperMissing.call(depth0, "trans", "Email address", options)))
    + "\" autocomplete=\"off\" />\n					<br>\n					<input type=\"password\" name=\"password\" id=\"signup-password\" class=\"form-control validate[required]\" placeholder=\""
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Password", options) : helperMissing.call(depth0, "trans", "Password", options)))
    + " \">\n					<span id=\"show-password\" style=\"float: right;\">Show Password</span>\n					<br>\n					<input type=\"checkbox\" name=\"accept-tnc\" id=\"signup-accept-tnc\" class=\"validate[required]\" style=\"top: 3px; position: relative;\">\n					<span id='signup-accept-tnc-label' for='signup-accept-tnc'>\n						I accept\n						<a href=\"http://www.flow.city/app-terms-conditions/current\" target=\"_blank\">FlowCity Terms and Conditions</a>\n					</span>\n					<button id=\"std-signup-btn\" class=\"btn btn-theme btn-block\" type=\"submit\" style=\"margin-top: 5px;\"> "
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "CREATE ACCOUNT", options) : helperMissing.call(depth0, "trans", "CREATE ACCOUNT", options)))
    + "</button>\n					<div class=\"row\">\n						<div class=\"col-xs-5\"><hr/></div>\n						<div class=\"col-xs-2\" style=\"line-height: 38px; text-align: center; color: white;\">\n							or\n						</div>\n						<div class=\"col-xs-5\"><hr/></div>\n					</div>\n					<a href=\"#signupfb\" class=\"btn btn-facebook btn-block btn-goto-fb-login\">\n						<i class=\"fa fa-facebook\"></i> "
    + escapeExpression((helper = helpers.trans || (depth0 && depth0.trans),options={hash:{},data:data},helper ? helper.call(depth0, "Sign up with Facebook", options) : helperMissing.call(depth0, "trans", "Sign up with Facebook", options)))
    + "\n					</a>\n					<p style=\"text-align: center; color: white; font-size: 12px;\">We won't post to Facebook without asking</p>\n\n					<span class=\"text-center\" style=\"padding: 15px 15px 0 15px; display: block\">\n						<a href=\"#\" class=\"back-button\">Go back</a>\n					</span>\n				</div>\n                  <input type=\"hidden\" name=\"to-url\" value=\"/\" />\n            </form>\n\n	  	</div>\n	  </div>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
