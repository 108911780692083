
/* START_TEMPLATE */
define('hbs!templates/views/Offers/MultiOfferPreview',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<h4 class=\"mt title\">Artworks</h4>\n<ul class=\"items\"></ul>";
  });
return t;
});
/* END_TEMPLATE */
;
