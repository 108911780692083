// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('layouts/DashboardNew',['App',
        'backbone',
        'hbs!templates/layouts/DashboardNew',
        'collections/Displays',
        'collections/Companies',
        'collections/DisplayGroups',
        'views/Displays/Display',

        'bootstrap-select'
        ],
    function (
        App,
        Backbone,
        template
    ) {

        'use strict';
        return Backbone.Marionette.LayoutView.extend({
            template: template,

            onRender: function () {
                var me = this;
                this.srcReplacer();
            },

            srcReplacer: function(){
                var me = this;

                setTimeout(function () {
                    if( $('#ng-iframe').length ) {
                        var src = window.appUrl + '/ng/dist/index.html?module=dashboard';
                        if(window.ngAddress) {
                            src += '&ngAddress=' + App.enrichApiUrl(window.ngAddress);
                            delete window.ngAddress;
                        }

                        $('#ng-iframe').find('iframe').attr('src', src);
                    }
                    else
                        this.srcReplacer();
                }, 200);
            }

        });
    });
