// -*- coding: utf-8 -*-


/*jslint nomen: true*/
/*global define,Comment*/
define('models/OfferSchedule',["App", "backbone"],
    function (App, Backbone) {

        'use strict';
        var OfferSchedule = Backbone.Model.extend({
            uriStencil: 'schedules'
        });

        return OfferSchedule;
    }
);
