
/* START_TEMPLATE */
define('hbs!templates/views/Retailers/DepartmentDisplayGroups',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"department-displaygroups-list\">\n    <div class=\"filter-panel top-panel\">\n        <div class=\"row\">\n            <div class=\"col-lg-6\">\n                <div class=\"suggest-display-groups\"></div>\n            </div>\n            <div class=\"col-lg-6\">\n                <button class=\"btn btn-default addGroupsBtn\" disabled>Add groups</button>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"content-panel\">\n        <div class=\"scroll-area department-users-scroll-area\">\n            <table class=\"table\">\n                <thead>\n                    <tr>\n                        <th>Display group name</th>\n                    </tr>\n                </thead>\n                <tbody></tbody>\n            </table>\n        </div>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
