// -*- coding: utf-8 -*-


/*global define,sessionData*/
define('views/Retailers/Retailers',['App',
        'backbone',
        'hbs!templates/views/Retailers/Retailers',
        'collections/Companies',
        'views/Retailers/Retailer',
        'views/Retailers/CreateRetailer'
        ],
    function (
        App,
        Backbone,
        template,
        RetailersCollection,
        Retailer,
        CreateRetailer
    ) {

        'use strict';
        return Backbone.Marionette.CompositeView.extend({
            template: template,

            childView: Retailer,

            childViewContainer: 'ul',

            className: 'panel-list-box-scroll-area',

            filterValue: '',

            reorderOnSort: true,

            emptyView: function () {
                var EmptyView = Backbone.Marionette.ItemView.extend({
                    className: 'empty-list',
                    tagName: 'li',

                    getTemplate: function () {
                        var templateHtml = '<div class="no-items"><div class="spinner" style="margin: 0 auto"></div></div>',
                            template = Handlebars.compile(templateHtml);
                        return template;
                    }

                });

                return new EmptyView();
            },

            events: {
                'retailers-clear-selection' : '_retailersClearSelection'
            },

            initialize: function () {
                var me = this;

                me.childViewOptions = {
                    parent: me
                };

                me.collection = App.currentUser.getRetailers();

                this.listenTo(App.vent, 'App.retailers.filter', _.bind(me._filterRetailersList, me));
                this.listenTo(App.vent, 'App.retailers.setActive', _.bind(me._setActiveRetailer, me));
                this.listenTo(App.vent, 'App.retailers.create', _.bind(me._createRetailer, me));
            },

            onRender: function () {
                var me = this;

                $.when(me.collection.promise).then(function () {
                    me.$el.find('.no-items').html("No retailers");
                });
            },

            _retailersClearSelection: function() {
                var me = this;
                me.$el.find('li').removeClass('active');
            },

            _filterRetailersList: function (text) {
                var me = this;
                if (!_.isEmpty(text)) {
                    me.filter = function (child, index, collection) {
                        return _.startsWith(child.get('name').toLowerCase(), text.toLowerCase());
                    };
                } else {
                    me.filter = function (child, index, collection) {
                        return true;
                    };
                }
                me.render();
            },

            _setActiveRetailer: function (retailerUri) {
                var me = this;
                me.children.each(function (childView) {
                    childView.$el.removeClass('active');
                });

                me.$el.find('li[data-id="' + retailerUri + '"]').addClass('active');

            },

            _createRetailer: function (event) {
                event && event.preventDefault();

                var modalView = new CreateRetailer();
                App.mainRegion.currentView.modalRegion.show(modalView);
            }


        });
    });
