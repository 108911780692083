
/* START_TEMPLATE */
define('hbs!templates/views/Displays/DisplayGroup',['hbs','handlebars','templates/helpers/isPlural','templates/helpers/iif','templates/helpers/isApprover'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n        <small>";
  if (helper = helpers.credit_cost) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.credit_cost); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  stack1 = (helper = helpers.isPlural || (depth0 && depth0.isPlural),options={hash:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.credit_cost), options) : helperMissing.call(depth0, "isPlural", (depth0 && depth0.credit_cost), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " / hour</small>\n        ";
  return buffer;
  }
function program2(depth0,data) {
  
  
  return "credits";
  }

function program4(depth0,data) {
  
  
  return "credit";
  }

function program6(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <small>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.mobile_reach)),stack1 == null || stack1 === false ? stack1 : stack1.Reach)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " devices</small>\n        ";
  return buffer;
  }

function program8(depth0,data) {
  
  
  return "\n    ";
  }

function program10(depth0,data) {
  
  
  return "\n    <div class=\"display__status-icon\"></div>\n    ";
  }

function program12(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n        ";
  stack1 = (helper = helpers.isApprover || (depth0 && depth0.isApprover),options={hash:{},inverse:self.program(15, program15, data),fn:self.program(13, program13, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.uri), options) : helperMissing.call(depth0, "isApprover", (depth0 && depth0.uri), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        <a href=\"#\" class=\"menu-item hide-menu\" title=\"Close this menu\"><i class=\"fa fa-angle-right\"></i></a>\n    ";
  return buffer;
  }
function program13(depth0,data) {
  
  
  return "\n        <a href=\"#\" class=\"menu-item\" data-permission=\"targ_publ_btn\" data-status=\"online\" title=\"Publish offer\">Publish</a>\n        ";
  }

function program15(depth0,data) {
  
  
  return "\n        <a href=\"#\" class=\"menu-item submit-for-approval\" data-permission=\"targ_subm_btn\" data-status=\"submitted\" title=\"Submit offer for approval\">Submit</a>\n        ";
  }

function program17(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(23, program23, data),fn:self.program(18, program18, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "submitted", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "submitted", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program18(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.isApprover || (depth0 && depth0.isApprover),options={hash:{},inverse:self.program(21, program21, data),fn:self.program(19, program19, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.uri), options) : helperMissing.call(depth0, "isApprover", (depth0 && depth0.uri), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program19(depth0,data) {
  
  
  return "\n    <a href=\"#\" class=\"menu-item\" data-permission=\"targ_reje_btn\" data-status=\"rejected\" title=\"Reject offer\">Reject</a>\n    <a href=\"#\" class=\"menu-item\" data-permission=\"targ_acce_btn\" data-status=\"accepted\" title=\"Accept offer\">Accept</a>\n    <a href=\"#\" class=\"menu-item hide-menu\" title=\"Close this menu\"><i class=\"fa fa-angle-right\"></i></a>\n    ";
  }

function program21(depth0,data) {
  
  
  return "\n    <a href=\"#\" class=\"menu-item back-to-draft\" data-permission=\"targ_draf_btn\" data-status=\"draft\" title=\"Back to draft\">Back to draft</a>\n    <a href=\"#\" class=\"menu-item hide-menu\" title=\"Close this menu\"><i class=\"fa fa-angle-right\"></i></a>\n    ";
  }

function program23(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(24, program24, data),fn:self.program(21, program21, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "rejected", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "rejected", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program24(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(27, program27, data),fn:self.program(25, program25, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "accepted", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "accepted", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program25(depth0,data) {
  
  
  return "\n    <a href=\"#\" class=\"menu-item\" data-permission=\"targ_publ_btn\" data-status=\"online\" title=\"Publish offer\">Publish</a>\n    <a href=\"#\" class=\"menu-item hide-menu\" title=\"Close this menu\"><i class=\"fa fa-angle-right\"></i></a>\n    ";
  }

function program27(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.noop,fn:self.program(28, program28, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "online", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "online", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program28(depth0,data) {
  
  
  return "\n    <a href=\"#\" class=\"menu-item\" data-permission=\"targ_unpu_btn\" data-status=\"draft\" title=\"Unpublish offer\">Unpublish</a>\n    <a href=\"#\" class=\"menu-item hide-menu\" title=\"Close this menu\"><i class=\"fa fa-angle-right\"></i></a>\n    ";
  }

  buffer += "<div class=\"inner\">\n    <h2>";
  if (helper = helpers.display_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.display_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.mobile), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.mobile), {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </h2>\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(10, program10, data),fn:self.program(8, program8, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "draft", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "draft", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    <div class=\"inactive-mask\"></div>\n</div>\n\n<nav class=\"menu\" style=\"position: relative; display: block;\">\n    <div class=\"menu__mask\"></div>\n    ";
  stack1 = (helper = helpers.iif || (depth0 && depth0.iif),options={hash:{},inverse:self.program(17, program17, data),fn:self.program(12, program12, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.status), "draft", options) : helperMissing.call(depth0, "iif", (depth0 && depth0.status), "draft", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</nav>";
  return buffer;
  });
return t;
});
/* END_TEMPLATE */
;
