
/* START_TEMPLATE */
define('hbs!templates/views/ApplicationLayout/ApplicationSearchBar',['hbs','handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"search-bar-container\">\n    <div style=\"\n        width: 30px;\n        float:  left;\n    \">\n        <i class=\"fa fa-search\" style=\"\n            color:  #01b7cd;\n            font-size:  30px;\n        \" aria-hidden=\"true\"></i>\n    </div>\n    <div style=\"\n        float: left;\n        width:  calc(100% - 290px);\n    \">\n        <input id=\"app-search-query\" type=\"text\" class=\"form-control\" placeholder=\"Type to search...\" style=\"\n            font-size: 18px;\n            border: 0;\n            box-shadow: none;\n            color: #01b7cd;\n        \">\n    </div>\n    <div style=\"\n        float: left;\n        width:  260px;\n    \">\n        <select title=\"Set sorting order\" id=\"app-search-query-order\" class=\"selectpicker search-bar-selector\" data-width=\"auto\"></select>\n    </div>\n</div>";
  });
return t;
});
/* END_TEMPLATE */
;
